import * as React from 'react';
import Strings from './../../../strings';
import FolderNames from '../../../data/constants/FolderNames';
import { ButtonToolbar, Button, Form, Dropdown } from 'react-bootstrap';
import type { IApiAdditionalField, IApiEnumType, IApiGlobalSetting, TFolderName } from '@eway-crm/connector';
import type { TFieldPermissionWithName } from './NewAdditionalFieldWizard';
import { NewAdditionalFieldWizard } from './NewAdditionalFieldWizard';
import { DeleteAdditionalFieldsWizard } from './DeleteAdditionalFieldsWizard';
import type { IApiDataResponse } from '@eway-crm/connector';
import EditFieldWizard from './EditFieldWizard';
import type { TEnumValue } from '../../shared/dropDowns/EnumValuesEditGrid';
import { RemoteItemStore } from '../../../RemoteItemStore';
import RouteConfig from '../../../RouteConfig';
import type { RouteComponentProps, match as IRouterMatch} from 'react-router-dom';
import { Route, Switch, Link, matchPath, Redirect } from 'react-router-dom';
import { LoaderProvider } from '../../shared/WcfDataLoaderProvider';
import type { IApiColumnPermission, IApiColumn, IApiGroup} from '@eway-crm/connector';
import { GlobalSettingsNames } from '@eway-crm/connector';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import { GroupNames } from '../../../data/constants/GroupNames';
import FieldsGrid from './FieldsGrid';
import FieldPermissionsDataTransformer from '../fields/FieldPermissionsDataTransformer';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { FontIcon, TooltipHost } from '@fluentui/react';
import ExcelJS from 'exceljs';
import ExcelHelper from '../../../helpers/ExcelHelper';
import { EnumTypes } from '../../../data/constants/EnumTypes';
import Fields from '../Fields';
import FieldTypes from '../../../data/constants/FieldTypes';
import StringHelper from '../../../helpers/StringHelper';
import FilterDropdown from '../../shared/FilterDropdown';
import Cookies from '../../../Cookies';
import memoizeOne from 'memoize-one';
import FakeDisabledButton from '../../shared/FakeDisabledButton';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { DateHelper, FolderNamesHelper, SpinnerVariant } from '@eway-crm/gui';
import FieldsLicenseRestrictionsErrors from './FieldsLicenseRestrictionsErrors';
import LicenseRestrictionsTooltipContent from '../../shared/locks/LicenseRestrictionsTooltipContent';
import { Spinner } from '@eway-crm/gui';

const EXPORT_OPTIONS = {
    onlyCurrentTable: 'onlyCurrentTable',
    allTables: 'allTables',
} as const;

const EXPORT_FILENAME = 'Fields';

type TCookie = {
    showSystemFields: boolean;
    showCustomFields: boolean;
};

export type TFieldWithCol = IApiColumnPermission & {
    col: IApiColumn;
    colName: string;
    typeName: string | null;
    IsMandatory?: boolean;
    IsImportant?: boolean;
    IsUnique?: boolean;
    IsReadonly?: boolean;
};

type TFolderFieldsProps = Pick<RouteComponentProps, 'history' | 'location'> & {
    availableFolders: string[];
    customizableFolders: string[];
    linkableFolders: string[];
    folderName: string;
    groups: IApiGroup[];
    systemHealthNotificationGroupGuid: string | null;
    columns: IApiColumn[];
    onReload: () => void;
    expandedRowIds?: (string | number)[];
    onExpandedRowIdsChange?: (expandedRowIds: (string | number)[]) => void;
    searchedString: string;
    onSearchedStringChange: (newSearchedString: string) => void;
};

type TFolderFieldsState = {
    selection: React.ReactText[];
    isDeleting: boolean;
    loadingItemGuid: string | null;
    fields: TFieldWithCol[] | null;
    initialFields: TFieldWithCol[] | null;
    uniqueNotFullyPermittedForGroupName: string | null;
    isCheckingForDuplicatedValues: boolean;
    nonUniqueValue: string | null;
    anyChangeMade: boolean;
    isSavingChangesOrLoading: boolean;
    showSystemFields: boolean;
    showCustomFields: boolean;
};

const myStrings = Strings.components.routes.fields;
const myFieldPermissionsStrings = Strings.components.routes.fieldPermissions;

export default class FolderFields extends React.PureComponent<TFolderFieldsProps, TFolderFieldsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TFolderFieldsProps) {
        super(props);

        if (props.availableFolders.length === 0) {
            throw new Error('No available folder names supplied.');
        }

        if (props.linkableFolders.length === 0) {
            throw new Error('No linkable folder names supplied.');
        }

        const cookie = Cookies.getCookie<TCookie>(Cookies.names.fields);
        this.state = {
            selection: [],
            isDeleting: false,
            loadingItemGuid: null,
            fields: null,
            initialFields: null,
            uniqueNotFullyPermittedForGroupName: null,
            isCheckingForDuplicatedValues: false,
            nonUniqueValue: null,
            anyChangeMade: false,
            isSavingChangesOrLoading: false,
            showSystemFields: cookie.showSystemFields ?? true,
            showCustomFields: cookie.showCustomFields ?? true
        };
    }

    private readonly delete = () => {
        this.setState({ isDeleting: true });
    };

    private readonly getNewAdditionalFieldsData = (successCb: ([fp, displayLanguageColumns]: [TFieldPermissionWithName[], string[]]) => void) => {
        const currentFieldPermissions: TFieldPermissionWithName[] = this.props.groups
            .filter((g) => !g.IsCategory && !g.IsOutlookCategory)
            .map((grp) => ({
                ItemGUID: '',
                ItemVersion: 1,
                FileAs: '',
                ColumnName: '',
                GroupGuid: grp.ItemGUID,
                FolderName: this.props.folderName,
                IsAdditionalField: true,
                IsMandatoryRuleEditable: true,
                IsPermissionRuleEditable: true,
                MandatoryRule: FieldPermissionNames.MandatoryRuleKeys.None,
                PermissionRule: FieldPermissionNames.PermissionRuleKeys.All,
                NotFullyPermittedForGroupGuids: null,
                IsSetToUnique: false,
                colName: grp.GroupName,
                CreatedByGUID: this.context.connection.state.session!.userGuid,
                ModifiedByGUID: this.context.connection.state.session!.userGuid,
                OwnerGUID: this.context.connection.state.session!.userGuid,
                IsPrivate: false,
                ItemCreated: DateHelper.toRfc3339String(new Date()),
                ItemChanged: DateHelper.toRfc3339String(new Date()),
                Server_ItemCreated: DateHelper.toRfc3339String(new Date()),
                Server_ItemChanged: DateHelper.toRfc3339String(new Date())
            }));

        this.setState(
            { loadingItemGuid: null },
            () => {
                (async () => {
                    const remoteItemStore = new RemoteItemStore(this.context.connection);
                    const displayLanguageColumns = await remoteItemStore.askForFieldsLanguageColumns();
                    successCb([currentFieldPermissions, displayLanguageColumns]);
                })()
                    .catch((err) => console.error('Unable to ask for fields language columns.', err));
            }
        );
    };

    private readonly askIsLastActivityGsEnabled = async (enumType: IApiEnumType) => {
        let isLastActivityGsEnabled = false;
        if (enumType.AllowEditLastActivity) {
            let gsName = GlobalSettingsNames.lastActivityAttributes;
            if (enumType.EnumName === EnumTypes.taskType) {
                gsName = GlobalSettingsNames.nextStepAttributes;
            }
            const globalSettingResponse = await this.context.connection.askApi<IApiDataResponse<IApiGlobalSetting>>('SearchGlobalSettings', {
                transmitObject: { Name: gsName },
            });
            const globalSetting = globalSettingResponse.Data[0];
            const enumTypeInGsKey = globalSetting.AvailableValues?.find((av) => av.Value === FolderNames.getFolderNameByEnumTypeName(enumType.EnumName))?.Key;
            if (globalSetting.Value && enumTypeInGsKey && globalSetting.Value.toString().includes(enumTypeInGsKey)) {
                isLastActivityGsEnabled = true;
            }
        }
        return isLastActivityGsEnabled;
    };

    private readonly getParticularItemData = (
        successCb: (data: {
            additionalField: IApiAdditionalField | null;
            currentColumn: IApiColumn;
            enumType: IApiEnumType | null;
            enumValues: TEnumValue[] | null;
            isLastActivityGsEnabled: boolean;
            fieldPermissions: TFieldPermissionWithCol[];
            displayLanguageColumns: string[];
        }) => void
    ) => {
        const { askApi } = this.context.connection;
        const remoteItemStore = new RemoteItemStore(this.context.connection);

        const matchEdit: IRouterMatch<{ guid: string; folder: string } | null> | null = matchPath(this.props.location.pathname, {
            path: RouteConfig.customizations.fields.edit.pathWithGuid,
        });
        const matchDuplicate: IRouterMatch<{ guid: string; folder: string } | null> | null = matchPath(this.props.location.pathname, {
            path: RouteConfig.customizations.fields.duplicate.pathWithGuid,
        });
        const dbColumnName = matchEdit?.params?.guid || matchDuplicate?.params?.guid;

        const columns = this.props.columns.filter((c) => c.FolderName === this.props.folderName);
        const currentColumn = columns.find((t) => t.ColumnName === dbColumnName && t.FolderName === this.props.folderName);
        const isColumnEditable = currentColumn && FieldsGrid.getIsColumnEditable(currentColumn);

        if (dbColumnName && isColumnEditable) {
            this.setState({ loadingItemGuid: dbColumnName }, () => {
                (async () => {
                    const { duplicateDisabledTooltip } = this.getDisabledButtonTooltips([dbColumnName]);
                    if (!currentColumn || (!currentColumn.IsPermissionEnabled && duplicateDisabledTooltip)) {
                        return this.setState({ loadingItemGuid: null });
                    }

                    const colPermissionsResult = await askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
                        includeDefaultPermissions: true,
                        transmitObject: {
                            ColumnName: dbColumnName,
                            FolderName: this.props.folderName,
                        },
                    });
                    const currentFieldPermissions = colPermissionsResult.Data.filter(cp => cp.GroupGuid !== this.props.systemHealthNotificationGroupGuid).map((colPermission) => {
                        const column = columns.find((t) => t.ColumnName === colPermission.ColumnName && t.FolderName === colPermission.FolderName)!;
                        return {
                            ...colPermission,
                            col: column,
                            colName: this.props.groups.find((g) => g.ItemGUID === colPermission.GroupGuid)!.GroupName,
                        };
                    });

                    const displayLanguageColumns = await remoteItemStore.askForFieldsLanguageColumns();

                    let deletableEnumValues: TEnumValue[] | null = null;
                    let enumType: IApiEnumType | null = null;
                    if (currentColumn.EnumTypeItem?.ItemGUID) {
                        // Load EnumValues
                        const etResult = await askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypesByItemGuids', { itemGuids: [currentColumn.EnumTypeItem.ItemGUID] });
                        enumType = etResult.Data[0];
                        if (enumType) {
                            const deletableEnumValuesRes = await remoteItemStore.askForEnumValuesDeleteStatus(etResult.Data[0].EnumValuesInEnumType);
                            deletableEnumValues = deletableEnumValuesRes.sort((ev1, ev2) => ev1.Rank - ev2.Rank);
                        }
                    }

                    if (currentColumn.AdditionalFieldItem?.ItemGUID) {
                        // Is Additional Field
                        const afResult = await askApi<IApiDataResponse<IApiAdditionalField>>('SearchAdditionalFields', {
                            transmitObject: {
                                ItemGUID: currentColumn.AdditionalFieldItem.ItemGUID,
                            },
                        });
                        const af = afResult.Data[0];
                        if (!af) {
                            return this.setState({ loadingItemGuid: null });
                        }

                        this.setState({ loadingItemGuid: null }, () =>
                            successCb({
                                additionalField: af,
                                currentColumn,
                                enumType,
                                enumValues: deletableEnumValues,
                                fieldPermissions: currentFieldPermissions,
                                isLastActivityGsEnabled: false,
                                displayLanguageColumns,
                            })
                        );
                    } else {
                        // Is System Field
                        let isLastActivityGsEnabled = false;
                        if (enumType) {
                            isLastActivityGsEnabled = await this.askIsLastActivityGsEnabled(enumType);
                        }
                        this.setState({ loadingItemGuid: null }, () =>
                            successCb({
                                additionalField: null,
                                currentColumn,
                                enumType,
                                enumValues: deletableEnumValues,
                                fieldPermissions: currentFieldPermissions,
                                isLastActivityGsEnabled,
                                displayLanguageColumns,
                            })
                        );
                    }
                })()
                    .catch((err) => console.error('Unable to get particular item data.', err));
            });
        }
    };

    private readonly edit = (dbColumnName: string) => {
        const { isFieldLocked } = this.context.licenseRestrictionsHelper.isFieldLocked(this.props.folderName as TFolderName, dbColumnName);
        if (isFieldLocked) {
            return;
        }

        this.props.history.push(`${RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)}/${RouteConfig.customizations.fields.edit.slug}/${dbColumnName}`);
    };

    private readonly cancelAction = (fullReload?: boolean) => {
        this.props.history.push(`${RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)}`);
        if (fullReload) {
            this.props.onReload();
        }
    };

    private readonly duplicate = (itemGuid: string) => {
        this.props.history.push(`${RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)}/${RouteConfig.customizations.fields.duplicate.slug}/${itemGuid}`);
    };

    private readonly onDismiss = () => {
        this.props.history.push(`${RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)}`);
    };

    private readonly onDone = () => {
        this.onDismiss();
        this.props.onReload();
    };

    private readonly getSystemGroup = () => this.props.groups.find((group) => group.GroupName === GroupNames.system)!;

    private readonly askForFields = async (): Promise<TFieldWithCol[] | null> => {
        const { askApi } = this.context.connection;
        if (this.props.folderName) {
            const colPermissionsResult = await askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
                includeDefaultPermissions: true,
                transmitObject: {
                    FolderName: this.props.folderName,
                    GroupGuid: this.getSystemGroup()?.ItemGUID,
                },
            });

            const columns = this.props.columns.filter((col) => col.FolderName === this.props.folderName && !!col.IsPermissionEnabled);
            const fields = colPermissionsResult.Data.map((colPermission) => {
                const column = columns.find((t) => t.ColumnName === colPermission.ColumnName && t.FolderName === colPermission.FolderName);
                if (!column) {
                    console.error(`Couldn't find Column for ColumnPermission`, colPermission);
                    return null;
                }
                return {
                    ...colPermission,
                    col: column,
                    colName: Fields.getColumnName(column),
                    typeName: FieldTypes.getLocalizedName(column.Type),
                };
            }).filter(colPermission => colPermission !== null) as TFieldWithCol[];

            return fields;
        }
        return null;
    };

    private readonly askForAllFields = async (): Promise<{ [folderName: string]: TFieldWithCol[] }> => {
        const { askApi } = this.context.connection;

        const colPermissionsResult = await askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
            includeDefaultPermissions: true,
            transmitObject: {
                GroupGuid: this.getSystemGroup()?.ItemGUID,
            },
        });
        const colPermissionsMap: { [folderName: string]: IApiColumnPermission[] } = {};
        colPermissionsResult.Data.forEach((colPermission) => {
            if (!colPermissionsMap[colPermission.FolderName]) {
                colPermissionsMap[colPermission.FolderName] = [colPermission];
            } else {
                colPermissionsMap[colPermission.FolderName].push(colPermission);
            }
        });

        const columns = this.props.columns.filter((col) => !!col.IsPermissionEnabled);

        const fieldsMap: { [key: string]: TFieldWithCol[] } = {};
        this.props.availableFolders.sort(StringHelper.localeCompare).forEach((folderName) => {
            const folderFields = colPermissionsMap[folderName].map((colPermission) => {
                const column = columns.find((c) => c.ColumnName === colPermission.ColumnName && c.FolderName === folderName)!;
                return {
                    ...colPermission,
                    col: column,
                    colName: Fields.getColumnName(column),
                    typeName: FieldTypes.getLocalizedName(column.Type),
                };
            });

            fieldsMap[folderName] = folderFields;
        });

        return fieldsMap;
    };

    private readonly loadData = async () => {
        const fields = await this.askForFields();
        this.setState({ fields, initialFields: fields, anyChangeMade: false });
    };

    private readonly deselect = () => {
        this.setState({ selection: [] });
    };

    componentDidMount() {
        this.loadData()
            .catch((err) => console.error('Unable to load folder fields data.', err));
    }

    private readonly exportExcelWorkbook = (exportOption: keyof typeof EXPORT_OPTIONS) => {
        this.setState({ isSavingChangesOrLoading: true }, () => {
            (async () => {
                let fields: { [folderName: string]: TFieldWithCol[] } = {};
                let folderNamesToExport = [this.props.folderName];
                if (exportOption === EXPORT_OPTIONS.onlyCurrentTable) {
                    const folderFields = await this.askForFields();
                    if (folderFields) {
                        fields[this.props.folderName] = folderFields;
                    }
                } else {
                    fields = await this.askForAllFields();
                    folderNamesToExport = this.props.availableFolders;
                }

                const workbook = new ExcelJS.Workbook();

                folderNamesToExport.sort((a, b) => StringHelper.localeCompare(FolderNamesHelper.getSingularName(a), FolderNamesHelper.getSingularName(b))).forEach((folderName) => {
                    const displayFolderName = FolderNames.getPluralName(folderName);
                    const worksheet = workbook.addWorksheet(ExcelHelper.prepareSheetName(displayFolderName));
                    worksheet.columns = [{ width: 30 }, { width: 25 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 15 }] as ExcelJS.Column[]; // cast because of bug in newest version of ExcelJS https://github.com/exceljs/exceljs/issues/1543#issuecomment-742622403

                    ExcelHelper.insertHeaderRow(worksheet, `${Strings.components.sideMenu.fields} | ${displayFolderName}`);
                    ExcelHelper.insetColumnTitleRow(worksheet, [
                        myStrings.name,
                        myStrings.type,
                        myStrings.dbColumnName,
                        myFieldPermissionsStrings.columns.isMandatoryTitle,
                        myFieldPermissionsStrings.columns.isOptionalTitle,
                        myFieldPermissionsStrings.columns.isUniqueTitle,
                    ]);

                    if (this.context.licenseRestrictionsHelper.isFolderNameHidden(folderName as TFolderName)) {
                        return;
                    }

                    const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(folderName as TFolderName);
                    if (isFolderNameLocked) {
                        const newRow = worksheet.addRow([Strings.components.licenseRestrictions.excelExportModuleLocked]);
                        const currentRowIdx = worksheet.rowCount;
                        worksheet.mergeCells(currentRowIdx, 1, worksheet.rowCount + 5, worksheet.columnCount);
                        newRow.font = { size: 18, bold: true };
                        newRow.height = 80;
                        newRow.getCell(1).alignment = { horizontal: "center", vertical: "middle" };
                        return;
                    }

                    Object.values(fields[folderName])
                        .sort((field1, field2) => StringHelper.localeCompare(field1.colName, field2.colName))
                        .forEach((field) => {
                            worksheet.addRow([
                                field.colName,
                                field.typeName,
                                field.ColumnName,
                                field.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory ? Strings.yes : Strings.no,
                                field.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional ? Strings.yes : Strings.no,
                                field.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique ? Strings.yes : Strings.no,
                            ]);
                        });
                });

                ExcelHelper.exportToExcel(workbook, exportOption === EXPORT_OPTIONS.onlyCurrentTable ? `${this.props.folderName}_${EXPORT_FILENAME}` : EXPORT_FILENAME, () => {
                    this.setState({
                        isSavingChangesOrLoading: false,
                    });
                });
            })()
                .catch((err) => console.error('Unable to export excel workbook.', err));
        });
    };

    private readonly getRouteMatchParams = () => {
        const matchEdit: IRouterMatch<{ guid: string; folder: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.customizations.fields.edit.pathWithGuid });
        const matchDuplicate: IRouterMatch<{ guid: string; folder: string } | null> | null = matchPath(this.props.location.pathname, {
            path: RouteConfig.customizations.fields.duplicate.pathWithGuid,
        });
        const editedItemGuid = matchEdit?.params?.guid;
        const duplicatedItemGuid = matchDuplicate?.params?.guid;

        return {
            editedItemGuid,
            duplicatedItemGuid,
        };
    };

    private readonly getFilteredFieldPermissions = memoizeOne((fields: TFieldWithCol[], showSystemFields: boolean, showCustomFields: boolean) => {
        return fields.filter((f) => (showCustomFields ? true : !f.IsAdditionalField) && (showSystemFields ? true : f.IsAdditionalField));
    });

    private readonly getIsFieldLocked = (colName: string) => {
        return this.context.licenseRestrictionsHelper.isFieldLocked(this.props.folderName as TFolderName, colName).isFieldLocked;
    };

    private readonly getDisabledButtonTooltips = (selection: string[]): { addNewDisabledTooltip?: string | JSX.Element; duplicateDisabledTooltip?: string | JSX.Element; deleteDisabledTooltip?: string } => {
        let addNewDisabledTooltip: string | JSX.Element | undefined;
        let duplicateDisabledTooltip: string | JSX.Element | undefined;
        let deleteDisabledTooltip: string | undefined;
        const currentFolderName = FolderNames.getPluralName(this.props.folderName);

        const { isLimitReached, licenseRestriction } = this.context.licenseRestrictionsHelper.isCustomFieldsCountExceeded();
        if (isLimitReached) {
            addNewDisabledTooltip = <LicenseRestrictionsTooltipContent licenseRestriction={licenseRestriction} />;
            duplicateDisabledTooltip = <LicenseRestrictionsTooltipContent licenseRestriction={licenseRestriction} />;
        }

        if (!this.props.customizableFolders.includes(this.props.folderName)) {
            addNewDisabledTooltip = Strings.formatString(myStrings.tooltips.cantCreateCustomField, currentFolderName) as string;
            duplicateDisabledTooltip = Strings.formatString(myStrings.tooltips.cantDuplicateFields, currentFolderName) as string;
        }

        if (selection && selection.length > 0) {
            const selectedFields = this.state.fields?.filter((f) => selection.includes(f.ColumnName));
            if (selectedFields?.some((f) => !f.IsAdditionalField)) {
                // Is System Field
                const systemTypeName = selectedFields.find((f) => FieldTypes.getAfType(f.col.Type) === null)?.typeName; // Name of system-only field type
                duplicateDisabledTooltip = systemTypeName ? (Strings.formatString(myStrings.tooltips.cantDuplicateSystemOnlyField, systemTypeName.toLowerCase()) as string) : duplicateDisabledTooltip;
                deleteDisabledTooltip = myStrings.tooltips.cantDeleteFields;
            }
        }

        if (this.props.folderName === FolderNames.goodsInCart) {
            const productsFolderName = FolderNames.getPluralName(FolderNames.goods);
            addNewDisabledTooltip = Strings.formatString(myStrings.tooltips.cantManageFieldsInProducts, Strings.create.toLowerCase(), currentFolderName, productsFolderName) as string;
            duplicateDisabledTooltip = Strings.formatString(myStrings.tooltips.cantManageFieldsInProducts, Strings.duplicate.toLowerCase(), currentFolderName, productsFolderName) as string;
            deleteDisabledTooltip = Strings.formatString(myStrings.tooltips.cantManageFieldsInProducts, Strings.delete.toLowerCase(), currentFolderName, productsFolderName) as string;
        }

        return {
            addNewDisabledTooltip,
            duplicateDisabledTooltip,
            deleteDisabledTooltip,
        };
    };

    render() {
        const folderTranslatedName = FolderNames.getPluralName(this.props.folderName);
        const systemGroup = this.getSystemGroup();
        const { addNewDisabledTooltip, duplicateDisabledTooltip, deleteDisabledTooltip } = this.getDisabledButtonTooltips(this.state.selection as string[]);

        const headerComponent = (
            <>
                <div className="d-flex align-items-stretch justify-content-between">
                    <div className="pb-2 w-100 d-flex justify-content-between">
                        <div className="d-flex flex-column">
                            <h1 style={{ marginBottom: '0.75rem' }}>{Strings.formatString(myStrings.title, folderTranslatedName)}</h1>
                            <p className="m-0 text-medium">{Strings.formatString(myStrings.subtitle, folderTranslatedName)}</p>
                        </div>
                    </div>
                </div>
                <hr className="mb-1 mt-3" />
            </>
        );

        const loadingComponent = (
            <div className="container-fluid d-flex h-100 px-3 py-3 px-lg-4 py-lg-4 px-xl-5 py-xl-4 flex-column">
                <div className="row mx-0">
                    <div className="col p-0">{headerComponent}</div>
                </div>
                <div className="row flex-fill d-flex justify-content-start mt-2">
                    <div className="col p-0 align-self-center text-center">
                        <Spinner variant={SpinnerVariant.ease} />
                    </div>
                </div>
            </div>
        );

        if (!this.state.fields) {
            return loadingComponent;
        }

        const { editedItemGuid, duplicatedItemGuid } = this.getRouteMatchParams();
        return (
            <div className="h-100 w-100 d-flex flex-column">
                <FieldsLicenseRestrictionsErrors folderName={this.props.folderName} />
                <div className="container-fluid d-flex flex-fill px-3 py-3 px-lg-4 py-lg-4 px-xl-5 py-xl-4 flex-column">
                    {this.state.isSavingChangesOrLoading && <SpinnerModal variant={SpinnerVariant.linear} />}
                    <div className="row mx-0">
                        <div className="col p-0">
                            {headerComponent}
                            <div className="d-block">
                                <Switch>
                                    <Route path={RouteConfig.customizations.fields.new.path}>
                                        {!!addNewDisabledTooltip && <Redirect to={RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)} />}
                                        <LoaderProvider
                                            loadData={this.getNewAdditionalFieldsData}
                                            render={([fieldPermissions, displayLanguageColumns]) => (
                                                <NewAdditionalFieldWizard
                                                    availableFolderNames={this.props.availableFolders}
                                                    linkableFolderNames={this.props.linkableFolders}
                                                    customizableFolderNames={this.props.customizableFolders}
                                                    folderName={this.props.folderName}
                                                    onDismiss={this.onDismiss}
                                                    onDone={this.onDone}
                                                    fieldPermissions={fieldPermissions}
                                                    allGroups={this.props.groups}
                                                    allColumns={this.props.columns}
                                                    displayLanguageColumns={displayLanguageColumns}
                                                    allFields={this.state.fields!}
                                                    licenseRestrictionsHelper={this.context.licenseRestrictionsHelper}
                                                />
                                            )}
                                        />
                                    </Route>
                                    <Route path={RouteConfig.customizations.fields.edit.pathWithGuid}>
                                        {this.getIsFieldLocked(editedItemGuid!) && <Redirect to={RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)} />}
                                        <LoaderProvider
                                            key={editedItemGuid}
                                            loadData={this.getParticularItemData}
                                            render={({ additionalField, currentColumn, enumType, enumValues, isLastActivityGsEnabled, fieldPermissions, displayLanguageColumns }) => (
                                                <EditFieldWizard
                                                    currentColumn={currentColumn}
                                                    allColumns={this.props.columns}
                                                    folderName={this.props.folderName}
                                                    availableFolderNames={this.props.availableFolders}
                                                    additionalFieldItem={additionalField}
                                                    fieldPermissions={fieldPermissions}
                                                    enumType={enumType}
                                                    isLastActivityGsEnabled={isLastActivityGsEnabled}
                                                    enumValues={enumValues}
                                                    allGroups={this.props.groups}
                                                    onDismiss={() => this.cancelAction()}
                                                    onDone={() => this.cancelAction(true)}
                                                    displayLanguageColumns={displayLanguageColumns}
                                                    allFields={this.state.fields!}
                                                />
                                            )}
                                        />
                                    </Route>
                                    <Route path={RouteConfig.customizations.fields.duplicate.pathWithGuid}>
                                        {(!!duplicateDisabledTooltip || this.getIsFieldLocked(duplicatedItemGuid!)) && (
                                            <Redirect to={RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)} />
                                        )}
                                        <LoaderProvider
                                            key={duplicatedItemGuid}
                                            loadData={this.getParticularItemData}
                                            render={({ additionalField: duplicatedItem, currentColumn, enumValues, fieldPermissions, displayLanguageColumns }) => (
                                                <NewAdditionalFieldWizard
                                                    availableFolderNames={this.props.availableFolders}
                                                    customizableFolderNames={this.props.customizableFolders}
                                                    linkableFolderNames={this.props.linkableFolders}
                                                    folderName={this.props.folderName}
                                                    onDismiss={() => this.cancelAction()}
                                                    onDone={() => this.cancelAction(true)}
                                                    sourceAdditionalFieldItem={duplicatedItem}
                                                    sourceEnumValues={enumValues || undefined}
                                                    allColumns={this.props.columns}
                                                    sourceColumn={currentColumn}
                                                    fieldPermissions={fieldPermissions}
                                                    allGroups={this.props.groups}
                                                    displayLanguageColumns={displayLanguageColumns}
                                                    allFields={this.state.fields!}
                                                    licenseRestrictionsHelper={this.context.licenseRestrictionsHelper}
                                                />
                                            )}
                                        />
                                    </Route>
                                </Switch>
                                {this.state.isDeleting && this.state.selection.length !== 0 && (
                                    <DeleteAdditionalFieldsWizard
                                        onDismiss={() => this.setState({ isDeleting: false })}
                                        selectedFields={this.state.fields?.filter((f) => this.state.selection.includes(f.ColumnName))}
                                        onDone={this.props.onReload}
                                    />
                                )}
                                <div className="container max-w-none mx-0 mb-3 p-0">
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <ButtonToolbar>
                                                <TooltipHost content={addNewDisabledTooltip ?? undefined}>
                                                    <Link to={`${RouteConfig.customizations.fields.getPathWithFolder(this.props.folderName)}/${RouteConfig.customizations.fields.new.slug}`}>
                                                        <FakeDisabledButton variant="outline-secondary" disabled={!!addNewDisabledTooltip}>
                                                            <i className="mdl2 mdl2-add" aria-hidden="true" /> {myStrings.addField}
                                                        </FakeDisabledButton>
                                                    </Link>
                                                </TooltipHost>
                                                <Button variant="outline-secondary" onClick={this.props.onReload}>
                                                    <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                                </Button>
                                                <TooltipHost content={duplicateDisabledTooltip ?? undefined}>
                                                    <FakeDisabledButton
                                                        variant="outline-secondary"
                                                        onClick={() => this.duplicate(this.state.selection[0] as string)}
                                                        disabled={
                                                            !!duplicateDisabledTooltip ||
                                                            this.state.selection.length !== 1 ||
                                                            (this.state.selection.length === 1 && this.getIsFieldLocked(this.state.selection[0] as string))
                                                        }
                                                    >
                                                        <i className="mdl2 mdl2-copy" aria-hidden="true" /> {Strings.duplicate}
                                                    </FakeDisabledButton>
                                                </TooltipHost>
                                                <TooltipHost content={deleteDisabledTooltip ?? undefined}>
                                                    <FakeDisabledButton variant="outline-secondary" onClick={this.delete} disabled={!!deleteDisabledTooltip || this.state.selection.length === 0}>
                                                        <i className="mdl2 mdl2-delete" aria-hidden="true" /> {Strings.delete}
                                                    </FakeDisabledButton>
                                                </TooltipHost>
                                                <Dropdown>
                                                    <Dropdown.Toggle className="exportBtn__toggle" title={Strings.exportToExcel} variant="outline-secondary">
                                                        <FontIcon iconName="ExcelDocument" /> {Strings.export}
                                                        <FontIcon className="pl-1 exportBtn__chevronDown" iconName="ChevronDown" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="exportBtn__menu">
                                                        <Dropdown.Item className="exportBtn__item" eventKey="1" onClick={() => this.exportExcelWorkbook(EXPORT_OPTIONS.onlyCurrentTable)}>
                                                            {myStrings.exportThisTable}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item className="exportBtn__item" eventKey="2" onClick={() => this.exportExcelWorkbook(EXPORT_OPTIONS.allTables)}>
                                                            {myStrings.exportAllTables}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </ButtonToolbar>
                                        </div>
                                        <div className="col-auto">
                                            <Button variant="outline-secondary" onClick={this.deselect} hidden={this.state.selection.length === 0}>
                                                {Strings.formatString(Strings.selectedFormat, this.state.selection.length)} <i className="mdl2 mdl2-cancel" aria-hidden="true" />
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Form.Control
                                                type="text"
                                                placeholder={Strings.search}
                                                value={this.props.searchedString}
                                                onChange={(e) => this.props.onSearchedStringChange(e.target.value)}
                                            />
                                        </div>
                                        <div className="col-auto">
                                            <FilterDropdown>
                                                <FilterDropdown.Switch
                                                    checked={this.state.showSystemFields}
                                                    id="showSystemFields"
                                                    label={myStrings.showSystemFields}
                                                    onChange={(v) => {
                                                        Cookies.setCookie(Cookies.names.fields, { showSystemFields: v }, 365 * 24);
                                                        this.setState({ showSystemFields: v });
                                                    }}
                                                />
                                                <FilterDropdown.Switch
                                                    checked={this.state.showCustomFields}
                                                    id="showCustomFields"
                                                    label={myStrings.showCustomFields}
                                                    onChange={(v) => {
                                                        Cookies.setCookie(Cookies.names.fields, { showCustomFields: v }, 365 * 24);
                                                        this.setState({ showCustomFields: v });
                                                    }}
                                                />
                                            </FilterDropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-fill d-flex justify-content-start mt-2 mx-0">
                        <div className="col p-0">
                            <FieldPermissionsDataTransformer<TFieldWithCol>
                                allGroups={this.props.groups}
                                fieldPermissions={this.getFilteredFieldPermissions(this.state.fields, this.state.showSystemFields, this.state.showCustomFields)}
                                selectedFolderName={this.props.folderName}
                                selectedGroup={systemGroup}
                                render={({ fieldPermissions }) => (
                                    <FieldsGrid
                                        fields={fieldPermissions}
                                        folderName={this.props.folderName}
                                        loadingItemGuid={this.state.loadingItemGuid}
                                        searchedString={this.props.searchedString}
                                        selection={this.state.selection}
                                        onSelectionChange={(selection) => this.setState({ selection })}
                                        systemGroupGuid={systemGroup.ItemGUID}
                                        onEdit={this.edit}
                                        expandedRowIds={this.props.expandedRowIds}
                                        onExpandedRowIdsChange={this.props.onExpandedRowIdsChange}
                                    />
                                )}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
