import { FontIcon, mergeStyles } from '@fluentui/react';
import React, { useState } from 'react';

type TCollapsibleCheckoutSection = React.PropsWithChildren & {
    title: string;
    mobileSubtitle: string;
    disableCollapsing?: boolean;
};

const CollapsibleCheckoutSection: React.FC<TCollapsibleCheckoutSection> = ({ children, title, disableCollapsing, mobileSubtitle }) => {
    const [isExpanded, setIsExpanded] = useState(disableCollapsing);

    return (
        <div className="d-flex flex-column flex-grow-1">
            <div className={'collapsible-checkout-section__title-wrap'}>
                <h3
                    className={mergeStyles('collapsible-checkout-section__title force-ew-window-move', disableCollapsing && 'collapsible-checkout-section--not-collapsible')}
                    onClick={() => setIsExpanded((prev) => !prev)}
                >
                    {title}
                    <FontIcon
                        className={mergeStyles('ml-1 collapsible-checkout-section__chevron', disableCollapsing && 'collapsible-checkout-section--not-collapsible')}
                        iconName={isExpanded ? 'ChevronDownMed' : 'ChevronRightMed'}
                    />
                </h3>
                <div className={mergeStyles('collapsible-checkout-section__mobile-subtitle', isExpanded && 'expanded')}>{mobileSubtitle}</div>
            </div>
            <div className={mergeStyles('flex-grow-1 collapsible-checkout-section', isExpanded && 'expanded', disableCollapsing && 'collapsible-checkout-section--not-collapsible')}>{children}</div>
        </div>
    );
};

export default CollapsibleCheckoutSection;
