import { useCopyTextToClipboard } from '@eway-crm/gui';
import { DirectionalHint, FontIcon, TooltipHost } from '@fluentui/react';
import React from "react";
import Strings from "../../../strings";

type TCopyToClipboardTextProps = React.PropsWithChildren & {
    value?: string;
};

const COPY_TO_CLIPBOARD_CALLOUT_PROPS = { isBeakVisible: false, gapSpace: 8, styles: { root: { padding: 0, '::after': { inset: 'initial' } } } };

const CopyToClipboardText: React.FC<TCopyToClipboardTextProps> = ({ value, children }) => {
    const valueToCopy = value ?? (children as string);
    const { handleCopyClick, isCopied } = useCopyTextToClipboard(valueToCopy);

    return (
        <TooltipHost
            content={
                isCopied ? (
                    <span style={{ backgroundColor: 'var(--success)', color: 'white' }} className="px-2 py-1 d-flex">
                        <FontIcon iconName="CheckMark" className="pr-1" style={{ fontWeight: 'bold' }} /> {Strings.copied}
                    </span>
                ) : (
                    <span className="px-2 py-1 d-flex">
                        <FontIcon iconName="Copy" className="pr-1" />
                        {Strings.clickToCopy}
                    </span>
                )
            }
            delay={0}
            calloutProps={COPY_TO_CLIPBOARD_CALLOUT_PROPS}
            directionalHint={DirectionalHint.rightCenter}
        >
            <div onClick={handleCopyClick} className="copyBtn d-inline-block">
                {children}
            </div>
        </TooltipHost>
    );
};

export default CopyToClipboardText;