import type { IApiDataResponse, IApiEnumType, IApiEnumValue, IApiResult, IApiWorkflowModel } from '@eway-crm/connector';
import React from 'react';
import FolderNames from '../../../data/constants/FolderNames';
import ReactHelper from '../../../helpers/ReactHelper';
import Strings from '../../../strings';
import FolderContainer from '../../layout/FolderContainer';
import SimpleWorkflowEditor from './SimpleWorkflowEditor';
import type { TEnumValue } from '../../shared/dropDowns/EnumValuesEditGrid';
import type { TStageAttribute } from './WorkflowModelContainer';
import { RemoteItemStore } from '../../../RemoteItemStore';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { Button } from 'react-bootstrap';
import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.workflow;

type TFolderSimpleWorkflowEditorProps = {
    folderName: string;
    workflowName: string | null;
    selectedWfModel?: IApiWorkflowModel;
    onDeleteWfModelDone: () => void;
    stageAttributes: TStageAttribute[] | null;
    disabled: boolean;
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumn: (language: string) => Promise<void>;
    anyChangeMade: boolean;
    onAnyChangeMadeChange: (anyChangeMade: boolean) => void;
    onUpdateStageAttributes: (gsName: string, value: string) => Promise<void>; 
};

type TFolderSimpleWorflowEditorState = {
    isLoading: boolean;
    initialWfStages: TEnumValue[] | null;
    wfStages: TEnumValue[] | null;
    enumType: IApiEnumType | null;
    showResetWfDialog: boolean;
};

export default class FolderSimpleWorkflowEditor extends React.Component<TFolderSimpleWorkflowEditorProps, TFolderSimpleWorflowEditorState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TFolderSimpleWorkflowEditorProps) {
        super(props);
        this.state = {
            isLoading: false,
            initialWfStages: null,
            wfStages: null,
            enumType: null,
            showResetWfDialog: false,
        };
    }

    public readonly saveChanges = async () => {
        const { askApi } = this.context.connection;
        if (!this.state.wfStages || !EnumValuesValidator.getAreRowsAndColumnsValid(this.state.wfStages)) {
            return;
        }

        await ReactHelper.setState(this, { isLoading: true });

        const enumValuesTransmitObjects: Partial<IApiEnumValue>[] = this.state.wfStages.map((ev, i) => ({
            ItemGUID: ev.ItemGUID,
            ItemVersion: ev.ItemVersion + 1,
            Rank: i + 1,
            FileAs: ev.FileAs,
            En: ev.En,
            Cs: ev.Cs,
            De: ev.De,
            Ru: ev.Ru,
            No: ev.No,
            Sk: ev.Sk,
            IsDefault: ev.IsDefault,
            IsVisible: ev.IsVisible,
            IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
            AllActionEvents: ev.AllActionEvents,
        }));

        await askApi('SaveEnumType', {
            transmitObject: {
                ItemGUID: this.state.enumType!.ItemGUID,
                EnumValuesInEnumType: enumValuesTransmitObjects,
            },
        });
        const { enumType, wfStages } = await this.askForEnumType();
        this.setState({ isLoading: false, wfStages, initialWfStages: wfStages, enumType }, () => {
            this.props.anyChangeMade && this.props.onAnyChangeMadeChange(false);
        });
    };

    private readonly discardChanges = () => {
        this.setState(
            (prevState) => ({
                wfStages: prevState.initialWfStages,
            }),
            () => {
                this.props.anyChangeMade && this.props.onAnyChangeMadeChange(false);
            }
        );
    };

    private readonly askForEnumType = async () => {
        const { askApi } = this.context.connection;
        const enumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypesByItemGuids', { itemGuids: [this.props.selectedWfModel!.EnumTypeGuid], includeEnumValuesAllActionEvents: true });
        const enumValuesWithDeleteStatus = await new RemoteItemStore(this.context.connection).askForEnumValuesDeleteStatus(enumTypeRes.Data[0].EnumValuesInEnumType);

        return { enumType: enumTypeRes.Data[0], wfStages: enumValuesWithDeleteStatus.sort((a, b) => a.Rank - b.Rank) ?? [] };
    };

    private readonly setEnumValues = (newEnumValues: Partial<TEnumValue>[], callback?: () => void) => {
        this.setState({ wfStages: newEnumValues as TEnumValue[] }, () => {
            !this.props.anyChangeMade && this.props.onAnyChangeMadeChange(true);
            callback && callback();
        });
    };

    private readonly loadWorkflowStages = async () => {
        await ReactHelper.setState(this, { isLoading: true, initialWfStages: null, wfStages: null });
        const { enumType, wfStages } = await this.askForEnumType();
        this.setState({ enumType: enumType ?? null, wfStages, initialWfStages: wfStages, isLoading: false });
    };

    private readonly getIsWfDeletable = () => {
        if (!this.state.wfStages || this.props.selectedWfModel?.IsSystem) {
            return false;
        }

        return this.state.wfStages.every((wfStage) => wfStage.CanBeDeleted);
    };

    private readonly deleteWf = async () => {
        if (this.props.selectedWfModel?.ItemGUID) {
            await ReactHelper.setState(this, { isLoading: true, showResetWfDialog: false });
            await this.context.connection.askApi<IApiResult>('DeleteWorkflowModel', { itemGuid: this.props.selectedWfModel?.ItemGUID });
            this.props.onDeleteWfModelDone();
        }
    };

    componentDidMount() {
        this.loadWorkflowStages().catch((err) => console.error('Unable to load workflow stages.', err));
    }

    render() {
        const folderTranslatedName = FolderNames.getPluralName(this.props.folderName);
        const areGridChangesValid = EnumValuesValidator.getAreRowsAndColumnsValid(this.state.wfStages);
        const isWfDeletable = this.getIsWfDeletable();
        return (
            <FolderContainer
                title={`${myStrings.title} | ${this.props.workflowName} | ${folderTranslatedName}`}
                subtitle={myStrings.subtitleSimpleEditor}
                anyChangeMade={this.props.anyChangeMade}
                onSave={this.saveChanges}
                onDiscard={this.discardChanges}
                isSaveButtonDisabled={!areGridChangesValid}
            >
                <div className="row flex-fill d-flex justify-content-start mt-3 mx-0">
                    {!this.state.wfStages || !this.state.enumType || this.state.isLoading ? (
                        <div className="col p-0 align-self-center text-center">
                            <Spinner variant={SpinnerVariant.ease} />
                        </div>
                    ) : (
                        <div className="d-flex flex-column w-100 h-100">
                                <SimpleWorkflowEditor
                                    enumType={this.state.enumType}
                                    wfSteps={this.state.wfStages}
                                    onDataChange={this.setEnumValues}
                                    folderName={this.props.folderName}
                                    stageAttributes={this.props.stageAttributes}
                                    onUpdateStageAttributes={this.props.onUpdateStageAttributes}
                                    disabled={this.props.disabled}
                                    displayLanguageColumns={this.props.displayLanguageColumns}
                                    toggleDisplayLanguageColumn={this.props.toggleDisplayLanguageColumn}
                                />
                             <Button
                                variant="outline-secondary"
                                className="text-danger align-self-start"
                                style={{ marginBottom: "-0.5rem", marginTop: "0.25rem" }}
                                onClick={() => {
                                    this.setState({ showResetWfDialog: true });
                                }}
                                disabled={!isWfDeletable}
                            >
                                {myStrings.deleteWfModelDialog}
                            </Button>
                        </div> 
                    )}
                </div>

                <Dialog
                    dialogContentProps={{
                        showCloseButton: false,
                        title: myStrings.deleteWfModelDialog,
                    }}
                    hidden={!this.state.showResetWfDialog}
                >
                    {myStrings.deleteWfModelDialogMessage}
                    <DialogFooter className="pt-3">
                        <PrimaryButton
                            onClick={() => {
                                void this.deleteWf();
                            }}
                        >
                            {Strings.yes}
                        </PrimaryButton>
                        <DefaultButton
                            onClick={() => {
                                this.setState({ showResetWfDialog: false });
                            }}
                        >
                            {Strings.cancel}
                        </DefaultButton>
                    </DialogFooter>
                </Dialog>
            </FolderContainer>
        );
    }
}
