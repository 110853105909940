import React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiUser } from '@eway-crm/connector';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TForceChangePasswordWizardProps = {
    onDismiss: () => void;
    selectedUsers: IApiUser[];
    onDone: () => void;
};

type TForceChangePasswordWizardState = {
    isSavingInProgress: boolean;
};

export class ForceChangePasswordWizard extends React.Component<TForceChangePasswordWizardProps, TForceChangePasswordWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TForceChangePasswordWizardProps) {
        super(props);
        this.state = {
            isSavingInProgress: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = () => {
        this.setState({ isSavingInProgress: true }, () => {
            const dataToSubmit: Partial<IApiUser>[] = this.props.selectedUsers.map((user) => ({
                ItemGUID: user.ItemGUID,
                ItemVersion: user.ItemVersion + 1,
                Server_ForcePasswordChange: true,
            }));
            this.context.connection.callApi(
                'SaveUsers',
                {
                    transmitObjects: dataToSubmit,
                },
                () => {
                    this.props.onDone();
                }
            );
        });
    };

    render() {
        const myStrings = Strings.components.routes.users;

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.forceChangePassword}</h1>
                        <div className="mb-4">
                            {this.props.selectedUsers.map((user, i) => (
                                <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                    {user.FileAs}
                                </span>
                            ))}
                        </div>
                        <div>{this.props.selectedUsers.length > 1 ? myStrings.forceChangePasswordMessagePlural : myStrings.forceChangePasswordMessageSingular}</div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={this.submit} disabled={this.props.selectedUsers.length === 0}>
                            {myStrings.forceChangePassword}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}
