import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Strings from '../../../strings';
import styles from './SideMenu.module.css';
import Cookies from '../../../Cookies';
import RouteConfig from '../../../RouteConfig';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import NotificationDot from '../NotificationDot';
import { mergeStyles, TooltipHost } from '@fluentui/react';
import { getWorkflowLicenseRestrictionsErrors } from '../../routes/workflow/WorkflowLicenseRestrictionsErrors';
import { getFieldsLicenseRestrictionsErrors } from '../../routes/fields/FieldsLicenseRestrictionsErrors';
import { Spinner } from 'react-bootstrap';

interface IMenuItemPropsSuperbase extends React.PropsWithChildren {
    menuExpanded: boolean;
}

interface ILinkPropsBase {
    to: string;
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void | undefined;
}

interface IMenuItemPropsBase extends IMenuItemPropsSuperbase {
    iconName: string;
    onClick?: (e?: React.MouseEvent<HTMLAnchorElement>) => void | undefined;
}

interface IMenuItemProps extends ILinkPropsBase, IMenuItemPropsBase {
    title?: string | undefined;
    displayNotificationDot?: boolean;
    exact: boolean;
}

const MenuItemIcon: React.FunctionComponent<{ iconName: string }> = (props) => <i className={styles.icon + ' align-middle mr-2 mdl2 mdl2-' + props.iconName} />;

const MenuItem: React.FunctionComponent<IMenuItemProps> = (props) => {
    const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        if (props.onClick) {
            props.onClick();
            e.preventDefault();
        }
    };
    return (
        <div className="row p-0 menu-item">
            <NavLink
                to={props.to}
                className={'btn btn-outline-gray-200 btn-normal-font btn-nav-link w-100 text-left align-middle py-1 px-1 ' + styles.navLink}
                activeClassName="active"
                onClick={onClick}
                exact={props.exact}
            >
                {React.Children.count(props.children) === 0 ? (
                    <div className="d-flex py-2">
                        <div className="cel ml-2">
                            <MenuItemIcon iconName={props.iconName} />
                        </div>
                        {props.menuExpanded && (
                            <div className="cel ml-1 d-flex">
                                {props.title}
                                {props.displayNotificationDot && (
                                    <TooltipHost hostClassName="d-flex ml-2" content={myStrings.notifications.versionAvailableTooltip}>
                                        <NotificationDot value={1} style={{ marginTop: "-1px" }}/>
                                    </TooltipHost>
                                )}
                            </div>
                        )}
                    </div>
                ) : (
                    <>{props.children}</>
                )}
            </NavLink>
        </div>
    );
};

interface IGroupItemItemProps extends ILinkPropsBase, IMenuItemPropsSuperbase {
}

const GroupItem: React.FunctionComponent<IGroupItemItemProps> = (props) => {
    return (
        <div className="row p-0 menu-item">
            <NavLink to={props.to}
                onClick={props.onClick}
                className={'btn btn-outline-gray-200 btn-normal-font btn-nav-link w-100 text-left py-1 px-1'}
                exact={false}
                activeClassName="active"
            >
                <div className="p-0 py-1 d-flex">
                    <div className={styles.fakeIcon + ' d-inline-block'}>
                        &shy;
                    </div>
                    {props.menuExpanded &&
                        <div className="d-flex align-items-center ml-3">
                            {props.children}
                        </div>
                    }
                </div>
            </NavLink>
        </div>
    );
};

const LoadingGroup: React.FunctionComponent<IMenuItemPropsSuperbase> = (props) => {
    return (
        <div className="row p-0 menu-item">
            <div className={'d-flex align-items-center text-medium mx-3 py-1 text-black-50 ' + styles.loadingRow}>
                <Spinner animation="grow" size={'sm'} variant={'secondary'} className="" />
                {props.menuExpanded && <div className={'d-flex align-items-center ' + styles.loadingRowChildren}>{props.children}</div>}
            </div>
        </div>
    );
};

interface IGroupCookie {
    expanded: boolean;
}

interface IGroupProps extends IMenuItemPropsBase, ILinkPropsBase {
    title: string;
    groupKey: string;
    cookie: IGroupCookie;
    onCookieChange: ((key: string, cookie: IGroupCookie) => void);
    onMenuExpandRequest: () => void;
}

interface IGroupState {
    groupExpanded: boolean;
}

class Group extends React.Component<IGroupProps, IGroupState> {

    constructor(props: IGroupProps) {
        super(props);
        this.state = {
            groupExpanded: props.cookie.expanded
        };
    }

    private readonly toggleGroupExpanded = (exp: boolean) => {
        // If the group is hidden by collapsed menu, do not collapse the group. Show the menu.
        if (!exp && !this.props.menuExpanded) {
            this.props.onMenuExpandRequest();
            return;
        }

        this.props.onCookieChange(this.props.groupKey, { expanded: exp });
        let callback: (() => void) | undefined;
        if (exp && !this.props.menuExpanded) {
            callback = this.props.onMenuExpandRequest;
        }
        this.setState({ groupExpanded: exp }, callback);
    };

    render() {
        return (
            <>
                <MenuItem
                    menuExpanded={this.props.menuExpanded}
                    exact={this.props.menuExpanded && this.state.groupExpanded}
                    iconName={this.props.iconName}
                    to={this.props.to}
                    onClick={() => { this.toggleGroupExpanded(!this.state.groupExpanded); }}
                >
                    <div className="py-1">
                        <div className="d-flex">
                            <div className="cel ml-2">
                                <MenuItemIcon iconName={this.props.iconName} />
                            </div>
                            {this.props.menuExpanded &&
                                <>
                                    <div className="cel ml-1 align-baseline">
                                        {this.props.title}
                                    </div>
                                    <div className="cel ml-auto mr-2">
                                        <i className={'mdl2 mdl2-scroll-chevron-' + (this.state.groupExpanded ? 'up' : 'down') + '-legacy ' + styles.expansionIcon} aria-hidden="true" />
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </MenuItem>
                {(this.state.groupExpanded && this.props.menuExpanded) &&
                    <>
                        {this.props.children}
                    </>
                }
            </>
        );
    }
}

interface ISideMenuProps {
    beginAsCollapsed: boolean;
    isShoppingEnabled: boolean;
    isShoppingEnabledLoaded: boolean;
    isNewVersionAvailable: boolean;
    unpaidOrdersCount: number;
}

interface ISideMenuState {
    expanded: boolean;
}

interface ISideMenuCookie {
    expanded: boolean;
    groups: {
        key: string;
        data: IGroupCookie;
    }[];
}

const myStrings = Strings.components.sideMenu;
const sideMenuCookie = Cookies.getCookie<ISideMenuCookie>(Cookies.names.sideMenu);
const menuData = [
    {
        key: 'home',
        to: RouteConfig.root.path,
        exact: true,
        iconName: 'home',
        title: myStrings.home,
        isGroup: false
    },
    {
        key: 'permissions',
        to: RouteConfig.users.basePath,
        iconName: 'contact',
        title: myStrings.usersAndPermissions,
        isGroup: true,
        groupKey: 'usersAndPermissions',
        groupItems: [
            {
                key: 'users',
                to: RouteConfig.users.path,
                title: myStrings.users,
            },
            {
                key: 'groups',
                to: RouteConfig.groups.path,
                title: myStrings.groups,
            },
            {
                key: 'modulePermissions',
                to: RouteConfig.modulePermissions.path,
                title: myStrings.modulePermissions,
            }
        ]
    },
    {
        key: 'customizations',
        to: RouteConfig.customizations.path,
        iconName: 'settings',
        title: myStrings.customizations,
        isGroup: true,
        groupKey: 'customizations',
        groupItems: [
            {
                key: 'features',
                to: RouteConfig.customizations.features.path,
                title: myStrings.features,
            },
            {
                key: 'fields',
                to: RouteConfig.customizations.fields.path,
                title: myStrings.fields,
            },
            {
                key: 'workflow',
                to: RouteConfig.customizations.workflow.path,
                title: myStrings.workflow,
            }
        ]
    },
    {
        key: 'billing',
        isEnabledByShopping: true,
        to: RouteConfig.billing.path,
        iconName: 'shop',
        title: myStrings.billing,
        isGroup: true,
        groupKey: 'billing',
        groupItems: [
            {
                key: 'manageSubsriptions',
                to: RouteConfig.billing.subscriptions.path,
                title: myStrings.manageSubscriptions,
            },
            {
                key: 'bills',
                to: RouteConfig.billing.bills.path,
                title: myStrings.bills,
            },
            {
                key: 'billingDetails',
                to: RouteConfig.billing.paymentMethods.path,
                title: myStrings.billingDetails,
            }
        ]
    },
    {
        key: 'updates',
        to: RouteConfig.updates.path,
        exact: false,
        iconName: 'set-lock-screen',
        title: myStrings.updates,
        isGroup: false
    },
];

export class SideMenu extends React.Component<ISideMenuProps, ISideMenuState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: ISideMenuProps) {
        super(props);

        let expanded = true;

        if (props.beginAsCollapsed) {
            expanded = false;
        } else {
            const cookie = Cookies.getCookie<ISideMenuCookie>(Cookies.names.sideMenu);
            if (typeof cookie.expanded !== "undefined") {
                expanded = cookie.expanded || false;
            }
        }

        this.state = {
            expanded: expanded
        };
    }

    private readonly setExpanded = (expanded: boolean) => {
        const cookie = Cookies.getCookie<ISideMenuCookie>(Cookies.names.sideMenu);
        cookie.expanded = expanded;
        Cookies.setCookie(Cookies.names.sideMenu, cookie, 8760);
        this.setState({ expanded: expanded });
    };

    private static setGroupCookie(key: string, data: IGroupCookie) {
        const cookie = Cookies.getCookie<ISideMenuCookie>(Cookies.names.sideMenu);
        const newRecord = { key: key, data: data };
        if (!cookie.groups) {
            cookie.groups = [newRecord];
        } else {
            const index = cookie.groups.findIndex((record) => record.key === key);
            if (index === -1) {
                cookie.groups.push(newRecord);
            } else {
                const oldRecord = cookie.groups[index];
                cookie.groups[index] = {
                    ...oldRecord,
                    ...newRecord
                };
            }
        }
        Cookies.setCookie(Cookies.names.sideMenu, cookie, 8760);
    }

    private static getGroupCookie(cookie: ISideMenuCookie | Partial<ISideMenuCookie>, key: string) {
        const defaultCookie: IGroupCookie = {
            expanded: true
        };
        if (!cookie.groups) {
            return defaultCookie;
        }
        const index = cookie.groups.findIndex((record) => record.key === key);
        if (index === -1) {
            return defaultCookie;
        }

        return cookie.groups[index].data;
    }

    private readonly getLicenseRestriction = (groupItemKey: string) => {
        const { isUserRolesLocked, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesLocked();
        if (groupItemKey === "groups" && isUserRolesLocked) {
            return userRolesLicenseRestriction;
        }

        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        if (groupItemKey === "modulePermissions" && isModulePermissionsLocked) {
           return modulePermissionsLicenseRestriction;
        }

        return false;
    };

    render() {
        return (
            <div className={'h-100' + (this.state.expanded ? ' ' + styles.menuExpanded : '')}>
                <div className="h-100 p-0">
                    <div className="row p-0">
                        <div className="d-flex justify-content-end w-100 p-0">
                            <button className="btn btn-outline-gray-200 py-2" onClick={() => this.setExpanded(!this.state.expanded)}>
                                <i className={mergeStyles("mdl2", this.state.expanded ? "mdl2-scroll-chevron-left-legacy" : "mdl2-scroll-chevron-right-legacy" )} aria-hidden="true" />
                            </button>
                        </div>
                    </div>

                    {menuData.map((item, i) => {
                        const key = 'sidemenu-item-' + i;

                        if (item.isEnabledByShopping && !this.props.isShoppingEnabled) {
                            if (this.props.isShoppingEnabledLoaded) {
                                return null;
                            }

                            return <LoadingGroup menuExpanded={this.state.expanded} key={key}>{item.title}</LoadingGroup>;
                        }

                        if (item.isGroup) {
                            if (!item.groupKey || !item.groupItems) {
                                console.error('One of groups is missing groupKey or groupItems.');
                                return null;
                            }

                            return (
                                <Group
                                    key={key}
                                    menuExpanded={this.state.expanded}
                                    to={item.to}
                                    title={item.title}
                                    iconName={item.iconName}
                                    groupKey={item.groupKey}
                                    cookie={SideMenu.getGroupCookie(sideMenuCookie, item.groupKey)}
                                    onCookieChange={SideMenu.setGroupCookie}
                                    onMenuExpandRequest={() => this.setExpanded(true)}
                                >
                                    {item.groupItems.map((groupItem, j) => {
                                        const innerKey = key + 'group-item' + j;

                                        let titleSuffix = null;
                                        const licenseRestriction = this.getLicenseRestriction(groupItem.key);

                                        if (licenseRestriction) {
                                            titleSuffix = (
                                                <LicenseRestrictionsLockIcon className="ml-1" style={{fontSize: "90%", top: "1px"}} licenseRestriction={licenseRestriction} />
                                            );
                                        } else if (groupItem.key === 'bills' && this.props.unpaidOrdersCount) {
                                            titleSuffix = (
                                                <TooltipHost hostClassName="d-flex ml-2" content={myStrings.notifications.unpaidOrdersTooltip}>
                                                    <NotificationDot value={this.props.unpaidOrdersCount} />
                                                </TooltipHost>
                                            );
                                        } else if (groupItem.key === 'fields') {
                                            const fieldsErrors = getFieldsLicenseRestrictionsErrors(this.context);
                                            let count = 0;
                                            Object.values(fieldsErrors).forEach(val => {
                                                count += val.length;
                                            });

                                            if (count > 0) {
                                                titleSuffix = (
                                                    <TooltipHost hostClassName="d-flex ml-2" content={myStrings.notifications.fields}>
                                                        <NotificationDot value={count} />
                                                    </TooltipHost>
                                                );
                                            }
                                        } else if (groupItem.key === 'workflow') {
                                            const workflowErrors = getWorkflowLicenseRestrictionsErrors(this.context);
                                            let count = 0;
                                            Object.values(workflowErrors).forEach((val) => {
                                                count += val.length;
                                            });

                                            if (count > 0) {
                                                titleSuffix = (
                                                    <TooltipHost hostClassName="d-flex ml-2" content={myStrings.notifications.workflow}>
                                                        <NotificationDot value={count} />
                                                    </TooltipHost>
                                                );
                                            }
                                        }

                                        return (
                                            <GroupItem
                                                key={innerKey}
                                                menuExpanded={this.state.expanded}
                                                to={groupItem.to}
                                                onClick={(e) => {
                                                    if (licenseRestriction) {
                                                        e?.preventDefault();
                                                        this.context.showLicenseRestrictionModal(licenseRestriction);
                                                    }
                                                }}
                                            >
                                                {groupItem.title} {titleSuffix}
                                            </GroupItem>
                                        );
                                    })}
                                </Group>
                            );
                        } else {
                            if (!item.to) {
                                console.error('One of menu items is missing to attribute.');
                                return null;
                            }

                            return (
                                <MenuItem
                                    key={key}
                                    displayNotificationDot={item.key === 'updates' && this.props.isNewVersionAvailable}
                                    menuExpanded={this.state.expanded}
                                    exact={item.exact!}
                                    title={item.title}
                                    to={item.to}
                                    iconName={item.iconName}
                                />
                            );
                        }
                    })}
                </div>
            </div>
        );
    }
}