import Strings from '../../strings';
import { AdditionalFieldTypes } from './AdditionalFieldTypes';

export default class FieldTypes {
    static readonly textBox = 'TextBox';
    static readonly comboBox = 'ComboBox';
    static readonly numericBox = 'NumericBox';
    static readonly relation = 'Relation';
    static readonly checkBox = 'CheckBox';
    static readonly linkTextBox = 'LinkTextBox';
    static readonly dateEdit = 'DateEdit';
    static readonly memoBox = 'MemoBox';
    static readonly multiSelectComboBox = 'MultiSelectComboBox';
    static readonly workflowState = 'WorkflowState';
    static readonly image = 'Image';
    static readonly multiSelectRelation = 'MultiSelectRelation';

    static readonly getAfType = (fieldType: string) => {
        switch (fieldType) {
            case FieldTypes.textBox:
                return AdditionalFieldTypes.textBox;
            case FieldTypes.comboBox:
                return AdditionalFieldTypes.comboBox;
            case FieldTypes.numericBox:
                return AdditionalFieldTypes.numericBox;
            case FieldTypes.relation:
                return AdditionalFieldTypes.relation;
            case FieldTypes.checkBox:
                return AdditionalFieldTypes.checkBox;
            case FieldTypes.linkTextBox:
                return AdditionalFieldTypes.linkTextBox;
            case FieldTypes.dateEdit:
                return AdditionalFieldTypes.dateEdit;
            case FieldTypes.memoBox:
                return AdditionalFieldTypes.memoBox;
            case FieldTypes.multiSelectComboBox:
                return AdditionalFieldTypes.multiSelectComboBox;
            default:
                return null;
        }
    };

    static getLocalizedName = (type: string) => {
        switch (type) {
            case FieldTypes.textBox:
                return Strings.fieldTypes.textBox;
            case FieldTypes.comboBox:
                return Strings.fieldTypes.comboBox;
            case FieldTypes.numericBox:
                return Strings.fieldTypes.numericBox;
            case FieldTypes.relation:
                return Strings.fieldTypes.relation;
            case FieldTypes.checkBox:
                return Strings.fieldTypes.checkBox;
            case FieldTypes.linkTextBox:
                return Strings.fieldTypes.linkTextBox;
            case FieldTypes.dateEdit:
                return Strings.fieldTypes.dateEdit;
            case FieldTypes.memoBox:
                return Strings.fieldTypes.memoBox;
            case FieldTypes.multiSelectComboBox:
                return Strings.fieldTypes.multiSelectComboBox;
            case FieldTypes.workflowState:
                return Strings.fieldTypes.workflowState;
            case FieldTypes.image:
                return Strings.fieldTypes.image;
            case FieldTypes.multiSelectRelation:
                return Strings.fieldTypes.multiSelectRelation;
            default:
                return '';
        }
    };
}
