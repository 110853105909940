import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { mergeStyles, SpinButton } from '@fluentui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import OptionalComponentWrapper from '../../../helpers/components/OptionalComponentWrapper';
import UnitHelper from '../../../helpers/UnitHelper';
import Strings from '../../../strings';
import type { TPermissionsCellEditProps } from '../../shared/permissions/OrbDropdownEditCell';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import type { TModulePermissionsCellWrapper, TRowWithEffectiveModulePermissions } from './ModulePermissionsGrid';
import { MP_GRID_ROW_HEIGHT } from './ModulePermissionsGrid';

const ROWS_RESTRICTIONS_MAX = 5000;
const ROWS_RESTRICTIONS_DEFAULT_VALUE = 10;

const myStrings = Strings.components.routes.modulePermissions;

export const getRowsRestrictionsSuffix = (value: number) => {
    return UnitHelper.getSingularOrPluralByQuantity(value, {
        one: myStrings.options.rowRestrictionsSuffixSingular,
        twoToFour: myStrings.options.rowRestrictionsSuffixPluralTwo,
        fiveAndMore: myStrings.options.rowRestrictionsSuffixPluralFiveAndMore,
    });
};

const RowsRestrictionsEditCell: React.FC<Table.DataCellProps & TPermissionsCellEditProps<TRowWithEffectiveModulePermissions>> = ({ onValueChange, isEditable, cellWrapper, ...props }) => {
    const typedPropsValue = props.value as number;
    const typedRow = props.row as TModulePermissionWithFolder;

    const [lastValue, setLastValue] = useState(ROWS_RESTRICTIONS_DEFAULT_VALUE);
    const isRestricted = !typedPropsValue;

    const handleValueChange = React.useCallback(
        (newValue: number | null) => {
            onValueChange && onValueChange(props.tableRow.rowId as string, props.column.name, newValue);
        },
        [props.tableRow.rowId, props.column.name, onValueChange]
    );

    const onSpinButtonIncrement = () => {
        if (typedPropsValue + 1 <= ROWS_RESTRICTIONS_MAX) {
            handleValueChange(typedPropsValue + 1);
        } else {
            handleValueChange(1);
        }
    };

    const onSpinButtonDecrement = () => {
        if (typedPropsValue - 1 > 0) {
            handleValueChange(typedPropsValue - 1);
        } else if (typedPropsValue === 1) {
            handleValueChange(1);
        }
    };

    const onSpinButtonValidate = (value: string) => {
        const numberMatch = /^-?(\d+)/.exec(value);
        const newValue = Number(numberMatch?.[0]);
        if (newValue) {
            if (value.trim().length === 0 || newValue <= 0) {
                handleValueChange(1);
            } else if (newValue >= ROWS_RESTRICTIONS_MAX) {
                handleValueChange(ROWS_RESTRICTIONS_MAX);
            } else {
                handleValueChange(newValue);
            }
        } else {
            handleValueChange(1);
        }
    };

    const onToggle = () => {
        if (isRestricted) {
            handleValueChange(lastValue);
        } else {
            setLastValue(typedPropsValue);
            handleValueChange(null);
        }
    };

    const rowsRestrictionsSuffix = getRowsRestrictionsSuffix(typedPropsValue);
    const value = `${typedPropsValue ? typedPropsValue.toString() : ROWS_RESTRICTIONS_DEFAULT_VALUE} ${rowsRestrictionsSuffix}`;

    if (typedRow.IsSystem || !isEditable) {
        // System rows are not editable
        return (
            <td className="text-secondary" style={{ height: MP_GRID_ROW_HEIGHT }}>
                <OptionalComponentWrapper wrapperComponent={cellWrapper as TModulePermissionsCellWrapper} data={{ row: typedRow, column: props.column }}>
                    {isRestricted ? myStrings.options.noRestrictionsText : value}
                </OptionalComponentWrapper>
            </td>
        );
    }

    return (
        <td className={mergeStyles('d-flex align-items-center', !isRestricted && 'p-0')}>
            {isRestricted ? (
                <div className="d-flex" style={{ cursor: 'pointer' }} onClick={onToggle}>
                    <div>{myStrings.options.noRestrictionsText}</div>
                    <Button variant="link" className="p-0 ml-2 border-0 invisible d-parent-td-hover-visible">
                        <i className="mdl2 mdl2-edit" aria-hidden="true" />
                    </Button>
                </div>
            ) : (
                <>
                    <SpinButton
                        {...props}
                        min={1}
                        max={ROWS_RESTRICTIONS_MAX}
                        disabled={isRestricted}
                        className="ms-spinButton-container"
                        styles={{ root: { width: '6.25rem', padding: '0.4rem 0 0.4rem', marginLeft: '0.25rem' } }}
                        value={value}
                        onValidate={(v, e) => {
                            onSpinButtonValidate(v);
                        }}
                        onIncrement={onSpinButtonIncrement}
                        onDecrement={onSpinButtonDecrement}
                    />
                    <Button variant="link" onClick={onToggle} className="p-1 border-0 invisible d-parent-td-hover-visible">
                        <i className="mdl2 mdl2-cancel" aria-hidden="true" />
                    </Button>
                </>
            )}
        </td>
    );
};

export default RowsRestrictionsEditCell;
