import * as React from 'react';
import Strings from '../../../strings';
import { Form, Col, Button } from 'react-bootstrap';
import WizardSteps from '../../WizardSteps';
import StringHelper from '../../../helpers/StringHelper';
import { PasswordHelper } from '../../../helpers/PasswordHelper';
import FolderNames from '../../../data/constants/FolderNames';
import RelationTypes from '../../../data/constants/RelationTypes';
import WizardModal from '../../WizardModal';
import type { THiddenGroup, TDefaultGroupValue } from './UserWizardBase';
import { UserWizardBase } from './UserWizardBase';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IUserWizardBaseProps } from './IUserWizardBaseProps';
import type { IApiLocalizedLicenseBundle, IApiSaveResponse } from '@eway-crm/connector';
import { FieldNames } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import type { IApiUser } from '@eway-crm/connector';
import { v1 as uuidv1 } from 'uuid';
import { VirtualizedComboBox } from '@fluentui/react';
import PasswordInput from '../../shared/users/PasswordInput';
import type { IApiPasswordStrength } from '@eway-crm/connector';
import { GroupNames } from '../../../data/constants/GroupNames';
import ReactHelper from '../../../helpers/ReactHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { LicensePicker } from './LicensePicker';
import type { Optional } from '../../../data/typeHelpers/Optional';
import { SpinnerVariant, VersionHelper } from '@eway-crm/gui';
import type LicenseRestrictionsHelper from '../../../helpers/LicenseRestrictionsHelper';
import DisabledByPermissionBadge from '../../shared/DisabledByPermissionsBadge';
import WizardBase from '../../shared/WizardBase';

const dialogBodyExpectedHeight = '46rem';
const myStrings = Strings.components.routes.users;

type TNewUserWizardProps = IUserWizardBaseProps & {
    numberOfJumps: number;
    minimumPasswordLength: number;
    licenseRestrictionsHelper: LicenseRestrictionsHelper; // Pass as props so that we can use it in constructor without using deprecated legacy context API
};

type TNewUserState = {
    newGuid: string;
    firstName: string;
    lastName: string;
    username: string;
    supervisorGuid: string;
    email: string;
    assignedLicenses: IApiLocalizedLicenseBundle[];
    userCategoriesGuids: string[];
    isApiUser: boolean;
    autoGeneratePassword: boolean;
    password: string;
    passwordToCheck: string | null;
    passwordStrength: IApiPasswordStrength[] | null;
    sendTipsAndTricks: boolean;
    forceChangePassword: boolean;
    sendInstructions: boolean;
    groupsSearchedString: string;
    groupsSelection: string[];
    hiddenGroupsAssignment: THiddenGroup[];
    toggledGroups: string[];
    anyChangeMade: boolean;
    defaultGroup: TDefaultGroupValue;
    groupsUnassignedByDefaultGroupChange: string[] | null; // If null, the the admin have not checked assigned groups yet and that's why we don't track unassigned groups to warn them
};

type TNewUserWizardState = {
    currentJump: number;
    currentStep: number;
    currentJumpData: TNewUserState[];
    availableLicenses: IApiLocalizedLicenseBundle[];
    isGeneralFormValidated: boolean;
    isSavingInProgress: boolean;
    anyChangeMade: boolean;
};

export class NewUserWizard extends React.Component<TNewUserWizardProps, TNewUserWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly stepGeneral = 1;
    private readonly stepGroups = 2;
    private readonly stepSpecial = 3;
    private readonly wizardSteps = [myStrings.general, myStrings.assignGroups, myStrings.special];
    private lockedSteps = [];

    private readonly firstStep = this.stepGeneral;
    private lastStep = this.stepSpecial;

    private readonly generalFormReference: React.RefObject<HTMLFormElement>;

    constructor(props: TNewUserWizardProps) {
        super(props);
        this.generalFormReference = React.createRef();
        const userStates: TNewUserState[] = [];
        for (let i = 0; i < this.props.numberOfJumps; i++) {
            userStates.push({
                newGuid: uuidv1(),
                firstName: '',
                lastName: '',
                username: '',
                supervisorGuid: UserWizardBase.noSupervisorItemGUID,
                email: '',
                assignedLicenses: [],
                isApiUser: false,
                autoGeneratePassword: true,
                password: '',
                passwordToCheck: null,
                passwordStrength: null,
                sendTipsAndTricks: true,
                forceChangePassword: true,
                sendInstructions: true,
                groupsSearchedString: '',
                userCategoriesGuids: props.initialUserCategories.map(x => x.ItemGUID),
                groupsSelection: [],
                hiddenGroupsAssignment: this.props.hiddenGroups.map(hg => ({ guid: hg, isUserThere: true })),
                toggledGroups: [],
                anyChangeMade: false,
                defaultGroup: null,
                groupsUnassignedByDefaultGroupChange: null // Null means do not warn until the admin modifies groups elsewhere then by changing DF group
            });
        }

        const { newLicense, newAvailableLicenses } = UserWizardBase.takeBestAvailableLicense(props.availableLicenses);

        this.state = {
            currentJump: 1,
            currentStep: this.firstStep,
            currentJumpData: userStates,
            availableLicenses: newAvailableLicenses,
            isGeneralFormValidated: false,
            isSavingInProgress: false,
            anyChangeMade: false,
        };

        // Give first user best available licence
        if (newLicense) {
            this.state.currentJumpData[0].assignedLicenses = [newLicense];
        }

        // Lock other steps based on license restrictions
        if (props.licenseRestrictionsHelper.isUserRolesLocked().isUserRolesLocked) {
            this.lastStep = this.stepGeneral;
            this.lockedSteps = this.lockedSteps.filter((_, idx) => idx <= this.lastStep);
        }
    }

    private readonly getCurrentUserState = () => {
        return this.state.currentJumpData[this.state.currentJump - 1];
    };

    private readonly setCurrentUserState = (newState: Partial<TNewUserState>, callback?: () => void) => {
        const userStates = [...this.state.currentJumpData];
        userStates[this.state.currentJump - 1] = {
            ...userStates[this.state.currentJump - 1],
            ...newState,
            anyChangeMade: true
        };
        this.setState({ currentJumpData: userStates, anyChangeMade: true }, callback);
    };

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly back = () => {
        this.goToStep(this.state.currentStep - 1);
    };

    private readonly next = () => {
        this.goToStep(this.state.currentStep + 1);
    };

    private readonly goToStep = (wantedStep: number) => {
        const { isUserRolesLocked, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesLocked();
        if (isUserRolesLocked && wantedStep !== this.stepGeneral) {
            this.context.showLicenseRestrictionModal(userRolesLicenseRestriction);
            return;
        }

        const number = Math.max(this.firstStep, Math.min(this.lastStep, wantedStep));
        if (this.state.currentStep === number)
            return;

        for (let i = this.firstStep; i < number; i++) {
            if (!this.isStepValid(i, true)) {
                return;
            }
        }
        this.setState({
            currentStep: number,
            isGeneralFormValidated: false
        });
    };

    private readonly isStepValid = (number: number, goToIfInvalid: boolean) => {
        const handleInvalidStep = () => {
            this.setState({ isGeneralFormValidated: true }, () => {
                if (goToIfInvalid) {
                    this.goToStep(number);
                }
            });
        };

        if (number === this.stepGeneral) {
            const { isApiUser, email, username } = this.getCurrentUserState();
            if (
                (this.generalFormReference.current && this.generalFormReference.current.checkValidity() === false) ||
                (!this.getCurrentUserState().autoGeneratePassword && !PasswordHelper.areStrongEnough(this.getCurrentUserState().passwordStrength)) ||
                UserWizardBase.getIsUsernameInvalid(username, this.props.forbiddenUsernames)
            ) {
                handleInvalidStep();
                return false;
            }
            if (UserWizardBase.getIsEmailInvalid(email, this.props.forbiddenEmails, isApiUser)) {
                console.warn('Email is wrong.');
                handleInvalidStep();
                return false;
            }
            if (!this.isUsernameValid()) {
                console.warn('Username is invalid.');
                handleInvalidStep();
                return false;
            }
        }

        return true;
    };

    private readonly setName = (fName: string | undefined, lName: string | undefined) => {
        UserWizardBase.setName(fName, lName, this.getCurrentUserState(), (s) => {
            const hasUsernameChanged = s.username !== this.getCurrentUserState().username;
            this.setCurrentUserState(s, () => {
                if (s.username && hasUsernameChanged) {
                    this.checkPasswordStrengths(this.getCurrentUserState().password);
                }
            });
        });
    };

    private readonly changeUsername = (value: string) => {
        const username = UserWizardBase.normalizeUsername(value, true);
        const hasUsernameChanged = username !== this.getCurrentUserState().username;
        const authSettings = this.context.authSettings;
        const email = authSettings.isAzureAuth && this.getCurrentUserState().username === this.getCurrentUserState().email ? username : this.getCurrentUserState().email;

        this.setCurrentUserState({ username, email }, () => {
            const { password } = this.getCurrentUserState();
            if (hasUsernameChanged && !!password) {
                this.checkPasswordStrengths(password);
            }
        });
    };

    private readonly isUsernameValid = () => {
        const { username, isApiUser } = this.getCurrentUserState();

        if (!username)
            return false;

        if (username.length < UserWizardBase.usernameMinLength)
            return false;

        if (this.props.forbiddenUsernames.includes(username))
            return false;

        const authSettings = this.context.authSettings;
        if (authSettings.isAzureAuth) {
            if (UserWizardBase.getIsEmailInvalid(username, this.props.forbiddenEmails, isApiUser) || UserWizardBase.getIsUsernameInvalid(username, this.props.forbiddenUsernames)) {
                return false;
            }
        }

        for (let i = 0; i < (this.state.currentJump - 1); i++) {
            if (StringHelper.compareIgnoringCase(username, this.state.currentJumpData[i].username) === 0)
                return false;
        }

        return true;
    };

    private readonly changeGroupsAssignment = (selection: string[], isDetoggleOnly: boolean) => {
        const prevState = this.getCurrentUserState();
        const { newToggledGroups, newDefaultGroup, newGroupsUnassignedByDefaultGroupChange } = UserWizardBase.solveGroupChange(
            prevState.toggledGroups,
            prevState.groupsSelection,
            selection,
            isDetoggleOnly,
            prevState.defaultGroup,
            prevState.groupsUnassignedByDefaultGroupChange
        );

        this.setCurrentUserState({
            groupsSelection: selection,
            toggledGroups: newToggledGroups,
            defaultGroup: newDefaultGroup,
            groupsUnassignedByDefaultGroupChange: newGroupsUnassignedByDefaultGroupChange
        });
    };

    private readonly handleDefaultGroupGuidChange = (newUserDefaultGroupGuid: string) => {
        const prevState = this.getCurrentUserState();
        const { newGroupsSelection, newToggledGroups, newDefaultGroup, newGroupsUnassignedByDefaultGroupChange } = UserWizardBase.solveDefaultGroupGuidChange(
            prevState.toggledGroups,
            prevState.groupsSelection,
            prevState.defaultGroup,
            prevState.groupsUnassignedByDefaultGroupChange,
            newUserDefaultGroupGuid
        );
        this.setCurrentUserState({
            groupsSelection: newGroupsSelection,
            toggledGroups: newToggledGroups,
            defaultGroup: newDefaultGroup,
            groupsUnassignedByDefaultGroupChange: newGroupsUnassignedByDefaultGroupChange
        });
    };

    private readonly backJump = () => {
        const newJump = this.state.currentJump - 1;
        if (newJump < 1) {
            console.error('Unable to jump outside of the wizard.');
            return;
        }
        this.setState({
            currentJump: newJump,
            currentStep: this.firstStep,
            isGeneralFormValidated: false
        });
    };

    private nextJump = () => {
        const newJump = this.state.currentJump + 1;
        if (newJump > this.props.numberOfJumps) {
            console.error('Unable to jump outside of the wizard.');
            return;
        }

        this.setState((prevState) => {
            const newJumpData = [...prevState.currentJumpData];
            let newAvailableLicenses = prevState.availableLicenses;
            if (!newJumpData[newJump - 1].anyChangeMade) {
                // Start each jump with best currently available license
                const { newLicense, newAvailableLicenses: _newAvailableLicenses } = UserWizardBase.takeBestAvailableLicense(prevState.availableLicenses);
                newAvailableLicenses = _newAvailableLicenses;
                if (newLicense) {
                    newJumpData[newJump - 1].assignedLicenses = [newLicense];
                }
            }

            return {
                currentJump: newJump,
                currentJumpData: newJumpData,
                currentStep: this.firstStep,
                availableLicenses: newAvailableLicenses,
                isGeneralFormValidated: false,
            };
        });
    };

    /**
     * Check whether client uses database or external service to login
     */
    private readonly isDatabaseLoginEnabled = () => {
        const authSettings = this.context.authSettings;
        return !authSettings.isActiveDirectory && !authSettings.isAzureAuth;
    };

    private readonly submit = async () => {
        if (!this.isStepValid(this.state.currentStep, false)) {
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        const { askApi } = this.context.connection;
        const { askShoppingApi, askIsEnabled: askIsShoppingApiEnabled } = this.context.connection.getShoppingConnection();

        const passwordStrength = await this.getPasswordStrengths(this.getCurrentUserState().password, this.getCurrentUserState().username) ?? null;
        if (!this.getCurrentUserState().autoGeneratePassword && !PasswordHelper.areStrongEnough(passwordStrength)) {
            this.setCurrentUserState({ passwordStrength: passwordStrength });
            this.setState({ isSavingInProgress: false, isGeneralFormValidated: true });
        } else {
            type TUserToSave = Optional<Omit<IApiUser, 'Server_IsAccountLocked' | 'Server_LastLogin' | 'Server_LastActivity' | 'Server_ItemCreated' | 'Server_ItemChanged' | 'ItemCreated' | 'ItemChanged' | 'OwnerGUID' | 'CreatedByGUID' | 'ModifiedByGUID' | 'IsPrivate'>, 'Server_Password'> & { sendWelcomeEmail: boolean };
            const userItems: TUserToSave[] = this.state.currentJumpData.map((userState: TNewUserState) => {
                const userToSave: TUserToSave = {
                    ItemGUID: userState.newGuid,
                    ItemVersion: 1,
                    FileAs: undefined,
                    Username: userState.username,
                    FirstName: userState.firstName,
                    LastName: userState.lastName,
                    IsActive: true,
                    Email1Address: userState.email,
                    Email2Address: null,
                    IsSystem: false,
                    IsApiUser: userState.isApiUser,
                    Server_LicensingBundlesList: UserWizardBase.prepareAssignedLicensesForSubmit(userState.assignedLicenses),
                    Server_ForcePasswordChange: userState.forceChangePassword,
                    Users_SupervisorGuid: userState.supervisorGuid === UserWizardBase.noSupervisorItemGUID ? null : userState.supervisorGuid,
                    ProfilePicture: null,
                    ProfilePictureHeight: 0,
                    ProfilePictureWidth: 0,
                    sendWelcomeEmail: userState.sendInstructions
                };

                if (this.isDatabaseLoginEnabled()) {
                    userToSave.Server_Password = userState.autoGeneratePassword ? PasswordHelper.generate(Math.max(this.props.minimumPasswordLength + 2, 12)) : userState.password;
                }

                return userToSave;
            });

            if (userItems.length !== this.props.numberOfJumps) {
                console.error('Number of result users does not match the number of jumps');
                return;
            }

            await askApi<IApiSaveResponse>('SaveUsers', { transmitObjects: userItems });

            const groupRelations = this.state.currentJumpData.flatMap((userState) => {
                if (!userState.isApiUser) {
                    userState.groupsSelection.push(this.props.everyoneGroupGuid);
                }

                const groupsAndCategories = userState.groupsSelection.concat(userState.userCategoriesGuids);
                return groupsAndCategories.map((groupGuid) => ({
                    ItemGUID1: groupGuid,
                    ItemGUID2: userState.newGuid,
                    FolderName1: FolderNames.groups,
                    FolderName2: FolderNames.users,
                    RelationType: RelationTypes.group,
                    DifferDirection: false,
                }));
            });

            if (groupRelations.length !== 0) {
                await askApi<IApiSaveResponse>('SaveRelations', { transmitObjects: groupRelations });
            }

            // Now when the groups are assigned, the default group can be saved
            const userItemsWithDefaultGroups = this.state.currentJumpData
                .filter((userState: TNewUserState) => !!userState.defaultGroup)
                .map((userState: TNewUserState) => ({
                    ItemGUID: userState.newGuid,
                    Groups_Default_GroupGuid: userState.defaultGroup?.guid ?? null
                }));
            if (userItemsWithDefaultGroups.length !== 0) {
                await askApi<IApiSaveResponse>('SaveUsers', { transmitObjects: userItemsWithDefaultGroups });
            }

            const emailItems = userItems.filter((u) => u.sendWelcomeEmail && u.Email1Address);
            if (emailItems.length !== 0) {
                await askApi<IApiSaveResponse>('SendWelcomeEmails', {
                    transmitObjects: emailItems.map((user) => ({
                        UserTransmitObject: user,
                        MessageType: 'Welcome',
                        InvitedByName: this.context.connection.state.userItem?.FileAs, // Without invitation, the email does not contain the credentials
                        IsUsingMsLogin: !this.isDatabaseLoginEnabled(), // For welcome mail purposes AD or Azure means the same - no credentials
                        IsSelfServiceCustomer: false,
                        OutlookTypes: ["DesktopClassic"]
                    })),
                    webServiceAddress: this.context.connection.getApiConnection().wsUrl,
                });
            }

            const { isEnabled } = await askIsShoppingApiEnabled();
            if (isEnabled) {
                const tipsAndTricksEmailItems = this.state.currentJumpData
                    .filter((item) => item.sendTipsAndTricks && item.email)
                    .map((item) => ({ Email: item.email, FirstName: item.firstName, LastName: item.lastName }));

                if (tipsAndTricksEmailItems.length > 0) {
                    await askShoppingApi('RegisterEmployeesUsersEmails', {
                        transmitObjects: tipsAndTricksEmailItems,
                        signUpForTips: true,
                    });
                } else {
                    const hasAnyUserEmail = this.state.currentJumpData.some(item => !!item.email);
                    if (hasAnyUserEmail) {
                        await askShoppingApi('RegisterEmployeesUsersEmails', {
                            transmitObjects: null,
                            signUpForTips: false
                        });
                    }
                }
            }

            this.props.onDone();
        }
    };

    private readonly getFieldDisabledByPermissions = (fieldName: string) => {
        return WizardBase.getFieldDisabledByPermissions(fieldName, this.props.myColumnPermissionsMap);
    };

    private readonly checkPasswordStrengths = (password: string) => {
        if (!this.getCurrentUserState().passwordToCheck && !this.getCurrentUserState().autoGeneratePassword) {
            const passwordChecker = async () => {
                const passwordCurrentlyChecking = this.getCurrentUserState().passwordToCheck;
                const usernameCurrentlyChecking = this.getCurrentUserState().username;
                const passwordStrengths = await this.getPasswordStrengths(password, usernameCurrentlyChecking);
                this.setCurrentUserState({ passwordStrength: passwordStrengths ?? null, passwordToCheck: null }, () => {
                    const { password, username } = this.getCurrentUserState();
                    if ((password && passwordCurrentlyChecking !== password) || (username && usernameCurrentlyChecking !== username)) {
                        // If password or username changed during API call
                        this.checkPasswordStrengths(password);
                    }
                });
            };

            this.setCurrentUserState(
                { passwordToCheck: password },
                () => {
                    passwordChecker()
                        .catch((err) => console.error('Unable to check the password strength.', err));
                }
            );
        }
    };

    private readonly getPasswordStrengths = async (password: string, username: string) => {
        const passwordStrengths = await PasswordHelper.getPasswordStrengths(
            this.context.connection,
            password,
            [username]
        );
        return passwordStrengths;
    };

    private readonly onPasswordChange = (newValue: string) => {
        if (newValue) {
            this.setCurrentUserState({ password: newValue }, () => {
                this.checkPasswordStrengths(newValue);
            });
        } else {
            this.setCurrentUserState({ password: newValue, passwordStrength: null });
        }
    };

    private readonly checkIsPasswordInvalid = () => {
        const currentUserState = this.getCurrentUserState();
        return (
            (this.state.isGeneralFormValidated && PasswordHelper.areStrongEnough(currentUserState.passwordStrength)) ||
            Boolean(!currentUserState.autoGeneratePassword && !currentUserState.username && currentUserState.password)
        );
    };

    render() {
        const firstNameDisabledByPermissions = this.getFieldDisabledByPermissions("FirstName");
        const lastNameDisabledByPermissions = this.getFieldDisabledByPermissions("LastName");
        const supervisorDisabledByPermissions = this.getFieldDisabledByPermissions(FieldNames.Users.Supervisor);
        const emailDisabledByPermissions = this.getFieldDisabledByPermissions(FieldNames.Users.Email1Address);
        const authSettings = this.context.authSettings;

        const myStrings = Strings.components.routes.users;
        const isEmailInvalid = UserWizardBase.getIsEmailInvalid(this.getCurrentUserState().email, this.props.forbiddenEmails, this.getCurrentUserState().isApiUser);

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            const isApiUserWithoutEmail = this.getCurrentUserState().isApiUser && !this.getCurrentUserState().email;
            const usernamePattern = UserWizardBase.getUsernamePattern(
                this.getCurrentUserState().username,
                [
                    ...this.props.forbiddenUsernames,
                    ...(this.state.currentJumpData.slice(0, this.state.currentJump - 1).map(d => d.username))
                ]
            );

            return (
                <WizardModal show={true} size="xl" onHide={this.dismiss} hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)} showCancelButtonInFooter={true} dialogBodyExpectedHeight={dialogBodyExpectedHeight}>
                    <WizardModal.Title>
                        {myStrings.addNewUser}
                        {this.props.numberOfJumps > 1 &&
                            <>
                                {' '}{this.state.currentJump}/{this.props.numberOfJumps}
                            </>
                        }
                    </WizardModal.Title>
                    <WizardModal.Steps>
                        <WizardSteps steps={this.wizardSteps} currentStep={this.state.currentStep} onStepPicked={this.goToStep} lockedSteps={this.lockedSteps} />
                    </WizardModal.Steps>
                    <WizardModal.Body>
                        {this.state.currentStep === this.stepGeneral &&
                            <>
                                <h2 className="mb-5">{myStrings.general}</h2>
                                <Form ref={this.generalFormReference} validated={this.state.isGeneralFormValidated} className="form-application">
                                    <Form.Row>
                                        <Form.Group as={Col} sm={5} controlId="newUserFirstName">
                                            <Form.Label>{myStrings.firstName}<DisabledByPermissionBadge disabledByPermissions={firstNameDisabledByPermissions} /></Form.Label>
                                            <Form.Control type="text" disabled={firstNameDisabledByPermissions?.isDisabled} value={this.getCurrentUserState().firstName} onChange={(e) => this.setName(e.target.value, undefined)} />
                                        </Form.Group>
                                        <Form.Group as={Col} sm={7} controlId="newUserLastName">
                                            <Form.Label>{myStrings.lastName}<DisabledByPermissionBadge disabledByPermissions={lastNameDisabledByPermissions} /></Form.Label>
                                            <Form.Control type="text" disabled={lastNameDisabledByPermissions?.isDisabled} value={this.getCurrentUserState().lastName} onChange={(e) => this.setName(undefined, e.target.value)} />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        {/* Show MS Azure account email input instead of username when isAzureAuth */}
                                        {authSettings.isAzureAuth ? (
                                            <Form.Group as={Col} sm={5} controlId="newUserUsername">
                                                <Form.Label>{myStrings.msAzureAccount}<DisabledByPermissionBadge disabledByPermissions={emailDisabledByPermissions} /></Form.Label>
                                                <Form.Control
                                                    type="email"
                                                    value={this.getCurrentUserState().username}
                                                    required
                                                    onChange={(e) => this.changeUsername(e.target.value)}
                                                    disabled={emailDisabledByPermissions?.isDisabled}
                                                    isInvalid={
                                                        this.state.isGeneralFormValidated &&
                                                        (UserWizardBase.getIsEmailInvalid(this.getCurrentUserState().username, this.props.forbiddenEmails, this.getCurrentUserState().isApiUser) ||
                                                            UserWizardBase.getIsUsernameInvalid(this.getCurrentUserState().username, this.props.forbiddenUsernames))
                                                    }
                                                />
                                                <Form.Control.Feedback type="invalid">
                                                    {UserWizardBase.getInvalidAzureUsernameMessage(
                                                        this.getCurrentUserState().username,
                                                        this.props.forbiddenUsernames,
                                                        this.props.forbiddenEmails,
                                                        this.getCurrentUserState().isApiUser
                                                    )}
                                                </Form.Control.Feedback>
                                            </Form.Group>
                                        ) : (
                                            <Form.Group as={Col} sm={5} controlId="newUserUsername">
                                                <Form.Label>{myStrings.username}</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    value={this.getCurrentUserState().username}
                                                    onChange={(e) => this.changeUsername(e.target.value)}
                                                    required
                                                    pattern={usernamePattern}
                                                />
                                                <Form.Control.Feedback type="invalid">{UserWizardBase.getInvalidUsernameMessage(this.getCurrentUserState().username)}</Form.Control.Feedback>
                                            </Form.Group>
                                        )}
                                        <Form.Group as={Col} sm={7} controlId="newUserSupervisor">
                                            <Form.Label>{myStrings.supervisor}<DisabledByPermissionBadge disabledByPermissions={supervisorDisabledByPermissions} /></Form.Label>
                                            <VirtualizedComboBox
                                                selectedKey={this.getCurrentUserState().supervisorGuid}
                                                options={UserWizardBase.getSupervisorOptionsForDropdown(this.props.availableSupervisors.sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs)))}
                                                onChange={(e, o, i, v) => this.setCurrentUserState({ supervisorGuid: o!.key as string })}
                                                useComboBoxAsMenuWidth={true}
                                                disabled={supervisorDisabledByPermissions?.isDisabled}
                                            />
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} sm={5} controlId="newUserEmail">
                                            <Form.Label>{myStrings.email}<DisabledByPermissionBadge disabledByPermissions={emailDisabledByPermissions} /></Form.Label>
                                            <Form.Control
                                                type="email"
                                                required={!this.getCurrentUserState().isApiUser}
                                                value={this.getCurrentUserState().email}
                                                onChange={(e) => this.setCurrentUserState({ email: e.target.value })}
                                                isInvalid={this.state.isGeneralFormValidated && isEmailInvalid}
                                                disabled={emailDisabledByPermissions?.isDisabled}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {UserWizardBase.getInvalidEmailMessage(
                                                    this.getCurrentUserState().email,
                                                    UserWizardBase.getIsEmailForbidden(this.getCurrentUserState().email, this.props.forbiddenEmails)
                                                )}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                        <Form.Group as={Col} sm={7} controlId="newUserLicense">
                                            <Form.Label>{myStrings.ewayLicense}</Form.Label>
                                            <LicensePicker
                                                assignedLicenses={this.getCurrentUserState().assignedLicenses}
                                                availableLicenses={this.state.availableLicenses}
                                                isApiUser={this.getCurrentUserState().isApiUser}
                                                useComboBoxAsMenuWidth
                                                onLicenseChange={({ isApiUser, assignedLicenses, availableLicenses }) => {
                                                    this.setState({ availableLicenses }, () => {
                                                        this.setCurrentUserState({ isApiUser, assignedLicenses });
                                                    });
                                                }}
                                            />
                                        </Form.Group>
                                    </Form.Row>

                                    {this.isDatabaseLoginEnabled() &&
                                        <>
                                            <Form.Row className="mt-5">
                                                <Col>
                                                    <Form.Label className="mb-2">{myStrings.passwordSettings}</Form.Label>
                                                    <Form.Group controlId="newUserAutogeneratePassword" className="mb-2">
                                                        <Form.Check
                                                            type="radio"
                                                            label={myStrings.autogeneratePassword}
                                                            checked={this.getCurrentUserState().autoGeneratePassword}
                                                            onChange={(e: IFormCheckChangeEvent) => this.setCurrentUserState({ autoGeneratePassword: e.target.checked, sendInstructions: true })}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group controlId="newUserTypePassword">
                                                        <Form.Check
                                                            type="radio"
                                                            label={myStrings.letMeCreatePassword}
                                                            checked={!this.getCurrentUserState().autoGeneratePassword}
                                                            onChange={(e: IFormCheckChangeEvent) => this.setCurrentUserState({ autoGeneratePassword: !e.target.checked })}
                                                        />
                                                    </Form.Group>
                                                </Col>
                                            </Form.Row>
                                            <Form.Row>
                                                <PasswordInput
                                                    disabled={this.getCurrentUserState().autoGeneratePassword}
                                                    invalid={this.checkIsPasswordInvalid()}
                                                    minPasswordLength={this.props.minimumPasswordLength}
                                                    usernames={[this.getCurrentUserState().username]}
                                                    onChange={this.onPasswordChange}
                                                    passwordStrengths={this.getCurrentUserState().passwordStrength}
                                                    value={this.getCurrentUserState().password}
                                                />
                                            </Form.Row>
                                        </>
                                    }
                                    {(!this.props.licenseRestrictionsHelper.isUserRolesLocked().isUserRolesLocked && is810OrLater) &&
                                        <Form.Row>
                                            <div className="col-sm-5">
                                                <Form.Group controlId="newUserDefaultGroup">
                                                    <Form.Label>{myStrings.defaultGroup}</Form.Label>
                                                    <VirtualizedComboBox
                                                        selectedKey={this.getCurrentUserState().defaultGroup?.guid ?? UserWizardBase.noDefaultGroupItemGUID}
                                                        options={UserWizardBase.getDefaultGroupsOptionsForDropdown(this.props.availableGroups.sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName)))}
                                                        onChange={(_e, o, _i, _v) => this.handleDefaultGroupGuidChange(o!.key as string)}
                                                        useComboBoxAsMenuWidth={true}
                                                    />
                                                    {!!this.getCurrentUserState().groupsUnassignedByDefaultGroupChange && this.getCurrentUserState().groupsUnassignedByDefaultGroupChange.map(gudf => (
                                                        <div key={`gudf-${gudf}`} className="small">
                                                            {myStrings.userWillBeRemovedFromGroup} {this.props.availableGroups.find((ag) => ag.ItemGUID === gudf)?.FileAs}
                                                        </div>
                                                    ))}
                                                </Form.Group>
                                            </div>
                                        </Form.Row>
                                    }
                                    <Form.Row className="mt-3">
                                        <Col>
                                            {this.isDatabaseLoginEnabled() &&
                                                <Form.Group controlId="newUserForcePassword" className="mb-2">
                                                    <Form.Check
                                                        type="checkbox"
                                                        label={myStrings.forceChangePasswordOnLogin}
                                                        checked={this.getCurrentUserState().forceChangePassword}
                                                        onChange={(e: IFormCheckChangeEvent) => this.setCurrentUserState({ forceChangePassword: e.target.checked })}
                                                    />
                                                </Form.Group>
                                            }
                                            <Form.Group controlId="newUserSendMail" className="mb-2">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={authSettings.isAzureAuth ? myStrings.sendInstalationInstructions : `${myStrings.sendInstalationInstructions} (${myStrings.containsPassword})`}
                                                    checked={this.getCurrentUserState().sendInstructions && !isApiUserWithoutEmail}
                                                    disabled={isApiUserWithoutEmail}
                                                    onChange={(e: IFormCheckChangeEvent) => this.setCurrentUserState({ sendInstructions: e.target.checked })}
                                                />
                                            </Form.Group>
                                            <Form.Group controlId="newUserSendTips">
                                                <Form.Check
                                                    type="checkbox"
                                                    label={myStrings.sendTipsAndTricks}
                                                    checked={this.getCurrentUserState().sendTipsAndTricks && !isApiUserWithoutEmail}
                                                    disabled={isApiUserWithoutEmail}
                                                    onChange={(e: IFormCheckChangeEvent) => this.setCurrentUserState({ sendTipsAndTricks: e.target.checked })}
                                                />
                                            </Form.Group>
                                        </Col>
                                    </Form.Row>
                                </Form>
                            </>
                        }
                        {this.state.currentStep === this.stepGroups &&
                            <UserWizardBase.GroupsPicker
                                showHeading
                                showSearch
                                groupsSelection={this.getCurrentUserState().groupsSelection}
                                onGroupsSelectionChange={(sel) => { this.changeGroupsAssignment(sel, true); }}
                                groupsSearchedString={this.getCurrentUserState().groupsSearchedString}
                                onGroupsSearchedStringChange={(str: string) => { this.setCurrentUserState({ groupsSearchedString: str }); }}
                                availableGroups={this.props.availableGroups.filter((g) => g.GroupName !== GroupNames.systemHealthNotification)}
                                defaultGroupGuid={this.getCurrentUserState().defaultGroup?.guid}
                            />
                        }
                        {this.state.currentStep === this.stepSpecial &&
                            <>
                                <h2 className="mb-5">{myStrings.special}</h2>
                                <UserWizardBase.SpecialForm
                                    exchangeRatesAdminGroupGuid={this.props.exchangeRatesAdminGroupGuid}
                                    sendServerUpdateStatusGroupGuid={this.props.sendServerUpdateStatusGroupGuid}
                                    sendSystemHealthNotificationsGroupGuid={this.props.sendSystemHealthNotificationsGroupGuid}
                                    isSystemHealthNotificationDefaultGroupInGs={this.props.isSystemHealthNotificationDefaultGroupInGs}
                                    hiddenGroups={this.getCurrentUserState().hiddenGroupsAssignment}
                                    groupsSelection={this.getCurrentUserState().groupsSelection}
                                    onGroupsSelectionChange={(sel) => { this.changeGroupsAssignment(sel, false); }}
                                    isSystemUser={false}
                                    availableGroups={this.props.availableGroups}
                                    allGroups={this.props.allGroups}
                                    onUserCategoriesChange={(userCategoriesGuids) => this.setCurrentUserState({ userCategoriesGuids })}
                                    userCategoriesGuids={this.getCurrentUserState().userCategoriesGuids}
                                    toggledGroups={this.getCurrentUserState().toggledGroups}
                                    isCloudHosted={this.context.apiData.license.IsCloudHosted}
                                />
                            </>
                        }
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        {(this.state.currentJump > 1 && this.state.currentStep === this.firstStep) &&
                            <Button variant="outline-secondary" onClick={this.backJump}>{myStrings.previousUser}</Button>
                        }
                        {this.state.currentStep > this.firstStep &&
                            <Button variant="outline-secondary" onClick={this.back}>{Strings.back}</Button>
                        }
                        {this.state.currentStep < this.lastStep &&
                            <Button variant="secondary" onClick={this.next}>{Strings.next}</Button>
                        }
                        {(this.state.currentStep === this.lastStep && this.state.currentJump === this.props.numberOfJumps) &&
                            <Button variant="primary" onClick={() => void this.submit()}>
                                {this.props.numberOfJumps === 1 ? myStrings.createUser : myStrings.createUsers}
                            </Button>
                        }
                        {(this.state.currentStep === this.lastStep && this.state.currentJump < this.props.numberOfJumps) &&
                            <Button variant="primary" onClick={this.nextJump}>{myStrings.nextUser}</Button>
                        }
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}