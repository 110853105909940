import type { ITokenizer } from 'js-search';
import { StopWordsTokenizer, StopWordsMap } from 'js-search';
import Strings from '../../../../strings';
import { StopWordsMapCs } from './StopWordsMapCs';

/**
 * Modified StopWordsTokenizer from js-search library. Allows to change StopWordsMap based on language and adjusts behavior of @method tokenize.
 */
export class CustomStopWordsTokenizer extends StopWordsTokenizer {
    _tokenizer: ITokenizer;
    stopWordsMap: Record<string, boolean>;

    /**
     * Constructor.
     *
     * @param decoratedIndexStrategy Index strategy to be run after all stop words have been removed.
     */
    constructor(decoratedTokenizer: ITokenizer) {
        super(decoratedTokenizer);
        this._tokenizer = decoratedTokenizer;
        this.stopWordsMap = CustomStopWordsTokenizer.getStopWordsMap();
    }

    static getStopWordsMap(): Record<string, boolean> {
        return Strings.getLanguage() === 'en' ? (StopWordsMap as Record<string, boolean>) : StopWordsMapCs;
    }

    tokenize = (text: string): Array<string> => {
        return this._tokenizer.tokenize(text).filter((token, index, tokenizedText) => {
            // Use StopWordsTokenizer on all searched words exept for first one. This prevents not showing any results when only part of the first word is written.
            if (index === 0 && tokenizedText.length === 1) {
                return true;
            }
            return !this.stopWordsMap[token];
        });
    };
}
