import * as React from 'react';
import type { IShoppingDatumResponse } from '../../../data/shopping/IShoppingDatumResponse';
import { Elements } from '@stripe/react-stripe-js';
import type { Stripe } from '@stripe/stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import type { IStripeDataModel } from '../../../data/shopping/IStripeDataModel';
import type { ShoppingConnection } from '../../ShoppingConnection';
import type { TInputData } from '@eway-crm/connector';

type TStripeAddingModalState = {
    stripe: Stripe;
    clientSecret: string;
};

type TStripeLoadedInitData = {
    apiMethodName: 'GetStripeCustomerSetupModel' | 'GetStripeInvoiceInstantPayData' | 'GetStripeInvoiceInstantPayDataForPaymentMethod';
    data?: TInputData;
};

type TStripeStaticInitData = {
    clientSecret: string;
    publicApiKey: string;
};

export type TStripeInitData = TStripeStaticInitData | TStripeLoadedInitData;

type TUseStripeWithClientSecretProps = {
    initData: TStripeInitData;
    shoppingConnection: ShoppingConnection;
};

export const useStripeWithClientSecret = (props: TUseStripeWithClientSecretProps | null): TStripeAddingModalState | null => {
    const [state, setState] = React.useState<TStripeAddingModalState | null>(null);
    const { shoppingConnection, initData } = props || {};
    React.useEffect(() => {
        if (!state && !!shoppingConnection && !!initData && isTStripeStaticInitData(initData)) {
            (async () => {
                const stripe = await loadStripe(initData.publicApiKey);
                if (!stripe) {
                    console.error('Stripe was not loaded.');
                    return;
                }
                setState({ stripe: stripe, clientSecret: initData.clientSecret });
            })()
                .catch((err) => console.error('Unable to load stripe.', err));
        } else if (!state && !!shoppingConnection && !!initData && isTStripeLoadedInitData(initData)) {
            (async () => {
                const response = await shoppingConnection.askShoppingApi<IShoppingDatumResponse<IStripeDataModel>>(initData.apiMethodName, initData.data || {});
                const stripe = await loadStripe(response.Datum.PublicApiKey);
                if (!stripe) {
                    console.error('Stripe was not loaded.');
                    return;
                }
                setState({ stripe: stripe, clientSecret: response.Datum.ClientSecret });
            })()
                .catch((err) => console.error('Unable to load stripe.', err));
        }
    }, [state, setState, shoppingConnection, initData]);

    return state;
};

export const StripeClientSecretContext = React.createContext<string>(null!);

type TStripeElementsContainerProps = TUseStripeWithClientSecretProps & React.PropsWithChildren & {
    loadingComponent?: JSX.Element;
};

export const StripeElementsContainer: React.FC<TStripeElementsContainerProps> = (props) => {
    const state = useStripeWithClientSecret(props);

    if (!state) {
        if (props.loadingComponent)
            return props.loadingComponent;

        return null;
    }

    return (
        <Elements stripe={state.stripe}>
            <StripeClientSecretContext.Provider value={state.clientSecret}>
                {props.children}
            </StripeClientSecretContext.Provider>
        </Elements>
    );
};

const isTStripeStaticInitData = (initData: TStripeInitData): initData is TStripeStaticInitData => {
    return !!((initData as TStripeStaticInitData).clientSecret);
};

const isTStripeLoadedInitData = (initData: TStripeInitData): initData is TStripeLoadedInitData => {
    return !!((initData as TStripeLoadedInitData).apiMethodName);
};
