import type { IApiResult, ITokenizedApiResult, TInputData } from "@eway-crm/connector";
import type ApiConnection from "@eway-crm/connector";
import { HttpMethod } from "@eway-crm/connector";
import { TokenizedServiceConnection } from "@eway-crm/connector";
import type { IOrdersHistoryModel } from "../data/shopping/IOrdersHistoryModel";
import type { IShoppingDatumResponse } from "../data/shopping/IShoppingDatumResponse";
import LocalStorageHelper from "../helpers/LocalStorageHelper";
import Strings from "../strings";

type TAskIsEnabledResult = {
    isEnabled: false;
} | {
    isEnabled: true;
    url: string;
    token: string;
};

type TObtainResponse = { ShoppingServiceUrl: string | null; ShoppingToken: string | null } & IApiResult;
const obtainer = (result: TObtainResponse) => {
    return { url: result.ShoppingServiceUrl, token: result.ShoppingToken };
};

const generalErrorHandler = (error: Error) => {
    console.error(error);
    alert(Strings.components.connection.error);
};

export class ShoppingConnection {
    private readonly tokenizedConection: TokenizedServiceConnection<TObtainResponse>;

    constructor(connection: ApiConnection) {
        this.tokenizedConection = new TokenizedServiceConnection<TObtainResponse>(
            'ObtainShoppingApiAccessToken',
            HttpMethod.post,
            true,
            'InvalidShoppingToken',
            obtainer,
            connection,
            generalErrorHandler
        );
    }

    /**
     * Asynchronously determines whether the connection is active and usable.
     * @param enabledCallback Callback for positive result. Gets the service url and session token as parameters.
     * @param disabledCallback Callback for negative result.
     */
    readonly isShoppingEnabled = (enabledCallback: (url: string, token: string) => void, disabledCallback: () => void) => {
        this.tokenizedConection.isEnabled(enabledCallback, disabledCallback);
    };

    /**
     * Asynchronously determines whether the connection is active and usable.
     * If the result is positive, returns also the service url and session token as parameters.
     */
    readonly askIsEnabled = async (): Promise<TAskIsEnabledResult> => {
        return new Promise<TAskIsEnabledResult>((resolve) => {
            this.isShoppingEnabled(
                (url, token) => resolve({ isEnabled: true, url: url, token: token }),
                () => resolve({ isEnabled: false })
            );
        });
    };

    /**
     * Asynchronously calls the tokenized service.
     * The call uses the token which is was gained previously or uses isEnabled method to gain it before the call.
     * If the service is not enabled, invoikes the unsuccess callback.
     * @param methodName The called method name.
     * @param data The passed data.
     * @param successCallback Callback when everything is ok.
     * @param unsuccessCallback Callback for unsucess service response, network errors or disabled service.
     */
    readonly callShoppingApi = <TResult extends ITokenizedApiResult>(methodName: string, data: TInputData, successCallback: (data: TResult) => void, unsuccessCallback?: ((data: TResult | null) => void) | null) => {
        this.tokenizedConection.callTokenizedApi(methodName, data, successCallback, unsuccessCallback);
    };

    /**
     * Asynchronously calls the tokenized service.
     * The call uses the token which is was gained previously or uses isEnabled method to gain it before the call.
     * If the service is not enabled, rejectes the promise.
     * @param methodName The called method name.
     * @param data The passed data.
     * @param catchGeneralUnsuccess Determines whether a general error handler has to be used before the exception is propagated outside.
     */
    readonly askShoppingApi = async <TResult extends ITokenizedApiResult>(methodName: string, data: TInputData, catchGeneralUnsuccess: boolean = true): Promise<TResult> => {
        const promise = new Promise<TResult>((resolve, reject) => {
            this.callShoppingApi<TResult>(methodName, data, resolve, reject);
        });

        if (!catchGeneralUnsuccess)
            return promise;

        try {
            return await promise;
        } catch (err) {
            generalErrorHandler(err as Error);
            throw err;
        }
    };

    readonly loadOrdersHistory = async (shouldUseCachedData: boolean): Promise<IOrdersHistoryModel> => {
        const ordersHistoryCache = LocalStorageHelper.getItem<IOrdersHistoryModel>(LocalStorageHelper.names.ordersHistory);
        if (shouldUseCachedData && ordersHistoryCache) {
            return Promise.resolve(ordersHistoryCache);
        }

        const ordersHistoryResult = await this.askShoppingApi<IShoppingDatumResponse<IOrdersHistoryModel>>('GetOrdersHistory', {});
        LocalStorageHelper.setItem(LocalStorageHelper.names.ordersHistory, ordersHistoryResult.Datum, 1000 * 60 * 15); // 15 mins
        return ordersHistoryResult.Datum;
    };
}