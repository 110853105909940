import type React from 'react';
import { useEffect, useState } from 'react';

type LoaderProviderProps<TResult> = {
    render: React.FC<TResult>;
    loadData: (successCb: (result: TResult) => void) => Promise<void> | void;
};

const LoaderProvider = <TResult,>({ render, loadData }: React.PropsWithChildren<LoaderProviderProps<TResult>>) => {
    const [data, setData] = useState<TResult | null>(null);
    useEffect(() => {
        void loadData((result: TResult) => {
            setData(result);
        });
    }, [loadData]);

    if (!data) {
        return null;
    }

    return render(data);
};

export { LoaderProvider };