import type { IApiCustomizationStatsItem, TFolderName } from '@eway-crm/connector';
import { CustomizationStatsItemKeys } from '@eway-crm/connector';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import memoizeOne from 'memoize-one';
import type { ContextType} from 'react';
import React, { useContext } from 'react';
import type LicenseRestrictionsHelper from '../../../helpers/LicenseRestrictionsHelper';
import UnitHelper from '../../../helpers/UnitHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { RemoteItemStore } from '../../../RemoteItemStore';
import Strings from '../../../strings';

type TWorkflowLicenseRestrictionError = {
    title: React.ReactNode;
    stripMessage: React.ReactNode;
    tooltipMessage: React.ReactNode;
    stats: ReturnType<LicenseRestrictionsHelper['isFunctionalityCountExceeded']>;
};

export const getWorkflowLicenseRestrictionsErrors = memoizeOne((context: ContextType<typeof ConnectionContext>) => {
    const {
        licenseRestrictionsHelper,
        showLicenseRestrictionModal,
        apiData: { objectTypes },
    } = context;
    const myStrings = Strings.components.licenseRestrictions;
    const workflowErrors: { [folderName: string]: TWorkflowLicenseRestrictionError[] } = {};
    const allWorkflowFolders = RemoteItemStore.getDisplayableWorkflowFolders(objectTypes, licenseRestrictionsHelper).filter(
        (folderName) => !licenseRestrictionsHelper.isFolderNameLocked(folderName as TFolderName).isFolderNameLocked
    );

    const getVisibleFoldersStats = (stats: IApiCustomizationStatsItem[]) => stats.filter((stat) => stat.FolderName && allWorkflowFolders.includes(stat.FolderName));

    const pushToObject = (folderName: string, value: TWorkflowLicenseRestrictionError) => {
        if (workflowErrors[folderName]) {
            workflowErrors[folderName].push(value);
        } else {
            workflowErrors[folderName] = [value];
        }
    };

    const customTypesCountStats = licenseRestrictionsHelper.customizationStats.filter((cs) => cs.Key === CustomizationStatsItemKeys.customVisibleTypesCount);
    const visibleFoldersCustomTypesCountStats = getVisibleFoldersStats(customTypesCountStats);

    visibleFoldersCustomTypesCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const itemTypesStats = licenseRestrictionsHelper.isItemTypesCountExceeded(stat.FolderName);
        if (itemTypesStats.isLimitReached && itemTypesStats.isCountExceeded) {
            const title = Strings.formatString(myStrings.workflowCountExceededTitle, myStrings.activeWfPluralFiveAndMore);
            const stripMessage = (
                <span key="itemTypesCount">
                    {Strings.formatString(myStrings.workflowCountExceededAlert, myStrings.activeWfPluralFiveAndMore, itemTypesStats.exceededCount.toString())}
                    <Link as={'a'} onClick={() => showLicenseRestrictionModal(itemTypesStats.licenseRestriction)}>
                        {myStrings.workflowAlertUpgradeYourPlan}
                    </Link>
                    .
                </span>
            );
            const currentLimit = itemTypesStats.licenseRestriction.currentLimit ?? 0;
            const itemTypesString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                one: myStrings.activeWfSingular,
                twoToFour: myStrings.activeWfPluralTwoToFour,
                fiveAndMore: myStrings.activeWfPluralFiveAndMore,
            });
            const tooltipMessage = <span>{Strings.formatString(myStrings.workflowCountExceeded, currentLimit.toString(), itemTypesString, itemTypesStats.currentCount.toString())}</span>;

            pushToObject(stat.FolderName, {
                title,
                stripMessage,
                tooltipMessage,
                stats: itemTypesStats,
            });
        }
    });

    const basicWorkflowCountStats = licenseRestrictionsHelper.customizationStats.filter((cs) => cs.Key === CustomizationStatsItemKeys.customEnabledBasicWorkflowsCount);
    const visibleFoldersBasicWorkflowCountStats = getVisibleFoldersStats(basicWorkflowCountStats);

    visibleFoldersBasicWorkflowCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const basicWorkflowCountStats = licenseRestrictionsHelper.isBasicWorkflowCountExceeded(stat.FolderName);
        if (basicWorkflowCountStats.isLimitReached && basicWorkflowCountStats.isCountExceeded) {
            const title = Strings.formatString(myStrings.workflowCountExceededTitle, myStrings.basicWfPluralFiveAndMore);
            const stripMessage = (
                <span key="basicWorkflowCount">
                    {Strings.formatString(myStrings.workflowCountExceededAlert, myStrings.basicWfPluralFiveAndMore, basicWorkflowCountStats.exceededCount.toString())}
                    <Link as={'a'} onClick={() => showLicenseRestrictionModal(basicWorkflowCountStats.licenseRestriction)}>
                        {myStrings.workflowAlertUpgradeYourPlan}
                    </Link>
                    .
                </span>
            );

            const currentLimit = basicWorkflowCountStats.licenseRestriction.currentLimit ?? 0;
            const basicWfString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                one: myStrings.basicWfPluralSingular,
                twoToFour: myStrings.basicWfPluralTwoToFour,
                fiveAndMore: myStrings.basicWfPluralFiveAndMore,
            });
            const tooltipMessage = (
                <span>
                    {Strings.formatString(
                        myStrings.workflowCountExceeded,
                        currentLimit.toString(),
                        basicWfString,
                        basicWorkflowCountStats.currentCount.toString()
                    )}
                </span>
            );

            pushToObject(stat.FolderName, {
                title,
                stripMessage,
                tooltipMessage,
                stats: basicWorkflowCountStats,
            });
        }
    });

    const advancedWorkflowCountStats = licenseRestrictionsHelper.customizationStats.filter((cs) => cs.Key === CustomizationStatsItemKeys.customEnabledAdvancedWorkflowsCount);
    const visibleFoldersAdvancedWorkflowCountStats = getVisibleFoldersStats(advancedWorkflowCountStats);
    visibleFoldersAdvancedWorkflowCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const advancedWorkflowCountStats = licenseRestrictionsHelper.isAdvancedWorkflowCountExceeded(stat.FolderName);
        if (advancedWorkflowCountStats.isLimitReached && advancedWorkflowCountStats.isCountExceeded) {
            let title = Strings.formatString(myStrings.workflowCountExceededTitle, myStrings.advancedWfPluralFiveAndMore);
            const stripMessage = (
                <span key="advancedWorkflowCount">
                    {Strings.formatString(myStrings.workflowCountExceededAlert, myStrings.advancedWfPluralFiveAndMore, advancedWorkflowCountStats.exceededCount.toString())}
                    <Link as={'a'} onClick={() => showLicenseRestrictionModal(advancedWorkflowCountStats.licenseRestriction)}>
                        {myStrings.workflowAlertUpgradeYourPlan}
                    </Link>
                    .
                </span>
            );

            const currentLimit = advancedWorkflowCountStats.licenseRestriction.currentLimit ?? 0;
            const advancedWorkflowString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                one: myStrings.advancedWfSingular,
                twoToFour: myStrings.advancedWfPluralTwoToFour,
                fiveAndMore: myStrings.advancedWfPluralFiveAndMore,
            });
            let tooltipMessage = (
                <span>
                    {Strings.formatString(
                        myStrings.workflowCountExceeded,
                        currentLimit.toString(),
                        advancedWorkflowString,
                        advancedWorkflowCountStats.currentCount.toString()
                    )}
                </span>
            );


            if (!currentLimit) {
                title = myStrings.workflowAdvancedNotAvailable;
                tooltipMessage = (
                    <span>
                        {Strings.formatString(
                            myStrings.workflowCountNotAvailable,
                            myStrings.advancedWfPluralTwoToFour,
                            advancedWorkflowCountStats.currentCount.toString()
                        )}
                    </span>
                );
            }

            pushToObject(stat.FolderName, { title, stripMessage, tooltipMessage, stats: advancedWorkflowCountStats });
        }
    });

    return workflowErrors;
});

type TWorkflowLicenseRestrictionErrorsProps = {
    folderName: string;
};

const WorkflowLicenseRestrictionsErrors: React.FC<TWorkflowLicenseRestrictionErrorsProps> = ({ folderName }) => {
    const context = useContext(ConnectionContext);
    const workflowLicenseRestrictionErrors = getWorkflowLicenseRestrictionsErrors(context);
    const workflowErrors = workflowLicenseRestrictionErrors[folderName];

    if (!workflowErrors) {
        return null;
    }

    return (
        <>
            {workflowErrors.map((wferror, idx) => (
                <MessageBar isMultiline={false} messageBarType={MessageBarType.severeWarning} truncated key={idx}>
                    {wferror.stripMessage}
                </MessageBar>
            ))}
        </>
    );
};

export default WorkflowLicenseRestrictionsErrors;
