import Strings from '../../strings';
import GoogleAnalyticsKeys from './GoogleAnalyticsKeys';

export default class ExternalLinks {
    static readonly oldAdminApp = 'eway://AdminApp';
    static readonly oldAdminAppWorkflow = 'eway://AdminApp/WorkflowDiagrams';
    static readonly zapier = 'https://eway.cr/BsNV2';
    static readonly powerBIApp = 'https://eway.cr/YgFWJ';
    static readonly searchExternalLinksCs = 'https://static.eway-crm.com/admin/externalLinks_cs.json';
    static readonly searchExternalLinksEn = 'https://static.eway-crm.com/admin/externalLinks_en.json';
    static readonly getKbLink = (urlShortCode: string) =>
        `https://eway.cr/${urlShortCode}?set_language=${Strings.getLanguage()}&utm_source=${GoogleAnalyticsKeys.source}&utm_medium=${GoogleAnalyticsKeys.medium.helpButton}&utm_campaign=${GoogleAnalyticsKeys.campaign.features}`;
}
