import * as React from 'react';
import { FontIcon } from '@fluentui/react';
import { Button } from 'react-bootstrap';
import Strings from '../../../strings';

const myStrings = Strings.components.routes.home;

type TVariant = 'more' | 'less';

type TMoreLessButtonProps = {
    variant: TVariant;
    onClick: () => void;
};

export class MoreLessButton extends React.Component<TMoreLessButtonProps> {

    private readonly handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
        this.props.onClick();
        (e.target as HTMLElement).blur();
    };

    render() {
        return (
            <Button variant="link" onClick={this.handleClick} className="align-middle"><FontIcon iconName={this.props.variant === 'more' ? 'ChevronDown' : 'ChevronUp'} className="pr-2" />{this.props.variant === 'more' ? myStrings.more : myStrings.less}</Button>
        );
    }
}