import { useCallback, useRef } from 'react';

type TUseSingleAndDoubleClickProps = {
    onClick: (e: React.MouseEvent) => void;
    onDoubleClick: (e: React.MouseEvent) => void;
    fireSingleClickImmediatelly?: boolean;
    delay?: number;
};

/**
 * Delay firing single click until we confirm that the click is not double click.
 * Inspired by https://medium.com/@kevin.dalman/the-20-line-double-click-handling-hook-i-created-for-this-purpose-its-simple-to-use-53b4d74a835e
 * @param delay time to wait for checking if the click is single or double click
 * @param fireSingleClickImmediatelly optionally do not delay single click
 */

const useSingleAndDoubleClick = ({ onClick, onDoubleClick, fireSingleClickImmediatelly, delay = 250 }: TUseSingleAndDoubleClickProps) => {
    const timer = useRef<number | null>(null);

    const cancelPendingClick = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
            timer.current = null;
        }
    }, [timer]);

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            // We only cache the most recent click event, so cancel any pending clicks
            cancelPendingClick();

            if (fireSingleClickImmediatelly) {
                onClick(e);
            } else {
                timer.current = window.setTimeout(() => {
                    timer.current = null;
                    onClick(e);
                }, delay);
            }
        },
        [timer, cancelPendingClick, onClick, fireSingleClickImmediatelly, delay]
    );

    const handleDoubleClick = useCallback(
        (e: React.MouseEvent) => {
            cancelPendingClick();
            onDoubleClick(e);
        },
        [cancelPendingClick, onDoubleClick]
    );

    return { handleClick, handleDoubleClick };
};

export default useSingleAndDoubleClick;
