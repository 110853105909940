import * as React from 'react';
import Strings from '../../../strings';
import { Modal, Button } from 'react-bootstrap';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TInvoiceViewerProps = {
    src: string;
    invoiceId: string;
};

type TInvoiceViewerState = {
    isShown: boolean;
    isIframeLoaded: boolean;
};

const myStrings = Strings.components.routes.purchaseHistory;

export class InvoiceViewer extends React.Component<TInvoiceViewerProps, TInvoiceViewerState> {

    constructor(props: TInvoiceViewerProps) {
        super(props);
        this.state = {
            isShown: false,
            isIframeLoaded: false
        };
    }

    private readonly handleShow = () => {
        this.setState({ isShown: true });
    };

    private readonly handleClose = () => {
        this.setState({ isShown: false });
    };

    private readonly handleIframeLoaded = () => {
        this.setState({
            isIframeLoaded: true
        });
    };

    render() {
        const src = this.props.src;

        const iframeName = 'invoiceIframe-' + this.props.invoiceId;
        const print = () => {
            const frame = window.frames[iframeName as keyof Window] as Window;
            frame.focus();
            frame.print();
        };
        const open = () => {
            window.open(src, '_blank');
        };
        return (
            <>
                <Button variant="link" className="p-0 border-0 align-baseline" onClick={this.handleShow}>{myStrings.invoiceOnline}</Button>
                <Modal show={this.state.isShown} onHide={this.handleClose} size="lg" dialogClassName="h-lg">
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <iframe src={'iframe.html?src=' + encodeURIComponent(src)} title={myStrings.invoice} className="w-100 vh-75 border-0" id={iframeName} name={iframeName} onLoad={this.handleIframeLoaded} />
                        {(!this.state.isIframeLoaded) &&
                            <Spinner variant={SpinnerVariant.ease} />
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={open}>
                            {myStrings.open}
                        </Button>
                        <Button variant="primary" onClick={print}>
                            {myStrings.print}
                        </Button>
                    </Modal.Footer>
                </Modal>
            </>
        );
    }
}