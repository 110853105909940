import Strings from "../../strings";

export class LinkTypes {
    static readonly http = 'Http';
    static readonly email = 'Email';
    static readonly file = 'File';
    static readonly custom = 'Custom';
    static readonly none = 'None';

    static readonly all = [LinkTypes.custom, LinkTypes.email, LinkTypes.file, LinkTypes.none, LinkTypes.http];

    static getLocalizedName(linkType: string): string {
        switch (linkType) {
            case LinkTypes.http:
                return Strings.linkTypes.http;
            case LinkTypes.email:
                return Strings.linkTypes.email;
            case LinkTypes.file:
                return Strings.linkTypes.file;
            case LinkTypes.none:
                return Strings.linkTypes.none;
            case LinkTypes.custom:
                return '-';
            default:
                return '';
        }
    }
}