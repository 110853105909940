import React, { useContext } from 'react';
import { autosuggestContext } from './AutosuggestContext';
import './Autosuggest.scss';
import { mergeStyles } from '@fluentui/react';

type TAutosuggestInputProps = React.HTMLProps<HTMLInputElement>;

const AutosuggestInput: React.FC<TAutosuggestInputProps> = ({ className, ...props }) => {
    const { value, onValueChange, setIsInputFocused, inputRef, disabled } = useContext(autosuggestContext);
    return (
        <input
            className={mergeStyles("autosuggest__input", className)}
            value={value}
            onChange={(e) => {
                onValueChange(e.target.value ?? '');
            }}
            onFocus={() => setIsInputFocused(true)}
            spellCheck={false}
            ref={inputRef}
            disabled={disabled}
            {...props}
        />
    );
};

export default AutosuggestInput;
