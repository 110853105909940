import * as React from 'react';
import getPrice from '../../../getPrice';
import type TCheckoutItem from '../../../TCheckoutItem';
import StoreV2Context from '../../StoreV2Context';

const useTotalPrices = (items: TCheckoutItem[]) => {
    const { customerInfo, isPackageInCart, selectedPeriodType } = React.useContext(StoreV2Context);
    return React.useMemo(() => {
        const getItemPrice = (item: TCheckoutItem): number => item.quantity * getPrice(item.packageData, item.quantity, customerInfo.currency, selectedPeriodType, isPackageInCart);
        return {
            totalPriceWithoutTaxes: items.map(getItemPrice).sum(),
            totalVat: customerInfo.willVatBeCharged ? items.map(item => getItemPrice(item) * (item.packageData.PriceVat || 0)).sum() : 0
        };
    }, [items, customerInfo.currency, customerInfo.willVatBeCharged, selectedPeriodType, isPackageInCart]);
};

export default useTotalPrices;