import type { IApiEnumType, IApiEnumValue } from '@eway-crm/connector';
import { Link, mergeStyles } from '@fluentui/react';
import React, { useContext, useMemo } from 'react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import FieldsLicenseRestrictionHelper from './FieldsLicenseRestrictionHelper';

type TFieldLicenseRestriction = React.HTMLProps<HTMLDivElement> & {
    enumValues: Partial<IApiEnumValue>[];
    columnName: string;
    enumType: IApiEnumType | null;
};

const FieldLicenseRestriction: React.FC<TFieldLicenseRestriction> = ({ enumValues, className, columnName, enumType, ...restProps }) => {
    const { licenseRestrictionsHelper, showLicenseRestrictionModal } = useContext(ConnectionContext);
    const myStrings = Strings.components.licenseRestrictions;

    const licenseRestriction = licenseRestrictionsHelper.getFieldLicenseRestriction(columnName, enumType?.EnumName);
    const limit = licenseRestriction?.currentLimit;
    const isVisibleCount = useMemo(() => enumValues.filter((ev) => ev.IsVisible && !ev.IsSystem).length, [enumValues]);

    if (!licenseRestriction || !limit) {
        return null;
    }

    const itemsAvailable = limit - isVisibleCount;
    const isExceeded = itemsAvailable <= 0;
    const isNearExceeding = (isVisibleCount / limit) >= FieldsLicenseRestrictionHelper.NEAR_LIMIT_WARNING_PERCENTAGE;

    const getHeadingColor = () => {
        if (isExceeded) {
            return 'var(--danger)';
        } else if (isNearExceeding) {
            return 'var(--warning)';
        }
        return 'var(--success)';
    };

    return (
        <div className={mergeStyles('d-flex flex-column align-items-end pb-3 justify-content-end', className)} {...restProps}>
            <h3 className="font-weight-600" style={{color: getHeadingColor(), fontSize: "1.25rem", marginBottom: "7px" }}>
                {myStrings.itemsAvailable}: {isExceeded ? 0 : itemsAvailable}
            </h3>
            <div className="text-medium">{myStrings.needMore} <Link onClick={() => showLicenseRestrictionModal(licenseRestriction)}>{myStrings.upgradeYourPlan}</Link></div>
        </div>
    );
};

export default FieldLicenseRestriction;
