const SAVE_DELAY = 2500;

/**
 * Acts as a store for items (i.e. Module or Field Permissions), which don't need to affect React component lifecycle.
 */
export class ItemStorage<T> {
    protected itemsToSave: T[];
    protected getItemId: (item: T) => string | number;

    constructor(getItemId: (item: T) => string | number, initialItemsToSave?: T[]) {
        this.itemsToSave = initialItemsToSave ?? [];
        this.getItemId = getItemId;
    }

    public readonly getItems = () => {
        return this.itemsToSave;
    };

    public readonly takeItems = () => {
        const itemsToSave = [...this.itemsToSave];
        this.itemsToSave = [];
        return itemsToSave;
    };

    public readonly clearItems = () => {
        this.itemsToSave = [];
        return this.itemsToSave;
    };

    /** Adds item to store without any other action */
    public readonly addItem = (item: T) => {
        const idxInItemsToSave = this.itemsToSave.findIndex((itemToSave) => this.getItemId(itemToSave) === this.getItemId(item));
        if (idxInItemsToSave === -1) {
            this.itemsToSave = [...this.itemsToSave, item];
        } else {
            this.itemsToSave[idxInItemsToSave] = item;
        }
        return this.itemsToSave;
    };
}

/**
 * Stores items (i.e. Module or Field Permissions) and saves them after a delay via @param saveFn.
 */
export default class ItemStorageWithDelayedSave<T> extends ItemStorage<T> {
    private saveTimeoutId: number | null;
    private itemsCurrentlySaving: T[];
    private onItemsCountUpdated: (itemsToSaveCount: number) => void;
    private saveFn: (itemsToSave: T[], saveSuccessCb: () => void) => void;

    constructor(getItemId: (item: T) => string | number, onItemsCountUpdated: (itemsToSaveCount: number) => void, saveFn: (itemsToSave: T[], saveSuccessCb: () => void) => void) {
        super(getItemId);
        this.saveTimeoutId = null;
        this.itemsCurrentlySaving = [];
        this.onItemsCountUpdated = onItemsCountUpdated;
        this.saveFn = saveFn;
    }

    public readonly getItemsCount = () => {
        return this.itemsToSave.length + this.itemsCurrentlySaving.length;
    };

    /** Adds item to store and starts delayed save */
    public readonly saveItem = (item: T) => {
        this.saveTimeoutId && window.clearTimeout(this.saveTimeoutId);

        this.addItem(item);
        this.onItemsCountUpdated(this.getItemsCount());

        if (this.itemsToSave.length > 0) {
            this.saveTimeoutId = window.setTimeout(() => {
                this.itemsCurrentlySaving = [...this.itemsCurrentlySaving, ...this.itemsToSave];
                this.itemsToSave = [];
                const savingItemIdentifiers = this.itemsCurrentlySaving.map((item) => this.getItemId(item));

                this.saveFn(this.itemsCurrentlySaving, () => {
                    this.itemsCurrentlySaving = this.itemsCurrentlySaving.filter((item) => !savingItemIdentifiers.includes(this.getItemId(item)));
                    this.onItemsCountUpdated(this.getItemsCount());
                });
            }, SAVE_DELAY);
        }
    };
}
