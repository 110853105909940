import * as React from 'react';
import Strings from '../../strings';
import { Form } from 'react-bootstrap';
import Cookies from '../../Cookies';
import { SecondaryPanel } from '../layout/SecondaryPanel';
import type { IApiDataResponse } from '@eway-crm/connector';
import splashImg from '../../img/splash/key-splash.svg';
import type { match as IRouterMatch, RouteComponentProps } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import RouteConfig from '../../RouteConfig';
import type { TApiItemWithReleations } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import GroupsListMenu from '../shared/GroupsListMenu';
import { validate as uuidValidate } from 'uuid';
import { FolderModulePermissions } from './modulePermissions/FolderModulePermissions';
import type { IApiModulePermission } from '@eway-crm/connector';
import FolderNames from '../../data/constants/FolderNames';
import ModulePermissionNames from '../../data/constants/ModulePermissionNames';
import memoizeOne from 'memoize-one';
import FolderSplash from '../layout/FolderSplash';
import { RemoteItemStore } from '../../RemoteItemStore';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.modulePermissions;

export type TModulePermissionWithFolder = IApiModulePermission & { Folder: string };

type TModulePermissionsProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TModulePermissionsState = {
    groups: IApiGroup[] | null;
    modulePermissions: TModulePermissionWithFolder[] | null;
    areDataLoaded: boolean;
    menuSearchedString: string;
};

export default class ModulePermissions extends React.Component<TModulePermissionsProps, TModulePermissionsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TModulePermissionsProps) {
        super(props);
        this.state = {
            groups: null,
            modulePermissions: null,
            areDataLoaded: false,
            menuSearchedString: '',
        };
    }

    componentDidMount() {
        this.reload();
    }

    static readonly changeModulePermission = (mp: TModulePermissionWithFolder, column: string, newValue: string | number | boolean | null) => {
        let updatedModulePermission = { ...mp, [column]: newValue };

        if (column === ModulePermissionNames.Columns.View && newValue === ModulePermissionNames.Options.None) {
            updatedModulePermission = {...updatedModulePermission, [ModulePermissionNames.Columns.CanCreate]: false};
        } else if (column === ModulePermissionNames.Columns.CanCreate && newValue === true && mp.View === ModulePermissionNames.Options.None) {
            updatedModulePermission = {...updatedModulePermission, [ModulePermissionNames.Columns.View]: ModulePermissionNames.Options.Own};
        }

        // User must be able to edit items he creates
        if (column === ModulePermissionNames.Columns.CanCreate && newValue === true && mp.Edit === ModulePermissionNames.Options.None) {
            updatedModulePermission = {...updatedModulePermission, [ModulePermissionNames.Columns.Edit]: ModulePermissionNames.Options.Own}; 
        } else if (column === ModulePermissionNames.Columns.Edit && newValue === ModulePermissionNames.Options.None && mp.CanCreate === true) {
            updatedModulePermission = {...updatedModulePermission, [ModulePermissionNames.Columns.CanCreate]: false};
        }

        return updatedModulePermission;
    };

    private readonly reload = () => {
        this.setState(
            {
                areDataLoaded: false,
                groups: [],
                modulePermissions: [],
            },
            () => {
                this.context.connection.callApi('GetGroups', {}, (grpResult: IApiDataResponse<TApiItemWithReleations<IApiGroup>>) => {
                    this.context.connection.callApi('GetModulePermissions', { includeDefaultPermissions: true }, (modulePermissionsResult: IApiDataResponse<IApiModulePermission>) => {
                        const { objectTypes } = this.context.apiData;
                        const otFolders = RemoteItemStore.getDisplayableModulePermissionFolders(objectTypes, this.context.licenseRestrictionsHelper);
                        const modulePermissions = modulePermissionsResult.Data.filter((mp) => otFolders.includes(mp.FolderName)).map((mp) => ({
                            ...mp,
                            Folder: FolderNames.getPluralName(mp.FolderName),
                        }));
                        this.setState({
                            areDataLoaded: true,
                            groups: RemoteItemStore.getDisplayableGroups(grpResult.Data),
                            modulePermissions,
                        });
                    });
                });
            }
        );
    };

    private readonly getCurrentItemId = () => {
        const match: IRouterMatch<{ groupGuid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.modulePermissions.pathWithGuid });
        const itemId = match?.params?.groupGuid;
        if (itemId && uuidValidate(itemId)) {
            return itemId;
        } else return null;
    };

    private readonly SecondaryPanelHead: React.FC = () => (
        <Form.Control type="text" placeholder={Strings.search} value={this.state.menuSearchedString} onChange={(e) => this.setState({ menuSearchedString: e.target.value })} />
    );

    private readonly SecondaryPanelBody: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => (
        <GroupsListMenu
            items={this.state.groups!.map((g) => ({ itemId: g.ItemGUID, itemName: g.GroupName, itemDescription: g.ResponsibilityDescription }))}
            selectedItemId={this.getCurrentItemId()}
            searchedString={this.state.menuSearchedString}
            isCollapsed={isCollapsed}
            history={this.props.history}
            path={RouteConfig.modulePermissions.path}
        />
    );

    private readonly getSelectedGroup = memoizeOne((selectedItemId: string, groups: IApiGroup[]) => groups.find((g) => g.ItemGUID === selectedItemId)!);

    render() {
        const selectedItemId = this.getCurrentItemId();

        if (!this.state.areDataLoaded) {
            return <Spinner variant={SpinnerVariant.ease}/>;
        }

        return (
            <SecondaryPanel
                collapseConfig={{ cookieName: Cookies.names.modulePermissions }}
                onMenuCollapsedChange={(isCollapsed) => {
                    if (isCollapsed) {
                        this.setState({ menuSearchedString: '' });
                    }
                }}
                panelHead={this.SecondaryPanelHead}
                panelBody={this.SecondaryPanelBody}
            >
                {!this.state.groups || !selectedItemId || !this.state.modulePermissions ? (
                    <FolderSplash img={splashImg} message={myStrings.splashMessage}/>
                ) : (
                    <FolderModulePermissions
                        key={selectedItemId}
                        location={this.props.location}
                        history={this.props.history}
                        allGroups={this.state.groups}
                        selectedGroup={this.getSelectedGroup(selectedItemId, this.state.groups)}
                        modulePermissions={this.state.modulePermissions}
                        onReload={this.reload}
                    />
                )}
            </SecondaryPanel>
        );
    }
}
