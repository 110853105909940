import type { IApiEnumValue } from "@eway-crm/connector";

export default class EnumValuesValidator {
    static getAreRowsAndColumnsValid = (data: Partial<IApiEnumValue>[] | null) => {
        if (!data) {
            return true;
        }
        const invalidMap = EnumValuesValidator.getInvalidRowsAndColumns(data);
        return invalidMap.size === 0;
    };

    static getInvalidRowsAndColumns = (data: Partial<IApiEnumValue>[]): Map<string, string[]> => {
        if (data.length === 0) {
            return new Map<string, string[]>();
        }

        const dicts: { [key: string]: Set<string> } = {
            FileAs: new Set<string>(),
            En: new Set<string>(),
            Cs: new Set<string>(),
            De: new Set<string>(),
            Ru: new Set<string>(),
            No: new Set<string>(),
            Sk: new Set<string>(),
        };

        const isValid = (val: string | null | undefined, name: string): boolean => {
            if (!val) {
                return false;
            }

            const valTrimmed = val.toLocaleLowerCase().trim();

            const dict = dicts[name];
            if (dict.has(valTrimmed)) {
                return false;
            }

            dict.add(valTrimmed);
            return true;
        };

        const invalidRows: Map<string, string[]> = new Map<string, string[]>();
        for (let i = 0; i < data.length; i++) {
            const row = data[i];
            type TTest = { getter: (r: Partial<IApiEnumValue>) => string | null | undefined; name: string };
            const tests: TTest[] = [
                { getter: (r) => r.FileAs, name: 'FileAs' },
                { getter: (r) => r.En, name: 'En' },
                { getter: (r) => r.Cs, name: 'Cs' },
                { getter: (r) => r.De, name: 'De' },
                { getter: (r) => r.Ru, name: 'Ru' },
                { getter: (r) => r.No, name: 'No' },
                { getter: (r) => r.Sk, name: 'Sk' },
            ];

            let problems: string[] | null = null;
            for (let j = 0; j < tests.length; j++) {
                const test = tests[j];
                if (!isValid(test.getter(row), test.name)) {
                    if (problems === null) {
                        problems = [test.name];
                    } else {
                        problems.push(test.name);
                    }
                }
            }

            if (problems !== null) {
                invalidRows.set(row.ItemGUID!, problems);
            }
        }

        return invalidRows;
    };
}