export class UserSettingsKeys {
    static readonly welcomePath = '\\WebAdmin\\Welcome\\';
    static readonly welcomeName = 'IsSeen';
    static readonly newFeaturesSeenPath = '\\WebAdmin\\NewFeatures\\';
    static readonly newFeaturesSeenName = 'IsSeen';

    static readonly searchSuccessPath = '\\WebAdmin\\Search\\Sucessful\\';
    static readonly searchUnsuccessPath = '\\WebAdmin\\Search\\Unsucessful\\';

    static readonly fieldsPath = '\\WebAdmin\\Fields\\DisplayLanguageColumns\\';
    static readonly fieldsDisplayLanguageColumnsName = 'DisplayLanguageColumns';
}