import * as React from 'react';
import WizardModal from '../../../WizardModal';
import Strings from '../../../../strings';
import StringHelper from '../../../../helpers/StringHelper';
import type { THiddenGroup, TDefaultGroupValue } from '../UserWizardBase';
import { UserWizardBase } from '../UserWizardBase';
import { Button, Nav, Col, Tab, Form } from 'react-bootstrap';
import RelationTypes from '../../../../data/constants/RelationTypes';
import FolderNames from '../../../../data/constants/FolderNames';
import { SpinnerModal } from '../../../shared/SpinnerModal';
import { UserBadgeFlags } from '../../../shared/users/UserBadgeFlags';
import type { IApiLocalizedLicenseBundle, IApiSaveResponse, IApiUser } from '@eway-crm/connector';
import { FieldNames } from '@eway-crm/connector';
import type { IApiBoundRelation, TApiItemWithReleations } from '@eway-crm/connector';
import type { IUserWizardBaseProps } from '../IUserWizardBaseProps';
import { VirtualizedComboBox } from '@fluentui/react';
import SearchResultsObtainer from '../../home/SearchBar/SearchResultsObtainer';
import { GroupNames } from '../../../../data/constants/GroupNames';
import type { IFormCheckChangeEvent } from '../../../interfaces/IFormCheckChangeEvent';
import ReactHelper from '../../../../helpers/ReactHelper';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import { LicensePicker } from '../LicensePicker';
import LicenseRestrictionsLockIcon from '../../../shared/locks/LicenseRestrictionsLockedIcon';
import FeatureLockedSplash from '../../../shared/locks/FeatureLockedSplash';
import { SpinnerVariant, VersionHelper } from '@eway-crm/gui';
import DisabledByPermissionBadge from '../../../shared/DisabledByPermissionsBadge';
import WizardBase from '../../../shared/WizardBase';

type TTabName = 'general' | 'groups' | 'special';

export type TEditUserWizardProps = IUserWizardBaseProps & {
    userItem: TApiItemWithReleations<IApiUser>;
    mandatoryGroups: string[];
};

type TEditUserWizardState = {
    firstName: string;
    lastName: string;
    username: string;
    supervisorGuid: string;
    email: string;
    availableLicenses: IApiLocalizedLicenseBundle[];
    assignedLicenses: IApiLocalizedLicenseBundle[];
    userCategoriesGuids: string[];
    isApiUser: boolean;
    isGeneralFormValidated: boolean;
    groupsSearchedString: string;
    groupsSelection: string[];
    hiddenGroupsAssignment: THiddenGroup[];
    isSavingInProgress: boolean;
    currentTabKey: TTabName;
    anyChangeMade: boolean;
    toggledGroups: string[];
    sendTipsAndTricks: boolean;
    defaultGroup: TDefaultGroupValue;
    groupsUnassignedByDefaultGroupChange: string[]; // Unlike NewUserWizard, we don't allow null here because we assume the groups selection was already considered - it is intentional
};

const dialogBodyExpectedHeight = '35rem';
const myStrings = Strings.components.routes.users;

export class EditUserWizard extends React.Component<TEditUserWizardProps, TEditUserWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly generalFormReference: React.RefObject<HTMLFormElement>;

    constructor(props: TEditUserWizardProps) {
        super(props);
        this.generalFormReference = React.createRef();

        let groupsSelection: string[] = [];
        let hiddenGroupsAssignment: THiddenGroup[] = props.hiddenGroups.map(g => ({ guid: g, isUserThere: false }));
        if (!props.userItem.Relations || props.userItem.Relations.length === 0) {
            console.warn(`User ${props.userItem.FileAs}/${props.userItem.ItemGUID} has no bound relations. The item should have at lease self relation and system group relation.`);
        } else {
            const result = EditUserWizard.getAssignedGroups(props.userItem.Relations, props.hiddenGroups);
            hiddenGroupsAssignment = result.hiddenGroupsAssignment;
            groupsSelection = result.conformGroupsSelection;
        }

        this.state = {
            firstName: StringHelper.emptyIfNull(props.userItem.FirstName),
            lastName: StringHelper.emptyIfNull(props.userItem.LastName),
            username: props.userItem.Username,
            supervisorGuid: props.userItem.Users_SupervisorGuid ? props.userItem.Users_SupervisorGuid : UserWizardBase.noSupervisorItemGUID,
            email: StringHelper.emptyIfNull(props.userItem.Email1Address),
            availableLicenses: props.availableLicenses,
            assignedLicenses: props.userItem.Server_LicensingBundlesList as IApiLocalizedLicenseBundle[] ?? [],
            isApiUser: props.userItem.IsApiUser,
            isGeneralFormValidated: false,
            groupsSearchedString: '',
            userCategoriesGuids: props.initialUserCategories.map(x => x.ItemGUID),
            groupsSelection: groupsSelection,
            hiddenGroupsAssignment: hiddenGroupsAssignment,
            isSavingInProgress: false,
            currentTabKey: 'general',
            anyChangeMade: false,
            toggledGroups: [],
            sendTipsAndTricks: false,
            defaultGroup: !!props.userItem.Groups_Default_GroupGuid ? {
                guid: props.userItem.Groups_Default_GroupGuid,
                causedAddingToGroup: false // Do not remove from the group
            } : null,
            groupsUnassignedByDefaultGroupChange: []
        };
    }

    private readonly setName = (fName: string | undefined, lName: string | undefined) => {
        UserWizardBase.setName(
            fName,
            lName,
            this.state,
            s => {
                const stateObj: Partial<TEditUserWizardState> = {};
                if (typeof s.username !== "undefined") {
                    stateObj.username = s.username;
                    stateObj.anyChangeMade = true;
                }
                if (typeof s.firstName !== "undefined") {
                    stateObj.firstName = s.firstName;
                    stateObj.anyChangeMade = true;
                }
                if (typeof s.lastName !== "undefined") {
                    stateObj.lastName = s.lastName;
                    stateObj.anyChangeMade = true;
                }
                this.setState(stateObj as TEditUserWizardState);
            }
        );
    };

    private readonly changeUsername = (value: string) => {
        const username = UserWizardBase.normalizeUsername(value, true);
        const authSettings = this.context.authSettings;

        if (authSettings.isAzureAuth && this.state.username === this.state.email) {
            // Prefill email from username
            this.setState({ username, email: username, anyChangeMade: true });
            return;
        }

        this.setState({ username, anyChangeMade: true });
    };

    private static getAssignedGroups(relations: IApiBoundRelation[], hiddenGroups: string[]): { conformGroupsSelection: string[]; hiddenGroupsAssignment: THiddenGroup[] } {
        if (!relations || relations.length === 0) {
            return {
                conformGroupsSelection: [],
                hiddenGroupsAssignment: hiddenGroups.map(g => ({ guid: g, isUserThere: false }))
            };
        }

        const conformGroupsSelection: string[] = [];
        const hiddenGroupsAssignment: THiddenGroup[] = hiddenGroups.map(g => ({ guid: g, isUserThere: false }));

        for (let i = 0; i < relations.length; i++) {
            const rel = relations[i];
            if (rel.RelationType !== RelationTypes.group)
                continue;

            const index = hiddenGroupsAssignment.findIndex(hg => hg.guid === rel.ForeignItemGUID);
            if (index === -1) {
                conformGroupsSelection.push(rel.ForeignItemGUID);
            } else {
                hiddenGroupsAssignment[index].isUserThere = true;
            }
        }

        return {
            conformGroupsSelection: conformGroupsSelection,
            hiddenGroupsAssignment: hiddenGroupsAssignment
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly changeGroupsAssignment = (selection: string[], isDetoggleOnly: boolean) => {
        for (let i = 0; i < this.props.mandatoryGroups.length; i++) {
            const mandatoryGroup = this.props.mandatoryGroups[i];
            if (!selection.includes(mandatoryGroup)) {
                selection.push(mandatoryGroup);
            }
        }
        const { newToggledGroups, newDefaultGroup, newGroupsUnassignedByDefaultGroupChange } = UserWizardBase.solveGroupChange(
            this.state.toggledGroups,
            this.state.groupsSelection,
            selection,
            isDetoggleOnly,
            this.state.defaultGroup,
            this.state.groupsUnassignedByDefaultGroupChange
        );

        this.setState({
            groupsSelection: selection,
            anyChangeMade: true,
            toggledGroups: newToggledGroups,
            defaultGroup: newDefaultGroup,
            groupsUnassignedByDefaultGroupChange: newGroupsUnassignedByDefaultGroupChange
        }
        );
    };

    private readonly handleDefaultGroupGuidChange = (newUserDefaultGroupGuid: string) => {
        const { newGroupsSelection, newToggledGroups, newDefaultGroup, newGroupsUnassignedByDefaultGroupChange } = UserWizardBase.solveDefaultGroupGuidChange(
            this.state.toggledGroups,
            this.state.groupsSelection,
            this.state.defaultGroup,
            this.state.groupsUnassignedByDefaultGroupChange,
            newUserDefaultGroupGuid
        );
        this.setState({
            anyChangeMade: true,
            groupsSelection: newGroupsSelection,
            toggledGroups: newToggledGroups,
            defaultGroup: newDefaultGroup,
            groupsUnassignedByDefaultGroupChange: newGroupsUnassignedByDefaultGroupChange ?? []
        });
    };

    private readonly handleEveryoneGroupSubmit = (relationsToBeRemoved: string[], groupsToBeAdded: string[]) => {
        if (this.state.isApiUser && this.state.hiddenGroupsAssignment.some(hg => hg.guid === this.props.everyoneGroupGuid && hg.isUserThere)) {
            const everyoneGroupRelation = this.props.userItem.Relations.find(rel => rel.ForeignItemGUID === this.props.everyoneGroupGuid);
            everyoneGroupRelation && relationsToBeRemoved.push(everyoneGroupRelation.RelationDataGUID);
        } else if (!this.state.isApiUser && this.props.userItem.Relations.every(rel => rel.ForeignItemGUID !== this.props.everyoneGroupGuid)) {
            this.props.everyoneGroupGuid && groupsToBeAdded.push(this.props.everyoneGroupGuid);
        }
        return [relationsToBeRemoved, groupsToBeAdded];
    };

    private readonly getFieldDisabledByPermissions = (fieldName: string) => {
        return WizardBase.getFieldDisabledByPermissions(fieldName, this.props.myColumnPermissionsMap);
    };

    private readonly submit = async () => {
        if (
            (this.generalFormReference.current && this.generalFormReference.current.checkValidity() === false) ||
            UserWizardBase.getIsEmailInvalid(this.state.email, this.props.forbiddenEmails, this.state.isApiUser) ||
            UserWizardBase.getIsUsernameInvalid(this.state.username, this.props.forbiddenUsernames)
        ) {
            this.setState({ isGeneralFormValidated: true });
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        const { askApi } = this.context.connection;
        const { askShoppingApi, askIsEnabled: askIsShoppingApiEnabled } = this.context.connection.getShoppingConnection();

        const transmitObject: Partial<IApiUser> = {
            ItemGUID: this.props.userItem.ItemGUID,
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            Email1Address: this.state.email,
            Users_SupervisorGuid: this.state.supervisorGuid === UserWizardBase.noSupervisorItemGUID ? null : this.state.supervisorGuid,
            Groups_Default_GroupGuid: !this.state.defaultGroup || this.props.userItem.Groups_Default_GroupGuid !== this.state.defaultGroup.guid ? null : undefined // The previously assigned default group might be unassigned bellow, so we rathere remove it and set the new one at the end
        };
        if (!this.props.userItem.IsSystem) {
            transmitObject.Username = this.state.username;
            transmitObject.IsActive = this.props.userItem.IsActive;
            transmitObject.Server_LicensingBundlesList = this.state.isApiUser ? null : UserWizardBase.prepareAssignedLicensesForSubmit(this.state.assignedLicenses);
            transmitObject.IsApiUser = this.state.isApiUser;
        }

        await askApi<IApiSaveResponse>('SaveUser', { transmitObject });
        SearchResultsObtainer.setShouldReloadOnNextSearch(true);

        const { isEnabled } = await askIsShoppingApiEnabled();
        if (isEnabled) {
            if (this.props.userItem.Email1Address !== this.state.email || this.props.userItem.Username !== this.state.username) {
                if (this.state.sendTipsAndTricks && this.state.email) {
                    await askShoppingApi('RegisterEmployeesUsersEmails', {
                        transmitObjects: [{
                            Email: this.state.email,
                            FirstName: this.state.firstName,
                            LastName: this.state.lastName
                        }],
                        signUpForTips: true
                    });
                } else {
                    await askShoppingApi('RegisterEmployeesUsersEmails', {
                        transmitObjects: null,
                        signUpForTips: false
                    });
                }
            }
        }

        let relationsToBeRemoved: string[] = [];
        let groupsToBeAdded: string[] = [];
        if (this.props.userItem.Relations && this.props.userItem.Relations.length !== 0) {
            relationsToBeRemoved = this.props.userItem.Relations.filter(
                (rel) => {
                    if (rel.RelationType !== RelationTypes.group) {
                        return false;
                    }

                    const group = this.props.allGroups.find(x => x.ItemGUID === rel.ForeignItemGUID);
                    if (group?.IsCategory) {
                        return !this.state.userCategoriesGuids.includes(rel.ForeignItemGUID);
                    }

                    return (
                        this.props.availableGroups.some((avRel) => avRel.ItemGUID === rel.ForeignItemGUID) &&
                        !this.props.hiddenGroups.includes(rel.ForeignItemGUID) &&
                        !this.state.groupsSelection.includes(rel.ForeignItemGUID)
                    );
                }
            ).map((rel) => rel.RelationDataGUID);
            groupsToBeAdded = this.state.groupsSelection.concat(this.state.userCategoriesGuids).filter(
                (groupGuid) => this.props.userItem.Relations.findIndex((rel: IApiBoundRelation) => rel.ForeignItemGUID === groupGuid && rel.RelationType === RelationTypes.group) === -1
            );
        } else {
            relationsToBeRemoved = [];
            groupsToBeAdded = this.state.groupsSelection;
        }
        [relationsToBeRemoved, groupsToBeAdded] = this.handleEveryoneGroupSubmit(relationsToBeRemoved, groupsToBeAdded);

        if (relationsToBeRemoved.length !== 0) {
            await askApi('DeleteRelations', { relationDataGuids: relationsToBeRemoved });
        }

        if (groupsToBeAdded.length !== 0) {
            const groupRelations = groupsToBeAdded.map((groupGuid) => {
                return {
                    ItemGUID1: groupGuid,
                    ItemGUID2: this.props.userItem.ItemGUID,
                    FolderName1: FolderNames.groups,
                    FolderName2: FolderNames.users,
                    RelationType: RelationTypes.group,
                    DifferDirection: false,
                };
            });

            await askApi('SaveRelations', { transmitObjects: groupRelations });
        }

        // Now when the groups are assigned, the default group can be saved. If it was not above
        if (!!this.state.defaultGroup && this.props.userItem.Groups_Default_GroupGuid !== this.state.defaultGroup.guid) {
            await askApi<IApiSaveResponse>('SaveUser', {
                transmitObject: {
                    ItemGUID: this.props.userItem.ItemGUID,
                    Groups_Default_GroupGuid: this.state.defaultGroup?.guid ?? null
                }
            });
        }

        this.props.onDone();
    };

    render() {
        const firstNameDisabledByPermissions = this.getFieldDisabledByPermissions("FirstName");
        const lastNameDisabledByPermissions = this.getFieldDisabledByPermissions("LastName");
        const supervisorDisabledByPermissions = this.getFieldDisabledByPermissions(FieldNames.Users.Supervisor);
        const emailDisabledByPermissions = this.getFieldDisabledByPermissions(FieldNames.Users.Email1Address);
        const authSettings = this.context.authSettings;

        const { isUserRolesLocked, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesLocked();
        const isApiUserWithoutEmail = this.state.isApiUser && !this.state.email;
        const isUsernameDisabled = this.props.userItem.IsSystem || ((authSettings.isActiveDirectory || authSettings.isAzureAuth) && this.context.connection.state.session?.userGuid === this.props.userItem.ItemGUID);
        const isEmailChanged = this.props.userItem.Email1Address !== this.state.email;
        const isEmailInvalid = UserWizardBase.getIsEmailInvalid(this.state.email, this.props.forbiddenEmails, this.state.isApiUser);

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            return (
                <WizardModal
                    show={true}
                    size="lg"
                    onHide={this.dismiss}
                    hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}
                    showCancelButtonInFooter={true}
                    dialogBodyExpectedHeight={dialogBodyExpectedHeight}
                >
                    <WizardModal.Body>
                        <h1 className="text-truncate" title={this.props.userItem.FileAs!}>
                            {this.props.userItem.FileAs}
                            <UserBadgeFlags item={this.props.userItem} currentUserGuid={this.context.connection.state.session!.userGuid} style={{ fontSize: '1rem' }} />
                        </h1>
                        <div className="text-monospace">{this.props.userItem.Username}</div>
                        <Tab.Container activeKey={this.state.currentTabKey} onSelect={(key) => this.setState({ currentTabKey: key as TTabName })} mountOnEnter>
                            <div className="mt-4">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">{myStrings.general}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="groups">{myStrings.groups} {isUserRolesLocked && <LicenseRestrictionsLockIcon licenseRestriction={userRolesLicenseRestriction} />}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="special">{myStrings.special} {isUserRolesLocked && <LicenseRestrictionsLockIcon licenseRestriction={userRolesLicenseRestriction} />}</Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="mt-4">
                                <Tab.Content>
                                    <Tab.Pane eventKey="general">
                                        <Form ref={this.generalFormReference} validated={this.state.isGeneralFormValidated} className="form-application">
                                            <Form.Row>
                                                <Form.Group as={Col} sm={5} controlId="formFirstName">
                                                    <Form.Label>{myStrings.firstName}<DisabledByPermissionBadge disabledByPermissions={firstNameDisabledByPermissions} /></Form.Label>
                                                    <Form.Control type="text" value={this.state.firstName} onChange={(e) => this.setName(e.target.value, undefined)} disabled={firstNameDisabledByPermissions?.isDisabled} />
                                                </Form.Group>

                                                <Form.Group as={Col} sm={7} controlId="formLastName">
                                                    <Form.Label>{myStrings.lastName}<DisabledByPermissionBadge disabledByPermissions={lastNameDisabledByPermissions} /></Form.Label>
                                                    <Form.Control type="text" value={this.state.lastName} onChange={(e) => this.setName(undefined, e.target.value)} disabled={lastNameDisabledByPermissions?.isDisabled} />
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <div className="col-sm-5">
                                                    {/* Show MS Azure account email input instead of username when isAzureAuth */}
                                                    {authSettings.isAzureAuth ? (
                                                        <Form.Group controlId="newUserUsername" className={isUsernameDisabled ? 'disabled' : undefined}>
                                                            <Form.Label>{myStrings.msAzureAccount}<DisabledByPermissionBadge disabledByPermissions={emailDisabledByPermissions} /></Form.Label>
                                                            <Form.Control
                                                                type="email"
                                                                value={this.state.username}
                                                                required
                                                                onChange={(e) => this.changeUsername(e.target.value)}
                                                                isInvalid={
                                                                    this.state.isGeneralFormValidated &&
                                                                    (UserWizardBase.getIsEmailInvalid(this.state.username, this.props.forbiddenEmails, this.state.isApiUser) ||
                                                                        UserWizardBase.getIsUsernameInvalid(this.state.username, this.props.forbiddenUsernames))
                                                                }
                                                                disabled={isUsernameDisabled || emailDisabledByPermissions?.isDisabled}
                                                            />
                                                            <Form.Control.Feedback type="invalid">
                                                                {UserWizardBase.getInvalidAzureUsernameMessage(
                                                                    this.state.username,
                                                                    this.props.forbiddenUsernames,
                                                                    this.props.forbiddenEmails,
                                                                    this.state.isApiUser
                                                                )}
                                                            </Form.Control.Feedback>
                                                        </Form.Group>
                                                    ) : (
                                                        <Form.Group controlId="newUserUsername" className={isUsernameDisabled ? 'disabled' : undefined}>
                                                            <Form.Label>{myStrings.username}</Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                value={this.state.username}
                                                                required
                                                                onChange={(e) => this.changeUsername(e.target.value)}
                                                                pattern={UserWizardBase.getUsernamePattern(this.state.username, this.props.forbiddenUsernames)}
                                                                disabled={isUsernameDisabled}
                                                            />
                                                            <Form.Control.Feedback type="invalid">{UserWizardBase.getInvalidUsernameMessage(this.state.username)}</Form.Control.Feedback>
                                                        </Form.Group>
                                                    )}
                                                </div>
                                                <div className="col-sm-7">
                                                    <Form.Group controlId="newUserSupervisor" className={this.props.userItem.IsSystem ? 'disabled' : undefined}>
                                                        <Form.Label>{myStrings.supervisor}<DisabledByPermissionBadge disabledByPermissions={supervisorDisabledByPermissions} /></Form.Label>
                                                        <VirtualizedComboBox
                                                            selectedKey={this.state.supervisorGuid}
                                                            options={UserWizardBase.getSupervisorOptionsForDropdown(this.props.availableSupervisors.sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs)))}
                                                            onChange={(_e, o, _i, _v) => this.setState({ supervisorGuid: o!.key as string, anyChangeMade: true })}
                                                            useComboBoxAsMenuWidth={true}
                                                            disabled={this.props.userItem.IsSystem || supervisorDisabledByPermissions?.isDisabled}
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Form.Row>
                                            <Form.Row>
                                                <div className="col-sm-5">
                                                    <Form.Group controlId="newUserEmail">
                                                        <Form.Label>{myStrings.email}<DisabledByPermissionBadge disabledByPermissions={emailDisabledByPermissions} /></Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            required={!this.state.isApiUser}
                                                            value={this.state.email}
                                                            isInvalid={this.state.isGeneralFormValidated && isEmailInvalid}
                                                            onChange={(e) => this.setState({ email: e.target.value, anyChangeMade: true })}
                                                            disabled={emailDisabledByPermissions?.isDisabled}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {UserWizardBase.getInvalidEmailMessage(this.state.email, UserWizardBase.getIsEmailForbidden(this.state.email, this.props.forbiddenEmails))}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </div>
                                                <div className="col-sm-7">
                                                    <Form.Group controlId="newUserLicense" className={(!this.props.userItem.IsActive || this.props.userItem.IsSystem) ? 'disabled' : undefined}>
                                                        <Form.Label>{myStrings.ewayLicense}</Form.Label>
                                                        <LicensePicker
                                                            assignedLicenses={this.state.assignedLicenses}
                                                            availableLicenses={this.state.availableLicenses}
                                                            isApiUser={this.state.isApiUser}
                                                            onLicenseChange={({ isApiUser, assignedLicenses, availableLicenses }) => this.setState({ isApiUser, assignedLicenses, availableLicenses })}
                                                            disabled={!this.props.userItem.IsActive || this.props.userItem.IsSystem}
                                                            useComboBoxAsMenuWidth
                                                        />
                                                    </Form.Group>
                                                </div>
                                            </Form.Row>
                                            {isEmailChanged && (
                                                <Form.Row className="pl-1">
                                                    <Form.Group controlId="newUserSendTips">
                                                        <Form.Check
                                                            type="checkbox"
                                                            label={myStrings.sendTipsAndTricks}
                                                            checked={this.state.sendTipsAndTricks && !isApiUserWithoutEmail}
                                                            disabled={isApiUserWithoutEmail}
                                                            onChange={(e: IFormCheckChangeEvent) => this.setState({ sendTipsAndTricks: e.target.checked })}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            )}
                                            {(!isUserRolesLocked && is810OrLater) &&
                                                <Form.Row>
                                                    <div className="col-sm-5">
                                                        <Form.Group controlId="newUserDefaultGroup">
                                                            <Form.Label>{myStrings.defaultGroup}</Form.Label>
                                                            <VirtualizedComboBox
                                                                selectedKey={this.state.defaultGroup?.guid ?? UserWizardBase.noDefaultGroupItemGUID}
                                                                options={UserWizardBase.getDefaultGroupsOptionsForDropdown(this.props.availableGroups.sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName)))}
                                                                onChange={(_e, o, _i, _v) => this.handleDefaultGroupGuidChange(o!.key as string)}
                                                                useComboBoxAsMenuWidth={true}
                                                            />
                                                            {!!this.state.groupsUnassignedByDefaultGroupChange && this.state.groupsUnassignedByDefaultGroupChange.map(gudf => (
                                                                <div key={`gudf-${gudf}`} className="small">
                                                                    {myStrings.userWillBeRemovedFromGroup} {this.props.availableGroups.find((ag) => ag.ItemGUID === gudf)?.FileAs}
                                                                </div>
                                                            ))}
                                                        </Form.Group>
                                                    </div>
                                                </Form.Row>
                                            }
                                        </Form>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="groups">
                                        {!isUserRolesLocked ? (
                                            <UserWizardBase.GroupsPicker
                                                showHeading={false}
                                                showSearch={true}
                                                groupsSelection={this.state.groupsSelection}
                                                onGroupsSelectionChange={this.changeGroupsAssignment}
                                                groupsSearchedString={this.state.groupsSearchedString}
                                                onGroupsSearchedStringChange={(str: string) => {
                                                    this.setState({ groupsSearchedString: str });
                                                }}
                                                availableGroups={this.props.availableGroups.filter((g) => g.GroupName !== GroupNames.systemHealthNotification)}
                                                gridTableHeight="20rem"
                                                defaultGroupGuid={this.state.defaultGroup?.guid}
                                            />
                                        ) : (
                                            <FeatureLockedSplash className="pt-5" />
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="special">
                                        {!isUserRolesLocked ? (
                                            <UserWizardBase.SpecialForm
                                                exchangeRatesAdminGroupGuid={this.props.exchangeRatesAdminGroupGuid}
                                                sendServerUpdateStatusGroupGuid={this.props.sendServerUpdateStatusGroupGuid}
                                                sendSystemHealthNotificationsGroupGuid={this.props.sendSystemHealthNotificationsGroupGuid}
                                                isSystemHealthNotificationDefaultGroupInGs={this.props.isSystemHealthNotificationDefaultGroupInGs}
                                                hiddenGroups={this.state.hiddenGroupsAssignment}
                                                groupsSelection={this.state.groupsSelection}
                                                onGroupsSelectionChange={this.changeGroupsAssignment}
                                                isSystemUser={this.props.userItem.IsSystem}
                                                allGroups={this.props.allGroups}
                                                userCategoriesGuids={this.state.userCategoriesGuids}
                                                onUserCategoriesChange={(userCategoriesGuids) => this.setState({ userCategoriesGuids })}
                                                availableGroups={this.props.availableGroups}
                                                toggledGroups={this.state.toggledGroups}
                                                isCloudHosted={this.context.apiData.license.IsCloudHosted}
                                            />
                                        ) : (
                                            <FeatureLockedSplash className="pt-5" />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={() => void this.submit()}>{Strings.save}</Button>
                    </WizardModal.Footer>
                </WizardModal >
            );
        }
    }
}