import * as React from 'react';
import type { HTMLReactParserOptions } from 'html-react-parser';
import parse from 'html-react-parser';

const safelyParse = (htmlString: string | null, options?: HTMLReactParserOptions) => {
    if (!htmlString) {
        return null;
    }

    try {
        return parse(htmlString, options);
    } catch (error) {
        console.error('HtmlComponent cought an unhandled error when parsing the html input. The error and the input follows. Nothing will be rendered.', error, htmlString);
        return null;
    }
};

type HtmlComponentProps = {
    htmlString: string | null;
    options?: HTMLReactParserOptions;
};

const HtmlComponent: React.FunctionComponent<HtmlComponentProps> = (props) => {
    const { htmlString, options } = props;
    const parsed = React.useMemo(() => safelyParse(htmlString, options), [htmlString, options]);
    return <>{parsed}</>;
};

export default HtmlComponent;