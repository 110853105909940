import * as Promise from 'es6-promise';
import type { AxiosResponse } from 'axios';
import Axios from 'axios';
import * as XmlParser from 'xml-js';
import type { IServerUpdaterAdditionalData } from './data/wcf/IServerUpdaterAdditionalData';
import { AppBuildModeHelper } from '@eway-crm/gui';
import APP_BUILD_MODE from './AppBuildMode';
import ApiConnection from '@eway-crm/connector';

Promise.polyfill();

function getUpdateResultFileAddress(wsUrl: string): string {
    // In case of running on localhost, replace relative api address with the localhost eWayWS IISExpress app.
    if (window.location.host === 'localhost:3000') {
        return 'Fake_UpdateResultMessages.txt';
    }
    // End of debug code.

    if (!wsUrl.endsWith('/')) {
        wsUrl = wsUrl + '/';
    }
    // We count on that we are in Admin/Subdirectory.
    return wsUrl + 'Download/UpdateResultMessages.txt';
}

export function getPremiseWsUrl(): string {
    if (!AppBuildModeHelper.isPremise(APP_BUILD_MODE))
        throw new Error('Getting premise ws url is not supported on cloud hosted admin app.');

    // In case of running on localhost, replace relative api address with the localhost eWayWS IISExpress app.
    if (window.location.host === 'localhost:3000') {
        return ApiConnection.normalizeWsUrl(import.meta.env.VITE_DEV_WS_URL)!;
    }
    // End of debug code.

    const server = window.location.protocol + '//' + window.location.host;
    let path = window.location.pathname;
    if (!path)
        return ApiConnection.normalizeWsUrl(server)!;

    if (path.startsWith('/')) {
        path = path.substring(1);
    }
    if (path.endsWith('/')) {
        path = path.substring(0, path.length - 1);
    }
    if (path.toLowerCase().endsWith('/admin')) {
        path = path.substring(0, path.length - 6);
    }
    if (path.toLowerCase() === 'admin') {
        path = '';
    }

    if (!path)
        return ApiConnection.normalizeWsUrl(server)!;

    return ApiConnection.normalizeWsUrl(server + '/' + path)!;
}

export function callWsGet<TResult extends Record<string, unknown>>(wsUrl: string, methodName: string, successCallback: (result: TResult) => void, errorCallback: (error: unknown) => void) {
    if (!wsUrl.endsWith('/')) {
        wsUrl = wsUrl + '/';
    }

    const address = wsUrl + 'eWayWS.asmx/' + methodName;

    Axios.get(address)
        .then((response: AxiosResponse<string>) => {
            if (response.status === 200) {
                const data = JSON.parse(XmlParser.xml2json(response.data, { compact: true })) as TResult;
                successCallback(data);
            } else {
                console.error('eWay WS get response error: ' + JSON.stringify(response));
                errorCallback(response);
            }
        })
        .catch((error) => {
            console.error('eWay WS get request failed: ' + JSON.stringify(error));
            errorCallback(error);
        });
}

export function getUpdateResult(wsUrl: string, callback: (stageFlag: string, otherData: string[] | null, otherDataObject: IServerUpdaterAdditionalData | null) => void, notExistCallback: () => void) {
    Axios.get((getUpdateResultFileAddress(wsUrl)))
        .then((response: AxiosResponse) => {
            if (response.status === 200 && !!response.data) {
                const dataString = response.data as string;
                const parts = dataString.replace(/(\r\n|\n\r|\n|\r)+/g, '\n').split('\n');
                if (parts.length > 0 && !!parts[0]) {
                    const otherData: string[] = [];
                    let otherDataObject: IServerUpdaterAdditionalData | null = null;
                    if (parts.length > 4) {
                        let currentOtherData = '';
                        for (let i = 1; i < parts.length; i++) {
                            if (!parts[i])
                                continue;
                            if (parts[i] === '###') {
                                if (!!currentOtherData) {
                                    otherData.push(currentOtherData);
                                    currentOtherData = '';
                                }
                                continue;
                            }

                            if (!!currentOtherData) {
                                currentOtherData += '\n';
                            }
                            currentOtherData += parts[i];

                            if (parts[i].startsWith('UpdateFailedBeforeActualStartData.')) {
                                if (!otherDataObject || !otherDataObject.UpdateFailedBeforeActualStartData) {
                                    otherDataObject = {
                                        UpdateFailedBeforeActualStartData: {}
                                    };
                                }
                                if (parts[i].startsWith('UpdateFailedBeforeActualStartData.BackupPath=')) {
                                    otherDataObject.UpdateFailedBeforeActualStartData!.BackupPath = parts[i].substring('UpdateFailedBeforeActualStartData.BackupPath='.length);
                                }
                                if (parts[i].startsWith('UpdateFailedBeforeActualStartData.WebServicePath=')) {
                                    otherDataObject.UpdateFailedBeforeActualStartData!.WebServicePath = parts[i].substring('UpdateFailedBeforeActualStartData.WebServicePath='.length);
                                }
                            }
                        }
                        if (!!currentOtherData) {
                            otherData.push(currentOtherData);
                        }
                    }
                    callback(parts[0], otherData.length === 0 ? null : otherData, otherDataObject);
                } else {
                    console.error('Unable to read update result file. Response follows:');
                    console.log(response);
                    notExistCallback();
                }
            } else {
                console.error('Unable to load update result file. Response follows:');
                console.log(response);
                notExistCallback();
            }
        })
        .catch((err: unknown) => {
            console.log('Unable to load update result file. Error follows:');
            console.log(err);
            notExistCallback();
        });
}