import type { TLicenseRestriction } from "@eway-crm/gui";
import { Link } from "@fluentui/react";
import { useContext } from "react";
import { ConnectionContext } from "../../../providers/ConnectionProvider";
import Strings from "../../../strings";

type TLicenseRestrictionsLockedGridRowInnerProps = {
    licenseRestriction: TLicenseRestriction;
    colSpan: number;
    unlockText: string;
};

const LicenseRestrictionsLockedGridRowInner: React.FC<TLicenseRestrictionsLockedGridRowInnerProps> = ({ licenseRestriction, colSpan, unlockText }) => {
    const { showLicenseRestrictionModal } = useContext(ConnectionContext);
    return (
        <td colSpan={colSpan} className="text-center text-medium" style={{ fontWeight: 500 }}>
            <Link
                onClick={() => {
                    showLicenseRestrictionModal(licenseRestriction);
                }}
            >
                {unlockText}
            </Link>{' '}
            {Strings.formatString(Strings.components.licenseRestrictions.withPlan, licenseRestriction.availableInEdition)}
        </td>
    );
};

export default LicenseRestrictionsLockedGridRowInner;