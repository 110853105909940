import type { ITranslatableUnit } from '../data/shopping/ITranslatableUnit';
import type { ITranslatableString } from '@eway-crm/connector';
import Strings from '../strings';
import { UnitHelper as JsGuiUnitHelper } from '@eway-crm/gui';

class UnitHelper extends JsGuiUnitHelper {
    static pickTranslatedUnit = (quantity: number, unit: ITranslatableUnit | null): string | null => {
        if (!unit) {
            return null;
        }

        let translatableString: ITranslatableString;
        if (quantity === 1) {
            translatableString = unit.Singular;
        } else if (quantity === 2) {
            translatableString = unit.PluralTwo;
        } else if (quantity === 3 || quantity === 4) {
            translatableString = unit.PluralThreeAndFour;
        } else {
            translatableString = unit.PluralFiveAndMore;
        }
        return Strings.pickTranslation(translatableString);
    };
}

export default UnitHelper;
