import React, { useMemo } from 'react';
import FoldersMenu from '../../../shared/FoldersMenu';
import type { TFieldPermissionsCellWrapperProps, TFieldPermissionWithCol } from '../../fields/FieldPermissionsGrid';
import FieldPermissionsGrid from '../../fields/FieldPermissionsGrid';
import splashImg from '../../../../img/splash/key-splash.svg';
import type { IApiColumn, IApiGroup, TFolderName } from '@eway-crm/connector';
import injectProps from '../../../../helpers/components/injectProps';
import type { TFieldPermissionsCellTooltipAdditionalProps } from './EffectiveFieldPermissionsCellTooltip';
import EffectiveFieldPermissionsCellTooltip from './EffectiveFieldPermissionsCellTooltip';
import { Link } from 'react-router-dom';
import type { TCustomUserLicenseRestrictionsObject } from '../EffectivePermissionsWizard';
import Strings from '../../../../strings';
import RouteConfig from '../../../../RouteConfig';

const SIDE_MENU_WIDTH = 265;

type TEffectiveFieldPermissionsTabProps = {
    folders: string[];
    selectedFolderName: string | null;
    onSelectedFolderNameChange: (newFolderName: string) => void;
    fieldPermissions: TFieldPermissionWithCol[];
    userGroups: IApiGroup[];
    columns: IApiColumn[];
    systemHealthNotificationGroupGuid: string | null;
    customUserLicenseRestrictionsObject?: TCustomUserLicenseRestrictionsObject;
};

const EffectiveFieldPermissionsTab: React.FC<TEffectiveFieldPermissionsTabProps> = ({
    folders,
    selectedFolderName,
    onSelectedFolderNameChange,
    fieldPermissions,
    userGroups,
    columns,
    systemHealthNotificationGroupGuid,
    customUserLicenseRestrictionsObject,
}) => {
    const myStrings = Strings.components.licenseRestrictions;
    const cellWrapper = useMemo(
        () =>
            injectProps<TFieldPermissionsCellWrapperProps, TFieldPermissionsCellTooltipAdditionalProps>(EffectiveFieldPermissionsCellTooltip, {
                userGroups,
                columns,
                systemHealthNotificationGroupGuid,
            }),
        [userGroups, columns, systemHealthNotificationGroupGuid]
    );
    const userLicenseRestriction = customUserLicenseRestrictionsObject && customUserLicenseRestrictionsObject.modules[selectedFolderName as TFolderName];

    return (
        <>
            <div className="d-flex position-relative">
                <div className="border-right border-gray align-self-end mt-1 pb-2 tr-cursor-hover" style={{ width: SIDE_MENU_WIDTH }}>
                    <FoldersMenu
                        folderNames={folders}
                        selectedFolderName={selectedFolderName}
                        searchedString={''}
                        isCollapsed={false}
                        onFolderChange={(newFolderName) => {
                            onSelectedFolderNameChange(newFolderName);
                        }}
                        variant="small"
                        height={400}
                    />
                </div>
                <div className="d-flex flex-fill flex-column position-absolute pb-2" style={{ top: 0, bottom: 0, left: SIDE_MENU_WIDTH, right: 0 }}>
                    {selectedFolderName && !userLicenseRestriction && (
                        <FieldPermissionsGrid
                            gridHeight={'20rem'}
                            fieldPermissions={fieldPermissions.filter((fp) => fp.col?.FolderName === selectedFolderName)}
                            alwaysShowIsUnique
                            searchedString={''}
                            disableEditing
                            cellWrapper={cellWrapper}
                        />
                    )}
                    {!selectedFolderName && !userLicenseRestriction && (
                        <div className="h-100 position-relative">
                            <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <img className="d-inline-block" src={splashImg} style={{ height: '160px', width: '100%' }} alt="" />
                            </div>
                        </div>
                    )}
                    {userLicenseRestriction && (
                        <div className="h-100 position-relative">
                            <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                <div className="text-center">
                                    {Strings.formatString(
                                        myStrings.userNotAuthorizedToUseModule,
                                        customUserLicenseRestrictionsObject.user.FileAs ?? myStrings.user,
                                        (<Link to={`${RouteConfig.users.edit.path}/${customUserLicenseRestrictionsObject.user.ItemGUID}`}>{myStrings.assignLicense}</Link>) as unknown as string,
                                        (<Link to={RouteConfig.billing.subscriptions.path}>{myStrings.buyNewOne}</Link>) as unknown as string
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

export default EffectiveFieldPermissionsTab;
