import * as React from 'react';
import Strings from '../../strings';
import { Form } from 'react-bootstrap';
import Cookies from '../../Cookies';
import { SecondaryPanel } from '../layout/SecondaryPanel';
import FoldersMenu from './../shared/FoldersMenu';
import type { IApiDataResponse, TFolderName } from '@eway-crm/connector';
import FolderFields from './fields/FolderFields';
import { RemoteItemStore } from '../../RemoteItemStore';
import splashImg from '../../img/splash/customfields-splash.svg';
import type { match as IRouterMatch, RouteComponentProps } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import RouteConfig from '../../RouteConfig';
import StringHelper from '../../helpers/StringHelper';
import type { IApiGroup } from '@eway-crm/connector';
import type { IApiColumn } from '@eway-crm/connector';
import FolderSplash from '../layout/FolderSplash';
import { GroupNames } from '../../data/constants/GroupNames';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import FeatureLockedSplash from '../shared/locks/FeatureLockedSplash';
import NotificationDot from '../layout/NotificationDot';
import { getFieldsLicenseRestrictionsErrors } from './fields/FieldsLicenseRestrictionsErrors';
import FieldsRestrictionTooltipContent from './fields/FieldsRestrictionTooltipContent';
import InteractiveTooltip from '../shared/InteractiveTooltip';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.fields;

type TFieldsProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TFieldsState = {
    folders: string[] | null;
    customizableFolders: string[] | null;
    linkableFolders: string[] | null;
    groups: IApiGroup[] | null;
    systemHealthNotificationGroupGuid: string | null;
    columns: IApiColumn[] | null;
    menuSearchedString: string;
    folderSearchedString: string;
    expandedRowIds: { [folder: string]: (string | number)[] };
};

export default class Fields extends React.Component<TFieldsProps, TFieldsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TFieldsProps) {
        super(props);
        this.state = {
            folders: null,
            customizableFolders: null,
            linkableFolders: null,
            groups: null,
            systemHealthNotificationGroupGuid: null,
            columns: null,
            menuSearchedString: '',
            folderSearchedString: '',
            expandedRowIds: {},
        };
    }

    static getColumnName = (column: Pick<IApiColumn, 'NameCs' | 'NameEn' | 'AltNameCs' | 'AltNameEn'>, customSwitch?: typeof Strings.switch) => {
        if (customSwitch) {
            return customSwitch(column?.AltNameEn ?? column?.NameEn, column?.AltNameCs ?? column?.NameCs) ?? '';
        }
        return Strings.switch(column?.AltNameEn ?? column?.NameEn, column?.AltNameCs ?? column?.NameCs) ?? '';
    };

    private readonly reload = () => {
        this.setState({ groups: null, folders: null, customizableFolders: null, linkableFolders: null, columns: null }, () => {
            (async () => {
                const { askApi } = this.context.connection;
                const { objectTypes } = this.context.apiData;

                const groupResult = await askApi<IApiDataResponse<IApiGroup>>('GetGroups', {});
                const otFolders = RemoteItemStore.getDisplayableFieldFolders(objectTypes, this.context.licenseRestrictionsHelper);
                const customizableFolders = objectTypes.filter((ot) => ot.IsModule && ot.IsActive && ot.IsCustomizable).map((ot) => ot.FolderName);
                const linkableFolders = objectTypes.filter((ot) => ot.IsModule && ot.IsActive && ot.IsLinkable).map((ot) => ot.FolderName);
                const colsResult = await askApi<IApiDataResponse<IApiColumn>>('GetColumns', { includeAdditionalFields: true });
                await this.context.reloadCustomizationStats();

                this.setState({
                    groups: groupResult.Data.filter((g) => g.GroupName !== GroupNames.systemHealthNotification), // Including Categories to show Field Permissions if they have some
                    systemHealthNotificationGroupGuid: groupResult.Data.find((g) => g.GroupName === GroupNames.systemHealthNotification)?.ItemGUID ?? null,
                    folders: otFolders,
                    customizableFolders,
                    linkableFolders: linkableFolders,
                    columns: colsResult.Data,
                });
            })()
                .catch((err) => console.error('Unable to reload fields data.', err));
        });
    };

    componentDidMount() {
        this.reload();
    }

    private readonly onFolderSearchedStringChange = (folderSearchedString: string) => {
        this.setState({ folderSearchedString });
    };

    private readonly onExpandedRowIdsChange = (expandedRowIds: (string | number)[]) => {
        this.setState((prevState) => {
            const currentFolderName = this.getCurrentFolderName()!;
            const newExpandedRowIds = { ...prevState.expandedRowIds };
            newExpandedRowIds[currentFolderName] = expandedRowIds;
            return { expandedRowIds: newExpandedRowIds };
        });
    };

    private readonly getCurrentFolderName = () => {
        const match: IRouterMatch<{ folder: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.customizations.fields.pathWithFolder });
        const folder = match?.params?.folder;
        if (folder && this.state.folders) {
            const capitalizedFolder = this.state.folders.find((f) => !StringHelper.compareIgnoringCase(f, folder)) ?? null;
            return capitalizedFolder;
        }

        return null;
    };

    private readonly SecondaryPanelHead: React.FC = () => (
        <Form.Control type="text" placeholder={Strings.search} value={this.state.menuSearchedString} onChange={(e) => this.setState({ menuSearchedString: e.target.value })} />
    );

    private readonly SecondaryPanelBody: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
        const fieldsErrors = getFieldsLicenseRestrictionsErrors(this.context);

        return (
            <FoldersMenu
                folderNames={this.state.folders!}
                selectedFolderName={this.getCurrentFolderName()}
                searchedString={this.state.menuSearchedString}
                isCollapsed={isCollapsed}
                onFolderChange={(newFolderName) => {
                    this.setState({ folderSearchedString: '' }, () => {
                        this.props.history.push(RouteConfig.customizations.fields.getPathWithFolder(newFolderName));
                    });
                }}
                onRenderCellSuffix={(props) => {
                    const typedRow = props.row as { folderName: TFolderName };

                    const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(typedRow.folderName);

                    if (isFolderNameLocked) {
                        return null;
                    }

                    const licenseRestrictionErrors = fieldsErrors[typedRow.folderName];
                    if (licenseRestrictionErrors) {
                        return (
                            <InteractiveTooltip hostClassName="ml-auto" content={<FieldsRestrictionTooltipContent folderName={typedRow.folderName} />}>
                                <NotificationDot value={licenseRestrictionErrors.length} />
                            </InteractiveTooltip>
                        );
                    }

                    return null;
                }}
            />
        );
    };

    render() {
        const selectedFolderName = this.getCurrentFolderName();
        const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(selectedFolderName as TFolderName);
        const isSelectedFolderNameLocked = selectedFolderName && isFolderNameLocked;
        if (!this.state.folders || !this.state.customizableFolders || !this.state.linkableFolders || !this.state.groups || !this.state.columns) {
            return <Spinner variant={SpinnerVariant.ease} />;
        }
        return (
            <SecondaryPanel
                collapseConfig={{ cookieName: Cookies.names.fields }}
                onMenuCollapsedChange={(isCollapsed) => {
                    if (isCollapsed) {
                        this.setState({ menuSearchedString: '' });
                    }
                }}
                panelHead={this.SecondaryPanelHead}
                panelBody={this.SecondaryPanelBody}
            >
                {!selectedFolderName ? (
                    <FolderSplash img={splashImg} message={myStrings.splashMessage} />
                ) : isSelectedFolderNameLocked ? (
                    <FeatureLockedSplash />
                ) : (
                    <FolderFields
                        key={'additional-fields-of-folder' + selectedFolderName}
                        availableFolders={this.state.folders}
                        customizableFolders={this.state.customizableFolders}
                        linkableFolders={this.state.linkableFolders}
                        folderName={selectedFolderName}
                        history={this.props.history}
                        location={this.props.location}
                        groups={this.state.groups}
                        systemHealthNotificationGroupGuid={this.state.systemHealthNotificationGroupGuid}
                        columns={this.state.columns}
                        onReload={this.reload}
                        searchedString={this.state.folderSearchedString}
                        onSearchedStringChange={this.onFolderSearchedStringChange}
                        expandedRowIds={this.state.expandedRowIds[selectedFolderName]}
                        onExpandedRowIdsChange={this.onExpandedRowIdsChange}
                    />
                )}
            </SecondaryPanel>
        );
    }
}
