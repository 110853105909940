import type { TFolderName } from '@eway-crm/connector';
import { Link } from '@fluentui/react';
import React, { useContext } from 'react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import TooltipContentSteps from '../../layout/TooltipContentSteps';
import { getFieldsLicenseRestrictionsErrors } from './FieldsLicenseRestrictionsErrors';

type TFieldsRestrictionTooltipContentProps = {
    folderName: TFolderName;
};

const FieldsRestrictionTooltipContent: React.FC<TFieldsRestrictionTooltipContentProps> = ({folderName}) => {
    const context = useContext(ConnectionContext);
    const { showLicenseRestrictionModal } = context;
    const myStrings = Strings.components.licenseRestrictions;

    const allFieldsLicenseRestrictionErrors = getFieldsLicenseRestrictionsErrors(context);
    const fieldsLicenseRestrictionErrors = allFieldsLicenseRestrictionErrors[folderName];

    return (
        <TooltipContentSteps
            steps={fieldsLicenseRestrictionErrors}
            onRenderContent={(step) => {
                return (
                    <div className="d-flex flex-row mb-2">
                        <div>
                            <h4 className="mb-2">{step.stripTitle}</h4>
                            <div>{step.tooltipMessage}</div>
                            <br />
                            <div>
                                {Strings.formatString(
                                    myStrings.fields.fieldsUpgradePlanToExtendLimit,
                                    <Link
                                        className="d-inline-block"
                                        onClick={() => {
                                            showLicenseRestrictionModal(step.stats.licenseRestriction!);
                                        }}
                                    >
                                        {myStrings.upgradeYourPlanBtn}
                                    </Link>
                                )}
                            </div>
                        </div>
                    </div>
                );
            }}
        />
    );
};

export default FieldsRestrictionTooltipContent;
