import * as React from 'react';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import FolderNames from './../../data/constants/FolderNames';
import StringHelper from './../../helpers/StringHelper';
import Strings from '../../strings';
import { mergeStyles } from '@fluentui/react';
import type { TSecondaryPanelVariant } from '../layout/SecondaryPanel';
import SideMenuGrid from './SideMenuGrid';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import LicenseRestrictionsLockIcon from './locks/LicenseRestrictionsLockedIcon';
import type { TFolderName } from '@eway-crm/connector';
import { LicenseRestrictionsModalVariant } from '@eway-crm/gui';

type TGridRow = {
    folder: string;
    folderName: string;
};

type TFoldersMenuProps = {
    folderNames: string[];
    selectedFolderName: string | null;
    searchedString: string;
    isCollapsed?: boolean;
    variant?: TSecondaryPanelVariant;
    onFolderChange: (newFolder: string) => void;
    height?: number;
    onRenderCellSuffix?: (props: Table.DataCellProps) => JSX.Element | null;
};

const myStrings = Strings.components.shared.foldersMenu;
const gridColumns = [
    { name: 'icon', title: '' },
    { name: 'folder', title: '' },
];

const columnExtensions: VirtualTable.ColumnExtension[] = [
    { columnName: 'icon', width: '54px' },
    { columnName: 'folder', width: '190px' },
];
const gridColumnsCollapsed = gridColumns.filter((col) => col.name !== 'folder');
const columnExtensionsCollapsed = columnExtensions.filter((col) => col.columnName !== 'folder');

class FoldersMenu extends React.Component<TFoldersMenuProps> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly getDisplayedData = (): TGridRow[] => {
        return this.props.folderNames.map((folderName) => ({ folder: FolderNames.getPluralName(folderName), folderName: folderName })).sort((a, b) => StringHelper.localeCompare(a.folder, b.folder));
    };

    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = (props) => {
        const typedRow = props.row as TGridRow;

        if (props.column.name === 'icon') {
            return (
                <VirtualTable.Cell className={mergeStyles('secondary-menu__iconCell', this.props.variant === 'small' && 'secondary-menu__iconCell--small')} {...props}>
                    <img src={FolderNames.getIcon(typedRow.folderName)} alt={typedRow.folder} className="secondary-menu__icon" />
                </VirtualTable.Cell>
            );
        }

        const { isFolderNameLocked, folderNameLicenseRestriction } = this.context.licenseRestrictionsHelper.isFolderNameLocked(typedRow.folderName as TFolderName);
        return (
            <VirtualTable.Cell className={mergeStyles('align-middle pl-0 secondary-menu__contentCell', this.props.variant === 'small' && 'secondary-menu__contentCell--small')} {...props}>
                <div className="d-flex">{props.value} {isFolderNameLocked && <LicenseRestrictionsLockIcon isSmall={false} className="ml-1" featureLockModalData={{ variant: LicenseRestrictionsModalVariant.module }} licenseRestriction={folderNameLicenseRestriction}/>} {this.props.onRenderCellSuffix?.(props)}</div>
            </VirtualTable.Cell>
        );
    };

    private readonly onMenuItemChange = (newFolderName: string) => {
        const { isFolderNameLocked, folderNameLicenseRestriction } = this.context.licenseRestrictionsHelper.isFolderNameLocked(newFolderName as TFolderName);
        if (isFolderNameLocked) {
            this.context.showLicenseRestrictionModal(folderNameLicenseRestriction, { variant: LicenseRestrictionsModalVariant.module });
            return;
        }

        this.props.onFolderChange(newFolderName);
    };

    render() {
        return (
            <SideMenuGrid
                items={this.getDisplayedData()}
                columns={this.props.isCollapsed ? gridColumnsCollapsed : gridColumns}
                getRowId={(row) => row.folderName}
                selectedItemId={this.props.selectedFolderName}
                onMenuItemChange={this.onMenuItemChange}
                searchedString={this.props.searchedString}
                cellComponent={this.CellComponent}
                columnExtensions={this.props.isCollapsed ? columnExtensionsCollapsed : columnExtensions}
                noDataText={myStrings.noFoldersMatch}
                height={this.props.height}
            />
        );
    }
}

export default FoldersMenu;
