import { mergeStyles } from '@fluentui/react';
import * as React from 'react';
import Cookies from '../../Cookies';

type TCookie = {
    isMenuExpanded: boolean;
};

export type TSecondaryPanelVariant = 'small';

type TSecondaryPanelProps = React.PropsWithChildren & {
    panelHead: React.ComponentType;
    collapseConfig?: { cookieName: string };
    panelBody: React.ComponentType<{ isCollapsed: boolean }>;
    variant?: TSecondaryPanelVariant;
    onMenuCollapsedChange?: (isCollapsed: boolean) => void;
};

type TSecondaryPanelState = {
    isMenuExpanded: boolean;
};

export class SecondaryPanel extends React.Component<TSecondaryPanelProps, TSecondaryPanelState> {

    private static readonly expandedWidth = '265px';
    private static readonly collapsedWidth = '66px';

    constructor(props: TSecondaryPanelProps) {
        super(props);

        let expanded = true;
        if (props.collapseConfig) {
            const cookie = Cookies.getCookie<TCookie>(props.collapseConfig.cookieName);
            if (typeof cookie.isMenuExpanded !== "undefined") {
                expanded = cookie.isMenuExpanded || false;
            }
        }
        this.state = {
            isMenuExpanded: expanded
        };
    }
    
    private readonly setMenuExpanded = (isExpanded: boolean) => {
        if (this.props.collapseConfig) {
            Cookies.setCookie<TCookie>(
                this.props.collapseConfig.cookieName,
                {
                    isMenuExpanded: isExpanded
                },
                365 * 24
            );
        }
        this.setState({ isMenuExpanded: isExpanded }, () => {
            this.props.onMenuCollapsedChange && this.props.onMenuCollapsedChange(!isExpanded);
        });
    };

    render() {
        const PanelHead = this.props.panelHead;
        const PanelBody = this.props.panelBody;

        const leftPanelWidth = this.state.isMenuExpanded ? SecondaryPanel.expandedWidth : SecondaryPanel.collapsedWidth;

        return (
            <div className="container-fluid d-flex h-100 flex-column">
                <div className="row flex-fill d-flex h-100">
                    <div className={mergeStyles("col-auto p-0 h-100 border-right border-gray bg-white side-menu--elevated", this.props.variant === "small" ? 'secondary-menu--small' : 'secondary-menu')} >
                        <div className="container-fluid d-flex h-100 flex-column" style={{ width: leftPanelWidth }}>
                            <div className="row flex-nowrap">
                                {!this.props.collapseConfig ? (
                                    <PanelHead />
                                ) : (
                                    <>
                                        <div className={mergeStyles('col', !this.state.isMenuExpanded && 'p-0')}>{this.state.isMenuExpanded && <PanelHead />}</div>
                                        <div className="col-auto px-0 align-self-end">
                                            {this.state.isMenuExpanded ? (
                                                <button onClick={() => this.setMenuExpanded(false)} className="btn btn-outline-secondary content-justify-right py-2">
                                                    <i className="mdl2 mdl2-scroll-chevron-left-legacy" aria-hidden="true" />
                                                </button>
                                            ) : (
                                                <button onClick={() => this.setMenuExpanded(true)} className="btn btn-outline-secondary content-justify-right py-2">
                                                    <i className="mdl2 mdl2-scroll-chevron-right-legacy" aria-hidden="true" />
                                                </button>
                                            )}
                                        </div>
                                    </>
                                )}
                            </div>
                            <div className={mergeStyles("row flex-fill d-flex", this.props.collapseConfig && "mt-3")}>
                                <div className="col p-0">
                                    <PanelBody isCollapsed={!this.state.isMenuExpanded} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col p-0 h-100" style={{ width: 'calc(100% - ' + leftPanelWidth + ' - 1px)' }}>
                        {this.props.children}
                    </div>
                </div>
            </div>
        );
    }
}