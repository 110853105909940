export default class EnumValuesColumns {
    static EDIT_GRID_COLS = {
        dragColumn: 'drag',
        editColumn: 'edit',
        isVisible: 'IsVisible',
        isDefault: 'IsDefault',
        useForLastActivity: 'IncludeInLastActivityCalculation',
        addTranslations: 'addTranslations',
        technicalName: 'FileAs',
        en: 'En',
        cs: 'Cs',
        de: 'De',
        ru: 'Ru',
        sk: 'Sk',
        no: 'No',
    } as const;

    static LANGUAGE_COLUMNS: string[] = [
        EnumValuesColumns.EDIT_GRID_COLS.en,
        EnumValuesColumns.EDIT_GRID_COLS.cs,
        EnumValuesColumns.EDIT_GRID_COLS.de,
        EnumValuesColumns.EDIT_GRID_COLS.no,
        EnumValuesColumns.EDIT_GRID_COLS.sk,
        EnumValuesColumns.EDIT_GRID_COLS.ru,
        EnumValuesColumns.EDIT_GRID_COLS.technicalName,
    ];
}