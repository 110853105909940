import type { IApiColumnPermission } from "@eway-crm/connector";
import { ColumnPermissionPermissionRules } from "@eway-crm/connector";

export type TMyColumnPermissionsMap = Record<string, IApiColumnPermission>;

export default class WizardBase {
    
    static getFieldDisabledByPermissions = (fieldName: string, myColumnPermissionsMap: TMyColumnPermissionsMap) => {
        const colPermission = myColumnPermissionsMap[fieldName];
        if (!colPermission) {
            return null;
        }

        const isDisabled = (
            colPermission.PermissionRule === ColumnPermissionPermissionRules.readonly ||
            colPermission.PermissionRule === ColumnPermissionPermissionRules.invisible ||
            colPermission.PermissionRule === ColumnPermissionPermissionRules.none
        );

        return {
            isDisabled,
            permissionRule: colPermission.PermissionRule
        };
    };
}