import * as React from 'react';
import DefaultFormLayoutsGrid, { type TFormLayoutGroupMapping } from './DefaultFormLayoutsGrid';
import { Form } from 'react-bootstrap';
import Strings from '../../../../strings';
import type { IApiLayout, TFolderName } from '@eway-crm/connector';
import { InfoRegular } from '@fluentui/react-icons';

type TDefaultFormLayoutsFormProps = {
    gridHeight: string | number;
    availableLayouts: IApiLayout[];
    mappings: TFormLayoutGroupMapping[];
    onMappingChange: (folderName: TFolderName, layoutGuid: string | null) => void;
};

const DefaultFormLayoutsForm: React.FC<TDefaultFormLayoutsFormProps> = (props) => {
    const [searchedString, setSearchString] = React.useState('');

    return (
        <>
            <div className={'row mb-2'}>
                <div className="col-auto align-self-center">
                    <div className="m-1 d-inline" style={{ fontSize: '16px', position: 'relative', top: '-1px' }}>
                        <InfoRegular />
                    </div>
                    {Strings.components.routes.groups.defaultFormLayouts.waOnly}
                </div>
                <div className="ml-auto col-auto">
                    <Form.Control type="text" placeholder={Strings.search} value={searchedString} onChange={(e) => setSearchString(e.target.value)} />
                </div>
            </div>
            <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                <div className="row flex-fill d-flex justify-content-start">
                    <div className="col">
                        <DefaultFormLayoutsGrid
                            gridHeight={props.gridHeight}
                            searchedString={searchedString}
                            availableLayouts={props.availableLayouts}
                            mappings={props.mappings}
                            onMappingChange={props.onMappingChange}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DefaultFormLayoutsForm;
