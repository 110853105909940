import { BrowserPostMessages } from '@eway-crm/gui';

export default class IframePostMessageHelper {
    static readonly checkoutLoading = () => {
        window.top?.postMessage(BrowserPostMessages.checkoutLoading, '*');
    };

    static readonly checkoutLoaded = () => {
        window.top?.postMessage(BrowserPostMessages.checkoutLoaded, '*');
    };

    static readonly windowClose = () => {
        window.top?.postMessage(BrowserPostMessages.windowClose, '*');
        window.top?.chrome?.webview?.postMessage(BrowserPostMessages.windowClose);
    };

    static readonly checkoutCompleted = () => {
        window.top?.postMessage(BrowserPostMessages.checkoutCompleted, '*');
        window.top?.chrome?.webview?.postMessage(BrowserPostMessages.checkoutCompleted);
    };
}
