import React from 'react';
import type { Color } from 'react-bootstrap/esm/types';

type TAlertProps = React.HTMLProps<HTMLDivElement> & {
    // We use type Color instead of Variant for IntelliSense because Variant is simplified to type string
    variant: Exclude<Color, 'white' | 'muted'>;
};

const Alert: React.FC<TAlertProps> = ({ children, variant, style, className, ...props }) => {
    return (
        <div style={{ position: 'absolute', bottom: 0, right: 0, ...style }} className={`alert alert-${variant} d-inline-block ${className}`} {...props}>
            {children}
        </div>
    );
};

export default Alert;
