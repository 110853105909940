import type { IApiGroup } from '@eway-crm/connector';
import React, { useMemo } from 'react';
import injectProps from '../../../../helpers/components/injectProps';
import type { TModulePermissionWithFolder } from '../../ModulePermissions';
import type { TModulePermissionsCellWrapperProps } from '../../modulePermissions/ModulePermissionsGrid';
import ModulePermissionsGrid from '../../modulePermissions/ModulePermissionsGrid';
import type { TCustomUserLicenseRestrictionsObject } from '../EffectivePermissionsWizard';
import type { TModulePermissionsCellTooltipAdditionalProps } from './EffectiveModulePermissionsCellTooltip';
import EffectiveModulePermissionsCellTooltip from './EffectiveModulePermissionsCellTooltip';

type TEffectiveModulePermissionsTabProps = {
    modulePermissions: TModulePermissionWithFolder[];
    userGroups: IApiGroup[];
    customUserLicenseRestrictionsObject: TCustomUserLicenseRestrictionsObject;
};

const EffectiveModulePermissionsTab: React.FC<TEffectiveModulePermissionsTabProps> = ({ modulePermissions, userGroups, customUserLicenseRestrictionsObject }) => {
    const cellWrapper = useMemo(
        () => injectProps<TModulePermissionsCellWrapperProps, TModulePermissionsCellTooltipAdditionalProps>(EffectiveModulePermissionsCellTooltip, { userGroups }),
        [userGroups]
    );

    return (
        <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
            <div className="row flex-fill d-flex justify-content-start">
                <div className="col">
                    <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                        <div className="row flex-fill d-flex justify-content-start">
                            <div className="col">
                                <ModulePermissionsGrid
                                    modulePermissions={modulePermissions}
                                    gridHeight={'25rem'}
                                    searchedString={''}
                                    disabled
                                    cellWrapper={cellWrapper}
                                    customUserLicenseRestrictionsObject={customUserLicenseRestrictionsObject}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EffectiveModulePermissionsTab;
