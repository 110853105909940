import { DefaultButton, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import Strings from '../../../../../../strings';
import CollapsibleCheckoutSection from '../../../../../shared/subscriptions/CollapsibleCheckoutSection';
import { CompanyDetailsForm } from '../../../../../shared/subscriptions/CompanyDetailsForm';
import StoreHelper from '../../../StoreHelper';
import type { TCustomerInfo } from '../../../TCustomerInfo';
import StoreV2Context from '../../StoreV2Context';

type TCompanyDetailsFormContainerProps = {
    isEditingCompanyInfo: boolean;
    onCompanyInfoEditingBegin: () => void;
    onCompanyInfoEditingEnd: () => void;
};

const CompanyDetailsFormContainer: React.FC<TCompanyDetailsFormContainerProps> = ({ isEditingCompanyInfo, onCompanyInfoEditingBegin, onCompanyInfoEditingEnd }) => {
    const { customerInfo, setCustomerInfo, countries, collectPersonName } = React.useContext(StoreV2Context);
    const [isValidated, setIsValidated] = React.useState(false);
    const reference = React.useRef<HTMLFormElement>(null);
    const isCompanyInfoValid = StoreHelper.isCompanyInfoValid(customerInfo);
    const [isReadonly, setIsReadonly] = React.useState(isCompanyInfoValid);

    const myStrings = Strings.components.routes.subscriptions;

    const handleCustomerInfoChange = React.useCallback((newCustomerInfo: Partial<TCustomerInfo>) => {
        onCompanyInfoEditingBegin();
        setCustomerInfo(newCustomerInfo);
    }, [onCompanyInfoEditingBegin, setCustomerInfo]);

    const handleApply = () => {
        if (!reference.current)
            return;

        if (!reference.current.checkValidity()) {
            if (!isValidated) {
                setIsValidated(true);
            }
            return;
        }

        setIsReadonly(true);
        onCompanyInfoEditingEnd();
    };

    return (
        <div>
            <CollapsibleCheckoutSection title={myStrings.companyDetails} mobileSubtitle={customerInfo.businessName} disableCollapsing={!isReadonly}>
                <CompanyDetailsForm
                    countries={countries}
                    isFormValidated={isValidated}
                    formReference={reference}
                    customerInfo={customerInfo}
                    hideHeadings // Hide because we use custom headings in this component
                    hidePersonName={!collectPersonName}
                    onCustomerInfoChanged={handleCustomerInfoChange}
                    isReadonly={isReadonly}
                    onIsReadonlyChange={(isEditing) => setIsReadonly(isEditing)}
                />
                {(isEditingCompanyInfo) &&
                    <PrimaryButton text={myStrings.applyCustomerDetails} onClick={handleApply} disabled={!isCompanyInfoValid} />
                }
                {(!isEditingCompanyInfo && !isReadonly) && <DefaultButton onClick={() => setIsReadonly(true)}>{Strings.discard}</DefaultButton>}
            </CollapsibleCheckoutSection>
        </div>
    );
};

export default CompanyDetailsFormContainer;