import React from "react";
import getPrice from "../../../getPrice";
import StoreHelper from "../../../StoreHelper";
import useItemsInCart from "../../hooks/useItemsInCart";
import StoreV2Context from "../../StoreV2Context";
import { mainDisplayedPeriod } from "../Summary";

const usePackagePriceSum = () => {
    const { customerInfo, storeData, selectedPeriodType, isPackageInCart } = React.useContext(StoreV2Context);
    const items = useItemsInCart();

    return items.map(item => {
        const packageData = StoreHelper.getPackageByArtno(storeData, item.artno);
        if (!packageData)
            return { price: 0, mainPeriodPrice: 0, wouldaMainPeriodPrice: 0, monthlyPeriodPrice: 0, yearlyPeriodPrice: 0 };

        const quantity = item.state.currentEditQuantity || 0;
        const unitPrice = getPrice(packageData, quantity, customerInfo.currency, selectedPeriodType, isPackageInCart);
        const mainPeriodUnitPrice = getPrice(packageData, quantity, customerInfo.currency, selectedPeriodType, isPackageInCart, mainDisplayedPeriod);
        const wouldaSelectedPeriodUnitPrice = getPrice(packageData, quantity, customerInfo.currency, mainDisplayedPeriod, isPackageInCart, selectedPeriodType);
        const monthlyPeriodUnitPrice = getPrice(packageData, quantity, customerInfo.currency, "Monthly", isPackageInCart, "Monthly");
        const yearlyPeriodUnitPrice = getPrice(packageData, quantity, customerInfo.currency, "Yearly", isPackageInCart, "Monthly");

        return {
            price: quantity * unitPrice,
            mainPeriodPrice: quantity * mainPeriodUnitPrice,
            wouldaMainPeriodPrice: quantity * wouldaSelectedPeriodUnitPrice,
            monthlyPeriodPrice: quantity * monthlyPeriodUnitPrice,
            yearlyPeriodPrice: quantity * yearlyPeriodUnitPrice
        };
    }).reduce((acc, item) => ({
        price: acc.price + item.price,
        mainPeriodPrice: acc.mainPeriodPrice + item.mainPeriodPrice,
        wouldaMainPeriodPrice: acc.wouldaMainPeriodPrice + item.wouldaMainPeriodPrice,
        savedPerMonthOnYearlyPlanPrice: acc.savedPerMonthOnYearlyPlanPrice + (item.monthlyPeriodPrice - item.yearlyPeriodPrice)
    }), { price: 0, mainPeriodPrice: 0, wouldaMainPeriodPrice: 0, savedPerMonthOnYearlyPlanPrice: 0 });
};

export default usePackagePriceSum;