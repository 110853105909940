import * as React from 'react';
import ewayLogo from '@eway-crm/js-resources/assets/ewayLogo/eWayCRM/eWayCRM-red.svg';
import fieldsImg from '../img/welcomeModal/custom-fields.svg';
import featuresImg from '../img/welcomeModal/features.svg';
import permissionsImg from '../img/welcomeModal/permissions.svg';
import workflowImg from '../img/welcomeModal/workflow.svg';

import type { Connection } from './layout/connection/Connection';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { IApiUserSetting } from '@eway-crm/connector';
import { UserSettingsKeys } from '../data/constants/UserSettingsKeys';
import type { IApiSaveResponse } from '@eway-crm/connector';
import { NewVersionDialog } from '@eway-crm/gui';
import Strings from '../strings';

export type TVersion =
    | 0 // pre 6.0.1
    | 1 // stable 6.0.1
    | 2 // stable 6.1.0
    | 3; // stable 7.0.0

const versions: { [key: string]: TVersion } = {
    pre601: 0, // pre 6.0.1
    stable601: 1, // stable 6.0.1
    stable610: 2, // stable 6.1.0
    stable700: 3, // stable 7.0.0
};

export const currentVersion: TVersion = versions.stable700;

type TWelcomeModalProps = {
    onDismiss: () => void;
};

type TWelcomeModalState = Record<string, never>;

export class WelcomeModal extends React.Component<TWelcomeModalProps, TWelcomeModalState> {
    static async loadLastSeenVersion(connection: Connection) {
        const result = await connection.askApi<IApiDataResponse<IApiUserSetting>>(
            'SearchUserSettings',
            {
                transmitObject: {
                    Path: UserSettingsKeys.welcomePath,
                    Name: UserSettingsKeys.welcomeName,
                },
            }
        );

        let lastSeenVersion: number | null = null;
        if (result.Data.length > 0) {
            const usValue = result.Data[0].Value;
            if (usValue !== null) {
                const numberValue = Number(usValue);
                if (Number.isInteger(numberValue)) {
                    lastSeenVersion = WelcomeModal.convertToVersion(numberValue);
                }
            }
        }

        const showWelcome = lastSeenVersion !== null && lastSeenVersion < currentVersion;

        if (lastSeenVersion === null) { // User was never in WebAdmin before so save version for next upgrade
            WelcomeModal.setWelcomeSeen(connection);
        }

        return showWelcome;
    }

    private static convertToVersion(number: number): TVersion {
        if (number < versions.pre601)
            return versions.pre601;
        if (number > currentVersion)
            return currentVersion;

        const version = Object.keys(versions).findMap<TVersion>((key: string): TVersion | undefined => {
            if (versions[key] === number)
                return versions[key];
            else
                return undefined;
        });
        if (!version && version !== 0)
            return currentVersion;

        return version;
    }

    static setWelcomeSeen(connection: Connection, callback?: (result: IApiSaveResponse) => void) {
        const transmitObject: Partial<IApiUserSetting> = {
            Path: UserSettingsKeys.welcomePath,
            Name: UserSettingsKeys.welcomeName,
            Value: currentVersion.toString(),
        };
        connection.callApi('SaveUserSetting', { transmitObject }, (res: IApiSaveResponse) => {
            callback && callback(res);
        });
    }

    render() {
        const slides = newVersionConfig[Strings.getLanguage() as keyof typeof newVersionConfig].slides;
        return <NewVersionDialog slides={slides} onClose={this.props.onDismiss} />;
    }
}

const newVersionConfig = {
    en: {
        slides: [
            {
                imgPath: ewayLogo,
                imgStyle: { width: '70%', height: '70%', marginTop: "40px" },
                title: 'This is the New Version',
                subTitle: 'Have a look at the most exciting changes.',
            },
            {
                imgPath: featuresImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Features',
                subTitle: 'Meet our new module and set up all global settings, module permissions, and field permissions in one place.',
            },
            {
                imgPath: fieldsImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Fields',
                subTitle: 'Set up your custom fields and manage the standard ones in the completely redesigned section.',
            },
            {
                imgPath: workflowImg,
                imgStyle: { width: '85%', height: '85%', marginTop: "40px" },
                title: '"Basic" Workflow',
                subTitle: 'Meet the new type of workflow called "Basic". It\'s great if you need a simple list of steps with no advanced actions.',
            },
            {
                imgPath: permissionsImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Module Permissions',
                subTitle: 'We took the module permissions and redesigned it from scratch. Keep your data protected.',
            },
        ],
    },
    cs: {
        slides: [
            {
                imgPath: ewayLogo,
                imgStyle: { width: '70%', height: '70%' },
                title: 'Toto je nová verze',
                subTitle: 'Podívejte se na ty největší novinky',
            },
            {
                imgPath: featuresImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Funkce',
                subTitle: 'Seznamte se s naším novým modulem a upravte si globální nastavení, oprávnění na moduly a oprávnění na pole na jednom místě.',
            },
            {
                imgPath: fieldsImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Pole',
                subTitle: 'Nastavte si uživatelská pole a spravujte ta standardní v kompletně přepracované sekci.',
            },
            {
                imgPath: workflowImg,
                imgStyle: { width: '85%', height: '85%', marginTop: "40px" },
                title: '"Základní" Workflow',
                subTitle: 'Seznamte se s novým typem workflow "Základní". Skvěle se hodí všem, kdo potřebují pouze základní seznam stavů bez pokročilých akcí.',
            },
            {
                imgPath: permissionsImg,
                imgStyle: { width: '80%', height: '80%', marginTop: "40px" },
                title: 'Práva na moduly',
                subTitle: 'Vzali jsme naše oprávnění na moduly a úplně je předělali. Chraňte svá data.',
            },
        ],
    },
};