import type { Edition, Feature } from '@eway-crm/connector';
import * as React from 'react';
import type { Location, LocationState } from 'history';
import type { IPackageModel, IStoreModel } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import StoreHelper from '../../StoreHelper';
import type TPackagesEditingStates from '../TPackagesEditingStates';
import type { TPackageEditingStateBase } from '../../TPackageEditingState';

const useAddToCartFromUrlEffect = (
    location: Location<LocationState>,
    storeData: IStoreModel,
    activeUsersCount: number,
    featureToPakagesMap: Partial<Record<Feature, { artno: string; edition: Edition }[]>>,
    defaultPackagesStates: TPackagesEditingStates,
    setMultiplePackagesEditingStates: (newStates: { artno: string; newState: Partial<TPackageEditingStateBase> }[]) => void,
    setIsCheckingOut: (isCheckingOut: boolean) => void
) => {
    const locationSearch = location.search;
    React.useEffect(() => {
        const locParams = new URLSearchParams(locationSearch);
        if (!locParams.has('addFeaturesToCart'))
            return;

        const goToCheckout = locParams.has('goToCheckout') && locParams.get('goToCheckout') === '1';

        const featuresStr = locParams.get('addFeaturesToCart');
        if (!featuresStr)
            return;

        const parts = featuresStr.split(',');
        const artnosToAdd = parts.map((part) => {
            if (!part)
                return null;

            const [feature, edition] = part.split(':');
            if (!feature || !edition)
                return null;

            const found = featureToPakagesMap[feature as Feature];
            if (!found)
                return null;

            return found.find(obj => obj.edition === edition as Edition)?.artno;
        }).filter(artno => !!artno) as string[];

        const finalPackagesToAdd: { artno: string; quantity: number }[] = [];
        const finalArtnosToRemove: string[] = [];
        const isPackageInDefaultCart = (artno: string) => {
            if (defaultPackagesStates.some(ps => ps.artno === artno && ps.state.currentEditQuantity && ps.state.currentEditQuantity > 0) && !finalArtnosToRemove.includes(artno)) {
                return true;
            }

            return finalPackagesToAdd.some(pa => pa.artno === artno && !!pa.quantity);
        };

        const enqueueToAdd = (packageData: IPackageModel) => {
            let quantity = activeUsersCount;

            // If there is another package with the same feature, take the quantity from there and enqueue it for removing.
            const exactFeature = StoreHelper.getExactSingleFeatureAndEdition(packageData);
            if (exactFeature) {
                const competitors = defaultPackagesStates.filter(ps => {
                    if (!ps.state.currentEditQuantity)
                        return false;

                    const otherPackageData = StoreHelper.getPackageByArtno(storeData, ps.artno);
                    const otherPackageExactFeature = StoreHelper.getExactSingleFeatureAndEdition(otherPackageData);
                    return otherPackageExactFeature && otherPackageExactFeature.Feature === exactFeature.Feature;
                });
                if (competitors.length !== 0) {
                    quantity = competitors[0].state.currentEditQuantity!;
                    finalArtnosToRemove.push(...competitors.map(ps => ps.artno));
                }
            }

            finalPackagesToAdd.push({ artno: packageData.Artno!, quantity });
        };

        while (artnosToAdd.length !== 0) {
            const artno = artnosToAdd.pop()!;
            const packageData = StoreHelper.getPackageByArtno(storeData, artno);
            if (isPackageInDefaultCart(artno) || !packageData)
                continue;

            const { isDisabled, enablingArtnos } = StoreHelper.isPackageDisabled(packageData, isPackageInDefaultCart);
            if (!isDisabled) {
                enqueueToAdd(packageData);
                continue;
            }

            if (!enablingArtnos || enablingArtnos.length === 0) {
                // Cannot add.
                console.warn(`Cannot add package '${artno}' to cart. It is disabled and no enabling package is available.`);
                continue;
            }

            let neededArtno = enablingArtnos[0];

            // From enabling artnos, try to pick the one with the same edition.
            const sameEditionArtno = StoreHelper.getArtnoWithSameEdition(packageData, enablingArtnos, storeData);
            if (sameEditionArtno) {
                neededArtno = sameEditionArtno;
            }

            // Stack logic
            artnosToAdd.push(neededArtno);
            artnosToAdd.unshift(artno);
        }

        setMultiplePackagesEditingStates([
            ...finalArtnosToRemove.map(artno => ({ artno, newState: { currentEditQuantity: 0 } })),
            ...finalPackagesToAdd.map(p => ({ artno: p.artno, newState: { currentEditQuantity: p.quantity } }))
        ]);

        if (goToCheckout) {
            setIsCheckingOut(true);
        }
    }, [locationSearch, storeData, activeUsersCount, featureToPakagesMap, defaultPackagesStates, setMultiplePackagesEditingStates, setIsCheckingOut]);
};

export default useAddToCartFromUrlEffect;