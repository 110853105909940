import { CommandButton } from '@fluentui/react';
import React from 'react';
import type { IStripePaymentMethod } from '../../../data/shopping/IPaymentMethodsModel';
import Strings from '../../../strings';
import '../../../theme/credit-card.scss';
import { Subscriptions } from '../../routes/Subscriptions';
import StoreHelper from '../../routes/subscriptions/StoreHelper';
import useTotalPrices from '../../routes/subscriptions/StoreV2/CheckoutV2/hooks/useTotalPrices';
import StoreV2Context from '../../routes/subscriptions/StoreV2/StoreV2Context';
import type TCheckoutItem from '../../routes/subscriptions/TCheckoutItem';

type TCreditCardProps = React.HTMLProps<HTMLDivElement> & {
    items: TCheckoutItem[];
    availableCards: IStripePaymentMethod[];
    selectedCard: IStripePaymentMethod;
    onCardSelected: (id: string) => void;
    onAddNewCard: () => void;
};

export const getCardShortTitle = (card: IStripePaymentMethod) => `**** ${card.Title?.substring(Math.max(card.Title.length - 4, 0))}`;

const CreditCard: React.FC<TCreditCardProps> = ({ items, availableCards, selectedCard, onCardSelected, onAddNewCard, ...restProps }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const { customerInfo, selectedPeriodType } = React.useContext(StoreV2Context);

    const { totalPriceWithoutTaxes } = useTotalPrices(items);

    const dropdownCardOptions = React.useMemo(() => [
        ...availableCards.map(card => ({
            key: card.Id,
            text: `${card.Brand} ${getCardShortTitle(card)}`,
            iconProps: { iconName: 'PaymentCard' },
            onClick: () => onCardSelected(card.Id)
        })),
        {
            key: '__new',
            text: Strings.components.routes.billingDetails.addNew,
            iconProps: { iconName: 'Add' },
            onClick: onAddNewCard
        }
    ], [availableCards, onCardSelected, onAddNewCard]);

    return (
        <div {...restProps}>
            <div className="rccs">
                <div className={`rccs__card rccs__card--${selectedCard.Brand?.toLowerCase() ?? 'unknown'}`}>
                    <div className="rccs__card--front">
                        <div className="rccs__card__background" />
                        <CommandButton className="rccs__switch-btn" menuProps={{ items: dropdownCardOptions }} text={myStrings.switchCard} />
                        <div className="rccs__issuer" />
                        <div className="rccs__pay-wrap">
                            <div className="rccs__pay-now">{myStrings.toBePaidNow}: $00000</div>
                            <div className="rccs__subscription">{myStrings.total}: {Strings.formatCurrency(totalPriceWithoutTaxes, Subscriptions.priceDecPlaces, customerInfo.currency)}&nbsp;/&nbsp;{StoreHelper.getPeriodStrings(selectedPeriodType).unit}</div>
                        </div>
                        <div className="rccs__number">{getCardShortTitle(selectedCard)?.replaceAll('*', '•')}</div>
                        <div className="rccs__chip" />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CreditCard;
