import type { IApiLocalizedLicenseBundle } from '@eway-crm/connector';
import { Feature } from '@eway-crm/connector';
import { FeatureAndEditionHelper } from '@eway-crm/gui';
import type {
    IComboBox,
    IComboBoxOption,
    IComboBoxOptionStyles,
    IRenderFunction,
    ISelectableOption,
    IComboBoxProps} from '@fluentui/react';
import {
    mergeStyles,
    VirtualizedComboBox,
    Link as FluentLink,
    Dialog,
    PrimaryButton,
    DefaultButton,
    DialogFooter,
} from '@fluentui/react';
import { useState } from 'react';
import { Badge } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import RouteConfig from '../../../RouteConfig';
import Strings from '../../../strings';
import { UserWizardBase } from './UserWizardBase';

// Make options whole content clickable. It is too narrow by default.
const COMBOBOX_OPTION_STYLES: Partial<IComboBoxOptionStyles> = { label: { minHeight: '32px' } };

type TLicenseCombobox = {
    availableLicenses: IApiLocalizedLicenseBundle[];
    assignedLicenses: IApiLocalizedLicenseBundle[];
    isApiUser: boolean;
    disabled?: boolean;
    onLicenseChange: (props: { isApiUser: boolean; assignedLicenses: IApiLocalizedLicenseBundle[]; availableLicenses: IApiLocalizedLicenseBundle[] }) => void;
    useComboBoxAsMenuWidth?: boolean;
};

export const LicensePicker: React.FC<TLicenseCombobox> = ({ availableLicenses, disabled, assignedLicenses, isApiUser, onLicenseChange, useComboBoxAsMenuWidth }) => {
    const [showAlert, setShowAlert] = useState(false);
    const myStrings = Strings.components.routes.users;
    const selectedKeys = assignedLicenses.map((al) => al.Code).concat(isApiUser ? [UserWizardBase.apiUserKeyCode] : []);
    const comboBoxOptions = UserWizardBase.getLicenseOptionsForDropdown(availableLicenses);
    const contactsAndCompaniesLicense = availableLicenses.find((al) => al.Features.find((f) => f.Feature === Feature.ContactsAndCompanies));

    const onRenderLicenseOption: IRenderFunction<ISelectableOption<IApiLocalizedLicenseBundle>> = (props, defaultRender) => {
        if (props && props.data) {
            const bundle = props.data;
            const isSelected = assignedLicenses.some((al) => al.Code === props.key);
            const isExceeded = !isSelected && bundle.FreeSlotsCount === 0;
            props.text = (
                <span className={mergeStyles(isExceeded && 'text-secondary')}>
                    {`${bundle.Name} (${bundle.FreeSlotsCount}/${bundle.Quantity})`}{' '}
                    {isExceeded && (
                        <Link to={RouteConfig.billing.subscriptions.path} target="_blank" rel="noopener noreferrer">
                            <Badge variant="warning cursor-pointer">{myStrings.buyMore}</Badge>
                        </Link>
                    )}
                </span>
            ) as unknown as string;
        }

        if (props && props.key === UserWizardBase.apiUserKeyCode) {
            props.selected = isApiUser;
        }

        return defaultRender?.(props) ?? null;
    };

    const handleLicenseChange = (e: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
        if (option && option.key === UserWizardBase.apiUserKeyCode) {
            // Api User does not have any other licenses
            const newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses(assignedLicenses, availableLicenses, true);
            onLicenseChange({ assignedLicenses: [], availableLicenses: newAvailableLicenses, isApiUser: !!option.selected });
            return;
        }

        const changedLicense = availableLicenses.find((al) => al.Code === option?.key);
        if (option && changedLicense) {
            if (changedLicense.FreeSlotsCount === 0 && option.selected) {
                // Do not select license with no available slots
                return;
            }

            const isLicenseWithAccessToAnyPlatform = UserWizardBase.isLicenseWithAccessToAnyPlatform(changedLicense);
            let newAvailableLicenses: IApiLocalizedLicenseBundle[] = availableLicenses;
            let newAssignedLicenses: IApiLocalizedLicenseBundle[] = assignedLicenses;

            if (option.selected) {
                const licensesWithAlreadyAssignedFeaturesToRemove = UserWizardBase.getLicensesWithAlreadyAssignedFeatures(changedLicense, newAssignedLicenses);
                if (licensesWithAlreadyAssignedFeaturesToRemove && licensesWithAlreadyAssignedFeaturesToRemove.length !== 0) {
                    // Remove all assigned licenses that have same features as newly assigned license
                    const licensesWithAlreadyAssignedFeaturesToRemoveCodes = licensesWithAlreadyAssignedFeaturesToRemove.map((l) => l.Code);
                    newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses(licensesWithAlreadyAssignedFeaturesToRemove, newAvailableLicenses, true);
                    newAssignedLicenses = newAssignedLicenses.filter((asl) => !licensesWithAlreadyAssignedFeaturesToRemoveCodes.includes(asl.Code));
                }

                if (!isLicenseWithAccessToAnyPlatform && !newAssignedLicenses.some((asl) => UserWizardBase.isLicenseWithAccessToAnyPlatform(asl))) {
                    // Cannot add just a license without user having access to any platform
                    const { newLicense: bestAvailableLicense, newAvailableLicenses: _newAvailableLicenses } = UserWizardBase.takeBestAvailableLicense(newAvailableLicenses);
                    newAvailableLicenses = _newAvailableLicenses;
                    if (bestAvailableLicense && UserWizardBase.isLicenseWithAccessToAnyPlatform(bestAvailableLicense)) {
                        newAssignedLicenses.push(bestAvailableLicense);
                    } else {
                        setShowAlert(true);
                        return;
                    }
                }

                newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses([changedLicense], newAvailableLicenses);
                newAssignedLicenses = [...newAssignedLicenses, changedLicense];
            } else {
                if (isLicenseWithAccessToAnyPlatform) {
                    // User has removed the only license with access to any platform. Remove all other licenses as well.
                    newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses(newAssignedLicenses, newAvailableLicenses, true);
                    newAssignedLicenses = [];
                    onLicenseChange({ assignedLicenses: newAssignedLicenses, availableLicenses: newAvailableLicenses, isApiUser: false });
                    return;
                }

                newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses([changedLicense], newAvailableLicenses, true);
                newAssignedLicenses = newAssignedLicenses.filter((lc) => lc.Code !== (option.key as string));
            }

            onLicenseChange({ assignedLicenses: newAssignedLicenses, availableLicenses: newAvailableLicenses, isApiUser: false });
        }
    };

    const onRenderLowerContent: IRenderFunction<IComboBoxProps> = () => {
        return (
            <div className="px-2 pt-1 pb-2">
                <Link to={RouteConfig.billing.subscriptions.path} target="_blank" rel="noopener noreferrer">
                    <FluentLink style={{ marginLeft: '3px' }}>{myStrings.buyMoreLicenses}</FluentLink>
                </Link>
            </div>
        );
    };

    return (
        <>
            <VirtualizedComboBox
                selectedKey={selectedKeys}
                multiSelect
                title={comboBoxOptions
                    .filter((o) => selectedKeys.includes(o.key as string))
                    .map((o) => o.text)
                    .join(', ')}
                comboBoxOptionStyles={COMBOBOX_OPTION_STYLES}
                options={UserWizardBase.getLicenseOptionsForDropdown(availableLicenses)}
                onRenderLowerContent={onRenderLowerContent}
                onRenderOption={onRenderLicenseOption}
                onChange={handleLicenseChange}
                placeholder={myStrings.noLicense}
                disabled={disabled}
                useComboBoxAsMenuWidth={useComboBoxAsMenuWidth}
            />
            <Dialog
                dialogContentProps={{
                    showCloseButton: false,
                    isMultiline: true,
                    title: myStrings.cannotAssignLicenseTitle,
                }}
                hidden={!showAlert}
            >
                <div style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap' }}>
                    {Strings.formatString(myStrings.cannotAssignLicenseMessage, contactsAndCompaniesLicense?.Name ?? FeatureAndEditionHelper.getLocalizedFeatureName(Feature.ContactsAndCompanies))}
                </div>
                <DialogFooter className="pt-3">
                    <Link to={RouteConfig.billing.subscriptions.path} target="_blank" rel="noopener noreferrer">
                        <PrimaryButton>{myStrings.buyMore}</PrimaryButton>
                    </Link>
                    <DefaultButton
                        onClick={() => {
                            setShowAlert(false);
                        }}
                    >
                        {Strings.cancel}
                    </DefaultButton>
                </DialogFooter>
            </Dialog>
        </>
    );
};
