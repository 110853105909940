import { Link } from '@fluentui/react';
import React, { useContext } from 'react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import lockImg from '../../../img/lock-full.svg';
import './locks.scss';
import type { TLicenseRestriction } from '../../../helpers/LicenseRestrictionsHelper';
import type { TLicenseRestrictionsModalData } from '@eway-crm/gui';
import { getLicenseRestrictionsModalTitle } from '@eway-crm/gui';

type TFeatureLockedTooltipContent = {
    licenseRestriction: TLicenseRestriction;
    featureLockModalData?: TLicenseRestrictionsModalData; 
};

const LicenseRestrictionsTooltipContent: React.FC<TFeatureLockedTooltipContent> = ({ licenseRestriction, featureLockModalData }) => {
    const myStrings = Strings.components.licenseRestrictions;
    const { showLicenseRestrictionModal } = useContext(ConnectionContext);

    const { title, additionalRestrictionsText } = getLicenseRestrictionsModalTitle(licenseRestriction, featureLockModalData);

    return (
        <div className="d-flex py-2 px-1">
            <img src={lockImg} alt="lock" className="locks__tooltipContent__img mr-1" />
            <div className="d-flex flex-column mx-1">
                <div>{title}</div>
                {additionalRestrictionsText}
                <div>
                    <Link
                        className="d-inline-block"
                        onClick={() => {
                            showLicenseRestrictionModal(licenseRestriction, featureLockModalData);
                        }}
                    >
                        {myStrings.learnMore}
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default LicenseRestrictionsTooltipContent;
