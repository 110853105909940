import type { ShoppingConnection } from "../../ShoppingConnection";
import type { ICheckoutSubmittedModel } from "../../../data/shopping/ICheckoutSubmittedModel";
import type { TCustomerInfo } from "./TCustomerInfo";
import type { ICheckoutOrderModel, ISubmittingUserInfo } from "../../../data/shopping/ICheckoutOrderModel";
import type { IShoppingDatumResponse } from "../../../data/shopping/IShoppingDatumResponse";
import type { TPeriodType } from "../../../data/shopping/ISubscriptionsShoppingModel";
import type { Connection } from "../../layout/connection/Connection";
import StringHelper from "../../../helpers/StringHelper";

type TProduct = {
    Amount: number;
    Artno: string;
};

export type TSubmitDataCreator = (customerInfo: TCustomerInfo | null, products: TProduct[], eulaHtml: string | null, selectedPeriodType: TPeriodType) => Partial<ICheckoutOrderModel>;

class CheckoutSubmitter {

    private shoppingConnection: ShoppingConnection;
    private onCheckoutStatusLoaded: (status: ICheckoutSubmittedModel, nextSteps?: () => void) => void;

    constructor(shoppingConnection: ShoppingConnection, onCheckoutStatusLoaded: (status: ICheckoutSubmittedModel, nextSteps?: () => void) => void) {
        this.shoppingConnection = shoppingConnection;
        this.onCheckoutStatusLoaded = onCheckoutStatusLoaded;
    }

    readonly submit = (data: Partial<ICheckoutOrderModel>) => {
        this.shoppingConnection.callShoppingApi(
            'SubmitCheckout',
            {
                checkoutOrder: data
            },
            (result: IShoppingDatumResponse<ICheckoutSubmittedModel>) => {
                this.checkSubmittedCheckout(result.Datum);
            },
            null
        );
    };

    static createCheckoutOrderModel(
        customerInfo: TCustomerInfo | null,
        products: TProduct[],
        eulaHtml: string | null,
        braintreePaymentMethodNonce: string | null,
        stripePaymentMethodId: string | null,
        wantedPeriodType: TPeriodType,
        connection: Connection
    ): Partial<ICheckoutOrderModel> {
        const data: Partial<ICheckoutOrderModel> = {
            Products: products,
            AcceptedEula: eulaHtml,
            payment_method_nonce: braintreePaymentMethodNonce,
            StripePaymentMethodId: stripePaymentMethodId,
            WantedPeriodType: wantedPeriodType,
            SubmittingUser: CheckoutSubmitter.getSubmittingUserInfo(connection)
        };
        if (customerInfo) {
            data.Country = customerInfo.country;
            data.Email = customerInfo.emailAddress;
            data.Phone = customerInfo.phone;
            data.BusinessName = customerInfo.businessName;
            data.VatNumber = customerInfo.vatNumber || null;
            data.TaxNumber = customerInfo.taxNumber || null;
            data.IdentificationNumber = customerInfo.identificationNumber || null;
            data.FirstName = customerInfo.firstName;
            data.LastName = customerInfo.lastName;
            data.AddressLine1 = customerInfo.street;
            data.City = customerInfo.city;
            data.State = customerInfo.state;
            data.PostalCode = customerInfo.postalCode;
            data.ChargeAdditionalServicesAutomatically = customerInfo.chargeAdditionalServicesAutomatically;
        }
        return data;
    }

    readonly checkSubmittedCheckout = (checkoutStatus: ICheckoutSubmittedModel | null | undefined) => {
        if (!checkoutStatus) {
            console.error('Unable to check the submitted checkout status. No submit data.');
            // Send error state. Do not continue checking.
            this.onCheckoutStatusLoaded({
                CheckoutId: 0,
                CompleteStadiumNumber: 0,
                CurrentStadiumNumber: 0,
                HasErrorOccured: true,
                IsComplete: false,
                ReturnCodeString: 'NonRecoverableInternalError',
                StripeSubscriptionIssue: null,
                IsAnyProductAvailableImmediatelly: false
            });
            return;
        }

        const nextSteps = () => {
            if (checkoutStatus.IsComplete && !checkoutStatus.HasErrorOccured) {
                // The state will be processed, do not continue checking.
                return;
            }

            if (checkoutStatus.HasErrorOccured) {
                // The state will be processed, do not continue checking.
                return;
            }

            window.setTimeout(
                () => {
                    this.shoppingConnection.callShoppingApi(
                        'GetCheckoutStatus',
                        {
                            checkoutCartId: checkoutStatus.CheckoutId
                        },
                        (result: IShoppingDatumResponse<ICheckoutSubmittedModel>) => {
                            this.checkSubmittedCheckout(result.Datum);
                        },
                        null
                    );
                },
                2500 + Math.floor(Math.random() * 4000)
            );
        };

        this.onCheckoutStatusLoaded(checkoutStatus, nextSteps);
    };

    static getSubmittingUserInfo(connection: Connection): ISubmittingUserInfo | null {
        if (!connection.state.userItem)
            return null;

        return {
            IsAdminShopping: StringHelper.compareIgnoringCase(connection.state.userItem.Username, 'admin') === 0,
            Username: connection.state.userItem.Username
        };
    }
}

export default CheckoutSubmitter;