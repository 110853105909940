import { MessageBar, MessageBarType } from "@fluentui/react";
import type { FC } from "react";
import Strings from "../../../strings";
import { Body1Strong } from "@fluentui/react-components";

const MESSAGEBAR_STYLES = {
    innerText: { width: '100%' },
};

export type TCustomWarningBar = { complexSettingWarning: boolean; modulesWithComplexCombination: string[] };

const formatComplexCombinationModulesString = (modules: string[], joiner: string) => {
    switch (modules.length) {
        case 0:
            return '';
        case 1:
            return modules[0];
        case 2:
            return modules.join(` ${joiner} `);
        default:
            return `${modules.slice(0, -1).join(', ')} ${joiner} ${modules.slice(-1)[0]}`;
    }
};

const CustomWarningStrip: FC<{ warningBarConfig: TCustomWarningBar }> = ({ warningBarConfig }) => {
    const isCs = Strings.getLanguage() === 'cs';
    const pluralOrSingularModule = warningBarConfig.modulesWithComplexCombination.length > 1 ? Strings.inModulePlural : Strings.inModuleSinglular;
    const formatedModuleStringInCs = isCs ? pluralOrSingularModule : '';
    const formatedModuleString = Strings.formatString(Strings.components.routes.modulePermissions.complexCombinationWarningText, formatedModuleStringInCs, formatComplexCombinationModulesString(warningBarConfig.modulesWithComplexCombination, Strings.and)) as string;

    return (
        <>
            {warningBarConfig.complexSettingWarning &&
                <MessageBar
                    key='complexSettingWarning'
                    messageBarType={MessageBarType.warning}
                    styles={MESSAGEBAR_STYLES}
                >
                    <Body1Strong>{Strings.components.routes.modulePermissions.complexWarningStripText}</Body1Strong>
                </MessageBar>
            }

            {warningBarConfig.modulesWithComplexCombination.length > 0 &&
                <MessageBar
                    key='complexCombinationWarning'
                    messageBarType={MessageBarType.severeWarning}
                    styles={MESSAGEBAR_STYLES}
                >
                    <Body1Strong>{formatedModuleString}</Body1Strong>
                </MessageBar>
            }
        </>
    );
};

export default CustomWarningStrip;