import { DelayedRender } from '@fluentui/react';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import type { ICheckoutSubmittedModel } from '../../../../../../data/shopping/ICheckoutSubmittedModel';
import RouteConfig from '../../../../../../RouteConfig';
import Strings from '../../../../../../strings';
import { SpinnerModal } from '../../../../../shared/SpinnerModal';
import { StripeRequiredActionHandler } from '../../../../../shared/subscriptions/StripeRequiredActionHandler';
import StoreV2Context from '../../StoreV2Context';
import somethingWentWrongImg from '../../../../../../img/SomethingWentWrong.svg';
import { WindowHelper } from '@eway-crm/gui';
import IframePostMessageHelper from '../../../../../../helpers/IframePostMessageHelper';
import GoToAdminCenter from '../GoToAdminCenter';
import { SpinnerVariant } from '@eway-crm/gui';

type TSubmitModalProps = {
    submitResult: ICheckoutSubmittedModel | null;
};

const PostIframeCheckoutLoading = () => {
    React.useEffect(() => {
        IframePostMessageHelper.checkoutLoading();
    }, []);

    return null;
};

const SubmitModal: React.FC<TSubmitModalProps> = ({ submitResult }) => {
    const { shoppingConnection, onStoreDataChanged } = React.useContext(StoreV2Context);
    const myStrings = Strings.components.routes.subscriptions;
    const isInIframe = WindowHelper.isInIframe();

    if (submitResult) {
        if (submitResult.IsComplete && !submitResult.HasErrorOccured) {
            // Delay rendering so that iframe can hide admin gui on time
            const finalRedirect = (
                <>
                    <PostIframeCheckoutLoading />
                    <DelayedRender delay={200}>
                        <Redirect to={RouteConfig.billing.subscriptions.actions.afterBuy.getPath(submitResult.IsAnyProductAvailableImmediatelly)} push={true} />
                    </DelayedRender>
                </>
            );
            if (!submitResult.StripeSubscriptionIssue?.SubscriptionRequiresActionPaymentIntent) {
                return finalRedirect;
            } else {
                return (
                    <StripeRequiredActionHandler
                        requiredActionModel={submitResult.StripeSubscriptionIssue.SubscriptionRequiresActionPaymentIntent}
                        shoppingConnection={shoppingConnection}
                        continueComponent={finalRedirect}
                        isInAnotherModal
                    />
                );
            }
        }
        if (submitResult.HasErrorOccured) {
            let errorMessage;
            if (submitResult.ReturnCodeString === 'PaymentMethodRejected') {
                errorMessage = myStrings.checkoutErrorPayment;
            } else if (submitResult.ReturnCodeString === 'InvalidCheckoutData') {
                errorMessage = myStrings.checkoutErrorWrongData;
            } else {
                errorMessage = myStrings.checkoutErrorGeneral;
            }

            if (isInIframe) {
                return <GoToAdminCenter imgSrc={somethingWentWrongImg} message={errorMessage} />;
            }
            return (
                <Alert variant="danger" className="mb-0">
                    <Alert.Heading>{myStrings.yourNewSubscription}</Alert.Heading>
                    <p>{errorMessage}</p>
                    <hr />
                    <div className="d-flex justify-content-end">
                        <Button onClick={() => void onStoreDataChanged()} variant="outline-danger">
                            {Strings.ok}
                        </Button>
                    </div>
                </Alert>
            );
        }
    }

    return <SpinnerModal.CenteredContent variant={SpinnerVariant.linear} />;
};

export default SubmitModal;
