import * as React from 'react';
import Strings from "../../../strings";
import { Form, Col, Button, OverlayTrigger, Popover } from 'react-bootstrap';
import StringHelper from '../../../helpers/StringHelper';
import { SelectionState, IntegratedSelection, SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, TableSelection, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import { TooltipHost } from '@fluentui/react';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import GridTable from '../../GridTable';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import { UserBadgeFlags } from '../../shared/users/UserBadgeFlags';
import { QuestionMarkPopover } from '../../shared/QuestionMarkPopover';
import ModulePermissionsGrid from '../modulePermissions/ModulePermissionsGrid';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import FieldPermissionsGrid from '../fields/FieldPermissionsGrid';
import FoldersMenu from '../../shared/FoldersMenu';
import { GroupNames } from '../../../data/constants/GroupNames';
import splashImg from '../../../img/splash/key-splash.svg';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsTooltipContent from '../../shared/locks/LicenseRestrictionsTooltipContent';
import type { TMyColumnPermissionsMap } from '../../shared/WizardBase';

const SIDE_MENU_WIDTH = 265;

type TUsersPickerProps = {
    showHeading: boolean;
    showSearch: boolean;
    usersSearchedString: string;
    onUsersSearchedStringChange: (newSearch: string) => void;
    availableUsers: IApiUser[];
    usersSelection: string[];
    onUsersSelectionChange: (newSelection: string[]) => void;
    gridTableHeight?: React.ReactText;
    currentUserGuid: string;
    currentGroupGuid?: string;
};

type TSpecialFormProps = {
    itemGuid: string | null;
    isSystem: boolean;
    pmGroupItem: IApiGroup | null;
    isRole: boolean;
    isPm: boolean;
    onRoleOrPmChange: (isRole: boolean, isPm: boolean) => void;
    showHeading: boolean;
};

export type TGroupWizardBaseProps = {
    onDismiss: () => void;
    availableUsers: IApiUser[];
    forbiddenGroupNames: string[];
    pmGroupItem: IApiGroup | null;
    onDone: () => void;
    myColumnPermissionsMap: TMyColumnPermissionsMap;
};

type TModulePermissionsFormProps = {
    groupItem: IApiGroup;
    gridHeight?: string;
    modulePermissions: TModulePermissionWithFolder[];
    onModulePermissionChange: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
};

type TFieldPermissionsFormProps = {
    groupItem: IApiGroup;
    allGroups: IApiGroup[];
    folders: string[];
    gridHeight?: string;
    fieldPermissions: TFieldPermissionWithCol[];
    onFieldPermissionChange: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    onMandatoryPermissionChange: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    selectedFolderName: string | null;
    onSelectedFolderNameChange: (newFolderName: string) => void;
    disableEditing: boolean;
};

export class GroupWizardBase {

    private static readonly usersGridColumns = [
        { name: 'FileAs', title: Strings.components.routes.groups.user },
        { name: 'Username', title: Strings.components.routes.groups.username }
    ];

    private static readonly usersDefaultColumnWidths = [
        { columnName: 'FileAs', width: 300 },
        { columnName: 'Username', width: 200 }
    ];

    static readonly minGroupNameLength = 3;

    static isGroupNameValid(groupName: string, forbiddenGroupNames: string[]): boolean {
        const testedName = groupName.trim();

        if (!testedName)
            return false;

        if (testedName.length < GroupWizardBase.minGroupNameLength)
            return false;

        if (forbiddenGroupNames.some(fgn => StringHelper.compareIgnoringCase(fgn.trim(), testedName) === 0))
            return false;

        return true;
    }

    static getGroupNamePattern(groupName: string, forbiddenGroupNames: string[]): string {
        const testedName = groupName.trim();
        if (!testedName || testedName.length < GroupWizardBase.minGroupNameLength)
            return '^.*[^\\s]{' + GroupWizardBase.minGroupNameLength + ',}.*$';

        if (forbiddenGroupNames.findIndex(grp => StringHelper.compareIgnoringCase(grp, testedName) === 0) > -1)
            return '^(?!' + StringHelper.escapeRegExp(groupName) + '$)';

        return '^.*[^\\s]{' + GroupWizardBase.minGroupNameLength + ',}.*$';
    }

    private static readonly UsersPickerCell: React.FunctionComponent<Table.DataCellProps & { currentUserGuid: string; currentGroupGuid?: string }> = (props) => {
        const typedRow = props.row as IApiUser;
        const { currentUserGuid, currentGroupGuid, ...restProps } = props;
        return (
            <VirtualTable.Cell {...restProps}>
                {props.value}
                {(props.column.name === 'Username') &&
                    <UserBadgeFlags item={typedRow} currentUserGuid={currentUserGuid} currentGroupGuidBadgedAsDefault={currentGroupGuid} />
                }
            </VirtualTable.Cell>
        );
    };

    static readonly UsersPicker: React.FunctionComponent<TUsersPickerProps> = (props) => {
        if (props.showHeading && !props.showSearch) {
            console.error('GroupWizardBase.UsersPicker: wrong heading configuration');
            return null;
        }

        const myStrings = Strings.components.routes.groups;
        const noUsersString = props.usersSearchedString ? Strings.components.routes.users.noUsersMatch : Strings.components.routes.users.noUsers;

        return (
            <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                {props.showSearch &&
                    <div className={props.showHeading ? 'row' : 'row justify-content-end mb-2'}>
                        {props.showHeading &&
                            <div className="col">
                                <h2 className="mb-5">{myStrings.assignUsers}</h2>
                            </div>
                        }
                        <div className="col-auto">
                            <Form.Control type="text" placeholder={Strings.search} value={props.usersSearchedString} onChange={(e) => props.onUsersSearchedStringChange(e.target.value)} />
                        </div>
                    </div>
                }
                <div className="row flex-fill d-flex justify-content-start">
                    <div className="col">
                        <Grid
                            rows={props.availableUsers.sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs))}
                            columns={GroupWizardBase.usersGridColumns}
                            rootComponent={GridTable.Root}
                            getRowId={(row: IApiUser) => row.ItemGUID}
                        >
                            <SelectionState
                                selection={props.usersSelection}
                                onSelectionChange={props.onUsersSelectionChange as ((sel: React.ReactText[]) => void)}
                            />
                            <SearchState
                                value={props.usersSearchedString}
                                onValueChange={props.onUsersSearchedStringChange}
                            />
                            <IntegratedSelection />
                            <IntegratedFiltering />
                            <VirtualTable
                                containerComponent={GridTable.TableContainerComponent}
                                height={props.gridTableHeight}
                                messages={{ noData: noUsersString }}
                                noDataCellComponent={GridTable.NoDataCell}
                                cellComponent={(ps) => <GroupWizardBase.UsersPickerCell currentUserGuid={props.currentUserGuid} currentGroupGuid={props.currentGroupGuid} {...ps} />}
                            />
                            <TableColumnResizing defaultColumnWidths={GroupWizardBase.usersDefaultColumnWidths} />
                            <TableHeaderRow />
                            <TableSelection showSelectAll selectByRowClick highlightRow />
                        </Grid>
                    </div>
                </div>
            </div>
        );
    };

    static setIsGsAssignment(gsSelection: string[], gsName: string, isThere: boolean, setGsSelection: (newSelection: string[]) => void) {
        if (isThere && !gsSelection.includes(gsName)) {
            const gs = gsSelection;
            gs.push(gsName);
            setGsSelection(gs);
            return;
        }
        if (!isThere && gsSelection.includes(gsName)) {
            const gs = gsSelection;
            const index = gsSelection.indexOf(gsName);
            if (index > -1) {
                gs.splice(index, 1);
            }
            setGsSelection(gs);
            return;
        }
    }

    static readonly ModulePermissionsForm: React.FC<TModulePermissionsFormProps> = (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [searchedString, setSearchString] = React.useState('');
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { licenseRestrictionsHelper } = React.useContext(ConnectionContext);
        const { isModulePermissionsReadOnly, modulePermissionsLicenseRestriction } = licenseRestrictionsHelper.isModulePermissionsReadOnly();

        const getCellWrapper = () => {
            if (!isModulePermissionsReadOnly) {
                // Do not render any wrappers
                return undefined;
            }

            return ({ children }: { children?: React.ReactNode }) => (
                <TooltipHost content={<LicenseRestrictionsTooltipContent licenseRestriction={modulePermissionsLicenseRestriction} />}>{children}</TooltipHost>
            );
        };

        return (
            <>
                <div className={'row justify-content-end mb-2'}>
                    <div className="col-auto">
                        <Form.Control type="text" placeholder={Strings.search} value={searchedString} onChange={(e) => setSearchString(e.target.value)} />
                    </div>
                </div>
                <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                    <div className="row flex-fill d-flex justify-content-start">
                        <div className="col">
                            <ModulePermissionsGrid
                                gridHeight={props.gridHeight}
                                modulePermissions={props.modulePermissions}
                                onModulePermissionChange={props.onModulePermissionChange}
                                searchedString={searchedString}
                                disabled={props.groupItem.DisallowControlModulePermissions || isModulePermissionsReadOnly}
                                cellWrapper={getCellWrapper()}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    };


    static readonly FieldPermissionsForm: React.FC<TFieldPermissionsFormProps> = (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const [searchedString, setSearchString] = React.useState('');
        return (
            <>
                <div className={'d-flex justify-content-end mb-2'}>
                    <div className="col-auto">
                        <Form.Control type="text" placeholder={Strings.search} value={searchedString} onChange={(e) => setSearchString(e.target.value)} />
                    </div>
                </div>
                <div className="d-flex position-relative">
                    <div className="border-right border-gray align-self-end mt-1 tr-cursor-hover" style={{ width: SIDE_MENU_WIDTH }}>
                        <FoldersMenu
                            height={440}
                            folderNames={props.folders}
                            selectedFolderName={props.selectedFolderName}
                            searchedString={''}
                            isCollapsed={false}
                            onFolderChange={(newFolderName) => {
                                props.onSelectedFolderNameChange(newFolderName);
                            }}
                            variant="small"
                        />
                    </div>
                    <div className="d-flex flex-fill flex-column position-absolute" style={{ top: 0, bottom: 0, left: SIDE_MENU_WIDTH, right: 0 }}>
                        {props.selectedFolderName ? (
                            <FieldPermissionsGrid
                                gridHeight={props.gridHeight}
                                fieldPermissions={props.fieldPermissions.filter((fp) => fp.col?.FolderName === props.selectedFolderName)}
                                onFieldPermissionChange={props.onFieldPermissionChange}
                                searchedString={searchedString}
                                selectedGroup={props.groupItem}
                                disableEditing={props.disableEditing}
                                systemGroupGuid={props.allGroups.find((group) => group.GroupName === GroupNames.system)!.ItemGUID}
                                onMandatoryPermissionChange={props.onMandatoryPermissionChange}
                            />
                        ) : (
                            <div className="h-100 position-relative">
                                <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                                    <img className="d-inline-block" src={splashImg} style={{ height: '160px', width: '100%' }} alt="" />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </>
        );
    };

    static readonly SpecialForm: React.FunctionComponent<TSpecialFormProps> = (props) => {
        const myStrings = Strings.components.routes.groups;

        const isRoleLabel = (
            <>
                {myStrings.isRole}

                <QuestionMarkPopover placement="right" color="black" title={myStrings.isRole}>
                    {myStrings.isRoleDescription}
                    <br />
                    <a href={myStrings.isRoleDescriptionTeamsUrl} target="_blank" rel="noopener noreferrer" >{myStrings.isRoleDescriptionTeams}</a>
                </QuestionMarkPopover>
            </>
        );

        const setIsPm = (isPm: boolean) => {
            props.onRoleOrPmChange(props.isRole || isPm, isPm);
        };
        const pmLabel = (
            <>
                {myStrings.isProjectManager}
                <QuestionMarkPopover placement="right" color="black" title={myStrings.isProjectManager}>
                    {myStrings.isProjectManagerDescription}
                    <br />
                    <a href={myStrings.isProjectManagerDescriptionMoreUrl} target="_blank" rel="noopener noreferrer" >{myStrings.isProjectManagerDescriptionMore}</a>
                </QuestionMarkPopover>
            </>
        );
        const isPmFormGroup = (
            <Form.Group controlId="newGrpIsPm" className="mb-2" key="newGrpIsPm">
                <Form.Check
                    type="checkbox"
                    label={pmLabel}
                    disabled={props.isSystem}
                    checked={props.isPm}
                    onChange={(e: IFormCheckChangeEvent) => {
                        if (!e.target.checked || !props.pmGroupItem || props.itemGuid === props.pmGroupItem.ItemGUID) {
                            setIsPm(e.target.checked);
                        }
                        // Else popover catches the focus.
                    }}
                />
            </Form.Group>
        );

        return (
            <>
                {props.showHeading &&
                    <h2 className="mb-5">{myStrings.special}</h2>
                }
                <Form className="form-application">
                    <Form.Row>
                        <Col>
                            <Form.Group controlId="newGrpIsRole" className="mb-2" key="newGrpIsRole">
                                <Form.Check
                                    type="checkbox"
                                    label={isRoleLabel}
                                    disabled={props.isSystem}
                                    checked={props.isRole}
                                    onChange={(e: IFormCheckChangeEvent) => props.onRoleOrPmChange(e.target.checked, props.isPm && e.target.checked)}
                                />
                            </Form.Group>
                            {!props.isPm && props.pmGroupItem && props.itemGuid !== props.pmGroupItem.ItemGUID ?
                                <OverlayTrigger
                                    trigger="focus"
                                    placement="bottom-start"
                                    delay={{ hide: 200, show: 0 }}
                                    overlay={
                                        <Popover id="pm-confirm-popover">
                                            <Popover.Title>{myStrings.pmAlreadySpecifiedTitle}</Popover.Title>
                                            <Popover.Content>
                                                <div>
                                                    {Strings.formatString(myStrings.pmAlreadySpecified, props.pmGroupItem.GroupName)}
                                                </div>
                                                <div className="mt-2">
                                                    <Button size="sm" onClick={() => setIsPm(true)} className="mr-2">{Strings.yes}</Button>
                                                    <Button size="sm" variant="outline-secondary" onClick={() => setIsPm(false)}>{Strings.no}</Button>
                                                </div>
                                            </Popover.Content>
                                        </Popover>
                                    }
                                >
                                    {isPmFormGroup}
                                </OverlayTrigger>
                                :
                                <>{isPmFormGroup}</>
                            }
                        </Col>
                    </Form.Row>
                </Form>
            </>
        );
    };
}