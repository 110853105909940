import { DefaultButton, Dialog, DialogFooter, PrimaryButton } from '@fluentui/react';
import React from 'react';
import Strings from '../../../strings';

type TWorkflowUnsavedChangesDialogProps = {
    onContinue: () => void;
    onCancel: () => void;
};

const WorkflowUnsavedChangesDialog: React.FC<TWorkflowUnsavedChangesDialogProps> = ({ onContinue, onCancel }) => {
    return (
        <Dialog
            maxWidth={430}
            modalProps={{ isBlocking: true }}
            dialogContentProps={{
                showCloseButton: false,
                isMultiline: true,
                title: Strings.confirmRouteChangeTitle,
            }}
            hidden={false}
        >
            <div style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap' }}>{Strings.confirmRouteChange}</div>
            <DialogFooter className="pt-3">
                <PrimaryButton onClick={onContinue}>{Strings.saveChangesContinue}</PrimaryButton>
                <DefaultButton onClick={onCancel}>{Strings.cancel}</DefaultButton>
            </DialogFooter>
        </Dialog>
    );
};

export default WorkflowUnsavedChangesDialog;
