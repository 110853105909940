import type { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { Prompt, useLocation } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';
import { ConfirmBeforeUnload } from '@eway-crm/gui';

type TRouteLeavingGuardProps = {
    isActive?: boolean | undefined;
    onConfirmNavigation: (path: string) => void;
    onSave?: (successCb: () => void) => void;
    modalTitle: string;
    modalMessage: string;
    saveButtonText: string;
    discardButtonText: string;
};

/**
 * Component showing confirmation modal on route change, or alert before unload (closing window, refresh,...)
 * @param isActive condition when to show confirm dialog
 * @param onConfirmNavigation callback when navigation is confirmed in modal dialog
 * @param onSave save function to be called before route change. Call successCb after successful save to change route.
 * Taken and edited from https://michaelchan-13570.medium.com/using-react-router-v4-prompt-with-custom-modal-component-ca839f5faf39
 */

const RouteLeavingGuardV5: React.FC<TRouteLeavingGuardProps> = ({ isActive, onConfirmNavigation, onSave, modalTitle, modalMessage, saveButtonText, discardButtonText }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [lastLocation, setLastLocation] = useState<Location | null>(null);
    const location = useLocation();
    const [confirmedNavigation, setConfirmedNavigation] = useState(false);

    const closeModal = () => {
        setModalVisible(false);
    };

    const handleBlockedNavigation = (nextLocation: Location): boolean => {
        if (!confirmedNavigation && location.pathname !== nextLocation.pathname) {
            setModalVisible(true);
            setLastLocation(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        setModalVisible(false);
        setConfirmedNavigation(true);
    };

    const handleSaveClick = () => {
        setModalVisible(false);
        onSave && onSave(() => {
            setConfirmedNavigation(true);
        });
    };

    useEffect(() => {
        if (confirmedNavigation && lastLocation) {
            onConfirmNavigation(lastLocation.pathname);
        }
    }, [confirmedNavigation, lastLocation, onConfirmNavigation]);

    return (
        <>
            {isActive && <ConfirmBeforeUnload />}
            <Prompt when={isActive} message={handleBlockedNavigation} />
            <Dialog
                modalType="alert"
                open={modalVisible}
                onOpenChange={(_e, data) => {
                    if (!data.open) {
                        closeModal();
                    }
                }}
            >
                <DialogSurface>
                    <DialogBody>
                        <DialogTitle>
                            {modalTitle}
                        </DialogTitle>
                        <DialogContent>
                            {modalMessage}
                        </DialogContent>
                        <DialogActions>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="primary" onClick={handleSaveClick}>
                                    {saveButtonText}
                                </Button>
                            </DialogTrigger>
                            <DialogTrigger disableButtonEnhancement>
                                <Button appearance="secondary" onClick={handleConfirmNavigationClick}>
                                    {discardButtonText}
                                </Button>
                            </DialogTrigger>
                        </DialogActions>
                    </DialogBody>
                </DialogSurface>
            </Dialog>
        </>
    );
};
export default RouteLeavingGuardV5;
