import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import type { ShoppingConnection } from '../../ShoppingConnection';
import type { TPaymentGateProvider } from '../../../data/shopping/IPaymentMethodsModel';
import { BraintreeAddingModal } from './BraintreeAddingModal';
import StripeAddingModal from './StripeAddingModal';

type TAddingButtonPropsBase = {
    shoppingConnection: ShoppingConnection;
    onAdded: () => void;
};

export type TAddingButtonChildProps = TAddingButtonPropsBase & {
    onCanceled: () => void;
};

type TAddingButtonProps = TAddingButtonPropsBase & {
    disabled: boolean;
    paymentGateProvider: TPaymentGateProvider;
};

export const AddingButton: React.FC<TAddingButtonProps> = (props) => {
    const myStrings = Strings.components.routes.billingDetails;

    const [isDialogShown, setIsDialogShown] = React.useState<boolean>(false);

    const click = React.useCallback(() => {
        if (props.disabled)
            return;

        setIsDialogShown(true);
    }, [setIsDialogShown, props.disabled]);

    const cancel = React.useCallback(() => {
        setIsDialogShown(false);
    }, [setIsDialogShown]);

    return (
        <>
            <Button variant="outline-secondary" onClick={click} disabled={props.disabled} >
                <i className="mdl2 mdl2-add" aria-hidden="true" /> {myStrings.addNew}
            </Button>

            {isDialogShown &&
                <>
                    {(props.paymentGateProvider === 'Braintree') &&
                        <BraintreeAddingModal shoppingConnection={props.shoppingConnection} onAdded={props.onAdded} onCanceled={cancel} />
                    }
                    {(props.paymentGateProvider === 'Stripe') &&
                        <StripeAddingModal shoppingConnection={props.shoppingConnection} onAdded={props.onAdded} onCanceled={cancel} />
                    }
                </>
            }
        </>
    );
};