import type { IStoreModel, IPackageModel, IEnablingModelCondition, ICategoryModel, IDealModel, TPeriodType } from "../../../data/shopping/ISubscriptionsShoppingModel";
import StringHelper from "../../../helpers/StringHelper";
import Strings from "../../../strings";
import { isFullAddressRequired } from "../../shared/subscriptions/CompanyDetailsForm";
import getPrice, { getFirstNonZeroPriceAmount } from "./getPrice";
import type { TCustomerInfo } from "./TCustomerInfo";

class StoreHelper {
    static getPackageByArtno(storeData: IStoreModel, artno: string): IPackageModel | undefined {
        return storeData.Categories?.findMap((cat) =>
            cat.Deals?.findMap((deal) =>
                deal.Packages?.find((p) => artno === p.Artno)
            )
        );
    }

    static getUnitPriceAttributes(packageData: IPackageModel, currency: string, currentEditQuantity: number | undefined, periodType: TPeriodType, isPackageInCart: (artno: string) => boolean) {
        const showUnitPriceAsUnit: boolean = packageData.ShowUnitPrice || false;
        let unitPriceSpanQuantity = 1;
        let showPrice: boolean = showUnitPriceAsUnit;
        if (typeof currentEditQuantity === 'number') {
            unitPriceSpanQuantity = currentEditQuantity;
            if (unitPriceSpanQuantity === 0 && !showUnitPriceAsUnit) {
                unitPriceSpanQuantity = 1;
            }
            showPrice = true;
        } else if (!!packageData.OriginalStatus && packageData.OriginalStatus.IsSubscribed && !!packageData.OriginalStatus.Quantity) {
            unitPriceSpanQuantity = packageData.OriginalStatus.Quantity;
            showPrice = true;
        } else if (!!packageData.PriceList && packageData.PriceList.length !== 0) {
            const firstPaidAmount: number | null = getFirstNonZeroPriceAmount(packageData, currency, periodType);
            if (!!firstPaidAmount) {
                unitPriceSpanQuantity = firstPaidAmount;
                showPrice = true;
            } else if (firstPaidAmount === 0) {
                unitPriceSpanQuantity = 1;
                showPrice = true;
            }
        }

        showPrice = showPrice && !!packageData.PriceList && packageData.PriceList.length > 0;

        let shownPrice: number | undefined;
        if (showPrice) {
            shownPrice = getPrice(
                packageData,
                unitPriceSpanQuantity,
                currency,
                periodType,
                isPackageInCart,
                'Monthly' // Unit price is forecly shown as montly price.
            ) * (!showUnitPriceAsUnit ? unitPriceSpanQuantity : 1);
        }

        return {
            showUnitPriceAsUnit,
            shownPrice
        };
    }

    static isPackageDisabled(p: IPackageModel, isPackageInCart: (artno: string) => boolean): { isDisabled: boolean; enablingArtnos: string[] | null } {
        if (!p.IsDisabled) {
            return { isDisabled: false, enablingArtnos: null };
        }
        if (!p.EnablingRules) {
            return { isDisabled: true, enablingArtnos: null };
        }
        if (!p.EnablingRules.Enable) {
            return { isDisabled: true, enablingArtnos: null };
        }
        if (this.isConditionMet(p.EnablingRules.Condition, isPackageInCart)) {
            return { isDisabled: false, enablingArtnos: null };
        }

        return { isDisabled: true, enablingArtnos: p.EnablingRules.Condition.OneOfPackagesInCart };
    }

    static isPackageSubscribeAvailable(p: IPackageModel, isPackageInCart: (artno: string) => boolean): boolean {
        if (!!p.OriginalStatus?.IsSubscribeAvailable) {
            return true;
        }
        if (!p.EnablingRules) {
            return false;
        }
        if (!p.EnablingRules.MakeSubscribeAvailable) {
            return false;
        }
        if (this.isConditionMet(p.EnablingRules.Condition, isPackageInCart)) {
            return true;
        }
        return false;
    }

    static isConditionMet(condition: IEnablingModelCondition, isPackageInCart: (artno: string) => boolean): boolean {
        if (!!condition.OneOfPackagesInCart && condition.OneOfPackagesInCart.length !== 0) {
            const isOneInCart: boolean = condition.OneOfPackagesInCart.some(isPackageInCart);

            // One of the specified artnos is in cart with positive quantity.
            if (isOneInCart) {
                return true;
            }
        }

        // No condition met.
        return false;
    }

    static getDefaultPackagesStates<TState>(
        storeData: IStoreModel,
        turnIntoEditModeArtno: string | null,
        stateItemCreator: (artno: string, quantity: number, isTurnedInto: boolean, catIndex: number, dealIndex: number, packageIndex: number) => TState
    ): TState[] {
        const packagesStatuses: TState[] = [];
        StoreHelper.iteratePackages(
            storeData,
            (packageData: IPackageModel, i: number, j: number, k: number) => {
                if (!packageData.Artno)
                    throw new Error('All packages must have an artno.');

                let state: TState | null = null;
                const isTurnedInto = !!turnIntoEditModeArtno && StringHelper.compareIgnoringCase(packageData.Artno, turnIntoEditModeArtno) === 0;

                // Set default numbers for already bought items.
                if (packageData.OriginalStatus && packageData.OriginalStatus.IsSubscribed && packageData.OriginalStatus.Quantity) {
                    state = stateItemCreator(
                        packageData.Artno,
                        packageData.OriginalStatus.Quantity,
                        isTurnedInto,
                        i,
                        j,
                        k
                    );
                } else if (isTurnedInto) {
                    const quantity: number = (packageData.OriginalStatus && packageData.OriginalStatus.SubscribeDefaultQuantity) ||
                        (packageData.OriginalStatus && packageData.OriginalStatus.Quantity) ||
                        1;
                    state = stateItemCreator(
                        packageData.Artno,
                        quantity,
                        true,
                        i,
                        j,
                        k
                    );
                }

                // If anything to set, set it.
                if (!!state) {
                    packagesStatuses.push(state);
                }
            }
        );
        return packagesStatuses;
    }

    static iteratePackages(storeData: IStoreModel, func: (packageData: IPackageModel, catIndex: number, dealIndex: number, packageIndex: number) => void) {
        if (storeData.Categories) {
            storeData.Categories.forEach((cat: ICategoryModel, i: number) => {
                if (cat.Deals) {
                    cat.Deals.forEach((deal: IDealModel, j: number) => {
                        if (deal.Packages) {
                            deal.Packages.forEach((packageData: IPackageModel, k: number) => {
                                func(packageData, i, j, k);
                            });
                        }
                    });
                }
            });
        }
    }

    static isCompanyInfoValid(customerInfo: TCustomerInfo) {
        if (!customerInfo.emailAddress || !customerInfo.emailAddress.includes('@') || customerInfo.emailAddress.length < 4)
            return false;

        if (!customerInfo.businessName)
            return false;

        if (!customerInfo.country)
            return false;

        if (isFullAddressRequired(customerInfo)) {
            if (!customerInfo.street || !customerInfo.city || !customerInfo.postalCode || !customerInfo.state)
                return false;
        }

        return true;
    }

    static getArtnoWithSameEdition(packageData: IPackageModel, artnosToPickFrom: string[], storeData: IStoreModel) {
        const exactFeature = StoreHelper.getExactSingleFeatureAndEdition(packageData);
        if (exactFeature) {
            const sameEditionEnablingArtno = artnosToPickFrom.find(possibleArtno => {
                const enablingPackage = StoreHelper.getPackageByArtno(storeData, possibleArtno);
                const enablingPackageExactFeature = StoreHelper.getExactSingleFeatureAndEdition(enablingPackage);
                return enablingPackageExactFeature && enablingPackageExactFeature.Edition === exactFeature.Edition;
            });
            if (sameEditionEnablingArtno) {
                return sameEditionEnablingArtno;
            }
        }
        return null;
    }

    static getExactSingleFeatureAndEdition(packageData: IPackageModel | undefined | null) {
        if (!packageData?.ProvidedFeatures || packageData.ProvidedFeatures.length !== 1)
            return null;

        return packageData.ProvidedFeatures[0];
    }

    static getPeriodStrings(periodType: TPeriodType) {
        switch (periodType) {
            case 'Monthly':
                return Strings.components.routes.subscriptions.periods.monthly;
            case 'Yearly':
                return Strings.components.routes.subscriptions.periods.yearly;
            case 'Quarterly':
                return Strings.components.routes.subscriptions.periods.quarterly;
            case 'SemiAnnually':
                return Strings.components.routes.subscriptions.periods.semiannually;
            case 'FixedPrice':
                return Strings.components.routes.subscriptions.periods.lifetime;
            case 'Triennially':
                return Strings.components.routes.subscriptions.periods.triennially;
        }
    }
}

export default StoreHelper;