export default class ModulePermissionNames {
    static readonly Columns = {
        Folder: 'Folder',
        View: 'View',
        CanCreate: 'CanCreate',
        Edit: 'Edit',
        Delete: 'Delete',
        CanExport: 'CanExport',
        CanSeeHistory: 'CanSeeHistory',
        RowsRestriction: 'RowsRestriction',
    };

    static readonly Options = {
        All: 'All',
        Group: 'Group',
        Inherited: 'Inherited',
        Related: 'Related',
        Own: 'Own',
        None: 'None',
    }; // Order in Admin GUI

    static readonly OptionsOrder = [
        ModulePermissionNames.Options.All,
        ModulePermissionNames.Options.Inherited,
        ModulePermissionNames.Options.Group,
        ModulePermissionNames.Options.Related,
        ModulePermissionNames.Options.Own,
        ModulePermissionNames.Options.None,
    ]; // Order based on priority in Permissions Manager

    static readonly BooleanOptions = {
        Yes: 'Yes',
        No: 'No',
    };
}
