import { Button } from 'react-bootstrap';
import NumberInputField from './NumberInputField';

type TNumberInputFieldWithSetButtonProps = {
    min?: number;
    max?: number;
    value?: string;
    onValueChange: (newValue: number) => void;
    isFloat?: boolean;
    disabled?: boolean;
    replaceText: string;
    replaceValue: number;
    buttonText: string;
};

const NumberInputFieldWithSetButton: React.FC<TNumberInputFieldWithSetButtonProps> = ({ min, max, value, onValueChange, isFloat, disabled, buttonText, replaceText, replaceValue }) => {
    const onSetValueClick = () => {
        onValueChange(replaceValue);
    };

    return (
        <div className={'d-flex align-items-center'}>
            <>
                <NumberInputField
                    className="features__input features__numberInput"
                    min={min ?? undefined}
                    max={max ?? undefined}
                    value={value ?? undefined}
                    onValueChange={onValueChange}
                    isFloat={isFloat}
                    disabled={disabled}
                    replaceValueMap={{
                        [replaceValue]: replaceText,
                    }}
                />
                {Number(value) !== replaceValue && !disabled && (
                    <Button variant="link" onClick={onSetValueClick} className="pl-2 pt-1 border-0">
                        {buttonText}
                    </Button>
                )}
            </>
        </div>
    );
};

export default NumberInputFieldWithSetButton;
