import * as React from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import { Route, withRouter, Switch, Redirect } from 'react-router-dom';
import Strings, { handleLangChange } from '../strings';
import { SideMenu } from './layout/sidemenu/SideMenu';
import { Home } from './routes/Home';
import { Users } from './routes/Users';
import { PurchaseHistory } from './routes/PurchaseHistory';
import { PaymentMethods } from './routes/BillingDetails';
import { Subscriptions } from './routes/Subscriptions';
import { Groups } from './routes/Groups';
import RouteConfig from '../RouteConfig';
import Fields from './routes/Fields';
import { Updates } from './routes/Updates';
import Features from './routes/Features';
import type { TVersion} from './WelcomeModal';
import { WelcomeModal, currentVersion } from './WelcomeModal';
import ModulePermissions from './routes/ModulePermissions';
import FeatureLockedSplash from './shared/locks/FeatureLockedSplash';
import { RemoteItemStore } from '../RemoteItemStore';
import type { IOrdersHistoryModel } from '../data/shopping/IOrdersHistoryModel';
import Workflow from './routes/Workflow';
import NewFeaturesModal from './routes/home/NewFeaturesModal';
import ReactHelper from '../helpers/ReactHelper';
import { ConnectionContext } from '../providers/ConnectionProvider';
import TopNavbar from './layout/TopNavbar/TopNavbar';
import ShoppingEnabledChecker from './layout/connection/ShoppingEnabledChecker';
import ChatBase from './routes/subscriptions/StoreV2/ChatBase';

type TMainComponentProps = RouteComponentProps;

type TMainComponentState = {
    isWelcomed: boolean;
    lastWelcomedVersion: TVersion;
    wasNewFeaturesDialogShown: boolean;
    showNewFeaturesDialog: boolean;
    isNewVersionAvailable: boolean;
    unpaidOrdersCount: number;
    isShoppingEnabled: boolean;
    isShoppingEnabledLoaded: boolean;
    isEmbedded: boolean;
};

export class MainComponent extends React.Component<TMainComponentProps, TMainComponentState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TMainComponentProps) {
        super(props);

        this.state = {
            isWelcomed: true,
            lastWelcomedVersion: currentVersion,
            showNewFeaturesDialog: false,
            wasNewFeaturesDialogShown: true,
            isNewVersionAvailable: false,
            unpaidOrdersCount: 0,
            isShoppingEnabled: false,
            isShoppingEnabledLoaded: false,
            isEmbedded: false
        };
    }

    private readonly handleWelcomeDismiss = () => {
        WelcomeModal.setWelcomeSeen(this.context.connection, () => { this.setState(prevState => ({ isWelcomed: true, showNewFeaturesDialog: !prevState.wasNewFeaturesDialogShown })); });
    };

    private static checkUnpaidOrdersCount(ordersHistory: IOrdersHistoryModel) {
        if (!ordersHistory)
            return 0;

        let sum = 0;
        if (!!ordersHistory.PastDueSubscription) {
            sum += 1;
        }

        if (!!ordersHistory.StripeSubscriptionIssue) {
            sum += 1;
        }

        if (!!ordersHistory.Orders) {
            sum += ordersHistory.Orders.filter((order) => !order.IsPaid).length;
        }

        return sum;
    }

    private readonly updateIsAnyOrderUnpaid = (ordersHistory: IOrdersHistoryModel) => {
        const newUnpaidOrdersCount = MainComponent.checkUnpaidOrdersCount(ordersHistory);
        if (this.state.unpaidOrdersCount !== newUnpaidOrdersCount) {
            this.setState({ unpaidOrdersCount: newUnpaidOrdersCount });
        }
    };

    componentDidMount() {
        window.document.title = 'eWay-CRM ' + Strings.components.mainComponent.title;
        void this.handleUrlParams();
        this.loadInitialData()
            .catch((err) => console.error('Unable to load main component initial data.', err));
    }

    private readonly handleUrlParams = async () => {
        const urlParams = new URLSearchParams(this.props.location.search);
        if (urlParams.has('lang')) {
            const langValue = urlParams.get('lang');
            if (langValue?.length === 2 && langValue !== Strings.getLanguage()) {
                urlParams.delete('lang');
                this.props.history.replace({ search: urlParams.toString() });
                handleLangChange(langValue);
            }
        }

        let newIsEmbedded = false;
        if (urlParams.has('embedded')) {
            const embeddedValue = urlParams.get('embedded');
            newIsEmbedded = embeddedValue === '1';
            if (this.state.isEmbedded !== newIsEmbedded) {
                await ReactHelper.setState(this, { isEmbedded: newIsEmbedded });
            }
        }
    };

    private readonly loadInitialData = async () => {
        const remoteItemStore = new RemoteItemStore(this.context.connection);
        const isNotCurrent = await WelcomeModal.loadLastSeenVersion(this.context.connection);
        const isAlreadySeen = await NewFeaturesModal.getIsNewFeaturesModalSeen(this.context.connection);

        const isWelcomed = !isNotCurrent;
        await ReactHelper.setState(
            this,
            {
                isWelcomed,
                wasNewFeaturesDialogShown: isAlreadySeen,
                showNewFeaturesDialog: isWelcomed && !isAlreadySeen,
            }
        );

        const availableVersionInfo = await remoteItemStore.askLoadAvailableVersionData(this.context.version.wsVersion, false);
        let unpaidOrdersCount = 0;
        const isShoppingEnabled = (await this.context.connection.getShoppingConnection().askIsEnabled()).isEnabled;
        if (isShoppingEnabled) {
            const ordersHistory = await this.context.connection.getShoppingConnection().loadOrdersHistory(true);
            unpaidOrdersCount = MainComponent.checkUnpaidOrdersCount(ordersHistory);
        }

        this.setState({
            unpaidOrdersCount: unpaidOrdersCount,
            isNewVersionAvailable: availableVersionInfo.IsNewVersionAvailable,
            isShoppingEnabled,
            isShoppingEnabledLoaded: true
        });
    };

    private readonly WelcomeModalComponent = () => {
        if (!this.context.isLoginProceeded || (this.state.wasNewFeaturesDialogShown && this.state.isWelcomed)) {
            return null;
        }

        const atHome = this.props.location.pathname === RouteConfig.root.path;

        if (!this.state.isWelcomed) {
            if (!atHome) {
                return <Redirect to={RouteConfig.root.path} push={true} />;
            }

            return <WelcomeModal onDismiss={this.handleWelcomeDismiss} />;
        }

        if (atHome && this.state.showNewFeaturesDialog && !!this.context.connection) {
            return <NewFeaturesModal connection={this.context.connection} onDismiss={() => this.setState({ showNewFeaturesDialog: false, wasNewFeaturesDialogShown: true })} />;
        }

        return null;
    };

    private readonly ContentPaneComponent = () => {
        if (!!window.eway) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            React.useEffect(() => {
                if (this.props.location.pathname !== RouteConfig.billing.subscriptions.path) {
                    // Other path means have left the shopping.
                    window.eway && window.eway.onShoppingLeft();
                }
            });
        }

        if (!this.context.connection) {
            console.error('Unable to render ContentPaneComponent. Missing Connection component current reference.');
            return null;
        }
        const commonDataConnection = this.context.connection.getCommonDataConnection();
        const shoppingConnection = this.context.connection.getShoppingConnection();

        let mainColClassName = 'col bg-white';
        if (RouteConfig.isNonpaddingRoute(this.props.location.pathname)) {
            mainColClassName += ' p-0';
        } else {
            mainColClassName += ' px-3 py-3 px-lg-4 py-lg-4 px-xl-5 py-xl-4';
        }

        const { isModulePermissionsLocked } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isUserRolesLocked } = this.context.licenseRestrictionsHelper.isUserRolesLocked();

        return (
            <div className={mainColClassName} style={{ minWidth: '18rem' }}>
                <Switch>
                    <Route exact path={RouteConfig.root.path}>
                        <Home
                            shoppingConnection={shoppingConnection}
                            ordersHistoryCallback={this.updateIsAnyOrderUnpaid}
                            commonDataConnection={commonDataConnection}
                        />
                    </Route>
                    <Route path={RouteConfig.users.path}>
                        <Users location={this.props.location} history={this.props.history} />
                    </Route>
                    <Route path={RouteConfig.groups.path}>
                        {!isUserRolesLocked ? <Groups location={this.props.location} history={this.props.history} /> : <FeatureLockedSplash />}
                    </Route>
                    <Route path={RouteConfig.modulePermissions.path}>
                        {!isModulePermissionsLocked ? (
                            <ModulePermissions history={this.props.history} location={this.props.location} />
                        ) : (
                            <FeatureLockedSplash />
                        )}
                    </Route>
                    <Route path={RouteConfig.customizations.features.path}>
                        <Features history={this.props.history} location={this.props.location} />
                    </Route>
                    <Route path={RouteConfig.customizations.fields.path} >
                        <Fields history={this.props.history} location={this.props.location} />
                    </Route>
                    <Route path={RouteConfig.customizations.workflow.path} >
                        <Workflow history={this.props.history} location={this.props.location} />
                    </Route>
                    <Route path={RouteConfig.billing.subscriptions.path} key={RouteConfig.billing.subscriptions.path}>
                        <ShoppingEnabledChecker isShoppingEnabled={this.state.isShoppingEnabled} isShoppingEnabledLoaded={this.state.isShoppingEnabledLoaded} isSubscriptionsPath>
                            <Subscriptions
                                shoppingConnection={shoppingConnection}
                                isAfterBuy={RouteConfig.billing.subscriptions.actions.afterBuy.isOn(this.props.location)}
                                addToCartArtno={RouteConfig.billing.subscriptions.getArtnoToBeAddedToCart(this.props.location)}
                                key={RouteConfig.billing.subscriptions.getKey(this.props.location)}
                            />
                        </ShoppingEnabledChecker>
                    </Route>
                    <Route path={RouteConfig.billing.bills.path} key={RouteConfig.billing.bills.path}>
                        <ShoppingEnabledChecker isShoppingEnabled={this.state.isShoppingEnabled} isShoppingEnabledLoaded={this.state.isShoppingEnabledLoaded}>
                            <PurchaseHistory shoppingConnection={shoppingConnection} ordersHistoryCallback={this.updateIsAnyOrderUnpaid} />
                        </ShoppingEnabledChecker>
                    </Route>
                    <Route path={RouteConfig.billing.paymentMethods.path} key={RouteConfig.billing.paymentMethods.path}>
                        <ShoppingEnabledChecker isShoppingEnabled={this.state.isShoppingEnabled} isShoppingEnabledLoaded={this.state.isShoppingEnabledLoaded}>
                            <PaymentMethods shoppingConnection={shoppingConnection} />
                        </ShoppingEnabledChecker>
                    </Route>
                    <Route path={RouteConfig.updates.path}>
                        <Updates isNewVersionAvailableCallback={(isNewVersionAvailable) => this.setState({ isNewVersionAvailable })} />
                    </Route>
                </Switch>
                <ChatBase />
            </div>
        );
    };

    render() {
        return (
            <>
                <this.WelcomeModalComponent />
                <div className="container-fluid d-flex min-vh-100 flex-column">
                    {!this.state.isEmbedded && (
                        <TopNavbar />
                    )}
                    <div className="row flex-fill d-flex justify-content-start flex-nowrap">
                        {(this.context.isLoginProceeded) &&
                            <>
                                {!this.state.isEmbedded && (
                                    <div className="col col-auto bg-gray-200 border-right border-gray side-menu side-menu--elevated">
                                        <SideMenu
                                            isShoppingEnabled={this.state.isShoppingEnabled}
                                            isShoppingEnabledLoaded={this.state.isShoppingEnabledLoaded}
                                            beginAsCollapsed={RouteConfig.callsForCollapsedSideMenu(this.props.location)}
                                            isNewVersionAvailable={this.state.isNewVersionAvailable}
                                            unpaidOrdersCount={this.state.unpaidOrdersCount}
                                        />
                                    </div>
                                )}
                                <this.ContentPaneComponent />
                            </>
                        }
                    </div>
                </div>
            </>
        );
    }
}

export default withRouter(MainComponent);