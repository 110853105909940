import { FolderNames } from '@eway-crm/connector';
import React, { useContext } from 'react';
import type { IApiUser } from '@eway-crm/connector';
import { ItemPreviewImage } from '@eway-crm/gui';
import { ConnectionContext } from '../../../providers/ConnectionProvider';

type TPartialWcfUser = Pick<IApiUser, 'FirstName' | 'LastName' | 'Username' | 'ProfilePictureWidth' | 'ItemGUID' | 'ItemVersion'>;

type TUserAvatarProps = {
    size: React.ComponentProps<typeof ItemPreviewImage>['size'];
    item: TPartialWcfUser;
};

const UserAvatar: React.FC<TUserAvatarProps> = ({ size, item }) => {
    const { connection } = useContext(ConnectionContext);
    return <ItemPreviewImage apiConnection={connection.getApiConnection()} authMode="header" folderName={FolderNames.users} itemGuid={item.ItemGUID} userData={item} size={size} />;
};

export default UserAvatar;
