import * as React from 'react';
import { Form } from 'react-bootstrap';
import { GroupNames } from '../../../data/constants/GroupNames';
import type { IApiGroup } from '@eway-crm/connector';
import Strings from '../../../strings';
import FieldPermissionsDataTransformer from '../fields/FieldPermissionsDataTransformer';
import FieldPermissionsGrid from '../fields/FieldPermissionsGrid';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import type { TFieldPermissionWithName } from './NewAdditionalFieldWizard';

type TFieldPermissionsFormProps = {
    fieldPermissions: TFieldPermissionWithName[];
    allGroups: IApiGroup[];
    searchedString: string;
    onSearchedStringChange: (newSearchedString: string) => void;
    onFieldPermissionsChange: (newFieldPermissions: TFieldPermissionWithCol[], newItemToSave: TFieldPermissionWithCol) => void;
    gridHeight?: string;
};

export default class FieldPermissionsForm extends React.Component<TFieldPermissionsFormProps> {
    render() {
        return (
            <div className="d-flex flex-column">
                <div className="d-flex justify-content-end mb-2">
                    <div className="col-auto">
                        <Form.Control
                            type="text"
                            placeholder={Strings.search}
                            value={this.props.searchedString}
                            onChange={(e) => {
                                this.props.onSearchedStringChange(e.target.value);
                            }}
                        />
                    </div>
                </div>
                <FieldPermissionsDataTransformer
                    allGroups={this.props.allGroups}
                    fieldPermissions={this.props.fieldPermissions as TFieldPermissionWithCol[]}
                    selectedFolderName=""
                    isShowingAdditionalField
                    onFieldPermissionsChange={this.props.onFieldPermissionsChange}
                    render={({ fieldPermissions, onFieldPermissionsChange, onMandatoryPermissionsChange }) => (
                        <FieldPermissionsGrid
                            fieldPermissions={fieldPermissions}
                            onFieldPermissionChange={onFieldPermissionsChange}
                            onMandatoryPermissionChange={onMandatoryPermissionsChange}
                            searchedString={this.props.searchedString}
                            isShowingAdditionalField
                            allGroups={this.props.allGroups}
                            gridHeight={this.props.gridHeight}
                            systemGroupGuid={this.props.allGroups.find((group) => group.GroupName === GroupNames.system)!.ItemGUID}
                        />
                    )}
                />
            </div>
        );
    }
}
