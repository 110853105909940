import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Strings from '../../../../strings';
import type { IApiCapacityAvailableBundle, TFeatureWithEdition } from '@eway-crm/connector';
import { Feature } from '@eway-crm/connector';
import { SpinnerModal } from '../../../shared/SpinnerModal';
import RouteConfig from '../../../../RouteConfig';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import { DefaultButton, FontIcon, IconButton, Text } from '@fluentui/react';
import logoRed from '@eway-crm/js-resources/assets/ewayLogo/eWayCRM/eWayCRM-red.svg';
import './CheckoutResult.scss';
import LocalizationHelper from '../../../../helpers/LocalizationHelper';
import { WindowHelper } from '@eway-crm/gui';
import IframePostMessageHelper from '../../../../helpers/IframePostMessageHelper';
import StoreV2Context from '../StoreV2/StoreV2Context';
import { SpinnerVariant } from '@eway-crm/gui';

type TCheckoutResultProps = {
    isAnythingNew: boolean;
    onDismiss: (e: React.MouseEvent<unknown>) => void;
};

const CheckoutResult: React.FC<TCheckoutResultProps> = ({ isAnythingNew, onDismiss }) => {
    const { reloadLicense, connection } = useContext(ConnectionContext);
    const { currentPlan } = useContext(StoreV2Context);
    const [licenseData, setLicenseData] = useState<IApiCapacityAvailableBundle[] | null>(null);
    const myStrings = Strings.components.routes.subscriptions;
    const isInIframe = WindowHelper.isInIframe();

    useEffect(() => {
        const reloadLicenseData = async () => {
            const license = await reloadLicense(true, true, true);
            setLicenseData(license.AvailableBundles);

            IframePostMessageHelper.checkoutLoaded();
            IframePostMessageHelper.checkoutCompleted();
        };

        if (!licenseData) {
            void reloadLicenseData();
        }
    }, [reloadLicense, licenseData]);

    const getFreeSlotsCount = () => {
        if (!licenseData) {
            return 0;
        }

        let count = 0;
        licenseData.forEach(bundle => {
            const containsCaC = (featuresWithEditions: TFeatureWithEdition[]) => {
                return featuresWithEditions.some(featureWithEdition => featureWithEdition.Feature === Feature.ContactsAndCompanies);
            };

            if (containsCaC(bundle.Features) && bundle.FreeSlotsCount > 0) {
                count += bundle.FreeSlotsCount;
            }
        });

        return count;
    };

    const getTitle = () => {
        if (!isAnythingNew)
            return Strings.done;

        const firstName = connection.state.userItem?.FirstName;
        if (LocalizationHelper.isLangCs() || !firstName) {
            return myStrings.checkoutResultTitle;
        }

        return `${myStrings.checkoutResultTitle}, ${firstName}!`;
    };

    if (!licenseData) {
        return <SpinnerModal.CenteredContent variant={SpinnerVariant.linear} />;
    }

    const freeSlotsCount = getFreeSlotsCount();
    const isThereAnyFreeSlot = freeSlotsCount > 0;

    return (
        <div className="checkout-result__wrap">
            <div className="empty-div-for-positioning" />
            <div className="checkout-result">
                {!isInIframe && <IconButton iconProps={{ iconName: 'Cancel' }} className={'checkoutV2__close-icon'} onClick={onDismiss} />}
                <img src={logoRed} alt="eWay-CRM" className="checkout-result__logo" />
                <Text className="checkout-result__title">{getTitle()}</Text>

                <Text variant="large" className="checkout-result__subtitle">
                    {isAnythingNew ? myStrings.checkoutSuccessSubtitle : Strings.formatString(myStrings.checkoutSuccessDowngradeSubtitleFormat, Strings.formatDate(currentPlan?.NextBillingDate) ?? '')}
                </Text>

                <FontIcon className="checkout-result__completed-icon" iconName="CompletedSolid" />
            </div>
            <div className="checkout-result__btn-wrap">
                {!isInIframe && (
                    <>
                        {isThereAnyFreeSlot && (
                            <NavLink to={RouteConfig.users.actions.afterBuy.getPath(true, freeSlotsCount)}>
                                <DefaultButton primary={true} className="checkout-result__btn">
                                    {Strings.formatString(myStrings.addUsersFormat, freeSlotsCount)}
                                </DefaultButton>
                            </NavLink>
                        )}
                        <NavLink to={RouteConfig.users.actions.afterBuy.getPath(false)}>
                            <DefaultButton className="checkout-result__btn">
                                {myStrings.redistributeLicenses}
                            </DefaultButton>
                        </NavLink>
                    </>
                )}

                <NavLink to={RouteConfig.billing.subscriptions.path}>
                    <DefaultButton primary={isInIframe} className="checkout-result__btn" onClick={onDismiss}>
                        {myStrings.checkoutResultStartBtn}
                    </DefaultButton>
                </NavLink>
            </div>
        </div>
    );
};

export default CheckoutResult;
