import * as React from 'react';
import { CompoundButton, mergeStyles, mergeStyleSets, SpinButton } from '@fluentui/react';
import type { IRow } from "../../../../../data/shopping/ISubscriptionsShoppingModel";
import StoreV2Context from '../StoreV2Context';
import usePackage from '../hooks/usePackage';
import StoreHelper from '../../StoreHelper';
import { Subscriptions } from '../../../Subscriptions';
import Strings from '../../../../../strings';
import UnitPriceSpan from '../../UnitPriceSpan';
import PackageQuestionMarkPopover from '../../PackageQuestionMarkPopover';
import { packagesPanelWidth } from '../StoreV2Row';

const css = mergeStyleSets({
    compoundButton: {
        height: '160px',
        paddingTop: '28px',
        paddingBottom: '28px',
        paddingLeft: '16px',
        paddingRight: '16px'
    },
    questionMark: {
        position: 'absolute',
        top: '0',
        right: '0'
    },
    icon: {
        height: '1.1rem'
    }
});

type TLegacyRowProps = {
    row: IRow;
};

const LegacyRow: React.FC<TLegacyRowProps> = ({ row }) => {
    if (!row.Artnos || row.Artnos.length === 0)
        return null;

    const packageWidth = packagesPanelWidth / row.Artnos.length;

    return (
        <div className="container p-0">
            <div className="row m-0 buttons-row">
                {row.Artnos.map(artno => <LegacyPackage key={artno} artno={artno} width={packageWidth} />)}
            </div>
        </div>
    );
};

type TLegacyPackageProps = {
    artno: string;
    width: number;
};

const LegacyPackage: React.FC<TLegacyPackageProps> = ({ artno, width }) => {
    const { setPackageEditingState, currentDbSizeGigabytes, activeUsersCount, customerInfo, isPackageInCart, selectedPeriodType } = React.useContext(StoreV2Context);
    const { packageData, packageEditingState, isDisabled } = usePackage(artno);

    const packageQuantity = packageEditingState.currentEditQuantity || 0;

    const handleSpinChange = React.useCallback((e?: React.SyntheticEvent, newValue?: string) => {
        if (isDisabled)
            return;

        setPackageEditingState(artno, { currentEditQuantity: parseInt(newValue as string) });
    }, [artno, setPackageEditingState, isDisabled]);

    const handleToggle = React.useCallback(() => {
        if (isDisabled)
            return;

        if (packageQuantity) {
            setPackageEditingState(artno, { currentEditQuantity: 0 });
        } else {
            setPackageEditingState(artno, { currentEditQuantity: activeUsersCount });
        }
    }, [artno, setPackageEditingState, packageQuantity, isDisabled, activeUsersCount]);

    if (!packageData)
        return null;

    const { showUnitPriceAsUnit, shownPrice } = StoreHelper.getUnitPriceAttributes(
        packageData,
        customerInfo.currency,
        packageQuantity,
        selectedPeriodType,
        isPackageInCart
    );

    return (
        <div className="col-auto p-0 button-container-in-row">
            <CompoundButton disabled={isDisabled} toggle checked={!!packageQuantity} onClick={handleToggle} className={mergeStyles('button-in-row', css.compoundButton)} style={{ width: width + 'px' }} onRenderChildren={() => (
                <div style={{ width: '100%' }}>
                    <div>
                        <h4 className="mb-1">{Strings.pickTranslation(packageData.Title)}</h4>
                        {(!!packageData.Icons) &&
                            <div>
                                {packageData.Icons.map((icon, i) => <img key={'package-icon-' + i} src={icon} className={mergeStyles(css.icon, 'm-1')} alt={Strings.pickTranslation(packageData.Title)!} />)}
                            </div>
                        }
                        <div>
                            {(typeof shownPrice === 'number') &&
                                <UnitPriceSpan
                                    formattedPrice={(packageData.ShowPlusInPrice ? '+' : '') + Strings.formatCurrency(shownPrice, Subscriptions.priceDecPlaces, customerInfo.currency)!}
                                    packageData={packageData}
                                    showUnit={showUnitPriceAsUnit}
                                />
                            }
                        </div>
                        <div className={css.questionMark}>
                            <PackageQuestionMarkPopover packageData={packageData} currentDbSizeGigabytes={currentDbSizeGigabytes} />
                        </div>
                    </div>
                    {(!!packageQuantity) &&
                        <div className="d-flex justify-content-end mt-3">
                            <div style={{ width: '86px' }} onClick={(e) => e.stopPropagation()}>
                                <SpinButton
                                    disabled={isDisabled}
                                    value={String(packageQuantity)}
                                    min={0}
                                    step={1}
                                    onChange={handleSpinChange}
                                />
                            </div>
                        </div>
                    }
                </div>
            )} />
        </div>
    );
};

export default LegacyRow;