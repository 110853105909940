import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { TFolderName } from '@eway-crm/connector';
import React, { useContext } from 'react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import LicenseRestrictionsLockedGridRowInner from './LicenseRestrictionsLockedGridRowInner';

const LicenseRestrictionsLockedFieldsGridRow: React.FC<Table.DataRowProps> = (props) => {
    const { licenseRestrictionsHelper } = useContext(ConnectionContext);
    const typedRow = props.row as { FolderName: TFolderName; ColumnName: string };
    const myStrings = Strings.components.licenseRestrictions;

    const { isFieldLocked, fieldLockedLicenseRestriction } = licenseRestrictionsHelper.isFieldLocked(typedRow.FolderName, typedRow.ColumnName);

    if (isFieldLocked) {
        const children = props.children as React.ReactNode[];
        const folderNameColumn = children[0]; // Is first in children

        return (
            <tr>
                {folderNameColumn}
                <LicenseRestrictionsLockedGridRowInner colSpan={children.length} unlockText={myStrings.unlockThisField} licenseRestriction={fieldLockedLicenseRestriction} />
            </tr>
        );
    }

    return <VirtualTable.Row {...props} />;
};

export default LicenseRestrictionsLockedFieldsGridRow;


