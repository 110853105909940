import type { Edition, Feature } from '@eway-crm/connector';
import * as React from 'react';
import type { ICurrentPlanModel, IStoreModel, TPeriodType } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import { NumberHelper } from '../../../../../helpers/NumberHelper';
import StoreHelper from '../../StoreHelper';
import type { TPackageEditingStateBase } from '../../TPackageEditingState';
import type TPackagesEditingStates from '../TPackagesEditingStates';

const useSubscriptionEditing = (storeData: IStoreModel, currentPlan: ICurrentPlanModel | null, activeUsersCount: number) => {
    const { defaultPackagesStates, isAnythingAlreadySubscribed, defaultPeriodType } = React.useMemo(() => {
        const defaultPackagesStates = StoreHelper.getDefaultPackagesStates(storeData, null, (artno, quantity) => ({ artno, state: { currentEditQuantity: quantity } }));
        let periodType: TPeriodType | null = null;
        if (currentPlan?.PeriodType) {
            const currentPlanPeriod = storeData.AvailablePeriods.find(per => per === currentPlan?.PeriodType);
            if (currentPlanPeriod) {
                periodType = currentPlanPeriod;
            }
        }
        if (!periodType && storeData.AvailablePeriods.length > 0) {
            // Pick the middle one as a psychological trick. Also, yearly is better than triennialy since it is supported by payment gates
            if (storeData.AvailablePeriods.length === 3) {
                periodType = storeData.AvailablePeriods[1];
            } else {
                periodType = storeData.AvailablePeriods[0];
            }
        }
        return {
            defaultPackagesStates,
            isAnythingAlreadySubscribed: defaultPackagesStates.some(ps => !!ps.state.currentEditQuantity && ps.state.currentEditQuantity > 0),
            defaultPeriodType: periodType ?? 'Monthly'
        };
    }, [storeData, currentPlan?.PeriodType]);

    const { featureToPakagesMap, lowerOrEqualThanArtnosMap, requiredArtnosMap, artnoToFeatureMap, ekArtnos } = React.useMemo(() => {
        const map: Partial<Record<Feature, { artno: string; edition: Edition }[]>> = {};
        const lowerThanFeatureArray: { artno: string; lowerOrEqualThanFeature: Feature }[] = [];
        const requiresFeatureArray: { artno: string; requiresFeature: Feature }[] = [];
        const artnoToFeatureMap: Record<string, Feature> = {};
        const ekArtnos: string[] = [];
        StoreHelper.iteratePackages(storeData, (packageData) => {
            if (!packageData.Artno)
                return;

            if (packageData.ProvidedFeatures) {
                packageData.ProvidedFeatures.forEach((fe) => {
                    const obj = { artno: packageData.Artno!, edition: fe.Edition };
                    if (!map[fe.Feature]) {
                        map[fe.Feature] = [obj];
                    } else {
                        map[fe.Feature]!.push(obj);
                    }
                });
            }

            if (packageData.AmountRules?.LowerOrEqualThanFeature) {
                lowerThanFeatureArray.push({ artno: packageData.Artno, lowerOrEqualThanFeature: packageData.AmountRules.LowerOrEqualThanFeature });
            }
            if (packageData.AmountRules?.RequiresFeature) {
                requiresFeatureArray.push({ artno: packageData.Artno, requiresFeature: packageData.AmountRules.RequiresFeature });
            }

            if (packageData.ProvidedFeatures) {
                artnoToFeatureMap[packageData.Artno] = packageData.ProvidedFeatures[0].Feature;
            }

            if (packageData.RequiresEmailkampaneczAccount) {
                ekArtnos.push(packageData.Artno);
            }
        });
        const lowerOrEqualThanArtnosMap: Record<string, string[]> = {};
        lowerThanFeatureArray.forEach(item => {
            const prerequisity = map[item.lowerOrEqualThanFeature];
            if (prerequisity && prerequisity.length !== 0) {
                lowerOrEqualThanArtnosMap[item.artno] = prerequisity.map(d => d.artno);
            }
        });
        const requiredArtnosMap: Record<string, string[]> = {};
        requiresFeatureArray.forEach(item => {
            const prerequisity = map[item.requiresFeature];
            if (prerequisity && prerequisity.length !== 0) {
                requiredArtnosMap[item.artno] = prerequisity.map(d => d.artno);
            }
        });
        return { featureToPakagesMap: map, lowerOrEqualThanArtnosMap, requiredArtnosMap, artnoToFeatureMap, ekArtnos };
    }, [storeData]);

    const [packagesEditingStates, setPackagesEditingStates] = React.useState<TPackagesEditingStates>(defaultPackagesStates);
    const revertToDefaultPackagesStates = React.useCallback(() => {
        setPackagesEditingStates(defaultPackagesStates);
        setPeriodType(defaultPeriodType);
    }, [defaultPackagesStates, defaultPeriodType]);
    const [periodType, setPeriodType] = React.useState<TPeriodType>(defaultPeriodType);

    const setMultiplePackagesEditingStates = React.useCallback((newStates: { artno: string; newState: Partial<TPackageEditingStateBase> }[], isEditionSwitch?: boolean) => {
        if (newStates.length === 0)
            return;

        setPackagesEditingStates((prevPackagesEditingStates) => {
            let array = prevPackagesEditingStates.map(px => ({ artno: px.artno, state: { ...px.state } }));
            newStates.forEach(item => {
                const { artno, newState } = item;
                const index = array.findIndex(ps => ps.artno === artno);
                if (index > -1) {
                    const resultState = { ...array[index].state, ...newState };
                    array[index] = { artno, state: resultState };
                } else {
                    const resultState = { ...{ currentEditQuantity: null }, ...newState };
                    array.push({ artno, state: resultState });
                }
            });

            const satisfyAmountDependencies = (
                getMasters: (artno: string) => string[] | null,
                getSatisfactionRules: (slaveQuantity: number, masterQuantitiesSum: number, prevSlaveQuantity: number) => { getRequestedMasterQuantity: (masterItemQuantity: number) => number; requestedSlaveQuantity: number } | null
            ) => {
                let anythingAdded: boolean;
                do {
                    anythingAdded = false;
                    const toBeAdded: TPackagesEditingStates = [];
                    array.forEach((item, _index, thisArray) => {
                        const masterArtnos = getMasters(item.artno);
                        if (!masterArtnos || masterArtnos.length === 0)
                            return;

                        const slaveQuantity = (item.state.currentEditQuantity || 0);
                        const prevSlaveQuantity = prevPackagesEditingStates.find(ppes => ppes.artno === item.artno)?.state?.currentEditQuantity || 0;
                        const masterQuantity = [
                            ...thisArray.filter(ps => masterArtnos.includes(ps.artno)),
                            ...toBeAdded.filter(ps => masterArtnos.includes(ps.artno))
                        ].map(ps => ps.state.currentEditQuantity || 0).sum();
                        const satisfactionRules = getSatisfactionRules(slaveQuantity, masterQuantity, prevSlaveQuantity);
                        if (!satisfactionRules)
                            return;

                        // Decide which to change depending on what state is being set.
                        if (newStates.find(ns => ns.artno === item.artno) && (masterQuantity === 0 || prevSlaveQuantity !== 0)) {
                            // The slave is being changed (or added with master not there) -> increase the master.
                            const masterItem = thisArray.find(ps => masterArtnos.includes(ps.artno) && ps.state.currentEditQuantity) ?? toBeAdded.find(ps => masterArtnos.includes(ps.artno));
                            if (masterItem) {
                                // Increase the master the missing amount
                                masterItem.state.currentEditQuantity = satisfactionRules.getRequestedMasterQuantity(masterItem.state.currentEditQuantity || 0);
                            } else {
                                // Add the master to the cart. Same edition is tried to be found.
                                let addedArtno = masterArtnos[0];
                                const packageData = StoreHelper.getPackageByArtno(storeData, item.artno);
                                if (packageData) {
                                    const sameEditionArtno = StoreHelper.getArtnoWithSameEdition(packageData, masterArtnos, storeData);
                                    if (sameEditionArtno) {
                                        addedArtno = sameEditionArtno;
                                    }
                                }
                                const zeroedMasterItem = thisArray.find(ps => ps.artno === addedArtno);
                                if (zeroedMasterItem) {
                                    zeroedMasterItem.state.currentEditQuantity = satisfactionRules.getRequestedMasterQuantity(0);
                                } else {
                                    toBeAdded.push({
                                        artno: addedArtno,
                                        state: { currentEditQuantity: ((item.state.currentEditQuantity || 0) - masterQuantity) }
                                    });
                                }
                            }
                        } else {
                            // The master is being set -> decrease the slave.
                            item.state.currentEditQuantity = satisfactionRules.requestedSlaveQuantity;
                        }
                    });
                    if (toBeAdded.length > 0) {
                        array = [...array, ...toBeAdded];
                        anythingAdded = true;
                    }
                } while (anythingAdded);
            };

            // Make sure depending articles will not overcome the main articles (there is not more Sales than Caces)
            satisfyAmountDependencies(
                (itemArtno) => lowerOrEqualThanArtnosMap[itemArtno],
                (slaveQuantity, masterQuantity, prevSlaveQuantity) => {
                    if (slaveQuantity <= masterQuantity) {
                        if (prevSlaveQuantity !== 0 || slaveQuantity === 0)
                            return null;

                        if (isEditionSwitch)
                            // Slave was added as a edition switch with lower than master quantity. Keep it.                        
                            return null;

                        // Slave newly added with lower quantity. Override quantity to master amount.
                        return {
                            getRequestedMasterQuantity: (masterItemQuantity) => masterItemQuantity,
                            requestedSlaveQuantity: masterQuantity
                        };
                    }

                    return {
                        getRequestedMasterQuantity: (masterItemQuantity) => masterItemQuantity + slaveQuantity - masterQuantity,
                        requestedSlaveQuantity: masterQuantity
                    };
                }
            );

            // Make sure the required articles are there in nonzero amount (there is no Marketing without any Cac)
            satisfyAmountDependencies(
                (itemArtno) => requiredArtnosMap[itemArtno],
                (slaveQuantity, masterQuantity) => {
                    if (slaveQuantity === 0 || masterQuantity !== 0)
                        return null;

                    return {
                        getRequestedMasterQuantity: () => activeUsersCount,
                        requestedSlaveQuantity: (masterQuantity === 0) ? 0 : slaveQuantity
                    };
                }
            );

            // If we increase one of the main articles, increase all depending which have the equal amount
            array.forEach(item => {
                const prevQuantity = (prevPackagesEditingStates.find(ppes => ppes.artno === item.artno)?.state.currentEditQuantity || 0);
                if ((item.state.currentEditQuantity || 0) !== prevQuantity)
                    return;

                if (prevQuantity === 0)
                    return;

                const greaterOrEqualArtnos = lowerOrEqualThanArtnosMap[item.artno];
                if (!greaterOrEqualArtnos)
                    return;

                const increasedGreaterAnchorArtno = greaterOrEqualArtnos.find(grArtno => {
                    const grPrevQuantity = (prevPackagesEditingStates.find(ppes => ppes.artno === grArtno)?.state.currentEditQuantity || 0);
                    return grPrevQuantity < (array.find(ps => ps.artno === grArtno)?.state.currentEditQuantity || 0) && grPrevQuantity === prevQuantity;
                });
                if (!increasedGreaterAnchorArtno)
                    return;

                item.state.currentEditQuantity = (array.find(ps => ps.artno === increasedGreaterAnchorArtno)?.state.currentEditQuantity || 0);
            });

            const compFunc = (a: { artno: string }) => StoreHelper.getPackageByArtno(storeData, a.artno)?.FinalCartRank;
            array = array.sort((a, b) => NumberHelper.compareWithNulls(compFunc(a), compFunc(b), 0));
            return array;
        });
    }, [storeData, setPackagesEditingStates, lowerOrEqualThanArtnosMap, requiredArtnosMap, activeUsersCount]);

    const setPackageEditingState = React.useCallback((artno: string, newState: Partial<TPackageEditingStateBase>) => {
        setMultiplePackagesEditingStates([{ artno, newState }]);
    }, [setMultiplePackagesEditingStates]);

    const isPackageInCart = React.useCallback((an: string) => {
        const ps = packagesEditingStates.find(ps => ps.artno === an);
        return !!ps && !!ps.state.currentEditQuantity;
    }, [packagesEditingStates]);

    return {
        defaultPackagesStates,
        isAnythingAlreadySubscribed,
        artnoToFeatureMap,
        ekArtnos,
        featureToPakagesMap,
        packagesEditingStates,
        revertToDefaultPackagesStates,
        setMultiplePackagesEditingStates,
        setPackageEditingState,
        isPackageInCart,
        periodType,
        setPeriodType
    };
};

export default useSubscriptionEditing;