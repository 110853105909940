import { LicenseRestrictionKeys } from '@eway-crm/connector';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import type { TLicenseRestriction, TLicenseRestrictionsObject } from '../../../helpers/LicenseRestrictionsHelper';
import type { TFieldWithCol } from './FolderFields';

type TAllFields = Pick<TFieldWithCol, 'ColumnName' | 'MandatoryRule' | 'PermissionRule'>[];

export default class FieldsLicenseRestrictionHelper {
    static readonly NEAR_LIMIT_WARNING_PERCENTAGE = 0.8;

    private readonly licenseRestrictions: TLicenseRestrictionsObject | null;
    private readonly allFields: TAllFields;
    private readonly currentColName?: string | null;

    constructor(licenseRestrictions: TLicenseRestrictionsObject | null, allFields: TAllFields, currentColName?: string | null) {
        this.licenseRestrictions = licenseRestrictions;
        this.allFields = allFields;
        this.currentColName = currentColName;
    }

    /**
     * Returns Mandatory/Optional/Unique/Readonly counts from all fields provided to the helper
     */
    public readonly getRuleCount = (ruleKeyToCheck: 'MandatoryRule' | 'PermissionRule', ruleKey: string, newChecked: boolean) => {
        let count = 0;
        if (newChecked) {
            count++;
        }

        this.allFields.forEach((field) => {
            if (field.ColumnName === this.currentColName) {
                // Skip counting for current field
                return;
            }

            if (field[ruleKeyToCheck] === ruleKey) {
                count++;
            }
        });

        return count;
    };

    /**
     * Checks if the rule can be toggled based on license restrictions
     */
    public readonly onRuleToggle = (ruleKey: string, newChecked: boolean) => {
        const mandatoryFieldsLicenseRestriction = this.licenseRestrictions?.functionalities[LicenseRestrictionKeys.mandatoryFields] ?? null;
        const importantFieldsLicenseRestriction = this.licenseRestrictions?.functionalities[LicenseRestrictionKeys.importantFields] ?? null;
        const uniqueFieldsLicenseRestriction = this.licenseRestrictions?.functionalities[LicenseRestrictionKeys.uniqueFields] ?? null;
        const readOnlyFieldsLicenseRestriction = this.licenseRestrictions?.functionalities[LicenseRestrictionKeys.readOnlyFields] ?? null;
        let appliedLicenseRestriction: TLicenseRestriction | null = null;

        const getCanContinue = (perModuleLimit: number | null | undefined, ruleCount: number) => {
            let canContinue = true;
            if (typeof perModuleLimit === 'number') {
                if (perModuleLimit === 0) {
                    // Is Disabled
                    canContinue = false;
                } else {
                    canContinue = !newChecked || ruleCount <= perModuleLimit;
                }
            }

            return {
                canContinue,
                appliedLicenseRestriction
            };
        };

        if (ruleKey === FieldPermissionNames.MandatoryRuleKeys.Mandatory) {
            const mandatoryCount = this.getRuleCount('MandatoryRule', FieldPermissionNames.MandatoryRuleKeys.Mandatory, newChecked);
            appliedLicenseRestriction = mandatoryFieldsLicenseRestriction;
            return getCanContinue(mandatoryFieldsLicenseRestriction?.currentLimit, mandatoryCount);
        } else if (ruleKey === FieldPermissionNames.MandatoryRuleKeys.Optional) {
            const optionalCount = this.getRuleCount('MandatoryRule', FieldPermissionNames.MandatoryRuleKeys.Optional, newChecked);
            appliedLicenseRestriction = importantFieldsLicenseRestriction;
            return getCanContinue(importantFieldsLicenseRestriction?.currentLimit, optionalCount);
        } else if (ruleKey === FieldPermissionNames.MandatoryRuleKeys.Unique) {
            const uniqueCount = this.getRuleCount('MandatoryRule', FieldPermissionNames.MandatoryRuleKeys.Unique, newChecked);
            appliedLicenseRestriction = uniqueFieldsLicenseRestriction;
            return getCanContinue(uniqueFieldsLicenseRestriction?.currentLimit, uniqueCount);
        } else if (ruleKey === FieldPermissionNames.PermissionRuleKeys.Readonly) {
            const readOnlyCount = this.getRuleCount('PermissionRule', FieldPermissionNames.PermissionRuleKeys.Readonly, newChecked);
            appliedLicenseRestriction = readOnlyFieldsLicenseRestriction;
            return getCanContinue(readOnlyFieldsLicenseRestriction?.currentLimit, readOnlyCount);
        }

        return {
            canContinue: true,
            appliedLicenseRestriction
        };
    };
}
