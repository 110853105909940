import { Depths, mergeStyleSets } from '@fluentui/react';
import React from 'react';
import type { IFeaturedVideo } from '../../../data/commonDataApi/IAdminFeaturedVideosResponse';
import playImg from '../../../img/play.svg';

const css = mergeStyleSets({
    carouselItem: {
        width: '100%'
    },
    carouselItemInner: {
        cursor: 'pointer',
        boxShadow: Depths.depth8
    },
    carouselItemImgAspectRatioBox: {
        width: '100%',
        paddingTop: '55.5%', // 16:9 would be 56.25%, we want to avoid rounding pixels.
        position: 'relative'
    },
    carouselItemImgAspectRatioFloater: {
        top: 0, bottom: 0, left: 0, right: 0,
        position: 'absolute'
    },
    carouselItemImg: {
        width: '100%',
        height: '100%',
        objectFit: 'cover'
    },
    playImg: {
        height: '50%',
        display: 'inline !important'
    }
});

type TVideoCarouselItemProps = {
    video: IFeaturedVideo;
    onOpen: () => void;
};

const VideoCarouselItem: React.FC<TVideoCarouselItemProps> = ({ video, onOpen }) => {
    return (
        <div className={'py-2 px-1 ' + css.carouselItem} onClick={() => onOpen()}>
            <div className={css.carouselItemInner} >
                <div className={css.carouselItemImgAspectRatioBox}>
                    <div className={css.carouselItemImgAspectRatioFloater}>
                        <img src={video.ThumbnailUrl} className={css.carouselItemImg} alt={video.Title} />
                    </div>
                    <div className={css.carouselItemImgAspectRatioFloater}>
                        <div className="text-center h-100">
                            <span className="d-inline-block h-100 align-middle" />
                            <img src={playImg} className={css.playImg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VideoCarouselItem;