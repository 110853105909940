import * as React from 'react';
import Strings from '../../../../strings';
import type { TGroupWizardBaseProps } from '../GroupWizardBase';
import { GroupWizardBase } from '../GroupWizardBase';
import { SpinnerModal } from '../../../shared/SpinnerModal';
import WizardModal from '../../../WizardModal';
import { Nav, Tab, Form, Col, Button, Spinner } from 'react-bootstrap';
import FolderNames from '../../../../data/constants/FolderNames';
import RelationTypes from '../../../../data/constants/RelationTypes';
import GroupBadgeFlags from '../GroupBadgeFlags';
import type { IApiGroup, IApiLayout, IApiLayoutsModel, TFolderName } from '@eway-crm/connector';
import type { TApiItemWithReleations, IApiBoundRelation } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import StringHelper from '../../../../helpers/StringHelper';
import { GroupNames } from '../../../../data/constants/GroupNames';
import type { TModulePermissionWithFolder } from '../../ModulePermissions';
import ModulePermissions from '../../ModulePermissions';
import FieldPermissionsWarningDialog from '../../fields/FieldPermissionsWarningDialog';
import FieldPermissionsDataTransformer from '../../fields/FieldPermissionsDataTransformer';
import Alert from '../../../shared/Alert';
import { RemoteItemStore } from '../../../../RemoteItemStore';
import { ItemStorage } from '../../../../data/ItemStorageWithDelayedSave';
import type { TFieldPermissionWithCol } from '../../fields/FieldPermissionsGrid';
import SearchResultsObtainer from '../../home/SearchBar/SearchResultsObtainer';
import LicenseRestrictionsLockIcon from '../../../shared/locks/LicenseRestrictionsLockedIcon';
import type { RouteComponentProps, match as IRouterMatch} from 'react-router-dom';
import { matchPath, withRouter } from 'react-router-dom';
import RouteConfig from '../../../../RouteConfig';
import { DelayedRender } from '@fluentui/react';
import type { TConnectionContext } from '../../../../providers/ConnectionProvider';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import FeatureLockedSplash from '../../../shared/locks/FeatureLockedSplash';
import { SpinnerVariant, VersionHelper } from '@eway-crm/gui';
import WizardBase from '../../../shared/WizardBase';
import DisabledByPermissionBadge from '../../../shared/DisabledByPermissionsBadge';
import DefaultFormLayoutsForm from '../defaultFormLayouts/DefaultFormLayoutsForm';
import type { TFormLayoutGroupMapping } from '../defaultFormLayouts/DefaultFormLayoutsGrid';
import ReactHelper from '../../../../helpers/ReactHelper';

const dialogBodyExpectedHeight = '42rem';
const dialogGridHeight = '28rem';
const myStrings = Strings.components.routes.groups;

type TTabName = 'general' | 'users' | 'mp' | 'fp' | 'additional' | 'defaultFormLayouts';

const isOfTabsType = (keyInput: string): keyInput is TTabName => {
    return ['general', 'users', 'mp', 'fp', 'additional', 'defaultFormLayouts'].includes(keyInput);
};

type TEditGroupWizardProps = TGroupWizardBaseProps &
    RouteComponentProps & {
        groupItem: TApiItemWithReleations<IApiGroup>;
        allGroups: TApiItemWithReleations<IApiGroup>[];
        modulePermissions: TModulePermissionWithFolder[];
        fieldPermissions: TFieldPermissionWithCol[];
        folders: string[];
        defaultGroupUserGuids: string[];
        layouts: IApiLayout[];
        groupLayoutsModels: IApiLayoutsModel[];
    };

type TEditGroupWizardState = {
    isGeneralFormValidated: boolean;
    isSavingInProgress: boolean;
    groupName: string;
    responsibilityDescription: string;
    note: string;
    usersSearchedString: string;
    usersSelection: string[];
    isPm: boolean;
    isRole: boolean;
    anyChangeMade: boolean;
    modulePermissions: TModulePermissionWithFolder[];
    fieldPermissions: TFieldPermissionWithCol[];
    selectedFolderName: string | null;
    isCheckingForDuplicateValues: boolean;
    nonUniqueValue: string | null;
    uniqueNotFullyPermittedForGroupName: string | null;
    layoutsMappings: TFormLayoutGroupMapping[];
};

class EditGroupWizard extends React.Component<TEditGroupWizardProps, TEditGroupWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly fieldPermissionsStorage: ItemStorage<TFieldPermissionWithCol>;
    private readonly modulePermissionsStorage: ItemStorage<TModulePermissionWithFolder>;
    private readonly generalFormReference: React.RefObject<HTMLFormElement>;

    constructor(props: TEditGroupWizardProps, context: TConnectionContext) {
        super(props);
        this.generalFormReference = React.createRef();
        this.modulePermissionsStorage = new ItemStorage(item => item.FolderName);
        this.fieldPermissionsStorage = new ItemStorage(item => `${item.ColumnName}.${item.FolderName}`);

        let usersSelection: string[] = [];
        if (!props.groupItem.Relations || props.groupItem.Relations.length === 0) {
            console.debug(`User ${props.groupItem.FileAs}/${props.groupItem.ItemGUID} has no bound relations.`);
        } else {
            usersSelection = EditGroupWizard.getAssignedUsers(props.groupItem.Relations, props.availableUsers);
        }

        const layoutsFolders = RemoteItemStore.getDisplayableWorkflowFolders(context.apiData.objectTypes, context.licenseRestrictionsHelper);

        this.state = {
            isGeneralFormValidated: false,
            isSavingInProgress: false,
            groupName: props.groupItem.GroupName ? props.groupItem.GroupName : '',
            responsibilityDescription: props.groupItem.ResponsibilityDescription ? props.groupItem.ResponsibilityDescription : '',
            note: props.groupItem.Description ? props.groupItem.Description : '',
            usersSearchedString: '',
            usersSelection: usersSelection,
            modulePermissions: this.props.modulePermissions,
            fieldPermissions: this.props.fieldPermissions,
            selectedFolderName: null,
            isPm: props.groupItem.IsPM ? true : false,
            isRole: props.groupItem.IsRole ? true : false,
            anyChangeMade: false,
            isCheckingForDuplicateValues: false,
            nonUniqueValue: null,
            uniqueNotFullyPermittedForGroupName: null,
            layoutsMappings: layoutsFolders.map(folderName => ({
                folderName,
                layoutGuid: props.groupLayoutsModels.find(lm => lm.FolderName === folderName)?.LayoutGuid ?? null
            }))
        };
    }

    private static getAssignedUsers(relations: IApiBoundRelation[], availableUsers: IApiUser[]): string[] {
        if (!relations || relations.length === 0)
            return [];

        return relations
            .filter((rel) => rel.RelationType === RelationTypes.group && rel.ForeignFolderName === FolderNames.users && availableUsers.findIndex((usr) => usr.ItemGUID === rel.ForeignItemGUID) !== -1)
            .map((rel) => rel.ForeignItemGUID);
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly changeUsersSelection = (selection: string[]) => {
        if (this.props.groupItem.DisallowControlUserAssignment) {
            return;
        }

        const currentUserGuid = this.context.connection.state.session!.userGuid;
        if (this.props.groupItem.IsAdmin && this.props.groupItem.Relations.some(rel => rel.ForeignItemGUID === currentUserGuid) && !selection.includes(currentUserGuid)) {
            // Do not allow deselection of admin rigths for current user
            selection.push(currentUserGuid);
        }

        this.setState({ usersSelection: selection, anyChangeMade: true });
    };

    private readonly onModulePermissionChange = (rowId: string, column: string, newValue: string | number | boolean | null) => {
        const groupGuid = this.props.groupItem.ItemGUID;

        const newModulePermissions = this.state.modulePermissions.map((mp) => {
            if (mp.FolderName === rowId && mp.GroupGuid === groupGuid) {
                const updatedModulePermission = ModulePermissions.changeModulePermission(mp, column, newValue);
                this.modulePermissionsStorage.addItem(updatedModulePermission);
                return updatedModulePermission;
            }
            return mp;
        });

        this.setState({ modulePermissions: newModulePermissions, anyChangeMade: true });
    };

    private readonly handleDefaultFormsMappingChange = (folderName: TFolderName, layoutGuid: string | null) => this.setState((prevState) => ({
        layoutsMappings: prevState.layoutsMappings.map(lm => {
            if (lm.folderName !== folderName)
                return lm;

            return { folderName, layoutGuid };
        }),
        anyChangeMade: true
    }));

    private readonly changeIsRoleAndIsPm = (isRole: boolean, isPm: boolean) => {
        if (this.props.groupItem.System) {
            return;
        }
        this.setState({ isPm: isPm, isRole: isRole, anyChangeMade: true });
    };

    private readonly submit = async () => {
        if (this.generalFormReference.current && this.generalFormReference.current.checkValidity() === false) {
            this.setState({ isGeneralFormValidated: true });
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        const saveModulePermissions = async () => {
            const modulePermissionsToSave = this.modulePermissionsStorage.getItems();
            if (!modulePermissionsToSave.length)
                return;

            await new RemoteItemStore(this.context.connection).saveModulePermissions(modulePermissionsToSave)
                .catch(err => console.error('Unable to save module permissions.', err));
        };

        const saveFieldPermissions = async () => {
            const fieldPermissionsToSave = this.fieldPermissionsStorage.getItems();
            if (!fieldPermissionsToSave.length)
                return;

            await this.context.connection.askApi(
                'SaveColumnPermissions',
                {
                    transmitObjects: fieldPermissionsToSave.map((item) => ({
                        GroupGuid: item.GroupGuid,
                        FolderName: item.FolderName,
                        ColumnName: item.ColumnName,
                        PermissionRule: item.PermissionRule,
                        MandatoryRule: item.MandatoryRule,
                    })),
                }
            );
        };

        // Only allow saving Field Permissions in group System
        if (StringHelper.compareIgnoringCase(this.props.groupItem.GroupName, GroupNames.system) === 0) {
            await saveFieldPermissions();
            this.props.onDone();
            return;
        }

        await this.context.connection.askApi(
            'SaveGroup',
            {
                transmitObject: {
                    ItemGUID: this.props.groupItem.ItemGUID,
                    IsPM: this.state.isPm,
                    IsRole: this.state.isRole,
                    GroupName: this.state.groupName.trim(),
                    FileAs: this.state.groupName.trim(),
                    ResponsibilityDescription: this.state.responsibilityDescription,
                    Description: this.state.note,
                },
            });
        SearchResultsObtainer.setShouldReloadOnNextSearch(true);

        await saveModulePermissions();
        await saveFieldPermissions();

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());
        if (is810OrLater) {
            const layoutsModelsToBeRemoved = this.props.groupLayoutsModels.filter(lm => {
                const mapping = this.state.layoutsMappings.find(mapping => mapping.folderName === lm.FolderName);
                return !mapping || !mapping.layoutGuid || mapping.layoutGuid !== lm.LayoutGuid;
            });
            const layoutsModelsToBeCreated = this.state.layoutsMappings
                .filter(mapping => {
                    if (!mapping.layoutGuid)
                        return false;

                    const model = this.props.groupLayoutsModels.find(lm => lm.FolderName === mapping.folderName && lm.LayoutGuid === mapping.layoutGuid);
                    if (model)
                        return false;

                    return true;
                })
                .map((mapping): Partial<IApiLayoutsModel> => ({
                    GroupGuid: this.props.groupItem.ItemGUID,
                    LayoutGuid: mapping.layoutGuid,
                    FolderName: mapping.folderName
                }));
            if (layoutsModelsToBeRemoved.length > 0) {
                await this.context.connection.askApi('DeleteLayoutsModels', { itemGuids: layoutsModelsToBeRemoved.map(lm => lm.ItemGUID) });
            }
            if (layoutsModelsToBeCreated.length > 0) {
                await this.context.connection.askApi('SaveLayoutsModels', { transmitObjects: layoutsModelsToBeCreated });
            }
        }

        const finalCallback = this.props.onDone;
        if (this.props.groupItem.DisallowControlUserAssignment) {
            finalCallback();
            return;
        }

        let relationsToBeRemoved: string[] = [];
        let usersToBeAdded: string[] = [];
        if (this.props.groupItem.Relations && this.props.groupItem.Relations.length !== 0) {
            relationsToBeRemoved = this.props.groupItem.Relations.filter(
                (rel) =>
                    this.props.availableUsers.findIndex((avRel) => avRel.ItemGUID === rel.ForeignItemGUID) !== -1 &&
                    rel.RelationType === RelationTypes.group &&
                    !this.state.usersSelection.includes(rel.ForeignItemGUID)
            ).map((rel) => rel.RelationDataGUID);
            usersToBeAdded = this.state.usersSelection.filter(
                (userGuid) => this.props.groupItem.Relations.findIndex((rel) => rel.ForeignItemGUID === userGuid && rel.RelationType === RelationTypes.group) === -1
            );
        } else {
            relationsToBeRemoved = [];
            usersToBeAdded = this.state.usersSelection;
        }

        if (relationsToBeRemoved.length > 0) {
            const removedDefaultGroupUserGuids = this.props.defaultGroupUserGuids.filter(dfgUserGuid => !this.state.usersSelection.includes(dfgUserGuid));
            if (removedDefaultGroupUserGuids.length > 0) {
                await this.context.connection.askApi(
                    'SaveUsers',
                    {
                        transmitObjects: removedDefaultGroupUserGuids.map((userGuid): Partial<IApiUser> => ({
                            ItemGUID: userGuid,
                            Groups_Default_GroupGuid: null
                        })),
                    }
                );
            }

            await this.context.connection.askApi(
                'DeleteRelations',
                {
                    relationDataGuids: relationsToBeRemoved,
                }
            );
        }

        if (usersToBeAdded.length > 0) {
            const groupRelations = usersToBeAdded.map((userGuid) => {
                return {
                    ItemGUID1: userGuid,
                    ItemGUID2: this.props.groupItem.ItemGUID,
                    FolderName1: FolderNames.users,
                    FolderName2: FolderNames.groups,
                    RelationType: RelationTypes.group,
                    DifferDirection: false,
                };
            });
            await this.context.connection.askApi(
                'SaveRelations',
                {
                    transmitObjects: groupRelations,
                }
            );
        }

        finalCallback();
    };

    private readonly getFieldDisabledByPermissions = (fieldName: string) => {
        return WizardBase.getFieldDisabledByPermissions(fieldName, this.props.myColumnPermissionsMap);
    };

    private readonly getCurrentTabKey = (): TTabName => {
        const match: IRouterMatch<{ key: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.groups.edit.pathWithTabKey });
        const tabKey = match?.params?.key;

        if (tabKey && isOfTabsType(tabKey)) {
            return tabKey;
        }
        return 'general';
    };

    private readonly onSelectTabKey = (key: string | null) => {
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        if (key === "mp" && isModulePermissionsLocked) {
            this.context.showLicenseRestrictionModal(modulePermissionsLicenseRestriction);
            return;
        }

        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        if (key === "fp" && isFieldPermissionsLocked) {
            this.context.showLicenseRestrictionModal(fieldPermissionsLicenseRestriction);
            return;
        }

        const { isFormLayoutsCustomizationLimited, formLayoutsCustomizationLicenseRestriction } = this.context.licenseRestrictionsHelper.isFormLayoutsCustomizationLimited();
        if (key === "defaultFormLayouts" && isFormLayoutsCustomizationLimited) {
            this.context.showLicenseRestrictionModal(formLayoutsCustomizationLicenseRestriction);
            return;
        }

        const { isUserRolesReadOnly, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesReadOnly();
        if (key === "additional" && isUserRolesReadOnly) {
            this.context.showLicenseRestrictionModal(userRolesLicenseRestriction);
            return;
        }

        this.props.history.push(`${RouteConfig.groups.edit.path}/${this.props.groupItem.ItemGUID}/${key ?? ''}`);
    };

    render() {
        const descriptionDisabledByPermissions = this.getFieldDisabledByPermissions("ResponsibilityDescription");
        const noteDisabledByPermissions = this.getFieldDisabledByPermissions("Description");

        const currentTabKey = this.getCurrentTabKey();
        const { isUserRolesReadOnly, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesReadOnly();
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        const { isFormLayoutsCustomizationLimited, formLayoutsCustomizationLicenseRestriction } = this.context.licenseRestrictionsHelper.isFormLayoutsCustomizationLimited();

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal
                    show={true}
                    size="xl"
                    onHide={this.dismiss}
                    showCancelButtonInFooter={true}
                    hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}
                    dialogBodyExpectedHeight={dialogBodyExpectedHeight}
                >
                    <WizardModal.Body>
                        <h1 className="text-truncate" title={this.props.groupItem.GroupName}>
                            {this.props.groupItem.GroupName}
                            <GroupBadgeFlags item={this.props.groupItem} style={{ fontSize: '1rem' }} />
                        </h1>
                        <p className="text-break">{this.props.groupItem.ResponsibilityDescription}</p>
                        <Tab.Container activeKey={currentTabKey} onSelect={this.onSelectTabKey} mountOnEnter>
                            <div className="mt-4">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey="general">{myStrings.general}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="users">{myStrings.users}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="mp">
                                            {myStrings.modulePermissions} {isModulePermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={modulePermissionsLicenseRestriction} />}
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="fp">
                                            {myStrings.fieldPermissions} {isFieldPermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={fieldPermissionsLicenseRestriction} />}
                                        </Nav.Link>
                                    </Nav.Item>
                                    {(is810OrLater) &&
                                        <Nav.Item>
                                            <Nav.Link eventKey="defaultFormLayouts">
                                                {myStrings.defaultLayouts} {isFormLayoutsCustomizationLimited && <LicenseRestrictionsLockIcon licenseRestriction={formLayoutsCustomizationLicenseRestriction} />}
                                            </Nav.Link>
                                        </Nav.Item>
                                    }
                                    <Nav.Item>
                                        <Nav.Link eventKey="additional">
                                            {myStrings.special} {isUserRolesReadOnly && <LicenseRestrictionsLockIcon licenseRestriction={userRolesLicenseRestriction} />}
                                        </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="mt-4">
                                <DelayedRender>
                                    <Tab.Content>
                                        <Tab.Pane eventKey="general">
                                            <Form ref={this.generalFormReference} validated={this.state.isGeneralFormValidated} className="form-application">
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="newGroupName">
                                                        <Form.Label>{myStrings.groupName}</Form.Label> {!this.props.groupItem.System && isUserRolesReadOnly && <LicenseRestrictionsLockIcon licenseRestriction={userRolesLicenseRestriction} />}
                                                        <Form.Control
                                                            type="text"
                                                            required
                                                            pattern={GroupWizardBase.getGroupNamePattern(this.state.groupName, this.props.forbiddenGroupNames)}
                                                            value={this.state.groupName}
                                                            onChange={(e) => this.setState({ groupName: e.target.value, anyChangeMade: true })}
                                                            disabled={this.props.groupItem.System || isUserRolesReadOnly}
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            {this.state.groupName
                                                                ? this.state.groupName.length < GroupWizardBase.minGroupNameLength
                                                                    ? myStrings.groupNameTooShort
                                                                    : myStrings.selectUniqueName
                                                                : Strings.thisFieldIsMandatory}
                                                        </Form.Control.Feedback>
                                                    </Form.Group>
                                                </Form.Row>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="newGroupRespDescription">
                                                        <Form.Label>{myStrings.description}<DisabledByPermissionBadge disabledByPermissions={descriptionDisabledByPermissions} /></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            value={this.state.responsibilityDescription}
                                                            onChange={(e) => this.setState({ responsibilityDescription: e.target.value, anyChangeMade: true })}
                                                            disabled={this.props.groupItem.System || descriptionDisabledByPermissions?.isDisabled}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                                <h2 className="mt-5">{myStrings.note}<DisabledByPermissionBadge disabledByPermissions={noteDisabledByPermissions} /></h2>
                                                <Form.Row>
                                                    <Form.Group as={Col} controlId="newGroupNote">
                                                        <Form.Control
                                                            as="textarea"
                                                            rows={14}
                                                            placeholder={myStrings.writeNotes}
                                                            value={this.state.note}
                                                            onChange={(e) => this.setState({ note: e.target.value, anyChangeMade: true })}
                                                            disabled={this.props.groupItem.System || noteDisabledByPermissions?.isDisabled}
                                                        />
                                                    </Form.Group>
                                                </Form.Row>
                                            </Form>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="users">
                                            <GroupWizardBase.UsersPicker
                                                showHeading={false}
                                                showSearch
                                                usersSelection={this.state.usersSelection}
                                                onUsersSelectionChange={this.changeUsersSelection}
                                                usersSearchedString={this.state.usersSearchedString}
                                                onUsersSearchedStringChange={(str) => {
                                                    this.setState({ usersSearchedString: str });
                                                }}
                                                availableUsers={this.props.availableUsers}
                                                gridTableHeight={dialogGridHeight}
                                                currentUserGuid={this.context.connection.state.session!.userGuid}
                                                currentGroupGuid={this.props.groupItem.ItemGUID}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="mp">
                                            {!isModulePermissionsLocked ? (
                                                <GroupWizardBase.ModulePermissionsForm
                                                    groupItem={this.props.groupItem}
                                                    modulePermissions={this.state.modulePermissions}
                                                    onModulePermissionChange={this.onModulePermissionChange}
                                                    gridHeight={dialogGridHeight}
                                                />
                                            ) : (
                                                <FeatureLockedSplash className="pt-6" />
                                            )}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="fp">
                                            {!isFieldPermissionsLocked ? (
                                                <>
                                                    <FieldPermissionsDataTransformer
                                                        allGroups={this.props.allGroups}
                                                        fieldPermissions={this.state.fieldPermissions}
                                                        onCheckForDuplicateValues={(isCheckingForDuplicateValues: boolean, nonUniqueValue: string | null, callback?: () => void) => {
                                                            this.setState({ isCheckingForDuplicateValues: isCheckingForDuplicateValues, nonUniqueValue }, callback);
                                                        }}
                                                        onFieldPermissionsChange={(newFieldPermissions, newItemsToSave) => {
                                                            this.fieldPermissionsStorage.addItem(newItemsToSave);
                                                            this.setState({ fieldPermissions: newFieldPermissions, anyChangeMade: true });
                                                        }}
                                                        onUniqueNotFullyPermittedForGroupName={(uniqueNotFullyPermittedForGroupName: string | null) => {
                                                            this.setState({ uniqueNotFullyPermittedForGroupName });
                                                        }}
                                                        selectedFolderName={this.state.selectedFolderName}
                                                        selectedGroup={this.props.groupItem}
                                                        render={({ fieldPermissions, onFieldPermissionsChange, onMandatoryPermissionsChange }) => {
                                                            return (
                                                                <GroupWizardBase.FieldPermissionsForm
                                                                    groupItem={this.props.groupItem}
                                                                    allGroups={this.props.allGroups}
                                                                    fieldPermissions={fieldPermissions}
                                                                    folders={this.props.folders}
                                                                    selectedFolderName={this.state.selectedFolderName}
                                                                    onSelectedFolderNameChange={(newFolderName) => {
                                                                        this.setState({ selectedFolderName: newFolderName });
                                                                    }}
                                                                    onFieldPermissionChange={onFieldPermissionsChange}
                                                                    onMandatoryPermissionChange={onMandatoryPermissionsChange}
                                                                    gridHeight={dialogGridHeight}
                                                                    disableEditing={this.state.isCheckingForDuplicateValues || this.props.groupItem.DisallowControlColumnPermissions}
                                                                />
                                                            );
                                                        }}
                                                    />
                                                    {(this.state.nonUniqueValue !== null || this.state.uniqueNotFullyPermittedForGroupName !== null) && (
                                                        <FieldPermissionsWarningDialog
                                                            nonUniqueValue={this.state.nonUniqueValue}
                                                            uniqueNotFullyPermittedForGroupName={this.state.uniqueNotFullyPermittedForGroupName}
                                                            onHide={() => {
                                                                this.setState({ nonUniqueValue: null, uniqueNotFullyPermittedForGroupName: null });
                                                            }}
                                                        />
                                                    )}
                                                    {this.state.isCheckingForDuplicateValues && (
                                                        <Alert variant="warning" style={{ right: 10 }}>
                                                            <Spinner size="sm" animation="border" />
                                                            <span className="pl-2">{Strings.components.routes.fieldPermissions.checkingForDuplicatedValues}</span>
                                                        </Alert>
                                                    )}
                                                </>
                                            ) : (
                                                <FeatureLockedSplash className="pt-6" />
                                            )}
                                        </Tab.Pane>
                                        {(is810OrLater) &&
                                            <Tab.Pane eventKey="defaultFormLayouts">
                                                {!isFormLayoutsCustomizationLimited ? (
                                                    <DefaultFormLayoutsForm
                                                        gridHeight={dialogGridHeight}
                                                        availableLayouts={this.props.layouts}
                                                        mappings={this.state.layoutsMappings}
                                                        onMappingChange={this.handleDefaultFormsMappingChange}
                                                    />
                                                ) : (
                                                    <FeatureLockedSplash className="pt-6" />
                                                )}
                                            </Tab.Pane>
                                        }
                                        <Tab.Pane eventKey="additional">
                                            {!isUserRolesReadOnly ? (
                                                <GroupWizardBase.SpecialForm
                                                    showHeading={false}
                                                    itemGuid={this.props.groupItem.ItemGUID}
                                                    isSystem={this.props.groupItem.System}
                                                    pmGroupItem={this.props.pmGroupItem}
                                                    isPm={this.state.isPm}
                                                    isRole={this.state.isRole}
                                                    onRoleOrPmChange={this.changeIsRoleAndIsPm}
                                                />
                                            ) : (
                                                <FeatureLockedSplash className="pt-6" />
                                            )}
                                        </Tab.Pane>
                                    </Tab.Content>
                                </DelayedRender>
                            </div>
                        </Tab.Container>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={() => void this.submit()}>{Strings.save}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}

export default withRouter(EditGroupWizard);