import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableColumnResizing, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { Sorting, TableColumnWidthInfo, Column } from '@devexpress/dx-react-grid';
import { IntegratedFiltering, IntegratedSorting, SearchState, SortingState } from '@devexpress/dx-react-grid';
import * as React from 'react';
import Strings from '../../../strings';
import GridTable from '../../GridTable';
import StringHelper from '../../../helpers/StringHelper';
import { getTheme, mergeStyles } from '@fluentui/react';
import { ReactComponent as CircleFull } from '../../../img/permissions/circle-full.svg';
import { ReactComponent as CircleEmpty } from '../../../img/permissions/circle-empty.svg';
import type { IApiColumn, IApiColumnPermission, IApiGroup } from '@eway-crm/connector';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import GroupBadgeFlags from '../groups/GroupBadgeFlags';
import FieldPermissionsGridCell from './FieldPermissionsGridCell';
import LicenseRestrictionsLockedFieldsGridRow from '../../shared/locks/LicenseRestrictionsLockedFieldsGridRow';

const GRID_ROOT_ID = 'FolderFieldPermissionsGridRoot';
const GRID_CONTAINER_ID = 'FolderFieldPermissionsGridContainer';

const myStrings = Strings.components.routes.fieldPermissions;
const theme = getTheme();
const currentLanguage = Strings.getLanguage();

const PERMISSION_LEVEL_COL_WIDTH = currentLanguage === 'cs' ? 125 : 110;
const ORB_COL_WIDTH = 85;

export type TFieldPermissionWithCol = IApiColumnPermission & {
    col: IApiColumn;
    colName: string;
    IsMandatory?: boolean;
    IsImportant?: boolean;
    IsUnique?: boolean;
};

const gridColumnExtensions: Table.ColumnExtension[] = [
    { columnName: FieldPermissionNames.colName, align: 'left' },
    { columnName: FieldPermissionNames.PermissionRule, align: 'center' },
    { columnName: FieldPermissionNames.IsMandatory, align: 'center' },
    { columnName: FieldPermissionNames.IsImportant, align: 'center' },
    { columnName: FieldPermissionNames.IsUnique, align: 'center' },
];

const defaultSorting: Sorting[] = [{ columnName: FieldPermissionNames.colName, direction: 'asc' }];
const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [{ columnName: FieldPermissionNames.colName, compare: StringHelper.localeCompare }];

const sortingStateColumnExtensions: SortingState.ColumnExtension[] = [
    { columnName: FieldPermissionNames.PermissionRule, sortingEnabled: false },
    { columnName: FieldPermissionNames.IsMandatory, sortingEnabled: false },
    { columnName: FieldPermissionNames.IsImportant, sortingEnabled: false },
    { columnName: FieldPermissionNames.IsUnique, sortingEnabled: false },
];

const getWidthOfMainColumn = (tableWidth: number | undefined, minColWidth: number, numberOfOrbColumns: number) => {
    if (tableWidth) {
        const newColWidth = tableWidth - PERMISSION_LEVEL_COL_WIDTH - numberOfOrbColumns * ORB_COL_WIDTH - 40;
        if (newColWidth > minColWidth) {
            return newColWidth;
        }
        return minColWidth;
    }
    return minColWidth;
};

export const fieldPermissionsOptionsSettings = {
    All: {
        key: FieldPermissionNames.PermissionRuleKeys.All,
        text: () => myStrings.options.allPermissionsText,
        description: myStrings.options.allPermissionsDescription,
        color: theme.palette.green,
        icon: CircleFull,
    },
    Own: {
        key: FieldPermissionNames.PermissionRuleKeys.Own,
        text: () => myStrings.options.ownPermissionsText,
        description: myStrings.options.ownPermissionsDescription,
        color: '#ffc107',
        icon: CircleFull,
    },
    Readonly: {
        key: FieldPermissionNames.PermissionRuleKeys.Readonly,
        text: () => myStrings.options.readonlyPermissionsText,
        description: myStrings.options.readonlyPermissionsDescription,
        color: theme.palette.neutralSecondary,
        icon: CircleFull,
    },
    Invisible: {
        key: FieldPermissionNames.PermissionRuleKeys.Invisible,
        text: () => myStrings.options.invisiblePermissionsText,
        description: myStrings.options.invisiblePermissionsDescription,
        color: theme.palette.neutralSecondary,
        icon: CircleEmpty,
    },
    None: {
        key: FieldPermissionNames.PermissionRuleKeys.None,
        text: () => myStrings.options.nonePermissionsText,
        description: myStrings.options.nonePermissionsDescription,
        color: theme.palette.redDark,
        icon: CircleFull,
    },
};

type TRowWithEffectiveFieldPermissions = TFieldPermissionWithCol & { rowPermissions?: TFieldPermissionWithCol[] };
export type TFieldPermissionsCellWrapperProps = { data?: { row: TRowWithEffectiveFieldPermissions; column: Column } };
export type TFieldPermissionsCellWrapper = React.ComponentType<TFieldPermissionsCellWrapperProps>;

type TFieldPermissionsGridProps = {
    searchedString: string;
    fieldPermissions: TFieldPermissionWithCol[];
    onFieldPermissionChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    onMandatoryPermissionChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    systemGroupGuid?: string;
    selectedGroup?: IApiGroup;
    disableEditing?: boolean;
    gridHeight?: string;
    isShowingAdditionalField?: boolean;
    allGroups?: IApiGroup[];
    alwaysShowIsUnique?: boolean;
    cellWrapper?: TFieldPermissionsCellWrapper;
};

type TFieldPermissionsGridState = {
    columnWidths: TableColumnWidthInfo[];
};

const GridRootComponent = GridTable.createRootWithProps({ id: GRID_ROOT_ID });
const TableContainerComponent = GridTable.createTableContainerComponent({ id: GRID_CONTAINER_ID });

export default class FieldPermissionsGrid extends React.Component<TFieldPermissionsGridProps, TFieldPermissionsGridState> {
    private readonly tableContainerRef: React.RefObject<HTMLDivElement> | null = null;
    private readonly virtualTableRef: React.RefObject<typeof VirtualTable>;
    constructor(props: TFieldPermissionsGridProps) {
        super(props);
        this.state = {
            columnWidths: [],
        };
        this.tableContainerRef = React.createRef<HTMLDivElement>();
        this.virtualTableRef = React.createRef<typeof VirtualTable>();
    }

    private readonly gridColumns = [
        { name: FieldPermissionNames.colName, title: this.props.isShowingAdditionalField ? myStrings.columns.columnGroupNameTitle : myStrings.columns.columnTitle },
        { name: FieldPermissionNames.PermissionRule, title: myStrings.columns.permissionTitle },
        { name: FieldPermissionNames.IsMandatory, title: myStrings.columns.isMandatoryTitle },
        { name: FieldPermissionNames.IsImportant, title: myStrings.columns.isOptionalTitle },
    ];

    private readonly gridColumnsWithUnique = this.gridColumns.concat([{ name: FieldPermissionNames.IsUnique, title: myStrings.columns.isUniqueTitle }]);

    componentDidMount() {
        let additionalColumnCount = 2;
        if ((!this.props.isShowingAdditionalField && this.props.selectedGroup && this.props.systemGroupGuid === this.props.selectedGroup.ItemGUID) || this.props.alwaysShowIsUnique) {
            // Show isUnique only in System Group or when it should be always displayed
            additionalColumnCount = 3;
        }

        this.setState({
            columnWidths: [
                // First column takes up remaining space of table
                {
                    columnName: FieldPermissionNames.colName,
                    width: getWidthOfMainColumn(this.tableContainerRef?.current?.clientWidth, 150, additionalColumnCount),
                },
                { columnName: FieldPermissionNames.PermissionRule, width: PERMISSION_LEVEL_COL_WIDTH },
                { columnName: FieldPermissionNames.IsMandatory, width: ORB_COL_WIDTH },
                { columnName: FieldPermissionNames.IsImportant, width: ORB_COL_WIDTH },
                { columnName: FieldPermissionNames.IsUnique, width: ORB_COL_WIDTH },
            ],
        });
    }

    private readonly getGridRowId = (row: TFieldPermissionWithCol) => {
        return this.props.isShowingAdditionalField ? row.GroupGuid : row.ColumnName;
    };

    private readonly ViewTableCell: React.FC<Table.DataCellProps> = (props) => {
        const typedRow = props.row as TFieldPermissionWithCol;

        if (props.column.name === FieldPermissionNames.colName) {
            const typedValue = props.value as string;
            return (
                <VirtualTable.Cell className={mergeStyles('align-middle', this.props.disableEditing && 'text-secondary')} {...props} title={typedValue}>
                    {typedValue}
                    {typedRow.IsAdditionalField && !this.props.isShowingAdditionalField && <span className="badge badge-info ml-1">{myStrings.additionalField}</span>}
                    {this.props.isShowingAdditionalField && this.props.allGroups && <GroupBadgeFlags item={this.props.allGroups.find((group) => group.ItemGUID === typedRow.GroupGuid)!} />}
                </VirtualTable.Cell>
            );
        }

        return (
            <FieldPermissionsGridCell
                disableEditing={this.props.disableEditing}
                fieldPermissions={this.props.fieldPermissions}
                onFieldPermissionChange={this.props.onFieldPermissionChange}
                onMandatoryPermissionChange={this.props.onMandatoryPermissionChange}
                systemGroupGuid={this.props.systemGroupGuid}
                allGroups={this.props.allGroups}
                isShowingAdditionalField={this.props.isShowingAdditionalField}
                cellWrapper={this.props.cellWrapper}
                {...props}
            />
        );
    };

    render() {
        let columns = this.gridColumns;
        if (!this.props.isShowingAdditionalField && (!this.props.selectedGroup || this.props.systemGroupGuid === this.props.selectedGroup.ItemGUID)) {
            // Only show IsUnique in System Group
            columns = this.gridColumnsWithUnique;
        }
        return (
            <div className="h-100 permissions-table" ref={this.tableContainerRef}>
                <Grid rows={this.props.fieldPermissions} columns={columns} rootComponent={GridRootComponent} getRowId={this.getGridRowId}>
                    <SearchState value={this.props.searchedString} />
                    <SortingState defaultSorting={defaultSorting} columnExtensions={sortingStateColumnExtensions} />
                    <IntegratedFiltering />
                    <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                    <VirtualTable
                        containerComponent={TableContainerComponent}
                        messages={{ noData: this.props.searchedString ? myStrings.noFieldPermissionsMatch : myStrings.noFieldPermissions }}
                        noDataCellComponent={GridTable.NoDataCell}
                        estimatedRowHeight={43}
                        ref={this.virtualTableRef}
                        height={this.props.gridHeight}
                        cellComponent={this.ViewTableCell}
                        columnExtensions={gridColumnExtensions}
                        rowComponent={LicenseRestrictionsLockedFieldsGridRow}
                    />
                    <TableColumnResizing columnWidths={this.state.columnWidths} onColumnWidthsChange={(nextColumnWidths) => this.setState({ columnWidths: nextColumnWidths })} />
                    <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                </Grid>
            </div>
        );
    }
}
