import type { TFolderName } from '@eway-crm/connector';
import { CustomizationStatsItemKeys } from '@eway-crm/connector';
import { Link, MessageBar, MessageBarType } from '@fluentui/react';
import memoizeOne from 'memoize-one';
import type { ContextType} from 'react';
import React, { useContext, useState } from 'react';
import type { TLicenseRestriction } from '../../../helpers/LicenseRestrictionsHelper';
import type LicenseRestrictionsHelper from '../../../helpers/LicenseRestrictionsHelper';
import UnitHelper from '../../../helpers/UnitHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { RemoteItemStore } from '../../../RemoteItemStore';
import Strings from '../../../strings';

type TFieldsLicenseRestrictionError = {
    stripTitle: React.ReactNode;
    stripMessage: React.ReactNode;
    tooltipMessage: React.ReactNode;
    stats: ReturnType<LicenseRestrictionsHelper['isFunctionalityCountExceeded']>;
};

export const getFieldsLicenseRestrictionsErrors = memoizeOne((context: ContextType<typeof ConnectionContext>) => {
    const {
        licenseRestrictionsHelper,
        apiData: { objectTypes },
    } = context;
    const myStrings = Strings.components.licenseRestrictions.fields;
    const myFieldPermissionsStrings = Strings.components.routes.fieldPermissions.columns;
    const fieldsErrors: { [folderName: string]: TFieldsLicenseRestrictionError[] } = {};
    const allFieldFolders = RemoteItemStore.getDisplayableFieldFolders(objectTypes, licenseRestrictionsHelper).filter(
        (folderName) => !licenseRestrictionsHelper.isFolderNameLocked(folderName as TFolderName).isFolderNameLocked
    );

    const getVisibleFoldersStats = (customizationStatsItemKey: string) =>
        licenseRestrictionsHelper.customizationStats.filter((stat) => stat.FolderName && allFieldFolders.includes(stat.FolderName) && stat.Key === customizationStatsItemKey);

    const pushToObject = (folderName: string, value: TFieldsLicenseRestrictionError) => {
        if (fieldsErrors[folderName]) {
            fieldsErrors[folderName].push(value);
        } else {
            fieldsErrors[folderName] = [value];
        }
    };

    const visibleFoldersReadOnlyCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customReadonlyFieldsCount);
    visibleFoldersReadOnlyCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const { isReadOnlyFieldsLocked, readonlyFieldsLicenseRestriction } = licenseRestrictionsHelper.isReadOnlyFieldsLocked();
        const currentCount = licenseRestrictionsHelper.getCustomizationStatsItem(CustomizationStatsItemKeys.customReadonlyFieldsCount, stat.FolderName)?.Value ?? 0;
        if (isReadOnlyFieldsLocked && currentCount > 0) {
            const stripTitle = myStrings.readonlyFieldsCountExceededTitle;
            const stripMsg = Strings.formatString(myStrings.fieldsCountNotAvailable, myStrings.readonlyFields, currentCount.toString()) as string;
            const stripMessage = <StripContent licenseRestriction={readonlyFieldsLicenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.permissionsCountExceededMessage, myFieldPermissionsStrings.isReadonlyTitle)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: { isCountExceeded: true, isLimitReached: true, currentCount, exceededCount: currentCount, licenseRestriction: readonlyFieldsLicenseRestriction },
            });
        }
    });

    const visibleFoldersUniqueCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customUniqueFieldsCount);
    visibleFoldersUniqueCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const { isUniqueFieldsLocked, uniqueFieldsLicenseRestriction } = licenseRestrictionsHelper.isUniqueFieldsLocked();
        const currentCount = licenseRestrictionsHelper.getCustomizationStatsItem(CustomizationStatsItemKeys.customUniqueFieldsCount, stat.FolderName)?.Value ?? 0;
        if (isUniqueFieldsLocked && currentCount > 0) {
            const stripTitle = myStrings.uniqueFieldsCountExceededTitle;

            const stripMsg = Strings.formatString(myStrings.fieldsCountNotAvailable, myStrings.uniqueFields, currentCount.toString()) as string;
            const stripMessage = <StripContent licenseRestriction={uniqueFieldsLicenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.permissionsCountExceededMessage, myFieldPermissionsStrings.isUniqueTitle)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: { isCountExceeded: true, isLimitReached: true, currentCount, exceededCount: currentCount, licenseRestriction: uniqueFieldsLicenseRestriction },
            });
        }
    });

    const visibleFoldersMandatoryCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customMandatoryFieldsCount);
    visibleFoldersMandatoryCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const mandatoryFieldsCountStats = licenseRestrictionsHelper.isMandatoryFieldsCountExceeded(stat.FolderName);
        if (mandatoryFieldsCountStats.isLimitReached && mandatoryFieldsCountStats.isCountExceeded) {
            const stripTitle = myStrings.mandatoryFieldsCountExceededTitle;
            const currentLimit = mandatoryFieldsCountStats.licenseRestriction.currentLimit ?? 0;

            let stripMsg = Strings.formatString(myStrings.fieldsCountNotAvailable, myStrings.mandatoryFieldsPluralTwoToFour, mandatoryFieldsCountStats.currentCount.toString()) as string;
            if (currentLimit > 0) {
                const mandatoryFieldsString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                    one: myStrings.mandatoryFieldsSingular,
                    twoToFour: myStrings.mandatoryFieldsPluralTwoToFour,
                    fiveAndMore: myStrings.mandatoryFieldsPluralFiveAndMore,
                });

                stripMsg = Strings.formatString(myStrings.fieldsCountExceeded, currentLimit.toString(), mandatoryFieldsString, mandatoryFieldsCountStats.currentCount.toString()) as string;
            }

            const stripMessage = <StripContent licenseRestriction={mandatoryFieldsCountStats.licenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.permissionsCountExceededMessage, myFieldPermissionsStrings.isMandatoryTitle)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: mandatoryFieldsCountStats,
            });
        }
    });

    const visibleFoldersImportantCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customOptionalFieldsCount);
    visibleFoldersImportantCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const importantFieldsCountStats = licenseRestrictionsHelper.isImportantFieldsCountExceeded(stat.FolderName);
        if (importantFieldsCountStats.isLimitReached && importantFieldsCountStats.isCountExceeded) {
            const stripTitle = myStrings.importantFieldsCountExceededTitle;
            const currentLimit = importantFieldsCountStats.licenseRestriction.currentLimit ?? 0;

            let stripMsg = Strings.formatString(myStrings.fieldsCountNotAvailable, myStrings.importantFieldsPluralTwoToFour, importantFieldsCountStats.currentCount.toString()) as string;
            if (currentLimit > 0) {
                const importantFieldsString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                    one: myStrings.importantFieldsSingular,
                    twoToFour: myStrings.importantFieldsPluralTwoToFour,
                    fiveAndMore: myStrings.importantFieldsPluralFiveAndMore,
                });

                stripMsg = Strings.formatString(myStrings.fieldsCountExceeded, currentLimit.toString(), importantFieldsString, importantFieldsCountStats.currentCount.toString()) as string;
            }

            const stripMessage = <StripContent licenseRestriction={importantFieldsCountStats.licenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.permissionsCountExceededMessage, myFieldPermissionsStrings.isOptionalTitle)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: importantFieldsCountStats,
            });
        }
    });

    const customFieldsStats = licenseRestrictionsHelper.isCustomFieldsCountExceeded();
    if (customFieldsStats.isLimitReached && customFieldsStats.isCountExceeded) {
        const visibleFoldersAfCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customAdditionalFieldsCount);
        visibleFoldersAfCountStats.forEach((stat) => {
            if (!stat.FolderName) {
                return;
            }

            const stripTitle = myStrings.customFieldsCountExceededTitle;
            const stripMsg = Strings.formatString(
                myStrings.fieldsCountExceeded,
                (customFieldsStats.licenseRestriction.currentLimit ?? 0).toString(),
                myStrings.customFields,
                customFieldsStats.currentCount.toString()
            ) as string;
            const stripMessage = <StripContent licenseRestriction={customFieldsStats.licenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {myStrings.customFieldsCountExceededMessage}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: customFieldsStats,
            });
        });
    }

    const visibleFoldersCustomTypesCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.customVisibleTypesCount);
    visibleFoldersCustomTypesCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const itemTypesStats = licenseRestrictionsHelper.isItemTypesCountExceeded(stat.FolderName);
        if (itemTypesStats.isLimitReached && itemTypesStats.isCountExceeded) {
            const stripTitle = myStrings.itemTypesCountExceededTitle;
            const currentLimit = itemTypesStats.licenseRestriction.currentLimit ?? 0;
            const itemTypesString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                one: myStrings.itemTypesSingular,
                twoToFour: myStrings.itemTypesPluralTwoToFour,
                fiveAndMore: myStrings.itemTypesFiveAndMore,
            });

            const stripMsg = Strings.formatString(myStrings.fieldsCountExceeded, currentLimit.toString(), itemTypesString, itemTypesStats.currentCount.toString()) as string;

            const stripMessage = <StripContent licenseRestriction={itemTypesStats.licenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.typesCountExceededMessage, myStrings.itemTypeFieldName)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: itemTypesStats,
            });
        }
    });

    const visibleFoldersCurrencyCountStats = getVisibleFoldersStats(CustomizationStatsItemKeys.visibleCurrenciesCount);
    visibleFoldersCurrencyCountStats.forEach((stat) => {
        if (!stat.FolderName) {
            return;
        }

        const currencyCountStats = licenseRestrictionsHelper.isCurrencyCountExceeded(stat.FolderName);

        if (currencyCountStats.isLimitReached && currencyCountStats.isCountExceeded) {
            const stripTitle = myStrings.currecyFieldsCountExceededTitle;
            const currentLimit = currencyCountStats.licenseRestriction.currentLimit ?? 0;
            const currencyCountString = UnitHelper.getSingularOrPluralByQuantity(currentLimit, {
                one: myStrings.currencyFieldsSingular,
                twoToFour: myStrings.currencyFieldsPluralTwoToFour,
                fiveAndMore: myStrings.currencyFieldsPluralFiveAndMore,
            });
            const stripMsg = Strings.formatString(myStrings.fieldsCountExceeded, currentLimit.toString(), currencyCountString, currencyCountStats.currentCount.toString()) as string;
            const stripMessage = <StripContent licenseRestriction={currencyCountStats.licenseRestriction} title={stripTitle} message={stripMsg} />;
            const tooltipMessage = (
                <span>
                    {stripMsg} {Strings.formatString(myStrings.typesCountExceededMessage, myStrings.currencyFieldName)}
                </span>
            );

            pushToObject(stat.FolderName, {
                stripTitle,
                stripMessage,
                tooltipMessage,
                stats: currencyCountStats,
            });
        }
    });

    return fieldsErrors;
});

const MESSAGEBAR_STYLES = {
    innerText: { width: '100%' },
};

type TFieldsLicenseRestrictionsErrorsProps = {
    folderName: string | null;
};

const FieldsLicenseRestrictionsErrors: React.FC<TFieldsLicenseRestrictionsErrorsProps> = ({ folderName }) => {
    const context = useContext(ConnectionContext);
    const [fieldsErrors, setFieldsErrors] = useState<TFieldsLicenseRestrictionError[] | null>(() => {
        if (!folderName) {
            return null;
        }

        return getFieldsLicenseRestrictionsErrors(context)[folderName];
    });

    if (!fieldsErrors) {
        return null;
    }

    return (
        <>
            {fieldsErrors.map((fieldError, idx) => (
                <MessageBar
                    isMultiline={false}
                    messageBarType={MessageBarType.severeWarning}
                    key={idx}
                    onDismiss={() => {
                        setFieldsErrors((prev) => prev?.filter((fe, i) => i !== idx) ?? null);
                    }}
                    styles={MESSAGEBAR_STYLES}
                >
                    {fieldError.stripMessage}
                </MessageBar>
            ))}
        </>
    );
};

export default FieldsLicenseRestrictionsErrors;

type TStripContentProps = {
    title: string;
    message: string;
    licenseRestriction: TLicenseRestriction;
};

const StripContent: React.FC<TStripContentProps> = ({ title, message, licenseRestriction }) => {
    const { showLicenseRestrictionModal } = useContext(ConnectionContext);
    const myStrings = Strings.components.licenseRestrictions;

    return (
        <div className="d-flex justify-content-between">
            <div className="text-truncate" title={title}>
                <span className={'font-weight-bold'}>{title}</span>: {message}
            </div>
            <div>
                <Link
                    as={'a'}
                    onClick={() => {
                        showLicenseRestrictionModal(licenseRestriction);
                    }}
                >
                    {myStrings.upgradePlan}
                </Link>
            </div>
        </div>
    );
};
