import React from 'react';
import { Col, Form, InputGroup } from 'react-bootstrap';
import type { IApiPasswordStrength } from '@eway-crm/connector';
import { PasswordHelper } from '../../../helpers/PasswordHelper';
import Strings from '../../../strings';
import PasswordRank from '../../routes/users/PasswordRank';

type TPasswordInputProps = {
    disabled: boolean;
    invalid: boolean;
    value: string;
    usernames: string[];
    minPasswordLength: number;
    onChange: (newValue: string) => void;
    passwordStrengths: IApiPasswordStrength[] | null;
};

const myStrings = Strings.components.routes.users;

class PasswordInput extends React.Component<TPasswordInputProps> {

    private readonly getPasswordFeedback = () => {
        if (!this.props.value) {
            return Strings.thisFieldIsMandatory;
        } else if (!this.props.usernames || this.props.usernames.filter(username => !!username).length === 0 ) {
            return myStrings.passwordEnterUsernameFirst;
        } else if (this.props.value.length < this.props.minPasswordLength) {
            return myStrings.passwordTooShort;
        } else if (!PasswordHelper.areStrongEnough(this.props.passwordStrengths)) {
            return myStrings.passwordTooWeak;
        } else {
            return null;
        }
    };

    render() {
        return (
            <Form.Group as={Col} controlId="newUserPassword" className={'ml-4' + (this.props.disabled ? ' disabled' : '')}>
                <Form.Label>{myStrings.password}</Form.Label>
                <InputGroup>
                    <Form.Control
                        type="password"
                        disabled={this.props.disabled}
                        required={!this.props.disabled}
                        isInvalid={this.props.invalid}
                        value={this.props.disabled ? '' : this.props.value}
                        onChange={(e) => {
                            this.props.onChange(e.target.value);
                        }}
                    />
                    <InputGroup.Append hidden={this.props.disabled || !this.props.value}>
                        <PasswordRank passwordStrengths={this.props.passwordStrengths} />
                    </InputGroup.Append>
                    <Form.Control.Feedback type="invalid">{this.getPasswordFeedback()}</Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
        );
    }
}

export default PasswordInput;
