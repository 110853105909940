import * as React from 'react';

class ReactHelper {
    static Children = {
        takeOne: (children: React.ReactNode, predicate: (child?: React.ReactNode) => boolean): React.ReactNode => {
            let taken = false;
            return React.Children.map(children, (child) => {
                if (predicate(child) && !taken) {
                    taken = true;
                    return child;
                } else {
                    return;
                }
            });
        },

        takeByType: <TProps> (children: React.ReactNode, type: React.JSXElementConstructor<TProps>): React.ReactNode => {
            return ReactHelper.Children.takeOne(children, child => !!child && (child as React.ReactElement).type === type);
        }
    };

    static async setState<TProps, TState, K extends keyof TState>(component: React.Component<TProps, TState>, newState: ((prevState: Readonly<TState>, props: Readonly<TProps>) => (Pick<TState, K> | TState | null)) | (Pick<TState, K> | TState | null)) {
        return new Promise<void>((resolve) => component.setState(newState, resolve));
    }
}

export default ReactHelper;