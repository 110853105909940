import * as React from 'react';
import Strings from '../../strings';
import { RemoteItemStore } from '../../RemoteItemStore';
import type { ShoppingConnection } from '../ShoppingConnection';
import type { ISubscriptionsShoppingModel } from '../../data/shopping/ISubscriptionsShoppingModel';
import type { IApiDataResponse, IApiDatumResponse, IApiItemIdentifier } from "@eway-crm/connector";
import StringHelper from '../../helpers/StringHelper';
import type { TCountriesAndRegions} from './subscriptions/TCountriesAndRegions';
import { loadCountriesAndRegions } from './subscriptions/TCountriesAndRegions';
import type { IShoppingDatumResponse } from '../../data/shopping/IShoppingDatumResponse';
import ReactHelper from '../../helpers/ReactHelper';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import SubscriptionIssuesHandler from './subscriptions/SubscriptionIssuesHandler/SubscriptionIssuesHandler';
import StoreV2 from './subscriptions/StoreV2/StoreV2';
import StoreHelper from './subscriptions/StoreHelper';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TSubscriptionsProps = {
    shoppingConnection: ShoppingConnection;
    isAfterBuy: false | { isAnythingNew: boolean };
    addToCartArtno: string | null;
};

type TSubscriptionsState = {
    countries: TCountriesAndRegions | null;
    data: ISubscriptionsShoppingModel | null;
    currentDbSize: number | null;
    activeUsersCount: number | null;
};

export class Subscriptions extends React.Component<TSubscriptionsProps, TSubscriptionsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    static readonly priceDecPlaces: number = 0;
    static readonly priceDecPlacesWithVat: number = 2;

    constructor(props: TSubscriptionsProps) {
        super(props);

        this.state = {
            countries: null,
            data: null,
            currentDbSize: null,
            activeUsersCount: null
        };
    }

    componentDidMount() {
        this.loadData()
            .catch((err) => console.error('Unable to load subscriptions data.', err));
    }

    private readonly loadData = async () => {
        const remoteStore = new RemoteItemStore(this.context.connection);
        const countries = await loadCountriesAndRegions(remoteStore);
        const subscriptionsData = await this.loadSubscriptionsData();
        const currentDbSize = await this.loadCurentDbSize();
        const activeUsersCount = await this.loadActiveUsersCount();

        this.setState({
            countries: countries,
            data: subscriptionsData,
            currentDbSize: currentDbSize,
            activeUsersCount: activeUsersCount
        });
    };

    private readonly reloadData = async () => {
        await ReactHelper.setState(
            this,
            {
                countries: null,
                data: null,
                currentDbSize: null,
                activeUsersCount: null
            }
        );
        await this.loadData();
    };

    private readonly loadSubscriptionsData = async (): Promise<ISubscriptionsShoppingModel> => {
        const isAdminShopping: boolean = StringHelper.compareIgnoringCase(this.context.connection.state.userItem?.Username, 'admin') === 0;
        const result = await this.props.shoppingConnection.askShoppingApi<IShoppingDatumResponse<ISubscriptionsShoppingModel>>('GetSubscriptionsShoppingModel', { isAdminShopping: isAdminShopping });

        // Clear the data for usage in forms.
        if (!result.Datum.CustomerInfo.EmailAddress) {
            result.Datum.CustomerInfo.EmailAddress = '';
        }
        if (!result.Datum.CustomerInfo.VatNumber) {
            result.Datum.CustomerInfo.VatNumber = '';
        }
        if (!result.Datum.CustomerInfo.BusinessName) {
            result.Datum.CustomerInfo.BusinessName = '';
        }
        if (!result.Datum.CustomerInfo.FirstName) {
            result.Datum.CustomerInfo.FirstName = '';
        }
        if (!result.Datum.CustomerInfo.LastName) {
            result.Datum.CustomerInfo.LastName = '';
        }
        if (!result.Datum.CustomerInfo.Phone) {
            result.Datum.CustomerInfo.Phone = '';
        }
        if (!result.Datum.CustomerInfo.AddressLine1) {
            result.Datum.CustomerInfo.AddressLine1 = '';
        }
        if (!result.Datum.CustomerInfo.City) {
            result.Datum.CustomerInfo.City = '';
        }
        if (!result.Datum.CustomerInfo.State) {
            result.Datum.CustomerInfo.State = '';
        }
        if (!result.Datum.CustomerInfo.PostalCode) {
            result.Datum.CustomerInfo.PostalCode = '';
        }
        if (!result.Datum.CustomerInfo.AdditionalInformation) {
            result.Datum.CustomerInfo.AdditionalInformation = '';
        }

        // Default values.
        if (!result.Datum.CustomerInfo.Country) {
            result.Datum.CustomerInfo.Country = 'US';
        }
        if (!result.Datum.CustomerInfo.Currency) {
            result.Datum.CustomerInfo.Currency = 'USD';
        }

        return result.Datum;
    };

    private readonly loadCurentDbSize = async (): Promise<number> => {
        if (!this.context.connection)
            throw new Error("Api connection not initialized.");

        const result = await this.context.connection.askApi<IApiDatumResponse<number>>('GetCurrentDbSize', {});
        return result.Datum;
    };

    private readonly loadActiveUsersCount = async (): Promise<number> => {
        if (!this.context.connection)
            throw new Error("Api connection not initialized.");

        const result = await this.context.connection.askApi<IApiDataResponse<IApiItemIdentifier>>('SearchUsersIdentifiers', { transmitObject: { IsActive: true, IsApiUser: false, IsSystem: false } });
        return result.Data.length;
    };

    private readonly areAllDataLoaded = () => {
        if (this.state.countries && this.state.data && this.state.currentDbSize && typeof this.state.activeUsersCount === 'number') {
            return true;
        } else {
            return false;
        }
    };

    render() {
        const myStrings = Strings.components.routes.subscriptions;

        let storeComponent = null;
        let currentPlanComponent = null;
        if (this.state.data && this.state.countries && this.state.currentDbSize && typeof this.state.activeUsersCount === 'number') {
            if (this.state.data.Store) {
                const isReallyAfterBuy: false | { isAnythingNew: boolean } = !this.state.data?.CurrentPlan?.StripeSubscriptionIssue?.SubscriptionRequiresActionPaymentIntent
                    && !this.state.data?.CurrentPlan?.StripeSubscriptionIssue?.SubscriptionRequiresPaymentMethodPaymentIntent
                    && this.props.isAfterBuy;

                storeComponent = (
                    <StoreV2
                        shoppingConnection={this.props.shoppingConnection}
                        paymentGateProvider={this.state.data.PaymentGateProvider}
                        storeData={this.state.data.Store}
                        customerInfo={this.state.data.CustomerInfo}
                        currentDbSizeGigabytes={this.state.currentDbSize / 1024.0}
                        activeUsersCount={this.state.activeUsersCount}
                        currentPlan={this.state.data.CurrentPlan}
                        countries={this.state.countries}
                        onStoreDataChanged={this.reloadData}
                        isAfterBuy={isReallyAfterBuy}
                        isCloseAccountAllowed={this.state.data.IsCloseAccountAvailable}
                        collectPersonName={this.state.data.CollectPersonName}
                    />
                );
            }

            if (this.state.data.CurrentPlan) {
                const payingVat: boolean = (this.state.data.CurrentPlan.TotalPriceWithoutVat.Price !== this.state.data.CurrentPlan.TotalPriceWithVat.Price);
                currentPlanComponent = (
                    <>
                        <div className="text-success text-right font-weight-600">
                            <div style={{ fontSize: '130%' }}>
                                <>{Strings.formatCurrency(this.state.data.CurrentPlan.TotalPriceWithoutVat.Price, Subscriptions.priceDecPlaces, this.state.data.CurrentPlan.TotalPriceWithoutVat.Currency)}</>
                                {(this.state.data.CurrentPlan.PeriodType) &&
                                    <>
                                        <> / </>
                                        <>{StoreHelper.getPeriodStrings(this.state.data.CurrentPlan.PeriodType).unit}</>
                                    </>
                                }
                                {payingVat &&
                                    <span style={{ fontSize: '62%' }}> {myStrings.withoutVat}</span>
                                }
                            </div>

                            <div>{myStrings.autoRenewalOn} {Strings.formatDate(this.state.data.CurrentPlan.NextBillingDate)}</div>
                        </div>
                        <SubscriptionIssuesHandler shoppingConnection={this.props.shoppingConnection} currentPlan={this.state.data.CurrentPlan} onDone={this.reloadData} />
                    </>
                );
            }
        }

        const headerComponent = (
            <>
                <div className="d-flex justify-content-between">
                    <div>
                        <h1>{myStrings.title}</h1>
                        <p className="mb-0 text-medium">{myStrings.subtitle}</p>
                    </div>
                    <div className="align-self-center">
                        {currentPlanComponent}
                    </div>
                </div>
                <hr className="mb-3 mt-4" />
            </>
        );

        const dataLoaded = this.areAllDataLoaded();

        if (dataLoaded) {
            return (
                <div className="container-fluid d-flex flex-column min-h-100">
                    <div className="row">
                        <div className="col p-0">
                            {headerComponent}
                        </div>
                    </div>
                    <div className="row flex-fill d-flex justify-content-start">
                        <div className="col p-0">
                            {storeComponent}
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="container-fluid d-flex flex-column min-h-100">
                    <div className="row">
                        <div className="col p-0">
                            {headerComponent}
                        </div>
                    </div>
                    <Spinner backgroundOpacity={0} variant={SpinnerVariant.ease} />
                </div>
            );
        }
    }
}