import type { IChoiceGroupOption} from '@fluentui/react';
import { ChoiceGroup, mergeStyles, mergeStyleSets } from '@fluentui/react';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { CreatePaymentMethodCardData, Stripe, StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import { Alert, Button } from 'react-bootstrap';
import type { IPaymentMethodsModel, IStripePaymentMethod } from '../../../data/shopping/IPaymentMethodsModel';
import type { IShoppingDatumResponse } from '../../../data/shopping/IShoppingDatumResponse';
import Strings from '../../../strings';
import { StripePaymentMethodBrand } from '../../routes/billingDetails/MethodsGridTable';
import type { ShoppingConnection } from '../../ShoppingConnection';
import type { TStripeInitData} from './StripeElementsContainer';
import { StripeClientSecretContext, StripeElementsContainer, useStripeWithClientSecret } from './StripeElementsContainer';
import { isConnectionSecureForStripe, StripeElementsForm } from './StripeElementsForm';

export type TStripeIntentResult = { error?: StripeError };

export type TStripeDropinSurroundingComponentProps = React.PropsWithChildren & {
    isSubmitDisabled: boolean;
    isProcessing: boolean;
    onSubmit: () => void;
};

type TStripeDropinProps = {
    shoppingConnection: ShoppingConnection;
    loadingComponent: JSX.Element;
    existingCardInitDataGetter: (paymentMethodId: string) => TStripeInitData;
    onExistingCardSubmit: (stripe: Stripe, clientSecret: string, paymentMethodId: string) => Promise<TStripeIntentResult>;
    newCardInitData: TStripeInitData;
    onNewCardSubmit: (stripe: Stripe, clientSecret: string, paymentMethod: Omit<CreatePaymentMethodCardData, 'type'>) => Promise<TStripeIntentResult>;
    as: React.ComponentType<TStripeDropinSurroundingComponentProps>;
};

export const StripeDropin: React.FC<TStripeDropinProps> = (props) => {
    const [methods, setMethods] = React.useState<IStripePaymentMethod[] | null>(null);
    const [isEnteringAnotherCard, setIsEnteringAnotherCard] = React.useState<boolean>(false);
    const { shoppingConnection } = props;

    React.useEffect(() => {
        if (methods) {
            if (methods.length === 0 && !isEnteringAnotherCard) {
                setIsEnteringAnotherCard(true);
            }

            // Do not continue to load the methods.
            return;
        }

        (async () => {
            const methodsResult = await shoppingConnection.askShoppingApi<IShoppingDatumResponse<IPaymentMethodsModel>>('GetPaymentMethods', {});
            setMethods(methodsResult.Datum.StripeMethods?.filter(pm => !pm.IsExpired) || []);
        })()
            .catch((err) => console.error('Unable to get payment methods.', err));
    }, [methods, setMethods, isEnteringAnotherCard, setIsEnteringAnotherCard, shoppingConnection]);

    const clickAnotherCard = React.useCallback(() => setIsEnteringAnotherCard(true), [setIsEnteringAnotherCard]);

    const SurroundingComponent = props.as || React.Fragment;

    if (!methods)
        return props.loadingComponent;

    if (isEnteringAnotherCard) {
        return (
            <AnotherCardForm
                shoppingConnection={props.shoppingConnection}
                loadingComponent={props.loadingComponent}
                newCardInitData={props.newCardInitData}
                onNewCardSubmit={props.onNewCardSubmit}
                as={SurroundingComponent}
            />
        );
    }

    return (
        <ExistingMethodForm
            shoppingConnection={props.shoppingConnection}
            methods={methods}
            onAnotherCardChosen={clickAnotherCard}
            existingCardInitDataGetter={props.existingCardInitDataGetter}
            onExistingCardSubmit={props.onExistingCardSubmit}
            as={SurroundingComponent}
        />
    );
};

type TSurroundingProps = TStripeDropinSurroundingComponentProps & {
    error: StripeError | null;
    as: React.ComponentType<TStripeDropinSurroundingComponentProps>;
};

const Surrounding: React.FC<TSurroundingProps> = (props) => {
    const Component = props.as;
    return (
        <Component isProcessing={props.isProcessing} isSubmitDisabled={props.isSubmitDisabled} onSubmit={props.onSubmit}>
            {(!!props.error) &&
                <Alert variant="danger">
                    {props.error.message || Strings.components.routes.billingDetails.wrongMethod}
                </Alert>
            }
            {props.children}
        </Component>
    );
};

type TExistingMethodFormProps = {
    shoppingConnection: ShoppingConnection;
    methods: IStripePaymentMethod[];
    onAnotherCardChosen: () => void;
    existingCardInitDataGetter: (paymentMethodId: string) => TStripeInitData;
    onExistingCardSubmit: (stripe: Stripe, clientSecret: string, paymentMethodId: string) => Promise<TStripeIntentResult>;
    as: React.ComponentType<TStripeDropinSurroundingComponentProps>;
};

const ExistingMethodForm: React.FC<TExistingMethodFormProps> = (props) => {
    const [chosenId, setChosenId] = React.useState<string>((props.methods.filter(pm => pm.IsActive)[0] || props.methods[0])?.Id || '');
    const [stripeError, setStripeError] = React.useState<StripeError | null>(null);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);
    const { shoppingConnection, existingCardInitDataGetter, onExistingCardSubmit } = props;

    const stripeState = useStripeWithClientSecret(
        (isProcessing && chosenId) ?
            {
                shoppingConnection: shoppingConnection,
                initData: existingCardInitDataGetter(chosenId)
            }
            :
            null
    );

    const submit = React.useCallback(() => {
        if (isProcessing || !chosenId)
            return;

        setIsProcessing(true);
    }, [isProcessing, chosenId, setIsProcessing]);

    React.useEffect(() => {
        if (!isProcessing || !chosenId || !stripeState)
            return;

        const { stripe, clientSecret } = stripeState;
        (async () => {
            if (!chosenId) {
                console.warn('No method chosen suddenly.');
                return;
            }

            const result = await onExistingCardSubmit(stripe, clientSecret, chosenId);

            if (result.error) {
                setStripeError(result.error);
                setIsProcessing(false);
            }
        })()
            .catch((err) => console.error('Unableto submit existing card.', err));
    }, [isProcessing, chosenId, stripeState, shoppingConnection, onExistingCardSubmit]);

    return (
        <Surrounding
            isSubmitDisabled={!chosenId}
            onSubmit={submit}
            isProcessing={isProcessing}
            error={stripeError}
            as={props.as}
        >
            <StripePaymentsMethodPicker methods={props.methods} chosenId={chosenId} onMethodChange={setChosenId} disabled={isProcessing} onAnotherCardChosen={props.onAnotherCardChosen} />
        </Surrounding>
    );
};

type TCardPickerProps = {
    methods: IStripePaymentMethod[];
    chosenId: string;
    onMethodChange: (id: string) => void;
    onAnotherCardChosen: () => void;
    disabled: boolean;
};

const StripePaymentsMethodPicker: React.FC<TCardPickerProps> = (props) => {
    const { onMethodChange } = props;
    const myStrings = Strings.components.routes.purchaseHistory;
    return (
        <>
            <ChoiceGroup
                className="ms-ChoiceFieldGroup-w-100"
                selectedKey={props.chosenId}
                onChange={React.useCallback((_?: React.FormEvent, option?: IChoiceGroupOption) => option && onMethodChange(option.key), [onMethodChange])}
                options={props.methods.map(pm => ({
                    key: pm.Id,
                    text: pm.Title || '',
                    onRenderLabel: () => (<RadioLabel pm={pm} />),
                    styles: { input: { position: "relative" } }
                }))}
                disabled={props.disabled}
            />
            <div className="text-center">
                <Button variant="outline-success" onClick={props.onAnotherCardChosen} disabled={props.disabled}>
                    <i className="mdl2 mdl2-add" style={{ paddingTop: '' }} aria-hidden="true" />&nbsp;&nbsp;{myStrings.enterAnotherCard}
                </Button>
            </div>
        </>
    );
};

const cssRadioLabel = mergeStyleSets({
    container: { paddingLeft: '1.625rem', marginTop: '-1.30rem' },
    brandCell: { width: '10rem' },
    cardTitleCell: { minWidth: '14rem', fontSize: '16px !important', paddingRight: '2rem !important', paddingLeft: '2rem !important' },
    expCell: { fontSize: '16px !important' }
});

const RadioLabel: React.FC<{ pm: IStripePaymentMethod }> = ({ pm }) => (
    <div className={mergeStyles(cssRadioLabel.container, 'grid-table', 'grid-table-no-hover')}>
        <table className="table">
            <tbody>
                <tr>
                    <td className={mergeStyles('text-center', 'align-middle', cssRadioLabel.brandCell)}><StripePaymentMethodBrand paymentMethod={pm} variant="larger" /></td>
                    <td className={mergeStyles('align-middle', 'highlighted', cssRadioLabel.cardTitleCell)}>{pm.Title}</td>
                    <td className={mergeStyles('align-middle', 'text-right', cssRadioLabel.expCell)}>{pm.Expiration}</td>
                </tr>
            </tbody>
        </table>
    </div>
);

type TAnotherCardPayerModalProps = {
    shoppingConnection: ShoppingConnection;
    loadingComponent: JSX.Element;
    newCardInitData: TStripeInitData;
    onNewCardSubmit: (stripe: Stripe, clientSecret: string, paymentMethod: Omit<CreatePaymentMethodCardData, 'type'>) => Promise<TStripeIntentResult>;
    as: React.ComponentType<TStripeDropinSurroundingComponentProps>;
};

const AnotherCardForm: React.FC<TAnotherCardPayerModalProps> = (props) => {
    return (
        <StripeElementsContainer
            initData={props.newCardInitData}
            shoppingConnection={props.shoppingConnection}
            loadingComponent={props.loadingComponent}
        >
            <AnotherCardLoadedForm
                onNewCardSubmit={props.onNewCardSubmit}
                as={props.as}
            />
        </StripeElementsContainer>
    );
};

type TAnotherCardLoadedFormProps = {
    onNewCardSubmit: (stripe: Stripe, clientSecret: string, paymentMethod: Omit<CreatePaymentMethodCardData, 'type'>) => Promise<TStripeIntentResult>;
    as: React.ComponentType<TStripeDropinSurroundingComponentProps>;
};

const AnotherCardLoadedForm: React.FC<TAnotherCardLoadedFormProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const clientSecret = React.useContext(StripeClientSecretContext);

    const [stripeError, setStripeError] = React.useState<StripeError | null>(null);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    const handleSubmit = React.useCallback(() => {
        if (isProcessing)
            return;

        setIsProcessing(true);
    }, [isProcessing, setIsProcessing]);

    const { onNewCardSubmit } = props;
    React.useEffect(() => {
        if (!isProcessing)
            return;

        (async () => {
            if (!stripe || !elements) {
                console.warn('Stripe.js has not loaded yet.');
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);

            if (!cardElement) {
                console.error('Unable to find Stripe card element.');
                return;
            }

            const paymentMethod: Omit<CreatePaymentMethodCardData, 'type'> = {
                card: cardElement
            };

            const result = await onNewCardSubmit(stripe, clientSecret, paymentMethod);

            if (result.error) {
                setStripeError(result.error);
                setIsProcessing(false);
            }
        })()
            .catch((err) => console.error('Unable to submit a new card.', err));
    }, [stripe, elements, setStripeError, isProcessing, clientSecret, onNewCardSubmit]);

    return (
        <Surrounding
            isSubmitDisabled={!stripe || !elements || !isConnectionSecureForStripe()}
            onSubmit={handleSubmit}
            isProcessing={isProcessing}
            error={stripeError}
            as={props.as}
        >
            <StripeElementsForm />
        </Surrounding>
    );
};