import { DefaultButton, mergeStyles, Text } from '@fluentui/react';
import React from 'react';
import { Link } from 'react-router-dom';
import RouteConfig from '../../../../../RouteConfig';
import Strings from '../../../../../strings';

type TGoToAdminCenterProps = {
    imgSrc: string;
    imgClassName?: string;
    message: string;
};

const GoToAdminCenter: React.FC<TGoToAdminCenterProps> = ({ imgSrc, imgClassName, message }) => {
    const myStrings = Strings.components.routes.subscriptions;

    return (
        <div className="d-flex flex-column align-items-center mt-5 p-3 h-100 justify-content-center">
            <img src={imgSrc} className={mergeStyles("checkoutV2__go-to-admin-center-img", imgClassName)} alt="Something went wrong" />
            <Text variant="xLarge" className="d-flex w-100 justify-content-center mt-3 text-center px-3">
                {myStrings.subscriptionPaymentIssueGoToAdminCenterTitle}
            </Text>
            <Text className="d-flex w-100 justify-content-center mt-2 text-center px-3">{message}</Text>
            <Link to={RouteConfig.billing.subscriptions.path} target="_blank" rel="noopener noreferrer" className="my-3">
                <DefaultButton>{myStrings.goToAdminCenter}</DefaultButton>
            </Link>
        </div>
    );
};

export default GoToAdminCenter;
