import FieldTypes from "./FieldTypes";

export class AdditionalFieldTypes {
    static readonly textBox = 0;
    static readonly comboBox = 1;
    static readonly numericBox = 2;
    static readonly relation = 3;
    static readonly checkBox = 4;
    static readonly linkTextBox = 5;
    static readonly dateEdit = 6;
    static readonly memoBox = 7;
    static readonly multiSelectComboBox = 8;

    static readonly all = {
        [AdditionalFieldTypes.textBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.textBox)
        },
        [AdditionalFieldTypes.memoBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.memoBox)

        },
        [AdditionalFieldTypes.numericBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.numericBox)
        },
        [AdditionalFieldTypes.dateEdit]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.dateEdit)
        },
        [AdditionalFieldTypes.checkBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.checkBox)
        },
        [AdditionalFieldTypes.comboBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.comboBox)
        },
        [AdditionalFieldTypes.multiSelectComboBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.multiSelectComboBox)
        },
        [AdditionalFieldTypes.relation]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.relation)
        },
        [AdditionalFieldTypes.linkTextBox]: {
            localizedName: FieldTypes.getLocalizedName(FieldTypes.linkTextBox)
        }, 
    };

    static readonly order = [
        AdditionalFieldTypes.textBox,
        AdditionalFieldTypes.memoBox,
        AdditionalFieldTypes.numericBox,
        AdditionalFieldTypes.dateEdit,
        AdditionalFieldTypes.checkBox,
        AdditionalFieldTypes.comboBox,
        AdditionalFieldTypes.multiSelectComboBox,
        AdditionalFieldTypes.relation,
        AdditionalFieldTypes.linkTextBox
    ];
}