import * as React from 'react';
import type { IApiEnumValue, ITranslatableString } from '@eway-crm/connector';
import type { HTMLReactParserOptions } from 'html-react-parser';
import type { IEulaAlternative, TPeriodType } from '../../../../data/shopping/ISubscriptionsShoppingModel';
import StringHelper from '../../../../helpers/StringHelper';
import type { TCustomerInfo } from '../TCustomerInfo';
import Strings from '../../../../strings';
import { NumberHelper } from '../../../../helpers/NumberHelper';
import getPrice from '../getPrice';
import { Subscriptions } from '../../Subscriptions';
import HtmlComponent from '../../../shared/HtmlComponent';
import type TCheckoutItem from '../TCheckoutItem';
import StoreHelper from '../StoreHelper';

type TEulaProps = {
    customerInfo: TCustomerInfo;
    customerCountryEv: IApiEnumValue | null;
    eula: ITranslatableString | null;
    eulaAlternatives: IEulaAlternative[] | null;
    orderNumber: string | null;
    items: TCheckoutItem[];
    isPackageInCart: (artno: string) => boolean;
    periodType: TPeriodType;
};

export const Eula: React.FC<TEulaProps> = (props) => {
    let eula: string = Strings.pickTranslation(props.eula)!;
    if (!!props.customerInfo.country && !!props.eulaAlternatives) {
        const eulaAlternative = props.eulaAlternatives.find(ea => StringHelper.compareIgnoringCase(ea.CountryCode, props.customerInfo.country) === 0);
        if (!!eulaAlternative) {
            eula = Strings.pickTranslation(eulaAlternative.Eula)!;
        }
    }

    const myStrings = Strings.components.routes.subscriptions;
    const { orderNumber, customerInfo, items, customerCountryEv, isPackageInCart, periodType } = props;
    const options: HTMLReactParserOptions = React.useMemo(() => ({
        replace: (domNode) => {
            const isNodeWithId = (id: string) => ('attribs' in domNode && domNode.attribs && domNode.attribs.id === id);

            if (isNodeWithId('replace-order-number')) {
                return <span>{orderNumber}</span>;
            }
            if (isNodeWithId('replace-customer')) {
                return (
                    <div>
                        {customerInfo.businessName}<br />
                        {customerInfo.street}<br />
                        {customerInfo.postalCode}<> </>{customerInfo.city}<br />
                        {(!!customerInfo.state) && <>{customerInfo.state}, </>}{(!!customerCountryEv) && Strings.pickTranslation(customerCountryEv)}<br />
                        {(!!customerInfo.vatNumber) ?
                            <>
                                {myStrings.vatNumber}: {customerInfo.vatNumber}
                            </>
                            :
                            <br />
                        }
                    </div>
                );
            }
            if (isNodeWithId('replace-products')) {
                return (
                    <Products customerInfo={customerInfo} items={items} isPackageInCart={isPackageInCart} periodType={periodType} />
                );
            }
            if (isNodeWithId('replace-vat-note')) {
                return <span>{(customerInfo.willVatBeCharged && customerInfo.willVatBeShownInEula) ? myStrings.vatIncludedText : myStrings.vatExcludedText}</span>;
            }
            if (isNodeWithId('replace-today-date')) {
                return <span>{Strings.formatDate(new Date())}</span>;
            }
            if (isNodeWithId('replace-support-flat-rate')) {
                return <span>{Strings.formatCurrency(0, 0, customerInfo.currency)}</span>;
            }
        }
    }),
        [orderNumber, customerInfo, customerCountryEv, items, myStrings.vatNumber, myStrings.vatIncludedText, myStrings.vatExcludedText, isPackageInCart, periodType]
    );

    return (
        <HtmlComponent htmlString={eula} options={options} />
    );
};

// Eula styles cannot be classes. It has to be exportable to html file.
const tableBorderStyle: React.CSSProperties = {
    border: '1px solid black',
    borderCollapse: 'collapse',
    padding: '2px'
};
const alignRightCellStyle: React.CSSProperties = {
    ...tableBorderStyle,
    textAlign: 'right'
};

type TProductsProps = {
    customerInfo: TCustomerInfo;
    items: TCheckoutItem[];
    isPackageInCart: (artno: string) => boolean;
    periodType: TPeriodType;
};

const Products: React.FC<TProductsProps> = (props) => {
    const myStrings = Strings.components.routes.subscriptions;
    const { items, customerInfo, isPackageInCart } = props;
    return (
        <div>
            <table style={tableBorderStyle}>
                <thead>
                    <tr>
                        <th style={tableBorderStyle}>{myStrings.productDescription}</th>
                        <th style={tableBorderStyle}>{myStrings.quantity}</th>
                        <th style={tableBorderStyle}>{myStrings.priceUnitPeriod + StoreHelper.getPeriodStrings(props.periodType).unit}</th>
                        <th style={tableBorderStyle}>{myStrings.totalPricePeriod + StoreHelper.getPeriodStrings(props.periodType).unit}</th>
                    </tr>
                </thead>
                <tbody>
                    {items.sort((a, b) => NumberHelper.compareWithNulls(a.packageData.FinalCartRank, b.packageData.FinalCartRank, 1000)).map((item: TCheckoutItem, i: number) => {
                        const unitPrice = getPrice(item.packageData, item.quantity, customerInfo.currency, props.periodType, isPackageInCart) * ((customerInfo.willVatBeCharged && customerInfo.willVatBeShownInEula) ? 1 + (item.packageData.PriceVat || 0) : 1.00);
                        const standardDecPlaces = (customerInfo.willVatBeCharged && customerInfo.willVatBeShownInEula) ? Subscriptions.priceDecPlacesWithVat : Subscriptions.priceDecPlaces;
                        let unitPriceDecimals: number;
                        if (unitPrice > 0.00) {
                            const decLog = Math.log10(unitPrice);
                            unitPriceDecimals = (decLog < 0.00 ? Math.max(Subscriptions.priceDecPlaces, Math.ceil((-1) * decLog)) : standardDecPlaces);
                        } else {
                            unitPriceDecimals = standardDecPlaces;
                        }
                        return (
                            <tr key={'eula-productrow-' + i + '-' + item.packageData.Artno}>
                                <td style={tableBorderStyle}>
                                    {Strings.pickTranslation(item.packageData.InCartTitle)}
                                </td>
                                <td style={alignRightCellStyle}>
                                    {item.quantity}</td>
                                <td style={alignRightCellStyle}>
                                    {!(!customerInfo.currency) &&
                                        <>
                                            {Strings.formatCurrency(
                                                unitPrice,
                                                unitPriceDecimals,
                                                customerInfo.currency
                                            )}
                                        </>
                                    }
                                </td>
                                <td style={alignRightCellStyle}>
                                    {!(!customerInfo.currency) &&
                                        <>
                                            {Strings.formatCurrency(
                                                unitPrice * item.quantity,
                                                standardDecPlaces,
                                                customerInfo.currency
                                            )}
                                        </>
                                    }
                                </td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};