import { Link } from '@fluentui/react';
import * as React from 'react';
import Strings from '../../../../../../strings';

export type TEulaAcceptanceFormProps = {
    onShowFullEula: () => void;
    payBtnText: string;
};

const EulaAcceptanceForm: React.FC<TEulaAcceptanceFormProps> = ({ onShowFullEula, payBtnText }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const content = Strings.formatString(myStrings.byClickingIAccept, payBtnText, (<Link onClick={onShowFullEula}>{myStrings.order}</Link>) as unknown as string) as string;

    return <div className={'small mt-2'}>{content}</div>;
};

export default EulaAcceptanceForm;
