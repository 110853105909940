import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import Strings from '../../../strings';
import type { ShoppingConnection } from '../../ShoppingConnection';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../SpinnerModal';
import type { TStripeInitData } from '../paymentGate/StripeElementsContainer';
import type { CreatePaymentMethodCardData, PaymentIntentResult, Stripe } from '@stripe/stripe-js';
import type { TStripeDropinSurroundingComponentProps } from '../paymentGate/StripeDropin';
import { StripeDropin } from '../paymentGate/StripeDropin';
import { SpinnerVariant } from '@eway-crm/gui';

type TStripeInvoicePayerProps = {
    shoppingConnection: ShoppingConnection;
    invoiceId: number;
    invoiceNumber: string;
    priceToPay: number;
    currencyCode: string;
    onDone: () => Promise<void>;
    onCancel: () => void;
};

export const StripeInvoicePayer: React.FC<TStripeInvoicePayerProps> = (props) => {
    const { invoiceId, onDone, shoppingConnection } = props;

    const existingCardInitDataGetter = React.useCallback((chosenId: string): TStripeInitData => ({
        apiMethodName: 'GetStripeInvoiceInstantPayDataForPaymentMethod',
        data: { invoiceId: invoiceId, paymentMethodId: chosenId }
    }), [invoiceId]);

    const submit = React.useCallback((stripe: Stripe, clientSecret: string, paymentMethod: string | Omit<CreatePaymentMethodCardData, 'type'>): Promise<PaymentIntentResult> => {
        return (async () => {
            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod
            });

            if (result.error) {
                console.error('Stripe paymentconfirming failed.', result.error);
                return result;
            }

            await shoppingConnection.askShoppingApi('SetInvoiceAsPaidInStripe', { invoiceId: invoiceId, paymentIntentId: result.paymentIntent.id });

            window.setTimeout(() => void onDone(), 10);

            return result;
        })();
    }, [shoppingConnection, invoiceId, onDone]);

    const DropinSurrounding: React.FC<TStripeDropinSurroundingComponentProps> = React.useCallback((p) => {
        return (
            <PayerModal
                invoiceNumber={props.invoiceNumber}
                onCancel={props.onCancel}
                priceToPay={props.priceToPay}
                currencyCode={props.currencyCode}
                isSubmitDisabled={p.isSubmitDisabled}
                onSubmit={p.onSubmit}
                isProcessing={p.isProcessing}
            >
                {p.children}
            </PayerModal>
        );
    }, [props.invoiceNumber, props.onCancel, props.priceToPay, props.currencyCode]);

    return (
        <StripeDropin
            shoppingConnection={props.shoppingConnection}
            loadingComponent={<SpinnerModal variant={SpinnerVariant.ease} />}
            existingCardInitDataGetter={existingCardInitDataGetter}
            onExistingCardSubmit={submit}
            newCardInitData={React.useMemo(() => ({ apiMethodName: 'GetStripeInvoiceInstantPayData', data: { invoiceId: invoiceId } }), [invoiceId])}
            onNewCardSubmit={submit}
            as={DropinSurrounding}
        />
    );
};

type TPayerModalBaseProps = {
    invoiceNumber: string;
    onCancel: () => void;
    priceToPay: number;
    currencyCode: string;
};

type TPayerModalProps = TPayerModalBaseProps & React.PropsWithChildren & {
    isSubmitDisabled?: boolean;
    isProcessing: boolean;
    onSubmit: () => void;
};

const PayerModal: React.FC<TPayerModalProps> = (props) => {
    const { isProcessing, onCancel } = props;
    const cancel = React.useCallback(() => {
        if (isProcessing)
            return;

        onCancel();
    }, [isProcessing, onCancel]);

    const myStrings = Strings.components.routes.purchaseHistory;
    return (
        <WizardModal show={true} onHide={cancel} size="lg">
            <WizardModal.Body>
                <h1 className="mb-5">{myStrings.payInvoiceNo} <span className="text-nowrap">{props.invoiceNumber}</span></h1>
                {props.children}
            </WizardModal.Body>
            <WizardModal.Footer>
                <Button variant="primary" onClick={props.onSubmit} disabled={isProcessing || props.isSubmitDisabled}>
                    {(!!isProcessing) &&
                        <Spinner animation="border" size="sm" className="mr-2" />
                    }
                    {myStrings.pay} {Strings.formatCurrency(props.priceToPay, 2, props.currencyCode)}
                </Button>
                <Button variant="outline-secondary" onClick={cancel} disabled={isProcessing}>
                    {Strings.cancel}
                </Button>
            </WizardModal.Footer>
        </WizardModal>
    );
};