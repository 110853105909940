import { ColumnPermissionPermissionRules } from "@eway-crm/connector";
import { Badge, Tooltip } from "@fluentui/react-components";
import Strings from "../../strings";

type TDisabledByPermissionBadgeProps = {
    disabledByPermissions: {
        permissionRule: string;
        isDisabled: boolean;
    } | null;
};

const DisabledByPermissionBadge: React.FC<TDisabledByPermissionBadgeProps> = ({ disabledByPermissions }) => {
    if (!disabledByPermissions || !disabledByPermissions.isDisabled) {
        return null;
    }

    let title = "";
    if (disabledByPermissions.permissionRule === ColumnPermissionPermissionRules.readonly) {
        title = Strings.components.routes.fieldPermissions.options.readonlyPermissionsDescription;
    } else if (disabledByPermissions.permissionRule === ColumnPermissionPermissionRules.invisible) {
        title = Strings.components.routes.fieldPermissions.options.invisiblePermissionsDescription;
    } else if (disabledByPermissions.permissionRule === ColumnPermissionPermissionRules.none) {
        title = Strings.components.routes.fieldPermissions.options.nonePermissionsDescription;
    }

    if (!title) {
        return null;
    }

    return (
        <Tooltip relationship={"description"} content={<>{Strings.components.routes.fieldPermissions.currentPermissionsDontAllowEdit}</>}>
            <Badge className={"ml-1"} size="small" color={"informative"}>{title}</Badge>
        </Tooltip>
    );
};

export default DisabledByPermissionBadge;