import * as React from 'react';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import type { Connection } from '../../../layout/connection/Connection';
import type { TBraintreeNonceCallback } from '../../../shared/paymentGate/BraintreeDropin';
import BraintreeDropin from '../../../shared/paymentGate/BraintreeDropin';
import type { TSubmitDataCreator } from '../CheckoutSubmitter';
import CheckoutSubmitter from '../CheckoutSubmitter';

type TBraintreeCheckoutFinisherProps = {
    onFinisherInitialized: (finishDelegate: () => void) => void;
    onOnPaymentMethodConfirmed: (submitDataCreator: TSubmitDataCreator) => void;
    onFinisherUnloaded: () => void;
    clientToken: string;
};

export const BraintreeCheckoutFinisher: React.FC<TBraintreeCheckoutFinisherProps> = (props) => {
    const { connection } = React.useContext(ConnectionContext);
    const [nonceGetterRef, setNonceGetterRef] = React.useState<((clb: TBraintreeNonceCallback) => void) | null>(null);
    const [isFinishing, setIsFinishing] = React.useState<boolean>(false);
    const { onFinisherInitialized, onOnPaymentMethodConfirmed, onFinisherUnloaded } = props;

    const registerDropin = React.useCallback((nonceGetter: (clb: TBraintreeNonceCallback) => void) => {
        // React state setter expects function as state-to-state resolving setter. It does not simply store the pointer to the function.
        // That is why we have to wrap it into a returning function again.
        setNonceGetterRef(() => nonceGetter);
    }, [setNonceGetterRef]);

    React.useEffect(() => {
        if (!nonceGetterRef)
            return;

        onFinisherInitialized(() => setIsFinishing(true));
    }, [nonceGetterRef, onFinisherInitialized, setIsFinishing]);

    React.useEffect(() => {
        if (!isFinishing)
            return;

        if (!nonceGetterRef) {
            console.error('BraintreeCheckoutFinisher is finishing but there is no nonce getter reference.');
            return;
        }

        const confirmNonce = (nonce: string | null) => {
            if (!nonce) {
                console.error('Cannot confirm BT nonce. No nonce.');
                setIsFinishing(false);
                return;
            }

            onOnPaymentMethodConfirmed(createDataCreator(nonce, connection));
        };

        nonceGetterRef(confirmNonce);
    }, [isFinishing, nonceGetterRef, onOnPaymentMethodConfirmed, setIsFinishing, connection]);

    React.useEffect(() => onFinisherUnloaded, [onFinisherUnloaded]);

    return (
        <BraintreeDropin
            mode="vault"
            name="OrderPaymentMethodApplet"
            clientToken={props.clientToken}
            onDropinInitialized={registerDropin}
        />
    );
};

const createDataCreator = (nonce: string | null, connection: Connection): TSubmitDataCreator => {
    return (customerInfo, products, eulaHtml, selectedPeriodType) => CheckoutSubmitter.createCheckoutOrderModel(customerInfo, products, eulaHtml, nonce, null, selectedPeriodType, connection);
};