import Strings from './../../strings';

export class ValidatorTypes {
    static readonly double = 'Double';
    static readonly currency = 'Currency';
    static readonly int = 'Int';
    static readonly percent = 'Percent';
    static readonly fileSize = 'FileSize';

    static readonly all = [ValidatorTypes.int, ValidatorTypes.double, ValidatorTypes.currency, ValidatorTypes.percent, ValidatorTypes.fileSize];

    static getLocalizedName(formatType: string): string {
        switch (formatType) {
            case ValidatorTypes.double:
                return Strings.validatorTypes.double;
            case ValidatorTypes.currency:
                return Strings.validatorTypes.currency;
            case ValidatorTypes.int:
                return Strings.validatorTypes.int;
            case ValidatorTypes.percent:
                return Strings.validatorTypes.percents;
            case ValidatorTypes.fileSize:
                return Strings.validatorTypes.fileSize;
            default:
                return '';
        }
    }
}