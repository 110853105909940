import * as React from 'react';
import { Form, Col } from 'react-bootstrap';
import Strings from '../../../strings';
import { CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import type { StripeCardNumberElementOptions, StripeCardCvcElementOptions, StripeCardExpiryElementOptions } from '@stripe/stripe-js';

export const isConnectionSecureForStripe = () => {
    // Stripe can only be used with secure connection
    return window.location.protocol === 'https:' || window.location.hostname === 'localhost';
};

const elementOptions: StripeCardCvcElementOptions & StripeCardExpiryElementOptions = {
    classes: {
        base: 'form-control stripe-element',
        focus: 'stripe-element-focused',
        invalid: 'is-invalid'
    },
    style: {
        base: {
            color: '#495057'
        },
        invalid: {
            color: '#495057'
        }
    }
};

const cardNumberElementOptions: StripeCardNumberElementOptions = {
    showIcon: true,
    ...elementOptions
};

export const StripeElementsForm: React.FC = () => {
    const myStrings = Strings.components.routes.billingDetails;

    if (!isConnectionSecureForStripe()) {
        return <p className="text-danger">{myStrings.insecureConnectionMessage}</p>;
    }

    return (
        <Form className="form-application">
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{myStrings.cardNumber}</Form.Label>
                    <CardNumberElement options={cardNumberElementOptions} />
                </Form.Group>
            </Form.Row>
            <Form.Row>
                <Form.Group as={Col}>
                    <Form.Label>{myStrings.cardExpiration}</Form.Label>
                    <CardExpiryElement options={elementOptions} />
                </Form.Group>
                <Form.Group as={Col}>
                    <Form.Label>{myStrings.cardCvc}</Form.Label>
                    <CardCvcElement options={elementOptions} />
                </Form.Group>
            </Form.Row>
        </Form>
    );
};