import * as React from 'react';
import Strings from "../../../strings";
import type { IApiEnumValue } from '@eway-crm/connector';

type TEnumValueBadgeFlagsProps = {
    item: IApiEnumValue;
    style?: React.CSSProperties;
};

export class EnumValueBadgeFlags extends React.Component<TEnumValueBadgeFlagsProps> {

    render() {
        const myStrings = Strings.components.routes.fields.dropDowns;

        return (
            <>
                {this.props.item.IsSystem &&
                    <span style={this.props.style}> <span className="badge badge-warning">{myStrings.system}</span></span>
                }
            </>
        );
    }
}