import * as React from 'react';
import type { TCustomerInfo } from './TCustomerInfo';
import Strings from '../../../strings';
import { Subscriptions } from '../Subscriptions';
import type { ICheckSalesTaxModel } from '../../../data/shopping/ICheckSalesTaxModel';
import { Spinner } from 'react-bootstrap';
import type TCheckoutItem from './TCheckoutItem';
import useTotalPrices from './StoreV2/CheckoutV2/hooks/useTotalPrices';
import StoreV2Context from './StoreV2/StoreV2Context';
import StoreHelper from './StoreHelper';
import type { IImmediateChargeModel } from '../../../data/shopping/ICheckTaxesAndChargeForecastModel';

type TProductsStepSummaryTableProps = {
    customerInfo: TCustomerInfo;
    salesTax: ICheckSalesTaxModel | null;
    items: TCheckoutItem[];
    isCheckingSalesTax: boolean;
    isPackageInCart: (artno: string) => boolean;
    immediateCharge: IImmediateChargeModel | null;
};

export const ProductsStepSummaryTable: React.FC<TProductsStepSummaryTableProps> = (props) => {
    const myStrings = Strings.components.routes.subscriptions;

    const { selectedPeriodType } = React.useContext(StoreV2Context);
    const { totalPriceWithoutTaxes, totalVat } = useTotalPrices(props.items);
    let totalSalesTax = 0;
    let isAnyTaxCharged = false;
    let isSalesTaxCharged = false;
    if (props.customerInfo.willVatBeCharged) {
        isAnyTaxCharged = true;
    }

    if (props.salesTax) {
        isAnyTaxCharged = true;
        isSalesTaxCharged = true;
        totalSalesTax = props.salesTax.TaxTotal;
    }

    const decPlaces = isAnyTaxCharged ? Subscriptions.priceDecPlacesWithVat : Subscriptions.priceDecPlaces;

    let totalWithTaxesText = myStrings.total;
    if (props.customerInfo.willVatBeCharged && isSalesTaxCharged) {
        totalWithTaxesText = myStrings.totalWithVatAndSalesTax;
    } else if (isSalesTaxCharged) {
        totalWithTaxesText = myStrings.totalWithSalesTax;
    } else if (props.customerInfo.willVatBeCharged) {
        totalWithTaxesText = myStrings.totalWithVat;
    }

    let loader = null;
    if (props.isCheckingSalesTax) {
        loader = (
            <span className="text-muted">
                <Spinner size="sm" animation="border" />&nbsp;
            </span>
        );
    }

    const translatedPeriodUnit = StoreHelper.getPeriodStrings(selectedPeriodType).unit;

    return (
        <table>
            <tbody>
                {(!!props.immediateCharge && !props.immediateCharge.ChargesFullSubscriptionAmount && !!props.immediateCharge.DiffAmount) &&
                    <>
                        <tr className="font-weight-bold">
                            <td className="text-right">{myStrings.toBePaidNow}</td>
                            <td className="text-right pl-4">
                                {Strings.formatCurrency(
                                    props.immediateCharge.DiffAmount,
                                    decPlaces,
                                    props.customerInfo.currency
                                )}
                            </td>
                            <td />
                        </tr>
                        {(!!props.immediateCharge.CoversLicensesUpToNextBillingDate) &&
                            <tr>
                                <td colSpan={4} style={{ fontSize: '12px', textAlign: 'right', paddingTop: 0 }}>
                                    {Strings.formatString(myStrings.payNowToBeCoveredUntilFormat, Strings.formatDate(props.immediateCharge.CoversLicensesUpToNextBillingDate) as string)}
                                </td>
                            </tr>
                        }
                    </>
                }
                <tr className="font-weight-bold">
                    <td className="text-right">
                        {(!isAnyTaxCharged && props.isCheckingSalesTax) &&
                            <>{loader}</>
                        }
                        {myStrings.total}
                    </td>
                    <td className="text-right pl-4">
                        {Strings.formatCurrency(
                            totalPriceWithoutTaxes,
                            decPlaces,
                            props.customerInfo.currency
                        )}
                    </td>
                    <td>&nbsp;/&nbsp;{translatedPeriodUnit}</td>
                </tr>
                {isAnyTaxCharged &&
                    <>
                        {(props.customerInfo.willVatBeCharged) &&
                            <tr>
                                <td className="text-right">{myStrings.vat}</td>
                                <td className="text-right pl-4">
                                    {Strings.formatCurrency(
                                        totalVat,
                                        decPlaces,
                                        props.customerInfo.currency
                                    )}
                                </td>
                                <td>&nbsp;/&nbsp;{translatedPeriodUnit}</td>
                            </tr>
                        }
                        {(isSalesTaxCharged) &&
                            <tr>
                                <td className="text-right">{myStrings.salesTax}</td>
                                <td className="text-right pl-4">
                                    {Strings.formatCurrency(
                                        totalSalesTax,
                                        decPlaces,
                                        props.customerInfo.currency
                                    )}
                                </td>
                                <td>&nbsp;/&nbsp;{translatedPeriodUnit}</td>
                            </tr>
                        }
                        <tr>
                            <td className="text-right">
                                {loader}
                                {totalWithTaxesText}
                            </td>
                            <td className="text-right pl-4">
                                {Strings.formatCurrency(
                                    totalPriceWithoutTaxes + totalVat + totalSalesTax,
                                    decPlaces,
                                    props.customerInfo.currency
                                )}
                            </td>
                            <td>&nbsp;/&nbsp;{translatedPeriodUnit}</td>
                        </tr>
                    </>
                }
            </tbody>
        </table >
    );
};