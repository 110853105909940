export default class GoogleAnalyticsKeys {
    static readonly source = 'eway_admin_app';

    static readonly medium = {
        helpButton: 'help_button'
    };

    static readonly campaign = {
        features: 'features'
    };
}