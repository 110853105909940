import React from 'react';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, VirtualTable, TableHeaderRow, TableSelection, TableColumnResizing, TableTreeColumn } from '@devexpress/dx-react-grid-bootstrap4';
import type {
    TableColumnWidthInfo,
    Sorting,
    Filter} from '@devexpress/dx-react-grid';
import {
    SearchState,
    IntegratedFiltering,
    SelectionState,
    IntegratedSelection,
    IntegratedSorting,
    SortingState,
    TreeDataState,
    CustomTreeData
} from '@devexpress/dx-react-grid';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import type { TFieldWithCol } from './FolderFields';
import Strings from '../../../strings';
import GridTable from '../../GridTable';
import { Spinner } from 'react-bootstrap';
import { FontIcon, mergeStyles, TooltipHost } from '@fluentui/react';
import FolderNames from '../../../data/constants/FolderNames';
import StringHelper from '../../../helpers/StringHelper';
import CopyToClipboardText from './CopyToClipboardText';
import ReactHelper from '../../../helpers/ReactHelper';
import FakeDisabledButton from '../../shared/FakeDisabledButton';
import type { IApiColumn, TFolderName} from '@eway-crm/connector';
import { FieldNames, FieldTypes } from '@eway-crm/connector';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsLockedGridRowInner from '../../shared/locks/LicenseRestrictionsLockedGridRowInner';

const myStrings = Strings.components.routes.fields;
const myFieldPermissionStrings = Strings.components.routes.fieldPermissions;

const MANDATORY_PERMISSION_WIDTH = 94;
const gridRootId = 'FolderAdditionalFieldsGridRoot';
const gridContainerId = 'FolderAdditionalFieldsGridContainer';
const gridRowHeight = 50;

const BOOLEAN_COMPARE = (a: boolean, b: boolean) => Number(b) - Number(a);

const FIELD_GRID_COLS = {
    colName: 'colName',
    typeName: 'typeName',
    DbName: 'ColumnName',
    IsMandatory: FieldPermissionNames.IsMandatory,
    IsImportant: FieldPermissionNames.IsImportant,
    IsUnique: FieldPermissionNames.IsUnique,
    IsReadonly: FieldPermissionNames.IsReadonly
};

const gridColumns = [
    { name: FIELD_GRID_COLS.colName, title: myStrings.name },
    { name: FIELD_GRID_COLS.typeName, title: myStrings.type },
    { name: FIELD_GRID_COLS.DbName, title: myStrings.dbColumnName },
    { name: FIELD_GRID_COLS.IsMandatory, title: myFieldPermissionStrings.columns.isMandatoryTitle },
    { name: FIELD_GRID_COLS.IsImportant, title: myFieldPermissionStrings.columns.isOptionalTitle },
    { name: FIELD_GRID_COLS.IsUnique, title: myFieldPermissionStrings.columns.isUniqueTitle },
    { name: FIELD_GRID_COLS.IsReadonly, title: myFieldPermissionStrings.columns.isReadonlyTitle },
];

const gridColumnExtensions: Table.ColumnExtension[] = [
    { columnName: FIELD_GRID_COLS.colName, align: 'left' },
    { columnName: FIELD_GRID_COLS.typeName, align: 'left' },
    { columnName: FIELD_GRID_COLS.DbName, align: 'left' },
    { columnName: FIELD_GRID_COLS.IsMandatory, align: 'center' },
    { columnName: FIELD_GRID_COLS.IsImportant, align: 'center' },
    { columnName: FIELD_GRID_COLS.IsUnique, align: 'center' },
    { columnName: FIELD_GRID_COLS.IsReadonly, align: 'center' },
];

const filteringColumnExtensions = [
    {
        columnName: 'colName',
        predicate: (value: string, filter: Filter, row: TFieldWithCol) => {
            if (typeof filter.value !== 'string')
                return false;

            const colName = value.toLowerCase();
            const filterValue = filter.value?.toLowerCase() ?? '';
            const description = row.col.AdditionalFieldItem?.Comment?.toLowerCase();
            return colName.includes(filterValue) || !!description?.includes(filterValue);
        },
    },
];

const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [
    { columnName: FIELD_GRID_COLS.colName, compare: StringHelper.localeCompare },
    { columnName: FIELD_GRID_COLS.IsMandatory, compare: BOOLEAN_COMPARE },
    { columnName: FIELD_GRID_COLS.IsImportant, compare: BOOLEAN_COMPARE },
    { columnName: FIELD_GRID_COLS.IsUnique, compare: BOOLEAN_COMPARE },
    { columnName: FIELD_GRID_COLS.IsReadonly, compare: BOOLEAN_COMPARE },
];
const sortingStateColumnExtensions: SortingState.ColumnExtension[] = [
    { columnName: FIELD_GRID_COLS.DbName, sortingEnabled: false },
];

const getWidthOfMainColumn = (tableWidth: number | undefined, minColWidth: number) => {
    if (tableWidth) {
        const newColWidth = tableWidth - 55 - 150 - 140 - 4 * MANDATORY_PERMISSION_WIDTH;
        if (newColWidth > minColWidth) {
            return newColWidth;
        }
        return minColWidth;
    }
    return minColWidth;
};

const GridRootComponent = GridTable.createRootWithProps({ id: gridRootId });
const TableContainerComponent = GridTable.createTableContainerComponent({ id: gridContainerId });

const INDENT_MARGIN = 1.25;
const IndentComponent: React.ComponentType<TableTreeColumn.IndentProps> = ({ level }) => {
    if (!level) {
        return null;
    }
    return (
        <span className="d-inline-block" style={{ marginRight: `${level * INDENT_MARGIN}rem` }}>
            &shy;
        </span>
    );
};

type TFieldsGridProps = {
    fields: TFieldWithCol[];
    searchedString: string;
    selection: React.ReactText[];
    onSelectionChange: (newSelection: React.ReactText[]) => void;
    loadingItemGuid: string | null;
    folderName: string;
    onEdit: (dbColumnName: string) => void;
    systemGroupGuid: string;
    expandedRowIds?: (string | number)[];
    onExpandedRowIdsChange?: (expandedRowIds: (string | number)[]) => void;
};

type TFieldsGridState = {
    columnWidths: TableColumnWidthInfo[];
    sort: Sorting[];
    copied: boolean;
};

export default class FieldsGrid extends React.Component<TFieldsGridProps, TFieldsGridState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;
    private readonly tableRef: React.RefObject<HTMLDivElement> | null = null;

    constructor(props: TFieldsGridProps) {
        super(props);
        this.state = {
            sort: [{ columnName: FIELD_GRID_COLS.colName, direction: 'asc' }],
            columnWidths: [],
            copied: false,
        };
        this.tableRef = React.createRef<HTMLDivElement>();
    }

    private readonly HeaderCell: React.FC<TableHeaderRow.ContentProps> = (props) => {
        if (props.column.name === FIELD_GRID_COLS.DbName) {
            return (
                <TableHeaderRow.Content {...props} className="overflow-hidden">
                    {props.column.title}{' '}
                    <TooltipHost content={myStrings.dbColumnNameTooltip}>
                        <FontIcon iconName="info" className="pl-1" style={{ cursor: 'default' }} />
                    </TooltipHost>
                </TableHeaderRow.Content>
            );
        }
        const colNameStyle = props.column.name === FIELD_GRID_COLS.colName ? { marginLeft: '-0.5rem' } : undefined;
        return <TableHeaderRow.Content {...props} style={colNameStyle} />;
    };

    /**
     * Checks if field can be opened in edit wizard
     * @param col column to check
     */
    static readonly getIsColumnEditable = (col: Pick<IApiColumn, 'AllowedColumnMandatoryTypes' | 'AllowedColumnPermissionTypes' | 'IsPermissionEnabled'>) => {
        if (!col.IsPermissionEnabled) {
            return false;
        }

        return true;
    };

    private readonly NameTableCell: React.FC<TableTreeColumn.CellProps> = (props) => {
        const typedRow = props.row as TFieldWithCol;
        const typedValue = props.value as React.ReactChild;
        const isFieldEditable = FieldsGrid.getIsColumnEditable(typedRow.col);
        const comment = typedRow.col.AdditionalFieldItem?.Comment;
        const expandButton = ReactHelper.Children.takeByType(props.children, this.ExpandButtonComponent);
        const indent = ReactHelper.Children.takeByType(props.children, IndentComponent);
        const { isFieldLocked } = this.context.licenseRestrictionsHelper.isFieldLocked(this.props.folderName as TFolderName, typedRow.ColumnName);

        return (
            <VirtualTable.Cell className={mergeStyles('align-middle w-100 d-flex align-items-center px-1', comment && 'py-2')} title={typedValue} {...props}>
                {indent}
                {expandButton}
                <div className="align-middle flex-grow-1 fullName d-inline-flex justify-content-between text-truncate">
                    <div style={{ lineHeight: comment ? '18px' : '22px' }} className="text-truncate my-auto">
                        <div className="text-truncate">
                            {typedValue}
                            {typedRow.IsAdditionalField && <span className="badge badge-info ml-1">{myFieldPermissionStrings.additionalField}</span>}
                        </div>
                        {comment && (
                            <div className="text-truncate text-secondary" title={comment}>
                                {comment}
                            </div>
                        )}
                    </div>
                    {this.props.loadingItemGuid === typedRow.ColumnName ? (
                        <div style={{ minHeight: '22px' }} className="ml-2 d-inline-flex">
                            <Spinner style={{ margin: 'auto 0' }} animation="border" size="sm" />
                        </div>
                    ) : (
                        <div className="ml-2 d-inline-flex" onClick={(e) => e.stopPropagation()}>
                            {!this.props.loadingItemGuid && !isFieldLocked && (
                                <FakeDisabledButton
                                    style={{ minHeight: '22px' }}
                                    variant="link"
                                    onClick={() => {
                                        this.props.onEdit(typedRow.ColumnName);
                                    }}
                                    disabled={!isFieldEditable}
                                    className="p-0 border-0 invisible d-parent-tr-hover-visiblie"
                                >
                                    {isFieldEditable ? (
                                        <FontIcon iconName="Edit" />
                                    ) : (
                                        <TooltipHost content={myStrings.tooltips.fieldCannotBeModified}>
                                            <FontIcon iconName="Uneditable" />
                                        </TooltipHost>
                                    )}
                                </FakeDisabledButton>
                            )}
                        </div>
                    )}
                </div>
            </VirtualTable.Cell>
        );
    };

    private readonly ViewTableCell: React.FC<Table.DataCellProps> = (props) => {
        const baseClassNames = 'align-middle';
        if ([FIELD_GRID_COLS.IsImportant, FIELD_GRID_COLS.IsMandatory, FIELD_GRID_COLS.IsUnique, FIELD_GRID_COLS.IsReadonly].includes(props.column.name)) {
            const typedValue = props.value as boolean;
            return (
                <VirtualTable.Cell className={mergeStyles(baseClassNames, 'p-0')} {...props} title={typedValue ? Strings.yes : Strings.no}>
                    {props.value ? <FontIcon iconName="Accept" className="font-weight-bolder" style={{ fontSize: '1rem' }} /> : null}
                </VirtualTable.Cell>
            );
        } else if (props.column.name === FIELD_GRID_COLS.DbName) {
            const typedValue = props.value as string;
            return (
                <VirtualTable.Cell className={mergeStyles(baseClassNames, 'fullName')} {...props}>
                    <CopyToClipboardText>{typedValue}</CopyToClipboardText>
                </VirtualTable.Cell>
            );
        } else {
            const typedValue = props.value as React.ReactChild;
            return (
                <VirtualTable.Cell className={baseClassNames} {...props} title={typedValue}>
                    {typedValue}
                </VirtualTable.Cell>
            );
        }
    };

    private readonly RowComponent: React.FC<TableSelection.RowProps> = (props) => {
        const typedRow = props.tableRow.row as TFieldWithCol;
        const { isFieldLocked, fieldLockedLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldLocked(this.props.folderName as TFolderName, typedRow.ColumnName);

        if (isFieldLocked) {
            const children = props.children as React.ReactNode[];
            const checkboxColumn = children[0]; // Is first in children
            const folderNameColumn = children[1]; // Is second in children

            return (
                <tr>
                    {checkboxColumn}
                    {folderNameColumn}
                    <LicenseRestrictionsLockedGridRowInner
                        colSpan={children.length - 1}
                        licenseRestriction={fieldLockedLicenseRestriction}
                        unlockText={Strings.components.licenseRestrictions.unlockThisField}
                    />
                </tr>
            );
        }

        const onEdit = (row: TFieldWithCol) => {
            this.props.onEdit(row.ColumnName);
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const hasError = React.useMemo(() => {
            if (typedRow.IsAdditionalField) {
                const { isCountExceeded } = this.context.licenseRestrictionsHelper.isCustomFieldsCountExceeded();
                if (isCountExceeded) {
                    return true;
                }
            }

            if (typedRow.IsMandatory && typedRow.IsMandatoryRuleEditable) {
                const { isCountExceeded } = this.context.licenseRestrictionsHelper.isMandatoryFieldsCountExceeded(this.props.folderName as TFolderName);
                if (isCountExceeded) {
                    return true;
                }
            }

            if (typedRow.IsImportant && typedRow.IsMandatoryRuleEditable) {
                const { isCountExceeded } = this.context.licenseRestrictionsHelper.isImportantFieldsCountExceeded(this.props.folderName as TFolderName);
                if (isCountExceeded) {
                    return true;
                }
            }

            if (typedRow.IsUnique && typedRow.IsMandatoryRuleEditable) {
                const { isUniqueFieldsLocked } = this.context.licenseRestrictionsHelper.isUniqueFieldsLocked();
                if (isUniqueFieldsLocked) {
                    return true;
                }
            }

            if (typedRow.IsReadonly && typedRow.IsPermissionRuleEditable) {
                const { isReadOnlyFieldsLocked } = this.context.licenseRestrictionsHelper.isReadOnlyFieldsLocked();
                if (isReadOnlyFieldsLocked) {
                    return true;
                }
            }

            if (!typedRow.IsAdditionalField && typedRow.col.Type === FieldTypes.comboBox) {
                if (typedRow.col.EnumTypeItem?.EnumName === 'Currency') {
                    const { isCountExceeded } = this.context.licenseRestrictionsHelper.isCurrencyCountExceeded(this.props.folderName as TFolderName);
                    if (isCountExceeded) {
                        return true;
                    }
                }

                if (FieldNames.allTypeEnNames.includes(typedRow.ColumnName)) {
                    const { isCountExceeded } = this.context.licenseRestrictionsHelper.isItemTypesCountExceeded(this.props.folderName as TFolderName);
                    if (isCountExceeded) {
                        return true;
                    }
                }
                return false;
            }
        }, [typedRow]);

        return <GridTable.RowComponent {...props} hasError={hasError} onEdit={onEdit} />;
    };

    componentDidMount() {
        this.setState({
            columnWidths: [
                // First column takes up remaining space of table
                { columnName: FIELD_GRID_COLS.colName, width: getWidthOfMainColumn(this.tableRef?.current?.clientWidth, 150) },
                { columnName: FIELD_GRID_COLS.typeName, width: 150 },
                { columnName: FIELD_GRID_COLS.DbName, width: 140 },
                { columnName: FIELD_GRID_COLS.IsMandatory, width: MANDATORY_PERMISSION_WIDTH },
                { columnName: FIELD_GRID_COLS.IsImportant, width: MANDATORY_PERMISSION_WIDTH },
                { columnName: FIELD_GRID_COLS.IsUnique, width: MANDATORY_PERMISSION_WIDTH },
                { columnName: FIELD_GRID_COLS.IsReadonly, width: MANDATORY_PERMISSION_WIDTH },
            ],
        });
    }

    private readonly getChildRows = (row: TFieldWithCol, rootRows: TFieldWithCol[]) => {
        const childRows = rootRows.filter((r) => row ? r.col.OriginalCurrencyColumn === row.col.ColumnName : !r.col.OriginalCurrencyColumn);
        return childRows.length ? childRows : null;
    };

    private readonly ExpandButtonComponent: React.ComponentType<TableTreeColumn.ExpandButtonProps> = (expandProps) => {
        const onClick = (e?: React.MouseEvent<HTMLElement>) => {
            e?.stopPropagation();
            expandProps.onToggle();
        };

        if (expandProps.visible) {
            const iconClassNames = 'pr-2 font-weight-bold user-select-none';
            const iconStyles = { cursor: 'pointer' };
            if (expandProps.expanded) {
                return <FontIcon iconName="ChevronDown" onClick={onClick} className={iconClassNames} style={iconStyles} />;
            }
            return <FontIcon iconName="ChevronRight" onClick={onClick} className={iconClassNames} style={iconStyles} />;
        }
        return <IndentComponent level={1} />;
    };

    render() {
        const folderTranslatedName = FolderNames.getPluralName(this.props.folderName);
        const noDataString = (this.props.searchedString
            ? Strings.formatString(myStrings.noAfsInFolderMatchFormat, folderTranslatedName)
            : Strings.formatString(myStrings.noAfsInFolderFormat, folderTranslatedName)) as string;

        return (
            <div className="h-100 position-absolute permissions-table" ref={this.tableRef} style={{ maxHeight: '100%', minHeight: '0', top: '0', bottom: '0', left: '0', right: '0' }}>
                <Grid rows={this.props.fields} columns={gridColumns} rootComponent={GridRootComponent} getRowId={(row: TFieldWithCol) => row.ColumnName}>
                    <SearchState value={this.props.searchedString} />
                    <SelectionState selection={this.props.selection} onSelectionChange={this.props.onSelectionChange} />
                    <SortingState sorting={this.state.sort} onSortingChange={(sort) => this.setState({ sort })} columnExtensions={sortingStateColumnExtensions} />
                    <TreeDataState expandedRowIds={this.props.expandedRowIds} onExpandedRowIdsChange={this.props.onExpandedRowIdsChange} />
                    <CustomTreeData getChildRows={(row: TFieldWithCol, rootRows: TFieldWithCol[]) => this.getChildRows(row, rootRows)} />
                    <IntegratedSelection />
                    <IntegratedFiltering columnExtensions={filteringColumnExtensions} />
                    <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                    <VirtualTable
                        containerComponent={TableContainerComponent}
                        cellComponent={this.ViewTableCell}
                        messages={{ noData: noDataString }}
                        noDataCellComponent={GridTable.NoDataCell}
                        estimatedRowHeight={gridRowHeight}
                        columnExtensions={gridColumnExtensions}
                    />
                    <TableColumnResizing columnWidths={this.state.columnWidths} onColumnWidthsChange={(nextColumnWidths) => this.setState({ columnWidths: nextColumnWidths })} />
                    <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} contentComponent={this.HeaderCell} />
                    <TableTreeColumn for={FIELD_GRID_COLS.colName} indentComponent={IndentComponent} expandButtonComponent={this.ExpandButtonComponent} cellComponent={this.NameTableCell} />
                    <TableSelection
                        showSelectAll
                        selectByRowClick
                        highlightRow
                        rowComponent={this.RowComponent}
                    />
                </Grid>
            </div>
        );
    }
}
