import React from 'react';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import type { IApiDatumResponse } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import type { TFieldPermissionWithCol } from './FieldPermissionsGrid';
import { ConnectionContext } from '../../../providers/ConnectionProvider';

type TFieldPermissionsDataTransformerRenderProps<T extends TFieldPermissionWithCol> = {
    fieldPermissions: T[];
    onFieldPermissionsChange: (rowId: string, column: string, newValue: string | number | boolean | null, isChangingUnique?: boolean) => void;
    onMandatoryPermissionsChange: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
};

type TFieldPermissionsDataTransformerProps<T extends TFieldPermissionWithCol> = {
    render: React.FC<TFieldPermissionsDataTransformerRenderProps<T>>;
    fieldPermissions: T[];
    allGroups: IApiGroup[];
    selectedGroup?: IApiGroup;
    selectedFolderName: string | null;
    onUniqueNotFullyPermittedForGroupName?: (uniqueNotFullyPermittedForGroupName: string | null) => void;
    onCheckForDuplicateValues?: (isCheckingForDuplicateValues: boolean, nonUniqueValue: string | null, callback?: () => void) => void;
    onFieldPermissionsChange?: (newFieldPermissions: T[], newItemToSave: T) => void;
    isShowingAdditionalField?: boolean;
};

class FieldPermissionsDataTransformer<T extends TFieldPermissionWithCol> extends React.Component<TFieldPermissionsDataTransformerProps<T>> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    static readonly getFieldPermissionsWithMandatoryRuleInMultipleCols = <T extends TFieldPermissionWithCol>(fieldPermissions: T[]) => {
        return fieldPermissions.map((fp) => ({
            ...fp,
            IsMandatory: fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory,
            IsImportant: fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional,
            IsUnique: fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique,
            IsReadonly: fp.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Readonly
        })); 
    };

    private readonly handleFieldPermissionChange = (rowId: string, column: string, newValue: string | number | boolean | null, isChangingUnique?: boolean) => {
        let newItemToSave: T | undefined;
        const newFieldPermissions = this.props.fieldPermissions.map((fp) => {
            const fieldPermissionId = this.props.isShowingAdditionalField ? fp.GroupGuid : fp.ColumnName;
            if (fieldPermissionId === rowId && (!this.props.selectedFolderName || this.props.selectedFolderName === fp.FolderName)) {
                let updatedModulePermission = { ...fp, [column]: newValue };

                if (isChangingUnique) {
                    updatedModulePermission = { ...fp, [column]: newValue, IsSetToUnique: newValue === FieldPermissionNames.MandatoryRuleKeys.Unique };
                }

                newItemToSave = updatedModulePermission;

                return updatedModulePermission;
            }
            return fp;
        });

        if (newItemToSave) {
            this.props.onFieldPermissionsChange && this.props.onFieldPermissionsChange(newFieldPermissions, newItemToSave);
        }
    };

    private readonly handleMandatoryPermissionChange = (rowId: string, column: string, newValue: string | number | boolean | null) => {
        const editedFieldPermission = this.props.fieldPermissions.find((fp) => fp.ColumnName === rowId)!;

        // Transform value from multiple columns of MandatoryRule back to one
        let newValueInMandatoryRule: keyof typeof FieldPermissionNames.MandatoryRuleKeys = FieldPermissionNames.MandatoryRuleKeys.None;
        if (column === FieldPermissionNames.IsMandatory) {
            newValueInMandatoryRule = newValue ? FieldPermissionNames.MandatoryRuleKeys.Mandatory : FieldPermissionNames.MandatoryRuleKeys.None;
        } else if (column === FieldPermissionNames.IsImportant) {
            newValueInMandatoryRule = newValue ? FieldPermissionNames.MandatoryRuleKeys.Optional : FieldPermissionNames.MandatoryRuleKeys.None;
        } else if (column === FieldPermissionNames.IsUnique) {
            newValueInMandatoryRule = newValue ? FieldPermissionNames.MandatoryRuleKeys.Unique : FieldPermissionNames.MandatoryRuleKeys.Mandatory;
        }

        if (!this.props.isShowingAdditionalField && this.props.selectedGroup && column === FieldPermissionNames.IsUnique && newValueInMandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique) {
            if (editedFieldPermission.PermissionRule !== FieldPermissionNames.PermissionRuleKeys.All) {
                this.props.onUniqueNotFullyPermittedForGroupName && this.props.onUniqueNotFullyPermittedForGroupName(this.props.selectedGroup.GroupName);
            } else if (
                editedFieldPermission.NotFullyPermittedForGroupGuids &&
                editedFieldPermission.NotFullyPermittedForGroupGuids.length > 0 &&
                !(editedFieldPermission.NotFullyPermittedForGroupGuids.length === 1 && editedFieldPermission.NotFullyPermittedForGroupGuids[0] === this.props.selectedGroup.ItemGUID) // If System group is not the only one in NotFullyPermittedForGroupGuids
            ) {
                const notFullyPermittedWithoutSystemGroupGuid = editedFieldPermission.NotFullyPermittedForGroupGuids.filter((efp) => efp !== editedFieldPermission.GroupGuid);
                this.props.onUniqueNotFullyPermittedForGroupName && this.props.onUniqueNotFullyPermittedForGroupName(this.props.allGroups.find((ag) => ag.ItemGUID === notFullyPermittedWithoutSystemGroupGuid?.[0])!.GroupName);
            } else if (this.props.onCheckForDuplicateValues) {
                this.props.onCheckForDuplicateValues(true, null, () => {
                    this.context.connection.callApi(
                        'CheckForDuplicatedValues',
                        { columnName: rowId, folderName: this.props.selectedFolderName },
                        (res: IApiDatumResponse<{ IsThereAnyDuplicatedValue: boolean; DuplicatedValue: string }>) => {
                            if (res.Datum.IsThereAnyDuplicatedValue) {
                                this.props.onCheckForDuplicateValues && this.props.onCheckForDuplicateValues(false, res.Datum.DuplicatedValue);
                            } else {
                                this.handleFieldPermissionChange(rowId, FieldPermissionNames.MandatoryRule, newValueInMandatoryRule, true);
                                this.props.onCheckForDuplicateValues && this.props.onCheckForDuplicateValues(false, null, () => {
                                    this.handleFieldPermissionChange(rowId, FieldPermissionNames.MandatoryRule, newValueInMandatoryRule, true);
                                });
                            }
                        }
                    );
                });
            }
        } else {
            this.handleFieldPermissionChange(rowId, FieldPermissionNames.MandatoryRule, newValueInMandatoryRule, column === FieldPermissionNames.IsUnique);
        }
    };

    render() {
        return (
            <>
                {this.props.render({
                    fieldPermissions: FieldPermissionsDataTransformer.getFieldPermissionsWithMandatoryRuleInMultipleCols<T>(this.props.fieldPermissions),
                    onFieldPermissionsChange: this.handleFieldPermissionChange,
                    onMandatoryPermissionsChange: this.handleMandatoryPermissionChange,
                })}
            </>
        );
    }
}

export default FieldPermissionsDataTransformer;
