import * as React from 'react';
import Strings from '../../../strings';
import WizardModal from '../../WizardModal';
import type { IApiColumn, IApiColumnPermission, IApiDataResponse, IApiDatumResponse, IApiGroup, IApiLicense, IApiModulePermission, IApiUser, TApiItemWithReleations, TFolderName } from '@eway-crm/connector';
import { Button, Nav, Tab } from 'react-bootstrap';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import { DelayedRender } from '@fluentui/react';
import EffectiveModulePermissionsTab from './effectivePermissions/EffectiveModulePermissionsTab';
import FeatureLockedSplash from '../../shared/locks/FeatureLockedSplash';
import EffectiveFieldPermissionsTab from './effectivePermissions/EffectiveFieldPermissionsTab';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import GroupsToggleMenu from './effectivePermissions/GroupsToggleMenu';
import memoizeOne from 'memoize-one';
import ReactHelper from '../../../helpers/ReactHelper';
import { SpinnerModal } from '../../shared/SpinnerModal';
import RelationTypes from '../../../data/constants/RelationTypes';
import FolderNames from '../../../data/constants/FolderNames';
import { RemoteItemStore } from '../../../RemoteItemStore';
import FieldPermissionsDataTransformer from '../fields/FieldPermissionsDataTransformer';
import Fields from '../Fields';
import EffectivePermissionsToggleGroupDialog from './effectivePermissions/EffectivePermissionsToggleGroupDialog';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import { GroupNames } from '../../../data/constants/GroupNames';
import StringHelper from '../../../helpers/StringHelper';
import { Usernames } from '../../../data/constants/Usernames';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsHelper from '../../../helpers/LicenseRestrictionsHelper';
import type { TLicenseRestrictionsObject } from '@eway-crm/gui';
import { SpinnerVariant } from '@eway-crm/gui';

const dialogBodyExpectedHeight = '35rem';

type TTabName = 'modulePermissions' | 'fieldPermissions';

const myStrings = Strings.components.routes.users;

export type TUserLicenseRestrictionsModulesMap = Partial<Record<TFolderName, IApiLicense['Restrictions']['Modules'][0]>>;
export type TCustomUserLicenseRestrictionsObject = TLicenseRestrictionsObject & { user: IApiUser };

type TEffectivePermissionsWizardProps = {
    onDismiss: () => void;
    selectedUserGuid: string;
};

type TEffectivePermissionsWizardState = {
    modulePermissions: TModulePermissionWithFolder[] | null;
    fieldPermissions: TFieldPermissionWithCol[] | null;
    fieldPermissionFolders: string[] | null;
    groups: IApiGroup[] | null;
    userGroups: IApiGroup[] | null;
    systemHealthNotificationGroupGuid: string | null;
    currentUser: TApiItemWithReleations<IApiUser> | null;
    columns: IApiColumn[] | null;
    currentTabKey: TTabName;
    selectedFieldPermissionsFolder: string | null;
    groupGuidToChange: string | null;
    isLoading: boolean;
    customUserLicenseRestrictionsObject: TCustomUserLicenseRestrictionsObject | null;
};

export class EffectivePermissionsWizard extends React.Component<TEffectivePermissionsWizardProps, TEffectivePermissionsWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TEffectivePermissionsWizardProps) {
        super(props);
        this.state = {
            columns: null,
            currentUser: null,
            fieldPermissionFolders: null,
            fieldPermissions: null,
            groups: null,
            modulePermissions: null,
            systemHealthNotificationGroupGuid: null,
            userGroups: null,
            currentTabKey: 'modulePermissions',
            selectedFieldPermissionsFolder: null,
            groupGuidToChange: null,
            isLoading: false,
            customUserLicenseRestrictionsObject: null
        };
    }

    private readonly toggleUserGroup = async () => {
        const { askApi } = this.context.connection;
        await ReactHelper.setState(this, { isLoading: true });

        if (this.state.userGroups!.every((g) => g.ItemGUID !== this.state.groupGuidToChange)) {
            const transmitObject = {
                ItemGUID1: this.state.groupGuidToChange,
                ItemGUID2: this.state.currentUser!.ItemGUID,
                FolderName1: FolderNames.groups,
                FolderName2: FolderNames.users,
                RelationType: RelationTypes.group,
                DifferDirection: false,
            };
            await askApi('SaveRelation', { transmitObject });
        } else {
            const relationToBeRemoved = this.state.currentUser!.Relations.find((rel) => rel.ForeignItemGUID === this.state.groupGuidToChange);
            if (relationToBeRemoved) {
                await askApi('DeleteRelation', { relationDataGuid: relationToBeRemoved.RelationDataGUID });
            }
        }
        await ReactHelper.setState(this, { groupGuidToChange: null });
        this.reload()
            .catch((err) => console.error('Unable to reload effective permissions data.', err));
    };

    private readonly reload = async () => {
        const { askApi } = this.context.connection;
        const { licenseRestrictionsHelper, apiData: { objectTypes } } = this.context;
        await ReactHelper.setState(this, { isLoading: true });

        const groupsRes = await askApi<IApiDataResponse<IApiGroup>>('GetGroups', {});
        const groups = groupsRes.Data;
        const systemHealthNotificationGroupGuid = groups.find(g => g.GroupName === GroupNames.systemHealthNotification)?.ItemGUID ?? null;

        const userWithGroupsRes = await askApi<IApiDataResponse<TApiItemWithReleations<IApiUser>>>('GetUsersByItemGuids', {
            itemGuids: [this.props.selectedUserGuid],
            includeForeignKeys: true,
            includeRelations: true,
            relationsFilter: { RelationType: RelationTypes.group, ForeignFolderName: FolderNames.groups },
        });
        const currentUser = userWithGroupsRes.Data[0];
        const userGroups = currentUser.Relations.map((group) => groups.find((g) => g.ItemGUID === group.ForeignItemGUID)!);

        const otModulePermissionFolders = RemoteItemStore.getDisplayableModulePermissionFolders(objectTypes, licenseRestrictionsHelper);
        const modulePermissionsRes = await askApi<IApiDataResponse<IApiModulePermission>>('GetEffectiveModulePermissions', { userGuid: this.props.selectedUserGuid });
        const modulePermissions = modulePermissionsRes.Data.filter((mp) => otModulePermissionFolders.includes(mp.FolderName)).map((mp) => ({
            ...mp,
            Folder: FolderNames.getPluralName(mp.FolderName),
        }));

        const userLicenseRestrictionsRes = await askApi<IApiDatumResponse<IApiLicense['Restrictions']>>('GetUserLicenseRestrictions', { userGuid: this.props.selectedUserGuid });
        const customUserLicenseRestrictionsObject = LicenseRestrictionsHelper.getLicenseRestrictionsObject(userLicenseRestrictionsRes.Datum);

        const otFieldPermissionFolders = RemoteItemStore.getDisplayableFieldPermissionFolders(objectTypes, licenseRestrictionsHelper);
        const columnsRes = await askApi<IApiDataResponse<IApiColumn>>('GetColumns', { includeAdditionalFields: true });
        const columns = columnsRes.Data.filter((col) => !!col.IsPermissionEnabled);
        const colPermissionsRes = await askApi<IApiDataResponse<IApiColumnPermission>>('GetEffectiveColumnPermissions', {
            userGuid: this.props.selectedUserGuid,
            includeDefaultPermissions: true,
        });
        const fieldPermissions = colPermissionsRes.Data.map((colPermission) => {
            const column = columns.find((t) => t.ColumnName === colPermission.ColumnName && t.FolderName === colPermission.FolderName)!;
            return {
                ...colPermission,
                col: column,
                colName: Fields.getColumnName(column),
            };
        });
        const fieldPermissionsFinal = FieldPermissionsDataTransformer.getFieldPermissionsWithMandatoryRuleInMultipleCols(fieldPermissions);

        await ReactHelper.setState(this, {
            isLoading: false,
            modulePermissions,
            userGroups,
            systemHealthNotificationGroupGuid,
            fieldPermissions: fieldPermissionsFinal,
            fieldPermissionFolders: otFieldPermissionFolders,
            columns,
            groups,
            currentUser,
            customUserLicenseRestrictionsObject: { ...customUserLicenseRestrictionsObject, user: currentUser }
        });
    };

    componentDidMount() {
        this.reload()
            .catch((err) => console.error('Unable to reload effective permissions data.', err));
    }

    private readonly onGroupToggle = (groupGuid: string) => {
        this.setState({ groupGuidToChange: groupGuid });
    };

    private readonly getGroupsMenuItems = memoizeOne((groups: IApiGroup[], userGroups: IApiGroup[], selectedUser: TApiItemWithReleations<IApiUser>) => {
        const userGroupsGuids = userGroups.map((g) => g.ItemGUID);
        const filteredGroups = RemoteItemStore.getDisplayableGroups(groups);
        const disabledGroups = [GroupNames.system, GroupNames.everyone];

        const isUserInAdminGroup = filteredGroups.some((g) => {
            const isSelectedUserCurrentUser = this.context.connection.state.session!.userGuid === selectedUser.ItemGUID;
            const isSelectedUserAdmin = StringHelper.compareIgnoringCase(selectedUser.Username, Usernames.admin) === 0;

            return g.IsAdmin && (isSelectedUserCurrentUser || isSelectedUserAdmin);
        });

        return filteredGroups.map((g) => ({
            itemId: g.ItemGUID,
            itemName: g.GroupName,
            itemDescription: g.ResponsibilityDescription,
            isUserInGroup: userGroupsGuids.includes(g.ItemGUID),
            isDisabled: disabledGroups.includes(g.GroupName) || (g.IsAdmin && isUserInAdminGroup),
        }));
    });

    private readonly onTabSelect = (key: string | null) => {
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        if (key === "modulePermissions" && isModulePermissionsLocked) {
            this.context.showLicenseRestrictionModal(modulePermissionsLicenseRestriction);
            return;
        }

        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        if (key as TTabName === "fieldPermissions" && isFieldPermissionsLocked) {
            this.context.showLicenseRestrictionModal(fieldPermissionsLicenseRestriction);
            return;
        }
        this.setState({ currentTabKey: key as TTabName });
    };

    render() {
        if (
            this.state.isLoading ||
            !this.state.currentUser ||
            !this.state.modulePermissions ||
            !this.state.userGroups ||
            !this.state.customUserLicenseRestrictionsObject ||
            !this.state.fieldPermissionFolders ||
            !this.state.fieldPermissions ||
            !this.state.columns ||
            !this.state.groups
        ) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        }

        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        const { isUserRolesReadOnly } = this.context.licenseRestrictionsHelper.isUserRolesReadOnly();

        return (
            <>
                {this.state.isLoading && <SpinnerModal variant={SpinnerVariant.linear} />}
                {this.state.groupGuidToChange && (
                    <EffectivePermissionsToggleGroupDialog
                        onCancel={() => this.setState({ groupGuidToChange: null })}
                        onConfirm={this.toggleUserGroup}
                        currentUser={this.state.currentUser}
                        isAddingGroup={this.state.userGroups.every((g) => g.ItemGUID !== this.state.groupGuidToChange)}
                        groupName={this.state.groups.find((g) => g.ItemGUID === this.state.groupGuidToChange)?.GroupName ?? ''}
                    />
                )}
                <WizardModal show={true} dialogClassName={'modal-90w'} dialogBodyClassName="p-0 d-flex w-100" onHide={this.props.onDismiss} dialogBodyExpectedHeight={dialogBodyExpectedHeight}>
                    <WizardModal.Title>{myStrings.effectivePermissions}</WizardModal.Title>
                    <WizardModal.Body>
                        <div className="d-flex flex-column pl-xl-5 pr-xl-4 px-lg-4 pt-lg-4 px-md-3 pt-md-3 pb-0 px-2 flex-grow-1" style={{ width: 'calc(100% - 295px)' }}>
                            <div>
                                <h1>{'' + this.state.currentUser.FileAs}</h1>
                            </div>
                            <div>
                                <DelayedRender>
                                    <Tab.Container activeKey={this.state.currentTabKey} onSelect={this.onTabSelect} mountOnEnter>
                                        <div className="mt-4">
                                            <Nav variant="tabs">
                                                <Nav.Item>
                                                    <Nav.Link eventKey="modulePermissions">
                                                        {myStrings.modulePermissions} {isModulePermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={modulePermissionsLicenseRestriction} />}
                                                    </Nav.Link>
                                                </Nav.Item>
                                                <Nav.Item>
                                                    <Nav.Link eventKey="fieldPermissions">
                                                        {myStrings.fieldPermissions} {isFieldPermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={fieldPermissionsLicenseRestriction} />}
                                                    </Nav.Link>
                                                </Nav.Item>
                                            </Nav>
                                        </div>
                                        <div className="mt-4">
                                            <Tab.Content>
                                                <Tab.Pane eventKey="modulePermissions">
                                                    {!isModulePermissionsLocked ? (
                                                        <EffectiveModulePermissionsTab
                                                            modulePermissions={this.state.modulePermissions}
                                                            userGroups={this.state.userGroups}
                                                            customUserLicenseRestrictionsObject={this.state.customUserLicenseRestrictionsObject}
                                                        />
                                                    ) : (
                                                        <FeatureLockedSplash className="pt-6" />
                                                    )}
                                                </Tab.Pane>
                                                <Tab.Pane eventKey="fieldPermissions">
                                                    {!isFieldPermissionsLocked ? (
                                                        <EffectiveFieldPermissionsTab
                                                            folders={this.state.fieldPermissionFolders}
                                                            onSelectedFolderNameChange={(newFolderName) => this.setState({ selectedFieldPermissionsFolder: newFolderName })}
                                                            selectedFolderName={this.state.selectedFieldPermissionsFolder}
                                                            fieldPermissions={this.state.fieldPermissions}
                                                            userGroups={this.state.userGroups}
                                                            columns={this.state.columns}
                                                            systemHealthNotificationGroupGuid={this.state.systemHealthNotificationGroupGuid}
                                                            customUserLicenseRestrictionsObject={this.state.customUserLicenseRestrictionsObject}
                                                        />
                                                    ) : (
                                                        <FeatureLockedSplash className="pt-6" />
                                                    )}
                                                </Tab.Pane>
                                            </Tab.Content>
                                        </div>
                                    </Tab.Container>
                                </DelayedRender>
                            </div>
                        </div>
                        {!isUserRolesReadOnly && (
                            <div className="border-left border-gray h-100 mr-2 p-2 pt-3 flex-grow-0" style={{ width: '295px' }}>
                                <DelayedRender>
                                    <>
                                        <h4 className="mb-4 pl-3">{myStrings.quicklyAssignGroups}</h4>
                                        <GroupsToggleMenu items={this.getGroupsMenuItems(this.state.groups, this.state.userGroups, this.state.currentUser)} onSelectItem={this.onGroupToggle} />
                                    </>
                                </DelayedRender>
                            </div>
                        )}
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="outline-secondary" onClick={() => this.props.onDismiss()} className="mr-0">
                            {Strings.close}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            </>
        );
    }
}
