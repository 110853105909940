import * as React from 'react';
import Strings from '../../../strings';
import { Spinner, Button } from 'react-bootstrap';
import type { IPaymentMethod } from '../../../data/shopping/IPaymentMethodsModel';
import type { ITokenizedApiResult } from '@eway-crm/connector';

type TSetAsActiveLinkProps<TPaymentMethod extends IPaymentMethod> = {
    paymentMethod: TPaymentMethod;
    activateFunc: (paymentMethod: TPaymentMethod) => Promise<ITokenizedApiResult>;
    onActivated: () => void;
};

export const SetAsActiveLink = <TPaymentMethod extends IPaymentMethod,>(props: React.PropsWithChildren<TSetAsActiveLinkProps<TPaymentMethod>>): React.ReactElement => {
    const [isCallInitiated, setIsCallInitiated] = React.useState<boolean>(false);
    const { paymentMethod, activateFunc, onActivated } = props;

    const click = React.useCallback(() => {
        if (isCallInitiated)
            return;
        setIsCallInitiated(true);
    }, [isCallInitiated, setIsCallInitiated]);

    React.useEffect(() => {
        if (!isCallInitiated)
            return;

        (async () => {
            await activateFunc(paymentMethod);
            onActivated();
        })()
            .catch((err) => console.error('Unable to invoke set activating func.', err));
    }, [isCallInitiated, activateFunc, paymentMethod, onActivated]);

    const myStrings = Strings.components.routes.billingDetails;
    return (
        <>
            {isCallInitiated ?
                <Spinner animation="border" size="sm" />
                :
                <Button variant="link" onClick={click}>{myStrings.setAsActive}</Button>
            }
        </>
    );
};