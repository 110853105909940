import { useEffect } from 'react';
import LocalizationHelper from '../../../../helpers/LocalizationHelper';

export const useChatBase = () => {
    useEffect(() => {
        const isCs = LocalizationHelper.isLangCs();
        const chatbotId = isCs ? 'nZkyvAAaEE4MNuuNf1F1E' : 'heUtGA4ntcwAbEW-ujezt';
        const script1: HTMLScriptElement & { chatbotId?: string; domain?: string } = document.createElement('script');
        script1.src = 'https://www.chatbase.co/embed.min.js';
        script1.chatbotId = chatbotId;
        script1.domain = 'www.chatbase.co';
        script1.defer = true;

        const script2 = document.createElement('script');
        script2.innerHTML = `
            window.embeddedChatbotConfig = {
                chatbotId: "${chatbotId}",
                domain: "www.chatbase.co" 
            }
        `;

        document.head.appendChild(script1);
        document.head.appendChild(script2);

        return () => {
            document.head.removeChild(script1);
            document.head.removeChild(script2);
        };
    }, []);
};