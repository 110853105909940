import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import type { IApiGroup } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import type { Sorting } from '@devexpress/dx-react-grid';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import GridTable from '../../GridTable';
import StringHelper from '../../../helpers/StringHelper';
import UserAvatar from '../users/UserAvatar';
import { DelayedRender, mergeStyles } from '@fluentui/react';
import type { TApiItemWithReleations } from '@eway-crm/connector';
import { UserBadgeFlags } from '../users/UserBadgeFlags';
import { ConnectionContext } from '../../../providers/ConnectionProvider';

const iconColumnName = '__icon';
const myStrings = Strings.components.routes.users;

const gridColumns = [
    { name: iconColumnName, title: ' ' },
    { name: 'FileAs', title: myStrings.fullName },
    { name: 'Email1Address', title: myStrings.email },
];

const tableColumnExtensions = [
    { columnName: 'FileAs', wordWrapEnabled: false },
    { columnName: 'Email1Address', wordWrapEnabled: true },
];

const defaultColumnWidths = [
    { columnName: iconColumnName, width: 44 },
    { columnName: 'FileAs', width: 380 },
    { columnName: 'Email1Address', width: 250 },
];

const integratedSortingColumnExtensions = [
    { columnName: 'FileAs', compare: StringHelper.localeCompare },
    { columnName: 'Email1Address', compare: StringHelper.localeCompare },
];

type AffectedUsersProps = {
    onDismiss: () => void;
    affectedUsers: IApiUser[];
    currentGroup: IApiGroup;
    allGroups: IApiGroup[];
    title: string;
    description: string;
};

type AffectedUsersState = {
    sort: Sorting[];
};

class AffectedUsers extends React.Component<AffectedUsersProps, AffectedUsersState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: AffectedUsersProps) {
        super(props);
        this.state = {
            sort: [{ columnName: 'FileAs', direction: 'asc' }],
        };
    }

    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = ({ column, value, row, ...restProps }) => {
        const typedRow = row as TApiItemWithReleations<IApiUser>;
        const typeValue = value as React.ReactChild;
        let inner;
        let tdClass = 'align-middle';
        if (column.name === iconColumnName) {
            tdClass = mergeStyles(tdClass, 'px-0 d-flex justify-content-center');
            inner = <UserAvatar size={32} item={typedRow} />;
        } else {
            inner = typeValue;
        }

        if (column.name === 'FileAs') {
            tdClass = mergeStyles(tdClass, 'highlighted fullName');
        }
        if (!typedRow.IsActive) {
            tdClass = mergeStyles(tdClass, 'disabled');
        }

        const groupNamesList = typedRow.Relations.map((rel) => this.props.allGroups.find((group) => group.ItemGUID === rel.ForeignItemGUID && !group.System)?.GroupName).filter(
            (groupName) => !!groupName && groupName !== this.props.currentGroup.GroupName
        );

        return (
            <VirtualTable.Cell column={column} value={typeValue} row={typedRow} {...restProps} className={tdClass}>
                {column.name === 'FileAs' ? (
                    <div className="w-100 d-inline-flex justify-content-between">
                        <div className="text-truncate">
                            {inner}
                            <UserBadgeFlags item={typedRow} currentUserGuid={this.context.connection.state.session!.userGuid} />
                            {groupNamesList.map((groupName) => (
                                <span key={groupName} className="badge badge-primary ml-1">
                                    {groupName}
                                </span>
                            ))}
                        </div>
                    </div>
                ) : (
                    <div>{inner}</div>
                )}
            </VirtualTable.Cell>
        );
    };

    render() {
        return (
            <WizardModal show={true} onHide={this.props.onDismiss} size="lg">
                <WizardModal.Body>
                    <h1>{this.props.title}</h1>
                    <div className="mb-4">
                        <div className="mt-2">{this.props.description}</div>
                    </div>
                    <div>
                        <DelayedRender>
                            <Grid key="affected-users-grid" rows={this.props.affectedUsers} columns={gridColumns} rootComponent={GridTable.Root} getRowId={(row: IApiUser) => row.ItemGUID}>
                                <SortingState sorting={this.state.sort} onSortingChange={(sort) => this.setState({ sort: sort })} />
                                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                                <VirtualTable
                                    height={450}
                                    containerComponent={GridTable.TableContainerComponent}
                                    cellComponent={this.CellComponent}
                                    messages={{ noData: myStrings.noUsers }}
                                    noDataCellComponent={GridTable.NoDataCell}
                                    columnExtensions={tableColumnExtensions}
                                />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                            </Grid>
                        </DelayedRender>
                    </div>
                </WizardModal.Body>
                <WizardModal.Footer>
                    <Button variant="primary" onClick={this.props.onDismiss}>
                        {Strings.ok}
                    </Button>
                </WizardModal.Footer>
            </WizardModal>
        );
    }
}

export default AffectedUsers;
