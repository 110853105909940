import * as React from 'react';
import type { IStripePaymentMethod } from "../../../../../../data/shopping/IPaymentMethodsModel";
import StoreV2Context from '../../StoreV2Context';
import useBraintreePaymentConfig from './useBraintreePaymentConfig';
import useNoPaymentConfig from './useNoPaymentConfig';
import useStripePaymentConfig from './useStripePaymentConfig';
import type { TSubmitHandler } from './useSubmitHandler';

export type TPaymentConfig = {
    // Stripe with existing cards.
    availableStripeMethods: IStripePaymentMethod[];
    selectedStripeMethod: IStripePaymentMethod;
    onAddNew: () => void;
    onAddNewAndPay: () => void;
    onSelectMethodId: (id: string) => void;
    onPayWithStripeMethodId: ((id: string) => void);
    onPaySimple: null;
    isLoading: boolean;
    renderComponent: JSX.Element | null;
} | {
    // Stripe without existing cards or Braintree
    availableStripeMethods: null;
    selectedStripeMethod: null;
    onAddNew: null;
    onAddNewAndPay: null;
    onSelectMethodId: null;
    onPayWithStripeMethodId: null;
    onPaySimple: () => void;
    isLoading: boolean;
    renderComponent: JSX.Element | null;
};

const usePaymentConfig = (checkEula: () => boolean, showEula: () => void, submitHandler: TSubmitHandler): TPaymentConfig => {
    const { paymentGateProvider, storeData } = React.useContext(StoreV2Context);

    let mode: 'no' | 'st' | 'bt';
    if (storeData.SkipPaymentWhenSubscribing) {
        mode = 'no';
    } else if (paymentGateProvider === 'Stripe') {
        mode = 'st';
    } else if (paymentGateProvider === 'Braintree') {
        mode = 'bt';
    } else {
        throw new Error(`Unknown gate provider '${paymentGateProvider as string}'.`);
    }

    const noPaymentConfig = useNoPaymentConfig(mode === 'no', submitHandler);
    const stripePaymentConfig = useStripePaymentConfig(mode === 'st', checkEula, showEula, submitHandler);
    const braintreePaymentConfig = useBraintreePaymentConfig(mode === 'bt', submitHandler);

    switch (mode) {
        case 'no':
            return noPaymentConfig;
        case 'st':
            return stripePaymentConfig;
        case 'bt':
            return braintreePaymentConfig;
    }
};

export default usePaymentConfig;