import * as React from 'react';
import type { Sorting } from '@devexpress/dx-react-grid';
import { SelectionState, IntegratedSelection, SearchState, IntegratedFiltering, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, TableSelection, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import Strings from '../../../strings';
import StringHelper from '../../../helpers/StringHelper';
import { UserBadgeFlags } from './UserBadgeFlags';
import GridTable from '../../GridTable';
import type { IApiBoundRelation, IApiCapacityAvailableBundle, IApiGroup, ITranslatableString, IApiUser } from '@eway-crm/connector';
import { FolderNames, RelationTypes } from '@eway-crm/connector';
import { Button, Spinner } from 'react-bootstrap';
import { DateHelper } from '../../../helpers/DateHelper';
import { mergeStyles } from '@fluentui/react';
import UserAvatar from './UserAvatar';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import memoizeOne from 'memoize-one';
import GroupBadgeFlags, { GroupBadgeFlagsHelper } from '../../routes/groups/GroupBadgeFlags';

const myStrings = Strings.components.routes.users;

type TApiCapacityAvailableBundleWithTranslations = (IApiCapacityAvailableBundle & { Name: ITranslatableString });

const licenseBundlesToString = (bundles: TApiCapacityAvailableBundleWithTranslations[] | null, isApiUser: boolean): string | null => {
    if (!bundles) {
        if (isApiUser) {
            return myStrings.apiOnly;
        }

        return null;
    }

    return bundles.map(bundle => Strings.pickTranslation(bundle.Name)).join(', ');
};

const compareLicensingBundlesList = (a: TApiCapacityAvailableBundleWithTranslations[] | null, b: TApiCapacityAvailableBundleWithTranslations[] | null) => {
    const compareAtIndex = (i: number) => Math.sign(StringHelper.localeCompare(Strings.pickTranslation(a?.at(i)?.Name), Strings.pickTranslation(b?.at(i)?.Name)));
    return compareAtIndex(0) * 8 + compareAtIndex(1) * 4 + compareAtIndex(2) * 2 + compareAtIndex(3);
};

const columns = {
    icon: {
        name: '__icon'
    },
    name: {
        name: 'FileAs'
    },
    username: {
        name: 'Username'
    },
    email: {
        name: 'Email1Address'
    },
    groups: {
        name: 'Relations'
    },
    licenses: {
        name: 'Server_LicensingBundlesList'
    },
    lastLogin: {
        name: 'Server_LastLogin'
    },
    lastActivity: {
        name: 'Server_LastActivity'
    }
};

const tableColumnExtensions = [
    { columnName: columns.name.name, wordWrapEnabled: false },
    { columnName: columns.email.name, wordWrapEnabled: true }
];
const defaultColumnWidths = [
    { columnName: columns.icon.name, width: 44 },
    { columnName: columns.name.name, width: 290 },
    { columnName: columns.username.name, width: 200 },
    { columnName: columns.email.name, width: 200 },
    { columnName: columns.groups.name, width: 150 },
    { columnName: columns.licenses.name, width: 100 },
    { columnName: columns.lastLogin.name, width: 160 },
    { columnName: columns.lastActivity.name, width: 160 }
];
const integratedSortingColumnExtensions = [
    { columnName: columns.name.name, compare: StringHelper.localeCompare },
    { columnName: columns.lastLogin.name, compare: DateHelper.compareUniversals },
    { columnName: columns.lastActivity.name, compare: DateHelper.compareUniversals },
    { columnName: columns.licenses.name, compare: compareLicensingBundlesList }
];
const gridColumnsCard = [
    { name: columns.icon.name, title: ' ' },
    { name: columns.name.name, title: myStrings.fullName },
    { name: columns.lastLogin.name, title: myStrings.lastLogin },
    { name: columns.lastActivity.name, title: myStrings.lastActivity }
];

type TVariant = 'full' | 'card';

type TUsersGridProps = {
    variant: TVariant;
    data: IApiUser[];
    searchedString: string;
    onSearchStringChange?: (searchedString: string) => void;
    selection: string[];
    onSelectionChange?: (selection: React.ReactText[]) => void;
    loadingEdittedUserItemGuid: string | null;
    onEditUser?: (user: IApiUser) => void;
    visibleGroups: IApiGroup[];
};

type TUsersGridState = {
    sort: Sorting[];
};

export class UsersGrid extends React.Component<TUsersGridProps, TUsersGridState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TUsersGridProps) {
        super(props);
        const defaultSorting: Sorting = (props.variant === 'full' ? { columnName: columns.username.name, direction: 'asc' } : { columnName: columns.lastActivity.name, direction: 'desc' });
        this.state = {
            sort: [defaultSorting]
        };
    }

    private readonly getGridColumnsFull = memoizeOne((isAzureAuth: boolean) => {
        return [
            { name: columns.icon.name, title: ' ' },
            { name: columns.name.name, title: myStrings.fullName },
            { name: columns.username.name, title: isAzureAuth ? myStrings.msAzureAccount : myStrings.username },
            { name: columns.email.name, title: myStrings.email },
            { name: columns.groups.name, title: myStrings.groups },
            { name: columns.licenses.name, title: myStrings.license },
            { name: columns.lastLogin.name, title: myStrings.lastLogin },
            { name: columns.lastActivity.name, title: myStrings.lastActivity }
        ];
    });

    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = ({ column, value, row, ...restProps }) => {
        const typedRow = row as IApiUser;
        const typedValue = value as React.ReactChild | null;
        let inner;
        let tdClass = 'align-middle';
        let innerWrapClass = '';
        if (column.name === columns.icon.name) {
            tdClass = mergeStyles(tdClass, 'px-0');
            innerWrapClass = mergeStyles(innerWrapClass, 'd-flex justify-content-center');
            inner = (
                <UserAvatar size={32} item={typedRow} />
            );
        } else if (column.name === columns.lastLogin.name || column.name === columns.lastActivity.name) {
            inner = Strings.formatDateTime(typedValue as string);
        } else if (column.name === columns.licenses.name) {
            inner = licenseBundlesToString(typedValue as (TApiCapacityAvailableBundleWithTranslations[] | null), typedRow.IsApiUser);
        } else if (column.name === columns.groups.name) {
            const groups = (typedValue as IApiBoundRelation[] | null)
                ?.filter(r => r.ForeignFolderName === FolderNames.groups && r.RelationType === RelationTypes.group)
                .map(r => this.props.visibleGroups.find(g => g.ItemGUID === r.ForeignItemGUID))
                .filter(g => !!g)
                .sort((a, b) => StringHelper.localeCompare(a?.GroupName, b?.GroupName)) as IApiGroup[];
            if (groups?.length) {
                inner = (
                    <>
                        {groups.map((g, i, arr) => (
                            <span key={g.ItemGUID}>
                                {g?.GroupName}
                                {(GroupBadgeFlagsHelper.hasAnyBadge(g, typedRow.Groups_Default_GroupGuid)) &&
                                    <GroupBadgeFlags item={g} defaultGroupGuid={typedRow.Groups_Default_GroupGuid ?? undefined} />
                                }
                                {(i !== (arr.length - 1)) &&
                                    <>, </>
                                }
                            </span>
                        ))}
                    </>
                );
            } else {
                inner = null;
            }
        } else {
            inner = typedValue;
        }

        if (column.name === 'FileAs') {
            tdClass = mergeStyles(tdClass, 'highlighted fullName');
        }
        if (!typedRow.IsActive) {
            tdClass = mergeStyles(tdClass, 'disabled');
        }

        return (
            <VirtualTable.Cell
                column={column}
                value={typedValue}
                row={typedRow}
                {...restProps}
                className={tdClass}
            >
                {column.name === 'FileAs' ?
                    <div className="w-100 d-inline-flex justify-content-between">
                        <div className="text-truncate">{inner}</div>

                        {this.props.loadingEdittedUserItemGuid === typedRow.ItemGUID ?
                            <div style={{ minHeight: "21px" }} className="ml-2 pr-1 d-inline-flex">
                                <Spinner style={{ margin: 'auto 0' }} animation="border" size="sm" />
                            </div>
                            :
                            <>
                                {!this.props.loadingEdittedUserItemGuid && this.props.variant === 'full' && (
                                    <Button
                                        style={{ width: '21px' }}
                                        variant="link"
                                        onClick={(e) => {
                                            e.stopPropagation();
                                            this.props.onEditUser && this.props.onEditUser(typedRow);
                                        }}
                                        className="p-0 ml-2 border-0 invisible d-parent-tr-hover-visiblie"
                                    >
                                        <i className="mdl2 mdl2-edit" aria-hidden="true" />
                                    </Button>
                                )}
                            </>
                        }
                    </div>
                    :
                    <div className={innerWrapClass}>
                        {inner}
                        {column.name === columns.username.name &&
                            <UserBadgeFlags item={typedRow} currentUserGuid={this.context.connection.state.session!.userGuid} />
                        }
                    </div>
                }
            </VirtualTable.Cell>
        );
    };

    private readonly RowComponent: React.FC<TableSelection.RowProps> = (props) => {
        const onEdit = (row: IApiUser) => {
            this.props.onEditUser && this.props.onEditUser(row);
        };

        return <GridTable.RowComponent {...props} onEdit={onEdit} />;
    };

    render() {
        const noUsersString = this.props.searchedString ? myStrings.noUsersMatch : myStrings.noUsers;
        return (
            <Grid
                key="license-matrix-grid"
                rows={this.props.data}
                columns={this.props.variant === 'full' ? this.getGridColumnsFull(this.context.authSettings.isAzureAuth) : gridColumnsCard}
                rootComponent={this.props.variant === 'card' ? GridTable.RootGray : GridTable.Root}
                getRowId={(row: IApiUser) => row.ItemGUID}
            >
                {(this.props.variant === 'full') &&
                    <SelectionState
                        key="license-matrix-grid-searchstate"
                        selection={this.props.selection}
                        onSelectionChange={this.props.onSelectionChange}
                    />
                }
                <SearchState
                    value={this.props.searchedString}
                    onValueChange={this.props.onSearchStringChange}
                />
                <SortingState
                    sorting={this.state.sort}
                    onSortingChange={sort => this.setState({ sort: sort })}
                    columnSortingEnabled={this.props.variant === 'full'}
                />
                {(this.props.variant === 'full') &&
                    <IntegratedSelection />
                }
                <IntegratedFiltering />
                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                <VirtualTable
                    containerComponent={GridTable.TableContainerComponent}
                    columnExtensions={tableColumnExtensions}
                    cellComponent={this.CellComponent}
                    messages={{ noData: noUsersString }}
                    noDataCellComponent={GridTable.NoDataCell}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                {(this.props.variant === 'full') &&
                    <TableSelection
                        showSelectAll
                        selectByRowClick
                        highlightRow
                        rowComponent={this.RowComponent}
                    />
                }
            </Grid>
        );
    }
}