import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiGroup } from '@eway-crm/connector';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TDeleteGroupWizardProps = {
    onDismiss: () => void;
    selectedGroups: IApiGroup[];
    onDone: () => void;
};

type TDeleteGroupWizardState = {
    isSavingInProgress: boolean;
};

export class DeleteGroupWizard extends React.Component<TDeleteGroupWizardProps, TDeleteGroupWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TDeleteGroupWizardProps) {
        super(props);
        this.state = {
            isSavingInProgress: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                this.context.connection.callApi(
                    'DeleteGroups',
                    {
                        itemGuids: this.props.selectedGroups.map((grp) => grp.ItemGUID)
                    },
                    (_) => {
                        this.props.onDone();
                    }
                );
            }
        );
    };

    render() {
        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            const myStrings = Strings.components.routes.groups;

            let title;
            let message;
            if (this.props.selectedGroups.length === 1) {
                title = myStrings.deleteGroupSingularTitle;
                message = myStrings.deleteGroupSingularMessage;
            } else {
                title = myStrings.deleteGroupsPluralTitle;
                message = myStrings.deleteGroupsPluralMessage;
            }

            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{title}</h1>
                        <div className="mb-4">
                            {this.props.selectedGroups.map((grp) =>
                                <span key={'del-gropu-' + grp.ItemGUID} className="badge badge-info badge-full-font mr-1">
                                    {grp.GroupName}
                                </span>
                            )}
                        </div>
                        <div>
                            {message}
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="danger" onClick={this.submit}>{Strings.delete}</Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}