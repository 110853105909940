import React from 'react';
import ModulePermissionsGrid from '../modulePermissions/ModulePermissionsGrid';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import type { IApiGroup, TFolderName } from '@eway-crm/connector';
import GroupsListMenu from '../../shared/GroupsListMenu';
import splashImg from '../../../img/splash/key-splash.svg';
import type { TFeature } from '../Features';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { TooltipHost } from '@fluentui/react';
import LicenseRestrictionsTooltipContent from '../../shared/locks/LicenseRestrictionsTooltipContent';
import { Spinner } from '@eway-crm/gui';

const GRID_HEIGHT = '26rem';

type TFeatureModulePermissionsTabProps = {
    searchedString: string;
    selectedFeature: TFeature;
    onModulePermissionChange: (rowId: string, column: string, newValue: string | number | boolean | null, groupGuid: string) => void;
    modulePermissions: TModulePermissionWithFolder[] | null;
    groups: IApiGroup[] | null;
    onMount: () => Promise<void>;

};

type TFeatureModulePermissionsTabState = {
    selectedGroup: IApiGroup | null;
};

export default class FeatureModulePermissionsTab extends React.Component<TFeatureModulePermissionsTabProps, TFeatureModulePermissionsTabState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TFeatureModulePermissionsTabProps) {
        super(props);
        this.state = {
            selectedGroup: null,
        };
    }

    componentDidMount() {
        void this.props.onMount();
    }


    private readonly onModulePermissionChange = (rowId: string, column: string, newValue: string | number | boolean | null) => {
        const groupGuid = this.state.selectedGroup?.ItemGUID;
        if (groupGuid) {
            this.props.onModulePermissionChange(rowId, column, newValue, groupGuid);
        }
    };

    private readonly getCellWrapper = () => {
        const { isModulePermissionsReadOnly, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsReadOnly();

        if (!isModulePermissionsReadOnly) {
            // Do not render any wrappers
            return undefined;
        }

        return ({ children }: { children?: React.ReactNode }) => (
            <TooltipHost content={<LicenseRestrictionsTooltipContent licenseRestriction={modulePermissionsLicenseRestriction} />}>{children}</TooltipHost>
        );
    };

    render() {
        const { isModulePermissionsReadOnly } = this.context.licenseRestrictionsHelper.isModulePermissionsReadOnly();

        if (!this.props.groups || !this.props.modulePermissions) {
            return <Spinner />;
        }

        return (
            <div className="d-flex flex-fill">
                <div className="border-right border-gray tr-cursor-hover" style={{ width: '265px' }}>
                    <GroupsListMenu
                        items={this.props.groups.map((g) => ({ itemId: g.ItemGUID, itemName: g.GroupName, itemDescription: g.ResponsibilityDescription }))}
                        onItemChange={(newItemId) => {
                            this.setState({ selectedGroup: this.props.groups!.find((g) => g.ItemGUID === newItemId) ?? null });
                        }}
                        selectedItemId={this.state.selectedGroup?.ItemGUID ?? null}
                        searchedString={''}
                        isCollapsed={false}
                    />
                </div>
                {!this.state.selectedGroup ? (
                    <div className="d-flex flex-column flex-fill position-relative">
                        <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img className="d-inline-block" src={splashImg} style={{ height: '160px', width: '100%' }} alt="" />
                        </div>
                    </div>
                ) : (
                    <div className="row flex-fill d-flex justify-content-start mt-2 mx-0">
                        <div className="col p-0">
                            <div className="h-100 position-absolute" style={{ maxHeight: '100%', minHeight: '0', top: '0', bottom: '0', left: '0', right: '0' }}>
                                <ModulePermissionsGrid
                                    gridHeight={GRID_HEIGHT}
                                    modulePermissions={this.props.modulePermissions.filter(
                                        (mp) =>
                                            mp.GroupGuid === this.state.selectedGroup!.ItemGUID &&
                                            this.props.selectedFeature.associatedFolderNames.includes(mp.FolderName) &&
                                            !this.context.licenseRestrictionsHelper.isFolderNameHidden(mp.FolderName as TFolderName)
                                    )}
                                    onModulePermissionChange={this.onModulePermissionChange}
                                    searchedString={this.props.searchedString}
                                    disabled={this.state.selectedGroup.DisallowControlModulePermissions || isModulePermissionsReadOnly}
                                    cellWrapper={this.getCellWrapper()}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}
