import * as React from 'react';
import type { ICheckoutSubmittedModel } from '../../../../../../data/shopping/ICheckoutSubmittedModel';
import type { TPeriodType } from '../../../../../../data/shopping/ISubscriptionsShoppingModel';
import { ConnectionContext } from '../../../../../../providers/ConnectionProvider';
import type { Connection } from '../../../../../layout/connection/Connection';
import type { ShoppingConnection } from '../../../../../ShoppingConnection';
import type { TSubmitDataCreator } from '../../../CheckoutSubmitter';
import CheckoutSubmitter from '../../../CheckoutSubmitter';
import type TCheckoutItem from '../../../TCheckoutItem';
import type { TCustomerInfo } from '../../../TCustomerInfo';
import StoreV2Context from '../../StoreV2Context';

export type TSubmitHandler = {
    handleFinisherInitialized: (finishDelegate: () => void) => void;
    handlePaymentMethodConfirmed: (creator: TSubmitDataCreator) => void;
    handleFinisherUnloaded: () => void;
    handleSubmitClick: () => void;
    isSubmitted: boolean;
    checkoutSubmittedModel: ICheckoutSubmittedModel | null;
};

const useSubmitHandler = (items: TCheckoutItem[], eulaRef: React.RefObject<HTMLDivElement>): TSubmitHandler => {
    const { shoppingConnection, customerInfo, selectedPeriodType } = React.useContext(StoreV2Context);
    const { connection } = React.useContext(ConnectionContext);
    const finisherDelegateRef = React.useRef<(() => void) | null>(null);

    const handleFinisherInitialized = React.useCallback((finishDelegate: () => void) => {
        finisherDelegateRef.current = finishDelegate;
    }, [finisherDelegateRef]);

    const handleFinisherUnloaded = React.useCallback(() => {
        finisherDelegateRef.current = null;
    }, [finisherDelegateRef]);

    const [isSubmitted, setIsSubmitted] = React.useState(false);
    const [checkoutSubmittedModel, setCheckoutSubmittedModel] = React.useState<ICheckoutSubmittedModel | null>(null);

    const handlePaymentMethodConfirmed = React.useCallback((creator: TSubmitDataCreator) => {
        // We have to extract the eula html before we call setIsSubmitted(true), because setting state invalidates ref objects.
        let eulaHtml: string | null = null;
        if (eulaRef.current) {
            eulaHtml = eulaRef.current.innerHTML;
        } else {
            console.error('Unable to get eula html. Missing eula ref.', eulaRef);
            return;
        }

        setIsSubmitted(true);

        const handleSubmitResultUpdate = (submitResult: ICheckoutSubmittedModel, callback?: () => void) => {
            setCheckoutSubmittedModel(submitResult);
            if (callback) {
                window.setTimeout(callback, 100);
            }
        };

        submit(connection, shoppingConnection, customerInfo, eulaHtml, items, selectedPeriodType, creator, handleSubmitResultUpdate)
            .catch((err) => console.error('Caught error when submitting the checkout.', err));
    }, [setIsSubmitted, connection, shoppingConnection, customerInfo, eulaRef, items, selectedPeriodType]);

    const handleSubmitClick = React.useCallback(() => {
        if (!finisherDelegateRef.current) {
            console.error('Unable to submit checkout. The finisher is unloaded.');
            return;
        }

        finisherDelegateRef.current();
    }, [finisherDelegateRef]);

    return {
        handleFinisherInitialized,
        handlePaymentMethodConfirmed,
        handleFinisherUnloaded,
        handleSubmitClick,
        isSubmitted,
        checkoutSubmittedModel
    };
};

const submit = async (
    connection: Connection,
    shoppingConnection: ShoppingConnection,
    customerInfo: TCustomerInfo,
    eulaHtml: string | null,
    items: TCheckoutItem[],
    selectedPeriodType: TPeriodType,
    dataCreator: TSubmitDataCreator,
    handleSubmitResultUpdate: (status: ICheckoutSubmittedModel, nextSteps?: () => void) => void
) => {
    if (!eulaHtml) {
        console.error('Unable to submit checkout. Empty eula html.');
        return;
    }

    // Set state and submit to server.
    const products = items.map((item) => {
        return {
            Artno: item.packageData.Artno!,
            Amount: item.quantity
        };
    });

    // If a EK product is in the cart, make sure we have EK account.
    if (items.some(item => item.packageData.RequiresEmailkampaneczAccount)) {
        if (customerInfo.emailAddress && customerInfo.country) {
            await connection.askApi(
                'CreateEmailCampaignAccount',
                {
                    email: customerInfo.emailAddress,
                    countryCode: customerInfo.country
                }
            );
        }
    }

    const submitter = new CheckoutSubmitter(shoppingConnection, handleSubmitResultUpdate);
    const data = dataCreator(customerInfo, products, eulaHtml, selectedPeriodType);
    submitter.submit(data);
};


export default useSubmitHandler;