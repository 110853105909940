import { FolderNamesHelper } from '@eway-crm/gui';
import { Icon, mergeStyles, TooltipHost } from '@fluentui/react';
import React from 'react';
import { Form } from 'react-bootstrap';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import type { OptionalExceptFor } from '../../../data/typeHelpers/OptionalExceptFor';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import Toggle from '../../shared/Toggle';
import type { TFieldPermissionWithCol } from './FieldPermissionsGrid';
import FieldsLicenseRestrictionHelper from './FieldsLicenseRestrictionHelper';
import type { TFieldWithCol } from './FolderFields';
import type { TFieldPermissionWithName } from './NewAdditionalFieldWizard';

const myStrings = Strings.components.routes.fields;
const myPermissionStrings = Strings.components.routes.fieldPermissions;

type TGeneralFieldPermissionTogglesProps = Pick<React.HTMLProps<HTMLDivElement>, 'className' | 'style'> & {
    systemGroupFieldPermissions: OptionalExceptFor<TFieldPermissionWithCol, 'MandatoryRule' | 'PermissionRule' | 'IsMandatoryRuleEditable' | 'IsPermissionRuleEditable'>;
    onSystemGroupChange: (permissionName: string) => void;
    isNewField: boolean;
    isDisabled: boolean;
    allFields: TFieldWithCol[];
    folderName: string;
};

const changePermissionIfAllowed = (newPermission: string, oldPermission: string, isEditable: boolean, allowedPermissionTypes: string[] | null ) => {
    if (isEditable && (allowedPermissionTypes === null || allowedPermissionTypes?.includes(newPermission))) {
        return newPermission;
    }
    return oldPermission;
};

export default class GeneralFieldPermissionToggles extends React.Component<TGeneralFieldPermissionTogglesProps> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    static readonly onSystemGroupChange = (permissionName: string, systemGroupPermissions: TFieldPermissionWithName, setFieldToUnique?: () => void) => {
        let permissionRule = systemGroupPermissions.PermissionRule;
        let mandatoryRule = systemGroupPermissions.MandatoryRule;

        if (permissionName === FieldPermissionNames.MandatoryRuleKeys.Mandatory) {
            if (systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory || systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique) {
                // Turn off
                mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.None;
            } else {
                if (
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Invisible ||
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.None ||
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Readonly
                ) {
                    permissionRule = FieldPermissionNames.PermissionRuleKeys.All;
                }

                mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.Mandatory;
            }
        } else if (permissionName === FieldPermissionNames.MandatoryRuleKeys.Optional) {
            if (systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional) {
                // Turn off
                mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.None;
            } else {
                if (
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Invisible ||
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.None ||
                    systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Readonly
                ) {
                    permissionRule = FieldPermissionNames.PermissionRuleKeys.All;
                }
                mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.Optional;
            }
        } else if (permissionName === FieldPermissionNames.MandatoryRuleKeys.Unique) {
            if (systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique) {
                // Turn off
                mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.Mandatory;
            } else {
                setFieldToUnique && setFieldToUnique();
                return;
            }
        } else if (permissionName === FieldPermissionNames.PermissionRuleKeys.Readonly) {
            if (systemGroupPermissions.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Readonly) {
                // Turn off
                permissionRule = FieldPermissionNames.PermissionRuleKeys.All;
            } else {
                permissionRule = FieldPermissionNames.PermissionRuleKeys.Readonly;
                if (
                    systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique ||
                    systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory ||
                    systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional
                ) {
                    mandatoryRule = FieldPermissionNames.MandatoryRuleKeys.None;
                }
            }
        }

        return {
            permissionRule: changePermissionIfAllowed(
                permissionRule,
                systemGroupPermissions.PermissionRule,
                systemGroupPermissions.IsPermissionRuleEditable,
                systemGroupPermissions.col?.AllowedColumnPermissionTypes ?? null
            ),
            mandatoryRule: changePermissionIfAllowed(
                mandatoryRule,
                systemGroupPermissions.MandatoryRule,
                systemGroupPermissions.IsMandatoryRuleEditable,
                systemGroupPermissions.col?.AllowedColumnMandatoryTypes ?? null
            ),
        };
    };

    private readonly getIsMandatoryRuleDisabled = (permissionName: keyof typeof FieldPermissionNames.MandatoryRuleKeys) => {
        if (this.props.isNewField) {
            return false;
        }

        const systemFp = this.props.systemGroupFieldPermissions;
        let isDisabled = !systemFp.IsMandatoryRuleEditable;
        if (permissionName === FieldPermissionNames.MandatoryRuleKeys.Unique) {
            isDisabled = isDisabled || systemFp.col?.IsUniqueConstraintSupported === false;
        }
        if (systemFp.col?.AllowedColumnMandatoryTypes) {
            isDisabled = isDisabled || !systemFp.col?.AllowedColumnMandatoryTypes?.includes(permissionName);
        }
        return isDisabled;
    };

    private readonly getIsPermissionRuleDisabled = (permissionName: keyof typeof FieldPermissionNames.PermissionRuleKeys) => {
        if (this.props.isNewField) {
            return false;
        }

        const systemFp = this.props.systemGroupFieldPermissions;
        let isDisabled = !systemFp.IsPermissionRuleEditable;

        if (systemFp.col?.AllowedColumnPermissionTypes) {
            isDisabled = !systemFp.col?.AllowedColumnPermissionTypes?.includes(permissionName);
        }
        return isDisabled;
    };

    private readonly onToggle = (permissionKey: string, newChecked: boolean) => {
        const fieldsLicenseLimitsHelper = new FieldsLicenseRestrictionHelper(this.context.licenseRestrictionsHelper.licenseRestrictions, this.props.allFields, this.props.isNewField ? null : this.props.systemGroupFieldPermissions.ColumnName);

        const { canContinue, appliedLicenseRestriction } = fieldsLicenseLimitsHelper.onRuleToggle(permissionKey, newChecked);
        
        if (!canContinue) {
            let modalTitle;
            if (permissionKey === FieldPermissionNames.MandatoryRuleKeys.Mandatory) {
                modalTitle = Strings.components.licenseRestrictions.mandatoryFieldsCustomTitle;
            } else if (permissionKey === FieldPermissionNames.MandatoryRuleKeys.Optional) {
                modalTitle = Strings.components.licenseRestrictions.importantFieldsCustomTitle;
            }

            this.context.showLicenseRestrictionModal(appliedLicenseRestriction!, { customTitle: modalTitle });
            return;
        }

        this.props.onSystemGroupChange(permissionKey);
    };

    render() {
        const systemFp = this.props.systemGroupFieldPermissions;
        const { isMandatoryFieldsLocked, mandatoryFieldsLicenseRestriction } = this.context.licenseRestrictionsHelper.isMandatoryFieldsLocked();
        const { isImportantFieldsLocked, importantFieldsLicenseRestriction } = this.context.licenseRestrictionsHelper.isImportantFieldsLocked();
        const { isUniqueFieldsLocked, uniqueFieldsLicenseRestriction } = this.context.licenseRestrictionsHelper.isUniqueFieldsLocked();
        const { isReadOnlyFieldsLocked, readonlyFieldsLicenseRestriction } = this.context.licenseRestrictionsHelper.isReadOnlyFieldsLocked();

        const toggles = [
            {
                name: FieldPermissionNames.MandatoryRuleKeys.Mandatory,
                title: myPermissionStrings.columns.isMandatoryTitle,
                tooltip: (
                    <>
                        {myStrings.toggles.isRequiredTooltip}
                        <br />
                        <br />
                        {Strings.formatString(myStrings.toggles.generalTooltip, <b>{myPermissionStrings.columns.isMandatoryTitle.toLowerCase()}</b>)}
                    </>
                ),
                isLocked: isMandatoryFieldsLocked,
                licenseRestriction: mandatoryFieldsLicenseRestriction,
                checked: systemFp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory || systemFp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique,
                disabled: this.getIsMandatoryRuleDisabled(FieldPermissionNames.MandatoryRuleKeys.Mandatory),
            },
            {
                name: FieldPermissionNames.MandatoryRuleKeys.Optional,
                title: myPermissionStrings.columns.isOptionalTitle,
                tooltip: (
                    <>
                        {myStrings.toggles.isImportantTooltip}
                        <br />
                        <br />
                        {Strings.formatString(myStrings.toggles.generalTooltip, <b>{myPermissionStrings.columns.isOptionalTitle.toLowerCase()}</b>)}
                    </>
                ),
                isLocked: isImportantFieldsLocked,
                licenseRestriction: importantFieldsLicenseRestriction,
                checked: systemFp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional,
                disabled: this.getIsMandatoryRuleDisabled(FieldPermissionNames.MandatoryRuleKeys.Optional),
            },
            {
                name: FieldPermissionNames.MandatoryRuleKeys.Unique,
                title: myPermissionStrings.columns.isUniqueTitle,
                tooltip: <div style={{ whiteSpace: "pre-wrap" }}>
                    {Strings.formatString(myStrings.toggles.isUniqueTooltip, <b>{myPermissionStrings.columns.isUniqueTitle}</b>)}
                    <div className={"pl-2 pt-1"}>{Strings.formatString(myStrings.toggles.isUniqueTooltipList, FolderNamesHelper.getPluralName(this.props.folderName))}</div>
                    <div className={"pt-2"}><strong>{myStrings.toggles.isUniqueTooltipNote}</strong> {myStrings.toggles.isUniqueTooltipNoteText}</div>
                </div>,
                isLocked: isUniqueFieldsLocked,
                licenseRestriction: uniqueFieldsLicenseRestriction,
                checked: systemFp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique,
                disabled: this.getIsMandatoryRuleDisabled(FieldPermissionNames.MandatoryRuleKeys.Unique) || this.props.isNewField,
            },
            {
                name: FieldPermissionNames.PermissionRuleKeys.Readonly,
                title: myPermissionStrings.options.readonlyPermissionsDescription,
                tooltip: (
                    <>
                        {myStrings.toggles.isReadOnlyTooltip}
                        <br />
                        <br />
                        {Strings.formatString(myStrings.toggles.generalTooltip, <b>{myPermissionStrings.options.readonlyPermissionsDescription.toLowerCase()}</b>)}
                    </>
                ),
                isLocked: isReadOnlyFieldsLocked,
                licenseRestriction: readonlyFieldsLicenseRestriction,
                checked: systemFp.PermissionRule === FieldPermissionNames.PermissionRuleKeys.Readonly,
                disabled: this.getIsPermissionRuleDisabled(FieldPermissionNames.PermissionRuleKeys.Readonly),
            },
        ];

        const onLabelClick = (e: React.MouseEvent<HTMLLabelElement, MouseEvent>) => {
            // Do not trigger feature lock click when clicking on label
            e.preventDefault();
        };

        return (
            <div className={mergeStyles('d-flex flex-wrap', this.props.className)} style={{ maxWidth: '450px', ...this.props.style }}>
                {toggles.map((toggle) => (
                    <Form.Group className="w-50" key={toggle.name}>
                        <Form.Label style={{ height: '1.5rem' }} onClick={onLabelClick}>
                            {toggle.title}
                            <TooltipHost content={toggle.tooltip} hostClassName="pl-1">
                                <Icon iconName="Info" aria-label="Info tooltip" />
                            </TooltipHost>
                            {toggle.isLocked && <LicenseRestrictionsLockIcon licenseRestriction={toggle.licenseRestriction!} isSmall={false} className="pl-1" />}
                        </Form.Label>
                        <Toggle
                            checked={toggle.checked}
                            disabled={this.props.isDisabled || toggle.disabled || (toggle.isLocked && !toggle.checked)}
                            label={toggle.checked ? Strings.on : Strings.off}
                            onChange={() => {
                                void this.onToggle(toggle.name, !toggle.checked);
                            }}
                            className="justify-content-start"
                        />
                    </Form.Group>
                ))}
            </div>
        );
    }
}
