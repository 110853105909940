import { EnumTypes } from "../../../data/constants/EnumTypes";
import type { IApiEnumValue } from "@eway-crm/connector";
import type { RemoteItemStore } from "../../../RemoteItemStore";

export type TCountriesAndRegions = TCountry[];

type TCountry = {
    countryEv: IApiEnumValue;
    regions: IApiEnumValue[] | null;
};

export const loadCountriesAndRegions = async (remoteStore: RemoteItemStore): Promise<TCountriesAndRegions> => {
    const countriesData = (await remoteStore.askForEnumValues(EnumTypes.countryCode)).Data;
    const usStatesData = (await remoteStore.askForEnumValues(EnumTypes.usStatesDistrictsTerritories)).Data;
    return countriesData.map(countryEv => ({ countryEv, regions: (countryEv.FileAs === 'US' ? usStatesData : null) }));
};