import { mergeStyles } from '@fluentui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import padlockSplash from '../../../img/splash/padlock-splash.svg';
import RouteConfig from '../../../RouteConfig';
import Strings from '../../../strings';
import './locks.scss';

const myStrings = Strings.lockedContent;
type TFeatureLockedSplashProps = React.HTMLProps<HTMLDivElement>;

const FeatureLockedSplash: React.FC<TFeatureLockedSplashProps> = ({ className, ...restProps }) => {
    return (
        <div className={mergeStyles('h-100 position-relative flex-fill d-flex flex-column align-items-center justify-content-center', className)} {...restProps}>
            <img className="locks__padlockSplash" src={padlockSplash} alt="padlock" />
            <h2 className="text-secondary text-center pt-2 mb-2">{myStrings.title}</h2>
            <p>{myStrings.text}</p>
            <div>
                <Link to={RouteConfig.billing.subscriptions.path} className="d-block text-center pt-2">
                    <Button>{myStrings.buttonText}</Button>
                </Link>
            </div>
        </div>
    );
};

export default FeatureLockedSplash;
