import { autosuggestContext } from './AutosuggestContext';
import './Autosuggest.scss';
import { FontIcon, mergeStyles } from '@fluentui/react';
import { forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useDetectDismiss } from '@eway-crm/gui';

export const NEW_SUGGESTION_KEY = 'NEW_SUGGESTION_KEY';

export type TAutosuggestItem = {
    key: string;
    value: string;
};

export type TAutosuggestRefHandle = Pick<HTMLDivElement, 'focus'>;

type TAutosuggestProps = React.HTMLProps<HTMLDivElement> & {
    value: string;
    onValueChange: (newValue: string) => void;
    onValueSelection: (newValue: string) => void;
    suggestions: TAutosuggestItem[];
    allowCreatingNewItems?: boolean;
    shouldShowSuggestionsWhenEmpty?: boolean;
    showChevron?: boolean;
    invalid?: boolean;
};

const { Provider } = autosuggestContext;

const Autosuggest = forwardRef<TAutosuggestRefHandle, TAutosuggestProps>(
    ({ value, onValueChange, onValueSelection, suggestions, allowCreatingNewItems, showChevron, invalid, children, className, disabled, ...restProps }, forwardedRef) => {
        const [isInputFocused, setIsInputFocused] = useState(false);

        const onDetectClickOutside = useCallback(() => {
            setIsInputFocused(false);
        }, []);

        const { ref: autosuggestRef, isOpen: isSuggestOpen, setIsOpen: setIsSuggestOpen } = useDetectDismiss(false, onDetectClickOutside);
        const inputRef = useRef<HTMLInputElement>(null);

        useImperativeHandle(forwardedRef, () => ({
            focus: () => inputRef.current?.focus(),
        }));

        useEffect(() => {
            if (isInputFocused) {
                inputRef.current?.focus();
                setIsSuggestOpen(true);
            } else {
                inputRef.current?.blur();
            }
        }, [isInputFocused, setIsSuggestOpen]);

        const handleValueSelection = (newValue: string) => {
            onValueChange(newValue);
            onValueSelection(newValue);
            setIsSuggestOpen(false);
            setIsInputFocused(false);
        };

        const handleValueChange = (newValue: string) => {
            setIsSuggestOpen(true);
            onValueChange(newValue);
        };

        const createNewItem =
            allowCreatingNewItems && !invalid && value && suggestions.every((suggestion) => suggestion.value.toLowerCase() !== value.toLowerCase()) ? [{ key: NEW_SUGGESTION_KEY, value: value }] : [];
        const filteredSuggestions = [...createNewItem, ...suggestions.filter((suggestion) => suggestion.value.toLowerCase().includes(value.toLowerCase()))];

        return (
            <div
                className={mergeStyles('autosuggest', className, isInputFocused && 'is-focused', invalid && 'is-invalid', disabled && 'is-disabled')}
                onClick={() => {
                    !isInputFocused && setIsInputFocused(true);
                }}
                ref={autosuggestRef}
                {...restProps}
            >
                <Provider
                    value={{
                        isInputFocused,
                        inputRef,
                        setIsInputFocused,
                        isSuggestOpen,
                        setIsSuggestOpen,
                        value,
                        onValueChange: handleValueChange,
                        suggestions: filteredSuggestions,
                        onValueSelection: handleValueSelection,
                        disabled
                    }}
                >
                    {children}
                    {showChevron && <FontIcon iconName="ChevronDown" className="px-2" style={{ cursor: 'pointer' }} />}
                </Provider>
            </div>
        );
    }
);

export default Autosuggest;
