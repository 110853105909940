import ExternalLinks from '../../../../data/constants/ExternalLinks';
import RouteConfig from '../../../../RouteConfig';
import Strings from '../../../../strings';
import type { TPageBase } from './SearchResultsObtainer';
import administrationSettingsImg from '../../../../img/administrationSettings.svg';
import powerBiImg from '../../../../img/search/PowerBI.svg';
import zapierImg from '../../../../img/search/Zapier.svg';

const myStrings = Strings.components;

export const lowerCaseBasedOnLang = (string: string) => {
    return Strings.getLanguage() === 'en' ? string : string.toLowerCase();
};

export enum SearchAction {
    Manage,
    Add,
    Other,
    Other2,
}

export const getSearchPages = (isLegacyAdministrationLinkHidden: boolean): TPageBase[] => {
    const searchPages: TPageBase[] = [
        {
            group: `${myStrings.sideMenu.users}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.search.users)}`,
            link: RouteConfig.users.path,
            iconName: 'Contact',
            keyWords: ['Users'],
            synonyms: [myStrings.search.synonyms.user, myStrings.search.synonyms.edit],
        },
        {
            group: `${myStrings.sideMenu.users}`,
            action: SearchAction.Add,
            nameGetter: (strings) => `${strings.components.search.usersGroupAddNew}`,
            link: `${RouteConfig.users.new.path}/1`,
            iconName: 'AddFriend',
            synonyms: [myStrings.search.synonyms.create, myStrings.search.synonyms.user],
        },
        {
            group: `${myStrings.sideMenu.users}`,
            action: SearchAction.Other,
            nameGetter: (strings) => `${strings.components.search.reassignLicenses}`,
            link: RouteConfig.users.licences.path,
            iconName: 'ContactCardSettings',
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.reassignLicenses],
        },
        {
            group: `${myStrings.sideMenu.groups}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.groups)}`,
            link: RouteConfig.groups.path,
            iconName: 'People',
            keyWords: ['Groups'],
            synonyms: [myStrings.search.synonyms.edit],
        },
        {
            group: `${myStrings.sideMenu.groups}`,
            action: SearchAction.Add,
            nameGetter: (strings) => `${strings.components.search.groupsGroupAddNew}`,
            link: RouteConfig.groups.new.path,
            iconName: 'PeopleAdd',
            synonyms: [myStrings.search.synonyms.create],
        },
        {
            group: `${myStrings.sideMenu.features}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.features)}`,
            link: RouteConfig.customizations.features.path,
            iconName: 'IssueTracking',
            keyWords: ['Features'],
            synonyms: [myStrings.search.synonyms.edit],
        },
        {
            group: `${myStrings.sideMenu.fields}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.fields)}`,
            link: RouteConfig.customizations.fields.path,
            iconName: 'FieldNotChanged',
            keyWords: ['Additional Fields'],
            synonyms: [myStrings.search.synonyms.edit],
        },
        {
            group: `${myStrings.sideMenu.billing}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.sideMenu.manageSubscriptions}`,
            link: RouteConfig.billing.subscriptions.path,
            iconName: 'MailOptions',
            keyWords: ['Subscriptions'],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.subscriptions],
        },
        {
            group: `${myStrings.sideMenu.billing}`,
            action: SearchAction.Other,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.bills)}`,
            link: RouteConfig.billing.bills.path,
            iconName: 'History',
            keyWords: ['Bills'],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.billingHistory],
        },
        {
            group: `${myStrings.sideMenu.billing}`,
            action: SearchAction.Other2,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.billingDetails)}`,
            link: RouteConfig.billing.paymentMethods.path,
            iconName: 'PaymentCard',
            keyWords: ['Payment methods'],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.paymentMethods],
        },
        {
            group: `${myStrings.sideMenu.updates}`,
            action: SearchAction.Manage,
            nameGetter: (strings) => `${strings.components.search.allGroupsDefaultPrefix} ${lowerCaseBasedOnLang(strings.components.sideMenu.updates)}`,
            link: RouteConfig.updates.path,
            iconName: 'WebPublish',
            keyWords: ['Updates'],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.updates],
        },
        {
            group: `${myStrings.search.apps.name}`,
            action: SearchAction.Other,
            nameGetter: (strings) => strings.components.search.apps.oldAdminSettings.name,
            link: ExternalLinks.oldAdminApp,
            isExternalLink: true,
            img: administrationSettingsImg,
            keyWords: ['Old Administration Settings'],
            synonyms: [myStrings.search.apps.oldAdminSettings.synonyms],
        },
        {
            group: `${myStrings.search.apps.name}`,
            action: SearchAction.Other,
            nameGetter: (strings) => strings.components.search.apps.powerBI.name,
            link: ExternalLinks.powerBIApp,
            isExternalLink: true,
            img: powerBiImg,
            keyWords: ['Power BI'],
            synonyms: [myStrings.search.apps.powerBI.synonyms],
        },
        {
            group: `${myStrings.search.apps.name}`,
            action: SearchAction.Other,
            nameGetter: (strings) => strings.components.search.apps.zapier.name,
            link: ExternalLinks.zapier,
            isExternalLink: true,
            img: zapierImg,
            keyWords: ['Zapier'],
            synonyms: [myStrings.search.apps.zapier.synonyms],
        },
    ];

    if (isLegacyAdministrationLinkHidden) {
        return searchPages.filter((sp) => sp.link !== ExternalLinks.oldAdminApp);
    }

    return searchPages;
};
