import type { IApiCustomizationStatsItem, IApiDataResponse, IApiLicense, IApiObjectType } from '@eway-crm/connector';
import { Spinner, useLicenseRestrictionsModal } from '@eway-crm/gui';
import React, { createContext, useCallback, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Connection } from '../components/layout/connection/Connection';
import LicenseRestrictionsHelper from '../helpers/LicenseRestrictionsHelper';
import { RemoteItemStore } from '../RemoteItemStore';
import APP_BUILD_MODE from '../AppBuildMode';

type TApiData = {
    license: IApiLicense;
    customizationStats: IApiCustomizationStatsItem[];
    objectTypes: IApiObjectType[];
};

export type TVersionsObject = {
    wsVersion: string;
    outlookClientVersion: string;
};

export type TAuthSettingsObject = {
    isActiveDirectory: boolean;
    isAzureAuth: boolean;
};

export type TConnectionContext = {
    connection: Connection;
    apiData: TApiData;
    isLoginProceeded: boolean;
    licenseRestrictionsHelper: LicenseRestrictionsHelper;
    showLicenseRestrictionModal: ReturnType<typeof useLicenseRestrictionsModal>['showLicenseRestrictionModal'];
    version: TVersionsObject;
    authSettings: TAuthSettingsObject;
    reloadCustomizationStats: () => Promise<void>;
    reloadLicense: (doReload: boolean, tryWithoutReloadIfFails: boolean, reloadCustomizationStats?: boolean) => Promise<IApiLicense>;
};

export const ConnectionContext = createContext<TConnectionContext>(null!);

const ConnectionProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [isLoginProceeded, setIsLoginProceeded] = useState(false);
    const [licenseRestrictionsHelper, setLicenseRestrictionsHelper] = useState<LicenseRestrictionsHelper | null>(null);
    const [apiData, setApiData] = useState<TApiData | null>(null);
    const [authSettings, setAuthSettings] = useState<TAuthSettingsObject | null>(null);
    const [version, setVersion] = useState<TVersionsObject | null>(null);
    const connectionRef = useRef<Connection>(null);

    const reloadLicense = useCallback(async (doReload = true, tryWithoutReloadIfFails = true, reloadCustomizationStats = false) => {
        const remoteItemStore = new RemoteItemStore(connectionRef.current!);
        const license = await remoteItemStore.askLoadLicense(doReload, tryWithoutReloadIfFails);

        let customizationStats: IApiCustomizationStatsItem[] | null = null;
        if (reloadCustomizationStats) {
            const customizationStatsRes = await connectionRef.current!.askApi<IApiDataResponse<IApiCustomizationStatsItem>>('GetCustomizationStats', {});
            customizationStats = customizationStatsRes.Data;
        }

        setApiData((prev) => {
            if (!prev) {
                throw new Error(`License can only be reloaded after initial data load.`);
            }

            const newCustomizationStats = reloadCustomizationStats && customizationStats ? customizationStats : prev.customizationStats;

            setLicenseRestrictionsHelper(new LicenseRestrictionsHelper(license, newCustomizationStats));
            return { ...prev, customizationStats: newCustomizationStats, license };
        });

        return license;
    }, []);

    const { licenseRestrictionModal, showLicenseRestrictionModal } = useLicenseRestrictionsModal(
        apiData?.license ?? null,
        isLoginProceeded ? connectionRef.current!.getApiConnection() : null,
        APP_BUILD_MODE,
        true,
        false,
        reloadLicense
    );

    const location = useLocation();
    const history = useHistory();

    const loadData = useCallback(async () => {
        const remoteItemStore = new RemoteItemStore(connectionRef.current!);
        const licenseRes = await remoteItemStore.askLoadLicense(false, false);
        const customizationStatsRes = await connectionRef.current!.askApi<IApiDataResponse<IApiCustomizationStatsItem>>('GetCustomizationStats', {});
        const otResult = await connectionRef.current!.askApi<IApiDataResponse<IApiObjectType>>('GetObjectTypes', {});

        setLicenseRestrictionsHelper(new LicenseRestrictionsHelper(licenseRes, customizationStatsRes.Data));
        setApiData({
            license: licenseRes,
            objectTypes: otResult.Data,
            customizationStats: customizationStatsRes.Data,
        });
    }, []);

    const reloadCustomizationStats = useCallback(async () => {
        if (!apiData?.license || !connectionRef.current) {
            return;
        }

        const customizationStatsRes = await connectionRef.current.askApi<IApiDataResponse<IApiCustomizationStatsItem>>('GetCustomizationStats', {});
        setLicenseRestrictionsHelper(new LicenseRestrictionsHelper(apiData.license, customizationStatsRes.Data));
        return;
    }, [apiData?.license]);

    const handleLoginComplete = useCallback(async (hasUserChanged: boolean) => {
        if (isLoginProceeded) {
            if (hasUserChanged) {
                console.log('User changed, reloading...');
                // Reload page if user has changed when relogging to reset all states
                window.location.reload();
                return;
            }
        } else {
            await loadData();
            setIsLoginProceeded(true);
        }
    }, [isLoginProceeded, loadData]);

    return (
        <>
            <Connection
                history={history}
                location={location}
                ref={connectionRef}
                authSettings={authSettings}
                onAuthSettingsLoaded={setAuthSettings}
                onVersionLoaded={setVersion}
                lastLoadedVersion={version}
                onLogin={handleLoginComplete}
            />
            {(connectionRef.current && licenseRestrictionsHelper && apiData && authSettings && version && isLoginProceeded) ?
                (
                    <ConnectionContext.Provider
                        value={{ connection: connectionRef.current, apiData, isLoginProceeded, licenseRestrictionsHelper, showLicenseRestrictionModal, reloadCustomizationStats, reloadLicense, authSettings, version }}
                    >
                        {children}
                        {licenseRestrictionModal}
                    </ConnectionContext.Provider>
                )
                :
                <Spinner />
            }
        </>
    );
};

export default ConnectionProvider;
