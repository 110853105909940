import { MetaStrings } from '@eway-crm/gui';
import Cookies from './Cookies';
import { DateHelper } from './helpers/DateHelper';
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
const nrExt = ['st', 'nd', 'rd', 'th'];

function parseDate(dateString?: Date | string | null) {
    if (typeof (dateString) !== 'string') {
        return dateString;
    }

    return DateHelper.parseUniversalDate(dateString);
}

function getNumberExt(nr: number) {
    if (nr < 1) {
        return null;
    }
    if (nr > nrExt.length) {
        return nrExt[nrExt.length - 1];
    }
    return nrExt[nr - 1];
}

function formatDateEn(date?: Date | string | null) {
    date = parseDate(date);
    if (!date) {
        return null;
    }
    return months[date.getMonth()] + " " + date.getDate() + "" + getNumberExt(date.getDate()) + ", " + date.getFullYear();
}

function formatDateTimeEn(date?: Date | string | null) {
    date = parseDate(date);
    if (!date) {
        return null;
    }

    let hours = date.getHours();
    let minutes: string | number = date.getMinutes();
    const ampm = hours >= 12 ? 'pm' : 'am';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;

    return months[date.getMonth()] + " " + date.getDate() + "" + getNumberExt(date.getDate()) + ", " + date.getFullYear() + " " + strTime;
}

function formatDateCs(date?: Date | string | null) {
    date = parseDate(date);
    if (!date) {
        return null;
    }
    return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear();
}

function formatDateTimeCs(date?: Date | string | null) {
    date = parseDate(date);
    if (!date) {
        return null;
    }

    let hours: string | number = date.getHours();
    let minutes: string | number = date.getMinutes();
    hours = hours < 10 ? '0' + hours : hours;
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes;

    return date.getDate() + ". " + (date.getMonth() + 1) + ". " + date.getFullYear() + " " + strTime;
}

function formatNumber(nr: number | null, numDec: number, lang: string) {
    if (!nr && nr !== 0) {
        return null;
    }
    return nr.toLocaleString(lang, { minimumFractionDigits: numDec, maximumFractionDigits: numDec });
}

function formatCurrency(nr: number | null, currencyCode: string | null, numDec: number, lang: string) {
    if (!nr && nr !== 0) {
        return null;
    }
    return nr.toLocaleString(lang, { style: "currency", currency: currencyCode ?? undefined, minimumFractionDigits: numDec, maximumFractionDigits: numDec });
}

function pickTranslation<T extends string | number | symbol, R>(translatableModel: Record<T, R> | null | undefined, switchFunc: (model: Record<T, R>) => R | null, defaultAttributeName: T) {
    if (!translatableModel) {
        return null;
    }
    const exact = switchFunc(translatableModel);
    if (exact)
        return exact;
    return translatableModel[defaultAttributeName];
}

const DEFAULT_FEATURES_DESCRIPTION = {
    en: 'Customize eWay-CRM to your needs, set up various features and manage permissions.',
    cs: 'Přizpůsobte si eWay-CRM podle svých potřeb, nastavte různé funkce a spravujte oprávnění.'
};

const Strings = MetaStrings.create({
    en: {
        switch: (en: string | null, _: string | null) => en,
        pickTranslation: <T>(t: Record<'En' | 'Cs', T> | null | undefined) => pickTranslation<'En' | 'Cs', T>(t, (tt) => tt.En, 'En'),
        pickNameTranslation: <T>(t: Record<'NameEn' | 'NameCs', T> | null | undefined) => pickTranslation<'NameEn' | 'NameCs', T>(t, (tt) => tt.NameEn, 'NameEn'),
        formatDate: formatDateEn,
        formatDateTime: formatDateTimeEn,
        formatNumber: (nr: number | null, numDec: number) => formatNumber(nr, numDec, 'en'),
        formatCurrency: (nr: number | null, numDec: number, currencyCode: string | null) => formatCurrency(nr, currencyCode, numDec, 'en'),
        ok: 'OK',
        inModuleSinglular: 'module',
        inModulePlural: 'modules',
        no: 'No',
        yes: 'Yes',
        cancel: 'Cancel',
        discard: 'Discard',
        done: 'Done',
        next: 'Next',
        back: 'Back',
        save: 'Save',
        saveChanges: 'Save Changes',
        close: 'Close',
        refresh: 'Refresh',
        duplicate: 'Duplicate',
        delete: 'Delete',
        search: 'Search',
        filter: 'Filter',
        on: 'On',
        off: 'Off',
        active: 'Active',
        inactive: 'Inactive',
        and: 'and',
        continue: 'Continue',
        create: 'Create',
        createNewItem: 'Create new item',
        selectedFormat: '{0} selected',
        companies: 'Companies',
        contacts: 'Contacts',
        marketingCampaigns: 'Marketing Campaigns',
        activeProjects: 'Active Projects',
        completedProjects: 'Completed Projects',
        deadProjects: 'Lost Projects',
        activeDeals: 'Active Deals',
        completedDeals: 'Completed Deals',
        deadDeals: 'Lost Deals',
        activeTasks: 'Active Tasks',
        loadingError: 'We are sorry but the data couldn\'t be loaded.',
        thisFieldIsMandatory: 'This field is required.',
        saveChangesContinue: 'Save and continue',
        discardChanges: 'Discard changes',
        unsavedChanges: 'Unsaved Changes',
        youHaveUnsavedChanges: 'You have made some changes that has not been saved. Do you want to drop these changes?',
        allChangesWillBeSavedOnSave: 'All changes will be saved after you press the Save button.',
        export: 'Export',
        exportToExcel: 'Export to Excel',
        confirmRouteLeave: 'Are you sure you want to leave this page? Some changes have not been saved.',
        confirmRouteChange: 'Do you want to save your changes before leaving this page?',
        confirmRouteChangeTitle: 'Unsaved changes',
        saveErrorMessage: 'Something went wrong while saving the change. Please refresh the page.',
        savingMessageSingular: 'Saving change',
        savingMessagePlural: 'Saving {0} changes',
        copy: 'Copy',
        clickToCopy: 'Click to Copy',
        copied: 'Copied!',
        remove: 'Remove',
        add: 'Add',
        components: {
            routes: {
                home: {
                    title: 'Welcome to the eWay-CRM Admin Center',
                    featuredAdminVideos: 'Featured Admin Videos',
                    loginActivity: 'Account Activity',
                    billing: 'Recent Bills',
                    more: 'View More',
                    less: 'View Less',
                    noData: 'No data available.',
                    newFeaturesWizard: {
                        title: 'Upskill your Team',
                        subtitle: 'Our users are often surprised when they find out about a feature they didn\'t even know existed. We have created a set of tutorials that walk you through all the handy features of eWay-CRM.',
                        sendTipsLabel: 'Can we send tips to your colleagues?',
                        sendTipsPlaceholder: 'Select an option',
                        cancelButton: 'Ask me Later',
                        dropdownError: 'Select one of the options'
                    }
                },
                users: {
                    title: 'Users',
                    subtitle: 'Manage users within your organization. Assign them to group to define their permissions.',
                    fullName: 'Full Name',
                    username: 'Username',
                    msAzureAccount: 'Microsoft Azure Account',
                    email: 'Email',
                    license: 'License',
                    lastLogin: 'Last Login',
                    lastActivity: 'Last Activity',
                    addUser: 'Add a User',
                    resetPassword: 'Reset Password',
                    forceChangePassword: "Force Password Change",
                    forceChangePasswordMessageSingular: 'Are you sure you want to force this user to change their password the next time they log in?',
                    forceChangePasswordMessagePlural: 'Are you sure you want to force these users to change their password the next time they log in?',
                    assignToGroup: 'Assign to Groups',
                    delegate: 'Delegate',
                    deactivate: 'Deactivate',
                    activate: 'Activate',
                    unlock: 'Unlock',
                    showInactiveUsers: 'Show Inactive Users',
                    showSystemUsers: 'Show System Users',
                    system: 'System',
                    you: 'You',
                    addNewUser: 'Add New User',
                    general: 'General',
                    groups: 'Groups',
                    assignGroups: 'Assign Groups',
                    special: 'Additional Settings',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    supervisor: 'Supervisor',
                    noSupervisor: '(no supervisor)',
                    defaultGroup: 'Default Group',
                    noDefaultGroup: '(no default group)',
                    locked: 'Locked',
                    ewayLicense: 'License',
                    noLicense: '(no license)',
                    apiOnly: 'API Only (can be used for integrations)',
                    buyMore: 'Buy more',
                    buyMoreLicenses: 'Buy more licenses',
                    cannotAssignLicenseTitle: 'Cannot assign this license',
                    cannotAssignLicenseMessage: 'You cannot assign this license without also assigning {0} license.',
                    passwordSettings: 'Password Settings',
                    autogeneratePassword: 'Auto-generate Password',
                    letMeCreatePassword: 'Let Me Create the Password',
                    password: 'Password',
                    sendTipsAndTricks: 'Send tips on how to work with eWay-CRM',
                    forceChangePasswordOnLogin: 'Force the user to change their password at first login',
                    sendInstalationInstructions: 'Send installation instructions in email upon completion',
                    containsPassword: 'contains password',
                    sendNewPassword: 'Send new password via email',
                    unableSendNewPasswordFormat: 'Unable to send the new password. The user {0} does not have email address .',
                    projectManager: 'Is Project Manager',
                    exchangeRatesAdmin: 'Notify about issues with exchange rates',
                    sendServerUpdateStatus: 'Notify about ongoing server updates',
                    sendSystemHealthNotifications: 'Notify about system errors',
                    systemApiUser: 'System API User',
                    notifyUnpaidInvoices: 'Notify about unpaid invoices',
                    notifyDeadLeads: 'Notify about expired deals',
                    forceEmailTrancking: 'Force emails tracking',
                    sendPhoneTaskList: 'Send phone task list',
                    notifyPohodaInvoiced: 'Notify about invoiced invoices synchronized from Pohoda',
                    notifyPohodaPaid: 'Notify about paid invoices synchronized from Pohoda',
                    passwordSuperweak: 'Too Weak',
                    passwordWeak: 'Weak',
                    passwordGood: 'Good',
                    passwordStrong: 'Strong',
                    createUser: 'Create User',
                    createUsers: 'Create Users',
                    groupName: 'Group Name',
                    assign: 'Assign',
                    reset: 'Reset',
                    usersWillHaveSamePassword: 'This will apply the same password for all the selected users. For security, we suggest requiring these users to change their password when they first log in.',
                    reassignFollowing: 'Reassign the Following Items',
                    newOwner: 'New Owner',
                    selectUniqueUsername: 'This username is already used.',
                    selectUniqueEmail: 'This email is already in use.',
                    emailTooLong: 'The email must be under {0} characters.',
                    usernameTooLong: 'The username must be under {0} characters.',
                    usernameTooShort: 'The username is too short.',
                    passwordTooShort: 'The password is too short.',
                    passwordTooWeak: 'The password is too weak.',
                    passwordEnterUsernameFirst: 'Enter a username first.',
                    wrongEmail: 'Email is filled wrong.',
                    reassignLicenses: 'Reassign Licenses',
                    nextUser: 'Next User',
                    previousUser: 'Previous User',
                    noUsers: 'No users to display.',
                    noUsersMatch: 'No users match your search.',
                    userWillBeAddedToGroup: 'User will be added to group',
                    userWillBeRemovedFromGroup: 'User will be removed from group',
                    categories: 'Categories',
                    effectivePermissions: 'Effective Permissions',
                    effectivePermission: 'Effective Permission',
                    modulePermissions: 'Module Permissions',
                    fieldPermissions: 'Field Permissions',
                    highest: 'highest',
                    lowest: 'lowest',
                    presenceInGroups: 'The presence in groups with the',
                    permissionAffectsAbility: 'permission affects the ability to',
                    items: 'items',
                    affects: 'Affects',
                    workWithField: 'work with the field',
                    quicklyAssignGroups: 'Quickly Assign Groups',
                    addUserToGroupTitle: 'Add User to Group',
                    addUserToGroupText: 'Are you sure you want to add user {0} to group {1}?',
                    removeUserFromGroupTitle: 'Remove User from Group',
                    removeUserFromGroupText: 'Are you sure you want to remove user {0} from group {1}?',
                    cannotResetPasswordWithAD: 'This feature is disabled because you are using the Azure or Windows authentication. Please reset the password there.'
                },
                groups: {
                    title: 'Groups',
                    subtitle: 'Create groups and assign there individual users. With our group-policy, you will be able to control permissions, email notifications, etc.',
                    system: 'System',
                    administrator: 'Administrator',
                    groupName: 'Group Name',
                    numberOfUsers: 'Number of Users',
                    description: 'Description',
                    note: 'Note',
                    addGroup: 'Add a Group',
                    assignUsers: 'Assign Users',
                    users: 'Users',
                    special: 'Additional Settings',
                    defaultLayouts: 'Default Forms',
                    addNewGroup: 'Add New Group',
                    general: 'General',
                    user: 'User',
                    username: 'Username',
                    isProjectManager: 'This role is used for marking users as project managers.',
                    isProjectManagerDescription: 'When creating teams in eWay-CRM, one person has to be the project manager. By choosing role with this setting, you will mark a user as the project manager. There can be only one role that works as the project manager role.',
                    isProjectManagerDescriptionMore: 'Find our more about project manager role in teams.',
                    isProjectManagerDescriptionMoreUrl: 'https://eway.cr/4owhf',
                    projectManager: 'Project Manager',
                    isRole: 'Available as a role in project teams.',
                    isRoleDescription: 'If you check this box, this group will be available as a team role when creating/editing teams in eWay-CRM.',
                    isRoleDescriptionTeams: 'Find out more about teams.',
                    isRoleDescriptionTeamsUrl: 'https://eway.cr/BXiJ7',
                    createGroup: 'Create Group',
                    assign: 'Assign',
                    duplicateGroup: 'Duplicate Group Settings',
                    duplicateGroupErrorIsSystem: 'Group {0} already exists and cannot be duplicated to.',
                    duplicateGroupErrorReserved: 'Name {0} cannot be used.',
                    targetGroupName: 'Type to create a new group or choose from existing',
                    new: 'New',
                    copyUsers: '{0} Users',
                    copyModulePermissions: '{0} Module Permissions',
                    copyFieldPermissions: '{0} Field Permissions',
                    include: 'Include',
                    replace: 'Replace',
                    deleteGroupSingularTitle: 'Delete Group',
                    deleteGroupSingularMessage: 'Are you sure you want to delete the selected group?',
                    deleteGroupsPluralTitle: 'Delete Groups',
                    deleteGroupsPluralMessage: 'Are you sure you want to delete all the selected groups?',
                    writeNotes: 'Write Your Notes',
                    selectUniqueName: 'This name is already used.',
                    groupNameTooShort: 'Group name is too short.',
                    pmAlreadySpecifiedTitle: 'Switch project manager role?',
                    pmAlreadySpecified: 'Project managers are currently recognized by being assigned into the role "{0}". Are you sure that you want to mark this role as the project manager role?',
                    noGroups: 'No groups to display.',
                    noGroupsMatch: 'No groups match your search.',
                    modulePermissions: 'Module Permissions',
                    fieldPermissions: 'Field Permissions',
                    exportThisTable: 'Export List of Groups',
                    exportGroupsWithModulePermissions: 'Export Groups Including Module Permissions',
                    exportGroupsWithFieldPermissions: 'Export Groups Including Field Permissions',
                    defaultFormLayouts: {
                        waOnly: 'This feature is supported in eWay-CRM Online only. It is not supported in eWay-CRM Desktop or eWay-CRM Mobile.',
                        folderName: 'Module',
                        layout: 'Form',
                        noMatch: 'No modules match your search.',
                        noData: 'No modules to display.',
                        noLayout: '(default)'
                    }
                },
                modulePermissions: {
                    complexCombinationWarningText: 'eWay-CRM Mobile: We must change {1} edit permissions to "Own" in eWay-CRM Mobile.',
                    complexWarningStripText: "Tip for faster eWay-CRM: Keep your permissions simple.",
                    title: 'Module Permissions for {0}',
                    subtitle: 'Manage module permissions of group {0}. Use the button Affected Users to quickly find out whose permissions are changed.',
                    splashMessage: 'Select a group to manage its module permissions.',
                    noModulePermissions: 'No modules to display.',
                    noModulePermissionsMatch: 'No modules match your search.',
                    legend: 'Legend',
                    affectedUsers: 'Affected Users',
                    affectedUsersDescription: 'Note: There may be other groups which override settings of currently selected group for these users.',
                    exportThisTable: 'Export Only This Table',
                    exportAllTables: 'Export All Tables for All Groups',
                    duplicate: {
                        title: 'Duplicate Module Permissions',
                        description: 'Set up permissions in the target groups according to the role {0}',
                        targetGroup: 'Target Groups',
                        includeFieldPermissions: 'Include Field Permissions',
                        okButtonTitle: 'Duplicate',
                        noGroup: '<Choose Group>',
                    },
                    columns: {
                        moduleTitle: 'Module',
                        viewTitle: 'View',
                        viewAction: 'see',
                        editTitle: 'Edit',
                        editAction: 'edit',
                        deleteTitle: 'Delete',
                        deleteAction: 'delete',
                        canCreateTitle: 'Create',
                        canCreateAction: 'create',
                        canExportTitle: 'Export',
                        canExportAction: 'export',
                        canSeeHistoryTitle: 'History',
                        canSeeHistoryAction: 'see history of',
                        rowsRestrictionTitle: 'No. of Visible Rows',
                        rowsRestrictionAction: 'see limited number of'
                    }, options: {
                        allPermissionsText: 'Users can {0} all items',
                        allPermissionsDescription: 'All',
                        inheritedPermissionsText: 'Users can {0} all items subordinated to contacts, companies, deals, or projects they can see',
                        inheritedPermissionsDescription: 'Subordinated to contacts, companies, deals or projects users can see',
                        groupPermissionsText: 'Users can {0} items related to the same categories they are members of',
                        groupPermissionsDescription: 'Related to the same categories users are members of',
                        relatedPermissionsText: 'Users can {0} items related to them',
                        relatedPermissionsDescription: 'Related to users',
                        ownPermissionsText: 'Users can {0} just their own items',
                        ownPermissionsDescription: 'Own',
                        nonePermissionsViewText: 'Users can\'t see the module at all',
                        nonePermissionsText: 'Users can\'t {0} anything',
                        nonePermissionsDescription: 'None / Hide module',
                        truePermissionsText: 'Users can {0} items',
                        falsePermissionsText: 'Users can\'t {0} anything',
                        noRestrictionsText: 'All',
                        rowRestrictionsSuffixSingular: 'row',
                        rowRestrictionsSuffixPluralTwo: 'rows',
                        rowRestrictionsSuffixPluralFiveAndMore: 'rows',
                    }
                },
                fieldPermissions: {
                    noFieldPermissions: 'No fields to display.',
                    noFieldPermissionsMatch: 'No fields match your search.',
                    additionalField: 'Custom Field',
                    checkingForDuplicatedValues: 'Checking for duplicates',
                    fieldCannotBeEdited: 'Field can\'t be edited',
                    fieldCannotBeSetUnique: 'Field can\'t be set as unique',
                    nonUniqueValueDialogTitle: 'Duplicates Found',
                    nonUniqueValueDialogMessage: 'We can\'t set up the field as unique because it contains duplicates: "{0}". Please go to eWay-CRM and clean them.',
                    nonUniqueValueDialogMessageBlank: 'We can\'t set up the field as unique because it contains duplicate empty values. Please go to eWay-CRM and clean them.',
                    uniqueNotFullyPermittedDialogTitle: 'Cannot Set This Field as Unique',
                    uniqueNotFullyPermittedDialogMessage: 'We can\'t set up this field as unique, because some groups are missing permissions.\n\nIn order to fix that, go through all groups and make sure they have Full Control permission for this field. You can start with the group {0}.',
                    currentPermissionsDontAllowEdit: 'Your current permissions don\'t allow you to edit this field.',
                    columns: {
                        columnTitle: 'Name',
                        columnGroupNameTitle: 'Group Name',
                        permissionTitle: 'Permission Level',
                        isMandatoryTitle: 'Required',
                        isNotMandatory: 'Not required',
                        isOptionalTitle: 'Important',
                        isNotOptional: 'Not important',
                        isUniqueTitle: 'Unique',
                        isNotUnique: 'Not unique',
                        isReadonlyTitle: 'Read Only',
                    },
                    options: {
                        allPermissionsText: 'Users Hold Full Control Over This Field',
                        allPermissionsDescription: 'Full Control',
                        ownPermissionsText: 'Users Hold Control Over Fields of Their Items',
                        ownPermissionsDescription: 'Own Items',
                        readonlyPermissionsText: 'Users See This Field But They Can\'t Edit Values',
                        readonlyPermissionsDescription: 'Read Only',
                        invisiblePermissionsText: 'Users Don\'t See This Column',
                        invisiblePermissionsDescription: 'Invisible',
                        nonePermissionsText: 'Users Don\'t See This Column nor Can Work With It',
                        nonePermissionsDescription: 'Disabled / Hidden',
                    }
                },
                features: {
                    title: 'Features in {0}',
                    splashMessage: 'Select a module to manage its features.',
                    active: 'Active',
                    feature: 'Feature',
                    description: 'Description',
                    noFeatureMatch: 'No features match your search.',
                    showRetired: 'Show Retired',
                    settingsTab: 'Settings',
                    modulePermissionsTab: 'Module Permissions',
                    fieldPermissionsTab: 'Field Permissions',
                    timeRangePickerBetween: 'and',
                    selectGroups: 'Select groups',
                    selectOptions: 'Select options',
                    externalLinkTooltip: 'Learn more about this feature in eWay-CRM Knowledge Base',
                    unlimited: 'Unlimited',
                    setUnlimited: 'Set as Unlimited',
                    disabled: 'Disabled',
                    setDisabled: 'Set as Disabled',
                },
                fields: {
                    title: 'Fields in {0}',
                    subtitle: 'Browse through the complete list of fields in {0}. Change their properties, set up permissions, or create your own custom fields.',
                    splashMessage: 'Select a module to manage its fields.',
                    name: 'Name',
                    type: 'Type',
                    dbColumnName: 'DB Name',
                    dbColumnNameTooltip: 'Database name of the field. Can be useful when making your own workflow actions, reports, or integrations.',
                    description: 'Description',
                    addField: 'Add a Field',
                    general: 'General',
                    fieldPermissions: 'Field Permissions',
                    fieldPermissionsTabDisabled: 'We do not support editing the permissions of this field.',
                    modules: 'Modules',
                    noModule: '<Add Module>',
                    category: 'Category',
                    noCategory: '<No Category>',
                    editMask: 'Edit Mask',
                    height: 'Field Height',
                    values: 'Values',
                    format: 'Format',
                    summarizationType: 'Sumarization Type',
                    linkType: 'Link Type',
                    showTime: 'Show Time',
                    relatedTable: 'Related Table',
                    comingSoon: 'Coming soon.',
                    createField: 'Create Field',
                    deleteAfSingularTitle: 'Delete Custom Field',
                    deleteAfSingularMessage: 'Are you sure you want to delete the selected custom field?',
                    deleteAfPluralTitle: 'Delete Custom Fields',
                    deleteAfPluralMessage: 'Are you sure you want to delete all the selected custom fields?',
                    noFieldsCanBeDeleted: 'The fields you have selected are used in custom layouts or views. As a consequence, they cannot be deleted.',
                    someFieldsCannotBeDeleted: 'Some of the fields you have selected are used in custom layouts or views. Do you want to delete the rest of the selection listed above?',
                    fieldNameAlreadyUsedFormat: 'This field name is already used in module {0}.',
                    fieldNameReservedFormat: 'This field name is reserved in module {0}.',
                    rankAutomaticallySaved: 'Position change is being saved.',
                    noAfsInFolderFormat: 'No fields for module {0} were found.\nPlease check your filter.',
                    noAfsInFolderMatchFormat: 'No fields in module {0} match your search.',
                    typeAndModuleAreInvalid: 'The module Marketing List cannot contain custom fields of type Relation or Multi Select Drop Down.',
                    isUnique: 'Unique',
                    exportThisTable: 'Export This View',
                    exportAllTables: 'Export All Modules',
                    toggles: {
                        isRequiredTooltip: 'All users will have to fill in this field when creating / editing records.',
                        isImportantTooltip: 'All users will be asked to fill in this field when creating / editing records. Yet, they can decide to keep it blank.',
                        isUniqueTooltip: '{0} fields are great when you need each record to contain a non-blank, unique value. To do that, we need you to follow these steps:',
                        isUniqueTooltipList: '1. Create the field as non-unique.\n' +
                            '2. Update your existing items in {0} and fill in the required data into your new field. The fastest way is to export {0} to Excel, edit items there and import them back.\n' +
                            '3. As soon as you finish uploading data to the server, come back here to the field settings and mark it as unique.',
                        isUniqueTooltipNote: 'Note:',
                        isUniqueTooltipNoteText: 'Unique fields can be used only with single-line text boxes and URL.',
                        isReadOnlyTooltip: 'Users won\'t be able to change values in this field. It\'s great if you need to create an automatically calculated field.',
                        generalTooltip: 'To make this field {0} for certain groups only, turn this setting off and set it up in the section Field Permissions.',
                    },
                    showSystemFields: 'Show System Fields',
                    showCustomFields: 'Show Custom Fields',
                    tooltips: {
                        cantCreateCustomField: 'We do not support custom fields in {0}.',
                        cantDuplicateFields: 'We do not support custom fields in {0}.',
                        cantDeleteFields: 'We can\'t delete system fields.',
                        cantDuplicateSystemOnlyField: 'We can\'t duplicate {0} fields.',
                        cantManageFieldsInProducts: 'We can\'t {0} custom fields in {1}. Please use module {2} instead. Custom fields will be copied to {1} automatically.',
                        fieldCannotBeModified: 'This field can\'t be modified.'
                    },
                    changesWillBeAlsoDuplicatedToFolderNameSingular: 'Changes to this field will be automatically populated to the same field in {0}.',
                    changesWillBeAlsoDuplicatedToFolderNamePlural: 'Changes to this field will be automatically populated to the same field in {0}.',
                    changesWillBeAlsoDuplicatedToFields: 'Changing the Permission Level in this field will be automatically populated to fields {0}',
                    dropDowns: {
                        name: 'Name',
                        techName: 'Tech. Name',
                        additionalField: 'Custom Field',
                        en: 'English',
                        cs: 'Czech',
                        de: 'German',
                        ru: 'Russian',
                        no: 'Norwegian',
                        sk: 'Slovak',
                        system: 'System',
                        systemEnumType: 'System',
                        default: 'Default',
                        visible: 'Enabled',
                        useForLastActivity: 'Use for Last Activity Calculation',
                        useForNextStep: 'Use for Next Step Calculation',
                        noValuesYet: 'No values yet. Add some by clicking on + button in top-left corner.',
                        stageName: 'Stage Name',
                        stageAttributes: 'Stage Attributes',
                        addColumn: 'Add Translation',
                        hideColumn: 'Hide Column',
                    },
                    enumValueCannotBeDeletedIsSystem: 'System values cannot be deleted',
                    enumValueCannotBeDeletedIsUsed: 'This value is already used in some items in eWay-CRM',
                    enumValuesErrorTitle: 'Unable to save',
                    enumValuesErrorEmptyText: 'Some values in dropdown are empty. Fill or delete them first and then try again.\n\nYou can start in a column {0}.',
                    enumValuesErrorDuplicateText: 'There are some duplicates in dropdown values. Please fix them first and then try again.\n\nYou can start in a column {0} in the row with the value "{1}".',
                },
                workflow: {
                    title: 'Workflow',
                    subtitleSimpleEditor: 'This is a basic workflow. You can add new steps, modify or delete the existing ones or change their order.',
                    subtitleAdvancedEditor: 'This is an advanced workflow. Use the editor to define what exact steps your employees should follow.',
                    splashMessage: 'Select a module to manage its workflows.',
                    subtitleEditorPicker: 'Choose editor type and begin.',
                    simple: 'Basic',
                    advanced: 'Advanced',
                    simpleEditor: 'Basic Workflow',
                    advancedEditor: 'Advanced Workflow',
                    new: 'Create new',
                    create: 'Create',
                    noModelTypeMatch: 'No workflow models to display.',
                    noValuesYet: 'No workflow stages were created yet.',
                    winProbability: 'Win Probability',
                    editName: 'Rename',
                    editNameTitle: 'Rename Workflow',
                    editNameMessage: 'Edit name and translations.',
                    errorDuplicateNameText: 'The name or one of its translations already exist in another workflow.\n\nDuplicate is for example in column {0}.',
                    deleteWorkflowTitle: 'Delete Workflow',
                    deleteWorkflowMessage: 'Are you sure you want to delete the selected workflow?',
                    enable: 'Enable',
                    enabled: 'Enabled',
                    disable: 'Disable',
                    disabled: 'Disabled',
                    system: 'System',
                    noSelectedAttribute: 'None',
                    noSelectedAttributeInLeads: 'None',
                    addNewWorkflow: 'Add new workflow',
                    nameAlreadyUsed: 'This name is already used.',
                    simpleOrAdvancedPickTitle: 'How do you want to start?',
                    simpleOrAdvancedPickSubtitle: 'Do you want a simple set of steps with no actions or an advanced step-by-step workflow with automatic tasks, emails and mandatory fields?',
                    advancedEditorTitle: 'Still working on it',
                    advancedEditorSubtitle: 'The advanced editor is still under development. Please click the button to customize your workflow using the Old Administration Settings.',
                    advancedEditorButton: 'Customize using the Old Administration Settings',
                    duplicateWorkflow: 'Duplicate workflow',
                    targetWorkflow: 'Type to create a new workflow or choose from existing',
                    newWorkflowBadge: 'New',
                    wfCannotBeTargetOfDuplication: 'This workflow cannot be used as a target of duplication.',
                    wfNameAlreadyExistsInTranslation: 'This name already exists as one of the translations of workflow {0}. So it will be used as target workflow.',
                    optionAlreadyUsedInOtherStage: 'This option is already used in stage {0}.',
                    deleteWfModelDialog: 'Start from scratch',
                    deleteWfModelDialogMessage: 'You are going to reset the workflow and start again from scratch. Do you want to continue?',
                    stageAttributes: {
                        projectsCompletedState: 'Completed',
                        projectsDeadState: 'Lost',
                        bonusesCompletedState: 'Accepted',
                        leadsCompletedState: 'Closed Won',
                        leadsDeadState: 'Lost',
                        marketingCompletedState: 'Completed',
                        marketingDeadState: 'Lost',
                        tasksCompletedState: 'Completed',
                        tasksDeferredState: 'Deferred',
                        tasksInProgressState: 'In Progress',
                        tasksNotStartedState: 'Not Started',
                        tasksWaitOnSomeoneElseState: 'Wait on Someone Else',
                        vacationCompletedState: 'Approved',
                        workReportApprovedState: 'Approved'
                    }
                },
                subscriptions: {
                    title: 'Manage Subscriptions',
                    subtitle: 'Here, you can purchase products and services and manage your existing subscriptions.',
                    switchV2: 'Switch old/new eshop',
                    autoRenewalOn: 'auto renewal on',
                    subscribe: 'Add to Cart',
                    editSubscription: 'Edit Subscription',
                    unsubscribe: 'Unsubscribe',
                    closeAccount: 'Close Account',
                    closeAccountDialogTitle: 'Are you sure?',
                    closeAccountDialogMessage: 'Are you sure you want to unsubscribe your eWay-CRM plan and close your account? If you close your account, you will still be able to use your eWay-CRM until the end of the current subscription period. After this period, your account will be deleted. Until then, you can always subscribe any eWay-CRM plan back again without losing your data.',
                    continueShopping: 'Continue Shopping',
                    finishOrder: 'Finish Order',
                    isAlwaysChargedFullPeriodPriceAlertTitle: 'Warning',
                    isAlwaysChargedFullPeriodPriceAlertText: 'Your subscription renewal date is in {1} days. The {2} can\'t be prorated, so you will be charged for the entire {3}. Do you want to continue?',
                    newAmount: 'new amount',
                    youAreInEditMode: 'You have some unfinished changes in your subscriptions.',
                    discardAll: 'Discard All',
                    per: 'per',
                    yourNewSubscription: 'Your New Subscription',
                    yourCurrentSubscription: 'Your Current Subscription',
                    productsAndServices: 'Products and Services',
                    companyDetails: 'Customer',
                    companyDetailsOtherOptional: 'Optional Information',
                    licenseAgreement: 'License Agreement',
                    payment: 'Payment',
                    finishOrderWitoutPaying: 'Finish Order',
                    finishOrderAndPay: 'Finish Order and Pay',
                    emailAddress: 'Email Address',
                    vatNumber: 'VAT ID',
                    idNumber: 'Identification Number',
                    taxNumber: 'Tax ID',
                    vatNumberSk: 'VAT ID (IČ DPH)',
                    idNumberSk: 'Identification Number (IČ)',
                    taxNumberSk: 'Tax ID (DIČ)',
                    businessName: 'Business Name',
                    firstName: 'First Name',
                    lastName: 'Last Name',
                    phone: 'Phone',
                    chargeAdditionalServicesAutomatically: 'Charge me automatically for additional services (support hours, training, custom development)',
                    chargeAdditionalServicesAutomaticallyMore: 'If not selected, we will charge you automatically for the eWay-CRM monthly subscription and send you manual payment requests for any possible additional services.',
                    addressLine: 'Street and Place',
                    city: 'City',
                    state: 'State / Province / Region',
                    postalCode: 'Postal Code',
                    country: 'Country',
                    additionalInformation: 'Note for Seller',
                    checkingTax: 'Determining tax configuration for your region.',
                    oneMoreToDiscount: 'One more and you will get a discount!',
                    currentDbSizeFormat: 'Your current DB size is {0} GB.',
                    lessThanDbSizeFormat: 'This amount is less than your actual DB size, which is currently {0} GB. You will need to delete some of your data until the next billing period; otherwise, the system will get blocked.',
                    packageContains: 'Package Contains',
                    switchCard: 'Switch card',
                    toBePaidNow: 'Pay now',
                    total: 'New Subscription',
                    vat: 'VAT',
                    salesTax: 'Sales Tax',
                    totalWithVat: 'Your New Subscription Total incl. VAT',
                    totalWithSalesTax: 'Your New Subscription Total incl. Sales Tax',
                    totalWithVatAndSalesTax: 'Your New Subscription Total incl. VAT and Sales Tax',
                    accept: 'Accept',
                    byClickingIAccept: 'By clicking {0} I accept the {1}.',
                    order: 'order',
                    productDescription: 'Product Description',
                    quantity: 'Quantity',
                    priceUnitPeriod: 'Price / unit / ',
                    totalPricePeriod: 'Total Price / ',
                    vatIncludedText: 'All prices include VAT.',
                    vatExcludedText: 'All prices exclude VAT.',
                    checkoutDismissTitle: 'Cancel',
                    checkoutDismissMessage: 'Are you sure you want to cancel the order finishing wizard?',
                    checkoutSuccessSubtitle: 'Your new products are ready. How do you want to continue?',
                    checkoutSuccessDowngradeSubtitleFormat: 'Your licenses will be updated with the next renewal (on {0}).',
                    redistributeLicenses: 'Redistribute Licenses',
                    addUsersFormat: 'Add New Users ({0})',
                    nothingForNow: 'Not Now',
                    checkoutErrorGeneral: 'We are very sorry, but an error occured while processing your order. Please try to repeat your order later. You can also contact the eWay-CRM® support team.',
                    checkoutErrorWrongData: 'Some of the information you have filled is not correct. Please fill the order form again.',
                    checkoutErrorPayment: 'We are very sorry, but the payment info you have filled has been rejected for this order. Please fill another credit card',
                    unsubscriptionFailed: 'We are very sorry, but an error occured while unsubscribing. Please try to repeat your request later. You can also contact the eWay-CRM® support team.',
                    subscribedAmountTitleFormat: 'You are subscribed to auto-renewal {0}.',
                    licensedAmountTitleFormat: 'You have {0} ending at {1}.',
                    pastDueTitle: 'Past Due - Payment Error',
                    pastDueMessage: 'Your subscription is past due. This means that the automatic recurring payment was not successfully proceeded. Please check your payment method (credit card/PayPal account). You may also change the active payment method. Until the payment method is working, no changes to the subscription can be done.',
                    checkBills: 'Check Billing History',
                    managePaymentMehtods: 'Manage Payment Methods',
                    loadingPaymentGate: 'Loading Payment Gate',
                    initPaymentFailedTitle: 'Payment Failed',
                    initPaymentFailedMessageFormat: 'Your subscription is setted up but the initial payment was not successful. The error from the payment gate was: \'{0}\' Please, try inserting another payment card for the subscription.',
                    stripeSubscriptionPaymentMethodRequestedTitle: 'Past Due - Payment Error',
                    stripeSubscriptionPaymentMethodRequestedMessage: 'Your subscription is past due because we couldn\'t charge your default card. Please enter a new one and click Try Again.',
                    subscriptionPaymentIssueGoToAdminCenterTitle: 'Issue with subscription',
                    subscriptionPaymentIssueGoToAdminCenterMessage: 'There is an issue with your current subscription. Please go to Admin Center and resolve it there.',
                    paySubscription: 'Pay Subscription',
                    tryAgain: 'Try again',
                    buyNow: 'Buy Now',
                    updateSubscription: 'Update Subscription',
                    billedAt: 'billed at',
                    revert: 'Revert',
                    applyCustomerDetails: 'Apply',
                    subscriptionSummary: 'Your New Subscription',
                    paymentInfo: 'Payment Info',
                    chooseTheNumberOf: 'Choose the number of',
                    for: 'for',
                    comparePlans: 'Compare plans',
                    checkoutResultTitle: 'Good job',
                    checkoutResultStartBtn: 'Take me to eWay-CRM',
                    goToAdminCenter: 'Go to eWay-CRM Admin Center',
                    pay: 'Pay',
                    payWith: 'Pay with',
                    payWithAnother: 'Pay with another',
                    rowTeachingBubbleTitle: 'Set up your Professional Emails',
                    rowTeachingBubbleText: 'Select the requested plan and use the slider to set how many emails per month you\'d like to send.',
                    commitment: 'Commitment',
                    periods: {
                        monthly: {
                            unit: 'month',
                            adverb: 'monthly',
                            commitment: 'Monthly'
                        },
                        yearly: {
                            unit: 'year',
                            adverb: 'yearly',
                            commitment: 'Yearly'
                        },
                        quarterly: {
                            unit: 'quarter',
                            adverb: 'quarterly',
                            commitment: 'Quarterly'
                        },
                        semiannually: {
                            unit: 'half a year',
                            adverb: 'semi-annually',
                            commitment: 'Semi-annually'
                        },
                        lifetime: {
                            unit: 'lifetime',
                            adverb: 'lifelong',
                            commitment: 'Lifetime'
                        },
                        triennially: {
                            unit: '3 years',
                            adverb: 'triennially',
                            commitment: '3-yearly'
                        }
                    },
                    changePeriodToInvoicingDialog: {
                        title: 'Changing the Payment Method Required',
                        message: '3-year plans don\'t support automatic payments and so we must change your payment method to manual. You will receive an invoice every 3 years to be paid by card/wire. Do you want to proceed?'
                    },
                    payNowToBeCoveredUntilFormat: 'to be taken care of until {0}'
                },
                purchaseHistory: {
                    title: 'Billing History',
                    invoiceNr: 'Invoice No.',
                    date: 'Date',
                    articles: 'Product',
                    quantity: 'Quantity',
                    total: 'Total',
                    status: 'Status',
                    invoice: 'Invoice',
                    actions: 'Actions',
                    invoiceOnline: 'View Invoice',
                    from: 'from',
                    til: 'til',
                    paid: 'Paid',
                    notPaid: 'Not Paid',
                    due: 'Due',
                    open: 'Open',
                    print: 'Print',
                    pastDueTitle: 'Past Due - Payment Error',
                    pastDueDescription: 'Your subscription is past due. This means that the automatic recurring payment was not successfully proceeded. Please check your payment method (credit card/PayPal account). You may also change the active payment method.',
                    pastDueAmount: 'Amount',
                    pastDueDescriptionEn: 'Reason',
                    pastDueMore: 'More',
                    pastDuePayNow: 'Pay Now',
                    pastDueProblem: 'Problem',
                    pastDueRecentPaymentErrors: 'Recent Transaction Errors',
                    pastDueSuccess: 'The payment was successfully initiated and is now waiting for settlement.It might take a couple of hours until the subscription will be updated.',
                    pastDueUnsuccess: 'The payment was unsuccessful.Try to change your active payment method.In case of further problems, contact your account manager.',
                    payNow: 'Pay Now',
                    payInvoiceNo: 'Pay Invoice No.',
                    enterAnotherCard: 'Use Different Card',
                    pay: 'Pay',
                    invoiceSuccessfullyPaidTitle: 'Successfully Paid',
                    invoiceSuccessfullyPaidMessage: 'The payment was successful.',
                    paymentErrorTitle: 'Payment Error',
                    paymentError: 'The payment was not successful.',
                    paymentErrorGateMessage: 'Message from payment gate:',
                    noInvoices: 'No invoices to display.'
                },
                billingDetails: {
                    title: 'Billing Details',
                    notAllowedUntilSubscribe: 'Payment methods management is not enabled until you subscribe for one of eWay-CRM® Premium packages. Do you want to pay easily by Card or PayPal? Subscribe now right from this Administration Center.',
                    subscribeNow: 'Subscribe Now',
                    companyDetails: 'Company Details',
                    billingEmails: 'Billing Emails',
                    paymentMethods: 'Payment Methods',
                    companyDetailsSubtext: 'The following company details will be displayed on invoices and official correspondence. If you change your address, local taxes may be changed as well.',
                    billingEmailsSubtext: 'The following email addresses will be used only for billing purposes.',
                    active: 'Active',
                    expired: 'Expired',
                    setAsActive: 'Set as Active',
                    delete: 'Delete',
                    deletePaymentMethod: 'Delete Payment Method',
                    areYouSureToDelete: 'Are you sure you want to delete the payment method {0}?',
                    addNew: 'Add New',
                    newPaymentMethod: 'New Payment Method',
                    add: 'Add',
                    processingNewMethod: 'Processing new payment method.',
                    wrongMethod: 'Unfortunately, this payment method cannot be used. Please try to enter another.',
                    type: 'Type',
                    identifier: 'Identifier',
                    expiration: 'Expiration',
                    status: 'Status',
                    primaryContactEmail: 'Primary Contact Email',
                    email: 'Email',
                    cardNumber: 'Card Number',
                    cardExpiration: 'Card Expiration',
                    cardCvc: 'CVC',
                    insecureConnectionMessage: 'Credit card information cannot be entered over an insecure connection. Use a secure connection (HTTPS) and try again.'
                },
                updates: {
                    title: 'Updates',
                    noUpdateAvailable: 'No update available.',
                    currentVersionFormat: 'Your are using version {0}.',
                    download: 'Download the installation file of your eWay-CRM Desktop (Windows Only)',
                    updateAvailableFormat: 'eWay-CRM® {0} Is Here',
                    newerVersionAvailableFormat: 'New version {0} released on {1} is available for you. Your current version is {2}.',
                    install: 'Install',
                    installDisabledMaintenanceWindowTitle: 'Maintenance window is currently active',
                    installDisabledMaintenanceWindowText: 'The update can\'t be started due to ongoing maintenance.',
                    installDisabledMaintenanceWindowTryAgain: 'Please try it again after {0}.',
                    installDisabledMaintenanceWindowTryAgainTomorrow: 'Please try it again tomorrow after {0}.',
                    installDisabledMaintentaceWindowChangeText: 'To change the maintenance window, click {0}.',
                    installDisabledMaintenanceWindowLinkText: 'here',
                    preCheckFailedTitle: 'Cannot Start the Update',
                    preCheckFailedMessageIntro: 'The update cannot be started because eWay-CRM® server component is reporting these issues:',
                    preCheckFailedMessageOutro: 'Please resolve these issues and try the update again. If you need an assistance, contact eWay-CRM® support.',
                    preCheckFailedMessageFallback: 'The update cannot be started because eWay-CRM® server component is reporting some issues. Please resolve them and try the update again. If you need an assistance, contact eWay-CRM® support.',
                    updating: 'Updating',
                    showChangelog: 'Show What\'s New',
                    versionChanged: 'The update cannot be installed at this moment. The installation package is not available anymore. Please, come back and repeat the installation a little bit later. The update will be available soon again.',
                    stageVerifyingUpdate: 'Verifying the update.',
                    stageDownloadingFiles: 'Downloading the update files.',
                    stageInstalling: 'Installing the update.',
                    takeLong: 'The update may take a couple of minutes or longer depending on your internet connection and database size.',
                    failedBeforeStartTitle: 'Cannot Install the Update',
                    failedBeforeStartIntro: 'eWay-CRM® was unable to successfully finish the update for one of the following possible reasons:',
                    failedBeforeStartOutro1: 'Please resolve the issues and try the update again. If you need an assistance, contact eWay-CRM® support.',
                    failedBeforeStartOutro2Format: 'The previous version {0} has been restored, so that you can continue working with eWay-CRM®.',
                    failedBeforeStartReasonBackupPermission: 'The system does not have permissions to create database backups.',
                    failedBeforeStartReasonBackupSpaceFallback: 'There is not enough space to back up the database.',
                    failedBeforeStartReasonBackupSpaceFormat: 'There is not enough space in \'{0}\' to back up the database.',
                    failedBeforeStartReasonFilesPermissionsFallback: 'eWay-CRM® Timer does not have permissions to change the file web service directory.',
                    failedBeforeStartReasonFilesPermissionsFormat: 'eWay-CRM® Timer does not have permissions to change the files in \'{0}\'.',
                    failedTitle: 'Update Failed',
                    failedMessage: 'The update failed and could not recover the previous state. eWay-CRM® server component (including this Admin Center) is not probably running or may malfunction. The database is fully and safely backed up; however, the server part needs to be repaired. For an assistance, contact eWay-CRM® support.',
                    completedTitle: 'Update Successful',
                    completedWithWarningsMessage: 'The update was installed, but we experienced some issues. Please resolve them immediately in order to avoid any future problems. If you need an assistance, contact eWay-CRM® support.',
                    completedMessage: 'Update of the server component was successful. Please update eWay-CRM® Desktop now.',
                    showTechInfo: 'Show Technical Report',
                    hideTechInfo: 'Hide Technical Report'
                }
            },
            shared: {
                foldersMenu: {
                    noFolders: 'No modules to display.',
                    noFoldersMatch: 'No modules match your search.',
                }
            },
            connection: {
                error: "Unfortunatelly an error has occured during communication with the server. Please, try reloading the page.",
                settings: 'Settings',
                language: 'Language',
                english: 'English',
                czech: 'Czech',
                externalLoginMessage: 'Your eWay-CRM uses Microsoft Azure authentication. Click the following button to log in using your Microsoft account.',
                externalLoginButton: 'Login via Microsoft'
            },
            shoppingConnection: {
                unavailableTitle: 'Eshop not available',
                unavailableText: 'You currently don\'t have access to eWay-CRM eshop.'
            },
            mainComponent: {
                title: 'Admin Center',
                oldAdmin: 'Old Administration Settings'
            },
            sideMenu: {
                home: "Home",
                users: "Users",
                groups: "Groups",
                usersAndPermissions: 'Users and Permissions',
                modulePermissions: 'Module Permissions',
                customizations: 'Customizations',
                features: 'Features',
                fields: 'Fields',
                workflow: 'Workflow',
                billing: "Billing",
                manageSubscriptions: "Manage Subscriptions",
                bills: "Billing History",
                billingDetails: "Billing Details",
                billingNotifications: "Billing Notifications",
                updates: "Updates",
                notifications: {
                    unpaidOrdersTooltip: 'Unpaid items',
                    versionAvailableTooltip: 'New version available',
                    fields: 'There are issues with your fields definitions. Find more details inside this section.',
                    workflow: 'There are issues with your worklow definitions. Find more details inside this section.'
                }
            },
            search: {
                searchPlaceholder: 'How can we help you?',
                title: 'Search results',
                loading: 'Loading',
                noResultsFound: "Haven't found what you've been looking for? Try our",
                noResultsFoundHowTo: 'how-to section',
                noResultsFoundHowToLink: 'https://www.eway-crm.com/resources/how-to-use-eway-crm/',
                noResultsFoundOr: 'or ask our',
                noResultsFoundCommunity: 'community',
                noResultsFoundCommunityLink: 'https://www.reddit.com/r/ewaycrm/',
                allGroupsDefaultPrefix: 'Manage',
                users: 'Users',
                editUser: '',
                effectivePermissions: 'Effective Permissions for',
                reassignLicenses: 'Reassign Licenses',
                usersGroupAddNew: 'Add a User',
                groupsGroupAddNew: 'Add a Group',
                editGroup: '',
                editModulePermissions: 'Edit Module Permissions of Group',
                fieldsNamePrefix: 'Manage Fields in',
                fieldsNameSuffix: '',
                newWorkflowPrefix: 'Create New Workflow in',
                newAdditionalFieldNamePrefix: 'Add a New Field in',
                fieldPermissionsPrefix: 'Field Permissions of Group',
                dismiss: 'Dismiss',
                folderNameSynonyms: {
                    AdditionalFields: 'Additional Field AF',
                    Bonuses: '',
                    Carts: 'Carts Cart',
                    Calendar: '',
                    Groups: 'Groups Group',
                    Companies: '',
                    Contacts: '',
                    Leads: 'Leads Lead',
                    IndividualDiscounts: 'Individual Discounts Discount Price List Lists',
                    Documents: '',
                    Emails: '',
                    CurrencyExchangeRates: 'Currency Currencies',
                    Goals: '',
                    Journal: 'Journals Record Records',
                    Vacation: 'Vacation Vacations',
                    Marketing: '',
                    MarketingList: '',
                    MarketingListSources: '',
                    Payments: '',
                    PriceListGroups: '',
                    Prices: '',
                    Goods: 'Goods Good',
                    GoodsInCart: 'Goods Good In Cart Carts',
                    GoodsInSet: 'Goods Good',
                    Projects: '',
                    ProjectAssignments: 'Resource Plan Plans',
                    Reports: '',
                    ProjectUsersInCaPlan: '',
                    Salaries: '',
                    SalePrices: 'Sale Prices Price',
                    Knowledge: 'Knowledge',
                    Tasks: '',
                    WorkReports: 'Work Report Reports',
                    Training: '',
                    Users: '',
                    WorkCommitments: '',
                },
                synonyms: {
                    create: 'Create Creation Make New Add Addition',
                    edit: 'Modify Modification Adjust Adjustment Allot Allotment Customize Customization Manage Edit Set Up Setup Configure Configuration Add Remove Change',
                    assign: 'Assign Set Allocate Allot Give',
                    user: 'Account Accounts Username Usernames User Users',
                    group: 'Group Groups',
                    module: 'Module Modules',
                    permissions: 'Permission Permissions User Users Right Rights',
                    features: 'Global Setting Settings Set GS Feature',
                    toggle: 'Turn On Off Activate Deactivate Stop Start Switch Toggle',
                    fields: 'Field Fields Column Columns Combo box Combobox Enum Enums List Lists Drop-down Drop-downs Dropdown Dropdowns',
                    reassignLicenses: 'Assign Assignment User Users Allocate Allocation',
                    additionalField: 'AF Additional Custom Field Fields',
                    systemField: 'System Fields Field',
                    workflow: 'Workflow Workflows',
                    subscriptions: 'License Licences Account Accounts Pay Payments Purchase Purchases Buy Subscribe Subscription Subscriptions Unsubscribe Cancel Cancellation Deactivate Deactivation Quote Quotation Price Cost Register Registration',
                    billingHistory: 'Invoice Invoices Purchase Purchased Purchases Pay Paid Payments Payment Buy Bought Unpaid Overdue',
                    paymentMethods: 'Credit Card Cards Debit Pay Payment Payments VISA Master Mastercard Amex',
                    updates: 'Update Updates Upgrade Upgrades'
                },
                apps: {
                    name: 'Apps',
                    oldAdminSettings: {
                        name: 'Old Administration Settings',
                        synonyms: 'Past Former Previous Classic Classical Admin App Apps Application Tool'
                    },
                    powerBI: {
                        name: 'Get the eWay-CRM Sales Executive Dashboard',
                        synonyms: 'Data Visualize Visualization MS Microsoft PowerBI Report Reports Reporting Business Analytics Intelligence Dashboard Dashboards Query View Connector App Apps'
                    },
                    zapier: {
                        name: 'Integrate with 4000+ Apps using the Zapier Connector',
                        synonyms: 'Automate Automation Connect Connection Integrate Integration Zap Workflow App Apps'
                    }
                },
            },
            licenseRestrictions: {
                unlockThisModule: 'Unlock this module',
                unlockThisField: 'Unlock this field',
                withPlan: 'with the {0} plan',
                itemsAvailable: 'Items available',
                needMore: 'Need more?',
                upgradeYourPlan: 'Upgrade your plan.',
                upgradePlan: 'Upgrade Plan',
                activeWfSingular: 'active',
                activeWfPluralTwoToFour: 'active',
                activeWfPluralFiveAndMore: 'active',
                advancedWfSingular: 'advanced',
                advancedWfPluralTwoToFour: 'advanced',
                advancedWfPluralFiveAndMore: 'advanced',
                basicWfPluralSingular: 'basic',
                basicWfPluralTwoToFour: 'basic',
                basicWfPluralFiveAndMore: 'basic',
                mandatoryFieldsCustomTitle: 'Upgrade your plan to unlock more required fields.',
                importantFieldsCustomTitle: 'Upgrade your plan to unlock more important fields.',
                workflowCustomTitle: 'Upgrade your plan to unlock more workflow types.',
                excelExportModuleLocked: 'Upgrade your plan to unlock this module',
                learnMore: 'Learn more',
                workflowCountExceededTitle: 'Too many {0} workflows',
                workflowCountExceededAlert: 'Too many {0} workflows. Disable {1} more or',
                workflowAlertUpgradeYourPlan: 'upgrade your plan',
                workflowAdvancedNotAvailable: 'Advanced workflows are not available',
                workflowCountExceeded: 'Your current plan allows you to have only {0} {1} workflows, but you have {2}. Disable the unnecessary ones by right-clicking on them and selecting Disable.',
                workflowCountNotAvailable: 'Your current plan doesn\'t support {0} workflows, but you have {1}. Disable the unnecessary ones by right-clicking on them and selecting Disable.',
                workflowUpgradePlanToExtendLimit: 'You can also {0} to extend your limit.',
                upgradeYourPlanBtn: 'upgrade your plan',
                userNotAuthorizedToUseModule: '{0} is not authorized to use this module. You need to either {1} or {2}.',
                user: 'User',
                assignLicense: 'assign the license',
                buyNewOne: 'buy a new one',
                fields: {
                    fieldsCountExceeded: 'Your current plan allows you to have only {0} {1}, but you have {2}.',
                    fieldsCountNotAvailable: 'Your current plan doesn\'t support {0}, but you have {1}.',
                    customFieldsCountExceededTitle: 'Maximum number of custom fields exceeded',
                    customFieldsCountExceededMessage: 'Delete the unnecessary ones by selecting them and clicking on Delete button.',
                    itemTypesCountExceededTitle: 'Maximum number of item types exceeded',
                    typesCountExceededMessage: 'You can edit the {0} field and disable the unnecessary ones.',
                    itemTypeFieldName: 'type',
                    permissionsCountExceededMessage: 'Please deactivate the flag "{0}" from the unnecessary fields.',
                    mandatoryFieldsCountExceededTitle: 'Maximum number of required fields exceeded',
                    importantFieldsCountExceededTitle: 'Maximum number of important fields exceeded',
                    uniqueFieldsCountExceededTitle: 'Unique fields are not available',
                    readonlyFieldsCountExceededTitle: 'Read only fields are not available',
                    currecyFieldsCountExceededTitle: 'Maximum number of currencies exceeded',
                    fieldsUpgradePlanToExtendLimit: 'You can also {0} to extend your limit.',
                    itemTypesSingular: 'item type',
                    itemTypesPluralTwoToFour: 'item types',
                    itemTypesFiveAndMore: 'item types',
                    customFields: 'custom fields',
                    mandatoryFieldsSingular: 'required field',
                    mandatoryFieldsPluralTwoToFour: 'required fields',
                    mandatoryFieldsPluralFiveAndMore: 'required fields',
                    importantFieldsSingular: 'important field',
                    importantFieldsPluralTwoToFour: 'important fields',
                    importantFieldsPluralFiveAndMore: 'important fields',
                    uniqueFields: 'unique fields',
                    readonlyFields: 'read only fields',
                    currencyFieldsSingular: 'active currency',
                    currencyFieldsPluralTwoToFour: 'active currencies',
                    currencyFieldsPluralFiveAndMore: 'active currencies',
                    currencyFieldName: 'currency'
                }
            }
        },
        features: {
            'General': { name: 'General', customTitle: 'General Features', description: 'Configure your regional settings, force apply group policies and/or set up our telephony module (PBX).' },
            'Security': { name: 'Security', customTitle: 'Security Features', description: 'Make sure your company data are safe.' },
            'ContactsAndCompanies': { name: 'Contacts & Companies', description: DEFAULT_FEATURES_DESCRIPTION.en },
            'MarketingCampaigns': { name: 'Marketing', description: DEFAULT_FEATURES_DESCRIPTION.en },
            'Projects': { name: 'Projects', description: DEFAULT_FEATURES_DESCRIPTION.en },
            'QuotesAndBilling': { name: 'Quotes & Billing', description: DEFAULT_FEATURES_DESCRIPTION.en },
            'Sales': { name: 'Sales', description: DEFAULT_FEATURES_DESCRIPTION.en },
            'TimeSheetsAndPlanning': { name: 'Time Sheets & Planning', description: DEFAULT_FEATURES_DESCRIPTION.en }
        },
        fieldTypes: {
            textBox: 'Single Line Text',
            memoBox: 'Multi Line Text',
            numericBox: 'Number',
            dateEdit: 'Date',
            checkBox: 'Check Box',
            comboBox: 'Drop Down',
            multiSelectComboBox: 'Multi Select Drop Down',
            relation: 'Relation',
            linkTextBox: 'Link',
            image: 'Image',
            workflowState: 'Workflow Status',
            multiSelectRelation: 'Multi Select Relation'
        },
        summaryTypes: {
            min: 'Minimum',
            max: 'Maximum',
            average: 'Average',
            sum: 'Sum',
            count: 'Count'
        },
        validatorTypes: {
            currency: 'Currency',
            double: 'Decimal',
            int: 'Integer',
            fileSize: 'File Size',
            percents: 'Percents'
        },
        linkTypes: {
            none: 'General',
            http: 'URL',
            email: 'Email',
            file: 'File'
        },
        lockedContent: {
            title: 'Feature Locked',
            text: 'This feature is only available for Premium users.',
            buttonText: 'Buy Here',
        }
    },
    cs: {
        switch: (_: string | null, cs: string | null) => cs,
        pickTranslation: <T>(t: Record<'En' | 'Cs', T> | null | undefined) => pickTranslation<'En' | 'Cs', T>(t, (tt) => tt.Cs, 'En'),
        pickNameTranslation: <T>(t: Record<'NameEn' | 'NameCs', T> | null | undefined) => pickTranslation<'NameEn' | 'NameCs', T>(t, (tt) => tt.NameCs, 'NameEn'),
        formatDate: formatDateCs,
        formatDateTime: formatDateTimeCs,
        formatNumber: (nr: number | null, numDec: number) => formatNumber(nr, numDec, 'cs'),
        formatCurrency: (nr: number | null, numDec: number, currencyCode: string | null) => formatCurrency(nr, currencyCode, numDec, 'cs'),
        ok: 'OK',
        no: 'Ne',
        yes: 'Ano',
        cancel: 'Zrušit',
        discard: 'Storno',
        done: 'Hotovo',
        next: 'Další',
        back: 'Zpět',
        save: 'Uložit',
        saveChanges: 'Uložit změny',
        close: 'Zavřít',
        refresh: 'Obnovit',
        duplicate: 'Duplikovat',
        delete: 'Odstranit',
        search: 'Hledat',
        filter: 'Filtrovat',
        on: 'Zapnuto',
        off: 'Vypnuto',
        active: 'Aktivní',
        inactive: 'Neaktivní',
        and: 'a',
        continue: 'Pokračovat',
        create: 'Vytvořit',
        inModuleSinglular: 'modulu',
        inModulePlural: 'modulech',
        createNewItem: 'Vytvořit novou položku',
        selectedFormat: 'Vybráno {0}',
        companies: 'Společnosti',
        contacts: 'Kontakty',
        marketingCampaigns: 'Marketingové kampaně',
        activeProjects: 'Aktivní projekty',
        completedProjects: 'Dokončené projekty',
        deadProjects: 'Ztracené projekty',
        activeDeals: 'Aktivní příležitosti',
        completedDeals: 'Dokončené příležitosti',
        deadDeals: 'Ztracené příležitosti',
        activeTasks: 'Aktivní úkoly',
        loadingError: 'Omlouváme se, ale data se nepodařilo načíst.',
        thisFieldIsMandatory: 'Toto pole je povinné',
        saveChangesContinue: 'Uložit a pokračovat',
        discardChanges: 'Zahodit změny',
        unsavedChanges: 'Neuložené změny',
        youHaveUnsavedChanges: 'Byly provedeny změny, které nebyly uloženy. Chcete tyto změny zahodit?',
        allChangesWillBeSavedOnSave: 'Všechny změny se uloží klepnutím na tlačítko Uložit.',
        export: 'Exportovat',
        exportToExcel: 'Exportovat do Excelu',
        confirmRouteLeave: 'Opravdu chcete opustit tuto stránku? Některé změny nebyly uloženy.',
        confirmRouteChange: 'Chcete uložit změny před opuštěním stránky?',
        confirmRouteChangeTitle: 'Neuložené změny',
        saveErrorMessage: 'Změny se nepodařilo uložit. Načtěte prosím stránku znovu.',
        savingMessageSingular: 'Ukládání změny',
        savingMessagePlural: 'Ukládání {0} změn',
        copy: 'Kopírovat',
        clickToCopy: 'Kliknutím zkopírujete',
        copied: 'Zkopírováno!',
        remove: 'Odebrat',
        add: 'Přidat',
        components: {
            routes: {
                home: {
                    title: 'Vítejte v Centru pro správu eWay-CRM',
                    featuredAdminVideos: 'Vytvořte si vlastní eWay-CRM',
                    loginActivity: 'Poslední přihlášení',
                    billing: 'Fakturace',
                    more: 'Zobrazit více',
                    less: 'Zobrazit méně',
                    noData: 'Žádná data nejsou k dispozici.',
                    newFeaturesWizard: {
                        title: 'To nejlepší z eWay-CRM',
                        subtitle: 'Uživatelé jsou často překvapeni, když narazí na funkce, o kterých v eWay-CRM nikdy neslyšeli, a přitom jim umí ušetřit spoustu času. Proto jsme připravili sadu krátkých návodů, které představují ty nejlepší vychytávky z našeho CRM.',
                        sendTipsLabel: 'Můžeme je vašim kolegům postupně představit?',
                        cancelButton: 'Zeptat se později',
                        sendTipsPlaceholder: 'Vyberte možnost',
                        dropdownError: 'Vyberte jednu z možností'
                    }
                },
                users: {
                    title: 'Uživatelé',
                    subtitle: 'Spravujte uživatele napříč vaší firmou. Přiřazením do skupiny jim definujte specifická oprávnění.',
                    fullName: 'Celé jméno',
                    username: 'Uživatelské jméno',
                    msAzureAccount: 'Účet Microsoft Azure',
                    email: 'E-mail',
                    license: 'Licence',
                    lastLogin: 'Poslední přihlášení',
                    lastActivity: 'Poslední aktivita',
                    addUser: 'Přidat uživatele',
                    resetPassword: 'Resetovat heslo',
                    forceChangePassword: 'Vynutit změnu hesla',
                    forceChangePasswordMessageSingular: 'Opravdu chcete u tohoto uživatele vynutit změnu hesla při příštím přihlášení?',
                    forceChangePasswordMessagePlural: 'Opravdu chcete u těchto uživatelů vynutit změnu hesla při příštím přihlášení?',
                    assignToGroup: 'Přiřadit do skupin',
                    delegate: 'Převést',
                    deactivate: 'Deaktivovat',
                    activate: 'Aktivovat',
                    unlock: 'Odemknout',
                    showInactiveUsers: 'Zobrazit neaktivní',
                    showSystemUsers: 'Zobrazit systémové',
                    system: 'Systémový',
                    you: 'Vy',
                    addNewUser: 'Přidat nového uživatele',
                    general: 'Obecné',
                    groups: 'Skupiny',
                    assignGroups: 'Přiřadit skupiny',
                    special: 'Další nastavení',
                    firstName: 'Jméno',
                    lastName: 'Příjmení',
                    supervisor: 'Nadřízený',
                    noSupervisor: '(bez nadřízeného)',
                    defaultGroup: 'Výchozí skupina',
                    noDefaultGroup: '(bez výchozí skupiny)',
                    locked: 'Uzamčen',
                    ewayLicense: 'Licence',
                    noLicense: '(bez licence)',
                    apiOnly: 'API (používá se pro integrace)',
                    buyMore: 'Koupit více',
                    buyMoreLicenses: 'Koupit více licencí',
                    cannotAssignLicenseTitle: 'Licence nelze přiřadit',
                    cannotAssignLicenseMessage: 'Nemůžete přiřadit tuto licenci, aniž abyste zároveň přiřadili licenci {0}.',
                    passwordSettings: 'Volby hesla',
                    autogeneratePassword: 'Vygenerovat heslo automaticky',
                    letMeCreatePassword: 'Zadat heslo ručně',
                    password: 'Heslo',
                    sendTipsAndTricks: 'Posílat tipy pro práci s eWay-CRM',
                    forceChangePasswordOnLogin: 'Vynutit změnu hesla po prvním přihlášení',
                    sendInstalationInstructions: 'Zaslat uživateli e-mail s instrukcemi k instalaci',
                    containsPassword: 'obsahuje heslo',
                    sendNewPassword: 'Zaslat nové heslo e-mailem',
                    unableSendNewPasswordFormat: 'Nelze zaslat nové heslo e-mailem. Uživatel {0} nemá vyplněnou e-mailovou adresu.',
                    projectManager: 'Projektový manažer',
                    exchangeRatesAdmin: 'Upozorňovat na chyby ve směnných kurzech',
                    sendServerUpdateStatus: 'Zasílat informace o stavu aktualizace serveru',
                    sendSystemHealthNotifications: 'Zasílat informace o chybách systému',
                    systemApiUser: 'Systémový uživatel API',
                    notifyUnpaidInvoices: 'Upozorňovat na nezaplacené faktury',
                    notifyDeadLeads: 'Upozorňovat na příležitosti po termínu',
                    forceEmailTrancking: 'Vynutit sledování e-mailů',
                    sendPhoneTaskList: 'Zasílat seznam k telefonování',
                    notifyPohodaInvoiced: 'Upozorňovat na vyfakturované faktury nahrané ze systému Pohoda',
                    notifyPohodaPaid: 'Upozorňovat na zaplacené faktury nahrané ze systému Pohoda',
                    passwordSuperweak: 'Příliš slabé',
                    passwordWeak: 'Slabé',
                    passwordGood: 'Dobré',
                    passwordStrong: 'Silné',
                    createUser: 'Založit uživatele',
                    createUsers: 'Založit uživatele',
                    groupName: 'Název skupiny',
                    assign: 'Přiřadit',
                    reset: 'Změnit',
                    usersWillHaveSamePassword: 'Tato akce nastaví všem vybraným uživatelům stejné heslo. Z bezbečnostních důvodů doporučujeme vynutit změnu hesla při prvním přihlášení.',
                    reassignFollowing: 'Delegovat následující jinému uživateli',
                    newOwner: 'Nově zodpovědný uživatel',
                    selectUniqueUsername: 'Toto uživatelské jméno je již použito.',
                    selectUniqueEmail: 'Tento e-mail je již použit u jiného uživatele.',
                    emailTooLong: 'E-mail musí mít méně než {0} znaků.',
                    usernameTooLong: 'Uživatelské jméno musí mít méně než {0} znaků.',
                    usernameTooShort: 'Uživatelské jméno je příliš krátké.',
                    passwordTooShort: 'Heslo je příliš krátké.',
                    passwordTooWeak: 'Heslo je příliš slabé.',
                    passwordEnterUsernameFirst: 'Nejprve vyplňte uživatelské jméno.',
                    wrongEmail: 'E-mail je ve špatném tvaru.',
                    reassignLicenses: 'Přiřadit licence',
                    nextUser: 'Další uživatel',
                    previousUser: 'Předchozí uživatel',
                    noUsers: 'Žádní uživatelé k zobrazení.',
                    noUsersMatch: 'Žádní uživatelé neodpovídají hledanému textu.',
                    userWillBeAddedToGroup: 'Uživatel bude přidán do skupiny',
                    userWillBeRemovedFromGroup: 'Uživatel bude odebrán ze skupiny',
                    categories: 'Kategorie',
                    effectivePermissions: 'Aktuální oprávnění',
                    effectivePermission: 'Aktuální oprávnění',
                    modulePermissions: 'Oprávnění na moduly',
                    fieldPermissions: 'Oprávnění na pole',
                    highest: 'nejvyšším',
                    lowest: 'nejnižším',
                    presenceInGroups: 'Členství ve skupině s',
                    permissionAffectsAbility: 'oprávněním ovlivňuje schopnost',
                    items: 'položky',
                    affects: 'Ovlivňuje',
                    workWithField: 'pracovat s polem',
                    quicklyAssignGroups: 'Rychle přiřadit skupiny',
                    addUserToGroupTitle: 'Přidat uživatele do skupiny',
                    addUserToGroupText: 'Opravdu chcete přidat uživatele {0} do skupiny {1}?',
                    removeUserFromGroupTitle: 'Odebrat uživatele ze skupiny',
                    removeUserFromGroupText: 'Opravdu chcete odebrat uživatele {0} ze skupiny {1}?',
                    cannotResetPasswordWithAD: 'Heslo nelze resetovat, protože využíváte zabezpečení skrz Azure nebo Windows. Prosím resetujte hesla tam.'
                },
                groups: {
                    title: 'Skupiny',
                    subtitle: 'Vytvářejte skupiny a přiřazujte do nich jednotlivé uživatele. Pomocí skupin můžete nastavovat různá oprávnění, e-mailové notifikace apod.',
                    system: 'Systemová',
                    administrator: 'Administrátor',
                    groupName: 'Název skupiny',
                    numberOfUsers: 'Počet uživatelů',
                    description: 'Popis',
                    note: 'Poznámka',
                    addGroup: 'Přidat skupinu',
                    assignUsers: 'Přiřadit uživatele',
                    users: 'Uživatelé',
                    special: 'Další nastavení',
                    defaultLayouts: 'Výchozí formuláře',
                    addNewGroup: 'Nová skupina',
                    general: 'Obecné',
                    user: 'Uživatel',
                    username: 'Uživatelské jméno',
                    isProjectManager: 'Tato role je používána k označení uživatele jako projektového manažera.',
                    isProjectManagerDescription: 'Každý tým musí v eWay-CRM® obsahovat jednoho uživatele, který plní roli projektového manažera. Pokud zaškrtnete toto pole, bude právě tato role reprezentovat projektové manažery. V celém systému může být nanejvýš jedna role projektového manažera.',
                    isProjectManagerDescriptionMore: 'Dozvědět se více o projektových manažerech v týmu.',
                    isProjectManagerDescriptionMoreUrl: 'https://eway.cr/oZ4T9',
                    projectManager: 'Projektový manažer',
                    isRole: 'Zpřístupnit jako týmovou roli v projektech',
                    isRoleDescription: 'Pokud zaškrtnete toto pole, bude tuto skupinu možno zároveň používat jako roli při editaci týmu projektu.',
                    isRoleDescriptionTeams: 'Dozvědět se více o týmech.',
                    isRoleDescriptionTeamsUrl: 'https://eway.cr/0sOcx',
                    createGroup: 'Vytvořit skupinu',
                    assign: 'Přidat',
                    duplicateGroup: 'Duplikovat nastavení skupiny',
                    duplicateGroupErrorIsSystem: 'Skupina {0} již existuje a nelze do ní duplikovat.',
                    duplicateGroupErrorReserved: 'Název skupiny {0} nelze použít.',
                    targetGroupName: 'Zadejte název nové skupiny nebo vyberte z existujících',
                    new: 'Nová',
                    copyUsers: '{0} uživatele',
                    copyModulePermissions: '{0} oprávnění na moduly',
                    copyFieldPermissions: '{0} oprávnění na pole',
                    include: 'Zahrnout',
                    replace: 'Nahradit',
                    deleteGroupSingularTitle: 'Odstranit skupinu',
                    deleteGroupSingularMessage: 'Opravdu chcete odstranit vybranou skupinu?',
                    deleteGroupsPluralTitle: 'Odstranit skupiny',
                    deleteGroupsPluralMessage: 'Opravdu chcete odstranit všechny vybrané skupiny?',
                    writeNotes: 'Přidejte poznámky.',
                    selectUniqueName: 'Toto jméno je již použito.',
                    groupNameTooShort: 'Jméno je příliš krátké.',
                    pmAlreadySpecifiedTitle: 'Vyměnit roli projektového manažera?',
                    pmAlreadySpecified: 'Projektoví manažeři jsou nyní označeni rolí "{0}". Jste si jisti, že chcete nově označovat projektové manažery touto rolí?',
                    noGroups: 'Žádné skupiny k zobrazení.',
                    noGroupsMatch: 'Žádné skupiny neodpovídají hledanému textu.',
                    modulePermissions: 'Oprávnění na moduly',
                    fieldPermissions: 'Oprávnění na pole',
                    exportThisTable: 'Exportovat seznam skupin',
                    exportGroupsWithModulePermissions: 'Exportovat skupiny s právy na moduly',
                    exportGroupsWithFieldPermissions: 'Exportovat skupiny s právy na pole',
                    defaultFormLayouts: {
                        waOnly: 'Tato funkce je podporována pouze v eWay-CRM Online. Není k dispozici v eWay-CRM Desktop ani eWay-CRM Mobile',
                        folderName: 'Modul',
                        layout: 'Formulář',
                        noMatch: 'Žádný modul neodpovídá hledanému textu.',
                        noData: 'Žádný modul k zobrazení.',
                        noLayout: '(výchozí)'
                    }
                },
                modulePermissions: {
                    complexCombinationWarningText: 'Pro uživatele eWay-CRM Mobile: Kvůli optimalizaci výkonu musíme nahradit právo na editaci v {0} {1} oprávněním "Vlastní".',
                    complexWarningStripText: "Tip pro rychlejší eWay-CRM: Mějte svá oprávnění nastavená jednoduše.",
                    title: 'Oprávnění na moduly skupiny {0}',
                    subtitle: 'Upravte si oprávnění na moduly skupiny {0}. Pomocí tlačítka Ovlivnění uživatelé rychle zjistíte, komu se oprávnění změní.',
                    splashMessage: 'Vyberte skupinu pro správu jejích oprávnění na moduly.',
                    noModulePermissions: 'Žádný modul k zobrazení.',
                    noModulePermissionsMatch: 'Žádný modul neodpovídá hledanému textu.',
                    legend: 'Legenda',
                    affectedUsers: 'Ovlivnění uživatelé',
                    affectedUsersDescription: 'Poznámka: Mohou existovat skupiny, které oprávnění této skupiny pro tyto uživatele přebíjí.',
                    exportThisTable: 'Exportovat pouze tuto tabulku',
                    exportAllTables: 'Exportovat všechny tabulky všech skupin',
                    duplicate: {
                        title: 'Duplikovat oprávnění na moduly',
                        description: 'Všechna oprávnění na vybraných skupin budou přepsána právy skupiny {0}.',
                        targetGroup: 'Cílové skupiny',
                        includeFieldPermissions: 'Zahrnout oprávnění na pole',
                        okButtonTitle: 'Duplikovat',
                        noGroup: '<Vybrat skupinu>',
                    },
                    columns: {
                        moduleTitle: 'Modul',
                        viewTitle: 'Zobrazit',
                        viewAction: 'vidět',
                        editTitle: 'Upravit',
                        editAction: 'editovat',
                        deleteTitle: 'Odstranit',
                        deleteAction: 'odstraňovat',
                        canCreateTitle: 'Vytvořit',
                        canCreateAction: 'vytvářet nové položky',
                        canExportTitle: 'Exportovat',
                        canExportAction: 'exportovat',
                        canSeeHistoryTitle: 'Historie',
                        canSeeHistoryAction: 'vidět historii',
                        rowsRestrictionTitle: 'Počet viditelných položek',
                        rowsRestrictionAction: 'vidět omezený počet'
                    },
                    options: {
                        allPermissionsText: 'Uživatelé mohou {0} všechny položky',
                        allPermissionsDescription: 'Vše',
                        inheritedPermissionsText: 'Uživatelé mohou {0} položky podřízené ke kontaktům, společnostem, příležitostem nebo projektům, které mohou vidět',
                        inheritedPermissionsDescription: 'Podřízené k projektům, příležitostenm, společnostem a kontaktům',
                        groupPermissionsText: 'Uživatelé mohou {0} položky svázané s kategoriemi, kterých jsou členy',
                        groupPermissionsDescription: 'Svázané se stejnými kategoriemi jako uživatelé',
                        relatedPermissionsText: 'Uživatelé mohou {0} položky svázané s nimi',
                        relatedPermissionsDescription: 'Svázané s uživateli',
                        ownPermissionsText: 'Uživatelé mohou {0} své položky',
                        ownPermissionsDescription: 'Vlastní',
                        nonePermissionsViewText: 'Uživatelé modul vůbec nevidí',
                        nonePermissionsText: 'Uživatelé nemohou nic {0}',
                        nonePermissionsDescription: 'Žádné / Skrýt modul',
                        truePermissionsText: 'Uživatelé mohou {0}',
                        falsePermissionsText: 'Uživatelé vůbec nemohou {0}',
                        noRestrictionsText: 'Všechny',
                        rowRestrictionsSuffixSingular: 'položka',
                        rowRestrictionsSuffixPluralTwo: 'položky',
                        rowRestrictionsSuffixPluralFiveAndMore: 'položek',
                    }
                },
                fieldPermissions: {
                    noFieldPermissions: 'Žádná pole k zobrazení.',
                    noFieldPermissionsMatch: 'Žádné pole neodpovídá hledanému výrazu.',
                    additionalField: 'Uživatelské pole',
                    checkingForDuplicatedValues: 'Kontrolujeme duplicity u existujících záznamů',
                    fieldCannotBeEdited: 'Pole nelze editovat',
                    fieldCannotBeSetUnique: 'Pole nelze nastavit jako unikátní',
                    nonUniqueValueDialogTitle: 'Nalezeny duplicity',
                    nonUniqueValueDialogMessage: 'Pole není možné nastavit jako unikátní, protože obsahuje duplicity: "{0}". Nejprve se prosím podívejte do eWay-CRM a duplicity odstraňte.',
                    nonUniqueValueDialogMessageBlank: 'Pole není možné nastavit jako unikátní, protože obsahuje duplicitní prázdné hodnoty. Nejprve se prosím podívejte do eWay-CRM a duplicity odstraňte.',
                    uniqueNotFullyPermittedDialogTitle: 'Nelze nastavit jako unikátní',
                    uniqueNotFullyPermittedDialogMessage: 'Chcete-li nastavit pole jako unikátní, aktualizujte nejprve úroveň oprávnění pole ve všech skupinách na Plná. Ta není nastavena například ve skupině "{0}".',
                    currentPermissionsDontAllowEdit: 'Vaše současná oprávnění neumožňují editaci tohoto pole.',
                    columns: {
                        columnTitle: 'Název',
                        columnGroupNameTitle: 'Název skupiny',
                        permissionTitle: 'Úroveň oprávnění',
                        isMandatoryTitle: 'Povinné',
                        isNotMandatory: 'Není povinné',
                        isOptionalTitle: 'Důležité',
                        isNotOptional: 'Není důležité',
                        isUniqueTitle: 'Unikátní',
                        isNotUnique: 'Není unikátní',
                        isReadonlyTitle: 'Jen pro čtení'
                    },
                    options: {
                        allPermissionsText: 'Uživatelé mají plnou kontrolu nad tímto polem',
                        allPermissionsDescription: 'Plná',
                        ownPermissionsText: 'Uživatelé mají plnou kontrolu nad tímto polem u svých položek',
                        ownPermissionsDescription: 'Vlastní',
                        readonlyPermissionsText: 'Uživatelé vidí toto pole, ale nemohou měnit jeho hodnotu',
                        readonlyPermissionsDescription: 'Jen pro čtení',
                        invisiblePermissionsText: 'Uživatelé nevidí toto pole',
                        invisiblePermissionsDescription: 'Neviditelné',
                        nonePermissionsText: 'Uživatelé toto pole nevidí ani nemohou pracovat s jeho hodnotami',
                        nonePermissionsDescription: 'Žádné / Skrýt pole',
                    }
                },
                features: {
                    title: 'Funkce v modulu {0}',
                    splashMessage: 'Vyberte modul pro správu jeho funkcí.',
                    active: 'Aktivní',
                    feature: 'Funkce',
                    description: 'Popis',
                    noFeatureMatch: 'Žádné funkce k zobrazení.',
                    showRetired: 'Zobrazit vyřazené',
                    settingsTab: 'Nastavení',
                    modulePermissionsTab: 'Oprávnění na moduly',
                    fieldPermissionsTab: 'Oprávnění na pole',
                    timeRangePickerBetween: 'a',
                    selectGroups: 'Vybrat skupiny',
                    selectOptions: 'Vybrat možnosti',
                    externalLinkTooltip: 'Zjistěte více o této funkci v eWay-CRM Knowledge Base',
                    unlimited: 'Neomezený počet',
                    setUnlimited: 'Nastavit neomezený počet',
                    disabled: 'Vypnuto',
                    setDisabled: 'Nastavit jako vypnuté',
                },
                fields: {
                    title: 'Pole v modulu {0}',
                    subtitle: 'Projděte si úplný seznam polí v modulu {0}. Změňte jejich vlastnosti, nastavte oprávnění nebo si vytvořte vlastní pole.',
                    splashMessage: 'Vyberte modul pro správu jeho polí.',
                    name: 'Název',
                    type: 'Typ',
                    dbColumnName: 'Databázové pole',
                    dbColumnNameTooltip: 'Databázový název políčka. Může se hodit v případech, kdy si tvoříte vlastní workflow akce, reporty nebo synchronizační můstky.',
                    description: 'Popis',
                    addField: 'Přidat pole',
                    general: 'Obecné',
                    fieldPermissions: 'Oprávnění na pole',
                    fieldPermissionsTabDisabled: 'Editace oprávnění na toto pole není podporována.',
                    modules: 'Moduly',
                    noModule: '<Přidat modul>',
                    category: 'Kategorie',
                    noCategory: '<Žádná kategorie>',
                    editMask: 'Editační maska',
                    height: 'Výška pole',
                    values: 'Hodnoty',
                    format: 'Formát',
                    summarizationType: 'Typ sumarizace',
                    linkType: 'Typ odkazu',
                    showTime: 'Zobrazovat čas',
                    relatedTable: 'Svázaný modul',
                    comingSoon: 'Již brzy.',
                    createField: 'Vytvořit pole',
                    deleteAfSingularTitle: 'Odtranit uživatelské pole',
                    deleteAfSingularMessage: 'Opravdu chcete odstranit vybrané uživatelské pole?',
                    deleteAfPluralTitle: 'Odtranit uživatelská pole',
                    deleteAfPluralMessage: 'Opravdu chcete odstranit vybrané uživatelská pole?',
                    noFieldsCanBeDeleted: 'Vybraná uživatelská pole jsou použita v uživatelských pohledech nebo formulářích. Z toho důvodu je nelze smazat.',
                    someFieldsCannotBeDeleted: 'Některá z vybraných uživatelských polí jsou použita v uživatelských pohledech nebo formulářích. Přejete si smazat alespoň zbytek vybraných polí vypsaný výše?',
                    fieldNameAlreadyUsedFormat: 'Název pole je již v modulu {0} používán.',
                    fieldNameReservedFormat: 'Název pole je v modulu {0} rezervován.',
                    rankAutomaticallySaved: 'Změna pořadí se ihned ukládá.',
                    noAfsInFolderFormat: 'Pro modul {0} nebyla nalezena žádná pole.\nProsím zkontrolujte, zda nemáte aplikovaný filtr.',
                    noAfsInFolderMatchFormat: 'Žádná pole v modulu {0} neodpovídají hledanému textu.',
                    typeAndModuleAreInvalid: 'Modul Marketingový seznam nemůže obsahovat uživatelská pole typu relace nebo zaškrtávací seznam.',
                    isUnique: 'Unikátní',
                    exportThisTable: 'Exportovat pouze tento modul',
                    exportAllTables: 'Exportovat všechny moduly',
                    toggles: {
                        isRequiredTooltip: 'Všichni uživatelé musí vyplnit hodnotu v tomto poli, když vytváří nebo upravují záznam.',
                        isImportantTooltip: 'Všichni uživatelé budou vyzváni k vyplnění hodnoty v tomto poli. Hlášku však mohou přeskočit, pokud hodnotu neznají.',
                        isUniqueTooltip: '{0} pole jsou skvělá, když potřebujete zajistit, aby měl každý záznam neprázdnou, unikátní hodnotu. Abychom toho dosáhli, prosím postupujte takto:',
                        isUniqueTooltipList: '1. Vytvořte pole jako ne-unikátní.\n' +
                            '2. Zaktualizujte vaše existující položky v modulu {0} a doplňte do vašeho nového pole požadovanou hodnotu. Nejrychlejší je data vyexportovat do Excelu, upravit je tam a naimportovat zpět.\n' +
                            '3. Jakmile budou všechny změny nahrané na server, vraťte se do Centra pro správu a nastavte políčko jako Unikátní.',
                        isUniqueTooltipNote: 'Pozn:',
                        isUniqueTooltipNoteText: 'Unikátní pole lze nastavit pouze pro jednořádkový text a URL.',
                        isReadOnlyTooltip: 'Uživatelé nebudou moci měnit hodnotu v tomto poli. Tento parametr se výborně hodí v případech, kdy má být hodnota automaticky počítána zakázkovým algoritmem.',
                        generalTooltip: 'Pokud potřebujete nastavit toto pole jako {0} jen pro vybrané skupiny, toto nastavení vypněte a přejděte do sekce Oprávnění na pole',
                    },
                    showSystemFields: 'Zobrazit systémová pole',
                    showCustomFields: 'Zobrazit uživatelská pole',
                    tooltips: {
                        cantCreateCustomField: 'V modulu {0} nejsou uživatelská pole podporována.',
                        cantDuplicateFields: 'V modulu {0} nejsou uživatelská pole podporována.',
                        cantDeleteFields: 'Nelze mazat systémová pole.',
                        cantDuplicateSystemOnlyField: 'Nelze duplikovat pole typu {0}.',
                        cantManageFieldsInProducts: 'V modulu {1} nelze {0} pole. Použijte modul {2}, ze kterého se pole propisují i do tohoto modulu.',
                        fieldCannotBeModified: 'Toto pole nelze editovat.'
                    },
                    changesWillBeAlsoDuplicatedToFolderNameSingular: 'Změny tohoto pole se automaticky propíšou do stejného pole v modulu {0}.',
                    changesWillBeAlsoDuplicatedToFolderNamePlural: 'Změny tohoto pole se automaticky propíšou do stejného pole v modulech {0}.',
                    changesWillBeAlsoDuplicatedToFields: 'Změny parametru Úroveň oprávnění se automaticky propíšou do polí {0}',
                    dropDowns: {
                        name: 'Název',
                        techName: 'Technický název',
                        additionalField: 'Uživatelské pole',
                        en: 'Anglicky',
                        cs: 'Česky',
                        de: 'Německy',
                        ru: 'Rusky',
                        no: 'Norsky',
                        sk: 'Slovensky',
                        system: 'Systémová hodnota',
                        systemEnumType: 'Systémový',
                        default: 'Výchozí',
                        visible: 'Povolené',
                        useForLastActivity: 'Zahrnout do výpočtu poslední aktivity',
                        useForNextStep: 'Zahrnout do výpočtu dalšího kroku',
                        noValuesYet: 'Zatím žádné hodnoty. Přijdete nějaké kliknutím na tlačítko + v levém horním rohu.',
                        stageName: 'Jméno stavu',
                        stageAttributes: 'Příznak',
                        addColumn: 'Přidat překlad',
                        hideColumn: 'Schovat sloupec',
                    },
                    enumValueCannotBeDeletedIsSystem: 'Systémové hodnoty nelze mazat',
                    enumValueCannotBeDeletedIsUsed: 'Tato hodnota je již použita v některé z položek v eWay-CRM',
                    enumValuesErrorTitle: 'Nelze uložit',
                    enumValuesErrorDuplicateText: 'V hodnotách seznamu jsou duplicitní hodnoty. Nejprve je opravte a pak to zkuste znovu.\n\nMůžete začít ve sloupci {0} na řádku s hodnotou "{1}".',
                    enumValuesErrorEmptyText: 'Některé hodnoty v seznamu jsou prázdné. Nejprve je vyplňte nebo odstraňte a pak to zkuste znovu.\n\nMůžete začít ve sloupci {0}.'
                },
                workflow: {
                    title: 'Workflow',
                    subtitleSimpleEditor: 'Toto je základní workflow. Můžete přidat nové kroky, upravit nebo odstranit stávající nebo změnit jejich pořadí.',
                    subtitleAdvancedEditor: 'Toto je pokročilé workflow. Pomocí editoru definujte jaké kroky mají vaši zaměstnanci dodržovat.',
                    splashMessage: 'Vyberte modul pro správu jeho workflow.',
                    subtitleEditorPicker: 'Vyberte si typ návrháře a začněte.',
                    simple: 'Základní',
                    advanced: 'Pokročilé',
                    simpleEditor: 'Základní workflow',
                    advancedEditor: 'Pokročilé workflow',
                    new: 'Vytvořit nové',
                    create: 'Vytvořit',
                    noModelTypeMatch: 'Žádné workflow modely k zobrazení.',
                    noValuesYet: 'Žádné stavy workflow ještě nebyly vytvořeny.',
                    winProbability: 'Pravděpodobnost výhry',
                    editName: 'Přejmenovat',
                    editNameTitle: 'Přejmenovat workflow',
                    editNameMessage: 'Upravte název a překlady.',
                    errorDuplicateNameText: 'Název nebo jeden z jeho překladů již existuje v jiném worklow.\n\nDuplicita je například ve sloupci {0}.',
                    deleteWorkflowTitle: 'Smazat workflow',
                    deleteWorkflowMessage: 'Opravdu chcete odstranit vybrané workflow?',
                    enable: 'Povolit',
                    enabled: 'Povolené',
                    disable: 'Zakázat',
                    disabled: 'Zakázané',
                    system: 'Systémové',
                    noSelectedAttribute: 'Žádný',
                    noSelectedAttributeInLeads: 'Žádná',
                    addNewWorkflow: 'Přidat nové workflow',
                    nameAlreadyUsed: 'Toto jméno je již použito.',
                    simpleOrAdvancedPickTitle: 'Jak začít?',
                    simpleOrAdvancedPickSubtitle: 'Chcete vytvořit jednoduchý seznam stavů nebo potřebujete pokročilé workflow s automatickým generováním úkolů, e-mailů nebo kontrolou povinných polí?',
                    advancedEditorTitle: 'Stále na tom pracujeme',
                    advancedEditorSubtitle: 'Pokročilé workflow je stále ve vývoji. Kliknutím na tlačítko můžete workflow upravit ve starém administračním rozhraní.',
                    advancedEditorButton: 'Upravit ve starém administračním rozhraní',
                    duplicateWorkflow: 'Duplikovat workflow',
                    targetWorkflow: 'Zadejte název nového workflow nebo vyberte z existujících',
                    newWorkflowBadge: 'Nové',
                    wfCannotBeTargetOfDuplication: 'Toto workflow nemůže být použito jako cíl duplikace.',
                    wfNameAlreadyExistsInTranslation: 'Tento název již existuje v jednom z překladů workflow {0}. To bude použito jako cíl duplikace.',
                    optionAlreadyUsedInOtherStage: 'Tato možnost je již použita ve stavu {0}.',
                    deleteWfModelDialog: 'Začít znovu',
                    deleteWfModelDialogMessage: 'Chystáte se zahodit všechny změny a začít úplně od začátku. Opravdu chcete pokračovat',
                    stageAttributes: {
                        projectsCompletedState: 'Dokončeno',
                        projectsDeadState: 'Ztracený',
                        bonusesCompletedState: 'Schválena',
                        leadsCompletedState: 'Vyhráno',
                        leadsDeadState: 'Ztraceno',
                        marketingCompletedState: 'Dokončeno',
                        marketingDeadState: 'Ztraceno',
                        tasksCompletedState: 'Dokončeno',
                        tasksDeferredState: 'Odloženo',
                        tasksInProgressState: 'Probíhá',
                        tasksNotStartedState: 'Nezahájeno',
                        tasksWaitOnSomeoneElseState: 'Čeká na někoho dalšího',
                        vacationCompletedState: 'Schválena',
                        workReportApprovedState: 'Schválená'
                    }
                },
                subscriptions: {
                    title: 'Správa předplatného',
                    subtitle: 'Zde můžete nakupovat produkty a služby nebo spravovat již zakoupené předplatné.',
                    switchV2: 'Přepnout starý/nový eshop',
                    autoRenewalOn: 'příští platba',
                    withoutVat: '(bez DPH)',
                    subscribe: 'Přidat do košíku',
                    editSubscription: 'Upravit předplatné',
                    unsubscribe: 'Odhlásit',
                    closeAccount: 'Odhlásit předplatné',
                    closeAccountDialogTitle: 'Opravdu?',
                    closeAccountDialogMessage: 'Opravdu chcete ukončit své předplatné a smazat účet? Po odhlášení budete stále moci používat eWay-CRM až do konce současného předplaceného období. Poté bude Váš účet smazán. Do té doby si můžete kdykoli eWay-CRM opět předplatit v jakékoli edici a nepřijdete o žádná svá data.',
                    continueShopping: 'Pokračovat v nákupu',
                    finishOrder: 'Dokončit objednávku',
                    isAlwaysChargedFullPeriodPriceAlertTitle: 'Upozornění',
                    isAlwaysChargedFullPeriodPriceAlertText: 'Vaše předplatné se automaticky obnoví {0} (za {1} dní). {2} nelze rozpočítávat poměrově, takže Vám naúčtujeme částku za celý {3}. Chcete pokračovat?',
                    newAmount: 'nový počet',
                    youAreInEditMode: 'Máte rozpracovány změny Vašeho předplatného.',
                    discardAll: 'Zrušit vše',
                    per: 'po',
                    yourNewSubscription: 'Vaše nové předplatné',
                    yourCurrentSubscription: 'Vaše současné předplatné',
                    productsAndServices: 'Produkty a služby',
                    companyDetails: 'Údaje o společnosti',
                    companyDetailsOtherOptional: 'Firemní údaje',
                    licenseAgreement: 'Licenční ujednání',
                    payment: 'Platba',
                    finishOrderWitoutPaying: 'Dokončit',
                    finishOrderAndPay: 'Dokončit a zaplatit',
                    emailAddress: 'E-mail',
                    vatNumber: 'DIČ',
                    idNumber: 'IČ',
                    taxNumber: 'DIČ (SK)',
                    vatNumberSk: 'IČ DPH',
                    idNumberSk: 'IČ',
                    taxNumberSk: 'DIČ',
                    businessName: 'Obchodní název',
                    firstName: 'Jméno',
                    lastName: 'Příjmení',
                    phone: 'Telefon',
                    chargeAdditionalServicesAutomatically: 'Automaticky strhávat platby za ostatní služby (technická podpora, školení, zakázkový vývoj)',
                    chargeAdditionalServicesAutomaticallyMore: 'Pokud je volba nezaškrtnutá, bude automaticky strháváno pouze aktivní předplatné (pokud nějaké máte). Za ostatní služby Vám budeme posílat standardní faktury.',
                    addressLine: 'Ulice a č.p.',
                    city: 'Město',
                    state: 'Kraj',
                    postalCode: 'PSČ',
                    country: 'Země',
                    additionalInformation: 'Poznámka',
                    checkingTax: 'Probíhá načítání konfigurace DPH dle Vašich údajů.',
                    oneMoreToDiscount: 'Ještě jeden a záskáte slevu!',
                    currentDbSizeFormat: 'Současná velikost Vaší databáze je {0} GB.',
                    lessThanDbSizeFormat: 'Toto je méně než současná velikost Vaší databáze ({0} GB). Do začátku nového účtovacího období bude potřeba nějaká data vymazat, jinak dojde k zablokování systému.',
                    packageContains: 'Balíček obsahuje',
                    switchCard: 'Přepnout kartu',
                    toBePaidNow: 'Nyní k zaplacení',
                    total: 'Nové předplatné',
                    vat: 'DPH',
                    salesTax: 'Prodejní daň',
                    totalWithVat: 'Nové předplatné s DPH',
                    totalWithSalesTax: 'Nové předplatné s prodejní daní',
                    totalWithVatAndSalesTax: 'Nové předplatné s DPH a prodejní daní',
                    accept: 'Souhlasím',
                    byClickingIAccept: 'Kliknutím na {0} souhlasím se {1}.',
                    order: 'smlouvou',
                    productDescription: 'Popis',
                    quantity: 'Množství',
                    priceUnitPeriod: 'Cena / m.j. / ',
                    totalPricePeriod: 'Cena celkem / ',
                    vatIncludedText: 'Uvedené ceny jsou stanoveny s DPH.',
                    vatExcludedText: 'Uvedené ceny jsou stanoveny bez DPH.',
                    checkoutDismissTitle: 'Ukončit',
                    checkoutDismissMessage: 'Opravdu si přejete opustit průvodce objednávkou?',
                    checkoutSuccessSubtitle: 'Zakoupené produkty jsou ihned k použití. Jak chcete pokračovat?',
                    checkoutSuccessDowngradeSubtitleFormat: 'Vaše licence budou změněny od příštího fakturačního období (od {0}).',
                    redistributeLicenses: 'Přerozdělit licence',
                    addUsersFormat: 'Přidat uživatele ({0})',
                    nothingForNow: 'Nyní ne',
                    checkoutErrorGeneral: 'Velmi se omlouváme, ale při zpracování objednávky došlo k nečekané chybě. Prosím, zkuste objednávku zopakovat později znovu. Můžete také kontaktovat naši technickou podporu.',
                    checkoutErrorWrongData: 'Některá ze zadaných data nesedí. Prosím, zkuste objednávku vytvořit znovu.',
                    checkoutErrorPayment: 'Je nám líto, ale zadaná platební metoda nemůže být použita pro tuto objednávku. Prosím, zkuste objednávku opakovat s jinou platební kartou.',
                    unsubscriptionFailed: 'Velmi se omlouváme, ale při rušení předplatného došlo k nečekané chybě. Prosím, zkuste akci zopakovat později znovu. Můžete také kontaktovat naši technickou podporu.',
                    subscribedAmountTitleFormat: 'Vaše současné předplatné tohoto balíčku čítá: {0}.',
                    licensedAmountTitleFormat: '{0} do {1}.',
                    pastDueTitle: 'Nezaplacené předplatné - chyba platby',
                    pastDueMessage: 'Vaše předplatné je nezaplaceno. To znamená, že pravidelné stržení peněz selhalo. Prosím, zkontrolujte zadanou platební metodu (platební karta/PayPal účet). Platební metodu můžete také změnit za jinou. Dokud nebude zadána funkční platební metoda, není možné dělat žádné změny předplatného.',
                    checkBills: 'Prohlédnout faktury',
                    managePaymentMehtods: 'Spravovat platební metody',
                    loadingPaymentGate: 'Načítání platební brány',
                    initPaymentFailedTitle: 'Platba selhala',
                    initPaymentFailedMessageFormat: 'Vaše předplatné bylo nastaveno, ale úvodní platba nebyla provedena úspěšně. Platební brána vrátila chubu: \'{0}\' Prosím, zkuste vybrat pro předplatné jinou platební metodu.',
                    stripeSubscriptionPaymentMethodRequestedTitle: 'Nezaplacené předplatné - chyba platby',
                    stripeSubscriptionPaymentMethodRequestedMessage: 'Vaše předplatné je po splatnosti, protože jsme nemohli použít vaši výchozí platební metodu. Prosím zadejte novou kartu a klikněte na Zkusit znovu.',
                    subscriptionPaymentIssueGoToAdminCenterTitle: 'Problém s předplatným',
                    subscriptionPaymentIssueGoToAdminCenterMessage: 'Vyskytl se problém s vaším předplatným. Přejděte prosím do Centra pro správu a vyřešte jej tam.',
                    paySubscription: 'Zaplatit předplatné',
                    tryAgain: 'Zkusit znovu',
                    buyNow: 'Koupit',
                    updateSubscription: 'Upravit Předplatné',
                    billedAt: 'platit budete',
                    revert: 'Vrátit změny',
                    applyCustomerDetails: 'Použít',
                    subscriptionSummary: 'Vaše nové předplatné',
                    paymentInfo: 'Platební údaje',
                    chooseTheNumberOf: 'Zvolte si počet',
                    for: 'za',
                    comparePlans: 'Porovnat plány',
                    checkoutResultTitle: 'Dobrá práce',
                    checkoutResultStartBtn: 'Přejít do eWay-CRM',
                    goToAdminCenter: 'Přejít do Centra pro správu',
                    pay: 'Zaplatit',
                    payWith: 'Zaplatit s',
                    payWithAnother: 'Zaplatit jinou kartou',
                    rowTeachingBubbleTitle: 'Nastavte si profesionální e-maily',
                    rowTeachingBubbleText: 'Vyberte si požadovaný plán a pomocí posuvníku nastavte, kolik e-mailů za měsíc chcete odesílat.',
                    commitment: 'Plán',
                    periods: {
                        monthly: {
                            unit: 'měsíc',
                            adverb: 'měsíčně',
                            commitment: 'Měsíční'
                        },
                        yearly: {
                            unit: 'rok',
                            adverb: 'ročně',
                            commitment: 'Roční'
                        },
                        quarterly: {
                            unit: 'čtvrtletí',
                            adverb: 'čtvrtletně',
                            commitment: 'Čtvrtletní'
                        },
                        semiannually: {
                            unit: 'půlrok',
                            adverb: 'půlročně',
                            commitment: 'Půlroční'
                        },
                        lifetime: {
                            unit: 'jednorázově',
                            adverb: 'jednorázově',
                            commitment: 'Jednorázový'
                        },
                        triennially: {
                            unit: 'tři roky',
                            adverb: 'tříletně',
                            commitment: 'Tříletý'
                        }
                    },
                    changePeriodToInvoicingDialog: {
                        title: 'Vyžadována změna platební metody',
                        message: 'Trojleté plány nepodporují automatické platby a musíme vás převést na ruční způsob. Každé tři roky vám pošleme e-mailem fakturu, kterou můžete uhradit platební kartou nebo bankovním převodem. Chcete pokračovat?'
                    },
                    payNowToBeCoveredUntilFormat: 'a mějte vystaráno do {0}'
                },
                purchaseHistory: {
                    title: 'Faktury',
                    invoiceNr: 'Číslo Fa.',
                    date: 'Datum',
                    articles: 'Zboží',
                    quantity: 'Množství',
                    total: 'Celkem',
                    status: 'Stav',
                    invoice: 'Faktura',
                    actions: 'Akce',
                    invoiceOnline: 'Zobrazit fakturu',
                    from: 'od',
                    til: 'do',
                    paid: 'Zaplaceno',
                    notPaid: 'Nezaplaceno',
                    due: 'splatné do',
                    open: 'Otevřít',
                    print: 'Tisk',
                    pastDueTitle: 'Nezaplacené předplatné - chyba platby',
                    pastDueDescription: 'Vaše předplatné je nezaplaceno. To znamená, že pravidelné stržení peněz selhalo. Prosím, zkontrolujte zadanou platební metodu (platební karta/PayPal účet). Platební metodu můžete také změnit za jinou.',
                    pastDueAmount: 'Částka',
                    pastDueDescriptionEn: 'Zdůvodnění (původní znění)',
                    pastDueMore: 'Více',
                    pastDuePayNow: 'Zaplatit teď',
                    pastDueProblem: 'Problém',
                    pastDueRecentPaymentErrors: 'Poslední chyby transakcí',
                    pastDueSuccess: 'Platba byla úspěšně iniciována. Může trvat i několik hodin, než se změny projeví do přehledu předplatného.',
                    pastDueUnsuccess: 'Platba byla neúspěšná. Prosím, zkuste změnit aktivní platební metodu. V případě přetrvávajícíh potíží, kontaktujte svého obchodního zástupce.',
                    payNow: 'Uhradit',
                    payInvoiceNo: 'Uhradit fakturu',
                    enterAnotherCard: 'Zadat jinou platební kartu',
                    pay: 'Uhradit',
                    invoiceSuccessfullyPaidTitle: 'Faktura zaplacena',
                    invoiceSuccessfullyPaidMessage: 'Platba byla úspěšná.',
                    paymentErrorTitle: 'Chyba platby',
                    paymentError: 'Platba se nezdařila.',
                    paymentErrorGateMessage: 'Zpráva z platební brány:',
                    noInvoices: 'Žádné faktury k zobrazení.'
                },
                billingDetails: {
                    title: 'Platební údaje',
                    notAllowedUntilSubscribe: 'Správa platebních metod není dostupná, dokud si nepředplatíte alespoň jeden z balíčků eWay-CRM® Premium. Chcete platit bezstarostně kartou nebo přes PayPal? Pořiďte si předplatné zde v Centru pro správu.',
                    subscribeNow: 'Předplatit teď',
                    companyDetails: 'Fakturační údaje',
                    billingEmails: 'Fakturační e-maily',
                    paymentMethods: 'Platební metody',
                    companyDetailsSubtext: 'Následující údaje budou použity pro fakturaci a oficiální korespondenci.',
                    billingEmailsSubtext: 'Tyto emailové adresy jsou použity pouze pro fakturaci.',
                    active: 'Aktivní',
                    expired: 'Prošlá',
                    setAsActive: 'Použít jako aktivní',
                    delete: 'Vymazat',
                    deletePaymentMethod: 'Vymazat platební metodu',
                    areYouSureToDelete: 'Opravdu chcete vymazat platební metodu {0}?',
                    addNew: 'Přidat novou',
                    newPaymentMethod: 'Nová platební metoda',
                    add: 'Přidat',
                    processingNewMethod: 'Zpracovávání platební metody.',
                    wrongMethod: 'Bohužel tato platební metoda nemůže být použita. Zkuste, prosím, zadat jinou.',
                    type: 'Typ',
                    identifier: 'Identifikátor',
                    expiration: 'Platnost',
                    status: 'Stav',
                    primaryContactEmail: 'Primární kontaktní e-mail',
                    email: 'E-mail',
                    cardNumber: 'Číslo karty',
                    cardExpiration: 'Platnost karty',
                    cardCvc: 'CVC',
                    insecureConnectionMessage: 'Informace o kreditní kartě nelze zadat přes nezabezpečené připojení. Použijte zabezpečené připojení (HTTPS) a zkuste to znovu.'
                },
                updates: {
                    title: 'Aktualizace',
                    noUpdateAvailable: 'Žádná aktualizace není k dispozici.',
                    currentVersionFormat: 'Používáte verzi {0}.',
                    download: 'Stáhnout instalátor pro eWay-CRM Desktop (pouze pro Windows)',
                    updateAvailableFormat: 'eWay-CRM® {0} je zde',
                    newerVersionAvailableFormat: 'Nová verze {0} vydaná {1} je k dispozici. Vaše stávající verze je {2}.',
                    install: 'Nainstalovat',
                    installDisabledMaintenanceWindowTitle: 'Právě probíhá údržba',
                    installDisabledMaintenanceWindowText: 'Instalaci nelze spustit, protože probíhá údržba eWay-CRM.',
                    installDisabledMaintenanceWindowTryAgain: 'Prosím zkuste to znovu po {0}.',
                    installDisabledMaintenanceWindowTryAgainTomorrow: 'Prosím zkuste to zítra po {0}.',
                    installDisabledMaintentaceWindowChangeText: 'V případě potřeby můžete čas údržby upravit {0}.',
                    installDisabledMaintenanceWindowLinkText: 'zde',
                    preCheckFailedTitle: 'Aktualizaci nelze zahájit',
                    preCheckFailedMessageIntro: 'Aktualizace nemůže být spuštěna, protože serverová komponenta eWay-CRM® hlásí tyto problémy:',
                    preCheckFailedMessageOutro: 'Prosím, vyřešte tyto problémy a zkuste aktualizaci znovu. V případě potřeby kontaktujte technickou podporu eWay-CRM®.',
                    preCheckFailedMessageFallback: 'Aktualizace nemůže být spuštěna, protože serverová komponenta eWay-CRM® hlásí technické problémy. Prosím, vyřešte tyto problémy a zkuste aktualizaci znovu. V případě potřeby kontaktujte technickou podporu eWay-CRM.',
                    updating: 'Aktualizace probíhá',
                    showChangelog: 'Zobrazit novinky',
                    versionChanged: 'Aktualizace nemůže být nainstalována, protože její instalační balík není v tuto chvíli již dostupný. Prosím, zkuste aktualizaci opakovat o něco později. Aktualizace bude brzy opět k dispozici.',
                    stageVerifyingUpdate: 'Ověřování aktualizace.',
                    stageDownloadingFiles: 'Stahování souborů aktualizace.',
                    stageInstalling: 'Instalace aktualizace.',
                    takeLong: 'Proces aktualizace serverové komponenty může trvat několik minut, ve výjimečných případech i déle. Celková doba záleží na rychlosti internetového připojení nebo velikosti vaší databáze.',
                    failedBeforeStartTitle: 'Aktualizaci nelze nainstalovat',
                    failedBeforeStartIntro: 'Aktualizace eWay-CRM® se nezdařila kvůli jednomu z následujících:',
                    failedBeforeStartOutro1: 'Prosím, vyřešte tyto problémy a zkuste aktualizaci spustit znovu. V případě potřeby kontaktujte technickou podporu eWay-CRM®.',
                    failedBeforeStartOutro2Format: 'Systém prozatím zůstane v původní verzi {0}, takže můžete pokračovat v běžné práci s eWay-CRM®.',
                    failedBeforeStartReasonBackupPermission: 'Systém nemá oprávnění vytvářet zálohy databáze.',
                    failedBeforeStartReasonBackupSpaceFallback: 'Na disku není dostatek místa pro provedení zálohy databáze.',
                    failedBeforeStartReasonBackupSpaceFormat: 'V \'{0}\' není dostatek místa pro provedení zálohy databáze.',
                    failedBeforeStartReasonFilesPermissionsFallback: 'eWay-CRM® Timer nemá oprávnění měnit soubory webové služby.',
                    failedBeforeStartReasonFilesPermissionsFormat: 'eWay-CRM® Timer nemá oprávnění měnit soubory v \'{0}\'.',
                    failedTitle: 'Aktualizace selhala',
                    failedMessage: 'Proces aktualizace selhal a nebyl schopen obnovit předchozí stav. Serverová komponenta eWay-CRM® (vč. tohoto administračního rozhraní) není v tuto chvíli již v provozu nebo může vykazovat chyby. Databáze je bezpečně a plně zazálohována, nicméně je potřeba serverovou komponentu opravit.',
                    completedTitle: 'Aktualizace je hotova',
                    completedWithWarningsMessage: 'Proces aktualizace nainstaloval novou verzi, nicméně během toho bylo nalezeno několik problémů. Prosím, vyřešte tyto problémy neprodleně, abyste předešli případným budoucím chybám. V případě potřeby můžete kontaktovat technickou podporu eWay-CRM®.',
                    completedMessage: 'Aktualizace serverové komponenty byla úspěšně dokončena. Prosím zaktualizujte si nyní eWay-CRM Desktop.',
                    showTechInfo: 'Ukázat technický report',
                    hideTechInfo: 'Skrýt technický report'
                }
            },
            shared: {
                foldersMenu: {
                    noFolders: 'Žádné moduly k zobrazení.',
                    noFoldersMatch: 'Nic neodpovídá hledání.'
                }
            },
            connection: {
                error: "Bohužel nastala neočekávatelná chyba při komunikaci se serverem. Prosím, zkuste obnovit stránku.",
                settings: 'Nastavení',
                language: 'Jazyk',
                english: 'Anglicky',
                czech: 'Česky',
                externalLoginMessage: 'Vaše eWay-CRM používá ověřování pomocí Microsoft Azure. Pro přihlížení pomocí účtu Microsoft klikněte na tlačítko níže.',
                externalLoginButton: 'Přihlásit se účtem Microsoft'
            },
            shoppingConnection: {
                unavailableTitle: 'E-shop není k dispozici',
                unavailableText: 'Momentálně nemáte přístup do e-shopu eWay-CRM.'
            },
            mainComponent: {
                title: 'Centrum pro správu',
                oldAdmin: 'Staré administrační rozhraní'
            },
            sideMenu: {
                home: "Domů",
                users: "Uživatelé",
                groups: "Skupiny",
                usersAndPermissions: 'Uživatelé a oprávnění',
                modulePermissions: 'Oprávnění na moduly',
                customizations: 'Volby',
                features: 'Funkce',
                fields: 'Pole',
                workflow: 'Workflow',
                billing: "Nákupy",
                manageSubscriptions: "Správa předplatného",
                bills: "Faktury",
                billingDetails: "Platební údaje",
                billingNotifications: "Fakturační notifikace",
                updates: "Aktualizace",
                notifications: {
                    unpaidOrdersTooltip: 'Nezaplacené položky',
                    versionAvailableTooltip: 'Je k dispozici nová verze',
                    fields: 'Zaznamenali jsme několik problémů v definici polí. Více informací naleznete uvnitř sekce u jednotlivých modulů.',
                    workflow: 'Zaznamenali jsme několik problémů s workflow. Více informací naleznete uvnitř sekce u jednotlivých modulů.'
                }
            },
            search: {
                searchPlaceholder: 'Jak Vám můžeme pomoci?',
                title: 'Výsledky hledání',
                loading: 'Načítání',
                noResultsFound: 'Nenašli jste, co jste hledali? Zkuste naše',
                noResultsFoundHowTo: 'how-to články',
                noResultsFoundHowToLink: 'https://www.eway-crm.com/cs/podpora/jak-pouzivat-eway-crm/',
                noResultsFoundOr: 'nebo se zeptejte naší',
                noResultsFoundCommunity: 'komunity',
                noResultsFoundCommunityLink: 'https://www.reddit.com/r/ewaycrm/',
                allGroupsDefaultPrefix: 'Spravovat',
                users: 'Uživatele',
                editUser: '',
                effectivePermissions: 'Aktuální práva uživatele',
                editModulePermissions: 'Upravit oprávnění na moduly skupiny',
                reassignLicenses: 'Přiřadit licence',
                usersGroupAddNew: 'Vytvořit nového uživatele',
                groupsGroupAddNew: 'Vytvořit novou skupinu',
                editGroup: '',
                fieldsNamePrefix: 'Přizpůsobit pole v modulu',
                fieldsNameSuffix: '',
                newWorkflowPrefix: 'Vytvořit nové workflow v modulu',
                newAdditionalFieldNamePrefix: 'Vytvořit nové pole v modulu',
                fieldPermissionsPrefix: 'Oprávnění na pole skupiny',
                dismiss: 'Zrušit',
                folderNameSynonyms: {
                    AdditionalFields: 'AF Vlastní Vlastního Vlastním Vlastnímu Uživatelskému Poli Položka Položky Položce Seznam Seznamu Seznamy',
                    Bonuses: 'Bonusech Bonusům Bonusu Prémiím Prémiích Prémiemi Prémií',
                    Carts: 'Dokladům Dokladech Dokladu',
                    Calendar: 'Kalendářům Kalendářích Kalendáři',
                    Groups: 'Kategoriím Kategoriích Kategorii',
                    Companies: 'Společnostem Společnostech Společnosti',
                    Contacts: 'Kontaktům Kontaktech Kontaktu',
                    Leads: 'Příležitostem Příležitostech Příležitosti',
                    IndividualDiscounts: 'Individuálním Individuálních Slevách Slevám Slevě Ceníky Cenících Ceníkách Ceníkům',
                    Documents: 'Dokumentům Dokumentech Dokumentu',
                    Emails: 'Email Emaily Emailu Emailům Emailech E-mailu E-mailům E-mailech',
                    CurrencyExchangeRates: 'Směnným Směna Kurzům Kurzech Kurzu',
                    Goals: 'Cílem Cíli Cílech Cílům',
                    Journal: 'Deníkům Denících Deníku',
                    Vacation: 'Absencím Absencích',
                    Marketing: 'Marketingových Maketingovým Marketingu',
                    MarketingList: 'Marketingových Marketingovým Marketingu Marketing Seznamu Seznamech Seznamy',
                    MarketingListSources: 'Zdrojích Zdrojům Zdroji Marketingových Marketingovým Marketingu Seznam Seznamu Seznamech Seznamy',
                    Payments: 'Placení Úhrada Úhradám Úhradách',
                    PriceListGroups: 'Cenících Ceníkách Ceníkům',
                    Prices: 'Cenám Cenách',
                    Goods: '',
                    GoodsInCart: 'Zboží Dokladech Doklad Doklady Dokladem',
                    GoodsInSet: 'Svázaných Svázáno Sadách Sadám Sady',
                    Projects: 'Projektech Projektům Projektem',
                    ProjectAssignments: 'Plány Plánování Plánováno Naplánování Naplánováno Zdroje Zdrojům Zdrojích Zdrojem',
                    Reports: 'Reportech Reportům Reportem',
                    ProjectUsersInCaPlan: 'Plánovat Plán Plánuj Plánů Kapacita Kapacity Kapacitám Kapacitách',
                    Salaries: 'Mzdám Mzdách',
                    SalePrices: 'Prodejních Prodejním Prodej Cenám Cenách',
                    Knowledge: 'Znalostech Znalostem',
                    Tasks: 'Úkolech Úkolem',
                    WorkReports: 'Docházkách Docházkám Docházkami',
                    Training: 'Školeních Školením',
                    Users: 'Účet Účtu Účtě Účtem Účty Uživatele Uživateli Uživatelovi Uživatelem',
                    WorkCommitments: ''
                },
                synonyms: {
                    create: 'Nový Nového Nových Novou Nové Vytvořit Vytvoření Vytvoř Přidat Přidání Přidej Vyrobit Vyrob Vyrobení Udělat Udělej Udělání Založit Založ Založení Zakládat',
                    edit: 'Upravit Upravení Uprav Úprava Modifikovat Modifikace Modifikuj Modifikování Přizpůsobit Přizpůsobení Přizpůsob Změnit Změň Změna Správa Spravovat Spravuj Spravování Přidat Přidání Sebrat Sebrání Seber Odebrat Odebrání Odeber',
                    assign: 'Přiřadit Přiřazení Nastavit Nastavení',
                    user: 'Účet Účtu Účtě Účtem Uživatelské Jméno Uživatel Uživatele Uživateli Uživatelovi Uživatelem',
                    group: 'Skupina Skupiny Skupinu Groupa Groupy Groupu',
                    module: 'Moduly Modulů Modul',
                    permissions: 'Oprávnění Oprávnit Právo Práva',
                    features: 'Globální Nastavení Nastavit GS Funkce',
                    toggle: 'Vypnutí Vypnout Zapnutí Zapnout Začít Začátek Zahájení Zahájit Zrušit Zrušení Zastavit Zastavení Přerušit Přerušení Přepnutí Přepnout',
                    fields: 'Oprávnění Oprávnit Pole Polí Sloupec Sloupce Sloupců Kombobox List Listu Listy Výčet Výčtu Výčty Seznam Seznamu Seznamy Enum Enumu Enumy',
                    reassignLicenses: 'Přiřadit Přiřaď Přiřazení Přidělit Přiděl Přidělení Přeřaď Přeřazení Přeřadit Uživatel Uživatele Uživatelům',
                    additionalField: 'AF Vlastní Vlastního Vlastním Uživatelská Uživatelské Pole Poli Položka Položky Položce Seznam Seznamu Seznamy',
                    systemField: 'Systém Systémové Systémová Pole Poli Položka Položky Položce Seznam Seznamu Seznamy',
                    workflow: 'Workflow Pracovní Postup Postupy',
                    subscriptions: 'Licence Licencím Licenci Licencemi Licencích Účet Účtu Účtě Účtem Platit Placení Plať Zaplatit Zaplacení Zaplať Koupit Koupení Kup Kupuj Nákup Nakoupit Nakup Nakupuj Nakoupení Odhlásit Odhlášení Odhlas Odhlašuj Zrušit Zruš Zrušení Odběr Odebírání Odebírej Deaktivovat Deaktivace Deaktivuj Storno Stornovat Stornuj Nabídka Nabídnout Nabízení Registrace Registrovat Registruj Registrování Cena',
                    billingHistory: 'Faktura Faktury Faktur Fakturace Fakturování Historie Plateb Platby Koupit Koupení Kup Kupuj Nákup Nakoupit Nakup Nakupuj Nakoupení Platba Platby Platit Zaplatit Zaplacené Zaplaceno Nezaplaceno Nezaplacené Zpožděné Zpožděno Opožděné Opožděno',
                    paymentMethods: 'Kreditní Debetní Karta Karty Kreditka Kreditky Platit Placení Plať Zaplatit Zaplacení Zaplať Způsob Způsoby Platba Platby VISA Master Mastercard Amex',
                    updates: 'Aktualizace Aktualizovat Aktualizuj Updatovat Updatuj'
                },
                apps: {
                    name: 'Aplikace',
                    oldAdminSettings: {
                        name: 'Staré administrační rozhraní',
                        synonyms: 'Starý Minulý Minulé Dřívější Původní Admin Administrace App Appka Nástroj'
                    },
                    powerBI: {
                        name: 'Získat eWay-CRM Sales Executive Dashboard',
                        synonyms: 'Data Vizualizace Vizualizuj Vizualizovat MS Microsoft PowerBI Reporting Report Reportování Business Přehled Dashboard Integrace'
                    },
                    zapier: {
                        name: 'Zapier',
                        synonyms: 'Připojit Propojit Napojit Automatizace Automatizuj Automatizovat Workflow App Appky Zap'
                    }
                },
            },
            licenseRestrictions: {
                unlockThisModule: 'Odemkněte tento modul',
                unlockThisField: 'Odemkněte toto pole',
                withPlan: 's plánem {0}.',
                itemsAvailable: 'Položky k dispozici',
                needMore: 'Potřebujete více?',
                upgradeYourPlan: 'Upgradujte svůj plán.',
                upgradePlan: 'Upgradovat plán',
                activeWfSingular: 'aktivní',
                activeWfPluralTwoToFour: 'aktivní',
                activeWfPluralFiveAndMore: 'aktivních',
                advancedWfSingular: 'pokročilé',
                advancedWfPluralTwoToFour: 'pokročilá',
                advancedWfPluralFiveAndMore: 'pokročilých',
                basicWfPluralSingular: 'základní',
                basicWfPluralTwoToFour: 'základní',
                basicWfPluralFiveAndMore: 'základních',
                mandatoryFieldsCustomTitle: 'Upgradujte svůj plán a odemkněte si více povinných polí.',
                importantFieldsCustomTitle: 'Upgradujte svůj plán a odemkněte si více důležitých polí.',
                workflowCustomTitle: 'Upgradujte svůj plán a odemkněte si více typů workflow.',
                excelExportModuleLocked: 'Upgradujte svůj plán a odemkněte si tento modul',
                learnMore: 'Zjistit více',
                workflowCountExceededTitle: 'Příliš mnoho {0} workflow',
                workflowCountExceededAlert: 'Příliš mnoho {0} workflow. Deaktivujte prosím {1} nebo',
                workflowAlertUpgradeYourPlan: 'přejděte na vyšší plán',
                workflowAdvancedNotAvailable: 'Pokročilá workflow nejsou k dispozici',
                workflowCountExceeded: 'Váš plán podporuje pouze {0} {1} workflow, ale aktuálně máte {2}. Deaktivujte prosím ta nepotřebná – stačí na ně kliknout pravým tlačítkem a vybrat Zakázat.',
                workflowCountNotAvailable: 'Váš plán nepodporuje žádná {0} workflow, ale aktuálně máte {1}. Deaktivujte prosím ta nepotřebná – stačí na ně kliknout pravým tlačítkem a vybrat Zakázat.',
                workflowUpgradePlanToExtendLimit: 'Můžete také {0}, kde můžete workflow definovat mnohem víc.',
                upgradeYourPlanBtn: 'přejít na vyšší plán',
                userNotAuthorizedToUseModule: '{0} není oprávněn využívat tento modul. Je třeba {1} nebo ji {2}.',
                user: 'Uživatel',
                assignLicense: 'přiřadit licenci',
                buyNewOne: 'zakoupit',
                fields: {
                    fieldsCountExceeded: 'Váš plán podporuje pouze {0} {1}, ale aktuálně máte {2}.',
                    fieldsCountNotAvailable: 'Váš plán nepodporuje žádná {0}, ale aktuálně máte {1}.',
                    customFieldsCountExceededTitle: 'Přesažen počet uživatelských polí',
                    customFieldsCountExceededMessage: 'Smažte prosím ta nepotřebná - stačí je označit a kliknout na tlačítko Smazat.',
                    itemTypesCountExceededTitle: 'Přesažen počet typů položek',
                    typesCountExceededMessage: 'Deaktivujte prosím ta nepotřebná - stačí editovat pole {0} a zde je vypnout.',
                    itemTypeFieldName: 'typu položky',
                    permissionsCountExceededMessage: 'Deaktivujte prosím ta nepotřebná - stačí jít do editace pole a zde je vypnout.',
                    mandatoryFieldsCountExceededTitle: 'Přesažen počet povinných polí',
                    importantFieldsCountExceededTitle: 'Přesažen počet důležitých polí',
                    uniqueFieldsCountExceededTitle: 'Unikátní pole nejsou k dispozici',
                    readonlyFieldsCountExceededTitle: 'Pole jen pro čtení nejsou k dispozici',
                    currecyFieldsCountExceededTitle: 'Přesažen počet dostupných měn',
                    fieldsUpgradePlanToExtendLimit: 'Můžete také {0}, kde můžete uživatelských polí definovat mnohem víc.',
                    customFields: 'uživatelských polí',
                    itemTypesSingular: 'typ položek',
                    itemTypesPluralTwoToFour: 'typy položek',
                    itemTypesFiveAndMore: 'typů položek',
                    mandatoryFieldsSingular: 'povinné pole',
                    mandatoryFieldsPluralTwoToFour: 'povinná pole',
                    mandatoryFieldsPluralFiveAndMore: 'povinných polí',
                    importantFieldsSingular: 'důležité pole',
                    importantFieldsPluralTwoToFour: 'důležitá pole',
                    importantFieldsPluralFiveAndMore: 'důležitých polí',
                    uniqueFields: 'unikátní pole',
                    readonlyFields: 'pole pro čtení',
                    currencyFieldsSingular: 'měnu',
                    currencyFieldsPluralTwoToFour: 'měny',
                    currencyFieldsPluralFiveAndMore: 'měn',
                    currencyFieldName: 's měnou',
                }
            }
        },
        features: {
            'General': { name: 'Obecné', customTitle: 'Obecné funkce', description: 'Upravte místní nastavení, aplikujte firemní politiky nebo si nakonfigurujte spojení s telefonní ústřednou.' },
            'Security': { name: 'Bezpečnost', customTitle: 'Bezpečnostní funkce', description: 'Nastavte eWay-CRM tak, aby byla vaše data opravdu v bezpečí.' },
            'MarketingCampaigns': { name: 'Marketingové kampaně', description: DEFAULT_FEATURES_DESCRIPTION.cs },
            'ContactsAndCompanies': { name: 'Společnosti a kontakty', description: DEFAULT_FEATURES_DESCRIPTION.cs },
            'Projects': { name: 'Projekty', description: DEFAULT_FEATURES_DESCRIPTION.cs },
            'QuotesAndBilling': { name: 'Nabídky a faktury', description: DEFAULT_FEATURES_DESCRIPTION.cs },
            'Sales': { name: 'Obchod', description: DEFAULT_FEATURES_DESCRIPTION.cs },
            'TimeSheetsAndPlanning': { name: 'Docházka a plánování', description: DEFAULT_FEATURES_DESCRIPTION.cs }
        },
        fieldTypes: {
            textBox: 'Jednořádkový text',
            memoBox: 'Víceřádkový text',
            numericBox: 'Číselné pole',
            dateEdit: 'Datumové pole',
            checkBox: 'Zaškrtávací pole',
            comboBox: 'Seznam',
            multiSelectComboBox: 'Zaškrtávací seznam',
            relation: 'Relace',
            linkTextBox: 'Odkaz',
            image: 'Obrázek',
            workflowState: 'Stav workflow',
            multiSelectRelation: 'Zaškrtávací relace'
        },
        summaryTypes: {
            min: 'Minimum',
            max: 'Maximum',
            average: 'Průměr',
            sum: 'Součet',
            count: 'Počet'
        },
        validatorTypes: {
            currency: 'Měna',
            double: 'Desetinné číslo',
            int: 'Celé číslo',
            fileSize: 'Velikost souboru',
            percents: 'Procenta'
        },
        linkTypes: {
            none: 'Obecný',
            http: 'URL',
            email: 'E-mail',
            file: 'Soubor'
        },
        lockedContent: {
            title: 'Funkce uzamčena',
            text: 'Tato funkce je k dispozici pouze pro uživatele s licencí Premium.',
            buttonText: 'Zakoupit'
        }
    }
});

const cookiesLang = Cookies.getCookie<{ lang: string }>(Cookies.names.language);
const urlParams = new URLSearchParams(window.location.hash);
const getLang = urlParams.get('lang');
if (getLang) {
    MetaStrings.setLanguage(getLang);
} else if (cookiesLang.lang) {
    MetaStrings.setLanguage(cookiesLang.lang);
}

export function handleLangChange(value: string) {
    MetaStrings.setLanguage(value);
    Cookies.setCookie(Cookies.names.language, { lang: value }, 365 * 24);
    console.log('Lang changed, reloading page...');
    window.location.reload();
}

export default Strings;