import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { IApiGroup } from '@eway-crm/connector';
import React from 'react';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import OptionalComponentWrapper from '../../../helpers/components/OptionalComponentWrapper';
import ObjectHelper from '../../../helpers/ObjectHelper';
import Strings from '../../../strings';
import OrbDropdownEditCell from '../../shared/permissions/OrbDropdownEditCell';
import Toggle from '../../shared/Toggle';
import type { TFieldPermissionsCellWrapper, TFieldPermissionWithCol } from './FieldPermissionsGrid';
import { fieldPermissionsOptionsSettings } from './FieldPermissionsGrid';

const myStrings = Strings.components.routes.fieldPermissions;
const currentLanguage = Strings.getLanguage();

const fieldPermissionsDropdownColumnSettings = {
    PermissionRule: {
        action: '',
        width: currentLanguage === 'en' ? 360 : 460,
    },
    MandatoryRule: {
        action: '',
        width: currentLanguage === 'en' ? 110 : 150,
    },
};

type TFieldPermissionGridCell = Table.DataCellProps & {
    isShowingAdditionalField?: boolean;
    allGroups?: IApiGroup[];
    fieldPermissions: TFieldPermissionWithCol[];
    systemGroupGuid?: string;
    disableEditing?: boolean;
    onFieldPermissionChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    onMandatoryPermissionChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    cellWrapper?: TFieldPermissionsCellWrapper;
};

const FieldPermissionsGridCell: React.FC<TFieldPermissionGridCell> = ({
    isShowingAdditionalField,
    allGroups,
    fieldPermissions,
    systemGroupGuid,
    disableEditing,
    onFieldPermissionChange,
    onMandatoryPermissionChange,
    cellWrapper,
    ...props
}) => {
    const row = props.row as TFieldPermissionWithCol;

    let isEditable = true;
    if (isShowingAdditionalField && allGroups) {
        isEditable = isEditable && !allGroups.find((ag) => ag.ItemGUID === props.tableRow.rowId)!.DisallowControlColumnPermissions;
    }
    if (props.column.name === FieldPermissionNames.PermissionRule) {
        let availableKeys = Object.keys(fieldPermissionsOptionsSettings);
        // If keys are narrowed down
        if (row.IsSetToUnique || (isShowingAdditionalField && fieldPermissions.find((fp) => fp.GroupGuid === systemGroupGuid)?.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique)) {
            availableKeys = [FieldPermissionNames.PermissionRuleKeys.All];
        } else if (row.col && row.col.AllowedColumnPermissionTypes && row.col.AllowedColumnPermissionTypes.length > 0) {
            availableKeys = row.col.AllowedColumnPermissionTypes;
        }

        isEditable = isEditable && !disableEditing && row.IsPermissionRuleEditable;
        const optionSettings = ObjectHelper.pickObjectProperties(fieldPermissionsOptionsSettings, availableKeys as (keyof typeof fieldPermissionsOptionsSettings)[]);
        return (
            <OrbDropdownEditCell
                {...props}
                isEditable={isEditable}
                onValueChange={onFieldPermissionChange}
                columnSettings={fieldPermissionsDropdownColumnSettings[props.column.name as keyof typeof fieldPermissionsDropdownColumnSettings]}
                optionsSettings={optionSettings}
                cellWrapper={cellWrapper}
            />
        );
    } else if ([FieldPermissionNames.IsImportant, FieldPermissionNames.IsMandatory, FieldPermissionNames.IsUnique].includes(props.column.name)) {
        let tooltip = props.value ? Strings.yes : Strings.no;
        if (row.col && row.col.AllowedColumnMandatoryTypes && row.col.AllowedColumnMandatoryTypes.length > 0) {
            if (
                (props.column.name === FieldPermissionNames.IsImportant && !row.col.AllowedColumnMandatoryTypes.includes(FieldPermissionNames.MandatoryRuleKeys.Optional)) ||
                (props.column.name === FieldPermissionNames.IsMandatory && !row.col.AllowedColumnMandatoryTypes.includes(FieldPermissionNames.MandatoryRuleKeys.Mandatory)) ||
                (props.column.name === FieldPermissionNames.IsUnique && !row.col.AllowedColumnMandatoryTypes.includes(FieldPermissionNames.MandatoryRuleKeys.Unique))
            ) {
                isEditable = false;
            }
        }

        isEditable = isEditable && !disableEditing && row.IsMandatoryRuleEditable && (props.column.name !== FieldPermissionNames.IsUnique || row.col.IsUniqueConstraintSupported);

        if (!row.IsMandatoryRuleEditable) {
            tooltip = myStrings.fieldCannotBeEdited;
        } else if (props.column.name === FieldPermissionNames.IsUnique && !row.col.IsUniqueConstraintSupported) {
            tooltip = myStrings.fieldCannotBeSetUnique;
        }
        let isChecked = props.value as boolean;
        if (props.column.name === FieldPermissionNames.IsMandatory && row.IsUnique) {
            isChecked = true;
        }
        return (
            <VirtualTable.Cell className={'align-middle p-0'} {...props} title={tooltip}>
                <OptionalComponentWrapper wrapperComponent={cellWrapper} data={{ row: row, column: props.column }}>
                    <Toggle
                        disabled={!isEditable}
                        checked={isChecked}
                        onChange={() => {
                            onMandatoryPermissionChange && onMandatoryPermissionChange(props.tableRow.rowId as string, props.column.name, !isChecked);
                        }}
                    />
                </OptionalComponentWrapper>
            </VirtualTable.Cell>
        );
    } else {
        console.error('Unknown Field Permission');
        return <>{props.value}</>;
    }
};

export default FieldPermissionsGridCell;
