import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { TNumberFormatterMode} from '../NumberFormatter';
import { NumberFormatter } from '../NumberFormatter';

type NumericSpinnerProps = {
    value: number;
    min?: number;
    max?: number;
    steps?: number[];
    className?: string;
    style?: React.CSSProperties;
    inputClassName?: string;
    inputStyle?: React.CSSProperties;
    numberFormatterMode?: TNumberFormatterMode;
    onChange: (newVal: number, callback?: () => void) => void;
    onBlurOrStep?: () => void;
};

class NumericSpinner extends React.Component<NumericSpinnerProps> {

    private sortedSteps: number[] | null;

    constructor(props: NumericSpinnerProps) {
        super(props);

        if (!props.steps || props.steps.length === 0) {
            this.sortedSteps = null;
        } else {
            this.sortedSteps = props.steps.sort((a: number, b: number) => a > b ? 1 : (a < b ? -1 : 0));
        }
    }

    private readonly handleOnUp = () => {
        if (this.sortedSteps) {
            for (let i = (this.sortedSteps.length - 1); i >= 0; i--) {
                if (this.sortedSteps[i] <= this.props.value && i < (this.sortedSteps.length - 1)) {
                    this.handleOnChange(this.sortedSteps[i + 1], false, this.props.onBlurOrStep);
                    break;
                }
            }
        }
        else {
            this.handleOnChange(this.props.value + 1, true, this.props.onBlurOrStep);
        }
    };

    private readonly handleOnDown = () => {
        if (this.sortedSteps) {
            for (let i = 0; i < this.sortedSteps.length; i++) {
                if (this.sortedSteps[i] >= this.props.value && i > 0) {
                    this.handleOnChange(this.sortedSteps[i - 1], false, this.props.onBlurOrStep);
                    break;
                }
            }
        } else {
            this.handleOnChange(this.props.value - 1, true, this.props.onBlurOrStep);
        }
    };

    private readonly handleOnChange = (newValueStr: number | string, checkRange: boolean, callback?: () => void) => {
        const formatterMode: TNumberFormatterMode = this.props.numberFormatterMode || 'plain';
        let value: number;
        switch (formatterMode) {
            case 'metric':
                if (typeof newValueStr === 'number') {
                    value = newValueStr ;
                } else {
                    const str = (newValueStr ).replace(/\s/g, '');
                    if (str.match(/^(\+|-)?\d+k$/g)) {
                        value = Number(str.substr(0, str.length - 1)) * 1000;
                    } else if (str.match(/^(\+|-)?\d+M$/g)) {
                        value = Number(str.substr(0, str.length - 1)) * 1000000;
                    } else {
                        value = Number(str);
                    }
                }
                break;
            default:
                value = newValueStr as number;
                break;
        }

        if (checkRange) {
            if (!this.sortedSteps) {
                if (value || value === 0) {
                    if (this.props.min || this.props.min === 0) {
                        value = Math.max(this.props.min, value);
                    }
                    if (this.props.max || this.props.max === 0) {
                        value = Math.min(this.props.max, value);
                    }
                }
            } else {
                let isSufficient = false;
                for (let i = 0; i < this.sortedSteps.length; i++) {
                    if (this.sortedSteps[i] >= value) {
                        value = this.sortedSteps[i];
                        isSufficient = true;
                        break;
                    }
                }
                if (!isSufficient) {
                    value = this.sortedSteps[this.sortedSteps.length - 1];
                }
            }
        }
        if (this.props.value !== value) {
            this.props.onChange(value, callback);
        } else {
            callback && callback();
        }
    };

    render() {
        const formatterMode: TNumberFormatterMode = this.props.numberFormatterMode || 'plain';
        let inputType: string;
        switch (formatterMode) {
            case 'metric':
                inputType = 'text';
                break;
            default:
                inputType = 'number';
                break;
        }

        return (
            <div className={this.props.className} style={this.props.style}>
                <div className="container">
                    <div className="row flex-nowrap align-items-baseline">
                        <div className="col-auto pr-1 pl-0">
                            <Button variant="outline-primary" size="sm" className="text-monospace" onClick={this.handleOnDown}>-</Button>
                        </div>
                        <div className="col-auto px-1">
                            <input
                                type={inputType}
                                min={this.props.min}
                                max={this.props.max}
                                onChange={(e) => this.handleOnChange(e.target.value, true)}
                                value={NumberFormatter.format(this.props.value, formatterMode) ?? undefined}
                                className={'form-control' + (this.props.inputClassName ? ' ' + this.props.inputClassName : '')}
                                style={this.props.inputStyle}
                                onBlur={this.props.onBlurOrStep}
                            />
                        </div>
                        <div className="col-auto pl-1 pr-0">
                            <Button variant="outline-primary" size="sm" className="text-monospace" onClick={this.handleOnUp}>+</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default NumericSpinner;