import * as React from 'react';
import type H from 'history';
import Strings from '../../../strings';
import Cookies from '../../../Cookies';
import type { ISessionHandler, IApiResult, TInputData, IApiDatumResponse, IApiLoginResponse } from '@eway-crm/connector';
import ApiConnection, { ReturnCodes, CommonDataConnection, ApiMethods, HttpMethod, OAuthHelper } from '@eway-crm/connector';
import * as eway from '../../../ewayapi';
import type { UpdateStatusMessageType } from '../../../data/constants/UpdateStatusMessageType';
import type { History } from 'history';
import type { IServerUpdaterAdditionalData } from '../../../data/wcf/IServerUpdaterAdditionalData';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiDataResponse, IApiServiceAuthSettingsResponse } from '@eway-crm/connector';
import { v1 as uuidv1 } from 'uuid';
import { GlobalSettingsNames } from '@eway-crm/connector';
import { RemoteItemStore } from '../../../RemoteItemStore';
import { OAuthFrame } from './OAuthFrame';
import { mergeStyleSets } from '@fluentui/react';
import { ActivityWatcher } from './ActivityWatcher';
import { ShoppingConnection } from '../../ShoppingConnection';
import ReactHelper from '../../../helpers/ReactHelper';
import { AppBuildModeHelper, Spinner, SpinnerVariant } from '@eway-crm/gui';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import type { TAuthSettingsObject, TVersionsObject } from '../../../providers/ConnectionProvider';
import APP_BUILD_MODE from '../../../AppBuildMode';

interface IConnectionContinueObject {
    callNow(connection: Connection): void;
}

class ConnectionContinueObject<TResult extends IApiResult> implements IConnectionContinueObject {

    readonly methodName: string;
    private readonly data: TInputData;
    private readonly successCallback: (result: TResult) => void;
    private readonly unsuccessCallback: ((result: TResult) => void) | undefined;
    private readonly httpMethod: HttpMethod;

    constructor(methodName: string, data: TInputData, successCallback: (result: TResult) => void, unsuccessCallback: ((result: TResult) => void) | undefined, httpMethod: HttpMethod) {
        this.methodName = methodName;
        this.data = data;
        this.successCallback = successCallback;
        this.unsuccessCallback = unsuccessCallback;
        this.httpMethod = httpMethod;
    }

    readonly callNow = (connection: Connection) => {
        connection.callApi(this.methodName, this.data, this.successCallback, this.unsuccessCallback, this.httpMethod);
    };
}

class InvalidateSessionContinueObject implements IConnectionContinueObject {
    private readonly callback: () => void;

    constructor(callback: () => void) {
        this.callback = callback;
    }

    readonly callNow = (_: Connection) => {
        this.callback();
    };
}

type TConnectionProps = {
    location: H.Location;
    history: History;
    onVersionLoaded: (vesion: TVersionsObject) => void;
    onAuthSettingsLoaded: (vesion: TAuthSettingsObject) => void;
    authSettings: TAuthSettingsObject | null;
    lastLoadedVersion: TVersionsObject | null;
    onLogin?: (hasUserChanged: boolean) => Promise<void>;
};

type TSessionObject = {
    sessionId: string;
    userGuid: string;
};

type TConnectionState = {
    session: TSessionObject | null;
    userItem: IApiUser | null;
    lastLoggedInUserGuid: string | null;
    nextTimePromptLogin: boolean;
    continueAfterRelogin: IConnectionContinueObject[];
    isLoggingIn: boolean;
    inactiveMinutesToLogout: number;
};

type TBrowserCookie = {
    browserGuid: string;
};

const css = mergeStyleSets({
    authOverall: {
        position: 'absolute',
        top: '0',
        left: '0',
        right: '0',
        bottom: '0',
        height: '100vh',
        width: '100vw',
        zIndex: 9999999,
        backgroundColor: 'white'
    }
});

const isPremise = AppBuildModeHelper.isPremise(APP_BUILD_MODE);

const handleConnectionError = (error: Error) => {
    console.error(error);
    alert(Strings.components.connection.error);
};

const readSessionFromLocalStorage = () => {
    const session = LocalStorageHelper.getSession();
    if (session && AppBuildModeHelper.isPremise(APP_BUILD_MODE) && session.wsUrl !== eway.getPremiseWsUrl()) {
        console.error(`The session object stored in local storage '${session.wsUrl}' does not match the premise ws url '${eway.getPremiseWsUrl()}'. The stored info won't be used.`);
        return null;
    }

    return session;
};


export class Connection extends React.Component<TConnectionProps, TConnectionState> implements ISessionHandler {

    private static readonly browserId: string = Connection.getBrowserId();

    // Api connection is assigned once we get the ws url. Then it is never reassigned
    private apiConnection: ApiConnection | null = null;
    private lastWsUrl: string | null = null;
    private shoppingConnection: ShoppingConnection | null = null;
    private commonDataConnection: CommonDataConnection | null = null;

    private readonly activityWatcher: ActivityWatcher = new ActivityWatcher();

    private wsUrlFromGetParams: string | null = null;

    constructor(props: TConnectionProps) {
        super(props);

        let initialSessionObject: TSessionObject | null = null;
        let initialIsLoggingIn = false;

        // Handle access token sent via url params.
        if (props.location.search) {
            const urlParams = new URLSearchParams(props.location.search);
            if (urlParams.has('accessToken')) {
                console.warn('Access token came in get param. Will use that.');
                initialIsLoggingIn = true;
                const accessToken = urlParams.get('accessToken')!;
                const username = OAuthHelper.decodeAccessToken(accessToken).username!;
                this.callApiLogin(accessToken, username);
            } else if (urlParams.has('sessionId') && urlParams.has('userGuid') && (isPremise || urlParams.has('wsUrl'))) {
                let wsUrl: string;
                if (isPremise) {
                    wsUrl = eway.getPremiseWsUrl();
                } else {
                    wsUrl = ApiConnection.normalizeWsUrl(urlParams.get('wsUrl'))!;
                }
                console.warn('SessionId came in get param. Will use that.');
                this.initializeApiConnection(wsUrl);
                const session = {
                    sessionId: urlParams.get('sessionId')!,
                    userGuid: urlParams.get('userGuid')!
                };
                LocalStorageHelper.saveSession({
                    ...session,
                    wsUrl
                });

                initialSessionObject = session;
            } else if (!isPremise && urlParams.has('wsUrl')) {
                this.wsUrlFromGetParams = urlParams.get('wsUrl')!;
            }

            let newSearchParams = '';
            urlParams.forEach((value, key) => {
                // Removing username for legacy reasons.
                if (key !== 'accessToken' && key !== 'sessionId' && key !== 'userGuid' && key !== 'username' && key !== 'wsUrl') {
                    newSearchParams += '&' + key + '=' + encodeURIComponent(value);
                }
            });
            if (newSearchParams) {
                newSearchParams = '?' + newSearchParams.substr(1);
            }
            props.history.push(props.location.pathname + newSearchParams + props.location.hash);
        }

        // If the session was not gotten from url params, try to load it from local storage
        if (!initialSessionObject) {
            const storedSession = readSessionFromLocalStorage();
            if (storedSession && (!this.wsUrlFromGetParams || this.wsUrlFromGetParams === storedSession.wsUrl)) {
                this.initializeApiConnection(storedSession.wsUrl);
                initialSessionObject = {
                    sessionId: storedSession.sessionId,
                    userGuid: storedSession.userGuid
                };
            }
        }

        this.state = {
            session: initialSessionObject,
            lastLoggedInUserGuid: null,
            userItem: null,
            nextTimePromptLogin: false,
            continueAfterRelogin: [],
            isLoggingIn: initialIsLoggingIn,
            inactiveMinutesToLogout: 0
        };
    }

    get lastSuccessfulLoginResponse() {
        if (!this.state.session || !this.props.lastLoadedVersion)
            return undefined;

        return {
            ReturnCode: ReturnCodes.rcSuccess,
            Description: 'Addmin app Connection component fake last login response.',
            SessionId: this.state.session.sessionId,
            UserItemGuid: this.state.session.userGuid,
            IsAdmin: true,
            WcfVersion: this.props.lastLoadedVersion.wsVersion,
            OutlookClientVersion: this.props.lastLoadedVersion.outlookClientVersion
        };
    }

    private readonly initializeApiConnection = (wsUrl: string): ApiConnection | undefined => {
        if (this.lastWsUrl && this.lastWsUrl !== wsUrl) {
            console.warn(`WS url has changed from '${this.lastWsUrl}' to  ${wsUrl}. The application is going to be reloaded.`);
            window.location.reload();
            return undefined;
        }

        LocalStorageHelper.setLocalStorageWsPath(wsUrl);

        // API connection is already initialized for this wsUrl
        if (this.apiConnection) {
            return this.apiConnection;
        }

        // Save wsUrl for later use
        this.lastWsUrl = wsUrl;

        this.apiConnection = new ApiConnection(wsUrl, this, handleConnectionError, true);

        this.shoppingConnection = new ShoppingConnection(this.apiConnection);
        this.commonDataConnection = new CommonDataConnection(this.apiConnection, handleConnectionError);

        return this.apiConnection;
    };

    readonly getApiConnection = () => {
        if (!this.apiConnection)
            throw new Error('Api connection has not been initialized yet. Do not use this getter until first login is done.');

        return this.apiConnection;
    };

    readonly getShoppingConnection = () => {
        if (!this.shoppingConnection)
            throw new Error('Shopping connection has not been initialized yet. Do not use this getter until first login is done.');

        return this.shoppingConnection;
    };

    readonly getCommonDataConnection = () => {
        if (!this.commonDataConnection)
            throw new Error('Common data connection has not been initialized yet. Do not use this getter until first login is done.');

        return this.commonDataConnection;
    };

    private readonly handleLoginComplete = () => {
        setTimeout(() => {
            if (this.props.onLogin) {
                void this.props.onLogin(!!this.state.lastLoggedInUserGuid && this.state.session?.userGuid !== this.state.lastLoggedInUserGuid);
            }
        }, 10);
    };

    private static getBrowserId(): string {
        const cookie = Cookies.getCookie<TBrowserCookie>(Cookies.names.browserId);
        if (typeof cookie.browserGuid === "undefined") {
            const guid = uuidv1();
            Cookies.setCookie<TBrowserCookie>(Cookies.names.browserId, { browserGuid: guid }, 365 * 24 * 5);
            return guid;
        }
        return cookie.browserGuid;
    }

    readonly getUpdateResult = (callback: (stage: UpdateStatusMessageType, otherData: string[] | null, otherDataObject: IServerUpdaterAdditionalData | null) => void, notExistCallback: () => void) => {
        eway.getUpdateResult(
            this.getApiConnection().wsUrl,
            (stageText: string, otherData: string[] | null, otherDataObject: IServerUpdaterAdditionalData | null) => callback(stageText as UpdateStatusMessageType, otherData, otherDataObject),
            notExistCallback
        );
    };

    readonly askApi = async <TResult extends IApiResult>(methodName: string, data: TInputData, catchGeneralUnsuccess = true, httpMethod?: HttpMethod): Promise<TResult> => {
        const promise = new Promise<TResult>((resolve, reject) => {
            this.callApi<TResult>(methodName, data, resolve, reject, httpMethod);
        });

        if (!catchGeneralUnsuccess)
            return await promise;

        try {
            return await promise;
        } catch (err) {
            if (err && typeof err === 'object' && 'ReturnCode' in err) {
                const result = err as TResult;
                console.error('Unhandled connection return code ' + result.ReturnCode + ': ' + result.Description);
                alert(Strings.components.connection.error);
            }
            throw err;
        }
    };

    readonly callApi = <TResult extends IApiResult>(methodName: string, data: TInputData, successCallback: (result: TResult) => void, unsuccessCallback?: (result: TResult) => void, httpMethod?: HttpMethod) => {
        if (!httpMethod) {
            httpMethod = HttpMethod.post;
        }
        const continueObject = new ConnectionContinueObject(methodName, data, successCallback, unsuccessCallback, httpMethod);

        if (!this.state.session) {
            this.nullSessionAndAddContinueCallback(continueObject);
            return;
        }

        const unsuccessClb = (result: TResult) => {
            if (result.ReturnCode === ReturnCodes.rcWebServiceMoved) {
                console.warn('Got rcWebServiceMoved while calling method ' + methodName + '.');
                this.handleMovedWebService();
                return;
            }
            if (unsuccessCallback) {
                unsuccessCallback(result);
            } else {
                console.error('Unhandled connection return code ' + result.ReturnCode + ': ' + result.Description);
                alert(Strings.components.connection.error);
            }
        };

        this.getApiConnection().callMethod(methodName, data, successCallback, unsuccessClb, httpMethod);
    };

    private readonly nullSessionAndAddContinueCallback = (continueObject?: IConnectionContinueObject, sessionNulledCallback?: () => void) => {
        this.setState(
            (prevState) => {
                let continueQueue: IConnectionContinueObject[];
                if (!!continueObject) {
                    if (!prevState.continueAfterRelogin || prevState.continueAfterRelogin.length === 0) {
                        continueQueue = [continueObject];
                    } else {
                        continueQueue = prevState.continueAfterRelogin.concat([continueObject]);
                    }
                } else {
                    continueQueue = prevState.continueAfterRelogin.slice();
                }
                return {
                    session: null,
                    lastLoggedInUserGuid: prevState.session?.userGuid ?? null,
                    userItem: null,
                    isLoggingIn: false,
                    continueAfterRelogin: continueQueue
                };
            },
            sessionNulledCallback
        );
    };

    private readonly handleMovedWebService = () => {
        if (this.state.continueAfterRelogin && this.state.continueAfterRelogin.length !== 0) {
            this.state.continueAfterRelogin.forEach(() => console.warn('Due to moved web service, canceling queued call.'));
        }

        eway.callWsGet(
            this.getApiConnection().wsUrl,
            'GetNewWebServiceAddressAfterMoving',
            (result: { StringResponse: { StringData: { _text: string | undefined } | undefined } | undefined }) => {
                if (!result || !result.StringResponse || !result.StringResponse.StringData || !result.StringResponse.StringData._text) {
                    console.error('Result GetNewWebServiceAddressAfterMoving has not the expected structure. Unable to continue.');
                    console.error(result);
                    alert(Strings.components.connection.error);
                    return;
                }

                let search = this.props.location.search;
                if (search) {
                    search = '?' + search;
                }
                const newUrl = result.StringResponse.StringData._text + 'admin/#' + this.props.location.pathname + search + this.props.location.hash;
                console.log('Based on moved flag, will redirect to: "' + newUrl + '" within 2 seconds.');

                setTimeout(
                    () => {
                        window.location.href = newUrl;
                    },
                    1000 + Math.floor(Math.random() * 2000)
                );
            },
            (error) => {
                console.error('Cought error when calling GetNewWebServiceAddressAfterMoving. Unable to continue.');
                console.error(error);
                alert(Strings.components.connection.error);
            }
        );

    };

    readonly invalidateSessionId = (sessionId: string, callback: () => void) => {
        // This method is called when the api connection receives rcBadSession

        if (this.state.session?.sessionId === sessionId) {
            LocalStorageHelper.saveSession(null);

            // No callback into queue, api lib will continue as the state is changed.
            this.nullSessionAndAddContinueCallback(undefined, callback);
        } else {
            callback();
        }
    };

    readonly getSessionId = (connection: ApiConnection, callback: (sessionId: string) => void) => {
        if (this.state.session) {
            callback(this.state.session.sessionId);
        } else {
            this.nullSessionAndAddContinueCallback(new InvalidateSessionContinueObject(() => this.getSessionId(connection, callback)));
        }
    };

    private readonly callApiLogin = (accessToken: string, username: string) => {
        const headers = {
            'Authorization': 'Bearer ' + accessToken
        };

        let wsUrl: string;
        if (!isPremise) {
            wsUrl = OAuthHelper.decodeAccessToken(accessToken).ws!;
            if (!wsUrl)
                throw new Error('Access token is missing ws url.');
        } else {
            wsUrl = eway.getPremiseWsUrl();
        }

        const apiConnection = this.initializeApiConnection(wsUrl);
        if (!apiConnection)
            return;

        apiConnection.callWithoutSession(
            ApiMethods.logIn,
            {
                userName: username,
                passwordHash: null,
                appVersion: 'AdministrationWebApp03',
                clientMachineIdentifier: Connection.browserId,
                clientMachineName: window.navigator.userAgent,
                createSessionCookie: apiConnection.supportsGetItemPreviewMethod
            },
            (result: IApiLoginResponse) => {
                const newSessionId = result.SessionId;
                const userGuid = result.UserItemGuid;
                if (!newSessionId || !userGuid) {
                    console.error("Successful login but no session or user guid.");
                    this.setState({
                        session: null,
                        userItem: null,
                        nextTimePromptLogin: true,
                        isLoggingIn: false
                    });
                    return;
                }
                if (!result.IsAdmin) {
                    console.warn("Successful login but the user is not admin.");
                    this.setState({
                        session: null,
                        userItem: null,
                        nextTimePromptLogin: true,
                        isLoggingIn: false
                    });
                    return;
                }

                LocalStorageHelper.saveSession({
                    sessionId: newSessionId,
                    userGuid,
                    wsUrl
                });

                const finalCallback = () => {
                    // Set state to logged in.
                    this.setState(
                        {
                            session: {
                                sessionId: newSessionId,
                                userGuid
                            },
                            nextTimePromptLogin: false,
                            isLoggingIn: false
                        },
                        () => {
                            this.loadEnvironmentData({
                                wsVersion: result.WcfVersion,
                                outlookClientVersion: result.OutlookClientVersion
                            })
                                .catch((err) => console.error('Unable to load environment data.', err));
                            this.doQueuedJobs();
                            this.handleLoginComplete();
                        }
                    );
                };

                if (this.props.authSettings) {
                    finalCallback();
                    return;
                }

                void this.loadAuthSettings(apiConnection)
                    .then(finalCallback);
            },
            (result: IApiLoginResponse) => {
                if (result.ReturnCode === ReturnCodes.rcWebServiceMoved) {
                    console.warn('Caught rcWebServiceMoved when trying to login.');
                    this.handleMovedWebService();
                    return;
                }

                if (result.ReturnCode === ReturnCodes.rcAccessDenied) {
                    console.warn('The login was successful, but the user has no access to admin panel.');
                } else {
                    console.error('Unable to login. Error response follows.');
                    console.error(result);
                }

                LocalStorageHelper.saveSession(null);

                this.setState(prevState => ({
                    session: null,
                    lastLoggedInUserGuid: prevState.session?.userGuid ?? null,
                    userItem: null,
                    nextTimePromptLogin: true,
                    isLoggingIn: false
                }));
            },
            headers,
            HttpMethod.post
        );
    };

    private readonly doQueuedJobs = () => {
        if (this.state.continueAfterRelogin && this.state.continueAfterRelogin.length !== 0) {
            const queue = this.state.continueAfterRelogin;
            this.setState(
                { continueAfterRelogin: [] },
                () => {
                    queue.forEach((continueObject, _) => {
                        continueObject.callNow(this);
                    });
                }
            );
        }
    };

    private readonly loadEnvironmentData = async (version?: TVersionsObject) => {
        if (!version) {
            const response = await this.getApiConnection()
                .askMethod<IApiDatumResponse<{ OutlookClientVersion: string; WebServiceVersion: string }>>('GetVersion', {});
            version = {
                outlookClientVersion: response.Datum.OutlookClientVersion,
                wsVersion: response.Datum.WebServiceVersion
            };
        }
        this.props.onVersionLoaded(version);

        const userGuid = this.state.session?.userGuid;
        if (!userGuid)
            return;

        await this.checkIsAdminSession();

        const remoteItemStore = new RemoteItemStore(this);
        const inactiveMinutes = await remoteItemStore.askForGlobalSetting(GlobalSettingsNames.adminAppInactiveLogoutTime, 0);
        const usersByItemGuidsRes = await this.askApi<IApiDataResponse<IApiUser>>('GetUsersByItemGuids', {
            itemGuids: [userGuid],
            includeForeignKeys: true
        });

        if (usersByItemGuidsRes.Data.length > 0) {
            let gsInactiveMinutes = Number(inactiveMinutes);
            if (gsInactiveMinutes <= 0) {
                gsInactiveMinutes = 0;
            }

            await ReactHelper.setState(this, {
                userItem: usersByItemGuidsRes.Data[0],
                inactiveMinutesToLogout: gsInactiveMinutes
            });
            this.checkLogoutTimeout();
        } else {
            console.error(`Unable to find logger user item by the guid '${userGuid}'.`);
        }
    };

    private readonly checkLogoutTimeout = () => {
        if (this.state.inactiveMinutesToLogout !== 0) {
            const inactiveSeconds = this.activityWatcher.getInactivitySeconds();
            const inactiveSecondsToLogout = (this.state.inactiveMinutesToLogout * 60);
            if (inactiveSeconds > inactiveSecondsToLogout) {
                console.log(`User is inactive for '${inactiveSeconds}' seconds.`);
                if (this.state.session) {
                    this.logout('promptLogin');
                }
            } else {
                setTimeout(
                    this.checkLogoutTimeout,
                    ((inactiveSecondsToLogout - inactiveSeconds) * 1000) + 1500
                );
                console.log(`User will be logged out after '${inactiveSecondsToLogout - inactiveSeconds}' seconds.`);
            }
        }
    };

    public readonly logout = (mode: 'promptLogin' | 'oauthLogout' | 'switchAccount') => {
        const finallyCallback = () => {
            switch (mode) {
                case 'oauthLogout':
                    const url = this.getApiConnection().wsUrl + "/auth/connect/endsession?ui_locales=" + encodeURI(Strings.getLanguage());
                    window.location.href = url;
                    return;

                case 'switchAccount':
                    window.location.reload();
                    return;

                case 'promptLogin':
                    this.setState(prevState => ({
                        isLoggingIn: false,
                        session: null,
                        lastLoggedInUserGuid: prevState.session?.userGuid ?? null,
                        userItem: null,
                        nextTimePromptLogin: true
                    }));
                    break;
            }
        };

        LocalStorageHelper.saveSession(null);

        if (this.state.session) {
            this.callApi(
                ApiMethods.logOut,
                {},
                finallyCallback,
                (result) => {
                    console.error('Unable to log out: \r\n' + result.ReturnCode + '\r\n' + result.Description);
                    finallyCallback();
                }
            );
        } else {
            finallyCallback();
        }
    };

    readonly fakeActivity = () => {
        this.activityWatcher.fakeActivity();
    };

    readonly checkIsAdminSession = async () => {
        if (!this.state.session) {
            this.logout('switchAccount');
            return;
        }

        const { Datum } = await this.getApiConnection().askMethod<IApiDatumResponse<boolean>>('CheckIsAdminSession', { transmitObject: this.state.session.sessionId });
        if (!Datum) {
            this.logout('switchAccount');
        }
    };

    readonly loadAuthSettings = async (apiConnection: ApiConnection, doNotAlertOnError?: boolean) => {
        const authSettings = await new Promise<IApiServiceAuthSettingsResponse>((res, rej) => {
            apiConnection.callWithoutSession<IApiServiceAuthSettingsResponse>(
                ApiMethods.getServiceAuthSettings,
                null,
                res,
                doNotAlertOnError
                    ? rej
                    : (response) => handleConnectionError(new Error(`Error response: ${JSON.stringify(response)}`)),
                undefined,
                HttpMethod.get,
                doNotAlertOnError
                    ? rej
                    : undefined
            );
        });
        this.props.onAuthSettingsLoaded({
            isActiveDirectory: authSettings.IsActiveDirectory,
            isAzureAuth: authSettings.IsAzureAuth
        });
    };

    componentDidMount() {
        this.activityWatcher.start();

        if (!this.state.session) {
            // On premise, load auth settings as first
            if (isPremise) {
                // The instance of ApiConnection is anonymous and temporary only
                // There is no need to wait for the result, because the OAuthFrame will not be rendered until the auth settings are state
                void this.loadAuthSettings(ApiConnection.createAnonymous(eway.getPremiseWsUrl(), handleConnectionError));
            }
        } else {
            // The session was assigned already in constructor, so no login procedure was done. Load the environment data manually
            // If there is a network error on first api call and we are on the cloud, consider the ws url to be delete and initiate a standard login
            void (async () => {
                try {
                    await this.loadAuthSettings(this.getApiConnection(), !isPremise);
                } catch (err) {
                    console.error('Unable to load auth settings.', err);
                    if (!isPremise && !(typeof err === 'object' && err && 'ReturnCode' in err)) {
                        LocalStorageHelper.saveSession(null);
                        console.warn('An error caught on first api call to ws obtained from constructor. Considering the server as being deleted. Reloading the page with session deleted...');
                        window.location.reload();
                    }
                    return;
                }
                try {
                    await this.loadEnvironmentData();
                } catch (err) {
                    console.error('Unable to load environment data.', err);
                    return;
                }

                this.handleLoginComplete();
            })();
        }
    }

    private readonly handleOAuthLoggedIn = (accessToken: string, username: string) => {
        this.activityWatcher.fakeActivity();
        this.setState(
            {
                isLoggingIn: true,
            },
            () => this.callApiLogin(accessToken, username)
        );
    };

    // This is a workarround for those who want to change the ws url but it is stored in the local storage - the close manually the login popup
    private readonly onLoginPopupFoundClosed = () => {
        // Login popup is asynchronously found closed even when the login proceeds and it is a RC to detect, whether the login is continuing.
        // That is why we try waiting a little bit and then read the most current state using the prevState.
        setTimeout(() => {
            if (this.state.isLoggingIn || !!this.state.session) {
                // Login is proceeding or is already done. Do not consider this popup to be closed by the user -> change nothing
                return;
            }

            console.log('Login PopUp closed by user when a ws url was already used, reloading page...');
            LocalStorageHelper.saveSession(null);
            LocalStorageHelper.setItem(LocalStorageHelper.names.doNotOpenLoginPopupNextTime, true, 3000);
            window.location.reload();
        }, 500);
    };

    render() {
        // Show spinner on logging in in process or for missing auth settings
        if ((!this.state.session && this.state.isLoggingIn) || ((isPremise || !!this.state.session) && !this.props.authSettings)) {
            return (
                <div className={css.authOverall}>
                    <div className="h-100 position-relative">
                        <Spinner variant={SpinnerVariant.ease} />
                    </div>
                </div>
            );
        }

        if (!!this.state.session)
            return null;

        return (
            <div className={css.authOverall}>
                <OAuthFrame
                    onLogIn={this.handleOAuthLoggedIn}
                    username={this.state.userItem?.Username || null}
                    wsUrl={this.wsUrlFromGetParams ?? this.lastWsUrl}
                    prompt={this.state.nextTimePromptLogin ? 'login' : null}
                    isPremiseAzureAuth={isPremise && this.props.authSettings!.isAzureAuth}
                    onLoginPopupFoundClosed={this.onLoginPopupFoundClosed}
                />
            </div>
        );
    }
}