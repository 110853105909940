import type { IApiDataResponse, IApiGroup, IApiSaveResponse, IApiUser, IApiUserSetting} from '@eway-crm/connector';
import { FolderNames, RelationTypes } from '@eway-crm/connector';
import { Dropdown, FontSizes } from '@fluentui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import { UserSettingsKeys } from '../../../data/constants/UserSettingsKeys';
import Strings from '../../../strings';
import type { Connection } from '../../layout/connection/Connection';
import { SpinnerModal } from '../../shared/SpinnerModal';
import WizardModal from '../../WizardModal';
import ideaImg from '../../../img/welcomeModal/idea.svg';
import { GroupNames } from '../../../data/constants/GroupNames';
import ReactHelper from '../../../helpers/ReactHelper';
import { SpinnerVariant } from '@eway-crm/gui';

const IS_ALREADY_SEEN_VALUE = '1';
const myStrings = Strings.components.routes.home.newFeaturesWizard;

const DROPDOWN_STYLES = {
    root: {
        height: '65px',
    },
};

const DROPDOWN_OPTIONS = [
    { key: 'Yes', text: Strings.yes },
    { key: 'No', text: Strings.no },
];

type TNewFeaturesModalProps = {
    onDismiss: () => void;
    connection: Connection;
};

type TNewFeaturesModalState = {
    isSaving: boolean;
    selectedKey: string | null;
    isValidated: boolean;
    showDialog: boolean;
};

export default class NewFeaturesModal extends React.Component<TNewFeaturesModalProps, TNewFeaturesModalState> {
    constructor(props: TNewFeaturesModalProps) {
        super(props);
        this.state = {
            showDialog: false,
            isSaving: false,
            selectedKey: null,
            isValidated: false,
        };
    }

    private readonly setNewFeaturesModalSeen = async () => {
        const { askApi } = this.props.connection;

        const transmitObject: Partial<IApiUserSetting> = {
            Path: UserSettingsKeys.newFeaturesSeenPath,
            Name: UserSettingsKeys.newFeaturesSeenName,
            Value: IS_ALREADY_SEEN_VALUE,
        };

        const userSettingRes = await askApi<IApiSaveResponse>('SaveUserSetting', { transmitObject });
        const groupsRes = await askApi<IApiDataResponse<IApiGroup>>('SearchGroups', { transmitObject: { GroupName: GroupNames.administrator } });
        const adminGroupGuid = groupsRes.Data[0]?.ItemGUID;

        if (adminGroupGuid) {
            const relationsTransmitObject = {
                ItemGUID1: userSettingRes.Guid,
                ItemGUID2: adminGroupGuid,
                FolderName1: FolderNames.userSettings,
                FolderName2: FolderNames.groups,
                RelationType: RelationTypes.group,
                DifferDirection: false,
            };

            await askApi<IApiSaveResponse>('SaveRelation', { transmitObject: relationsTransmitObject });
        }
    };

    static readonly getIsNewFeaturesModalSeen = async (connection: Connection) => {
        const result = await connection.askApi<IApiDataResponse<IApiUserSetting>>('SearchUserSettings', {
            transmitObject: {
                Path: UserSettingsKeys.newFeaturesSeenPath,
                Name: UserSettingsKeys.newFeaturesSeenName,
            },
        });
        return result.Data.some((us) => us.Value === IS_ALREADY_SEEN_VALUE);
    };

    private readonly registerUsers = async () => {
        const usersRes = await this.props.connection.askApi<IApiDataResponse<IApiUser>>('GetUsers', {});
        const usersToRegister = usersRes.Data.filter((u) => !!u.IsActive && !!u.Email1Address);
        const transmitObjects = usersToRegister.map((u) => ({ Email: u.Email1Address, FirstName: u.FirstName, LastName: u.LastName }));

        await this.props.connection.getShoppingConnection().askShoppingApi('RegisterEmployeesUsersEmails', {
            transmitObjects,
            signUpForTips: true
        });
    };

    private readonly handleSubmit = async () => {
        if (!this.state.selectedKey) {
            await ReactHelper.setState(this, { isValidated: true });
            return;
        }

        await ReactHelper.setState(this, { isSaving: true });
        if (this.state.selectedKey === 'Yes') {
            await this.registerUsers();
        }

        await this.setNewFeaturesModalSeen();
        await ReactHelper.setState(this, { isSaving: false });

        this.props.onDismiss();
    };

    private readonly handleDismiss = () => {
        this.props.onDismiss();
    };

    private readonly showDialog = async () => {
        const { isEnabled } = await this.props.connection.getShoppingConnection().askIsEnabled();

        if (isEnabled) {
            this.setState({ showDialog: isEnabled });
        }
    };

    componentDidMount() {
        this.showDialog()
            .catch((err) => console.error('Unable to show new features dialog.', err));
    }

    render() {
        if (this.state.isSaving) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        }

        return (
            <WizardModal show={this.state.showDialog} onHide={this.props.onDismiss} size="lg">
                <WizardModal.Body>
                    <div className="d-flex">
                        <img src={ideaImg} alt="thinking" style={{ width: 200 }} />
                        <div className="pl-xl-5 pl-lg-4 pl-md-2">
                            <div style={{ fontSize: FontSizes.size32 }} className="pb-2">
                                {myStrings.title}
                            </div>
                            <div className="mb-3" style={{ fontSize: FontSizes.size14 }}>
                                <div className="mt-2 text-break">{myStrings.subtitle}</div>
                                <Dropdown
                                    placeholder={myStrings.sendTipsPlaceholder}
                                    label={myStrings.sendTipsLabel}
                                    className="mt-3"
                                    styles={DROPDOWN_STYLES}
                                    options={DROPDOWN_OPTIONS}
                                    selectedKey={this.state.selectedKey}
                                    onChange={(e, o) => this.setState({ selectedKey: (o?.key as string) ?? null })}
                                    errorMessage={this.state.isValidated && !this.state.selectedKey ? myStrings.dropdownError : undefined}
                                />
                            </div>
                        </div>
                    </div>
                </WizardModal.Body>
                <WizardModal.Footer>
                    <Button variant="primary" onClick={() => void this.handleSubmit()}>
                        {Strings.continue}
                    </Button>
                    <Button variant="outline-secondary" onClick={this.handleDismiss}>
                        {myStrings.cancelButton}
                    </Button>
                </WizardModal.Footer>
            </WizardModal>
        );
    }
}
