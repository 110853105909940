import * as React from 'react';
import type { TPopoverPlacement } from './StableOverlayPopover';
import StableOverlayPopover from './StableOverlayPopover';
import { mergeStyleSets, mergeStyles } from '@fluentui/react';
import helpBlackImg from '../../img/helpTextBlack.svg';
import helpWhiteImg from '../../img/helpTextWhite.svg';
import helpRedImg from '../../img/helpTextRed.svg';

type TQuestionMarkPopoverProps = React.PropsWithChildren & {
    placement: TPopoverPlacement;
    color: 'black' | 'white' | 'red';
    title?: string;
    className?: string;
};

const css = mergeStyleSets({
    img: {
        height: '1.0rem'
    }
});

export const QuestionMarkPopover: React.FC<TQuestionMarkPopoverProps> = (props) => {
    let img;
    switch (props.color) {
        case 'white':
            img = helpWhiteImg;
            break;
        case 'red':
            img = helpRedImg;
            break;
        default:
            img = helpBlackImg;
            break;
    }
    return (
        <StableOverlayPopover
            placement={props.placement}
            popoverTitle={props.title}
            popoverContent={props.children}
            className={mergeStyles(props.className, 'd-inline')}
        >
            <img src={img} alt="" className={mergeStyles(css.img, 'm-1')} />
        </StableOverlayPopover>
    );
};