import * as React from 'react';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import StringHelper from './../../helpers/StringHelper';
import Strings from '../../strings';
import type { RouteComponentProps } from 'react-router-dom';
import SideMenuGrid from './SideMenuGrid';
import GroupAvatar from './groups/GroupAvatar';

type TGridRow = {
    itemId: string;
    itemName: string;
    itemDescription: string | null;
};

type TGroupsListMenuProps = Partial<Pick<RouteComponentProps, 'history'>> & {
    items: TGridRow[];
    onItemChange?: (newItemId: string) => void;
    selectedItemId: string | null;
    searchedString: string;
    isCollapsed: boolean;
    path?: string;
    pathEnd?: string | null;
};

const CellComponent: React.FunctionComponent<Table.DataCellProps> = (props) => {
    const typedRow = props.row as TGridRow;
    if (props.column.name === 'icon') {
        return (
            <VirtualTable.Cell className="secondary-menu__iconCell" {...props}>
                <GroupAvatar item={{ GroupName: typedRow.itemName, ItemGUID: typedRow.itemId }} size={28} />
            </VirtualTable.Cell>
        );
    }

    return (
        <VirtualTable.Cell className="align-middle text-truncate secondary-menu__contentCell" {...props} title={typedRow.itemDescription}>
            {props.value}
            <div className="text-truncate secondary-menu__cellDescription">{typedRow.itemDescription}</div>
        </VirtualTable.Cell>
    );
};

const myStrings = Strings.components.shared.foldersMenu;
const gridColumns = [
    { name: 'icon', title: '' },
    { name: 'itemName', title: '' },
];
const columnExtensions: VirtualTable.ColumnExtension[] = [
    { columnName: 'icon', width: '54px' },
    { columnName: 'itemName', width: '190px' },
];
const gridColumnsCollapsed = gridColumns.filter((col) => col.name !== 'itemName');
const columnExtensionsCollapsed = columnExtensions.filter((col) => col.columnName !== 'itemName');

class GroupsListMenu extends React.Component<TGroupsListMenuProps> {
    private readonly getDisplayedData = (): TGridRow[] => {
        return this.props.items.sort((a, b) => StringHelper.localeCompare(a.itemName, b.itemName));
    };

    private readonly handleSelectionChange = (newItemId: string) => {
        const newItem = this.props.items.find((item) => item.itemId === newItemId);
        if (newItem) {
            this.props.onItemChange && this.props.onItemChange(newItem.itemId);
            this.props.path && this.props.history && this.props.history.push(`${this.props.path}/${newItem.itemId.toLowerCase()}/${this.props.pathEnd ? this.props.pathEnd : ''}`);
        }
    };

    render() {
        return (
            <SideMenuGrid
                items={this.getDisplayedData()}
                columns={this.props.isCollapsed ? gridColumnsCollapsed : gridColumns}
                getRowId={(row) => row.itemId}
                selectedItemId={this.props.selectedItemId}
                onMenuItemChange={this.handleSelectionChange}
                searchedString={this.props.searchedString}
                cellComponent={CellComponent}
                columnExtensions={this.props.isCollapsed ? columnExtensionsCollapsed : columnExtensions}
                noDataText={myStrings.noFoldersMatch}
            />
        );
    }
}

export default GroupsListMenu;
