import * as React from 'react';
import { PrimaryButton, DefaultButton, MessageBar, MessageBarType, Stack } from '@fluentui/react';
import Strings from '../../../../../../strings';
import type { TStripeCardAdderContainerProps } from '../../../../../shared/paymentGate/StripeCardAdder';
import StripeCardAdder from '../../../../../shared/paymentGate/StripeCardAdder';
import StoreV2Context from '../../StoreV2Context';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const STRIPE_CARD_FORM_DIV_STYLE = { width: '308px' };

type TStripeCardEnteringFormProps = React.PropsWithChildren & {
    onCanceled: () => void;
    onAdded: (id: string) => void;
    submitButtonText?: string;
    onBeforeSubmitting?: () => boolean;
};

const StripeCardEnteringForm: React.FC<TStripeCardEnteringFormProps> = (superProps) => {
    const { shoppingConnection } = React.useContext(StoreV2Context);

    const { onCanceled, children: superChildren, submitButtonText, onBeforeSubmitting } = superProps;
    const ctx = React.useMemo(() => ({
        onCanceled,
        superChildren,
        submitButtonText,
        onBeforeSubmitting
    }), [onCanceled, superChildren, submitButtonText, onBeforeSubmitting]);

    return (
        <StripeCardEnteringFormPrivateContext.Provider value={ctx}>
            <StripeCardAdder
                loadingComponent={loader}
                elementsContainerComponent={StripeCardFormDiv}
                onAdded={superProps.onAdded}
                shoppingConnection={shoppingConnection}
            />
        </StripeCardEnteringFormPrivateContext.Provider>
    );
};

const loader = (
    <div className="checkoutV2__loader">
        <Spinner variant={SpinnerVariant.ease} />
    </div>
);

type TStripeCardEnteringFormPrivateContext = Pick<TStripeCardEnteringFormProps, 'onCanceled' | 'submitButtonText' | 'onBeforeSubmitting'> & {
    superChildren?: React.ReactNode;
};
const StripeCardEnteringFormPrivateContext = React.createContext<TStripeCardEnteringFormPrivateContext>(null!);

const StripeCardFormDiv: React.FC<TStripeCardAdderContainerProps> = (props) => {
    const { onCanceled, superChildren, submitButtonText, onBeforeSubmitting } = React.useContext(StripeCardEnteringFormPrivateContext);
    const { onSubmit } = props;
    const myStrings = Strings.components.routes.billingDetails;

    const handleSubmit = React.useCallback(() => {
        if (onBeforeSubmitting && !onBeforeSubmitting())
            return;

        onSubmit();
    }, [onBeforeSubmitting, onSubmit]);

    return (
        <div style={STRIPE_CARD_FORM_DIV_STYLE}>
            <h3 className="mb-5">{myStrings.newPaymentMethod}</h3>
            {(!!props.errorMessage) &&
                <MessageBar
                    messageBarType={MessageBarType.error}
                    isMultiline={false}
                    className="mb-3"
                >
                    {props.errorMessage}
                </MessageBar>
            }
            <div className={props.isProcessing ? 'd-none' : undefined}>
                {props.children}
            </div>
            {(props.isProcessing) &&
                <div className="position-relative size-5rem w-100 mb-5">
                    <Spinner variant={SpinnerVariant.linear} />
                </div>
            }
            <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: '8' }}>
                <PrimaryButton onClick={handleSubmit} disabled={!props.isSubmittable}>
                    {submitButtonText || myStrings.add}
                </PrimaryButton>
                <DefaultButton onClick={onCanceled} disabled={props.isProcessing}>
                    {Strings.cancel}
                </DefaultButton>
            </Stack>
            {(!!superChildren) &&
                <div>
                    {superChildren}
                </div>
            }
        </div>
    );
};

export default StripeCardEnteringForm;