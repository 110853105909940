import Strings from './../../strings';

export class SummaryTypes {
    static readonly sum = 'Sum';
    static readonly min = 'Min';
    static readonly max = 'Max';
    static readonly count = 'Count';
    static readonly average = 'Average';

    static readonly all = [SummaryTypes.sum, SummaryTypes.min, SummaryTypes.max, SummaryTypes.count, SummaryTypes.average];

    static getLocalizedName(summaryType: string): string {
        switch (summaryType) {
            case SummaryTypes.sum:
                return Strings.summaryTypes.sum;
            case SummaryTypes.min:
                return Strings.summaryTypes.min;
            case SummaryTypes.max:
                return Strings.summaryTypes.max;
            case SummaryTypes.count:
                return Strings.summaryTypes.count;
            case SummaryTypes.average:
                return Strings.summaryTypes.average;
            default:
                return '';
        }
    }
}