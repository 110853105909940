import * as React from 'react';
import Strings from '../../strings';
import { Button, ButtonToolbar } from 'react-bootstrap';
import type { ShoppingConnection } from '../ShoppingConnection';
import type { IPaymentMethodsModel } from '../../data/shopping/IPaymentMethodsModel';
import { NavLink } from 'react-router-dom';
import RouteConfig from '../../RouteConfig';
import { AddingButton } from './billingDetails/AddingButton';
import { MethodsGridTable } from './billingDetails/MethodsGridTable';
import type { IBillingInfoModel } from '../../data/shopping/IBillingInfoModel';
import type { TCountriesAndRegions} from './subscriptions/TCountriesAndRegions';
import { loadCountriesAndRegions } from './subscriptions/TCountriesAndRegions';
import { RemoteItemStore } from '../../RemoteItemStore';
import { CompanyDetailsEditor } from './billingDetails/CompanyDetailsEditor';
import type { TCustomerInfo } from './subscriptions/TCustomerInfo';
import type { ICheckoutCustomerModel } from '../../data/shopping/ICheckoutOrderModel';
import { EmailsGridTable } from './billingDetails/EmailsGridTable';
import ReactHelper from '../../helpers/ReactHelper';
import type { IShoppingDatumResponse } from '../../data/shopping/IShoppingDatumResponse';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TPaymentMethodsProps = {
    shoppingConnection: ShoppingConnection;
};

type TPaymentMethodsState = {
    countries: TCountriesAndRegions | null;
    billingInfo: IBillingInfoModel | null;
    paymentMethods: IPaymentMethodsModel | null;
    isAddingNewBillingEmail: boolean;
};

export class PaymentMethods extends React.Component<TPaymentMethodsProps, TPaymentMethodsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TPaymentMethodsProps) {
        super(props);

        this.state = {
            countries: null,
            billingInfo: null,
            paymentMethods: null,
            isAddingNewBillingEmail: false
        };
    }

    componentDidMount() {
        // Load all data.
        this.loadCountries()
            .then(this.loadBillingInfo)
            .then(this.loadPaymentMethods)
            .catch((err) => console.error('Unable to load billing info or payment methods.', err));
    }

    private readonly loadCountries = async () => {
        const countries = await loadCountriesAndRegions(new RemoteItemStore(this.context.connection));
        await ReactHelper.setState(this, { countries: countries });
    };

    private readonly loadBillingInfo = async () => {
        const infoResult = await this.props.shoppingConnection.askShoppingApi<IShoppingDatumResponse<IBillingInfoModel>>('GetBillingInfo', {});
        await ReactHelper.setState(this, { billingInfo: infoResult.Datum });
    };

    private readonly reloadBillingInfo = async () => {
        await ReactHelper.setState(this, { billingInfo: null, isAddingNewBillingEmail: false });
        await this.loadBillingInfo();
    };

    private readonly loadPaymentMethods = async () => {
        const methodsResult = await this.props.shoppingConnection.askShoppingApi<IShoppingDatumResponse<IPaymentMethodsModel>>('GetPaymentMethods', {});
        await ReactHelper.setState(this, { paymentMethods: methodsResult.Datum });
    };

    private readonly reloadPaymentMethods = async () => {
        await ReactHelper.setState(this, { paymentMethods: null });
        await this.loadPaymentMethods();
    };

    private readonly changeBillingInfo = (customerInfo: TCustomerInfo) => {
        const model: ICheckoutCustomerModel = {
            BusinessName: customerInfo.businessName,
            AddressLine1: customerInfo.street,
            City: customerInfo.city,
            State: customerInfo.state,
            Country: customerInfo.country,
            PostalCode: customerInfo.postalCode,
            Email: customerInfo.emailAddress,
            Phone: customerInfo.phone,
            IdentificationNumber: customerInfo.identificationNumber,
            VatNumber: customerInfo.vatNumber,
            TaxNumber: customerInfo.taxNumber,
            ChargeAdditionalServicesAutomatically: customerInfo.chargeAdditionalServicesAutomatically,
            AdditionalInformation: null,
            AddressLine2: null,
            FirstName: null,
            LastName: null
        };

        this.setState(
            { billingInfo: null, isAddingNewBillingEmail: false },
            () => {
                this.props.shoppingConnection.callShoppingApi(
                    'SaveBillingInfo',
                    {
                        customerInfoModel: model
                    },
                    () => {
                        this.loadBillingInfo()
                            .catch((err) => console.error('Unable to load billing info.', err));
                    }
                );
            });
    };

    private readonly addBillingEmailAddress = (email: string) => {
        this.setState(
            { billingInfo: null, isAddingNewBillingEmail: false },
            () => {
                this.props.shoppingConnection.callShoppingApi(
                    'AddBillingEmailAddress',
                    {
                        billingEmailAddress: email
                    },
                    () => {
                        this.loadBillingInfo()
                            .catch((err) => console.error('Unable to load billing info.', err));
                    }
                );
            });
    };

    private readonly removeBillingEmailAddress = (email: string) => {
        this.setState(
            { billingInfo: null, isAddingNewBillingEmail: false },
            () => {
                this.props.shoppingConnection.callShoppingApi(
                    'RemoveBillingEmailAddress',
                    {
                        billingEmailAddress: email
                    },
                    () => {
                        this.loadBillingInfo()
                            .catch((err) => console.error('Unable to load billing info.', err));
                    }
                );
            });
    };

    render() {
        const myStrings = Strings.components.routes.billingDetails;

        const headerComponent = (
            <>
                <h1>{myStrings.title}</h1>
                <hr className="mb-1 mt-4" />
            </>
        );

        if (!this.state.countries) {
            return (
                <div className="container-fluid d-flex flex-column min-h-100">
                    <div className="row">
                        <div className="col p-0">
                            {headerComponent}
                        </div>
                    </div>
                    <Spinner backgroundOpacity={0} variant={SpinnerVariant.ease}/>
                </div>
            );
        }

        return (
            <div>
                {headerComponent}
                {(!!this.state.paymentMethods && !this.state.paymentMethods.AllowAdding) &&
                    <div className="mt-2 alert alert-info">
                        {myStrings.notAllowedUntilSubscribe}
                        <div className="d-flex justify-content-end mt-3">
                            <NavLink to={RouteConfig.billing.subscriptions.path} className="btn btn-outline-info" >
                                {myStrings.subscribeNow}
                            </NavLink>
                        </div>
                    </div>
                }
                <div className="mt-5 container px-0" style={{ maxWidth: 'none' }}>
                    <div className="row">
                        <div className="col">
                            <div className="mr-6">
                                <h3>{myStrings.companyDetails}</h3>
                                <p>{myStrings.companyDetailsSubtext}</p>
                                {(!this.state.billingInfo) ?
                                    <Spinner backgroundOpacity={0} variant={SpinnerVariant.ease} />
                                    :
                                    <>
                                        {(!!this.state.billingInfo.AllowEditing) &&
                                            <CompanyDetailsEditor countries={this.state.countries} originalCustomerInfo={this.state.billingInfo.CustomerInfo} onSaving={this.changeBillingInfo} />
                                        }
                                    </>
                                }
                            </div>
                        </div>
                        <div className="col">
                            <div>
                                <h3>{myStrings.billingEmails}</h3>
                                <p>{myStrings.billingEmailsSubtext}</p>
                                {(!this.state.billingInfo) ?
                                    <div className={"position-relative mt-5"}>
                                        <Spinner backgroundOpacity={0} variant={SpinnerVariant.ease} />
                                    </div>
                                    :
                                    <>
                                        <ButtonToolbar>
                                            <Button variant="outline-secondary" onClick={() => this.setState({ isAddingNewBillingEmail: true })} disabled={!this.state.billingInfo.AllowEditing} >
                                                <i className="mdl2 mdl2-add" aria-hidden="true" /> {myStrings.addNew}
                                            </Button>
                                            <Button variant="outline-secondary" onClick={() => void this.reloadBillingInfo()}>
                                                <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                            </Button>
                                        </ButtonToolbar>
                                        {(!!this.state.billingInfo.AllowEditing) &&
                                            <EmailsGridTable
                                                emails={this.state.billingInfo.InvoicingEmails}
                                                isAdding={this.state.isAddingNewBillingEmail}
                                                onAdded={this.addBillingEmailAddress}
                                                onRemoved={this.removeBillingEmailAddress}
                                            />
                                        }
                                    </>
                                }
                            </div>
                            <div className="mt-6">
                                <h3>{myStrings.paymentMethods}</h3>
                                {(!this.state.paymentMethods) ?
                                    <div className={"position-relative mt-5"}>
                                        <Spinner backgroundOpacity={0}/>
                                    </div>
                                    :
                                    <>
                                        <ButtonToolbar>
                                            <AddingButton shoppingConnection={this.props.shoppingConnection} paymentGateProvider={this.state.paymentMethods.PaymentGateProvider} onAdded={() => void this.reloadPaymentMethods()} disabled={!this.state.paymentMethods.AllowAdding} />
                                            <Button variant="outline-secondary" onClick={() => void this.reloadPaymentMethods()}>
                                                <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                            </Button>
                                        </ButtonToolbar>
                                        <MethodsGridTable methods={this.state.paymentMethods.Methods} stripeMethods={this.state.paymentMethods.StripeMethods} shoppingConnection={this.props.shoppingConnection} onChange={() => void this.reloadPaymentMethods()} />
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}