import * as React from 'react';
import Strings from '../../strings';
import { Button, ButtonToolbar, Form } from 'react-bootstrap';
import Cookies from '../../Cookies';
import { NewUserWizard } from './users/NewUserWizard';
import { AssignToGroupsWizard } from './users/AssignToGroupsWizard';
import { ResetPasswordWizard } from './users/ResetPasswordWizard';
import { ForceChangePasswordWizard } from "./users/ForceChangePasswordWizard";
import { DeactivateWizard } from './users/DeactivateWizard';
import StringHelper from '../../helpers/StringHelper';
import { GroupNames } from '../../data/constants/GroupNames';
import type { IApiGroup, IApiUser, IApiBoundRelation, IApiColumnPermission, IApiDataResponse, TApiItemWithReleations, IApiLocalizedLicenseBundle } from '@eway-crm/connector';
import { GlobalSettingsNames } from '@eway-crm/connector';
import { RemoteItemStore } from '../../RemoteItemStore';
import { EditUserWizard } from './users/EditUserWizard/EditUserWizard';
import { ActivateWizard } from './users/ActivateWizard';
import { LicenseMatrixWizard } from './users/LicenseMatrixWizard';
import RouteConfig from '../../RouteConfig';
import RelationTypes from '../../data/constants/RelationTypes';
import FolderNames from '../../data/constants/FolderNames';
import { FontIcon, TooltipHost } from '@fluentui/react';
import { SpinnerModal } from '../shared/SpinnerModal';
import { UsersGrid } from '../shared/users/UsersGrid';
import type { RouteComponentProps, match as IRouterMatch} from 'react-router-dom';
import { Link, Route, Switch, matchPath, Redirect } from 'react-router-dom';
import { validate as uuidValidate } from 'uuid';
import { LoaderProvider } from '../shared/WcfDataLoaderProvider';
import FilterDropdown from '../shared/FilterDropdown';
import { EffectivePermissionsWizard } from './users/EffectivePermissionsWizard';
import { Usernames } from '../../data/constants/Usernames';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import FakeDisabledButton from '../shared/FakeDisabledButton';
import LicenseRestrictionsTooltipContent from '../shared/locks/LicenseRestrictionsTooltipContent';
import ReactHelper from '../../helpers/ReactHelper';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';
import type { TMyColumnPermissionsMap } from '../shared/WizardBase';

type TCookie = {
    showSystemUsers: boolean;
    showInactiveUsers: boolean;
};

const myStrings = Strings.components.routes.users;

type TUsersProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TUsersState = {
    areDataLoaded: boolean;
    data: TApiItemWithReleations<IApiUser>[];
    groupsData: IApiGroup[];
    myColumnPermissionsMap: TMyColumnPermissionsMap;
    minimumPasswordLength: number;
    exchangeRatesAdminGroup: string | null;
    serverUpdateProgressNotificationGroup: string | null;
    systemHealthNotificationGroup: string | null;
    hiddenSystemGroups: IApiGroup[] | null;
    administratorGroupGuid: string | null;
    licenseBundles: IApiLocalizedLicenseBundle[] | null;
    showSystemUsers: boolean;
    showInactiveUsers: boolean;
    selection: string[];
    searchedString: string;
    isAssigningToGroup: boolean;
    isResetingPassword: boolean;
    isForceChangingPassword: boolean;
    isDelegating: boolean;
    isDeactivating: boolean;
    isActivating: boolean;
    isUnlocking: boolean;
    loadingEditedUserItemGuid: string | null;
};

export class Users extends React.Component<TUsersProps, TUsersState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TUsersProps) {
        super(props);

        const cookie = Cookies.getCookie<TCookie>(Cookies.names.users);
        this.state = {
            areDataLoaded: false,
            data: [],
            groupsData: [],
            myColumnPermissionsMap: {},
            minimumPasswordLength: 8,
            exchangeRatesAdminGroup: null,
            serverUpdateProgressNotificationGroup: null,
            systemHealthNotificationGroup: null,
            hiddenSystemGroups: null,
            administratorGroupGuid: null,
            licenseBundles: null,
            showSystemUsers: cookie.showSystemUsers || false,
            showInactiveUsers: cookie.showInactiveUsers || false,
            selection: [],
            searchedString: '',
            isAssigningToGroup: false,
            isResetingPassword: false,
            isForceChangingPassword: false,
            isDelegating: false,
            isDeactivating: false,
            isActivating: false,
            isUnlocking: false,
            loadingEditedUserItemGuid: null,
        };
    }

    private readonly runNextPhase = () => {
        const query = new URLSearchParams(this.props.location.search);
        if (RouteConfig.users.actions.afterBuy.isOn(query)) {
            const newUsersCount = this.getFreeLicenseSlotsCount();
            if (RouteConfig.users.actions.afterBuy.addUsersFirst(query) && this.props.location.pathname.startsWith(RouteConfig.users.new.path)) {
                this.props.history.push(`${RouteConfig.users.licences.path}?action=afterBuy&addUsersFirst=true`);
            } else if (!RouteConfig.users.actions.afterBuy.addUsersFirst(query) && this.props.location.pathname.startsWith(RouteConfig.users.licences.path) && newUsersCount > 0) {
                this.props.history.push(`${RouteConfig.users.new.path}/${newUsersCount}?action=afterBuy&addUsersFirst=false`);
            } else {
                this.props.history.push(`${RouteConfig.users.path}`);
            }
        } else {
            if (RouteConfig.users.path !== this.props.location.pathname) {
                this.props.history.push(`${RouteConfig.users.path}`);
            }
        }
    };

    private readonly reload = async () => {
        const { askApi } = this.context.connection;
        const remoteItemStore = new RemoteItemStore(this.context.connection);

        await ReactHelper.setState(this, {
            areDataLoaded: false,
            data: [],
            groupsData: [],
            licenseBundles: null,
            selection: [],
        });

        const license = await this.context.reloadLicense(true, true, false);
        const grpResult = await askApi<IApiDataResponse<IApiGroup>>('GetGroups', {});
        const [minimumPasswordLength, exchangeRatesAdminGroupName, serverUpdateProgressNotificationGroupName, systemHealthNotificationGroupName] = await remoteItemStore.askForGlobalSettings([
            { name: GlobalSettingsNames.minimumPasswordLength, defaultValue: 8 },
            { name: GlobalSettingsNames.exchangeRatesAdminGroupName, defaultValue: '' },
            { name: GlobalSettingsNames.serverUpdateProgressNotificationGroup, defaultValue: '' },
            { name: GlobalSettingsNames.systemHealthNotificationGroup, defaultValue: '' },
        ]);
        const usrResult = await askApi<IApiDataResponse<TApiItemWithReleations<IApiUser>>>('GetUsers', {
            includeForeignKeys: true,
            includeRelations: true,
            relationsFilter: {
                RelationType: RelationTypes.group,
                ForeignFolderName: FolderNames.groups,
            },
        });

        const myColumnPermissionsRes = await askApi<IApiDataResponse<IApiColumnPermission>>('GetEffectiveColumnPermissions', {
            userGuid: this.context.connection.state.session?.userGuid ?? null,
            folderName: FolderNames.users
        });

        const myColumnPermissionsMap: TMyColumnPermissionsMap = {};
        myColumnPermissionsRes.Data.forEach(cp => {
            myColumnPermissionsMap[cp.ColumnName] = cp;
        });

        await ReactHelper.setState(this, {
            areDataLoaded: true,
            data: usrResult.Data,
            groupsData: grpResult.Data.filter((item) => item.GroupName !== GroupNames.system && item.GroupName !== GroupNames.everyone),
            myColumnPermissionsMap,
            hiddenSystemGroups: grpResult.Data.filter((item) => item.GroupName === GroupNames.system || item.GroupName === GroupNames.everyone),
            administratorGroupGuid: grpResult.Data.find((item) => item.IsAdmin)!.ItemGUID,
            minimumPasswordLength: minimumPasswordLength ? parseInt(minimumPasswordLength as string) : 8,
            exchangeRatesAdminGroup: exchangeRatesAdminGroupName as string | null,
            serverUpdateProgressNotificationGroup: serverUpdateProgressNotificationGroupName as string | null,
            systemHealthNotificationGroup: systemHealthNotificationGroupName as string | null,
            licenseBundles: license.AvailableBundles.map((bundle) => ({ ...bundle, ...{ Name: Strings.pickTranslation(bundle.Name) ?? '' } })),
        });
    };

    private readonly assignToGroup = () => {
        this.setState({ isAssigningToGroup: true });
    };

    private readonly resetPassword = () => {
        this.setState({ isResetingPassword: true });
    };

    private readonly forceChangePassword = () => {
        this.setState({ isForceChangingPassword: true });
    };

    private readonly delegate = () => {
        this.setState({ isDelegating: true });
    };

    private readonly deactivate = () => {
        this.setState({ isDeactivating: true });
    };

    private readonly activate = () => {
        this.setState({ isActivating: true });
    };

    private readonly unlock = (users: IApiUser[]) => {
        this.setState(
            { isUnlocking: true },
            () => {
                this.context.connection.callApi(
                    'UnlockUsers',
                    { userGuids: users.map(u => u.ItemGUID) },
                    this.dismissActionAndReload
                );
            }
        );
    };

    private readonly editUser = (userItem: IApiUser) => {
        this.props.history.push(`${RouteConfig.users.edit.path}/${userItem.ItemGUID}`);
    };

    private readonly getUserCategories = (allGroupsData: IApiGroup[], userGroupRelations: IApiBoundRelation[]) => {
        const groupsDataMap: Record<string, IApiGroup> = {};
        allGroupsData.forEach(group => {
            groupsDataMap[group.ItemGUID] = group;
        });

        const userCategories: IApiGroup[] = [];
        userGroupRelations.forEach(rel => {
            const relGroup = groupsDataMap[rel.ForeignItemGUID];
            if (relGroup && relGroup.IsCategory) {
                userCategories.push(relGroup);
            }
        });

        return userCategories;
    };

    private readonly getEditedUserData = async (successCb: (result: [TApiItemWithReleations<IApiUser>, IApiGroup[]]) => void) => {
        const matchEdit: IRouterMatch<{ guid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.users.edit.pathWithGuid });
        const editedUserGuid = matchEdit?.params?.guid;
        if (editedUserGuid && !this.state.loadingEditedUserItemGuid) {
            await ReactHelper.setState(this, { loadingEditedUserItemGuid: editedUserGuid });
            const usersRes = await this.context.connection.askApi<IApiDataResponse<TApiItemWithReleations<IApiUser>>>('GetUsersByItemGuids', {
                itemGuids: [editedUserGuid],
                includeForeignKeys: true,
                includeRelations: true,
                relationsFilter: {
                    RelationType: RelationTypes.group,
                    ForeignFolderName: FolderNames.groups,
                },
            });

            const userCategories = this.getUserCategories(this.state.groupsData, usersRes.Data[0].Relations);
            await ReactHelper.setState(this, { loadingEditedUserItemGuid: null });
            successCb([usersRes.Data[0], userCategories]);
        }
    };

    private readonly getMandatoryGroupsForUser = (user: TApiItemWithReleations<IApiUser>): string[] => {
        if (!this.state.administratorGroupGuid)
            throw new Error('The administrator group was not loaded yet.');

        // Do not allow deselection of admin rights of current user
        if (
            StringHelper.compareIgnoringCase(user.Username, Usernames.admin) === 0 ||
            (user.Relations.some((rel) => rel.ForeignItemGUID === this.state.administratorGroupGuid) && user.ItemGUID === this.context.connection.state.session!.userGuid)
        ) {
            return [this.state.administratorGroupGuid];
        }

        return [];
    };

    private readonly dismissAction = (callback?: () => Promise<void>) => {
        this.setState(
            {
                isAssigningToGroup: false,
                isResetingPassword: false,
                isForceChangingPassword: false,
                isDelegating: false,
                isDeactivating: false,
                isActivating: false,
                isUnlocking: false,
                loadingEditedUserItemGuid: null,
            },
            () => {
                this.runNextPhase();
                if (callback) {
                    void callback();
                }
            }
        );
    };

    private readonly dismissActionAndReload = () => {
        this.dismissAction(this.reload);
    };

    private readonly deselect = () => {
        this.setState({ selection: [] });
    };

    componentDidMount() {
        void this.reload();
    }

    private readonly getDisplayedData = () => {
        if (!this.state.data)
            return [];

        return this.state.data.filter((item) => {
            return (this.state.showInactiveUsers || item.IsActive) && (this.state.showSystemUsers || !item.IsSystem);
        });
    };

    private readonly getAvailableSupervisors = (includedGuid?: string | null, excludedUsers?: IApiUser[]) => {
        if (!this.state.data)
            return [];

        return this.state.data.filter((item) => {
            if (excludedUsers && excludedUsers.map(u => u.ItemGUID).includes(item.ItemGUID)) {
                return false;
            }

            return (item.IsActive && !item.IsSystem) || item.ItemGUID === includedGuid;
        });
    };

    private readonly getForbiddenUsernames = (excludedUserName?: string): string[] => {
        if (!this.state.data)
            return [];

        return this.state.data.filter(item => !excludedUserName || StringHelper.compareIgnoringCase(item.Username, excludedUserName) !== 0).map((item) => item.Username.toLowerCase());
    };

    private readonly getForbiddenEmails = (excludedUserName?: string) => {
        if (!this.state.data) {
            return [];
        }

        return this.state.data
            .filter((item) => (!excludedUserName || StringHelper.compareIgnoringCase(item.Username, excludedUserName) !== 0) && !!item.Email1Address)
            .map((item) => item.Email1Address!.toLowerCase());
    };

    private readonly getFreeLicenseSlotsCount = () => {
        if (!this.state.licenseBundles)
            return 0;

        let count = 0;
        for (let i = 0; i < this.state.licenseBundles.length; i++) {
            if (this.state.licenseBundles[i].FreeSlotsCount > 0) {
                count += this.state.licenseBundles[i].FreeSlotsCount;
            }
        }
        return count;
    };

    private readonly getAvailableGroups = (notRealtedToAll: TApiItemWithReleations<IApiUser>[], hideSystemHealthNotificationGroup?: boolean): IApiGroup[] => {
        if (!this.state.groupsData)
            return [];

        return this.state.groupsData.filter(grp =>
            !grp.IsCategory
            && !grp.IsOutlookCategory
            && (notRealtedToAll.length === 0 || !notRealtedToAll.every(usr => usr.Relations.findIndex(rel => rel.ForeignItemGUID === grp.ItemGUID && rel.RelationType === RelationTypes.group) !== -1))
            && (!hideSystemHealthNotificationGroup || grp.GroupName !== GroupNames.systemHealthNotification)
        );
    };

    private readonly getSelectedUsers = (): TApiItemWithReleations<IApiUser>[] => {
        if (!this.state.data)
            return [];

        return this.state.data.filter((item) => {
            return this.state.selection.includes(item.ItemGUID);
        });
    };

    private readonly getPasswordBtnDisabledTooltipContent = () => {
        const authSettings = this.context.authSettings;
        if (this.state.selection.length === 1 && (authSettings.isActiveDirectory || authSettings.isAzureAuth)) {
            return myStrings.cannotResetPasswordWithAD;
        }

        return undefined;
    };

    private readonly getGroupGuid = (groupName: string | null): string | null => {
        if (!this.state.groupsData || !this.state.hiddenSystemGroups)
            return null;

        if (!groupName)
            return null;

        const hiddenIndex = this.state.hiddenSystemGroups.findIndex((item) => item.GroupName === groupName);
        if (hiddenIndex !== -1)
            return this.state.hiddenSystemGroups[hiddenIndex].ItemGUID;

        const index = this.state.groupsData.findIndex((item) => item.GroupName === groupName);
        if (index === -1)
            return null;

        return this.state.groupsData[index].ItemGUID;
    };

    private readonly getRouteMatchParams = () => {
        const matchNew: IRouterMatch<{ count: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.users.new.pathWithCount });
        const matchEdit: IRouterMatch<{ guid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.users.edit.pathWithGuid });
        const matchEffectivePermissions: IRouterMatch<{ guid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.users.effectivePermissions.pathWithGuid });
        const addNewUsersCount = Number(matchNew?.params?.count) || 0;
        const editedUserGuid = matchEdit?.params?.guid;
        const effectivePermissionsUserGuid = matchEffectivePermissions?.params?.guid;

        let isRouteUserGuidValid = false;
        if (editedUserGuid) {
            isRouteUserGuidValid = uuidValidate(editedUserGuid);
        } else if (effectivePermissionsUserGuid) {
            isRouteUserGuidValid = uuidValidate(effectivePermissionsUserGuid);
        }

        return {
            addNewUsersCount,
            editedUserGuid,
            isRouteUserGuidValid,
            effectivePermissionsUserGuid,
        };
    };

    private readonly arePasswordBtnsDisabled = () => {
        const authStettings = this.context.authSettings;
        return this.state.selection.length === 0 || authStettings.isActiveDirectory || authStettings.isAzureAuth;
    };

    render() {

        const selectedUsers = this.getSelectedUsers();
        const { addNewUsersCount, editedUserGuid, isRouteUserGuidValid, effectivePermissionsUserGuid } = this.getRouteMatchParams();
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        const isModuleAndFieldPermissionsLocked = isModulePermissionsLocked && isFieldPermissionsLocked;

        return (
            <div className="container-fluid d-flex flex-column min-h-100">
                <div className="row">
                    <div className="col p-0">
                        <div>
                            <h1>{myStrings.title}</h1>
                            <p className="text-medium">{myStrings.subtitle}</p>
                        </div>
                        <hr className="mb-1 mt-4" />
                        {this.state.areDataLoaded && this.state.licenseBundles &&
                            <div className="d-block">
                                <Switch>
                                    <Route path={RouteConfig.users.new.path}>
                                        {addNewUsersCount > 0 && (
                                            <NewUserWizard
                                                key={addNewUsersCount}
                                                onDismiss={this.dismissAction}
                                                numberOfJumps={addNewUsersCount}
                                                availableSupervisors={this.getAvailableSupervisors()}
                                                availableLicenses={this.state.licenseBundles}
                                                availableGroups={this.getAvailableGroups([])}
                                                allGroups={this.state.groupsData}
                                                initialUserCategories={[]}
                                                hiddenGroups={this.state.hiddenSystemGroups!.map((x) => x.ItemGUID)}
                                                exchangeRatesAdminGroupGuid={this.getGroupGuid(this.state.exchangeRatesAdminGroup)}
                                                sendServerUpdateStatusGroupGuid={this.getGroupGuid(this.state.serverUpdateProgressNotificationGroup)}
                                                sendSystemHealthNotificationsGroupGuid={this.getGroupGuid(this.state.systemHealthNotificationGroup)}
                                                isSystemHealthNotificationDefaultGroupInGs={this.state.systemHealthNotificationGroup === GroupNames.systemHealthNotification}
                                                everyoneGroupGuid={this.getGroupGuid(GroupNames.everyone)!}
                                                minimumPasswordLength={this.state.minimumPasswordLength}
                                                forbiddenUsernames={this.getForbiddenUsernames()}
                                                forbiddenEmails={this.getForbiddenEmails()}
                                                onDone={this.dismissActionAndReload}
                                                licenseRestrictionsHelper={this.context.licenseRestrictionsHelper}
                                                myColumnPermissionsMap={this.state.myColumnPermissionsMap}
                                            />
                                        )}
                                    </Route>
                                    <Route path={RouteConfig.users.licences.path}>
                                        <LicenseMatrixWizard
                                            onDismiss={this.dismissAction}
                                            users={this.state.data.filter((user) => !user.IsSystem)}
                                            availableLicenses={this.state.licenseBundles}
                                            onDone={this.dismissActionAndReload}
                                        />
                                    </Route>
                                    <Route path={RouteConfig.users.edit.pathWithGuid}>
                                        {!isRouteUserGuidValid ? (
                                            <Redirect to={RouteConfig.users.path} />
                                        ) : (
                                            <LoaderProvider
                                                key={editedUserGuid}
                                                loadData={this.getEditedUserData}
                                                render={([editedUserItem, userCategories]) => (
                                                    <EditUserWizard
                                                        onDismiss={this.dismissAction}
                                                        userItem={editedUserItem}
                                                        availableSupervisors={this.getAvailableSupervisors(editedUserItem.Users_SupervisorGuid)}
                                                        availableLicenses={this.state.licenseBundles!}
                                                        allGroups={this.state.groupsData}
                                                        initialUserCategories={userCategories}
                                                        availableGroups={this.getAvailableGroups([])}
                                                        hiddenGroups={this.state.hiddenSystemGroups!.map((hg) => hg.ItemGUID)}
                                                        mandatoryGroups={this.getMandatoryGroupsForUser(editedUserItem)}
                                                        exchangeRatesAdminGroupGuid={this.getGroupGuid(this.state.exchangeRatesAdminGroup)}
                                                        sendServerUpdateStatusGroupGuid={this.getGroupGuid(this.state.serverUpdateProgressNotificationGroup)}
                                                        sendSystemHealthNotificationsGroupGuid={this.getGroupGuid(this.state.systemHealthNotificationGroup)}
                                                        isSystemHealthNotificationDefaultGroupInGs={this.state.systemHealthNotificationGroup === GroupNames.systemHealthNotification}
                                                        everyoneGroupGuid={this.getGroupGuid(GroupNames.everyone)!}
                                                        onDone={this.dismissActionAndReload}
                                                        forbiddenUsernames={this.getForbiddenUsernames(editedUserItem.Username)}
                                                        forbiddenEmails={this.getForbiddenEmails(editedUserItem.Username)}
                                                        myColumnPermissionsMap={this.state.myColumnPermissionsMap}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Route>
                                    <Route path={RouteConfig.users.effectivePermissions.pathWithGuid}>
                                        {!isRouteUserGuidValid || !effectivePermissionsUserGuid || isModuleAndFieldPermissionsLocked ? (
                                            <Redirect to={RouteConfig.users.path} />
                                        ) : (
                                            <EffectivePermissionsWizard
                                                key={effectivePermissionsUserGuid}
                                                selectedUserGuid={effectivePermissionsUserGuid}
                                                onDismiss={this.dismissAction}
                                            />
                                        )}
                                    </Route>
                                </Switch>
                                {this.state.isAssigningToGroup &&
                                    <AssignToGroupsWizard
                                        onDismiss={this.dismissAction}
                                        selectedUsers={selectedUsers}
                                        availableGroups={this.getAvailableGroups(selectedUsers, true)}
                                        onDone={this.dismissActionAndReload}
                                    />
                                }
                                {this.state.isResetingPassword &&
                                    <ResetPasswordWizard
                                        onDismiss={this.dismissAction}
                                        selectedUsers={selectedUsers}
                                        minimumPasswordLength={this.state.minimumPasswordLength}
                                        onDone={this.dismissActionAndReload}
                                    />
                                }
                                {this.state.isForceChangingPassword &&
                                    <ForceChangePasswordWizard
                                        onDismiss={this.dismissAction}
                                        selectedUsers={selectedUsers}
                                        onDone={this.dismissActionAndReload}
                                    />
                                }
                                {(this.state.isDeactivating || this.state.isDelegating) &&
                                    <DeactivateWizard
                                        onDismiss={this.dismissAction}
                                        delegateOnly={!this.state.isDeactivating}
                                        selectedUsers={this.state.isDeactivating ? selectedUsers.filter((usr) => !usr.IsSystem) : selectedUsers}
                                        availableSubstituteUsers={this.getAvailableSupervisors(undefined, selectedUsers)}
                                        onDone={this.dismissActionAndReload}
                                    />
                                }
                                {this.state.isActivating &&
                                    <ActivateWizard
                                        onDismiss={this.dismissAction}
                                        selectedUsers={selectedUsers.filter((usr) => !usr.IsActive)}
                                        availableLicenses={this.state.licenseBundles}
                                        onDone={this.dismissActionAndReload}
                                    />
                                }
                                {(this.state.isUnlocking) &&
                                    <SpinnerModal variant={SpinnerVariant.linear} />
                                }
                                <div className="container max-w-none mx-0 mb-3 p-0">
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <ButtonToolbar>
                                                <Link to={`${RouteConfig.users.new.path}/1`}>
                                                    <Button variant="outline-secondary" >
                                                        <i className="mdl2 mdl2-add-friend" aria-hidden="true" /> {myStrings.addUser}
                                                    </Button>
                                                </Link>
                                                <Link to={RouteConfig.users.licences.path}>
                                                    <Button variant="outline-secondary" >
                                                        <i className="mdl2 mdl2-other-user" aria-hidden="true" /> {myStrings.reassignLicenses}
                                                    </Button>
                                                </Link>
                                                <Button variant="outline-secondary" onClick={() => void this.reload()}>
                                                    <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                                </Button>
                                                <TooltipHost content={this.getPasswordBtnDisabledTooltipContent()}>
                                                    <FakeDisabledButton variant="outline-secondary" onClick={this.resetPassword} disabled={this.arePasswordBtnsDisabled()}>
                                                        <i className="mdl2 mdl2-permissions" aria-hidden="true" /> {myStrings.resetPassword}
                                                    </FakeDisabledButton>
                                                </TooltipHost>
                                                <TooltipHost content={this.getPasswordBtnDisabledTooltipContent()}>
                                                    <FakeDisabledButton variant="outline-secondary" onClick={this.forceChangePassword} disabled={this.arePasswordBtnsDisabled()}>
                                                        <i className="mdl2 mdl2-admin" aria-hidden="true" /> {myStrings.forceChangePassword}
                                                    </FakeDisabledButton>
                                                </TooltipHost>
                                                <Button variant="outline-secondary" onClick={this.assignToGroup} disabled={this.state.selection.length === 0}>
                                                    <i className="mdl2 mdl2-people" aria-hidden="true" /> {myStrings.assignToGroup}
                                                </Button>
                                                <Button variant="outline-secondary" onClick={this.delegate} disabled={this.state.selection.length === 0}>
                                                    <i className="mdl2 mdl2-forward" aria-hidden="true" /> {myStrings.delegate}
                                                </Button>
                                                <TooltipHost content={isModuleAndFieldPermissionsLocked ? <LicenseRestrictionsTooltipContent licenseRestriction={modulePermissionsLicenseRestriction} /> : undefined}>
                                                    <Link to={`${RouteConfig.users.effectivePermissions.path}/${this.state.selection[0]}`}>
                                                        <FakeDisabledButton variant="outline-secondary" disabled={isModuleAndFieldPermissionsLocked || this.state.selection.length !== 1}>
                                                            <FontIcon iconName="UserOptional" /> {myStrings.effectivePermissions}
                                                        </FakeDisabledButton>
                                                    </Link>
                                                </TooltipHost>
                                                {(this.state.selection.length === 0 || !selectedUsers.every((usr) => !usr.IsActive)) &&
                                                    <Button
                                                        variant="outline-secondary"
                                                        onClick={this.deactivate}
                                                        disabled={
                                                            this.state.selection.length === 0 ||
                                                            !selectedUsers.every((usr) => usr.IsActive) ||
                                                            selectedUsers.some((usr) => usr.ItemGUID === this.context.connection.state.session!.userGuid) ||
                                                            selectedUsers.findIndex((usr) => usr.IsSystem) !== -1
                                                        }
                                                    >
                                                        <i className="mdl2 mdl2-block-contact" aria-hidden="true" /> {myStrings.deactivate}
                                                    </Button>
                                                }
                                                {(this.state.selection.length > 0 && selectedUsers.every((usr) => !usr.IsActive)) &&
                                                    <Button variant="outline-secondary" onClick={this.activate}>
                                                        <i className="mdl2 mdl2-play" aria-hidden="true" /> {myStrings.activate}
                                                    </Button>
                                                }
                                                {(this.state.selection.length > 0 && selectedUsers.some((usr) => usr.Server_IsAccountLocked)) &&
                                                    <Button variant="outline-secondary" onClick={() => this.unlock(selectedUsers)} disabled={!selectedUsers.every((usr) => usr.Server_IsAccountLocked)}>
                                                        <FontIcon iconName="Unlock" /> {myStrings.unlock}
                                                    </Button>
                                                }
                                            </ButtonToolbar>
                                        </div>
                                        <div className="col-auto">
                                            <Button variant="outline-secondary" onClick={this.deselect} hidden={this.state.selection.length === 0}>
                                                {Strings.formatString(Strings.selectedFormat, this.state.selection.length)} <i className="mdl2 mdl2-cancel" aria-hidden="true" />
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Form.Control type="text" placeholder={Strings.search} value={this.state.searchedString} onChange={(e) => this.setState({ searchedString: e.target.value })} />
                                        </div>
                                        <div className="col-auto">
                                            <FilterDropdown>
                                                <FilterDropdown.Switch checked={this.state.showInactiveUsers} id="showInactiveUsers" label={myStrings.showInactiveUsers} onChange={(v) => { Cookies.setCookie(Cookies.names.users, { showInactiveUsers: v }, 365 * 24); this.setState({ showInactiveUsers: v }); }} />
                                                <FilterDropdown.Switch checked={this.state.showSystemUsers} id="showSystemUsers" label={myStrings.showSystemUsers} onChange={(v) => { Cookies.setCookie(Cookies.names.users, { showSystemUsers: v }, 365 * 24); this.setState({ showSystemUsers: v }); }} />
                                            </FilterDropdown>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="row flex-fill d-flex justify-content-start mt-2">
                    {this.state.areDataLoaded ?
                        <div className="col p-0">
                            <UsersGrid
                                variant="full"
                                data={this.getDisplayedData()}
                                searchedString={this.state.searchedString}
                                onSearchStringChange={(searchedString) => this.setState({ searchedString: searchedString })}
                                selection={this.state.selection}
                                onSelectionChange={(sel) => this.setState({ selection: sel as string[] })}
                                loadingEdittedUserItemGuid={this.state.loadingEditedUserItemGuid}
                                onEditUser={this.editUser}
                                visibleGroups={this.getAvailableGroups([])}
                            />
                        </div>
                        :
                        <div className="col p-0 align-self-center text-center">
                            <Spinner variant={SpinnerVariant.ease} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}