import { DefaultButton, Text, mergeStyleSets, mergeStyles } from '@fluentui/react';
import * as React from 'react';
import StoreV2Context from '../../StoreV2Context';
import StoreHelper from '../../../StoreHelper';
import type { TPeriodType } from '../../../../../../data/shopping/ISubscriptionsShoppingModel';
import Strings from '../../../../../../strings';
import { Button, Dialog, DialogActions, DialogBody, DialogContent, DialogSurface, DialogTitle, DialogTrigger } from '@fluentui/react-components';

type TPeriodToggleProps = {
    className?: string;
    onToggleChange?: () => void;
    disabled?: boolean;
};

const css = mergeStyleSets({
    notLastButton: {
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        borderRightWidth: 0
    },
    notFirstButton: {
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0
    },
    commitment: {
        fontWeight: 500
    }
});

const periodsSorter = (a: TPeriodType, b: TPeriodType) => {
    const getRank = (p: TPeriodType) => {
        switch (p) {
            case 'FixedPrice':
                return 1;
            case 'Monthly':
                return 2;
            case 'Quarterly':
                return 3;
            case 'SemiAnnually':
                return 4;
            case 'Yearly':
                return 5;
            case 'Triennially':
                return 6;
            default:
                throw new Error(`Unknown period type '${p as unknown as string}'.`);
        }
    };

    return getRank(a) - getRank(b);
};

const PeriodToggle: React.FC<TPeriodToggleProps> = ({ className, onToggleChange, disabled }) => {
    const { selectedPeriodType, setSelectedPeriodType, storeData, currentPlan } = React.useContext(StoreV2Context);
    const [invoicingWarnedPeriodType, setInvoicingWarnedPeriodType] = React.useState<TPeriodType | null>(null);

    // If there are no options to choose from, render nothing.
    if (storeData.AvailablePeriods.length < 2) {
        return null;
    }

    const pickPeriod = (period: TPeriodType, suppressWarning?: boolean) => {
        if (period === selectedPeriodType)
            return;

        if (currentPlan?.IsPaymentGateSubscription && storeData.InvoicingOnlyPeriods?.includes(period) && !suppressWarning) {
            setInvoicingWarnedPeriodType(period);
            return;
        }

        setSelectedPeriodType(period);
        onToggleChange && onToggleChange();
    };

    const periods = [...storeData.AvailablePeriods].sort(periodsSorter);

    return (
        <>
            <div className={className}>
                <div className="d-flex flex-row align-items-baseline">
                    <Text variant="medium" className={mergeStyles('mr-3', css.commitment)}>{Strings.components.routes.subscriptions.commitment}</Text>
                    {periods.map((availablePeriod, i, arr) =>
                        <DefaultButton
                            key={`avper-${i}-${availablePeriod}`}
                            text={StoreHelper.getPeriodStrings(availablePeriod).commitment}
                            checked={availablePeriod === selectedPeriodType}
                            onClick={() => pickPeriod(availablePeriod)}
                            disabled={disabled}
                            className={mergeStyles(i !== 0 && css.notFirstButton, i !== arr.length - 1 && css.notLastButton)}
                        />
                    )}
                </div>
            </div>
            {(!!invoicingWarnedPeriodType) &&
                <Dialog open={true} onOpenChange={(_ev, data) => !data.open && setInvoicingWarnedPeriodType(null)}>
                    <DialogSurface>
                        <DialogBody>
                            <DialogTitle>
                                {Strings.components.routes.subscriptions.changePeriodToInvoicingDialog.title}
                            </DialogTitle>
                            <DialogContent>
                                {Strings.components.routes.subscriptions.changePeriodToInvoicingDialog.message}
                            </DialogContent>
                            <DialogActions>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="primary" onClick={() => pickPeriod(invoicingWarnedPeriodType, true)}>{Strings.yes}</Button>
                                </DialogTrigger>
                                <DialogTrigger disableButtonEnhancement>
                                    <Button appearance="secondary">{Strings.no}</Button>
                                </DialogTrigger>
                            </DialogActions>
                        </DialogBody>
                    </DialogSurface>
                </Dialog>
            }
        </>
    );
};

export default PeriodToggle;
