import type { Column } from '@devexpress/dx-react-grid';
import type { IContextualMenuProps } from '@fluentui/react';
import { CommandButton, ContextualMenuItemType } from '@fluentui/react';
import React, { useMemo } from 'react';
import Strings from '../../../strings';
import EnumValuesColumns from './EnumValuesColumns';

const myStrings = Strings.components.routes.fields.dropDowns;

const ADD_ICON = { iconName: 'Add' };
const BUTTON_STYLES = { height: 'inherit', fontSize: 'inherit', paddingTop: '0.75rem', paddingBottom: '0.75rem' };
const DROPDOWN_DIVIDER = { key: 'divider_1', itemType: ContextualMenuItemType.Divider };

type TAddTranslationsDropdown = {
    displayLanguageColumns: string[];
    gridColumns: Column[];
    onToggleDislayLanguageColumn: (lang: string) => Promise<void>;
};

const AddTranslationColumnsDropdown: React.FC<TAddTranslationsDropdown> = ({ displayLanguageColumns, gridColumns, onToggleDislayLanguageColumn }) => {
    const menuProps: IContextualMenuProps = useMemo(
        () => ({
            items: EnumValuesColumns.LANGUAGE_COLUMNS.filter((lang) => !displayLanguageColumns.includes(lang)).flatMap((lang) => {
                const item = {
                    key: lang,
                    text: gridColumns.find((col) => col.name === lang)?.title,
                    onClick: () => {
                        void onToggleDislayLanguageColumn(lang);
                    },
                };
                if (lang === EnumValuesColumns.EDIT_GRID_COLS.technicalName) {
                    return [DROPDOWN_DIVIDER, item];
                }
                return item;
            }),
        }),
        [displayLanguageColumns, gridColumns, onToggleDislayLanguageColumn]
    );

    return (
        <CommandButton iconProps={ADD_ICON} menuProps={menuProps} style={BUTTON_STYLES}>
            {myStrings.addColumn}
        </CommandButton>
    );
};

export default AddTranslationColumnsDropdown;
