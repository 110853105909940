import type { IApiGroup } from '@eway-crm/connector';
import { FolderNames } from '@eway-crm/connector';
import React, { useContext } from 'react';
import { ItemPreviewImage } from '@eway-crm/gui';
import { ConnectionContext } from '../../../providers/ConnectionProvider';

type TPartialApiGroup = Pick<IApiGroup, 'GroupName' | 'ItemGUID'>;

type TGroupAvatarProps = {
    size: React.ComponentProps<typeof ItemPreviewImage>['size'];
    item: TPartialApiGroup;
};

const GroupAvatar: React.FC<TGroupAvatarProps> = ({ size, item }) => {
    const { connection } = useContext(ConnectionContext);
    return <ItemPreviewImage apiConnection={connection.getApiConnection()} authMode="header" folderName={FolderNames.groups} itemGuid={item.ItemGUID} userData={item} size={size} />;
};

export default GroupAvatar;