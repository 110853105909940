import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button } from 'react-bootstrap';
import FolderNames from '../../../data/constants/FolderNames';
import RelationTypes from '../../../data/constants/RelationTypes';
import WizardModal from '../../WizardModal';
import { UserWizardBase } from './UserWizardBase';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiGroup } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import { DelayedRender } from '@fluentui/react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

const dialogBodyExpectedHeight = '40rem';

type TAssignToGroupsWizardProps = {
    onDismiss: () => void;
    selectedUsers: IApiUser[];
    availableGroups: IApiGroup[];
    onDone: () => void;
};

type TAssignToGroupsWizardState = {
    groupsSearchedString: string;
    groupsSelection: string[];
    isSavingInProgress: boolean;
};

export class AssignToGroupsWizard extends React.Component<TAssignToGroupsWizardProps, TAssignToGroupsWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TAssignToGroupsWizardProps) {
        super(props);
        this.state = {
            groupsSearchedString: '',
            groupsSelection: [],
            isSavingInProgress: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                if (this.state.groupsSelection.length === 0 || this.props.selectedUsers.length === 0) {
                    this.props.onDone();
                } else {
                    const groupRelations = this.props.selectedUsers.flatMap((user) => this.state.groupsSelection.map((groupGuid) => {
                        return {
                            ItemGUID1: groupGuid,
                            ItemGUID2: user.ItemGUID,
                            FolderName1: FolderNames.groups,
                            FolderName2: FolderNames.users,
                            RelationType: RelationTypes.group,
                            DifferDirection: false
                        };
                    }));

                    this.context.connection.callApi(
                        'SaveRelations',
                        {
                            transmitObjects: groupRelations
                        },
                        (_) => {
                            this.props.onDone();
                        }
                    );
                }
            }
        );
    };

    render() {
        const myStrings = Strings.components.routes.users;

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            return (
                <WizardModal show={true} size="xl" onHide={this.dismiss} dialogBodyExpectedHeight={dialogBodyExpectedHeight}>
                    <WizardModal.Body>
                        <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                            <div className="row">
                                <div className="col mb-4">
                                    <h1>{myStrings.assignToGroup}</h1>
                                    <div>
                                        {this.props.selectedUsers.map((user, i) =>
                                            <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                                {user.FileAs}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Form.Control type="text" placeholder={Strings.search} value={this.state.groupsSearchedString} onChange={(e) => this.setState({ groupsSearchedString: e.target.value })} />
                                </div>
                            </div>
                            <div className="row flex-fill d-flex justify-content-start">
                                <div className="col">
                                    <DelayedRender>
                                        <UserWizardBase.GroupsPicker
                                            showHeading={false}
                                            showSearch={false}
                                            availableGroups={this.props.availableGroups}
                                            groupsSearchedString={this.state.groupsSearchedString}
                                            onGroupsSearchedStringChange={(val) => this.setState({ groupsSearchedString: val })}
                                            groupsSelection={this.state.groupsSelection}
                                            onGroupsSelectionChange={(sel) => this.setState({ groupsSelection: sel })}
                                        />
                                    </DelayedRender>
                                </div>
                            </div>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" disabled={this.state.groupsSelection.length === 0} onClick={this.submit}>{myStrings.assign}</Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}