import * as React from 'react';
import { FontIcon } from '@fluentui/react';
import type { ISettingsPanelButtonProps } from './SettingsPanel';
import { SettingsPanel } from './SettingsPanel';
import Strings, { handleLangChange } from '../../../strings';
import { Form } from 'react-bootstrap';
import { Col } from 'react-bootstrap';
import type { TFormDropdownOption } from '../../shared/forms/FormDropdown';
import { FormDropdown } from '../../shared/forms/FormDropdown';

type TSettingsProps = ISettingsPanelButtonProps;

export class Settings extends React.Component<TSettingsProps> {

    private readonly langOptions: TFormDropdownOption[] = [
        { title: Strings.components.connection.english, value: 'en' },
        { title: Strings.components.connection.czech, value: 'cs' }
    ];

    constructor(props: TSettingsProps) {
        super(props);
        this.state = {
            isPanelShown: false
        };
    }

    render() {
        const myStrings = Strings.components.connection;

        return (
            <>
                <button className="nav-link m-0 p-0 btn btn-link" onClick={this.props.togglePanel}>
                    <div style={{ width: '32px', height: '32px' }}>
                        <FontIcon iconName="Settings" style={{ fontSize: '20px', lineHeight: '28px', verticalAlign: 'middle', color: 'white' }} />
                    </div>
                </button>
                {
                    (this.props.isPanelShown) &&
                    <SettingsPanel title={myStrings.settings} onHide={this.props.hidePanel}>
                        <Form className="form-application pt-3 pb-0 px-3 bg-white shadow-sm">
                            <Form.Row>
                                <Form.Group as={Col} controlId="settingsLang">
                                    <Form.Label>{myStrings.language}</Form.Label>
                                    <FormDropdown
                                        value={Strings.getLanguage()}
                                        onValueChange={handleLangChange}
                                        options={this.langOptions}
                                    />
                                </Form.Group>
                            </Form.Row>
                        </Form>
                    </SettingsPanel>
                }
            </>
        );
    }
}