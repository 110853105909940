import * as React from 'react';
import Strings from '../../../strings';
import { Spinner, Modal, Button } from 'react-bootstrap';
import type { IPaymentMethod } from '../../../data/shopping/IPaymentMethodsModel';
import type { ITokenizedApiResult } from '@eway-crm/connector';

type TDeleteLinkProps<TPaymentMethod extends IPaymentMethod> = {
    paymentMethod: TPaymentMethod;
    deleteFunc: (paymentMethod: TPaymentMethod) => Promise<ITokenizedApiResult>;
    onDeleted: () => void;
};

export const DeleteLink = <TPaymentMethod extends IPaymentMethod,>(props: React.PropsWithChildren<TDeleteLinkProps<TPaymentMethod>>): React.ReactElement => {
    const [isConfimationDialogShown, setIsConfimationDialogShown] = React.useState<boolean>(false);
    const [isCallInitiated, setIsCallInitiated] = React.useState<boolean>(false);
    const { paymentMethod, deleteFunc, onDeleted } = props;

    const confirm = React.useCallback(() => {
        setIsConfimationDialogShown(true);
        setIsCallInitiated(false);
    }, [setIsConfimationDialogShown, setIsCallInitiated]);

    const cancel = React.useCallback(() => {
        setIsConfimationDialogShown(false);
        setIsCallInitiated(false);
    }, [setIsConfimationDialogShown, setIsCallInitiated]);

    const doDelete = React.useCallback(() => {
        setIsConfimationDialogShown(false);
        setIsCallInitiated(true);
    }, [setIsConfimationDialogShown, setIsCallInitiated]);

    React.useEffect(() => {
        if (!isCallInitiated)
            return;

        (async () => {
            await deleteFunc(paymentMethod);
            onDeleted();
        })()
            .catch((err) => console.error('Unable to invoke delete func.', err));
    }, [isCallInitiated, deleteFunc, paymentMethod, onDeleted]);

    const myStrings = Strings.components.routes.billingDetails;
    return (
        <>
            {isCallInitiated ?
                <Spinner animation="border" size="sm" />
                :
                <Button variant="link" onClick={confirm}>{myStrings.delete}</Button>
            }

            <Modal show={isConfimationDialogShown} onHide={cancel} centered>
                <Modal.Header closeButton><Modal.Title>{myStrings.deletePaymentMethod}</Modal.Title></Modal.Header>
                <Modal.Body>
                    {Strings.formatString(myStrings.areYouSureToDelete, paymentMethod.Title || '')}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={cancel}>
                        {Strings.no}
                    </Button>
                    <Button variant="danger" onClick={doDelete}>
                        {myStrings.delete}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};