import * as React from 'react';
import type { IApiResult } from "@eway-crm/connector";
import { SpinnerModal } from '../../shared/SpinnerModal';
import { Button, Modal } from 'react-bootstrap';
import { Dialog, DialogFooter, FontIcon } from '@fluentui/react';
import Strings from '../../../strings';
import WizardModal from '../../WizardModal';
import { UpdateStatusMessageType } from '../../../data/constants/UpdateStatusMessageType';
import type { IServerUpdaterAdditionalData } from '../../../data/wcf/IServerUpdaterAdditionalData';
import { NumberHelper } from '../../../helpers/NumberHelper';
import { ErrorMessage } from '@eway-crm/gui';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.updates;
const iframeName = 'versionChangelogIframeInModal';

enum Status {
    waitingForPreCheckStatus,
    errorPreCheckStatus,
    waitingForApprovingTheUpdate,
    errorApproving,
    waitingForResultFile,
    havingResultFileMessageType
}

type TErrorDialogProps = React.PropsWithChildren & {
    title: string;
    onOk: () => void;
    width?: '500px' | '90vw';
};

const ErrorDialog: React.FunctionComponent<TErrorDialogProps> = (props) => {
    return (
        <Dialog modalProps={{ isBlocking: true }} dialogContentProps={{ showCloseButton: false, title: props.title }} hidden={false} minWidth={props.width || '500px'}>
            {props.children}
            <DialogFooter>
                <Button variant="primary" onClick={props.onOk}>{Strings.ok}</Button>
            </DialogFooter>
        </Dialog>
    );
};

type TResultDialogProps = React.PropsWithChildren & {
    title: string;
    icon: 'success' | 'warning' | 'error';
    techData: string[] | null;
    onOk: () => void;
};

const ResultDialog: React.FunctionComponent<TResultDialogProps> = (props) => {
    return (
        <ErrorDialog title={props.title} onOk={props.onOk}>
            <div className="container max-w-none">
                <ErrorMessage icon={props.icon} techData={props.techData} customStrings={{ seeMore: myStrings.showTechInfo, seeLess: myStrings.hideTechInfo }}>
                    {props.children}
                </ErrorMessage>
            </div>
        </ErrorDialog>
    );
};

const PreCheckErrorDialog: React.FunctionComponent<{ status: Status; responseDescription: string | null; onOk: () => void }> = (props) => {
    let title;
    let text;

    switch (props.status) {
        case Status.errorPreCheckStatus:
        case Status.errorApproving:
            title = myStrings.preCheckFailedTitle;
            if (props.responseDescription) {
                text = (
                    <>
                        <p>{myStrings.preCheckFailedMessageIntro}</p>
                        <div><ul><li>{props.responseDescription}</li></ul></div>
                        <p>{myStrings.preCheckFailedMessageOutro}</p>
                    </>
                );
            } else {
                text = (<p>{myStrings.preCheckFailedMessageFallback}</p>);
            }
            break;

        default:
            throw new Error(`Unknown error status '${props.status}'.`);
    }

    return (
        <ResultDialog title={title} icon="warning" techData={null} onOk={props.onOk}>
            {text}
        </ResultDialog>
    );
};

type TInstallerWizardProps = {
    isAlreadyApproved: boolean;
    onDone: () => void;
    previousOutlookClientVersion: string;
    outlookClientVersion: string;
    webServiceVersion: string;
    kbLink: string | null;
};

type TInstallerWizardState = {
    status: Status;
    errorResponseDescription: string | null;
    resultFileMessageType: UpdateStatusMessageType | null;
    resultFileOtherData: string[] | null;
    resultFileOtherDataObject: IServerUpdaterAdditionalData | null;
    displayingKb: boolean;
};

export class InstallerWizard extends React.Component<TInstallerWizardProps, TInstallerWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TInstallerWizardProps) {
        super(props);
        this.state = {
            status: props.isAlreadyApproved ? Status.waitingForResultFile : Status.waitingForPreCheckStatus,
            errorResponseDescription: null,
            resultFileMessageType: null,
            resultFileOtherData: null,
            resultFileOtherDataObject: null,
            displayingKb: false
        };
    }

    componentDidMount() {
        if (this.props.isAlreadyApproved) {
            this.scheduleNextCheck();
            return;
        }

        this.context.connection.callApi(
            'CheckStatusWithSession',
            {},
            () => {
                this.setState(
                    { status: Status.waitingForApprovingTheUpdate },
                    () => {
                        this.context.connection.callApi(
                            'ApproveVersionForUpdate',
                            {
                                outlookClientVersion: this.props.outlookClientVersion,
                                webServiceVersion: this.props.webServiceVersion
                            },
                            () => {
                                this.setState({ status: Status.waitingForResultFile }, this.scheduleNextCheck);
                            },
                            (errorResult: IApiResult) => {
                                console.warn(`Updates Installer Wizard: Approwing the version ended up with error: '${errorResult.ReturnCode}': '${errorResult.Description}'.`);
                                this.setState({
                                    status: Status.errorApproving,
                                    errorResponseDescription: errorResult.Description
                                });
                            }
                        );
                    }
                );
            },
            (errorResult: IApiResult) => {
                console.warn(`Updates Installer Wizard: Pre check status ended up with error: '${errorResult.ReturnCode}': '${errorResult.Description}'.`);
                this.setState({
                    status: Status.errorPreCheckStatus,
                    errorResponseDescription: errorResult.Description
                });
            }
        );
    }

    private readonly scheduleNextCheck = () => {
        setTimeout(
            () => {
                this.context.connection.fakeActivity();
                this.context.connection.getUpdateResult(
                    this.handleCheck,
                    this.handleEmptyCheck
                );
            },
            NumberHelper.getRandomInt(4200, 7500)
        );
    };

    private readonly handleEmptyCheck = () => {
        this.setState({ status: Status.waitingForResultFile }, this.scheduleNextCheck);
    };

    private readonly handleCheck = (stage: UpdateStatusMessageType, otherData: string[] | null, otherDataObject: IServerUpdaterAdditionalData | null) => {
        let stillRunning = true;
        switch (stage) {
            case UpdateStatusMessageType.updateBreakedByFatalErrorWithSmallErrors:
            case UpdateStatusMessageType.updateBreakedByFatalErrorWithoutSmallErrors:
            case UpdateStatusMessageType.updateCompletedWithSmallErrors:
            case UpdateStatusMessageType.updateCompletedWithoutSmallErrors:
            case UpdateStatusMessageType.versionHasChangedInMeantime:
            case UpdateStatusMessageType.updateFailedWhileShuttingDown:
            case UpdateStatusMessageType.updateFailedWhileBackingUpDatabase:
                stillRunning = false;
                break;
        }

        this.setState(
            {
                status: Status.havingResultFileMessageType,
                resultFileMessageType: stage,
                resultFileOtherData: otherData,
                resultFileOtherDataObject: otherDataObject
            },
            stillRunning ? this.scheduleNextCheck : undefined
        );
    };

    private readonly showChangelog = () => {
        if (!this.props.kbLink)
            return;

        this.setState({ displayingKb: true });
    };

    render() {
        // Waiting for something.
        if (this.state.status === Status.waitingForPreCheckStatus
            || this.state.status === Status.waitingForApprovingTheUpdate
        ) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        }

        // Error and success dialogs.
        if (this.state.status === Status.errorPreCheckStatus
            || this.state.status === Status.errorApproving
        ) {
            return (
                <PreCheckErrorDialog status={this.state.status} responseDescription={this.state.errorResponseDescription} onOk={this.props.onDone} />
            );
        }
        if (this.state.status === Status.havingResultFileMessageType) {
            if (!this.state.resultFileMessageType)
                throw new Error('No message type from update result file.');
            let title = null;
            let error = null;
            let icon: 'error' | 'warning' | 'success' | null = null;
            let openUpTechData = true;
            switch (this.state.resultFileMessageType) {
                case UpdateStatusMessageType.versionHasChangedInMeantime:
                    title = myStrings.preCheckFailedTitle;
                    error = myStrings.versionChanged;
                    icon = 'warning';
                    break;
                case UpdateStatusMessageType.updateFailedWhileShuttingDown:
                    title = myStrings.failedBeforeStartTitle;
                    error = (
                        <>
                            <p>{myStrings.failedBeforeStartIntro}</p>
                            <div>
                                <ul>
                                    <li>
                                        {(!!(this.state.resultFileOtherDataObject?.UpdateFailedBeforeActualStartData?.WebServicePath) ?
                                            Strings.formatString(myStrings.failedBeforeStartReasonFilesPermissionsFormat, this.state.resultFileOtherDataObject.UpdateFailedBeforeActualStartData.WebServicePath)
                                            :
                                            myStrings.failedBeforeStartReasonFilesPermissionsFallback
                                        )}
                                    </li>
                                </ul>
                            </div>
                            <p>{myStrings.failedBeforeStartOutro1}</p>
                            <p>{Strings.formatString(myStrings.failedBeforeStartOutro2Format, this.props.previousOutlookClientVersion)}</p>
                        </>
                    );
                    break;
                case UpdateStatusMessageType.updateFailedWhileBackingUpDatabase:
                    title = myStrings.failedBeforeStartTitle;
                    icon = 'warning';
                    error = (
                        <>
                            <p>{myStrings.failedBeforeStartIntro}</p>
                            <div>
                                <ul>
                                    <li>
                                        {(!!(this.state.resultFileOtherDataObject?.UpdateFailedBeforeActualStartData?.BackupPath) ?
                                            Strings.formatString(myStrings.failedBeforeStartReasonBackupSpaceFormat, this.state.resultFileOtherDataObject.UpdateFailedBeforeActualStartData.BackupPath)
                                            :
                                            myStrings.failedBeforeStartReasonBackupSpaceFallback
                                        )}
                                    </li>
                                    <li>{myStrings.failedBeforeStartReasonBackupPermission}</li>
                                </ul>
                            </div>
                            <p>{myStrings.failedBeforeStartOutro1}</p>
                            <p>{Strings.formatString(myStrings.failedBeforeStartOutro2Format, this.props.previousOutlookClientVersion)}</p>
                        </>
                    );
                    break;
                case UpdateStatusMessageType.updateBreakedByFatalErrorWithoutSmallErrors:
                case UpdateStatusMessageType.updateBreakedByFatalErrorWithSmallErrors:
                    title = myStrings.failedTitle;
                    icon = 'error';
                    error = (<p>{myStrings.failedMessage}</p>);
                    break;
                case UpdateStatusMessageType.updateCompletedWithSmallErrors:
                    title = myStrings.completedTitle;
                    icon = 'warning';
                    error = (<p>{myStrings.completedWithWarningsMessage}</p>);
                    break;
                case UpdateStatusMessageType.updateCompletedWithoutSmallErrors:
                    title = myStrings.completedTitle;
                    icon = 'success';
                    error = (<p>{myStrings.completedMessage}</p>);
                    openUpTechData = false;
                    break;
                default:
                    // No error state. Continue.
                    break;
            }
            if (!!error) {
                return (
                    <ResultDialog title={title || myStrings.failedTitle} icon={icon || 'error'} techData={openUpTechData ? this.state.resultFileOtherData : null} onOk={this.props.onDone}>
                        {error}
                    </ResultDialog>
                );
            }
        }

        let statusMessage;
        switch (this.state.status) {
            case Status.waitingForResultFile:
                statusMessage = myStrings.stageVerifyingUpdate;
                break;

            case Status.havingResultFileMessageType:
                if (!this.state.resultFileMessageType)
                    throw new Error('No message type from update result file.');
                switch (this.state.resultFileMessageType) {
                    case UpdateStatusMessageType.touchedBeforeUpdateStart:
                        statusMessage = myStrings.stageDownloadingFiles;
                        break;
                    case UpdateStatusMessageType.updateStarted:
                    case UpdateStatusMessageType.updateRunningWithSmallErrors:
                        statusMessage = myStrings.stageInstalling;
                        break;
                    default:
                        console.warn(`No status message for type '${this.state.resultFileMessageType}'.`);
                        statusMessage = null;
                        break;
                }
                break;

            default:
                console.warn(`No status message for '${this.state.status as string}'.`);
                statusMessage = null;
                break;
        }

        const infoPart = (
            <div className="container max-w-none">
                <div className="row">
                    <div className="position-relative" style={{ width: '50px' }}>
                        <Spinner variant={SpinnerVariant.linear} backgroundOpacity={0} />
                    </div>
                    <div className="col pr-0" style={{ width: '11rem' }}>
                        <h1>{myStrings.updating}</h1>
                        <p>{statusMessage}</p>
                        {(!this.state.displayingKb && !!this.props.kbLink) &&
                            <Button variant="link" className="p-0" onClick={this.showChangelog}>{myStrings.showChangelog}</Button>
                        }
                    </div>
                </div>
                <div className="row mt-3 align-items-start">
                    <div className="col-auto px-1">
                        <FontIcon iconName="Info" style={{ fontSize: '150%', verticalAlign: 'middle' }} />
                    </div>
                    <div className="col px-1">
                        <div className="text-muted mr-auto" style={((this.state.displayingKb && !!this.props.kbLink) ? { maxWidth: '16rem' } : undefined)}>
                            {myStrings.takeLong}
                        </div>
                    </div>
                </div>
            </div>
        );

        if (this.state.displayingKb && !!this.props.kbLink) {
            return (
                <Modal show={true} centered dialogClassName="modal-90w" backdrop="static" >
                    <Modal.Body className="p-0">
                        <div className="container m-0 p-0 max-w-none">
                            <div className="row m-0 p-0">
                                <div className="col-auto border-right px-xl-5 px-lg-4 py-lg-5 p-md-3 pt-md-2 p-2 min-w-0">
                                    {infoPart}
                                </div>
                                <div className="col px-xl-5 px-lg-4 py-lg-5 p-md-3 pt-md-2 p-2 min-w-0">
                                    <iframe src={'iframe.html?src=' + encodeURIComponent(this.props.kbLink)} title={this.props.outlookClientVersion} className="w-100 vh-75 border-0" id={iframeName} name={iframeName} />
                                </div>
                            </div>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        } else {
            return (
                <WizardModal show={true}>
                    <WizardModal.Body>
                        {infoPart}
                    </WizardModal.Body>
                </WizardModal>
            );
        }
    }
}