import * as React from 'react';
import type { ButtonProps } from 'react-bootstrap';
import { Button, Dropdown } from 'react-bootstrap';

export type TFormDropdownOption = { title: string; value: string; disabled?: boolean; titleSuffix?: JSX.Element };

type TFormDropdownProps = {
    value: string;
    options: TFormDropdownOption[];
    onValueChange: (newValue: string) => void;
    onBlur?: () => void;
    disabled?: boolean;
    isInvalid?: boolean;
};

export class FormDropdown extends React.Component<TFormDropdownProps> {

    render() {
        const selectedIndex = this.props.options.findIndex((opt) => opt.value === this.props.value);
        if (selectedIndex === -1) {
            console.error(`FormDropdown does not have the selected value in options. Value is "${this.props.value}" and options are [${this.props.options.map(opt => `"${opt.value}"`).join(", ")}]`);
            return;
        }

        const Toggle = React.forwardRef((p: ButtonProps & React.DOMAttributes<HTMLButtonElement>, ref: React.Ref<HTMLButtonElement>) => (<Button disabled={this.props.disabled} ref={ref as (string & React.RefObject<HTMLButtonElement>)} onClick={p.onClick}>{p.children}</Button>));
        return (
            <Dropdown
                onSelect={(eventKey: string | null) => this.props.onValueChange(eventKey || this.props.options[0].value)}
                onBlur={this.props.onBlur}
                className={'form-control' + (this.props.isInvalid ? ' is-invalid' : '')}
            >
                <Dropdown.Toggle as={Toggle} id="form-dropwdown-toggle-for">
                    <span>{this.props.options[selectedIndex].title}</span>
                    <span className="float-right"><i className="mdl2 mdl2-scroll-chevron-down-legacy" aria-hidden="true" /></span>
                </Dropdown.Toggle>
                <Dropdown.Menu >
                    {this.props.options.map((opt, i) =>
                        <Dropdown.Item key={'dd-option-' + i} eventKey={opt.value} disabled={opt.disabled} active={opt.value === this.props.value}>{opt.title} {opt.titleSuffix}</Dropdown.Item>
                    )}
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}