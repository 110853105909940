import React from "react";
import { ConnectionContext } from "../../../../../../providers/ConnectionProvider";
import CheckoutSubmitter from "../../../CheckoutSubmitter";
import type { TPaymentConfig } from "./usePaymentConfig";
import type { TSubmitHandler } from "./useSubmitHandler";

const useNoPaymentConfig = (isEnabled: boolean, submitHandler: TSubmitHandler): TPaymentConfig => {
    const { connection } = React.useContext(ConnectionContext);
    const { handleFinisherInitialized, handlePaymentMethodConfirmed, handleFinisherUnloaded, handleSubmitClick } = submitHandler;

    const confirm = React.useCallback(() => {
        handlePaymentMethodConfirmed((customerInfo, products, eulaHtml, selectedPeriodType) => CheckoutSubmitter.createCheckoutOrderModel(customerInfo, products, eulaHtml, null, null, selectedPeriodType, connection));
    }, [handlePaymentMethodConfirmed, connection]);

    React.useEffect(() => {
        if (!isEnabled)
            return;

        handleFinisherInitialized(confirm);
    }, [isEnabled, handleFinisherInitialized, confirm]);

    React.useEffect(() => isEnabled ? handleFinisherUnloaded : undefined, [isEnabled, handleFinisherUnloaded]);

    return React.useMemo(() => {
        const handlePaySimple = () => {
            if (!isEnabled)
                return;

            handleSubmitClick();
        };
        return {
            availableStripeMethods: null,
            selectedStripeMethod: null,
            onAddNew: null,
            onAddNewAndPay: null,
            onSelectMethodId: null,
            onPayWithStripeMethodId: null,
            isLoading: false,
            renderComponent: null,
            onPaySimple: handlePaySimple
        };
    }, [isEnabled, handleSubmitClick]);
};

export default useNoPaymentConfig;