/**
 * Stop words combined from modified list of all Czech prepositions (předložky), conjunctions (spojky), and Google stop words https://www.ranks.nl/stopwords/czech
 */

export const StopWordsMapCs = {
    a: true,
    'a přec': true,
    'a přece': true,
    'a přeci': true,
    'a sice': true,
    'a to': true,
    aby: true,
    ač: true,
    ačkoli: true,
    ačkoliv: true,
    aj: true,
    aji: true,
    ale: true,
    an: true,
    aneb: true,
    ani: true,
    aniž: true,
    'ano i': true,
    anžto: true,
    ať: true,
    avšak: true,
    až: true,
    ba: true,
    bo: true,
    buď: true,
    buďto: true,
    by: true,
    byť: true,
    či: true,
    čili: true,
    coby: true,
    dílem: true,
    div: true,
    dokud: true,
    enem: true,
    haj: true,
    hať: true,
    hned: true,
    i: true,
    'i když': true,
    jakkoli: true,
    jakkoliv: true,
    jakmile: true,
    jako: true,
    jakoby: true,
    jakožto: true,
    jelikož: true,
    jen: true,
    jenom: true,
    jenomže: true,
    jenže: true,
    jestli: true,
    jestliže: true,
    ježto: true,
    jináč: true,
    jinak: true,
    kamkoli: true,
    kdežto: true,
    kdyby: true,
    kdybych: true,
    kdybychom: true,
    kdybys: true,
    kdybyste: true,
    kdykoli: true,
    kdykoliv: true,
    když: true,
    leč: true,
    leda: true,
    ledva: true,
    ledvaže: true,
    málem: true,
    neb: true,
    nebo: true,
    neboli: true,
    neboť: true,
    nebť: true,
    než: true,
    nežli: true,
    ni: true,
    nicméně: true,
    nobrž: true,
    nýbrž: true,
    pak: true,
    pokud: true,
    poněvadž: true,
    potom: true,
    přesto: true,
    přestože: true,
    pročež: true,
    proto: true,
    protože: true,
    seč: true,
    sic: true,
    sice: true,
    sotva: true,
    tak: true,
    takže: true,
    tedy: true,
    totiž: true,
    tož: true,
    třeba: true,
    třebaže: true,
    tudíž: true,
    však: true,
    vždyť: true,
    'zatím co': true,
    zatímco: true,
    zda: true,
    zdali: true,
    že: true,
    žes: true,
    à: true,
    během: true,
    bez: true,
    beze: true,
    blízko: true,
    cestou: true,
    dík: true,
    díky: true,
    dle: true,
    do: true,
    jménem: true,
    k: true,
    ke: true,
    kol: true,
    kolem: true,
    krom: true,
    kromě: true,
    ku: true,
    kvůli: true,
    mezi: true,
    mimo: true,
    místo: true,
    na: true,
    nad: true,
    nade: true,
    namísto: true,
    naproti: true,
    navrch: true,
    navzdory: true,
    nedaleko: true,
    o: true,
    ob: true,
    od: true,
    ode: true,
    ohledně: true,
    okolo: true,
    oproti: true,
    po: true,
    poblíž: true,
    pod: true,
    pode: true,
    podél: true,
    podle: true,
    podlevá: true,
    podlivá: true,
    pomocí: true,
    před: true,
    přede: true,
    přes: true,
    přese: true,
    při: true,
    pro: true,
    prostřednictvím: true,
    proti: true,
    s: true,
    se: true,
    skrz: true,
    skrze: true,
    stran: true,
    stranou: true,
    u: true,
    'u příležitosti': true,
    uprostřed: true,
    v: true,
    včetně: true,
    ve: true,
    vedle: true,
    versus: true,
    vinou: true,
    'vis-à-vis': true,
    vlivem: true,
    vně: true,
    vo: true,
    vod: true,
    vstříc: true,
    vůči: true,
    vůkol: true,
    vz: true,
    vzdor: true,
    'vzhledem k': true,
    z: true,
    za: true,
    ze: true,
    zkraje: true,
    zpod: true,
    zpoza: true,
    dnes: true,
    timto: true,
    budes: true,
    budem: true,
    byli: true,
    jses: true,
    muj: true,
    svym: true,
    ta: true,
    tomto: true,
    tohle: true,
    tuto: true,
    tyto: true,
    jej: true,
    proc: true,
    mate: true,
    tato: true,
    kam: true,
    tohoto: true,
    kdo: true,
    kteri: true,
    mi: true,
    nam: true,
    tom: true,
    tomuto: true,
    mit: true,
    kterou: true,
    byla: true,
    toho: true,
    protoze: true,
    asi: true,
    ho: true,
    nasi: true,
    coz: true,
    tim: true,
    takze: true,
    svych: true,
    jeji: true,
    svymi: true,
    jste: true,
    tu: true,
    teto: true,
    bylo: true,
    kde: true,
    ji: true,
    nejsou: true,
    ci: true,
    pres: true,
    ty: true,
    vam: true,
    kdyz: true,
    vsak: true,
    ne: true,
    jsem: true,
    tento: true,
    jsme: true,
    pred: true,
    pta: true,
    jejich: true,
    byl: true,
    jeste: true,
    az: true,
    take: true,
    pouze: true,
    vase: true,
    ktera: true,
    nas: true,
    muze: true,
    jeho: true,
    sve: true,
    jine: true,
    neni: true,
    vas: true,
    zde: true,
    uz: true,
    byt: true,
    vice: true,
    bude: true,
    jiz: true,
    nez: true,
    ktery: true,
    ktere: true,
    co: true,
    ten: true,
    ma: true,
    pri: true,
    jsou: true,
    jak: true,
    si: true,
    to: true,
    je: true,
};
