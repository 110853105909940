import * as React from 'react';
import type { Sorting } from '@devexpress/dx-react-grid';
import { SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import Strings from '../../../strings';
import GridTable from '../../GridTable';
import type { IOrder, IOrderItem } from '../../../data/shopping/IOrdersHistoryModel';
import { DateHelper } from '../../../helpers/DateHelper';
import HtmlComponent from '../HtmlComponent';
import { InvoiceViewer } from './InvoiceViewer';
import type { ShoppingConnection } from '../../ShoppingConnection';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import StringHelper from '../../../helpers/StringHelper';
import invoiceImg from '../../../img/data/CartTypeSalesInvoice.svg';
import { InvoicePayer } from './InvoicePayer';
import type { TPaymentGateProvider } from '../../../data/shopping/IPaymentMethodsModel';

const myStrings = Strings.components.routes.purchaseHistory;

const DatesSpan: React.FunctionComponent<{ item: IOrderItem }> = (props) => {
    const myStrings = Strings.components.routes.purchaseHistory;
    if (props.item.ValidFromString && props.item.ValidToString) {
        return (
            <span>
                {Strings.formatDate(props.item.ValidFromString)}
                &nbsp;&ndash;&nbsp;
                {Strings.formatDate(props.item.ValidToString)}
            </span>
        );
    } else if (props.item.ValidFromString) {
        return <span>{myStrings.from} {Strings.formatDate(props.item.ValidFromString)}</span>;
    } else if (props.item.ValidToString) {
        return <span>{myStrings.til}{Strings.formatDate(props.item.ValidToString)}</span>;
    } else {
        return <span />;
    }
};

const iconColumnName = '__icon';
const actionsColumnName = '__actions';
const defaultColumnWidths = [
    { columnName: iconColumnName, width: 44 },
    { columnName: 'InvoiceNumber', width: 100 },
    { columnName: 'OrderDateString', width: 110 },
    { columnName: 'Items', width: 440 },
    { columnName: 'TotalPrice', width: 110 },
    { columnName: 'IsPaid', width: 150 },
    { columnName: actionsColumnName, width: 120 }
];
const gridColumnsFull = [
    { name: iconColumnName, title: ' ' },
    { name: 'InvoiceNumber', title: Strings.components.routes.purchaseHistory.invoiceNr },
    { name: 'OrderDateString', title: Strings.components.routes.purchaseHistory.date },
    { name: 'Items', title: myStrings.articles },
    { name: 'TotalPrice', title: Strings.components.routes.purchaseHistory.total },
    { name: 'IsPaid', title: Strings.components.routes.purchaseHistory.status },
    { name: actionsColumnName, title: Strings.components.routes.purchaseHistory.actions }
];
const gridColumnsCard = [
    { name: iconColumnName, title: ' ' },
    { name: 'InvoiceNumber', title: Strings.components.routes.purchaseHistory.invoiceNr },
    { name: 'OrderDateString', title: Strings.components.routes.purchaseHistory.date },
    { name: 'TotalPrice', title: Strings.components.routes.purchaseHistory.total },
    { name: 'IsPaid', title: Strings.components.routes.purchaseHistory.status },
    { name: actionsColumnName, title: Strings.components.routes.purchaseHistory.actions }
];

const sortingStateColumnExtensions: SortingState.ColumnExtension[] = [
    { columnName: iconColumnName, sortingEnabled: false },
    { columnName: 'Items', sortingEnabled: false },
    { columnName: actionsColumnName, sortingEnabled: false }
];

const integratedSortingColumnExtensions = [
    { columnName: 'InvoiceNumber', compare: StringHelper.localeCompare },
    { columnName: 'OrderDateString', compare: DateHelper.compareUniversals }
];

const css = mergeStyleSets({
    iconImg: {
        height: '32px',
        width: '32px',
        margin: '0 auto',
        display: 'block'
    }
});

type TVariant = 'full' | 'card';

type TPurchaseHistoryGridProps = {
    shoppingConnection: ShoppingConnection;
    variant: TVariant;
    data: IOrder[];
    paymentGateProvider: TPaymentGateProvider;
    onReload: () => Promise<void>;
};

type TPurchaseHistoryGridState = {
    sort: Sorting[];
};

export class PurchaseHistoryGrid extends React.Component<TPurchaseHistoryGridProps, TPurchaseHistoryGridState> {

    constructor(props: TPurchaseHistoryGridProps) {
        super(props);
        this.state = {
            sort: [{ columnName: 'OrderDateString', direction: 'desc' }]
        };
    }

    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = ({ column, value, row, ...restProps }) => {
        let renderedValue;
        const typedRow = row as IOrder;
        const typedValue = value as React.ReactChild | null;
        const statusSpecified: boolean = typedRow.IsPaid !== null && typeof typedRow.IsPaid !== 'undefined';
        const red: boolean = statusSpecified && !typedRow.IsPaid && !!typedRow.DueDateString && DateHelper.getDatePart(DateHelper.parseUniversalDate(typedRow.DueDateString)) < DateHelper.getToday();
        const blue: boolean = statusSpecified && !typedRow.IsPaid && !!typedRow.DueDateString && DateHelper.getDatePart(DateHelper.parseUniversalDate(typedRow.DueDateString)).getTime() === DateHelper.getToday().getTime();
        let className = 'align-middle';
        if (blue) {
            className = mergeStyles(className, 'text-primary');
        }
        if (red) {
            className = mergeStyles(className, 'text-danger');
        }

        if (column.name === iconColumnName) {
            className = mergeStyles(className, 'px-0 align-items-center');
            renderedValue = (
                <img src={invoiceImg} alt={typedRow.InvoiceNumber || ''} className={css.iconImg} />
            );
        } else if (column.name === 'InvoiceNumber') {
            className = mergeStyles(className, 'highlighted');
            renderedValue = typedValue;
        } else if (column.name === 'Items') {
            renderedValue = (
                <>
                    {(!!typedRow.Items) &&
                        <>
                            {typedRow.Items.map((item, i) => (
                                <div key={'invoice-item-' + i} className="container p-0 m-0 text-wrap">
                                    <div className="row p-0 m-0">
                                        <div className="col p-0 m-0">
                                            <div>
                                                {Strings.pickTranslation(item.NameTranslations)}
                                            </div>
                                            <div>
                                                <DatesSpan item={item} />
                                            </div>
                                            {(!!item.Description) &&
                                                <div><HtmlComponent htmlString={item.Description} /></div>
                                            }
                                        </div>
                                        <div className="col-auto text-right p-0 mx-0 mr-0 ml-3">
                                            {item.Amount} {Strings.pickTranslation(item.UnitNameTranslations)}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    }
                </>
            );
        } else if (column.name === 'OrderDateString') {
            renderedValue = Strings.formatDate(typedValue as string | null);
        } else if (column.name === 'TotalPrice') {
            if (!!typedValue || typedValue === 0) {
                renderedValue = Strings.formatCurrency(typedValue as number | null, 2, typedRow.CurrencyCode);
            } else {
                renderedValue = '';
            }
            className = mergeStyles(className, 'text-right');
        } else if (column.name === 'IsPaid') {
            if (statusSpecified) {
                if (typedRow.IsPaid) {
                    renderedValue = Strings.components.routes.purchaseHistory.paid;
                } else {
                    renderedValue = (
                        <>
                            <div>{Strings.components.routes.purchaseHistory.notPaid}</div>
                            {(!!typedRow.DueDateString) &&
                                <div>{Strings.components.routes.purchaseHistory.due} {Strings.formatDate(typedRow.DueDateString)}</div>
                            }
                        </>
                    );
                }
            } else {
                renderedValue = '';
            }
        } else if (column.name === actionsColumnName) {
            renderedValue = (
                <>
                    {(typedRow.AllowInstantPay && !!typedRow.TotalPriceToPay && typedRow.TotalPriceToPay > 0.00) &&
                        <div>
                            <InvoicePayer
                                shoppingConnection={this.props.shoppingConnection}
                                paymentGateProvider={this.props.paymentGateProvider}
                                invoiceId={typedRow.InvoiceId}
                                priceToPay={typedRow.TotalPriceToPay}
                                invoiceNumber={typedRow.InvoiceNumber || '-'}
                                currencyCode={typedRow.CurrencyCode}
                                onDone={this.props.onReload}
                            />
                        </div>
                    }
                    {(!!typedRow.InvoiceUrl) &&
                        <div>
                            <InvoiceViewer src={typedRow.InvoiceUrl} invoiceId={'invoice-id-' + typedRow.InvoiceId} />
                        </div>
                    }
                </>
            );
        } else {
            renderedValue = typedValue;
        }

        return (
            <VirtualTable.Cell
                column={column}
                value={typedValue}
                row={typedRow}
                {...restProps}
                className={className}
            >
                {renderedValue}
            </VirtualTable.Cell>
        );
    };

    render() {
        return (
            <Grid
                rows={this.props.data}
                columns={this.props.variant === 'full' ? gridColumnsFull : gridColumnsCard}
                rootComponent={this.props.variant === 'card' ? GridTable.RootGray : GridTable.Root}
                getRowId={(row: { InvoiceId: number }) => row.InvoiceId}
            >
                <SortingState
                    sorting={this.state.sort}
                    onSortingChange={sort => this.setState({ sort: sort })}
                    columnExtensions={sortingStateColumnExtensions}
                    columnSortingEnabled={this.props.variant === 'full'}
                />
                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                <VirtualTable
                    containerComponent={GridTable.TableContainerComponent}
                    messages={{ noData: myStrings.noInvoices }}
                    noDataCellComponent={GridTable.NoDataCell}
                    cellComponent={this.CellComponent}
                />
                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
            </Grid>
        );
    }
}