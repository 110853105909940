import type { IApiColumn } from '@eway-crm/connector';

export type TUsedFieldOrigin = {
    folderName: string;
    isAdditionalField: boolean;
    additionalFieldItemGuid: string | null;
};

export type TUsedFieldsMap = Map<string, TUsedFieldOrigin[]>;

export class ForbiddenFieldNameDetector {

    /**
     * Gets the set of the already used field names.
     */
    static getUsedFieldNames(allColumns: IApiColumn[]) {
        const registerOrigin = (usedNames: TUsedFieldsMap, name: string, origin: TUsedFieldOrigin) => {
            const nameKey = ForbiddenFieldNameDetector.prepareNameForCollisionDetection(name);
            if (usedNames.has(nameKey)) {
                usedNames.get(nameKey)!.push(origin);
            } else {
                usedNames.set(nameKey, [origin]);
            }
        };

        const usedNames: TUsedFieldsMap = new Map<string, TUsedFieldOrigin[]>();
        allColumns.forEach(col => {
            const origin: TUsedFieldOrigin = {
                folderName: col.FolderName,
                isAdditionalField: col.IsAdditionalField,
                additionalFieldItemGuid: col.AdditionalFieldItem?.ItemGUID || null
            };
            if (col.NameEn) {
                registerOrigin(usedNames, col.NameEn, origin);
                col.AltNameEn && col.AltNameEn !== col.NameEn && registerOrigin(usedNames, col.AltNameEn, origin);
            }
            if (col.NameCs) {
                registerOrigin(usedNames, col.NameCs, origin);
                col.AltNameCs && col.AltNameCs !== col.NameCs && registerOrigin(usedNames, col.AltNameCs, origin);
            }
            if (col.NameDe) {
                registerOrigin(usedNames, col.NameDe, origin);
                col.AltNameDe && col.AltNameDe !== col.NameDe && registerOrigin(usedNames, col.AltNameDe, origin);
            }
            if (col.NameRu) {
                registerOrigin(usedNames, col.NameRu, origin);
                col.AltNameRu && col.AltNameRu !== col.NameRu && registerOrigin(usedNames, col.AltNameRu, origin);
            }
            if (col.NameSk) {
                registerOrigin(usedNames, col.NameSk, origin);
                col.AltNameSk && col.AltNameSk !== col.NameSk && registerOrigin(usedNames, col.AltNameSk, origin);
            }
            if (col.NameNo) {
                registerOrigin(usedNames, col.NameNo, origin);
                col.AltNameNo && col.AltNameNo !== col.NameNo && registerOrigin(usedNames, col.AltNameNo, origin);
            }
        });

        return usedNames;
    }

    /**
     * Checks whether the given af name can be used in all of the folders. If not, troublemaking field origin is returned.
     * 
     * @param usedFieldNames The used field names map.
     * @param name The tested name.
     * @param folderNames The assigned folders.
     * @param isNew True if the field does not exist yet.
     * @param itemGuid Item guid of the field if it already exists. Otherwise null.
     */
    static getNameCollisionOrigin(usedFieldNames: TUsedFieldsMap, name: string, folderNames: string[], isNew: boolean, itemGuid: string | null): TUsedFieldOrigin | null {
        const testedName = ForbiddenFieldNameDetector.prepareNameForCollisionDetection(name);
        if (usedFieldNames.has(testedName)) {
            const origins = usedFieldNames.get(testedName)!;
            for (let i = 0; i < origins.length; i++) {
                const origin = origins[i];
                if (folderNames.indexOf(origin.folderName) !== -1) {
                    if (!origin.isAdditionalField || isNew || origin.additionalFieldItemGuid !== itemGuid) {
                        return origin;
                    }
                }
            }
        }
        return null;
    }

    private static prepareNameForCollisionDetection(name: string) {
        return name.trim().toLocaleLowerCase();
    }
}