import type { IApiDataResponse, IApiGroup, IApiModulePermission } from '@eway-crm/connector';
import { TooltipHost } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { GroupNames } from '../../../../data/constants/GroupNames';
import ModulePermissionNames from '../../../../data/constants/ModulePermissionNames';
import StringHelper from '../../../../helpers/StringHelper';
import Strings from '../../../../strings';
import type {
    TModulePermissionsCellWrapperProps} from '../../modulePermissions/ModulePermissionsGrid';
import {
    modulePermissionsDropdownColumnSettings,
    modulePermissionsBooleanOptionsSettings,
    modulePermissionsOptionsSettings
} from '../../modulePermissions/ModulePermissionsGrid';
import { getRowsRestrictionsSuffix } from '../../modulePermissions/RowsRestrictionsEditCell';
import EffectivePermissionsTooltipContainer from './EffectivePermissionsTooltipContainer';
import type { TRowContentProps } from './EffectivePermissionsTooltipRow';
import EffectivePermissionsTooltipRow from './EffectivePermissionsTooltipRow';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';

const myStrings = Strings.components.routes.users;

const columnsPermissionPriority = {
    View: 'highest',
    CanCreate: 'highest',
    Edit: 'highest',
    Delete: 'highest',
    CanExport: 'lowest',
    CanSeeHistory: 'lowest',
    RowsRestriction: 'lowest',
} as const;

export type TModulePermissionsCellTooltipAdditionalProps = {
    userGroups: IApiGroup[];
};

type TModulePermissionWithGroupName = IApiModulePermission & { groupName: string };

type TEffectiveModulePermissionsCellTooltipProps = TModulePermissionsCellWrapperProps & TModulePermissionsCellTooltipAdditionalProps & React.PropsWithChildren;

const EffectiveModulePermissionsCellTooltip: React.FC<TEffectiveModulePermissionsCellTooltipProps> = ({ children, data, userGroups }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [permissionsData, setPermissionsData] = useState<TModulePermissionWithGroupName[] | null>(null);
    const { connection } = useContext(ConnectionContext);

    const getPermissionsData = async () => {
        const folderName = data!.row.FolderName;

        if (data!.row.rowPermissions) {
            return data!.row.rowPermissions;
        }

        const modulePermissionsRes = await connection.askApi<IApiDataResponse<IApiModulePermission>>('SearchModulePermissions', {
            transmitObject: { FolderName: folderName },
            includeDefaultPermissions: true,
        });

        const modulePermissions: TModulePermissionWithGroupName[] = [];
        modulePermissionsRes.Data.forEach((mp) => {
            const groupName = userGroups.find((group) => group.ItemGUID === mp.GroupGuid)?.GroupName;
            if (groupName && groupName !== GroupNames.systemHealthNotification) {
                modulePermissions.push({ ...mp, groupName });
            }
        });

        return modulePermissions.sort((a, b) => StringHelper.localeCompare(a.groupName, b.groupName));
    };

    const onTooltipToggle = async (isVisible: boolean) => {
        if (isVisible && !permissionsData) {
            setIsLoading(true);
            const permissions = await getPermissionsData();
            setIsLoading(false);
            setPermissionsData(permissions);
        }
    };

    const getTooltipContent = () => {
        if (!permissionsData) {
            return null;
        }
        const columnName = data!.column.name as keyof IApiModulePermission;
        const columnPermissionPriority = columnsPermissionPriority[columnName as keyof typeof columnsPermissionPriority];
        let sortedModulePermissions: TModulePermissionWithGroupName[] = [];
        let rowContentPropsGetter: (modulePermission: IApiModulePermission) => TRowContentProps;
        let action = '';

        if ([ModulePermissionNames.Columns.View, ModulePermissionNames.Columns.Edit, ModulePermissionNames.Columns.Delete].includes(columnName)) {
            sortedModulePermissions = permissionsData.sort((a, b) => {
                const order = ModulePermissionNames.OptionsOrder;
                return order.indexOf(a[columnName] as string) - order.indexOf(b[columnName] as string);
            });

            const columnSettings = modulePermissionsDropdownColumnSettings[columnName as keyof typeof modulePermissionsDropdownColumnSettings];
            action = columnSettings.action;

            rowContentPropsGetter = (modulePermission) => {
                const settingsKey = modulePermission[columnName];
                const optionSettings = modulePermissionsOptionsSettings[settingsKey as keyof typeof modulePermissionsOptionsSettings];

                return { description: optionSettings.text(columnSettings.action), iconProps: { icon: optionSettings.icon, fill: optionSettings.color } };
            };
        } else if ([ModulePermissionNames.Columns.CanCreate, ModulePermissionNames.Columns.CanExport, ModulePermissionNames.Columns.CanSeeHistory].includes(columnName)) {
            sortedModulePermissions = permissionsData.sort((a, b) => {
                if (columnPermissionPriority === 'highest') {
                    return Number(b[columnName] as boolean) - Number(a[columnName] as boolean);
                }
                return Number(a[columnName] as boolean) - Number(b[columnName] as boolean);
            });

            const columnSettings = modulePermissionsDropdownColumnSettings[columnName as keyof typeof modulePermissionsDropdownColumnSettings];
            action = columnSettings.action;

            rowContentPropsGetter = (modulePermission) => {
                const settingsKey = modulePermission[columnName] ? 'true' : 'false';
                const optionSettings = modulePermissionsBooleanOptionsSettings[settingsKey];

                return { description: optionSettings.text(columnSettings.action), iconProps: { icon: optionSettings.icon, fill: optionSettings.color } };
            };
        } else if ([ModulePermissionNames.Columns.RowsRestriction].includes(columnName)) {
            sortedModulePermissions = permissionsData.sort((a, b) => {
                if (!a[columnName]) {
                    return 1;
                } else if (!b[columnName]) {
                    return -1;
                }
                return Number(a[columnName]) - Number(b[columnName]);
            });

            action = 'see limited number of';

            rowContentPropsGetter = (modulePermission) => {
                const rowsRestrictions = modulePermission[columnName] as number;
                let description = Strings.components.routes.modulePermissions.options.noRestrictionsText;
                if (rowsRestrictions !== null) {
                    description = `${rowsRestrictions} ${getRowsRestrictionsSuffix(rowsRestrictions)}`;
                }

                return { description };
            };
        }

        const content = sortedModulePermissions.map((mp) => {
            const isActivePermissionLevel = data!.row[columnName] === mp[columnName];
            return <EffectivePermissionsTooltipRow key={mp.groupName} isActivePermissionLevel={isActivePermissionLevel} groupName={mp.groupName} contentProps={rowContentPropsGetter(mp)} />;
        });

        return (
            <EffectivePermissionsTooltipContainer
                description={
                    <>
                        {myStrings.presenceInGroups} <strong>{columnPermissionPriority === 'highest' ? myStrings.highest : myStrings.lowest}</strong> {myStrings.permissionAffectsAbility}{' '}
                        <strong>{action}</strong> {myStrings.items}.
                    </>
                }
            >
                {content}
            </EffectivePermissionsTooltipContainer>
        );
    };

    return (
        <TooltipHost
            calloutProps={{ gapSpace: 2, calloutMaxWidth: 500 }}
            tooltipProps={{ onRenderContent: () => (isLoading ? <Spinner size="sm" animation="border" /> : getTooltipContent()) }}
            onTooltipToggle={(isVisible) => void onTooltipToggle(isVisible)}
        >
            {children}
        </TooltipHost>
    );
};

export default EffectiveModulePermissionsCellTooltip;
