import React from 'react';
import type { TAutosuggestItem } from './Autosuggest';

type TAutosuggestContext = {
    isInputFocused: boolean;
    setIsInputFocused: React.Dispatch<React.SetStateAction<boolean>>;
    isSuggestOpen: boolean;
    setIsSuggestOpen: React.Dispatch<React.SetStateAction<boolean>>;
    value: string;
    onValueChange: (newValue: string) => void;
    suggestions: TAutosuggestItem[];
    onValueSelection: (newValue: string) => void;
    inputRef: React.RefObject<HTMLInputElement>;
    disabled?: boolean;
};

export const autosuggestContext = React.createContext<TAutosuggestContext>(null!);
export const AutosuggestContextConsumer = autosuggestContext.Consumer;
