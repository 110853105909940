import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiItemUsageStatus } from '@eway-crm/connector';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { TFieldWithCol } from './FolderFields';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TDeleteAdditionalFieldsWizardProps = {
    onDismiss: () => void;
    selectedFields: TFieldWithCol[];
    onDone: () => void;
};

type TDeleteAdditionalFieldsWizardState = {
    usageStatuses: IApiItemUsageStatus[] | null;
    isSavingInProgress: boolean;
};

export class DeleteAdditionalFieldsWizard extends React.Component<TDeleteAdditionalFieldsWizardProps, TDeleteAdditionalFieldsWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TDeleteAdditionalFieldsWizardProps) {
        super(props);
        this.state = {
            usageStatuses: null,
            isSavingInProgress: false
        };
    }

    componentDidMount() {
        this.context.connection.callApi(
            'GetAdditionalFieldsUsageStatus',
            { itemGuids: this.props.selectedFields.map(field => field.col.AdditionalFieldItem!.ItemGUID) },
            (usageResult: IApiDataResponse<IApiItemUsageStatus>) => {
                this.setState({ usageStatuses: usageResult.Data });
            }
        );
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly getUnusedAdditionalFields = () => {
        if (!this.state.usageStatuses)
            return [];

        return this.props.selectedFields.filter(field => this.state.usageStatuses!.findIndex(us => us.ItemGuid === field.col.AdditionalFieldItem!.ItemGUID && !us.IsUsed) !== -1);
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                this.context.connection.callApi(
                    'DeleteAdditionalFields',
                    {
                        itemGuids: this.getUnusedAdditionalFields().map((field) => field.col.AdditionalFieldItem!.ItemGUID)
                    },
                    () => {
                        this.props.onDone();
                    }
                );
            }
        );
    };

    render() {
        if (this.state.isSavingInProgress || !this.state.usageStatuses) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        }

        const myStrings = Strings.components.routes.fields;
        const fieldsToDelete = this.getUnusedAdditionalFields();

        let title;
        let message;
        if (fieldsToDelete.length === 0) {
            title = myStrings.deleteAfPluralTitle;
            message = myStrings.noFieldsCanBeDeleted;
        }
        else {
            if (fieldsToDelete.length !== this.props.selectedFields.length) {
                title = myStrings.deleteAfPluralTitle;
                message = myStrings.someFieldsCannotBeDeleted;
            } else {
                if (fieldsToDelete.length === 1) {
                    title = myStrings.deleteAfSingularTitle;
                    message = myStrings.deleteAfSingularMessage;
                } else {
                    title = myStrings.deleteAfPluralTitle;
                    message = myStrings.deleteAfPluralMessage;
                }
            }
        }

        return (
            <WizardModal show={true} onHide={this.dismiss}>
                <WizardModal.Body>
                    <h1>{title}</h1>
                    <div className="mb-4">
                        {fieldsToDelete.map((field) =>
                            <span key={'del-group-' + field.col.AdditionalFieldItem?.ItemGUID} className="badge badge-info badge-full-font mr-1">
                                {field.colName}
                            </span>
                        )}
                    </div>
                    <div>
                        {message}
                    </div>
                </WizardModal.Body>
                <WizardModal.Footer>
                    <Button variant="danger" onClick={this.submit} disabled={fieldsToDelete.length === 0}>{Strings.delete}</Button>
                    <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                </WizardModal.Footer>
            </WizardModal>
        );
    }
}