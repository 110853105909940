import * as React from 'react';
import Strings from '../../../strings';
import type { ShoppingConnection } from '../../ShoppingConnection';
import { DeleteLink } from './DeleteLink';
import { SetAsActiveLink } from './SetAsActiveLink';
import type { IBraintreePaymentMethod, IPaymentMethod, IStripePaymentMethod } from '../../../data/shopping/IPaymentMethodsModel';
import type { ITokenizedApiResult } from '@eway-crm/connector';
import { FontIcon, mergeStyleSets } from '@fluentui/react';

type TMethodsGridTableProps = {
    methods: IBraintreePaymentMethod[] | null;
    stripeMethods: IStripePaymentMethod[] | null;
    shoppingConnection: ShoppingConnection;
    onChange: () => void;
};

export const MethodsGridTable: React.FC<TMethodsGridTableProps> = (props) => {
    const myStrings = Strings.components.routes.billingDetails;

    return (
        <div className="grid-table">
            <table className="table">
                <thead>
                    <tr>
                        <th className="text-center">{myStrings.type}</th>
                        <th>{myStrings.identifier}</th>
                        <th>{myStrings.expiration}</th>
                        <th className="text-center">{myStrings.status}</th>
                        <th className="text-center">{myStrings.delete}</th>
                    </tr>
                </thead>
                <tbody>
                    {(!!props.stripeMethods) &&
                        <>
                            {props.stripeMethods.map((value) =>
                                <MethodRow
                                    key={'stripe-payment-method-' + value.Id}
                                    paymentMethod={value}
                                    brandComponent={StripePaymentMethodBrand}
                                    activateFunc={async (paymentMethod) => await props.shoppingConnection.askShoppingApi('SetStripeDefaultPaymentMethod', { paymentMethodId: paymentMethod.Id })}
                                    deleteFunc={async (paymentMethod) => await props.shoppingConnection.askShoppingApi('DeleteStripePaymentMethod', { paymentMethodId: paymentMethod.Id })}
                                    onChange={props.onChange}
                                />
                            )}
                        </>
                    }
                    {(!!props.methods) &&
                        <>
                            {props.methods.map((value: IBraintreePaymentMethod) =>
                                <MethodRow
                                    key={'bt-payment-method-' + value.Token}
                                    paymentMethod={value}
                                    brandComponent={BraintreeBrand}
                                    activateFunc={async (paymentMethod: IBraintreePaymentMethod) => await props.shoppingConnection.askShoppingApi('SetDefaultPaymentMethod', { paymentMethodToken: paymentMethod.Token })}
                                    deleteFunc={async (paymentMethod: IBraintreePaymentMethod) => await props.shoppingConnection.askShoppingApi('DeletePaymentMethod', { paymentMethodToken: paymentMethod.Token })}
                                    onChange={props.onChange}
                                />
                            )}
                        </>
                    }
                </tbody>
            </table>
        </div>
    );
};

type TBrandComponentProps<TPaymentMethod extends IPaymentMethod> = { paymentMethod: TPaymentMethod };

type TMethodRowProps<TPaymentMethod extends IPaymentMethod> = {
    paymentMethod: TPaymentMethod;
    brandComponent: React.ComponentType<TBrandComponentProps<TPaymentMethod>>;
    activateFunc: (paymentMethod: TPaymentMethod) => Promise<ITokenizedApiResult>;
    deleteFunc: (paymentMethod: TPaymentMethod) => Promise<ITokenizedApiResult>;
    onChange: () => void;
};

const MethodRow = <TPaymentMethod extends IPaymentMethod,>(props: React.PropsWithChildren<TMethodRowProps<TPaymentMethod>>): React.ReactElement => {
    const myStrings = Strings.components.routes.billingDetails;
    return (
        <tr className={props.paymentMethod.IsExpired ? 'text-danger' : undefined}>
            <td className="text-center align-middle" style={{ width: '5%' }}>
                <props.brandComponent paymentMethod={props.paymentMethod} />
            </td>
            <td className="align-middle highlighted">{props.paymentMethod.Title}</td>
            <td className="align-middle" style={{ width: '8%' }}>{props.paymentMethod.Expiration}</td>
            <td className="text-center align-middle" style={{ width: '20%' }}>
                {props.paymentMethod.IsExpired ?
                    <>{myStrings.expired}</>
                    :
                    (props.paymentMethod.IsActive ?
                        <>{myStrings.active}</>
                        :
                        <>
                            {(props.paymentMethod.CanBeActivated) &&
                                <SetAsActiveLink paymentMethod={props.paymentMethod} activateFunc={props.activateFunc} onActivated={props.onChange} />
                            }
                        </>
                    )
                }
            </td>
            <td className="text-center align-middle" style={{ width: '6%' }}>
                {(!!props.paymentMethod.CanBeDeleted) &&
                    <DeleteLink paymentMethod={props.paymentMethod} deleteFunc={props.deleteFunc} onDeleted={props.onChange} />
                }
            </td>
        </tr>
    );
};

const BraintreeBrand: React.FC<TBrandComponentProps<IBraintreePaymentMethod>> = (props) => {
    return (
        <>{(!!props.paymentMethod.ImageUrl) && <img src={props.paymentMethod.ImageUrl} alt="" />}</>
    );
};

type TStripePaymentMethodBrandProps = TBrandComponentProps<IStripePaymentMethod> & {
    variant?: 'medium' | 'larger';
};

const css = mergeStyleSets({
    img: {
        height: '2rem',
        maxWidth: '4rem'
    },
    imgLarger: {
        height: '2.35rem',
        maxWidth: '6rem'
    },
    brand: {
    },
    iconContainer: {
        height: '1.8rem'
    },
    icon: {
        fontSize: '1.75rem'
    }
});

export const StripePaymentMethodBrand: React.FC<TStripePaymentMethodBrandProps> = (props) => {
    if (props.paymentMethod.ImageUrl)
        return (
            <>{(!!props.paymentMethod.ImageUrl) && <img src={props.paymentMethod.ImageUrl} alt="" className={props.variant === 'larger' ? css.imgLarger : css.img} />}</>
        );

    return (
        <>
            <div className={css.iconContainer}>
                <FontIcon iconName="PaymentCard" className={css.icon} />
            </div>
            <div>
                {props.paymentMethod.Brand}
            </div>
        </>
    );
};