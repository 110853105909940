import * as React from 'react';
import Strings from '../../../strings';
import { mergeStyleSets } from '@fluentui/react';
import { FolderFluentV9ThemeProvider, Spinner, SpinnerVariant, useLoginPopup, LOGIN_POPUP_BACKGROUND_GRADIENT, AppBuildModeHelper } from '@eway-crm/gui';
import * as eway from '../../../ewayapi';
import FolderNames from '../../../data/constants/FolderNames';
import APP_BUILD_MODE from '../../../AppBuildMode';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';

type TPhase = 'login';
type TPrompt = 'login';

type TOAuthFrameProps = {
    username: string | null;
    wsUrl: string | null;
    prompt: TPrompt | null;
    onLogIn: (accessToken: string, username: string) => void;
    isPremiseAzureAuth: boolean;
    onLoginPopupFoundClosed?: () => void;
};

const css = mergeStyleSets({
    iframe: {
        width: '100%',
        height: '100%',
        borderWidth: 0,
        padding: 0,
        display: 'block'
    },
    whiteBackground: {
        backgroundColor: '#FFFFFF',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundImage: LOGIN_POPUP_BACKGROUND_GRADIENT
    },
    centeredItem: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'transparent !important'
    },
    externalLoginImg: {
        position: 'relative',
        left: '2.0rem',
        width: '26rem'
    },
    externalLoginTitle: {
        fontSize: '18px',
        fontWeight: 700,
        marginTop: '34px',
        marginBottom: '20px'
    }
});

class Event {
    private listener?: () => void;

    readonly dispatch = () => this.listener?.();
    readonly set = (l: () => void) => this.listener = l;
    readonly unset = (l: () => void) => {
        if (this.listener === l) {
            this.listener = undefined;
        }
    };
}

const popupCloseEvent = new Event();

export const OAuthFrame: React.FC<TOAuthFrameProps> = ({ onLogIn, prompt, username, wsUrl, isPremiseAzureAuth, onLoginPopupFoundClosed }) => {
    const [isIframeLoaded, setIsIframeLoaded] = React.useState(false);
    const src = React.useMemo(() => getAuthUrl(username, wsUrl, prompt), [username, wsUrl, prompt]);
    const { loginBackgroundComponent, showPopup } = useLoginPopup('admin', src, popupCloseEvent.dispatch);

    const handleMessage = React.useCallback((event: MessageEvent<{ phase: TPhase | null; username: string | null; accessToken: string | null; state?: string }>) => {
        if (!event.data.phase)
            return;

        if (event.data.phase === 'login') {
            if (!event.data.accessToken || !event.data.username)
                return;
            onLogIn(event.data.accessToken, event.data.username);
        }
    }, [onLogIn]);

    const shouldUsePopup = React.useMemo(() => {
        return isPremiseAzureAuth || !AppBuildModeHelper.isPremise(APP_BUILD_MODE);
    }, [isPremiseAzureAuth]);

    const handleIframeLoaded = () => {
        setIsIframeLoaded(true);
    };

    React.useEffect(() => {
        window.addEventListener("message", handleMessage, false);

        if (shouldUsePopup && !LocalStorageHelper.getItem(LocalStorageHelper.names.doNotOpenLoginPopupNextTime)) {
            setTimeout(showPopup, 100);
        }

        return () => {
            window.removeEventListener("message", handleMessage);
        };
    }, [showPopup, handleMessage, shouldUsePopup]);

    // Register for popup closes event
    React.useEffect(() => {
        if (!shouldUsePopup || !onLoginPopupFoundClosed)
            return;

        popupCloseEvent.set(onLoginPopupFoundClosed);
        return () => popupCloseEvent.unset(onLoginPopupFoundClosed);
    }, [shouldUsePopup, onLoginPopupFoundClosed]);

    if (shouldUsePopup) {
        return (
            <div className={css.whiteBackground}>
                <FolderFluentV9ThemeProvider folderName={FolderNames.contacts} className={css.centeredItem}>
                    {loginBackgroundComponent}
                </FolderFluentV9ThemeProvider>
            </div>
        );
    }

    return (
        <>
            <iframe
                sandbox="allow-same-origin allow-scripts allow-popups allow-forms"
                className={css.iframe}
                title="auth"
                src={src}
                onLoad={handleIframeLoaded}
            />
            {(!isIframeLoaded) && (
                <Spinner backgroundOpacity={1} variant={SpinnerVariant.ease} />
            )}
        </>
    );
};

const getAuthUrl = (username: string | null, wsUrl: string | null, prompt: TPrompt | null): string => {
    const cloudHosted: boolean = !AppBuildModeHelper.isPremise(APP_BUILD_MODE);
    const adminAuthRoot = cloudHosted
        ? (import.meta.env.MODE === 'development' ? "http://127.0.0.1:8787" : "https://" + import.meta.env.VITE_CLOUD_APP_DOMAIN + "/auth/")
        : eway.getPremiseWsUrl() + '/Auth.aspx';

    let src = adminAuthRoot + '?phase=' + encodeURI('login') + '&client_id=' + encodeURIComponent('administrationwebapp') + '&lang=' + encodeURI(Strings.getLanguage());
    if (!!username) {
        src += '&username=' + encodeURI(username);
    }
    if (!!prompt) {
        src += '&prompt=' + encodeURI(prompt);
    }
    if (cloudHosted && !!wsUrl) {
        src += '&wsurl=' + encodeURI(wsUrl);
    }
    if (import.meta.env.MODE === 'development') {
        src += '&debugOrigin=' + encodeURIComponent(window.location.origin);
    }

    return src;
};