import * as React from 'react';
import Strings from '../../strings';
import type { IShimmerElement} from '@fluentui/react';
import { mergeStyleSets, Shimmer, ShimmerElementType } from '@fluentui/react';
import { VideoCarousel } from './home/VideoCarousel';
import { LoginActivity } from './home/LoginActivity';
import { BillingHistory } from './home/BillingHistory';
import type { ShoppingConnection } from '../ShoppingConnection';
import type { IFeaturedVideo, IAdminFeaturedVideosResponse } from '../../data/commonDataApi/IAdminFeaturedVideosResponse';
import type { CommonDataConnection } from '@eway-crm/connector';
import LocalStorageHelper from '../../helpers/LocalStorageHelper';
import type { IOrdersHistoryModel } from '../../data/shopping/IOrdersHistoryModel';

const myStrings = Strings.components.routes.home;

const contentMaxWidth = 1128;
const contentOffset = 40;
const paneMaxWidth = (contentMaxWidth + 2 * contentOffset) + 'px';
const paneOffset = contentOffset + 'px';
const css = mergeStyleSets({
    videoPane: {
        maxWidth: paneMaxWidth,
    },
    videoPaneHeading: {
        marginLeft: paneOffset,
        marginRight: paneOffset
    }
});

const videosShimmerElements: IShimmerElement[] = [
    { type: ShimmerElementType.gap, width: 40 },
    { type: ShimmerElementType.line, height: 180 },
    { type: ShimmerElementType.gap, width: 16 },
    { type: ShimmerElementType.line, height: 180 },
    { type: ShimmerElementType.gap, width: 16 },
    { type: ShimmerElementType.line, height: 180 },
    { type: ShimmerElementType.gap, width: 16 },
    { type: ShimmerElementType.line, height: 180, width: 79 },
    { type: ShimmerElementType.gap, width: 40 }
];

type THomeProps = {
    shoppingConnection: ShoppingConnection;
    commonDataConnection: CommonDataConnection;
    ordersHistoryCallback: (orderHistory: IOrdersHistoryModel) => void;
};

type THomeState = {
    videos: IFeaturedVideo[] | null;
};

export class Home extends React.Component<THomeProps, THomeState> {

    constructor(props: THomeProps) {
        super(props);
        const videosCache = LocalStorageHelper.getItem<IAdminFeaturedVideosResponse>(LocalStorageHelper.names.featuredVideosCacheKey);
        let videos: IFeaturedVideo[] | null = null;
        if (!!videosCache) {
            videos = Home.getVideos(videosCache);
        }
        this.state = {
            videos: videos
        };
    }

    componentDidMount() {
        this.props.commonDataConnection.isCommonDataApiEnabled(
            () => {
                this.props.commonDataConnection.callCommonDataApi(
                    'GetAdminFeaturedVideos',
                    {},
                    (response: IAdminFeaturedVideosResponse) => {
                        LocalStorageHelper.setItem<IAdminFeaturedVideosResponse>(LocalStorageHelper.names.featuredVideosCacheKey, response);
                        this.setState((prevState) => ({ videos: prevState.videos || Home.getVideos(response) }));
                    }
                );
            },
            () => {
                console.error('Common Data API is not enabled.');
            }
        );
    }

    render() {
        const grayPanelOuterColumnClassName = 'col-12 col-xxxxl-6 m-0 p-0';

        return (
            <div style={{ height: 'calc(100vh - 48px)', overflowY: 'auto', display: 'flex', flexDirection: 'column' }}>
                <div className="mx-1 mx-xl-5 ">
                    <div className="text-center my-5 my-xl-6 pt-3">
                        <h1 className="m-0">{myStrings.title}</h1>
                    </div>
                    <div className="my-5 mt-xl-6">
                        <div className={'mr-auto ml-auto ' + css.videoPane}>
                            <h3 className={css.videoPaneHeading}>{myStrings.featuredAdminVideos}</h3>
                            <Shimmer className={"mx-5"} isDataLoaded={!!this.state.videos} shimmerElements={videosShimmerElements}>
                                <VideoCarousel videos={this.state.videos || []} />
                            </Shimmer>
                        </div>
                    </div>
                </div>
                <div className="text-center bg-gray-150 p-5" style={{ flexGrow: 1 }}>
                    <div className="container text-left m-0 p-0" style={{ maxWidth: 'none' }}>
                        <div className="row m-0 p-0">
                            <div className={grayPanelOuterColumnClassName}>
                                <div className='p-2 mr-xxxxl-2 mb-xxxxl-0 mb-2'>
                                    <h3 className="mx-0 mx-xl-1 mt-0 mt-xl-1 mb-2 mb-xl-4 pb-1 pb-xl-2">{myStrings.billing}</h3>
                                    <div>
                                        <BillingHistory shoppingConnection={this.props.shoppingConnection} ordersHistoryCallback={this.props.ordersHistoryCallback} />
                                    </div>
                                </div>
                            </div>
                            <div className={grayPanelOuterColumnClassName}>
                                <div className='p-2 ml-xxxxl-2 mt-xxxxl-0 mt-2'>
                                    <h3 className="mx-0 mx-xl-1 mt-0 mt-xl-1 mb-2 mb-xl-4 pb-1 pb-xl-2">{myStrings.loginActivity}</h3>
                                    <div>
                                        <LoginActivity />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    private static getVideos(response: IAdminFeaturedVideosResponse): IFeaturedVideo[] | null {
        return Strings.pickTranslation(response);
    }
}