import React, { useRef, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontIcon } from '@fluentui/react';
import type { TPage } from './SearchResultsObtainer';
import LicenseRestrictionsLockIcon from '../../../shared/locks/LicenseRestrictionsLockedIcon';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';

type TSearchResultItem = {
    page: TPage;
    isFocused: boolean;
    isExternalLink: boolean;
    resultClick: (clickedResult: string) => void;
};

const SearchResultItem: React.FC<TSearchResultItem> = ({ page, isFocused, resultClick, isExternalLink }) => {
    const itemRef = useRef<HTMLAnchorElement>(null);
    const { showLicenseRestrictionModal } = useContext(ConnectionContext);
    const isLocked = page.licenseRestriction?.currentLimit === 0;

    useEffect(() => {
        if (isFocused) {
            itemRef.current?.focus();
        }
    }, [isFocused]);

    const handleClick: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
        if (isLocked && page.licenseRestriction) {
            e.preventDefault();
            showLicenseRestrictionModal(page.licenseRestriction);
        }

        resultClick(page.name);
    };

    const item = (
        <div className="searchbar__results__item">
            {page.iconName && <FontIcon iconName={page.iconName} className="searchbar__results__icon" />}
            {page.img && <img src={page.img} alt={page.name} className="searchbar__results__img" />}
            {page.addNewIcon && <FontIcon iconName={'Add'} className="searchbar__results__addNewIcon" />}
            {page.getAvatar && page.getAvatar()}
            <div className="searchbar__results__textWrap">
                <div className="searchbar__results__text">
                    {page.name} {isLocked && page.licenseRestriction && <LicenseRestrictionsLockIcon licenseRestriction={page.licenseRestriction} />}
                </div>
                {page.description && <div className="searchbar__results__description">{page.description}</div>}
            </div>
        </div>
    );

    if (isExternalLink) {
        return (
            <a key={page.link} href={page.link} target="_blank" rel="noreferrer noopener" className="searchbar__results__itemWrap" ref={itemRef} onClick={handleClick}>
                {item}
            </a>
        );
    }

    return (
        <Link key={page.link} to={page.link} className="searchbar__results__itemWrap" ref={itemRef} onClick={handleClick}>
            {item}
        </Link>
    );
};

export default SearchResultItem;
