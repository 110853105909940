import { mergeStyles } from '@fluentui/react';
import React from 'react';
import Strings from '../../../../strings';
import { ORB_ICON_SIZE } from '../../../shared/permissions/OrbDropdownEditCell';

const myStrings = Strings.components.routes.users;

export type TRowContentProps = {
    description: string;
    iconProps?: {
        icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
        fill: string;
    };
};

type TEffectivePermissionsTooltipRowProps = {
    isActivePermissionLevel: boolean;
    contentProps: TRowContentProps;
    groupName: string;
};


const EffectivePermissionsTooltipRow: React.FC<TEffectivePermissionsTooltipRowProps> = ({ isActivePermissionLevel, groupName, contentProps }) => {
    const Icon = contentProps.iconProps?.icon;
    return (
        <div className={mergeStyles('effective-permissions__tooltip-row', !isActivePermissionLevel && 'effective-permissions__tooltip-row--disabled')}>
            {Icon && <Icon className="effective-permissions__tooltip-icon" style={{ width: ORB_ICON_SIZE, height: ORB_ICON_SIZE, minWidth: ORB_ICON_SIZE, fill: contentProps.iconProps!.fill }} />}
            <div className="effective-permissions__tooltip-text-wrap">
                <div className="d-flex justify-content-between">
                    <div className="effective-permissions__tooltip-groupname">{groupName}</div>
                    {isActivePermissionLevel && <div className="text-success">{myStrings.affects}</div>}
                </div>
                <div className="effective-permissions__tooltip-description">{contentProps.description}</div>
            </div>
        </div>
    );
};

export default EffectivePermissionsTooltipRow;
