import React from 'react';
import { useLocation } from 'react-router-dom';

const EK_URL_PARAM_NAME = 'ek';

const useScrollToMarketingPackageRowByUrlParam = (isEnabled: boolean) => {
    const rowRef = React.useRef<HTMLDivElement>(null);
    const [isRowHighlighted, setIsRowHighlighted] = React.useState(false);
    const [isTooltipVisible, setIsTooltipVisible] = React.useState(false);
    const [wasTooltipShown, setWasTooltipShown] = React.useState(false);
    const location = useLocation();

    React.useEffect(() => {
        if (!isEnabled || wasTooltipShown) {
            return;
        }

        const urlParams = new URLSearchParams(location.search);
        const hasEkParam = urlParams.has(EK_URL_PARAM_NAME);

        if (!hasEkParam) {
            return;
        }

        rowRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });

        setWasTooltipShown(true);
        setTimeout(() => {
            // Wait for scroll to finish
            setIsRowHighlighted(true);
            setIsTooltipVisible(true);
            setTimeout(() => {
                // Hide highlight after a while
                setIsRowHighlighted(false);
            }, 800);
        }, 400);
    }, [location.search, isEnabled, wasTooltipShown]);

    return {
        rowRef,
        isRowHighlighted,
        isTooltipVisible,
        setIsTooltipVisible,
    };
};

export default useScrollToMarketingPackageRowByUrlParam;
