import React from 'react';
import { CSSTransition } from 'react-transition-group';
import type { IApiGroup } from '@eway-crm/connector';
import Strings from '../../../strings';
import FoldersMenu from '../../shared/FoldersMenu';
import GroupsListMenu from '../../shared/GroupsListMenu';
import type { TFeature } from '../Features';
import FieldPermissionsDataTransformer from '../fields/FieldPermissionsDataTransformer';
import FieldPermissionsWarningDialog from '../fields/FieldPermissionsWarningDialog';
import splashImg from '../../../img/splash/key-splash.svg';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import FieldPermissionsGrid from '../fields/FieldPermissionsGrid';
import { GroupNames } from '../../../data/constants/GroupNames';
import Alert from '../../shared/Alert';
import { Spinner as BsSpinner } from 'react-bootstrap';
import type { IApiColumn } from '@eway-crm/connector';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TFeatureFieldPermissionsTabProps = {
    searchedString: string;
    selectedFeature: TFeature;
    onMenuSelectionChange: (selectedGroup: IApiGroup | null, selectedFolderName: string | null) => void;
    groups: IApiGroup[];
    folders: string[] | null;
    fieldPermissions: TFieldPermissionWithCol[] | null;
    onMount: () => Promise<void>;
    columns: IApiColumn[] | null;
    onFieldPermissionsChange: (newFieldPermissions: TFieldPermissionWithCol[], newItemToSave: TFieldPermissionWithCol) => void;
    selectedGroup: IApiGroup | null;
    selectedFolderName: string | null;
};

type TFeatureFieldPermissionsTabState = {
    groupsMenuSearchedString: string;
    modulesMenuSearchedString: string;
    uniqueNotFullyPermittedForGroupName: string | null;
    isCheckingForDuplicatedValues: boolean;
    nonUniqueValue: string | null;
};

const myStrings = Strings.components.routes.fieldPermissions;

export default class FeatureFieldPermissionsTab extends React.Component<TFeatureFieldPermissionsTabProps, TFeatureFieldPermissionsTabState> {
    constructor(props: TFeatureFieldPermissionsTabProps) {
        super(props);
        this.state = {
            groupsMenuSearchedString: '',
            modulesMenuSearchedString: '',
            uniqueNotFullyPermittedForGroupName: null,
            isCheckingForDuplicatedValues: false,
            nonUniqueValue: null,
        };
    }

    componentDidMount() {
        void this.props.onMount();
    }

    private readonly handleMenuSelectionChange = (newSelectedGroup?: IApiGroup, newSelectedFolderName?: string) => {
        const selectedGroup = newSelectedGroup ?? this.props.selectedGroup;
        const selectedFolderName = newSelectedFolderName ?? this.props.selectedFolderName;
        this.props.onMenuSelectionChange(selectedGroup, selectedFolderName);
    };

    render() {
        if (!this.props.groups || !this.props.columns) {
            return <Spinner variant={SpinnerVariant.ease} />;
        }
        return (
            <div className="d-flex flex-fill">
                <div className="border-right border-gray bg-white secondary-menu" style={{ width: '265px' }}>
                    <GroupsListMenu
                        items={this.props.groups.map((g) => ({ itemId: g.ItemGUID, itemName: g.GroupName, itemDescription: g.ResponsibilityDescription }))}
                        onItemChange={(newItemId) => {
                            this.handleMenuSelectionChange(this.props.groups.find((g) => g.ItemGUID === newItemId));
                        }}
                        selectedItemId={this.props.selectedGroup?.ItemGUID ?? null}
                        searchedString={''}
                        isCollapsed={false}
                    />
                </div>
                {!this.props.selectedGroup ? (
                    <div className="d-flex flex-column flex-fill position-relative">
                        <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img className="d-inline-block" src={splashImg} style={{ height: '160px', width: '100%' }} alt="" />
                        </div>
                    </div>
                ) : (
                    <CSSTransition in={!!this.props.selectedGroup} appear={!this.props.selectedFolderName} timeout={400} classNames="secondary-menu">
                        <div className="secondary-menu--small border-right border-gray" style={{ width: '265px' }}>
                            <FoldersMenu
                                folderNames={this.props.folders!}
                                selectedFolderName={this.props.selectedFolderName}
                                searchedString={this.state.modulesMenuSearchedString}
                                onFolderChange={(newFolderName) => {
                                    this.handleMenuSelectionChange(undefined, newFolderName);
                                }}
                                variant="small"
                            />
                        </div>
                    </CSSTransition>
                )}
                {!this.props.fieldPermissions && this.props.selectedFolderName && this.props.selectedGroup ? (
                    <div className="position-relative flex-fill">
                        <Spinner variant={SpinnerVariant.ease} />
                    </div>
                ) : (
                    this.props.fieldPermissions && (
                        <div className="position-relative flex-fill">
                            <div className="h-100 position-absolute" style={{ maxHeight: '100%', minHeight: '0', top: '0', bottom: '0', left: '0', right: '0' }}>
                                <FieldPermissionsDataTransformer
                                    allGroups={this.props.groups}
                                    fieldPermissions={this.props.fieldPermissions}
                                    onFieldPermissionsChange={this.props.onFieldPermissionsChange}
                                    onUniqueNotFullyPermittedForGroupName={(uniqueNotFullyPermittedForGroupName: string | null) => {
                                        this.setState({ uniqueNotFullyPermittedForGroupName });
                                    }}
                                    onCheckForDuplicateValues={(isCheckingForDuplicatedValues: boolean, nonUniqueValue: string | null, callback?: () => void) => {
                                        this.setState({ isCheckingForDuplicatedValues, nonUniqueValue }, callback);
                                    }}
                                    selectedFolderName={this.props.selectedFolderName}
                                    selectedGroup={this.props.selectedGroup!}
                                    render={({ fieldPermissions, onFieldPermissionsChange, onMandatoryPermissionsChange }) => (
                                        <FieldPermissionsGrid
                                            searchedString={''}
                                            disableEditing={this.state.isCheckingForDuplicatedValues || this.props.selectedGroup!.DisallowControlColumnPermissions}
                                            fieldPermissions={fieldPermissions}
                                            onFieldPermissionChange={onFieldPermissionsChange}
                                            onMandatoryPermissionChange={onMandatoryPermissionsChange}
                                            systemGroupGuid={this.props.groups.find((group) => group.GroupName === GroupNames.system)!.ItemGUID}
                                            selectedGroup={this.props.selectedGroup!}
                                        />
                                    )}
                                />
                            </div>
                        </div>
                    )
                )}
                {this.state.isCheckingForDuplicatedValues && (
                    <Alert variant="warning" style={{ right: 10 }}>
                        <BsSpinner size="sm" animation="border" />
                        <span className="pl-2">{myStrings.checkingForDuplicatedValues}</span>
                    </Alert>
                )}
                {(this.state.nonUniqueValue !== null || this.state.uniqueNotFullyPermittedForGroupName !== null) && (
                    <FieldPermissionsWarningDialog
                        nonUniqueValue={this.state.nonUniqueValue}
                        uniqueNotFullyPermittedForGroupName={this.state.uniqueNotFullyPermittedForGroupName}
                        onHide={() => {
                            this.setState({ nonUniqueValue: null, uniqueNotFullyPermittedForGroupName: null });
                        }}
                    />
                )}
            </div>
        );
    }
}
