import React from 'react';
import { Navbar } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import RouteConfig from '../../../RouteConfig';
import Strings from '../../../strings';
import SearchBar from '../../routes/home/SearchBar/SearchBar';
import UserProfile from '../connection/UserProfile';
import logoImg from '@eway-crm/js-resources/assets/ewayLogo/eWayCRM/eWayCRM-white.svg';
import './TopNavbar.scss';

const TopNavbar: React.FC = () => {
    return (
        <div className="row">
            <div className="flex-grow-1 p-0">
                <Navbar bg="dark" variant="dark" className="py-0 pl-2">
                    <Navbar.Brand className="py-0">
                        <NavLink to={RouteConfig.root.path} exact={true} className="d-flex align-items-center text-decoration-none">
                            <div className={'top-navbar__logo'}>
                                <img src={logoImg} alt="eWay-CRM" />
                            </div>
                            <div className={'top-navbar__title d-inline-block my-0 h-100'}>{Strings.components.mainComponent.title}</div>
                        </NavLink>
                    </Navbar.Brand>
                    <div className="flex-fill ml-4 mr-3">
                        <SearchBar />
                    </div>
                    <Navbar.Collapse className="justify-content-end flex-grow-0 top-navbar__collapse">
                        <UserProfile />
                    </Navbar.Collapse>
                </Navbar>
            </div>
        </div>
    );
};

export default TopNavbar;
