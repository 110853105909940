import { mergeStyles } from '@fluentui/react';
import React from 'react';
import './Toggle.scss';

type TToggleProps = React.HTMLProps<HTMLInputElement>;

const Toggle: React.FC<TToggleProps> = ({disabled, checked, onChange, label, className, ...restProps}) => {
    return (
        <div className={mergeStyles("toggle__wrap", className)} {...restProps}>
            <label className="toggle">
                <input type="checkbox" disabled={disabled} checked={checked} onChange={onChange} />
                <span className={mergeStyles('toggle__slider', disabled && 'disabled')} />
            </label>
            <div className={mergeStyles('toggle__label', disabled && 'disabled')}>{label}</div>
        </div>
    );
};

export default Toggle;
