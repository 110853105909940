import * as React from 'react';
import { Button, Form } from 'react-bootstrap';
import Strings from '../../../strings';

type TEmailsGridTableProps = {
    emails: string[];
    isAdding: boolean;
    onAdded: (address: string) => void;
    onRemoved: (address: string) => void;
};

export const EmailsGridTable: React.FC<TEmailsGridTableProps> = (props) => {
    const myStrings = Strings.components.routes.billingDetails;

    return (
        <div className="grid-table">
            <table className="table">
                <thead>
                    <tr>
                        <th>{myStrings.email}</th>
                        <th className="text-center" style={{ width: '6%' }}>&shy;</th>
                    </tr>
                </thead>
                <tbody>
                    {props.emails.map((email, i) => {
                        return (
                            <tr key={'billing-email-' + i}>
                                <td className="align-middle highlighted">{email}</td>
                                <td>
                                    <Button variant="link" onClick={() => props.onRemoved(email)}>{myStrings.delete}</Button>
                                </td>
                            </tr>
                        );
                    })}
                    {(props.isAdding) &&
                        <NewEmailRow onAdded={props.onAdded} />
                    }
                </tbody>
            </table>
        </div>
    );
};

const NewEmailRow: React.FC<{ onAdded: (address: string) => void }> = (props) => {
    const [address, setAddress] = React.useState<string>('');
    const [isFormValidated, setIsFormValidated] = React.useState<boolean>(false);
    const formRef = React.useRef<HTMLFormElement>(null);

    const save = () => {
        setIsFormValidated(true);
        if (!formRef.current || !formRef.current.checkValidity() || !address)
            return;

        props.onAdded(address);
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            save();
        }
    };

    return (
        <tr>
            <td>
                <Form ref={formRef} validated={isFormValidated}>
                    <Form.Control autoFocus required type="email" value={address} onChange={(e) => setAddress(e.target.value)} onKeyDown={handleKeyDown} />
                </Form>
            </td>
            <td>
                <Button variant="link" onClick={save}>{Strings.save}</Button>
            </td>
        </tr>
    );
};