import type { IApiColumn, IApiColumnPermission, IApiDataResponse, IApiGroup } from '@eway-crm/connector';
import { TooltipHost } from '@fluentui/react';
import React, { useContext, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import FieldPermissionNames from '../../../../data/constants/FieldPermissionNames';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import Strings from '../../../../strings';
import Fields from '../../Fields';
import FieldPermissionsDataTransformer from '../../fields/FieldPermissionsDataTransformer';
import type { TFieldPermissionsCellWrapperProps, TFieldPermissionWithCol } from '../../fields/FieldPermissionsGrid';
import { fieldPermissionsOptionsSettings } from '../../fields/FieldPermissionsGrid';
import EffectivePermissionsTooltipContainer from './EffectivePermissionsTooltipContainer';
import type { TRowContentProps } from './EffectivePermissionsTooltipRow';
import EffectivePermissionsTooltipRow from './EffectivePermissionsTooltipRow';

const myStrings = Strings.components.routes.users;
const myFieldPermissionsStrings = Strings.components.routes.fieldPermissions.columns;

const mandatoryRuleKeysOffNames = {
    IsMandatory: myFieldPermissionsStrings.isNotMandatory,
    IsImportant: myFieldPermissionsStrings.isNotOptional,
    IsUnique: myFieldPermissionsStrings.isNotUnique,
} as const;

export type TFieldPermissionsCellTooltipAdditionalProps = {
    userGroups: IApiGroup[];
    columns: IApiColumn[];
    systemHealthNotificationGroupGuid: string | null;
};

type TEffectiveFieldPermissionsCellTooltipProps = TFieldPermissionsCellWrapperProps & TFieldPermissionsCellTooltipAdditionalProps & React.PropsWithChildren;

const EffectiveFieldPermissionsCellTooltip: React.FC<TEffectiveFieldPermissionsCellTooltipProps> = ({ children, data, userGroups, columns, systemHealthNotificationGroupGuid }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [permissionsData, setPermissionsData] = useState<TFieldPermissionWithCol[] | null>(null);
    const { connection } = useContext(ConnectionContext);

    const getPermissionsData = async () => {
        const folderName = data!.row.FolderName;

        if (data!.row.rowPermissions) {
            return data!.row.rowPermissions;
        }

        const colPermissionsRes = await connection.askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
            includeDefaultPermissions: true,
            transmitObject: { FolderName: folderName, ColumnName: data!.row.ColumnName },
        });

        const fieldPermissions = colPermissionsRes.Data.filter((colPermission) => colPermission.GroupGuid !== systemHealthNotificationGroupGuid).map((colPermission) => {
            const column = columns.find((c) => c.ColumnName === colPermission.ColumnName && c.FolderName === colPermission.FolderName)!;
            return {
                ...colPermission,
                col: column,
                colName: Fields.getColumnName(column),
            };
        });
        const fieldPermissionsFinal = FieldPermissionsDataTransformer.getFieldPermissionsWithMandatoryRuleInMultipleCols(fieldPermissions);

        data!.row.rowPermissions = fieldPermissionsFinal; // Save to row to avoid unnecessary rerenders through state update
        return fieldPermissionsFinal;
    };

    const onTooltipToggle = async (isVisible: boolean) => {
        if (isVisible && !permissionsData) {
            setIsLoading(true);
            const permissions = await getPermissionsData();
            setIsLoading(false);
            setPermissionsData(permissions);
        }
    };

    const getTooltipContent = () => {
        if (!permissionsData) {
            return null;
        }

        let columnName = data!.column.name as keyof TFieldPermissionWithCol;

        if (data?.column.name === FieldPermissionNames.IsMandatory && data.row.IsUnique) {
            // IsMandatory field is overridden by IsUnique
            columnName = FieldPermissionNames.IsUnique;
        }

        let sortedFieldPermissions: TFieldPermissionWithCol[] = [];
        let rowContentPropsGetter: (fieldPermission: TFieldPermissionWithCol) => TRowContentProps;
        let description: React.ReactNode | null = null;

        if (columnName === FieldPermissionNames.PermissionRule) {
            sortedFieldPermissions = permissionsData;
            sortedFieldPermissions = permissionsData.sort((a, b) => {
                const order = Object.keys(FieldPermissionNames.PermissionRuleKeys);
                return order.indexOf(b[columnName]) - order.indexOf(a[columnName]);
            });
            description = (
                <>
                    {myStrings.presenceInGroups} <strong>{myStrings.lowest}</strong> {myStrings.permissionAffectsAbility} <strong>{myStrings.workWithField}</strong>.
                </>
            );
            rowContentPropsGetter = (fieldPermission) => {
                const settingsKey = fieldPermission[columnName];
                const optionSettings = fieldPermissionsOptionsSettings[settingsKey as keyof typeof fieldPermissionsOptionsSettings];

                return { description: optionSettings.text(), iconProps: { icon: optionSettings.icon, fill: optionSettings.color } };
            };
        } else if ([FieldPermissionNames.IsImportant, FieldPermissionNames.IsMandatory, FieldPermissionNames.IsUnique].includes(columnName)) {
            sortedFieldPermissions = permissionsData.sort((a, b) => {
                return Number(b[columnName] as boolean) - Number(a[columnName] as boolean);
            });
            rowContentPropsGetter = (fieldPermission) => {
                const description = fieldPermission[columnName] ? data!.column.title! : mandatoryRuleKeysOffNames[columnName as keyof typeof mandatoryRuleKeysOffNames];
                return { description };
            };
        }

        const content = sortedFieldPermissions
            .map((mp) => {
                const isActivePermissionLevel = data!.row[columnName] === mp[columnName];
                const groupName = userGroups.find((group) => group.ItemGUID === mp.GroupGuid)?.GroupName;
                if (groupName) {
                    return <EffectivePermissionsTooltipRow key={groupName} isActivePermissionLevel={isActivePermissionLevel} groupName={groupName} contentProps={rowContentPropsGetter(mp)} />;
                }
                return null;
            })
            .filter((c) => !!c);

        return <EffectivePermissionsTooltipContainer description={description}>{content}</EffectivePermissionsTooltipContainer>;
    };

    return (
        <TooltipHost
            calloutProps={{ gapSpace: 2, calloutMaxWidth: 500 }}
            tooltipProps={{ onRenderContent: () => (isLoading ? <Spinner size="sm" animation="border" /> : getTooltipContent()) }}
            onTooltipToggle={(isVisible) => void onTooltipToggle(isVisible)}
        >
            {children}
        </TooltipHost>
    );
};

export default EffectiveFieldPermissionsCellTooltip;
