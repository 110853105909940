import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button } from 'react-bootstrap';
import FolderNames from '../../../data/constants/FolderNames';
import RelationTypes from '../../../data/constants/RelationTypes';
import WizardModal from '../../WizardModal';
import { GroupWizardBase } from './GroupWizardBase';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiGroup } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import { DelayedRender } from '@fluentui/react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

const dialogBodyExpectedHeight = '40rem';

type TAssignUsersWizardProps = {
    onDismiss: () => void;
    selectedGroups: IApiGroup[];
    availableUsers: IApiUser[];
    onDone: () => void;
};

type TAssignUsersWizardState = {
    usersSelection: string[];
    usersSearchedString: string;
    isSavingInProgress: boolean;
};

export class AssignUsersWizard extends React.Component<TAssignUsersWizardProps, TAssignUsersWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TAssignUsersWizardProps) {
        super(props);
        this.state = {
            usersSelection: [],
            usersSearchedString: '',
            isSavingInProgress: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly changeSelection = (selection: string[]) => {
        this.setState({ usersSelection: selection });
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                if (this.state.usersSelection.length === 0 || this.props.selectedGroups.length === 0) {
                    this.props.onDone();
                } else {
                    const groupRelations = this.props.selectedGroups.flatMap((grp) => this.state.usersSelection.map((userGuid) => {
                        return {
                            ItemGUID1: userGuid,
                            ItemGUID2: grp.ItemGUID,
                            FolderName1: FolderNames.users,
                            FolderName2: FolderNames.groups,
                            RelationType: RelationTypes.group,
                            DifferDirection: false
                        };
                    }));

                    this.context.connection.callApi(
                        'SaveRelations',
                        {
                            transmitObjects: groupRelations
                        },
                        (_) => {
                            this.props.onDone();
                        }
                    );
                }
            }
        );
    };

    render() {
        const myStrings = Strings.components.routes.groups;

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            return (
                <WizardModal show={true} size="xl" onHide={this.dismiss} dialogBodyExpectedHeight={dialogBodyExpectedHeight}>
                    <WizardModal.Body>
                        <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                            <div className="row">
                                <div className="col mb-4">
                                    <h1>{myStrings.assignUsers}</h1>
                                    <div>
                                        {this.props.selectedGroups.map((user, i) =>
                                            <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                                {user.GroupName}
                                            </span>
                                        )}
                                    </div>
                                </div>
                                <div className="col-auto">
                                    <Form.Control type="text" placeholder={Strings.search} value={this.state.usersSearchedString} onChange={(e) => this.setState({ usersSearchedString: e.target.value })} />
                                </div>
                            </div>
                            <div className="row flex-fill d-flex justify-content-start">
                                <div className="col">
                                    <DelayedRender>
                                        <GroupWizardBase.UsersPicker
                                            showHeading={false}
                                            showSearch={false}
                                            usersSelection={this.state.usersSelection}
                                            onUsersSelectionChange={this.changeSelection}
                                            usersSearchedString={this.state.usersSearchedString}
                                            onUsersSearchedStringChange={(str) => { this.setState({ usersSearchedString: str }); }}
                                            availableUsers={this.props.availableUsers}
                                            currentUserGuid={this.context.connection.state.session!.userGuid}
                                        />
                                    </DelayedRender>
                                </div>
                            </div>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" disabled={this.state.usersSelection.length === 0} onClick={this.submit}>{myStrings.assign}</Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}