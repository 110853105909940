import { useState, useEffect, useRef, useContext } from 'react';
import { Search, SimpleTokenizer } from 'js-search';
import SearchResultsObtainer from './SearchResultsObtainer';
import { CustomStopWordsTokenizer } from './CustomStopWordsTokenizer';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';

const useGetSearchResults = (isResultOpen: boolean) => {
    const [isLoading, setIsLoading] = useState(false);
    const jsSearch = useRef<Search>(null!);
    const context = useContext(ConnectionContext);

    useEffect(() => {
        const loadData = async () => {
            setIsLoading(true);
            const ResultsObtainer = new SearchResultsObtainer(context);
            await ResultsObtainer.loadAllSearchData();
            const searchResults = ResultsObtainer.getFinalizedResults();
            jsSearch.current = new Search('searchHelper');
            const search = jsSearch.current;
            search.tokenizer = new CustomStopWordsTokenizer(new SimpleTokenizer());
            search.addIndex('enName');
            search.addIndex('searchHelper');
            search.addDocuments(searchResults);
            setIsLoading(false);
        };

        if ((SearchResultsObtainer.getShouldReloadOnNextSearch() && isResultOpen) || (!isLoading && !jsSearch.current)) {
            void loadData();
        }
    }, [isResultOpen, isLoading, context]);

    return { jsSearch, isLoading };
};

export default useGetSearchResults;
