import { Dialog, DialogFooter } from '@fluentui/react';
import { useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ISubscriptionRequiresActionPaymentIntentModel } from '../../../data/shopping/ICheckoutSubmittedModel';
import Strings from '../../../strings';
import { StripeClientSecretContext, StripeElementsContainer } from '../paymentGate/StripeElementsContainer';
import { SpinnerModal } from '../SpinnerModal';
import type { ShoppingConnection } from '../../ShoppingConnection';
import { WindowHelper } from '@eway-crm/gui';
import { Link } from 'react-router-dom';
import RouteConfig from '../../../RouteConfig';
import IframePostMessageHelper from '../../../helpers/IframePostMessageHelper';
import { SpinnerVariant } from '@eway-crm/gui';

// Make overlay transparent if not in iframe, since there are 2 overlays overlapping each other
const ERROR_DIALOG_PROPS = { isBlocking: true };
const IN_OTHER_ERROR_DIALOG_PROPS = { isBlocking: true, overlay: !WindowHelper.isInIframe() ? { styles: { root: { backgroundColor: 'transparent !important' } } } : undefined };

type TStripeCheckoutRequiredActionHandlerProps = {
    shoppingConnection: ShoppingConnection;
    requiredActionModel: ISubscriptionRequiresActionPaymentIntentModel;
    continueComponent?: JSX.Element;
    onDone?: () => Promise<void>;
    isInAnotherModal?: boolean;
};

export const StripeRequiredActionHandler: React.FC<TStripeCheckoutRequiredActionHandlerProps> = (props) => {
    const { requiredActionModel, continueComponent, onDone, isInAnotherModal } = props;
    const initData = React.useMemo(() => ({ publicApiKey: requiredActionModel.PublicApiKey, clientSecret: requiredActionModel.ClientSecret }), [requiredActionModel]);

    return (
        <StripeElementsContainer
            shoppingConnection={props.shoppingConnection}
            initData={initData}
            loadingComponent={isInAnotherModal ? <SpinnerModal.CenteredContent variant={SpinnerVariant.ease} /> : <SpinnerModal variant={SpinnerVariant.ease} />}
        >
            <Inner paymentMethodId={requiredActionModel.PaymentMethodId} continueComponent={continueComponent} onDone={onDone} isInAnotherModal={isInAnotherModal} />
        </StripeElementsContainer>
    );
};

type TInnerProps = {
    paymentMethodId: string;
    continueComponent?: JSX.Element;
    onDone?: () => Promise<void>;
    isInAnotherModal?: boolean;
};

const Inner: React.FC<TInnerProps> = (props) => {
    const clientSecret = React.useContext(StripeClientSecretContext);
    const { paymentMethodId, onDone, isInAnotherModal } = props;
    const stripe = useStripe();
    const [isDone, setIsDone] = React.useState<boolean>(false);
    const [error, setError] = React.useState<StripeError | null>(null);
    const [isErrorOked, setIsErrorOked] = React.useState<boolean>(false);
    const isInIframe = WindowHelper.isInIframe();

    React.useEffect(() => {
        if (isDone)
            return;

        if (!!stripe) {
            (async () => {
                const result = await stripe.confirmCardPayment(clientSecret, {
                    payment_method: paymentMethodId
                });

                if (result.error) {
                    console.error('Stripe payment confirming failed for subscription.', result.error);
                    setError(result.error);
                } else {
                    setError(null);
                }
                setIsDone(true);
            })()
                .catch((err) => console.error('Unable confirm stripe payment.', err));
        }
    }, [isDone, setIsDone, setError, stripe, clientSecret, paymentMethodId]);

    const myStrings = Strings.components.routes.subscriptions;

    const onErrorOk = React.useCallback(() => {
        if (isInIframe) {
            IframePostMessageHelper.windowClose();
            return;
        }
        setIsErrorOked(true);
    }, [setIsErrorOked, isInIframe]);

    const errorDialogProps = React.useMemo(
        () => ({ showCloseButton: false, title: myStrings.initPaymentFailedTitle, subText: (Strings.formatString(myStrings.initPaymentFailedMessageFormat, error?.message || '') as string) }),
        [error, myStrings.initPaymentFailedTitle, myStrings.initPaymentFailedMessageFormat]
    );

    React.useEffect(() => {
        if (isDone && (!error || isErrorOked)) {
            // We are done.
            if (onDone) {
                void onDone();
            }
        }
    }, [isDone, error, isErrorOked, onDone]);

    if (!isDone) {
        if (isInAnotherModal)
            return (<SpinnerModal.CenteredContent variant={SpinnerVariant.linear} />);
        else
            return (<SpinnerModal variant={SpinnerVariant.linear} />);
    }

    if (!error || isErrorOked) {
        // We are done.
        if (!!props.continueComponent)
            return props.continueComponent;

        return <></>;
    }

    return (
        <Dialog modalProps={isInAnotherModal ? IN_OTHER_ERROR_DIALOG_PROPS : ERROR_DIALOG_PROPS} dialogContentProps={errorDialogProps} hidden={false}>
            <DialogFooter>
                {isInIframe ? (
                    <Link to={RouteConfig.billing.subscriptions.path} target="_blank" rel="noopener noreferrer">
                        <Button variant="warning" onClick={onErrorOk}>
                            {myStrings.goToAdminCenter}
                        </Button>
                    </Link>
                ) : (
                    <Button variant="warning" onClick={onErrorOk}>
                        {Strings.ok}
                    </Button>
                )}
            </DialogFooter>
        </Dialog>
    );
};