import type { IContextualMenuProps } from '@fluentui/react';
import { CommandButton } from '@fluentui/react';
import React from 'react';
import Strings from '../../../strings';

const myStrings = Strings.components.routes.fields.dropDowns;
const MORE_ICON = { iconName: 'MoreVertical' };

type THideColumnDropdownProps = {
    colName: string;
    onToggleDisplayLanguageColumn: (lanugage: string) => Promise<void>;
};

const HideColumnDropdown: React.FC<THideColumnDropdownProps> = ({ onToggleDisplayLanguageColumn, colName }) => {
    const menuProps: IContextualMenuProps = {
        items: [
            {
                key: 'hideEvent',
                text: myStrings.hideColumn,
                onClick: () => {
                    void onToggleDisplayLanguageColumn(colName);
                },
                iconProps: { iconName: 'Hide3', style: { color: 'inherit' } },
            },
        ],
    };

    return <CommandButton iconProps={MORE_ICON} menuProps={menuProps} className="p-0 border-0 invisible d-parent-td-hover-visible moreButtonDropdown" />;
};

export default HideColumnDropdown;
