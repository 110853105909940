import * as React from 'react';

export type TNumberFormatterMode = 'plain' | 'metric';

type TNumberFormatterProps = {
    number: number | null | undefined;
    mode: TNumberFormatterMode;
};

export class NumberFormatter extends React.Component<TNumberFormatterProps> {

    static format(number: number | null | undefined, mode: TNumberFormatterMode) {
        if (!number && number !== 0)
            return null;

        let result: number | string;
        switch (mode) {
            case 'plain':
                result = number;
                break;
            case 'metric':
                if (Math.abs(number) >= 1000000) {
                    result = Math.round(number / 1000000) + 'M';
                } else if (Math.abs(number) >= 1000) {
                    result = Math.round(number / 1000) + 'k';
                } else {
                    result = number;
                }
                break;
        }

        return result;
    }

    render() {
        const formatted = NumberFormatter.format(this.props.number, this.props.mode);
        if (!formatted && formatted !== 0)
            return null;

        return <>{formatted}</>;
    }
}