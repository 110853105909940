import { Label } from '@fluentui/react';
import React from 'react';
import Strings from '../../../strings';
import { ORB_ICON_SIZE } from './OrbDropdownEditCell';
import './PermissionsLegend.scss';

type TPermissionsLegend = {
    optionsSettings: { [key: string]: { key: string; text: (action: string) => string; description: string; color: string; icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>> } };
};

const PermissionsLegend: React.FC<TPermissionsLegend> = ({optionsSettings}) => {
    return (
        <>
            <div className="d-flex">
                <Label className="mr-2">{Strings.components.routes.modulePermissions.legend}</Label>
                <hr className="m-0 mt-3 flex-grow-1 mp-legend__line" />
            </div>

            <div className="d-flex mp-legend__itemsContainer">
                <div className="d-flex flex-column flex-wrap flex-grow-1 justify-content-between">
                    {Object.entries(optionsSettings).map(([key, val]) => {
                        const Icon = val.icon;
                        return (
                            <div key={key}>
                                <Icon style={{ width: ORB_ICON_SIZE, height: ORB_ICON_SIZE, marginRight: '0.4rem', fill: val.color }} />
                                {val.description}
                            </div>
                        );
                    })}
                </div>
            </div>
        </>
    );
};

export default PermissionsLegend;
