import * as React from 'react';
import stepActiveImg from '../img/steps/stepActive.svg';
import stepInactiveImg from '../img/steps/stepInactive.svg';
import stepCheckedImg from '../img/steps/stepChecked.svg';
import stepLineActiveImg from '../img/steps/stepLineActive.svg';
import stepLineInactiveImg from '../img/steps/stepLineInactive.svg';
import { FontIcon } from '@fluentui/react';

type StepMarkProps = {
    currentStepNumber: number;
    lastStepNumber: number;
    number: number;
    name: string;
    onStepPicked: (number: number) => void;
    isLocked: boolean;
};

const StepMark = (props: StepMarkProps) => {
    const isChecked = props.currentStepNumber > props.number;
    const isActive = props.currentStepNumber === props.number;
    const showLineDown = props.lastStepNumber > props.number;
    return (
        <div className={'container m-0 cursor-pointer step-mark' + (isActive ? ' step-mark-active' : '')} onClick={() => props.onStepPicked(props.number)}>
            <div className="row align-items-center">
                <div className="col-auto p-0">
                    <img src={isChecked ? stepCheckedImg : (isActive ? stepActiveImg : stepInactiveImg)} alt="" className="d-block m-auto step-mark-point" />
                </div>
                <div className="col-auto px-2">
                    <div className="align-middle step-mark-caption">
                        {props.name} {props.isLocked && <FontIcon iconName="LockSolid" className="small" />}
                    </div>
                </div>
            </div>
            {showLineDown &&
                <div className="row">
                    <div className="col-auto px-0 py-1">
                        <img src={isChecked ? stepLineActiveImg : stepLineInactiveImg} alt="" className="step-mark-bar" />
                    </div>
                </div>
            }
        </div>
    );
};

type WizardStepsProps = {
    steps: string[];
    onStepPicked: (number: number) => void;
    currentStep: number;
    lockedSteps?: string[];
};

class WizardSteps extends React.Component<WizardStepsProps> {

    private lastStep: number;

    constructor(props: WizardStepsProps) {
        super(props);
        this.lastStep = props.steps.length;
    }

    goToStep = (number: number) => {
        this.props.onStepPicked(number);
    };

    render() {
        return (
            <div className="wizard-steps">
                {this.props.steps.map((step, index) =>
                    <StepMark key={'step-mark-' + index} number={index + 1} currentStepNumber={this.props.currentStep} lastStepNumber={this.lastStep} name={step} isLocked={!!this.props.lockedSteps && this.props.lockedSteps.includes(step)} onStepPicked={this.goToStep} />
                )}
            </div>
        );
    }
}

export default WizardSteps;