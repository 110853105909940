import * as React from 'react';
import { Form, Col } from 'react-bootstrap';
import { SelectionState, IntegratedSelection, SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, TableSelection, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import Strings from '../../../strings';
import StringHelper from '../../../helpers/StringHelper';
import GridTable from '../../GridTable';
import type { IApiGroup } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiLocalizedLicenseBundle } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import GroupBadgeFlags from '../groups/GroupBadgeFlags';
import type { IApiLicenseBundleBase } from '@eway-crm/connector';
import type { IComboBox, IComboBoxOption } from '@fluentui/react';
import { ComboBox, getTheme } from '@fluentui/react';

type TGroupsPickerProps = {
    gridTableHeight?: React.ReactText;
    groupsSearchedString: string;
    onGroupsSearchedStringChange: (search: string) => void;
    showHeading: boolean;
    showSearch: boolean;
    groupsSelection: string[];
    onGroupsSelectionChange: (selection: string[], isDetoggleOnly: boolean) => void;
    availableGroups: IApiGroup[];
    defaultGroupGuid?: string;
};

/**
 * These groups are cannot be changed in manner of assignment. But we have to at least show their effect on user.
 */
export type THiddenGroup = {
    guid: string;
    isUserThere: boolean;
};

export type TDefaultGroupValue = {
    guid: string;
    causedAddingToGroup: boolean;
} | null;

type TSpecialFormProps = {
    exchangeRatesAdminGroupGuid: string | null;
    sendServerUpdateStatusGroupGuid: string | null;
    sendSystemHealthNotificationsGroupGuid: string | null;
    isSystemHealthNotificationDefaultGroupInGs: boolean;
    groupsSelection: string[];
    hiddenGroups: THiddenGroup[];
    onGroupsSelectionChange: (selection: string[], isDetoggleOnly: boolean) => void;
    isSystemUser: boolean;
    availableGroups: IApiGroup[];
    allGroups: IApiGroup[];
    userCategoriesGuids: string[];
    onUserCategoriesChange: (newCategoriesGuids: string[]) => void;
    toggledGroups: string[];
    isCloudHosted: boolean;
};

type TNameOperatingState = { username: string; firstName: string; lastName: string };

export class UserWizardBase {

    static readonly noSupervisorItemGUID = '__NO_SUPERVISOR';
    static readonly noDefaultGroupItemGUID = '__NO_DEFAULT_GROUP';
    static readonly apiUserKeyCode = '__API_USER';
    static readonly usernameMinLength = 1;
    static readonly usernameMaxLength = 100;

    private static readonly groupsGridColumns = [
        { name: 'FileAs', title: Strings.components.routes.users.groupName }
    ];

    private static readonly groupsDefaultColumnWidths = [
        { columnName: 'FileAs', width: 400 }
    ];

    static getSupervisorOptionsForDropdown(availableSupervisors: IApiUser[]): { text: string; key: string }[] {
        return [{ text: Strings.components.routes.users.noSupervisor, key: UserWizardBase.noSupervisorItemGUID }].concat(
            availableSupervisors.map((supUsr) => ({ text: supUsr.FileAs || '', key: supUsr.ItemGUID }))
        );
    }

    static getDefaultGroupsOptionsForDropdown(availableGroups: IApiGroup[]): { text: string; key: string }[] {
        return [{ text: Strings.components.routes.users.noDefaultGroup, key: UserWizardBase.noDefaultGroupItemGUID }].concat(
            availableGroups.map((grp) => ({ text: grp.GroupName || '', key: grp.ItemGUID }))
        );
    }

    static sortAvailableLicenses = (availableLicenes: IApiLocalizedLicenseBundle[]) => {
        const getWeightPoints = (licence: IApiLocalizedLicenseBundle) => {
            let weightPoints = 0;
            if (licence.ContainsMobile) {
                weightPoints += 1;
            }
            if (licence.ContainsWeb) {
                weightPoints += 2;
            }
            if (licence.ContainsOutlook) {
                weightPoints += 4;
            }
            return weightPoints;
        };
        return availableLicenes.sort((a, b) => getWeightPoints(b) - getWeightPoints(a));
    };

    static getLicenseOptionsForDropdown(availableLicenses: IApiLocalizedLicenseBundle[]): IComboBoxOption[] {
        const availableLicensesOptions = UserWizardBase.sortAvailableLicenses(availableLicenses).map((license) => ({ text: license.Name, key: license.Code, data: license }));
        return [
            ...availableLicensesOptions,
            { text: Strings.components.routes.users.apiOnly, key: UserWizardBase.apiUserKeyCode, styles: { root: { borderTop: `1px solid ${getTheme().palette.neutralQuaternaryAlt}` } } },
        ];
    }

    /**
     * Performs adding and subtracting for FreeSlotsCount and UsedSlotsCount
     * @param licensesToRemove licenses to subtract from availableLicenses
     * @param availableLicenses all available licenses, from which to subtract licensesToRemove
     * @param isAddingBack if true then add licenses back instead of subtracting them
     * @returns Available Licenses after adding/subtracting
     */
    static removeFromAvailableLicenses = (licensesToRemove: IApiLocalizedLicenseBundle[], availableLicenses: IApiLocalizedLicenseBundle[], isAddingBack?: boolean) => {
        const assignedLicensesCodes = licensesToRemove.map((asl) => asl.Code);
        const newAvailableLicenses = availableLicenses.map((avl) => {
            if (assignedLicensesCodes.includes(avl.Code)) {
                if (isAddingBack) {
                    return { ...avl, FreeSlotsCount: avl.FreeSlotsCount + 1, UsedSlotsCount: avl.UsedSlotsCount - 1 };
                }
                return { ...avl, FreeSlotsCount: avl.FreeSlotsCount - 1, UsedSlotsCount: avl.UsedSlotsCount + 1 };
            }

            return avl;
        });

        return newAvailableLicenses;
    };

    /**
     * Takes the best available license, removes it from availableLicenses counts and returns new data.
     * @returns newLicense and updated availableLicenses
     */
    static takeBestAvailableLicense = (availableLicenses: IApiLocalizedLicenseBundle[]) => {
        if (availableLicenses.length > 0) {
            const newLicense = UserWizardBase.sortAvailableLicenses(availableLicenses)[0];
            if (newLicense.UsedSlotsCount < newLicense.Quantity) {
                const newAvailableLicenses = UserWizardBase.removeFromAvailableLicenses([newLicense], availableLicenses);
                return {
                    newLicense,
                    newAvailableLicenses,
                };
            }
        }

        return {
            newLicense: null,
            newAvailableLicenses: availableLicenses
        };
    };

    /**
     * Strips unnecessary info from license bundle object.
     */
    static prepareAssignedLicensesForSubmit = <T extends IApiLicenseBundleBase>(assignedLicenses?: T[] | null) => {
        if (!assignedLicenses) {
            return null;
        }

        return assignedLicenses.map((lic) => ({ Code: lic.Code, ContainsOutlook: lic.ContainsOutlook, ContainsWeb: lic.ContainsWeb, ContainsMobile: lic.ContainsMobile }));
    };

    /**
     * Checks if the license has access to any of the platforms.
     */
    static readonly isLicenseWithAccessToAnyPlatform = <T extends IApiLicenseBundleBase>(license: T) => {
        return license.ContainsOutlook || license.ContainsWeb || license.ContainsMobile;
    };

    static readonly getLicensesWithAlreadyAssignedFeatures = (license: IApiLocalizedLicenseBundle | null, assignedLicenses: IApiLocalizedLicenseBundle[]) => {
        if (!license) {
            return null;
        }

        const newLicenseFeatures = license.Features.map(f => f.Feature);
        return assignedLicenses.filter(al => {

            const assignedLicenseFeatures = al.Features.map(f => f.Feature);
            return assignedLicenseFeatures.some(alf => newLicenseFeatures.includes(alf));
        });
    };

    static readonly GroupsPicker: React.FunctionComponent<TGroupsPickerProps> = (props) => {
        if (props.showHeading && !props.showSearch) {
            console.error('UserWizardBase.GroupsPicker: wrong heading configuration');
            return null;
        }

        const myStrings = Strings.components.routes.users;

        const noGroupsString: string = props.groupsSearchedString ? Strings.components.routes.groups.noGroupsMatch : Strings.components.routes.groups.noGroups;

        const defaultGroupGuid = props.defaultGroupGuid;
        const GropusPickerCell: React.FunctionComponent<Table.DataCellProps> = React.useCallback((cellProps) => {
            const typedRow = cellProps.row as IApiGroup;
            return (
                <VirtualTable.Cell {...cellProps}>
                    {cellProps.value}
                    <GroupBadgeFlags item={typedRow} defaultGroupGuid={defaultGroupGuid} />
                </VirtualTable.Cell>
            );
        }, [defaultGroupGuid]);

        return (
            <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                {props.showSearch &&
                    <div className={props.showHeading ? 'row' : 'row justify-content-end mb-2'}>
                        {props.showHeading &&
                            <div className="col">
                                <h2 className="mb-5">{myStrings.assignGroups}</h2>
                            </div>
                        }
                        <div className="col-auto">
                            <Form.Control type="text" placeholder={Strings.search} value={props.groupsSearchedString} onChange={(e) => props.onGroupsSearchedStringChange(e.target.value)} />
                        </div>
                    </div>
                }
                <div className="row flex-fill d-flex justify-content-start">
                    <div className="col">
                        <Grid
                            rows={props.availableGroups.sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs))}
                            columns={UserWizardBase.groupsGridColumns}
                            rootComponent={GridTable.Root}
                            getRowId={(row: IApiGroup) => row.ItemGUID}
                        >
                            <SelectionState
                                selection={props.groupsSelection}
                                onSelectionChange={(sel) => (props.onGroupsSelectionChange as ((sel: React.ReactText[], isDetoggleOnly: boolean) => void))(sel, true)}
                            />
                            <SearchState
                                value={props.groupsSearchedString}
                                onValueChange={props.onGroupsSearchedStringChange}
                            />
                            <IntegratedSelection />
                            <IntegratedFiltering />
                            <VirtualTable
                                containerComponent={GridTable.TableContainerComponent}
                                height={props.gridTableHeight}
                                messages={{ noData: noGroupsString }}
                                cellComponent={GropusPickerCell}
                                noDataCellComponent={GridTable.NoDataCell}
                            />
                            <TableColumnResizing defaultColumnWidths={UserWizardBase.groupsDefaultColumnWidths} />
                            <TableHeaderRow />
                            <TableSelection showSelectAll selectByRowClick highlightRow />
                        </Grid>
                    </div>
                </div>
            </div>
        );
    };

    static isInGroup(groupsSelection: string[], hiddenGroups: THiddenGroup[], groupGuid: string | null) {
        if (!groupGuid)
            return false;

        if (hiddenGroups.some(hg => hg.guid === groupGuid && hg.isUserThere))
            return true;

        return groupsSelection.includes(groupGuid);
    }

    static solveGroupChange(oldToggledGroups: string[], oldSelection: string[], newSelection: string[], isDetoggleOnly: boolean, oldDefaultGroup: TDefaultGroupValue, oldGroupsUnassignedByDefaultGroupChange: string[] | null) {
        const newToggledGroups = UserWizardBase.toggleGroupChange(oldToggledGroups, oldSelection, newSelection, isDetoggleOnly);

        // Also erase default group if it was unassigned
        let newDefaultGroup = oldDefaultGroup;
        if (oldDefaultGroup && newSelection.indexOf(oldDefaultGroup.guid) === -1) {
            newDefaultGroup = null;
        }

        // Remove alert of unassigned groups that were added back
        // Also replace null to know, that the admin has made any changes to groups and should be warned about groups unassigned by DF group change
        let newGroupsUnassignedByDefaultGroupChange = oldGroupsUnassignedByDefaultGroupChange ?? [];
        if (newGroupsUnassignedByDefaultGroupChange.some(gudf => newSelection.indexOf(gudf) !== -1)) {
            newGroupsUnassignedByDefaultGroupChange = newGroupsUnassignedByDefaultGroupChange.filter(gudf => newSelection.indexOf(gudf) === -1);
        }

        return {
            newToggledGroups,
            newGroupsUnassignedByDefaultGroupChange,
            newDefaultGroup
        };
    }

    static solveDefaultGroupGuidChange(oldToggledGroups: string[], oldGroupsSelection: string[], oldDefaultGroup: TDefaultGroupValue, oldGroupsUnassignedByDefaultGroupChange: string[] | null, newUserDefaultGroupGuid: string) {
        let newGroupsSelection = oldGroupsSelection;
        let newGroupsUnassignedByDefaultGroupChange = oldGroupsUnassignedByDefaultGroupChange;

        // Toggle the new default group
        let causesAdding = false;
        if (newUserDefaultGroupGuid !== UserWizardBase.noDefaultGroupItemGUID) {
            const index = newGroupsSelection.indexOf(newUserDefaultGroupGuid);
            if (index === -1) {
                newGroupsSelection = [...newGroupsSelection];
                newGroupsSelection.push(newUserDefaultGroupGuid);
                causesAdding = true;
            }
        }

        // Check if change shall cause unassign
        if (oldDefaultGroup && oldDefaultGroup.guid !== newUserDefaultGroupGuid && oldDefaultGroup.causedAddingToGroup) {
            const index = newGroupsSelection.indexOf(oldDefaultGroup.guid);
            // In fact, the item should always be there
            if (index !== -1) {
                newGroupsSelection = [...newGroupsSelection];
                newGroupsSelection.splice(index, 1);

                // If the causes array is still null, the admin has not modified the groups elsewhere, so we don't warn them
                if (newGroupsUnassignedByDefaultGroupChange && newGroupsUnassignedByDefaultGroupChange.indexOf(oldDefaultGroup.guid) === -1) {
                    newGroupsUnassignedByDefaultGroupChange = [...newGroupsUnassignedByDefaultGroupChange];
                    newGroupsUnassignedByDefaultGroupChange.push(oldDefaultGroup.guid);
                }
            }
        }

        const newToggledGroups = UserWizardBase.toggleGroupChange(oldToggledGroups, oldGroupsSelection, newGroupsSelection, true);

        const newDefaultGroup = newUserDefaultGroupGuid !== UserWizardBase.noDefaultGroupItemGUID
            ? {
                guid: newUserDefaultGroupGuid,
                causedAddingToGroup: causesAdding
            }
            : null;

        return {
            newGroupsSelection,
            newToggledGroups,
            newGroupsUnassignedByDefaultGroupChange,
            newDefaultGroup
        };
    }

    private static toggleGroupChange(toggledGroups: string[], oldSelection: string[], newSelection: string[], isDetoggleOnly: boolean) {
        let changedItems;
        if (newSelection.length > oldSelection.length) {
            changedItems = newSelection.filter(x => !oldSelection.includes(x));
        } else {
            changedItems = oldSelection.filter(x => !newSelection.includes(x));
        }

        const newToggledGroups = [...toggledGroups];
        changedItems.forEach(groupGuid => {
            const index = newToggledGroups.indexOf(groupGuid);
            if (index > -1) {
                newToggledGroups.splice(index, 1);
            } else if (!isDetoggleOnly) {
                newToggledGroups.push(groupGuid);
            }
        });

        return newToggledGroups;
    }

    static setIsInGroup(groupsSelection: string[], hiddenGroups: THiddenGroup[], groupGuid: string | null, isThere: boolean, setGroupsSelection: (selection: string[], isDetoggleOnly: boolean) => void, isDetoggleOnly: boolean) {
        if (!groupGuid || hiddenGroups.some(hg => hg.guid === groupGuid))
            return;
        const groups = [...groupsSelection];
        if (isThere && !UserWizardBase.isInGroup(groupsSelection, hiddenGroups, groupGuid)) {
            groups.push(groupGuid);
            setGroupsSelection(groups, isDetoggleOnly);
            return;
        }
        if (!isThere && UserWizardBase.isInGroup(groupsSelection, hiddenGroups, groupGuid)) {
            const index = groups.indexOf(groupGuid);
            if (index > -1) {
                groups.splice(index, 1);
            }
            setGroupsSelection(groups, isDetoggleOnly);
            return;
        }
    }

    static readonly SpecialForm: React.FunctionComponent<TSpecialFormProps> = (props) => {
        const myStrings = Strings.components.routes.users;
        const usableGroupGuids = props.availableGroups.map(g => g.ItemGUID).concat(props.hiddenGroups.map(g => g.guid));
        const checkboxes = [
            { groupGuid: props.exchangeRatesAdminGroupGuid, res: myStrings.exchangeRatesAdmin, code: 'ex' },
            { groupGuid: props.sendServerUpdateStatusGroupGuid, res: myStrings.sendServerUpdateStatus, code: 'up' },
            { groupGuid: props.sendSystemHealthNotificationsGroupGuid, res: myStrings.sendSystemHealthNotifications, code: 'he', hidden: props.isCloudHosted },
        ];

        const categories = props.allGroups.filter(x => x.IsCategory).sort((a, b) => a.GroupName.localeCompare(b.GroupName));
        const categoriesOptions: IComboBoxOption[] = categories.map(cat => ({ key: cat.ItemGUID, text: cat.GroupName }));

        const handleCategoriesChange = (e: React.FormEvent<IComboBox>, option?: IComboBoxOption) => {
            if (!option) {
                return;
            }

            if (option.selected) {
                const newUserCategories = props.userCategoriesGuids.concat([option.key as string]);
                props.onUserCategoriesChange(newUserCategories);
            } else {
                const newUserCategories = props.userCategoriesGuids.filter(x => x !== option.key);
                props.onUserCategoriesChange(newUserCategories);
            }
        };

        return (
            <Form className="form-application">
                <Form.Row>
                    <Col>
                        {
                            checkboxes.map(({ groupGuid, res, code, hidden }) => {
                                if (!usableGroupGuids.includes(groupGuid!) || hidden) {
                                    return null;
                                }

                                const showGroupToggleMessage = groupGuid && props.toggledGroups.includes(groupGuid) && (code !== 'he' || !props.isSystemHealthNotificationDefaultGroupInGs);

                                return (
                                    <Form.Group controlId={'newUserSpec' + code} className="mb-2" key={'newuser-spec-' + code}>
                                        <Form.Check
                                            type="checkbox"
                                            label={res}
                                            disabled={!groupGuid || props.hiddenGroups.some(hg => hg.guid === groupGuid)}
                                            checked={UserWizardBase.isInGroup(props.groupsSelection, props.hiddenGroups, groupGuid)}
                                            onChange={(e: IFormCheckChangeEvent) => UserWizardBase.setIsInGroup(props.groupsSelection, props.hiddenGroups, groupGuid, e.target.checked, props.onGroupsSelectionChange, false)}
                                        />
                                        {showGroupToggleMessage && (
                                            <div>
                                                {`${UserWizardBase.isInGroup(props.groupsSelection, props.hiddenGroups, groupGuid) ? myStrings.userWillBeAddedToGroup : myStrings.userWillBeRemovedFromGroup} ${props.availableGroups.find((ag) => ag.ItemGUID === groupGuid)?.FileAs
                                                    }`}
                                            </div>
                                        )}
                                    </Form.Group>
                                );
                            })
                        }
                    </Col>
                </Form.Row>
                <Form.Row>
                    <Col sm={6}>
                        <Form.Group>
                            <Form.Label>{myStrings.categories}</Form.Label>
                            <ComboBox
                                multiSelect
                                selectedKey={props.userCategoriesGuids}
                                options={categoriesOptions}
                                onChange={handleCategoriesChange}
                                useComboBoxAsMenuWidth
                            />
                        </Form.Group>
                    </Col>
                </Form.Row>
            </Form>
        );
    };

    static getUsernamePattern(username: string, forbiddenUsernames: string[]): string {
        const basicPattern = `^.{${UserWizardBase.usernameMinLength},${UserWizardBase.usernameMaxLength}}$`;
        if (!username || username.length < 1)
            return basicPattern;

        if (forbiddenUsernames.findIndex(usr => StringHelper.compareIgnoringCase(usr, username) === 0) > -1)
            return '^(?!' + StringHelper.escapeRegExp(username) + '$)';

        return basicPattern;
    }

    /**
     * @param username String to normalize
     * @param allowExtraChars Allow characters ```[".", "@", "-"]``` in username
     */

    static normalizeUsername(username: string | null | undefined, allowExtraChars?: boolean): string {
        if (!username)
            return '';

        let regexp = /\W/g;

        if (allowExtraChars) {
            regexp = /(?!\.|@|-)\W/g;
        }

        return StringHelper.removeAccents(username)!.replace(regexp, '').toLowerCase();
    }

    static setName(fName: string | undefined, lName: string | undefined, state: TNameOperatingState, onResultSave: (newData: Partial<TNameOperatingState>) => void) {
        let firstName;
        let lastName;
        const stateObj: Partial<TNameOperatingState> = {};
        if (fName !== undefined) {
            firstName = fName;
            stateObj.firstName = fName;
        } else {
            firstName = state.firstName;
        }
        if (lName !== undefined) {
            lastName = lName;
            stateObj.lastName = lName;
        } else {
            lastName = state.lastName;
        }

        // Set username if not set manually.
        if (!state.username || state.username === UserWizardBase.computeUsername(state.firstName, state.lastName)) {
            stateObj.username = UserWizardBase.computeUsername(firstName, lastName);
        }

        onResultSave(stateObj);
    }

    private static computeUsername(fName: string | undefined, lName: string | undefined): string {
        let firstName: string | null = null;
        if (fName) {
            firstName = UserWizardBase.normalizeUsername(fName);
        }
        let lastName: string | null = null;
        if (lName) {
            lastName = UserWizardBase.normalizeUsername(lName);
        }

        if (!lastName && !firstName)
            return '';

        if (lastName && firstName)
            return firstName[0] + lastName;

        if (!lastName && firstName)
            return firstName[0];

        if (!firstName && lastName)
            return lastName;

        return '';
    }

    static getInvalidUsernameMessage = (username: string) => {
        const myStrings = Strings.components.routes.users;

        if (!username) {
            return Strings.thisFieldIsMandatory;
        } else if (username.length < UserWizardBase.usernameMinLength) {
            return myStrings.usernameTooShort;
        } else if (username.length >= UserWizardBase.usernameMaxLength) {
            return Strings.formatString(myStrings.usernameTooLong, UserWizardBase.usernameMaxLength);
        } else {
            return myStrings.selectUniqueUsername;
        }
    };

    static getInvalidAzureUsernameMessage = (username: string, forbiddenUsernames: string[], forbiddenEmails: string[], isApiUser: boolean) => {
        const myStrings = Strings.components.routes.users;
        const isUsernameInvalid = UserWizardBase.getIsUsernameInvalid(username, forbiddenUsernames);
        const isEmailInvalid = UserWizardBase.getIsEmailInvalid(username, forbiddenEmails, isApiUser);

        if (!username) {
            return Strings.thisFieldIsMandatory;

        } else if (username.length >= UserWizardBase.usernameMaxLength) {
            return Strings.formatString(myStrings.emailTooLong, UserWizardBase.usernameMaxLength);
        } else if (isEmailInvalid || isUsernameInvalid) {
            if (forbiddenEmails.includes(username) || forbiddenUsernames.includes(username)) {
                return myStrings.selectUniqueEmail;
            }

            return myStrings.wrongEmail;
        }

        return null;
    };

    static getIsUsernameInvalid = (username: string, forbiddenUsernames: string[]) => {
        return !username || username.length < UserWizardBase.usernameMinLength || username.length >= UserWizardBase.usernameMaxLength || forbiddenUsernames.includes(username);
    };

    static getIsEmailForbidden = (email: string, forbiddenEmails: string[]) => {
        return forbiddenEmails.includes(email);
    };

    static getIsEmailInvalid = (email: string, forbiddenEmails: string[], isApiUser: boolean) => {
        if (isApiUser && !email) {
            return false;
        }

        return !email || !email.includes('@') || email.length < 3 || UserWizardBase.getIsEmailForbidden(email, forbiddenEmails);
    };

    static getInvalidEmailMessage = (email: string, isForbidden: boolean) => {
        const myStrings = Strings.components.routes.users;

        if (!email) {
            return Strings.thisFieldIsMandatory;
        } else if (isForbidden) {
            return myStrings.selectUniqueEmail;
        } else {
            return myStrings.wrongEmail;
        }
    };
}