import { mergeStyles } from "@fluentui/react";
import type { ButtonProps } from "react-bootstrap";
import { Button } from "react-bootstrap";

const FakeDisabledButton: React.FC<ButtonProps> = ({ children, disabled, ...props }) => {
    if (disabled) {
        return (
            <Button {...props} className={mergeStyles(props.className, 'disabled')} style={{ ...props.style, cursor: 'default', pointerEvents: 'auto' }} onClick={(e) => e.preventDefault()} >
                {children}
            </Button>
        );
    }
    return <Button {...props}>{children}</Button>;
};

export default FakeDisabledButton;