import React, { useEffect } from 'react';
import { SpinnerModal } from '../../shared/SpinnerModal';
import somethingWentWrongImg from '../../../img/SomethingWentWrong.svg';
import { Text } from '@fluentui/react';
import Strings from '../../../strings';
import IframePostMessageHelper from '../../../helpers/IframePostMessageHelper';
import { SpinnerVariant } from '@eway-crm/gui';

type TShoppingEnabledChecker = React.PropsWithChildren & {
    isShoppingEnabledLoaded: boolean;
    isShoppingEnabled: boolean;
    isSubscriptionsPath?: boolean;
};

const ShoppingEnabledChecker: React.FunctionComponent<TShoppingEnabledChecker> = ({ isShoppingEnabled, isShoppingEnabledLoaded, isSubscriptionsPath, children }) => {
    const myStrings = Strings.components.shoppingConnection;
    
    useEffect(() => {
        if (isShoppingEnabledLoaded && !isShoppingEnabled && isSubscriptionsPath) {
            IframePostMessageHelper.checkoutLoaded();
        }
    }, [isShoppingEnabledLoaded, isSubscriptionsPath, isShoppingEnabled]);

    if (!isShoppingEnabledLoaded) {
        return <SpinnerModal.CenteredContent variant={SpinnerVariant.ease} />;
    }

    if (!isShoppingEnabled) {
        return (
            <div className="d-flex w-100 h-100 flex-column align-items-center justify-content-center">
                <img src={somethingWentWrongImg} alt="Something went wrong" style={{ width: '550px', maxWidth: '95%' }} />
                <Text variant="xLarge" className="d-flex w-100 justify-content-center mt-3 text-center px-3">
                    {myStrings.unavailableTitle}
                </Text>
                <Text className="d-flex w-100 justify-content-center mt-2 text-center px-3">{myStrings.unavailableText}</Text>
            </div>
        );
    }

    return <>{children}</>;

};

export default ShoppingEnabledChecker;
