import type { IComboBoxOption, IComboBoxProps} from '@fluentui/react';
import { VirtualizedComboBox } from '@fluentui/react';
import { useState } from 'react';
import { NumberHelper } from '../../../helpers/NumberHelper';
import Strings from '../../../strings';

const shouldUse12HourTimeFormat = () => Strings.getLanguage() === 'en';

const transformToTimeString = (hours: number, minutes: string) => {
    const twoDigitHours = NumberHelper.prependZeros(hours, 2);
    return `${twoDigitHours}:${minutes}`;
};

const formatToTime = (hours: number, minutes: number) => {
    return transformToTimeString(hours, NumberHelper.prependZeros(minutes, 2));
};

const getEnTime = (hours: number, minutes: number) => {
    let englishTime = `${transformToTimeString(hours, NumberHelper.prependZeros(minutes, 2))} AM`;
    if (hours === 0) {
        englishTime = `${transformToTimeString(12, NumberHelper.prependZeros(minutes, 2))} AM`;
    } else if (hours === 12) {
        englishTime = `${transformToTimeString(hours, NumberHelper.prependZeros(minutes, 2))} PM`;
    } else if (hours > 12) {
        englishTime = `${transformToTimeString(hours - 12, NumberHelper.prependZeros(minutes, 2))} PM`;
    }
    return englishTime;
};

const formatToLocalizedTime = (hours: number, minutes: number) => {
    if (shouldUse12HourTimeFormat()) {
        return getEnTime(hours, minutes);
    } else {
        return formatToTime(hours, minutes);
    }
};

const generateTimeOptions = () => {
    const times = [];
    for (let i = 0; i < 24; i++) {
        times.push({ key: formatToTime(i, 0), text: formatToLocalizedTime(i, 0) });
        times.push({ key: formatToTime(i, 30), text: formatToLocalizedTime(i, 30) });
    }
    return times;
};

const convertTimeStringTo24HourFormat = (time?: string | null) => {
    if (!time) {
        return null;
    }

    if (shouldUse12HourTimeFormat()) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [hours, c, minutes, s, amPm] = time.split(/(:| )/); // split on colon and space
        if (amPm === 'AM') {
            if (Number(hours) === 12) {
                time = `00:${minutes}`;
            } else {
                time = `${hours}:${minutes}`;
            }
        } else {
            if (Number(hours) === 12) {
                time = `${hours}:${minutes}`;
            } else {
                time = `${Number(hours) + 12}:${minutes}`;
            }
        }
    }
    return time;
};

const convertTimeStringToEnFormat = (time?: string | null) => {
    if (!time) {
        return null;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [hours, c, minutes] = time.split(/(:| )/); // split on colon and space
    return getEnTime(Number(hours), Number(minutes));
};

const checkEnTimeInput = (time: string) => {
    if (/((1[0-2]|0[1-9])([0-5][0-9]?))/.test(time)) {
        // Missing colon between hrs and minutes
        time = time.slice(0, 2) + ':' + time.slice(2);
    }

    if (/\b((?![0])[1-9]):([0-5][0-9])/.test(time)) {
        // Missing first number of whole string
        time = '0' + time;
    }

    if (/((1[0-2]|0[1-9]):([0-5](?![0-9])))/.test(time)) {
        // Missing last digit
        time = time.slice(0, 4) + '0' + time.slice(4);
    }

    if (/((1[0-2]|0[1-9]):([0-5][0-9])([AaPp][Mm]?))/.test(time)) {
        // Missing space
        time = time.slice(0, 5) + ' ' + time.slice(5).toUpperCase();
    }

    if (/((1[0-2]|0[1-9]):([0-5][0-9]) ([AaPp][Mm]))/.test(time)) {
        // Time is complete
        return time.slice(0, 5) + ' ' + time.slice(6, 8).toUpperCase();
    } else if (/((1[0-2]|0[1-9]):([0-5][0-9]) ([AaPp]))/.test(time)) {
        // Missing M at the end
        return time.slice(0, 5) + ' ' + time.slice(6, 7).toUpperCase() + 'M';
    } else if (/((1[0-2]|0[1-9]):([0-5][0-9]))/.test(time)) {
        // Missing AM/PM
        return time.slice(0, 5) + ' AM';
    } else {
        return null;
    }
};

const check24HourTimeInput = (time: string) => {
    if (/([01][0-9]|2[0-3])[0-5][0-9]/.test(time)) {
        // Missing colon between hrs and minutes
        time = time.slice(0, 2) + ':' + time.slice(2);
    }

    if (/\b((?![0])[0-9]):([0-5][0-9])/.test(time)) {
        // Missing first number of whole string
        time = '0' + time;
    }

    if (/([01][0-9]|2[0-3]):[0-5](?![0-9])/.test(time)) {
        // Missing last number
        time = time + '0';
    }

    if (/([01][0-9]|2[0-3]):[0-5][0-9]/.test(time)) {
        return time.slice(0, 5);
    } else {
        return null;
    }
};

const checkTimeInput = (time: string | null | undefined) => {
    if (!time) {
        return null;
    }

    if (shouldUse12HourTimeFormat()) {
        return checkEnTimeInput(time);
    } else {
        return check24HourTimeInput(time);
    }
};

const getDefaultTimeStateValue = (defaultValue?: string | null) => {
    if (!defaultValue) {
        return null;
    }
    const checkedValue = check24HourTimeInput(defaultValue);
    if (shouldUse12HourTimeFormat()) {
        return convertTimeStringToEnFormat(checkedValue);
    }

    return checkedValue;
};

type TTimePickerProps = Pick<IComboBoxProps, 'disabled'> & {
    defaultValue?: string | null;
    onChange?: (newValue: string | null, newLocalizedValue: string | null) => void;
};

const TimePicker: React.FC<TTimePickerProps> = ({ defaultValue, onChange, ...restProps }) => {
    const [time, setTime] = useState<null | string>(() => getDefaultTimeStateValue(defaultValue));
    const [timeOptions] = useState(generateTimeOptions());

    const handleSetTime = (newValue: string) => {
        onChange && onChange(convertTimeStringTo24HourFormat(newValue), newValue);
        setTime(newValue);
    };

    const handleChange = (option?: IComboBoxOption, value?: string) => {
        if (!option && !!value) {
            const newValue = checkTimeInput(value);
            if (newValue) {
                handleSetTime(newValue);
            }
        } else if (option) {
            handleSetTime(option.text.toString());
        }
    };

    return (
        <VirtualizedComboBox
            allowFreeform
            useComboBoxAsMenuWidth
            styles={{ root: { width: '6.5rem' }, optionsContainerWrapper: { maxHeight: '16rem' } }}
            onChange={(e, option, i, value) => {
                handleChange(option, value);
            }}
            options={timeOptions}
            text={time ?? undefined}
            {...restProps}
        />
    );
};

export default TimePicker;
