import * as React from 'react';
import type { TPaymentGateProvider } from '../../../../data/shopping/IPaymentMethodsModel';
import type { ICurrentPlanModel, IStoreModel, TPeriodType } from '../../../../data/shopping/ISubscriptionsShoppingModel';
import type { ShoppingConnection } from '../../../ShoppingConnection';
import type { TCountriesAndRegions } from '../TCountriesAndRegions';
import type { TCustomerInfo } from '../TCustomerInfo';
import type { TPackageEditingStateBase } from '../TPackageEditingState';
import type TPackagesEditingStates from './TPackagesEditingStates';

type TStoreV2ContextObject = {
    shoppingConnection: ShoppingConnection;
    storeData: IStoreModel;
    countries: TCountriesAndRegions;
    customerInfo: TCustomerInfo;
    setCustomerInfo: (newInfo: Partial<TCustomerInfo>) => void;
    paymentGateProvider: TPaymentGateProvider;
    packagesEditingStates: TPackagesEditingStates;
    setPackageEditingState: (artno: string, newState: Partial<TPackageEditingStateBase>) => void;
    setMultiplePackagesEditingStates: (newStates: { artno: string; newState: Partial<TPackageEditingStateBase> }[], isEditionSwitch?: boolean) => void;
    currentDbSizeGigabytes: number;
    activeUsersCount: number;
    isAnythingAlreadySubscribed: boolean;
    currentPlan: ICurrentPlanModel | null;
    onStoreDataChanged: () => Promise<void>;
    isPackageInCart: (artno: string) => boolean;
    revertToDefaultPackagesStates: () => void;
    selectedPeriodType: TPeriodType;
    setSelectedPeriodType: (periodType: TPeriodType) => void;
    collectPersonName: boolean;
};
const StoreV2Context = React.createContext<TStoreV2ContextObject>(null!);

export default StoreV2Context;