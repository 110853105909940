import * as React from 'react';
import type { RouteComponentProps, match as IRouterMatch} from 'react-router-dom';
import { Link, Switch, Route, matchPath, Redirect } from 'react-router-dom';
import Strings from '../../strings';
import { Button, ButtonToolbar, Dropdown, Form, Spinner as BsSpinner } from 'react-bootstrap';
import type { Sorting } from '@devexpress/dx-react-grid';
import { SelectionState, IntegratedSelection, SearchState, IntegratedFiltering, SortingState, IntegratedSorting } from '@devexpress/dx-react-grid';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, TableSelection, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css';
import GridTable from '../GridTable';
import StringHelper from '../../helpers/StringHelper';
import RelationTypes from '../../data/constants/RelationTypes';
import FolderNames from '../../data/constants/FolderNames';
import { NewGroupWizard } from './groups/NewGroupWizard';
import { AssignUsersWizard } from './groups/AssignUsersWizard';
import DuplicateGroupWizard from './groups/DuplicateGroupWizard';
import { DeleteGroupWizard } from './groups/DeleteGroupWizard';
import EditGroupWizard from './groups/EditGroupWizard/EditGroupWizard';
import GroupBadgeFlags from './groups/GroupBadgeFlags';
import type { IApiGroup, IApiLayout, IApiLayoutsModel, TFolderName } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { TApiItemWithReleations } from '@eway-crm/connector';
import RouteConfig from '../../RouteConfig';
import { validate as uuidValidate } from 'uuid';
import { LoaderProvider } from '../shared/WcfDataLoaderProvider';
import { FontIcon, mergeStyles, TooltipHost } from '@fluentui/react';
import type { IApiModulePermission } from '@eway-crm/connector';
import type { TModulePermissionWithFolder } from './ModulePermissions';
import type { TFieldPermissionWithCol } from './fields/FieldPermissionsGrid';
import type { IApiColumn } from '@eway-crm/connector';
import type { IApiColumnPermission } from '@eway-crm/connector';
import { RemoteItemStore } from '../../RemoteItemStore';
import ExcelJS from 'exceljs';
import ExcelHelper from '../../helpers/ExcelHelper';
import ReactHelper from '../../helpers/ReactHelper';
import { modulePermissionsOptionsSettings } from './modulePermissions/ModulePermissionsGrid';
import Fields from './Fields';
import { fieldPermissionsOptionsSettings } from './fields/FieldPermissionsGrid';
import FieldPermissionNames from '../../data/constants/FieldPermissionNames';
import { GroupNames } from '../../data/constants/GroupNames';
import { SpinnerModal } from '../shared/SpinnerModal';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import FakeDisabledButton from '../shared/FakeDisabledButton';
import LicenseRestrictionsLockIcon from '../shared/locks/LicenseRestrictionsLockedIcon';
import LicenseRestrictionsTooltipContent from '../shared/locks/LicenseRestrictionsTooltipContent';
import { Spinner, SpinnerVariant, VersionHelper } from '@eway-crm/gui';
import type { TMyColumnPermissionsMap } from '../shared/WizardBase';

const EXPORT_OPTIONS = {
    groupsList: 'groupsList',
    groupsWithModulePermissions: 'groupsWithModulePermissions',
    groupsWithFieldPermissions: 'groupsWithFieldPermissions',
} as const;

const EXPORT_FILENAMES = {
    groupsList: 'Groups',
    groupsWithModulePermissions: 'Groups_MP',
    groupsWithFieldPermissions: 'Groups_FP',
} as const;

const myStrings = Strings.components.routes.groups;
const myModulePermissionStrings = Strings.components.routes.modulePermissions;
const myFieldPermissionStrings = Strings.components.routes.fieldPermissions;

const tableColumnExtensions = [
    { columnName: 'GroupName', wordWrapEnabled: false },
    { columnName: 'ResponsibilityDescription', wordWrapEnabled: true },
    { columnName: 'Description', wordWrapEnabled: true },
];

const defaultColumnWidths = [
    { columnName: 'GroupName', width: 340 },
    { columnName: 'numberOfUsers', width: 130 },
    { columnName: 'ResponsibilityDescription', width: 400 },
    { columnName: 'Description', width: 400 },
];

const integratedSortingColumnExtensions = [
    { columnName: 'GroupName', compare: StringHelper.localeCompare },
];

const gridColumns = [
    { name: 'GroupName', title: myStrings.groupName },
    { name: 'numberOfUsers', title: myStrings.numberOfUsers },
    { name: 'ResponsibilityDescription', title: myStrings.description },
    { name: 'Description', title: myStrings.note },
];

type TGroupsProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TGroupsState = {
    areDataLoaded: boolean;
    data: TApiItemWithReleations<IApiGroup>[];
    usersData: IApiUser[];
    myColumnPermissionsMap: TMyColumnPermissionsMap;
    layouts: IApiLayout[];
    selection: string[];
    searchedString: string;
    sort: Sorting[];
    isAssigningUsers: boolean;
    isDuplicatingGroup: boolean;
    isDeletingGroups: boolean;
    loadingEdittedGroupItemGuid: string | null;
    isExporting: boolean;
};

export class Groups extends React.Component<TGroupsProps, TGroupsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TGroupsProps) {
        super(props);
        this.state = {
            areDataLoaded: false,
            data: [],
            usersData: [],
            myColumnPermissionsMap: {},
            layouts: [],
            selection: [],
            searchedString: '',
            sort: [
                { columnName: 'GroupName', direction: 'asc' }
            ],
            isAssigningUsers: false,
            isDuplicatingGroup: false,
            isDeletingGroups: false,
            loadingEdittedGroupItemGuid: null,
            isExporting: false,
        };
    }

    componentDidMount() {
        void this.reload();
    }

    private readonly reload = async () => {
        await ReactHelper.setState(this, {
            areDataLoaded: false,
            data: [],
            selection: []
        });

        const usrResult = await this.context.connection.askApi<IApiDataResponse<IApiUser>>('GetUsers', { includeForeignKeys: true });
        const grpResult = await this.context.connection.askApi<IApiDataResponse<TApiItemWithReleations<IApiGroup>>>('GetGroups', {
            includeRelations: true,
            relationsFilter: {
                RelationType: RelationTypes.group,
                ForeignFolderName: FolderNames.users
            }
        });
        const myColumnPermissionsRes = await this.context.connection.askApi<IApiDataResponse<IApiColumnPermission>>('GetEffectiveColumnPermissions', {
            userGuid: this.context.connection.state.session!.userGuid,
            folderName: FolderNames.groups
        });

        const myColumnPermissionsMap: TMyColumnPermissionsMap = {};
        myColumnPermissionsRes.Data.forEach(cp => {
            myColumnPermissionsMap[cp.ColumnName] = cp;
        });

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());
        const layouts = is810OrLater
            ? (await this.context.connection.askApi<IApiDataResponse<IApiLayout>>('GetLayouts', {})).Data
            : [];

        await ReactHelper.setState(this, {
            areDataLoaded: true,
            data: grpResult.Data,
            usersData: usrResult.Data,
            myColumnPermissionsMap,
            layouts: layouts
        });
    };

    private readonly getDisplayedData = () => {
        if (!this.state.data)
            return [];

        return RemoteItemStore.getDisplayableGroups(this.state.data).map((grp) => ({
            ...grp,
            numberOfUsers: !grp.Relations ? 0 : grp.Relations.filter((rel) => rel.ForeignFolderName === FolderNames.users && rel.RelationType === RelationTypes.group).length,
        }));
    };

    private readonly getForbiddenGroupNames = (groups: IApiGroup[]) => {
        return groups.filter((g) => g.IsCategory || g.IsOutlookCategory || g.GroupName === GroupNames.systemHealthNotification).map((group) => group.GroupName);
    };

    private readonly getSelectedGroups = () => {
        if (!this.state.data)
            return [];

        return this.state.data.filter((item) => {
            return this.state.selection.includes(item.ItemGUID);
        });
    };

    private readonly getPmGroupItem = (): TApiItemWithReleations<IApiGroup> | null => {
        if (!this.state.data)
            return null;

        const index = this.state.data.findIndex((group) => group.IsPM === true);
        if (index === -1)
            return null;

        return this.state.data[index];
    };

    private readonly deselect = () => {
        this.setState({ selection: [] });
    };

    private readonly assignUsers = () => {
        this.setState({ isAssigningUsers: true });
    };

    private readonly duplicateGroup = () => {
        this.setState({ isDuplicatingGroup: true });
    };

    private readonly deleteGroups = () => {
        this.setState({ isDeletingGroups: true });
    };

    private readonly editGroup = (groupItem: IApiGroup) => {
        this.props.history.push(`${RouteConfig.groups.edit.path}/${groupItem.ItemGUID}`);
    };

    private readonly getEditedGroupData = async (
        successCb: ([
            editedGroupItem,
            modulePermissions,
            fieldPermissions,
            otFolders,
            defaultGroupUserGuids,
            layoutsModels
        ]: [
                TApiItemWithReleations<IApiGroup>,
                TModulePermissionWithFolder[],
                TFieldPermissionWithCol[],
                string[],
                string[],
                IApiLayoutsModel[]
            ]) => void
    ) => {
        const matchEdit: IRouterMatch<{ guid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.groups.edit.pathWithGuid });
        const editedGroupGuid = matchEdit?.params?.guid;

        if (!editedGroupGuid || !!this.state.loadingEdittedGroupItemGuid)
            return;

        await ReactHelper.setState(this, { loadingEdittedGroupItemGuid: editedGroupGuid });

        const groupsResult: IApiDataResponse<TApiItemWithReleations<IApiGroup>> = await this.context.connection.askApi(
            'GetGroupsByItemGuids',
            {
                itemGuids: [editedGroupGuid],
                includeForeignKeys: true,
                includeRelations: true,
                relationsFilter: {
                    RelationType: RelationTypes.group,
                    ForeignFolderName: FolderNames.users,
                },
            }
        );
        const editedGroupItem = groupsResult.Data[0];

        const modulePermissionsResult: IApiDataResponse<IApiModulePermission> = await this.context.connection.askApi(
            'SearchModulePermissions',
            { transmitObject: { GroupGuid: editedGroupGuid }, includeDefaultPermissions: true }
        );
        const modulePermissions = modulePermissionsResult.Data.map((mpr) => ({
            ...mpr,
            Folder: FolderNames.getPluralName(mpr.FolderName),
        })).filter(mpr => !this.context.licenseRestrictionsHelper.isFolderNameHidden(mpr.FolderName as TFolderName));

        const cols: IApiDataResponse<IApiColumn> = await this.context.connection.askApi('GetColumns', { includeAdditionalFields: true });
        const colPermissions: IApiDataResponse<IApiColumnPermission> = await this.context.connection.askApi(
            'SearchColumnPermissions',
            {
                includeDefaultPermissions: true,
                transmitObject: {
                    GroupGuid: editedGroupGuid,
                },
            }
        );

        const { objectTypes } = this.context.apiData;
        const otFolders = RemoteItemStore.getDisplayableFieldPermissionFolders(objectTypes, this.context.licenseRestrictionsHelper);
        const fieldPermissions = colPermissions.Data.map((colPermission) => {
            const column = cols.Data.find((t) => t.ColumnName === colPermission.ColumnName && t.FolderName === colPermission.FolderName)!;

            return {
                ...colPermission,
                col: column,
                colName: Fields.getColumnName(column),
            };
        });

        const is810OrLater = VersionHelper.is81OrLater(this.context.connection.getApiConnection());
        const defaultGroupUserGuids = is810OrLater
            ? await (async () => {
                const dfUsersGroupsResult: IApiDataResponse<TApiItemWithReleations<IApiGroup>> = await this.context.connection.askApi(
                    'GetGroupsByItemGuids',
                    {
                        itemGuids: [editedGroupGuid],
                        includeForeignKeys: true,
                        includeRelations: true,
                        relationsFilter: {
                            RelationType: RelationTypes.defaultGroup,
                            ForeignFolderName: FolderNames.users,
                        },
                    });
                return dfUsersGroupsResult.Data[0].Relations.map((r) => r.ForeignItemGUID);
            })()
            : [];

        const layoutsModels = is810OrLater
            ? (await this.context.connection.askApi<IApiDataResponse<IApiLayoutsModel>>(
                'SearchLayoutsModels',
                {
                    transmitObject: {
                        GroupGuid: editedGroupGuid
                    }
                }
            )).Data
            : [];

        await ReactHelper.setState(this, { loadingEdittedGroupItemGuid: null });
        successCb([editedGroupItem, modulePermissions, fieldPermissions, otFolders, defaultGroupUserGuids, layoutsModels]);
    };

    private readonly exportExcelWorkbook = async (exportOption: keyof typeof EXPORT_OPTIONS) => {
        const { askApi } = this.context.connection;
        await ReactHelper.setState(this, { isExporting: true });

        const workbook = new ExcelJS.Workbook();
        const groupsToExport = this.getDisplayedData();

        if (exportOption === EXPORT_OPTIONS.groupsList) {
            const worksheet = workbook.addWorksheet(ExcelHelper.prepareSheetName(EXPORT_FILENAMES.groupsList));
            worksheet.columns = [{ width: 30 }, { width: 20 }, { width: 30 }, { width: 30 }] as ExcelJS.Column[]; // cast because of bug in newest version of ExcelJS https://github.com/exceljs/exceljs/issues/1543#issuecomment-742622403
            ExcelHelper.insertHeaderRow(worksheet, `${Strings.components.sideMenu.groups}`);
            ExcelHelper.insetColumnTitleRow(worksheet, [myStrings.groupName, myStrings.numberOfUsers, myStrings.description, myStrings.note]);

            groupsToExport
                .sort((grp1, grp2) => StringHelper.localeCompare(grp1.GroupName, grp2.GroupName))
                .forEach((group) => {
                    worksheet.addRow([group.GroupName, group.numberOfUsers, group.ResponsibilityDescription, group.Description]);
                });
        } else if (exportOption === EXPORT_OPTIONS.groupsWithModulePermissions) {
            const { objectTypes } = this.context.apiData;
            const otFolders = objectTypes.filter((ot) => ot.IsModule && ot.IsActive).map((ot) => ot.FolderName);
            const modulePermissionsRes = await askApi<IApiDataResponse<IApiModulePermission>>('GetModulePermissions', { includeDefaultPermissions: true });
            const modulePermissions = modulePermissionsRes.Data.filter((mp) => otFolders.includes(mp.FolderName)).map((mp) => ({
                ...mp,
                Folder: FolderNames.getPluralName(mp.FolderName),
            }));

            const groupsToExportGuids = groupsToExport.map((group) => group.ItemGUID);
            const groupGuidsWithModulePermissions: { [groupGuid: string]: TModulePermissionWithFolder[] } = {};

            modulePermissions.forEach((mp) => {
                if (!groupsToExportGuids.includes(mp.GroupGuid)) {
                    return;
                }

                if (groupGuidsWithModulePermissions[mp.GroupGuid]) {
                    groupGuidsWithModulePermissions[mp.GroupGuid].push(mp);
                } else {
                    groupGuidsWithModulePermissions[mp.GroupGuid] = [mp];
                }
            });

            groupsToExport
                .sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName))
                .forEach((group) => {
                    const worksheet = workbook.addWorksheet(ExcelHelper.prepareSheetName(group.GroupName));
                    worksheet.columns = [{ width: 30 }, { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 40 }] as ExcelJS.Column[]; // cast because of bug in newest version of ExcelJS https://github.com/exceljs/exceljs/issues/1543#issuecomment-742622403
                    ExcelHelper.insertHeaderRow(worksheet, `${Strings.components.sideMenu.modulePermissions} | ${group.GroupName}`);
                    ExcelHelper.insetColumnTitleRow(worksheet, [
                        myModulePermissionStrings.columns.moduleTitle,
                        myModulePermissionStrings.columns.viewTitle,
                        myModulePermissionStrings.columns.canCreateTitle,
                        myModulePermissionStrings.columns.editTitle,
                        myModulePermissionStrings.columns.deleteTitle,
                        myModulePermissionStrings.columns.canExportTitle,
                        myModulePermissionStrings.columns.canSeeHistoryTitle,
                        myModulePermissionStrings.columns.rowsRestrictionTitle,
                    ]);

                    groupGuidsWithModulePermissions[group.ItemGUID]
                        .sort((a, b) => StringHelper.localeCompare(a.Folder, b.Folder))
                        .forEach((mp) => {
                            worksheet.addRow([
                                mp.Folder,
                                modulePermissionsOptionsSettings[mp.View].description,
                                mp.CanCreate ? Strings.yes : Strings.no,
                                modulePermissionsOptionsSettings[mp.Edit].description,
                                modulePermissionsOptionsSettings[mp.Delete].description,
                                mp.CanExport ? Strings.yes : Strings.no,
                                mp.CanSeeHistory ? Strings.yes : Strings.no,
                                !mp.RowsRestriction ? myModulePermissionStrings.options.noRestrictionsText : mp.RowsRestriction.toString(),
                            ]);
                        });
                });
        } else if (exportOption === EXPORT_OPTIONS.groupsWithFieldPermissions) {
            const { objectTypes } = this.context.apiData;
            const otFolders = objectTypes.filter((ot) => ot.IsModule && ot.IsActive).map((ot) => ot.FolderName);
            const columnsRes = await askApi<IApiDataResponse<IApiColumn>>('GetColumns', { includeAdditionalFields: true });
            const columns = columnsRes.Data.filter((col) => !!col.IsPermissionEnabled);
            const columnPermissionsRes = await askApi<IApiDataResponse<IApiColumnPermission>>('GetColumnPermissions', { includeDefaultPermissions: true });

            const groupGuidsWithMappedFolderFieldPermissions: { [groupGuid: string]: { [folderName: string]: TFieldPermissionWithCol[] } } = {};
            const availableGroupGuids = groupsToExport.map((group) => group.ItemGUID);
            const fieldPermissions: TFieldPermissionWithCol[] = [];

            const insertColumnTitleRow = (worksheet: ExcelJS.Worksheet, groupName: string) => {
                const columnTitles = [
                    myFieldPermissionStrings.columns.columnTitle,
                    myFieldPermissionStrings.columns.permissionTitle,
                    myFieldPermissionStrings.columns.isMandatoryTitle,
                    myFieldPermissionStrings.columns.isOptionalTitle,
                ];
                if (groupName === GroupNames.system) {
                    columnTitles.push(myFieldPermissionStrings.columns.isUniqueTitle);
                }
                ExcelHelper.insetColumnTitleRow(worksheet, columnTitles);
            };

            columnPermissionsRes.Data.forEach((colPermission) => {
                const column = columns.find((col) => col.ColumnName === colPermission.ColumnName && col.FolderName === colPermission.FolderName)!;

                if (!column || !availableGroupGuids.includes(colPermission.GroupGuid) || !otFolders.includes(colPermission.FolderName)) {
                    return;
                }

                const fieldPermission = {
                    ...colPermission,
                    col: column,
                    colName: Fields.getColumnName(column),
                };

                fieldPermissions.push(fieldPermission);

                if (groupGuidsWithMappedFolderFieldPermissions[colPermission.GroupGuid]) {
                    if (groupGuidsWithMappedFolderFieldPermissions[fieldPermission.GroupGuid][fieldPermission.FolderName]) {
                        groupGuidsWithMappedFolderFieldPermissions[colPermission.GroupGuid][colPermission.FolderName].push(fieldPermission);
                    } else {
                        groupGuidsWithMappedFolderFieldPermissions[colPermission.GroupGuid][colPermission.FolderName] = [fieldPermission];
                    }
                } else {
                    groupGuidsWithMappedFolderFieldPermissions[colPermission.GroupGuid] = { [colPermission.FolderName]: [fieldPermission] };
                }
            });

            groupsToExport
                .sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName))
                .forEach((group) => {
                    const worksheet = workbook.addWorksheet(ExcelHelper.prepareSheetName(group.GroupName));
                    worksheet.columns = [{ width: 30 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 15 }] as ExcelJS.Column[]; // cast because of bug in newest version of ExcelJS https://github.com/exceljs/exceljs/issues/1543#issuecomment-742622403
                    ExcelHelper.insertHeaderRow(worksheet, `${myStrings.fieldPermissions} | ${group.GroupName}`);

                    Object.entries(groupGuidsWithMappedFolderFieldPermissions[group.ItemGUID])
                        .sort(([aFolderName], [bFolderName]) => StringHelper.localeCompare(FolderNames.getPluralName(aFolderName), FolderNames.getPluralName(bFolderName)))
                        .forEach(([folderName, fieldPermissions]) => {
                            const folderNameRow = worksheet.addRow([FolderNames.getPluralName(folderName)]);
                            folderNameRow.font = { ...folderNameRow.font, size: 14, bold: true };
                            insertColumnTitleRow(worksheet, group.GroupName);
                            fieldPermissions
                                .sort((a, b) => StringHelper.localeCompare(a.colName, b.colName))
                                .forEach((fp) => {
                                    const row = [
                                        fp.colName,
                                        fieldPermissionsOptionsSettings[fp.PermissionRule as keyof typeof fieldPermissionsOptionsSettings].description,
                                        fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory ? Strings.yes : Strings.no,
                                        fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional ? Strings.yes : Strings.no,
                                    ];

                                    if (group.GroupName === GroupNames.system) {
                                        row.push(fp.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Unique ? Strings.yes : Strings.no);
                                    }

                                    worksheet.addRow(row);
                                });
                            // Add two spacing rows at the bottom of each folder section
                            worksheet.addRows([[], []]);
                        });
                });
        }
        ExcelHelper.exportToExcel(workbook, EXPORT_FILENAMES[exportOption], () => {
            this.setState({ isExporting: false });
        });
    };

    private readonly dismissAction = (callback?: () => void) => {
        this.setState(
            {
                isAssigningUsers: false,
                isDuplicatingGroup: false,
                isDeletingGroups: false,
                loadingEdittedGroupItemGuid: null,
            }, () => {
                callback && callback();
                this.props.history.push(RouteConfig.groups.path);
            }
        );
    };

    private readonly dismissActionAndReload = () => {
        this.dismissAction(() => void this.reload());
    };

    private readonly getUsersForAssigning = (takeInactive: boolean, notRealtedToAll: TApiItemWithReleations<IApiGroup>[]): IApiUser[] => {
        return this.state.usersData.filter((user) =>
            !user.IsSystem
            && (takeInactive || user.IsActive)
            && (notRealtedToAll.length === 0 || !notRealtedToAll.every(group => group.Relations.findIndex(rel => rel.ForeignItemGUID === user.ItemGUID && rel.RelationType === RelationTypes.group) !== -1))
        );
    };

    private readonly getRouteMatchParams = () => {
        const matchEdit: IRouterMatch<{ guid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.groups.edit.pathWithGuid });
        const editedGroupGuid = matchEdit?.params?.guid;
        const isEditedGroupGuidValid = editedGroupGuid && uuidValidate(editedGroupGuid);

        return {
            editedGroupGuid,
            isEditedGroupGuidValid,
        };
    };

    private readonly RowComponent: React.FC<TableSelection.RowProps> = (props) => {
        const onEdit = (row: IApiGroup) => {
            this.editGroup(row);
        };

        return <GridTable.RowComponent {...props} onEdit={onEdit} />;
    };

    render() {
        const CellComponent: React.FunctionComponent<Table.DataCellProps> = ({ column, value, row, ...restProps }) => {
            const typedRow = row as IApiGroup;
            const typedValue = value as React.ReactChild | null;
            const tdClass = 'align-middle';
            let cellEdit;
            if (this.state.loadingEdittedGroupItemGuid === typedRow.ItemGUID) {
                cellEdit = (
                    <div style={{ minHeight: '22px' }} className="ml-2 d-inline-flex">
                        <BsSpinner style={{ margin: 'auto 0' }} animation="border" size="sm" />
                    </div>
                );
            } else {
                if (!this.state.loadingEdittedGroupItemGuid) {
                    cellEdit = (
                        <Button
                            style={{ minHeight: '22px' }}
                            variant="link"
                            onClick={(e) => {
                                e.stopPropagation();
                                this.editGroup(typedRow);
                            }}
                            className="p-0 ml-2 border-0 invisible d-parent-tr-hover-visiblie"
                        >
                            <i className="mdl2 mdl2-edit" aria-hidden="true" />
                        </Button>
                    );
                } else {
                    cellEdit = <div style={{ minHeight: '22px' }} />;
                }
            }

            return (
                <VirtualTable.Cell
                    column={column}
                    value={typedValue}
                    row={typedRow}
                    {...restProps}
                    className={column.name === 'GroupName' ? mergeStyles(tdClass, 'highlighted fullName') : tdClass}
                >
                    {column.name === 'GroupName' ? (
                        <div style={{ maxHeight: '22px' }} className="w-100 d-inline-flex justify-content-between">
                            <div className="text-truncate">
                                {typedValue}
                                <GroupBadgeFlags item={typedRow} />
                            </div>
                            {cellEdit}
                        </div>
                    ) : (
                        <>{typedValue}</>
                    )}
                </VirtualTable.Cell>
            );
        };

        const selectedGroups = this.getSelectedGroups();
        const groupsForDeletion = selectedGroups.filter((grp) => !grp.IsAdmin && !grp.System);
        const noGroupsString = this.state.searchedString ? myStrings.noGroupsMatch : myStrings.noGroups;
        const { editedGroupGuid, isEditedGroupGuidValid } = this.getRouteMatchParams();
        const { isUserRolesReadOnly, userRolesLicenseRestriction } = this.context.licenseRestrictionsHelper.isUserRolesReadOnly();
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();

        return (
            <div className="container-fluid d-flex flex-column min-h-100">
                {this.state.isExporting && <SpinnerModal variant={SpinnerVariant.linear} />}
                <div className="row">
                    <div className="col p-0">
                        <div>
                            <h1>{myStrings.title}</h1>
                            <p className="text-medium">{myStrings.subtitle}</p>
                        </div>
                        <hr className="mb-1 mt-4" />
                        {this.state.areDataLoaded && (
                            <div className="d-block">
                                <Switch>
                                    <Route path={RouteConfig.groups.new.path}>
                                        {isUserRolesReadOnly && <Redirect to={RouteConfig.groups.path} />}
                                        <NewGroupWizard
                                            onDismiss={this.dismissAction}
                                            availableUsers={this.getUsersForAssigning(false, [])}
                                            forbiddenGroupNames={this.state.data.map((group) => group.GroupName)}
                                            pmGroupItem={this.getPmGroupItem()}
                                            onDone={this.dismissActionAndReload}
                                            myColumnPermissionsMap={this.state.myColumnPermissionsMap}
                                        />
                                    </Route>
                                    <Route path={RouteConfig.groups.edit.pathWithGuid}>
                                        {!isEditedGroupGuidValid ? (
                                            <Redirect to={RouteConfig.groups.path} />
                                        ) : (
                                            <LoaderProvider
                                                key={editedGroupGuid}
                                                loadData={this.getEditedGroupData}
                                                render={([editedGroupItem, modulePermissions, fieldPermissions, otFolders, defaultGroupUserGuids, layoutsModels]) => (
                                                    <EditGroupWizard
                                                        onDismiss={this.dismissAction}
                                                        allGroups={this.state.data}
                                                        groupItem={editedGroupItem}
                                                        folders={otFolders}
                                                        forbiddenGroupNames={this.state.data
                                                            .filter((group) => group.ItemGUID !== editedGroupItem?.ItemGUID)
                                                            .map((group) => group.GroupName)}
                                                        availableUsers={this.getUsersForAssigning(true, [])}
                                                        pmGroupItem={this.getPmGroupItem()}
                                                        modulePermissions={modulePermissions}
                                                        fieldPermissions={fieldPermissions}
                                                        onDone={this.dismissActionAndReload}
                                                        myColumnPermissionsMap={this.state.myColumnPermissionsMap}
                                                        defaultGroupUserGuids={defaultGroupUserGuids}
                                                        layouts={this.state.layouts}
                                                        groupLayoutsModels={layoutsModels}
                                                    />
                                                )}
                                            />
                                        )}
                                    </Route>
                                </Switch>
                                {this.state.isAssigningUsers && (
                                    <AssignUsersWizard
                                        onDismiss={this.dismissAction}
                                        selectedGroups={selectedGroups}
                                        availableUsers={this.getUsersForAssigning(false, selectedGroups)}
                                        onDone={this.dismissActionAndReload}
                                    />
                                )}
                                {this.state.isDuplicatingGroup && (
                                    <DuplicateGroupWizard
                                        onDismiss={this.dismissAction}
                                        group={selectedGroups[0]}
                                        allGroups={this.getDisplayedData()}
                                        forbiddenGroupNames={this.getForbiddenGroupNames(this.state.data)}
                                        onDone={this.dismissActionAndReload}
                                    />
                                )}
                                {this.state.isDeletingGroups && (
                                    <DeleteGroupWizard onDismiss={this.dismissAction} selectedGroups={groupsForDeletion} onDone={this.dismissActionAndReload} />
                                )}
                                <div className="container max-w-none mx-0 mb-3 p-0">
                                    <div className="row justify-content-end">
                                        <div className="col">
                                            <ButtonToolbar>
                                                <TooltipHost content={isUserRolesReadOnly ? <LicenseRestrictionsTooltipContent licenseRestriction={userRolesLicenseRestriction} /> : undefined}>
                                                    <Link to={RouteConfig.groups.new.path}>
                                                        <FakeDisabledButton variant="outline-secondary" disabled={isUserRolesReadOnly}>
                                                            <i className="mdl2 mdl2-add" aria-hidden="true" /> {myStrings.addGroup}
                                                        </FakeDisabledButton>
                                                    </Link>
                                                </TooltipHost>
                                                <Button variant="outline-secondary" onClick={() => void this.reload()}>
                                                    <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                                </Button>
                                                <Button variant="outline-secondary" onClick={this.assignUsers} disabled={this.state.selection.length === 0}>
                                                    <i className="mdl2 mdl2-add-friend" aria-hidden="true" /> {myStrings.assignUsers}
                                                </Button>
                                                <TooltipHost content={isUserRolesReadOnly ? <LicenseRestrictionsTooltipContent licenseRestriction={userRolesLicenseRestriction} /> : undefined}>
                                                    <FakeDisabledButton variant="outline-secondary" onClick={this.duplicateGroup} disabled={isUserRolesReadOnly || this.state.selection.length !== 1}>
                                                        <i className="mdl2 mdl2-copy" aria-hidden="true" /> {Strings.duplicate}
                                                    </FakeDisabledButton>
                                                </TooltipHost>

                                                <TooltipHost content={isUserRolesReadOnly ? <LicenseRestrictionsTooltipContent licenseRestriction={userRolesLicenseRestriction} /> : undefined}>
                                                    <FakeDisabledButton
                                                        variant="outline-secondary"
                                                        onClick={this.deleteGroups}
                                                        disabled={
                                                            isUserRolesReadOnly ||
                                                            this.state.selection.length === 0 ||
                                                            groupsForDeletion.length === 0 ||
                                                            this.state.selection.length !== groupsForDeletion.length
                                                        }
                                                    >
                                                        <i className="mdl2 mdl2-delete" aria-hidden="true" /> {Strings.delete}
                                                    </FakeDisabledButton>
                                                </TooltipHost>

                                                <Dropdown>
                                                    <Dropdown.Toggle className="exportBtn__toggle" title={Strings.exportToExcel} variant="outline-secondary">
                                                        <FontIcon iconName="ExcelDocument" /> {Strings.export}
                                                        <FontIcon className="pl-1 exportBtn__chevronDown" iconName="ChevronDown" />
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu className="exportBtn__menu">
                                                        <Dropdown.Item className="exportBtn__item" eventKey="1" onClick={() => void this.exportExcelWorkbook(EXPORT_OPTIONS.groupsList)}>
                                                            {myStrings.exportThisTable}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            className="exportBtn__item"
                                                            eventKey="2"
                                                            onClick={() => {
                                                                if (isModulePermissionsLocked) {
                                                                    this.context.showLicenseRestrictionModal(modulePermissionsLicenseRestriction);
                                                                    return;
                                                                }
                                                                void this.exportExcelWorkbook(EXPORT_OPTIONS.groupsWithModulePermissions);
                                                            }}
                                                        >
                                                            {myStrings.exportGroupsWithModulePermissions} {isModulePermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={modulePermissionsLicenseRestriction} />}
                                                        </Dropdown.Item>
                                                        <Dropdown.Item
                                                            className="exportBtn__item"
                                                            eventKey="3"
                                                            onClick={() => {
                                                                if (isFieldPermissionsLocked) {
                                                                    this.context.showLicenseRestrictionModal(fieldPermissionsLicenseRestriction);
                                                                    return;
                                                                }
                                                                void this.exportExcelWorkbook(EXPORT_OPTIONS.groupsWithFieldPermissions);
                                                            }}
                                                        >
                                                            {myStrings.exportGroupsWithFieldPermissions} {isFieldPermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={fieldPermissionsLicenseRestriction} />}
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </ButtonToolbar>
                                        </div>
                                        <div className="col-auto">
                                            <Button variant="outline-secondary" onClick={this.deselect} hidden={this.state.selection.length === 0}>
                                                {Strings.formatString(Strings.selectedFormat, this.state.selection.length)} <i className="mdl2 mdl2-cancel" aria-hidden="true" />
                                            </Button>
                                        </div>
                                        <div className="col-auto">
                                            <Form.Control
                                                type="text"
                                                placeholder={Strings.search}
                                                value={this.state.searchedString}
                                                onChange={(e) => this.setState({ searchedString: e.target.value })}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="row flex-fill d-flex justify-content-start mt-2">
                    {this.state.areDataLoaded ?
                        <div className="col p-0">
                            <Grid
                                rows={this.getDisplayedData()}
                                columns={gridColumns}
                                rootComponent={GridTable.Root}
                                getRowId={(row: IApiGroup) => row.ItemGUID}
                            >
                                <SelectionState
                                    selection={this.state.selection}
                                    onSelectionChange={(sel) => this.setState({ selection: sel as string[] })}
                                />
                                <SearchState
                                    value={this.state.searchedString}
                                    onValueChange={searchedString => this.setState({ searchedString: searchedString })}
                                />
                                <SortingState
                                    sorting={this.state.sort}
                                    onSortingChange={sort => this.setState({ sort: sort })}
                                />
                                <IntegratedSelection />
                                <IntegratedFiltering />
                                <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                                <VirtualTable
                                    containerComponent={GridTable.TableContainerComponent}
                                    columnExtensions={tableColumnExtensions}
                                    cellComponent={CellComponent}
                                    messages={{ noData: noGroupsString }}
                                    noDataCellComponent={GridTable.NoDataCell}
                                />
                                <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                                <TableSelection
                                    showSelectAll
                                    selectByRowClick
                                    highlightRow
                                    rowComponent={this.RowComponent}
                                />
                            </Grid>
                        </div>
                        :
                        <div className="col p-0 align-self-center text-center">
                            <Spinner variant={SpinnerVariant.ease} />
                        </div>
                    }
                </div>
            </div>
        );
    }
}