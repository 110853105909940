import { FolderNames as LibFolderNames } from '@eway-crm/connector';
import Strings from "../../strings";
import additionalFieldsIconImg from './../../img/folders/AdditionalFields.svg';
import bonusesIconImg from './../../img/folders/Bonuses.svg';
import calendarIconImg from './../../img/folders/Calendar.svg';
import cartsIconImg from './../../img/folders/Carts.svg';
import companiesIconImg from './../../img/folders/Companies.svg';
import contactsIconImg from './../../img/folders/Contacts.svg';
import currencyExchangeRatesIconImg from './../../img/folders/CurrencyExchangeRates.svg';
import documentsIconImg from './../../img/folders/Documents.svg';
import emailsIconImg from './../../img/folders/Emails.svg';
import enumTypesIconImg from './../../img/folders/EnumTypes.svg';
import goalsIconImg from './../../img/folders/Goals.svg';
import goodsIconImg from './../../img/folders/Goods.svg';
import goodsInSetIconImg from './../../img/folders/GoodsInSet.svg';
import groupsIconImg from './../../img/folders/Groups.svg';
import individualDiscountsIconImg from './../../img/folders/IndividualDiscounts.svg';
import journalIconImg from './../../img/folders/Journal.svg';
import knowledgeIconImg from './../../img/folders/Knowledge.svg';
import leadsIconImg from './../../img/folders/Leads.svg';
import marketingIconImg from './../../img/folders/Marketing.svg';
import marketingListIconImg from './../../img/folders/MarketingList.svg';
import projectsIconImg from './../../img/folders/Projects.svg';
import reportsIconImg from './../../img/folders/Reports.svg';
import salariesIconImg from './../../img/folders/Salaries.svg';
import salePricesIconImg from './../../img/folders/SalePrices.svg';
import tasksIconImg from './../../img/folders/Tasks.svg';
import trainingIconImg from './../../img/folders/Training.svg';
import usersIconImg from './../../img/folders/Users.svg';
import vacationIconImg from './../../img/folders/Vacation.svg';
import workCommitmentsIconImg from './../../img/folders/WorkCommitments.svg';
import workReportsIconImg from './../../img/folders/WorkReports.svg';
import projectAssignmentsIconImg from './../../img/folders/ProjectAssignments.svg';
import ewayCircleLogoImg from '@eway-crm/js-resources/assets/ewayLogo/eWayCRM/eWayCRM-red-circle.svg';
import { FolderNamesHelper } from '@eway-crm/gui';

class FolderNames extends LibFolderNames {

    static getPluralName(folderName: string): string {
        return FolderNamesHelper.getPluralName(folderName);
    }

    static getAllStringForms(folderName: string): { plural: string; singular: string; synonyms: string } {
        const { singular, plural } = FolderNamesHelper.getAllStringForms(folderName);
        const synonyms = Strings.components.search.folderNameSynonyms[folderName as keyof typeof Strings.components.search.folderNameSynonyms];

        return { plural: plural ?? folderName, singular: singular ?? folderName, synonyms: synonyms ?? folderName };
    }

    static getIcon(folderName: string) {
        if (folderName === LibFolderNames.additionalFields) return additionalFieldsIconImg;
        if (folderName === LibFolderNames.bonuses) return bonusesIconImg;
        if (folderName === LibFolderNames.calendar) return calendarIconImg;
        if (folderName === LibFolderNames.carts) return cartsIconImg;
        if (folderName === LibFolderNames.companies) return companiesIconImg;
        if (folderName === LibFolderNames.contacts) return contactsIconImg;
        if (folderName === LibFolderNames.currencyExchangeRates) return currencyExchangeRatesIconImg;
        if (folderName === LibFolderNames.documents) return documentsIconImg;
        if (folderName === LibFolderNames.emails) return emailsIconImg;
        if (folderName === LibFolderNames.goals) return goalsIconImg;
        if (folderName === LibFolderNames.goods) return goodsIconImg;
        if (folderName === LibFolderNames.goodsInSet) return goodsInSetIconImg;
        if (folderName === LibFolderNames.groups) return groupsIconImg;
        if (folderName === LibFolderNames.individualDiscounts) return individualDiscountsIconImg;
        if (folderName === LibFolderNames.journal) return journalIconImg;
        if (folderName === LibFolderNames.knowledge) return knowledgeIconImg;
        if (folderName === LibFolderNames.leads) return leadsIconImg;
        if (folderName === LibFolderNames.marketing) return marketingIconImg;
        if (folderName === LibFolderNames.marketingList) return marketingListIconImg;
        if (folderName === LibFolderNames.projects) return projectsIconImg;
        if (folderName === LibFolderNames.reports) return reportsIconImg;
        if (folderName === LibFolderNames.salaries) return salariesIconImg;
        if (folderName === LibFolderNames.salePrices) return salePricesIconImg;
        if (folderName === LibFolderNames.tasks) return tasksIconImg;
        if (folderName === LibFolderNames.training) return trainingIconImg;
        if (folderName === LibFolderNames.users) return usersIconImg;
        if (folderName === LibFolderNames.vacation) return vacationIconImg;
        if (folderName === LibFolderNames.workReports) return workReportsIconImg;
        if (folderName === LibFolderNames.workCommitments) return workCommitmentsIconImg;
        if (folderName === LibFolderNames.enumTypes) return enumTypesIconImg;
        if (folderName === LibFolderNames.goodsInCart) return goodsIconImg; // Uses the same icon as goods in eWay-CRM OL
        if (folderName === LibFolderNames.projectAssignments) return projectAssignmentsIconImg;

        return ewayCircleLogoImg;
    }
}

export default FolderNames;