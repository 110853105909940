export default class ObjectHelper {
    static sortObjectByKeyNameList = <T>(object: { [key: string]: Array<T> }, sortKeys: string[]): Record<string, Array<T>> => {
        const objectKeys = Object.keys(object);

        const nonSortedKeys = objectKeys.filter(ok => !sortKeys.includes(ok));
        sortKeys.push(...nonSortedKeys); // Add keys not included in sortKeys at the end 

        return sortKeys.reduce((total: { [key: string]: Array<T> }, key: string) => {
            if (objectKeys.includes(key)) {
                total[key] = object[key];
            }
            return total;
        }, {});
    };

    static pickObjectProperties = <T>(object: Record<string, T>, properties: string[]): Record<string, T> => {
        return Object.assign({}, ...properties.map(property => ({ [property]: object[property] }))) as Record<string, T>;
    };

    /**
     * If the key already exists, it pushes value to array at object key. If key doesn't exist, it creates it with item in array.  
     * Mutates existing object.
     * @param object Object to add value to
     * @param key Key to add value to 
     * @param itemToAdd Item to add to object
     */
    static addToArrayInObjectKey = <TKey extends (string | number | symbol), TValue>(object: Record<TKey, TValue[]>, key: TKey, itemToAdd: TValue) => {
        if (!object[key]) {
            object[key] = [itemToAdd];
        } else {
            object[key].push(itemToAdd);
        }
    };
}
