import { Icon } from '@fluentui/react';
import { forwardRef } from 'react';
import { Spinner } from 'react-bootstrap';
import Strings from '../../../strings';
import type { TAutosuggestRefHandle } from '../../shared/Autosuggest/Autosuggest';
import Autosuggest from '../../shared/Autosuggest/Autosuggest';
import { AutosuggestContextConsumer } from '../../shared/Autosuggest/AutosuggestContext';
import AutosuggestInput from '../../shared/Autosuggest/AutosuggestInput';
import AutosuggestItems from '../../shared/Autosuggest/AutosuggestItems';

type TWorkflowMenuPanelHeadProps = {
    value: string;
    onValueChange: (newValue: string) => void;
    onValueSelection: (newValue: string) => void;
    invalid: boolean;
    isCreatingNew: boolean;
    disabled: boolean;
};

const myStrings = Strings.components.routes.workflow;

const WorkflowMenuPanelHead = forwardRef<TAutosuggestRefHandle, TWorkflowMenuPanelHeadProps>(({ value, invalid, isCreatingNew, onValueChange, onValueSelection, disabled }, autosuggestRef) => {
    const isValueInvalid = !!value && invalid;
    return (
        <div className="d-flex flex-wrap w-100">
            <Autosuggest
                value={value}
                onValueChange={onValueChange}
                onValueSelection={onValueSelection}
                className="mx-3 add-workflow-autosuggest"
                allowCreatingNewItems
                suggestions={[]}
                invalid={isValueInvalid}
                ref={autosuggestRef}
                disabled={disabled}
            >
                <AutosuggestContextConsumer>
                    {({ isInputFocused, disabled }) => <Icon iconName="Add" className="pl-1 pr-2" style={{ fontSize: '1rem', color: (isInputFocused || value) && !disabled ? 'initial' : '#ced4da' }} />}
                </AutosuggestContextConsumer>
                {!isCreatingNew ? (
                    <>
                        <AutosuggestInput placeholder={myStrings.addNewWorkflow} className="form-control" />
                        <AutosuggestItems />
                    </>
                ) : (
                    <div className="d-flex flex-no-wrap justify-content-between align-items-center w-100">
                        <div className="form-control text-truncate border-0 py-1 pr-1" style={{ maxWidth: '185px', lineHeight: '1.75rem' }}>
                            {value}
                        </div>
                        <Spinner size="sm" animation="border" style={{ minWidth: '1rem' }} />
                    </div>
                )}
            </Autosuggest>
            {!isValueInvalid ? (
                <div style={{ marginBottom: '19px' }} />
            ) : (
                <div className="text-danger ml-3" style={{ marginBottom: '1px' }}>
                    {myStrings.nameAlreadyUsed}
                </div>
            )}
        </div>
    );
});
export default WorkflowMenuPanelHead;
