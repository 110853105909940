import type { Feature } from '@eway-crm/connector';
import { Edition } from '@eway-crm/connector';
import { Link, mergeStyles, mergeStyleSets, NeutralColors, TeachingBubble } from '@fluentui/react';
import React, { useEffect, useRef, useState } from 'react';
import type { IRow } from '../../../../data/shopping/ISubscriptionsShoppingModel';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import Strings from '../../../../strings';
import HtmlComponent from '../../../shared/HtmlComponent';
import CheckboxRow from './CheckboxRow/CheckboxRow';
import EditionPickerRow from './EditionPickerRow/EditionPickerRow';
import useScrollToMarketingPackageRowByUrlParam from './hooks/useScrollToMarketingPackageRowByUrlParam';
import LegacyRow from './LegacyRow/LegacyRow';
import SliderRow from './SliderRow/SliderRow';
import StoreV2Context from './StoreV2Context';

export const packagesPanelWidth = 600;

const css = mergeStyleSets({
    rowHighlight: {
        position: 'absolute',
        top: 0,
        bottom: -20,
        left: 0,
        right: -20,
        backgroundColor: 'rgba(0, 0, 0, 0.05)',
        zIndex: 100,
    },
    subtitle: {
        fontSize: '14px',
        marginBottom: '14px !important',
        maxWidth: packagesPanelWidth + 'px',
    },
    rowTooltip: {
        marginLeft: '1rem',
    },
    notInCartMessage: {
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: NeutralColors.gray70,
        borderRadius: '2px',
        color: NeutralColors.gray150,
        fontSize: '12px',
        textAlign: 'center',
        padding: '0.75rem'
    }
});

type TStoreV2RowProps = {
    row: IRow;
    artnoToFeatureMap: Record<string, Feature>;
    ekArtnos: string[];
};

const StoreV2Row: React.FC<TStoreV2RowProps> = ({ row, artnoToFeatureMap, ekArtnos }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const { showLicenseRestrictionModal } = React.useContext(ConnectionContext);
    const { packagesEditingStates } = React.useContext(StoreV2Context);

    const isAnythingInCart = React.useMemo(() => {
        if (!row.Artnos || row.Artnos.length === 0)
            return false;
        return row.Artnos.some((artno) => {
            const es = packagesEditingStates.find(ps => ps.artno === artno);
            return !!es && !!es.state.currentEditQuantity;
        });
    }, [row.Artnos, packagesEditingStates]);

    const getRowFeature = () => {
        if (!row.Artnos || row.Artnos.length === 0) {
            return null;
        }

        // Take first ArtNo since we assume that product contains only one feature
        const firstArtno = row.Artnos[0];

        return artnoToFeatureMap[firstArtno];
    };

    const rowFeature = getRowFeature();
    const showCompareLink = !!rowFeature && (row.Mode === 'EditionPicker' || row.Mode === 'EditionPickerWithSlider');
    const handleComparePlans = () => {
        if (!showCompareLink) {
            return;
        }

        showLicenseRestrictionModal({ availableInEdition: Edition.Enterprise, availableInFeature: rowFeature }, { compareAllPlans: true });
    };

    const { rowRef, isRowHighlighted, isTooltipVisible, setIsTooltipVisible } = useScrollToMarketingPackageRowByUrlParam(!!row.Artnos?.find(artno => ekArtnos.includes(artno)));

    if (!row.Artnos || row.Artnos.length === 0)
        return null;

    const rowId = `${row.Artnos.join('')}`;

    return (
        <>
            <div ref={rowRef} id={rowId} className={mergeStyles('mb-5 position-relative')}>
                {isRowHighlighted && <div className={css.rowHighlight} />}
                <div className="d-flex align-items-end justify-content-between mb-2">
                    <h3 className="mb-0">{Strings.pickTranslation(row.Title)}</h3>
                    {showCompareLink && (
                        <Link className="text-medium" onClick={handleComparePlans}>
                            {myStrings.comparePlans}
                        </Link>
                    )}
                </div>
                {!!row.Subtitle && <div className={css.subtitle}>{Strings.pickTranslation(row.Subtitle)}</div>}
                {row.Mode === 'LegacyMultiPicker' && <LegacyRow row={row} />}
                {row.Mode === 'EditionPicker' && <EditionPickerRow row={row} />}
                {row.Mode === 'Slider' && <SliderRow row={row} />}
                {row.Mode === 'Checkbox' && <CheckboxRow row={row} />}
                {row.Mode === 'EditionPickerWithSlider' && <EditionPickerRow isWithSlider row={row} />}

                {(!!row.MessageIfNothingInCart) &&
                    <div style={{ transition: 'max-height 0.3s ease-out', maxHeight: isAnythingInCart ? '0px' : '100px', overflow: 'hidden' }}>
                        <div className={mergeStyles(css.notInCartMessage, 'mt-3')}>
                            <HtmlComponent htmlString={Strings.pickTranslation(row.MessageIfNothingInCart)} />
                        </div>
                    </div>
                }
            </div>
            {isTooltipVisible && <RowTeachingBubble onDismiss={() => setIsTooltipVisible(false)} rowId={rowId} rowRef={rowRef} />}
        </>
    );
};

type TRowTooltipProps = {
    onDismiss: () => void;
    rowId: string;
    rowRef: React.RefObject<HTMLDivElement>;
};

const RowTeachingBubble: React.FC<TRowTooltipProps> = ({ onDismiss, rowId, rowRef }) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [rerenderCount, setRerenderCount] = useState(0);
    const [isIntersecting, setIsIntersecting] = useState(true);
    const ref = useRef<HTMLDivElement>(null!);
    const myStrings = Strings.components.routes.subscriptions;

    useEffect(() => {
        const refCurrent = rowRef.current;
        const observer = new IntersectionObserver((entries) => {
            const [entry] = entries;
            setIsIntersecting(entry.isIntersecting);
        }, {
            root: null,
            rootMargin: "0px",
            threshold: 0.8
        });

        if (refCurrent) {
            observer.observe(refCurrent);
            return () => {
                observer.unobserve(refCurrent);
            };
        }

    }, [rowRef, onDismiss]);

    const handleDismiss = (ev: React.MouseEvent) => {
        if ('target' in ev) {
            const event = ev;
            if (ev.type === 'scroll') {
                // We need to rerender each time on scroll to keep TeachingBubble in correct position
                // https://github.com/microsoft/fluentui/issues/18222#issuecomment-879054327
                setRerenderCount(prev => prev + 1);
                return;
            }
            if (event.currentTarget?.className?.includes('ms-TeachingBubble-closebutton')) {
                // Only dismiss on close button click
                onDismiss();
            }
        }
    };

    return (
        <TeachingBubble
            ref={ref}
            target={`#${rowId}`}
            hasCondensedHeadline={true}
            onDismiss={handleDismiss}
            hasCloseButton
            calloutProps={{ className: mergeStyles(css.rowTooltip, !isIntersecting && "invisible") }}
            headline={myStrings.rowTeachingBubbleTitle}
        >
            {myStrings.rowTeachingBubbleText}
        </TeachingBubble>
    );
};

export default StoreV2Row;
