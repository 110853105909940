import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import React, { useCallback } from 'react';
import { Button } from 'react-bootstrap';
import StringHelper from '../../../../helpers/StringHelper';
import Strings from '../../../../strings';
import GridTable from '../../../GridTable';
import GroupAvatar from '../../../shared/groups/GroupAvatar';

type TGridRow = {
    itemId: string;
    itemName: string;
    itemDescription: string | null;
    isUserInGroup: boolean;
    isDisabled: boolean;
};

const gridColumns = [
    { name: 'icon', title: '' },
    { name: 'itemName', title: '' },
    { name: 'action', title: '' },
];

const columnExtensions: VirtualTable.ColumnExtension[] = [
    { columnName: 'icon', width: '54px' },
    { columnName: 'itemName', width: '138px' },
    { columnName: 'action', width: '72px' },
];

type TGroupsToggleMenuProps = {
    items: TGridRow[];
    onSelectItem?: (newItemId: string) => void;
};

const GroupsToggleMenu: React.FC<TGroupsToggleMenuProps> = ({ items, onSelectItem }) => {
    const getDisplayedData = () => {
        return items.sort((a, b) => StringHelper.localeCompare(a.itemName, b.itemName));
    };

    const CellComponent: React.FunctionComponent<Table.DataCellProps> = useCallback(
        (props) => {
            const typedRow = props.row as TGridRow;
            if (props.column.name === 'icon') {
                return (
                    <VirtualTable.Cell className="secondary-menu__iconCell" {...props}>
                        <GroupAvatar item={{ GroupName: typedRow.itemName, ItemGUID: typedRow.itemId }} size={28} />
                    </VirtualTable.Cell>
                );
            } else if (props.column.name === 'action') {
                return (
                    <VirtualTable.Cell className="align-middle text-right text-truncate pr-1" {...props}>
                        <Button disabled={typedRow.isDisabled} className="p-0" variant="link" onClick={() => onSelectItem && onSelectItem(typedRow.itemId)}>
                            {typedRow.isUserInGroup ? Strings.remove : Strings.add}
                        </Button>
                    </VirtualTable.Cell>
                );
            }

            return (
                <VirtualTable.Cell className="align-middle text-truncate secondary-menu__contentCell" {...props} title={typedRow.itemDescription}>
                    {props.value}
                    <div className="text-truncate secondary-menu__cellDescription">{typedRow.itemDescription}</div>
                </VirtualTable.Cell>
            );
        },
        [onSelectItem]
    );

    return (
        <Grid rows={getDisplayedData()} columns={gridColumns} rootComponent={GridTable.Root} getRowId={(row: TGridRow) => row.itemId}>
            <VirtualTable height={500} containerComponent={GridTable.SideMenuTableContainerComponent} cellComponent={CellComponent} columnExtensions={columnExtensions} />
        </Grid>
    );
};
export default GroupsToggleMenu;
