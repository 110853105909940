import { Slider } from '@fluentui/react';
import * as React from 'react';
import UnitHelper from '../../../../../helpers/UnitHelper';
import { NumberFormatter } from '../../../../shared/NumberFormatter';
import usePackage from '../hooks/usePackage';
import StoreV2Context from '../StoreV2Context';

type TPackageQuantitySliderProps = {
    artno: string;
    isExternallyDisabled?: boolean;
};

const PackageQuantitySlider: React.FC<TPackageQuantitySliderProps> = ({ artno, isExternallyDisabled }) => {
    const { packageData, packageEditingState, isDisabled, steps } = usePackage(artno);
    const { setPackageEditingState } = React.useContext(StoreV2Context);

    const packageQuantity = packageEditingState.currentEditQuantity || 0;

    // If the current quantity is outside the step, fix it.
    React.useEffect(() => {
        if (isDisabled || isExternallyDisabled)
            return;

        if (!steps || steps.length === 0)
            return;

        if (steps.includes(packageQuantity))
            return;

        let step = steps.find(s => s >= packageQuantity);
        if (typeof step === 'undefined') {
            step = steps[steps.length - 1];
        }
        setPackageEditingState(artno, { currentEditQuantity: step });
    }, [isDisabled, isExternallyDisabled, steps, packageQuantity, setPackageEditingState, artno]);

    if (!packageData)
        return null;

    let min = 0;
    let max = 9999;
    let value = packageQuantity;
    let valueFormat: ((v: number) => string) | undefined;
    if (steps && steps.length !== 0) {
        min = 0;
        max = steps.length - 1;
        let valueIndex = steps.findIndex(s => s === packageQuantity);
        if (valueIndex < 0) {
            valueIndex = steps.findIndex(s => s >= packageQuantity);
            if (valueIndex < 0) {
                valueIndex = 0;
            }
        }
        value = valueIndex;
        valueFormat = (v) => String(NumberFormatter.format(steps[v], 'metric')) + ' ' + UnitHelper.pickTranslatedUnit(steps[v], packageData.Unit);
    }

    const handleChange = (newValue: number) => {
        if (isDisabled || isExternallyDisabled)
            return;

        if (steps) {
            if (newValue >= 0 && newValue < steps.length) {
                setPackageEditingState(artno, { currentEditQuantity: steps[newValue] });
            }
        } else {
            setPackageEditingState(artno, { currentEditQuantity: newValue });
        }
    };

    return (
        <Slider disabled={isDisabled || isExternallyDisabled} value={value} min={min} max={max} valueFormat={valueFormat} onChange={handleChange} styles={{ valueLabel: { width: 'auto' } }} />
    );
};

export default PackageQuantitySlider;