import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableColumnResizing, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { Sorting, TableColumnWidthInfo, Column } from '@devexpress/dx-react-grid';
import { IntegratedFiltering, IntegratedSorting, SearchState, SortingState } from '@devexpress/dx-react-grid';
import * as React from 'react';
import Strings from '../../../strings';
import GridTable from '../../GridTable';
import { getTheme, mergeStyles } from '@fluentui/react';
import { ReactComponent as CircleFull } from '../../../img/permissions/circle-full.svg';
import { ReactComponent as CircleHalf } from '../../../img/permissions/circle-half.svg';
import { ReactComponent as CircleThreeQuarters } from '../../../img/permissions/circle-threequarters-bottom.svg';
import { ReactComponent as CircleQuarter } from '../../../img/permissions/circle-quarter-bottom.svg';
import StringHelper from '../../../helpers/StringHelper';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import OrbDropdownEditCell from '../../shared/permissions/OrbDropdownEditCell';
import ModulePermissionNames from '../../../data/constants/ModulePermissionNames';
import type { TModulePermissionsLastSeenRowCacheValue } from './FolderModulePermissions';
import type { IApiModulePermission, TFolderName } from '@eway-crm/connector';
import RowsRestrictionsEditCell from './RowsRestrictionsEditCell';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import LicenseRestrictionsLockedGridRow from '../../shared/locks/LicenseRestrictionsLockedGridRow';
import type { TCustomUserLicenseRestrictionsObject } from '../users/EffectivePermissionsWizard';

const GRID_ROOT_ID = 'FolderModulePermissionsGridRoot';
const GRID_CONTAINER_ID = 'FolderModulePermissionsGridContainer';
export const MP_GRID_ROW_HEIGHT = 46;
const ORB_COL_WIDTH = 85;

const theme = getTheme();
const myStrings = Strings.components.routes.modulePermissions;
const currentLanguage = Strings.getLanguage();

export type TCustomTextNode = string | number;

const gridColumns = [
    { name: ModulePermissionNames.Columns.Folder, title: myStrings.columns.moduleTitle },
    { name: ModulePermissionNames.Columns.View, title: myStrings.columns.viewTitle },
    { name: ModulePermissionNames.Columns.CanCreate, title: myStrings.columns.canCreateTitle },
    { name: ModulePermissionNames.Columns.Edit, title: myStrings.columns.editTitle },
    { name: ModulePermissionNames.Columns.Delete, title: myStrings.columns.deleteTitle },
    { name: ModulePermissionNames.Columns.CanExport, title: myStrings.columns.canExportTitle },
    { name: ModulePermissionNames.Columns.CanSeeHistory, title: myStrings.columns.canSeeHistoryTitle },
    { name: ModulePermissionNames.Columns.RowsRestriction, title: myStrings.columns.rowsRestrictionTitle },
];

const gridColumnExtensions: Table.ColumnExtension[] = [
    { columnName: ModulePermissionNames.Columns.View, align: 'center' },
    { columnName: ModulePermissionNames.Columns.CanCreate, align: 'center' },
    { columnName: ModulePermissionNames.Columns.Edit, align: 'center' },
    { columnName: ModulePermissionNames.Columns.Delete, align: 'center' },
    { columnName: ModulePermissionNames.Columns.CanExport, align: 'center' },
    { columnName: ModulePermissionNames.Columns.CanSeeHistory, align: 'center' },
    { columnName: ModulePermissionNames.Columns.RowsRestriction, align: 'left' },
];

const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [{ columnName: ModulePermissionNames.Columns.Folder, compare: StringHelper.localeCompare }];

const sortingStateColumnExtensions: SortingState.ColumnExtension[] = [
    { columnName: ModulePermissionNames.Columns.View, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.CanCreate, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.Edit, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.Delete, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.CanExport, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.CanSeeHistory, sortingEnabled: false },
    { columnName: ModulePermissionNames.Columns.RowsRestriction, sortingEnabled: false },
];

export const modulePermissionsDropdownColumnSettings = {
    View: {
        action: myStrings.columns.viewAction,
        width: currentLanguage === 'en' ? 570 : 630,
    },
    Edit: {
        action: myStrings.columns.editAction,
        width: currentLanguage === 'en' ? 570 : 650,
    },
    Delete: {
        action: myStrings.columns.deleteAction,
        width: currentLanguage === 'en' ? 590 : 680,
    },
    CanCreate: {
        action: myStrings.columns.canCreateAction,
        width: currentLanguage === 'en' ? 210 : 350,
    },
    CanExport: {
        action: myStrings.columns.canExportAction,
        width: currentLanguage === 'en' ? 220 : 280,
    },
    CanSeeHistory: {
        action: myStrings.columns.canSeeHistoryAction,
        width: currentLanguage === 'en' ? 260 : 290,
    },
};

export const modulePermissionsOptionsSettings = {
    All: {
        key: ModulePermissionNames.Options.All,
        text: (action: string) => Strings.formatString(myStrings.options.allPermissionsText, action) as string,
        description: myStrings.options.allPermissionsDescription,
        color: theme.palette.green,
        icon: CircleFull,
    },
    Group: {
        key: ModulePermissionNames.Options.Group,
        text: (action: string) => Strings.formatString(myStrings.options.groupPermissionsText, action) as string,
        description: myStrings.options.groupPermissionsDescription,
        color: theme.palette.green,
        icon: CircleThreeQuarters,
    },
    Inherited: {
        key: ModulePermissionNames.Options.Inherited,
        text: (action: string) => Strings.formatString(myStrings.options.inheritedPermissionsText, action) as string,
        description: myStrings.options.inheritedPermissionsDescription,
        color: theme.palette.green,
        icon: CircleHalf,
    },
    Related: {
        key: ModulePermissionNames.Options.Related,
        text: (action: string) => Strings.formatString(myStrings.options.relatedPermissionsText, action) as string,
        description: myStrings.options.relatedPermissionsDescription,
        color: '#ffc107',
        icon: CircleHalf,
    },
    Own: {
        key: ModulePermissionNames.Options.Own,
        text: (action: string) => Strings.formatString(myStrings.options.ownPermissionsText, action) as string,
        description: myStrings.options.ownPermissionsDescription,
        color: '#ffc107',
        icon: CircleQuarter,
    },
    None: {
        key: ModulePermissionNames.Options.None,
        text: (action: string) =>
            action === myStrings.columns.viewAction ? myStrings.options.nonePermissionsViewText : (Strings.formatString(myStrings.options.nonePermissionsText, action) as string),
        description: myStrings.options.nonePermissionsDescription,
        color: theme.palette.redDark,
        icon: CircleFull,
    },
};

export const modulePermissionsBooleanOptionsSettings = {
    true: {
        key: ModulePermissionNames.BooleanOptions.Yes,
        text: (action: string) => Strings.formatString(myStrings.options.truePermissionsText, action) as string,
        color: theme.palette.green,
        icon: CircleFull,
    },
    false: {
        key: ModulePermissionNames.BooleanOptions.No,
        text: (action: string) => Strings.formatString(myStrings.options.falsePermissionsText, action) as string,
        color: theme.palette.redDark,
        icon: CircleFull,
    },
};

const getWidthOfMainColumn = (tableWidth: number | undefined, minColWidth: number) => {
    if (tableWidth) {
        const newColWidth = tableWidth - 6 * ORB_COL_WIDTH - 160 - 20;
        if (newColWidth > minColWidth) {
            return newColWidth;
        }
        return minColWidth;
    }
    return minColWidth;
};

export type TRowWithEffectiveModulePermissions = IApiModulePermission & { rowPermissions?: (IApiModulePermission & { groupName: string })[] };
export type TModulePermissionsCellWrapperProps = { data?: { row: TRowWithEffectiveModulePermissions; column: Column } };
export type TModulePermissionsCellWrapper = React.ComponentType<React.PropsWithChildren & TModulePermissionsCellWrapperProps>;

type TModulePermissionsGridProps = {
    disabled?: boolean;
    modulePermissions: TModulePermissionWithFolder[];
    onModulePermissionChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    searchedString: string;
    gridHeight?: string;
    onLastSeenRowChange?: (rowId: string | null, sort: 'asc' | 'desc') => void;
    lastSeenRow?: TModulePermissionsLastSeenRowCacheValue | null;
    /** Wrapper around every cell component except for the name cell */
    cellWrapper?: TModulePermissionsCellWrapper;
    /** Map of restricted modules with their restrictions. Use only if you need 
     * license restrictions of different user than currently logged in user */
    customUserLicenseRestrictionsObject?: TCustomUserLicenseRestrictionsObject;
};

type TModulePermissionsGridState = {
    columnWidths: TableColumnWidthInfo[];
    sort: Sorting[];
};

const GridRootComponent = GridTable.createRootWithProps({ id: GRID_ROOT_ID });
const TableContainerComponent = GridTable.createTableContainerComponent({ id: GRID_CONTAINER_ID });

export default class ModulePermissionsGrid extends React.Component<TModulePermissionsGridProps, TModulePermissionsGridState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly tableRef: React.RefObject<HTMLDivElement> | null = null;
    private readonly virtualTableRef: React.RefObject<typeof VirtualTable>;
    constructor(props: TModulePermissionsGridProps) {
        super(props);
        this.state = {
            sort: [{ columnName: ModulePermissionNames.Columns.Folder, direction: 'asc' }],
            columnWidths: [],
        };
        this.tableRef = React.createRef<HTMLDivElement>();
        this.virtualTableRef = React.createRef<typeof VirtualTable>();
    }

    componentDidMount() {
        let sort: 'asc' | 'desc' = 'asc';
        if (this.props.lastSeenRow) {
            this.virtualTableRef.current?.scrollToRow(this.props.lastSeenRow.rowId);
            sort = this.props.lastSeenRow.sort;
        }

        this.setState({
            columnWidths: [
                // First column takes up remaining space of table
                { columnName: ModulePermissionNames.Columns.Folder, width: getWidthOfMainColumn(this.tableRef?.current?.clientWidth, 150) },
                { columnName: ModulePermissionNames.Columns.View, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.CanCreate, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.Edit, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.Delete, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.CanExport, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.CanSeeHistory, width: ORB_COL_WIDTH },
                { columnName: ModulePermissionNames.Columns.RowsRestriction, width: 160 },
            ],
            sort: [{ columnName: ModulePermissionNames.Columns.Folder, direction: sort }],
        });
    }

    private readonly getGridRowId = (row: IApiModulePermission) => row.FolderName;

    private readonly onTableTopRowChange = (rowId: TCustomTextNode) => {
        this.props.onLastSeenRowChange && this.props.onLastSeenRowChange(rowId as string, this.state.sort.find((s) => s.columnName === ModulePermissionNames.Columns.Folder)!.direction);
    };

    private readonly ViewTableCell: React.FC<Table.DataCellProps> = (props) => {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { licenseRestrictionsHelper } = React.useContext(ConnectionContext);
        const typedValue = props.value as TCustomTextNode;
        const typedRow = props.row as TModulePermissionWithFolder;
        const { isFolderNameLocked, folderNameLicenseRestriction } = licenseRestrictionsHelper.isFolderNameLocked(typedRow.FolderName as TFolderName);
        const isEditable = !typedRow.IsSystem && !this.props.disabled && !isFolderNameLocked;

        if ([ModulePermissionNames.Columns.View, ModulePermissionNames.Columns.Edit, ModulePermissionNames.Columns.Delete].includes(props.column.name)) {
            return (
                <OrbDropdownEditCell
                    {...props}
                    isEditable={isEditable}
                    onValueChange={this.props.onModulePermissionChange}
                    columnSettings={modulePermissionsDropdownColumnSettings[props.column.name as keyof typeof modulePermissionsDropdownColumnSettings]}
                    optionsSettings={modulePermissionsOptionsSettings}
                    cellWrapper={this.props.cellWrapper}
                />
            );
        } else if ([ModulePermissionNames.Columns.CanCreate, ModulePermissionNames.Columns.CanExport, ModulePermissionNames.Columns.CanSeeHistory].includes(props.column.name)) {
            return (
                <OrbDropdownEditCell
                    {...props}
                    isEditable={isEditable}
                    onValueChange={(rowId: string, column: string, newValue: string | number | boolean | null) => {
                        this.props.onModulePermissionChange && this.props.onModulePermissionChange(rowId, column, newValue !== 'false');
                    }}
                    value={typedValue.toString()}
                    columnSettings={modulePermissionsDropdownColumnSettings[props.column.name as keyof typeof modulePermissionsDropdownColumnSettings]}
                    optionsSettings={modulePermissionsBooleanOptionsSettings}
                    cellWrapper={this.props.cellWrapper}
                />
            );
        } else if ([ModulePermissionNames.Columns.RowsRestriction].includes(props.column.name)) {
            return <RowsRestrictionsEditCell {...props} onValueChange={this.props.onModulePermissionChange} isEditable={isEditable} cellWrapper={this.props.cellWrapper} />;
        }

        return (
            <VirtualTable.Cell className={mergeStyles('align-middle d-flex', !isEditable && 'text-secondary')} {...props} title={typedValue}>
                {typedValue} {isFolderNameLocked && <LicenseRestrictionsLockIcon licenseRestriction={folderNameLicenseRestriction} isSmall={false} className="ml-1 text-dark" />}
            </VirtualTable.Cell>
        );
    };

    private readonly rowComponent: React.ComponentType<Table.DataRowProps> = (props) => {
        return <LicenseRestrictionsLockedGridRow {...props} customUserLicenseRestrictionsObject={this.props.customUserLicenseRestrictionsObject} />;
    };

    render() {
        return (
            <div className="h-100 permissions-table" ref={this.tableRef}>
                <Grid rows={this.props.modulePermissions} columns={gridColumns} rootComponent={GridRootComponent} getRowId={this.getGridRowId}>
                    <SearchState value={this.props.searchedString} />
                    <SortingState
                        sorting={this.state.sort}
                        onSortingChange={(sort) =>
                            this.setState({ sort: sort }, () => {
                                this.props.onLastSeenRowChange && this.props.onLastSeenRowChange(null, sort.find((s) => s.columnName === ModulePermissionNames.Columns.Folder)!.direction);
                            })
                        }
                        columnExtensions={sortingStateColumnExtensions}
                    />
                    <IntegratedFiltering />
                    <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                    <VirtualTable
                        containerComponent={TableContainerComponent}
                        messages={{ noData: this.props.searchedString ? myStrings.noModulePermissionsMatch : myStrings.noModulePermissions }}
                        noDataCellComponent={GridTable.NoDataCell}
                        estimatedRowHeight={MP_GRID_ROW_HEIGHT}
                        height={this.props.gridHeight}
                        cellComponent={this.ViewTableCell}
                        columnExtensions={gridColumnExtensions}
                        ref={this.virtualTableRef}
                        onTopRowChange={this.onTableTopRowChange}
                        rowComponent={this.rowComponent}
                    />
                    <TableColumnResizing columnWidths={this.state.columnWidths} onColumnWidthsChange={(nextColumnWidths) => this.setState({ columnWidths: nextColumnWidths })} />
                    <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                </Grid>
            </div>
        );
    }
}
