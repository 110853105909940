import { useState } from 'react';
import TimePicker from './TimePicker';

type TTimeRangePickerProps = {
    betweenText?: string;
    defaultValue?: string;
    onChange?: (newValue: string | null) => void;
    disabled?: boolean;
};

const splitTimes = (times?: string | null) => {
    if (!times) {
        return [null, null];
    }
    return times.split('-');
};

const TimeRangePicker: React.FC<TTimeRangePickerProps> = ({ betweenText, defaultValue, onChange, disabled }) => {
    const [fromTime, setFromTime] = useState(() => splitTimes(defaultValue)[0]);
    const [toTime, setToTime] = useState(() => splitTimes(defaultValue)[1]);
    const handleToTimeChange = (newValue: string | null) => {
        setToTime(newValue);
        if (newValue && fromTime) {
            onChange && onChange(`${fromTime}-${newValue}`);
        } else if (!newValue && !toTime) {
            onChange && onChange(null);
        }
    };

    const handleFromTimeChange = (newValue: string | null) => {
        setFromTime(newValue);
        if (newValue && toTime) {
            onChange && onChange(`${newValue}-${toTime}`);
        } else if (!newValue && !toTime) {
            onChange && onChange(null);
        }
    };

    return (
        <div className="d-flex align-items-center">
            <TimePicker defaultValue={fromTime} onChange={handleFromTimeChange} disabled={disabled}/>
            <div className="px-2" style={{ fontSize: '0.9rem' }}>
                {betweenText}
            </div>
            <TimePicker defaultValue={toTime} onChange={handleToTimeChange} disabled={disabled}/>
        </div>
    );
};

export default TimeRangePicker;
