import * as React from 'react';
import type { IPackageModel, TPeriodType } from '../../../data/shopping/ISubscriptionsShoppingModel';
import UnitHelper from '../../../helpers/UnitHelper';
import StoreHelper from './StoreHelper';

type UnitPriceSpanProps = {
    formattedPrice: string;
    packageData: IPackageModel;
    showUnit: boolean;
    periodType?: TPeriodType;
};

const UnitPriceSpan: React.FunctionComponent<UnitPriceSpanProps> = (props) => {
    return (
        <>
            {props.formattedPrice}
            {(!!props.periodType) &&
                <>
                    &nbsp;/&nbsp;
                    {StoreHelper.getPeriodStrings(props.periodType).unit}
                </>
            }
            {props.showUnit &&
                <>
                    &nbsp;/&nbsp;
                    {UnitHelper.pickTranslatedUnit(1, props.packageData.Unit)}
                </>
            }
        </>
    );
};

export default UnitPriceSpan;