/**
 * Injects props into provided component.
 * @param Component component to inject props to
 * @param additionalProps props to be injected
 */

const injectProps = <TBaseProps, TAdditionalProps>(Component: React.ComponentType<TBaseProps & TAdditionalProps>, additionalProps: Partial<JSX.IntrinsicAttributes> & TAdditionalProps) => {
    const ComponentWithConnection = (props: TBaseProps) => {
        return <Component {...additionalProps} {...props} />;
    };

    ComponentWithConnection.displayName = `withInjectedProps(${Component.displayName || 'Component'})`;
    return ComponentWithConnection;
};

export default injectProps;
