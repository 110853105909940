import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { TFolderName } from '@eway-crm/connector';
import { useContext } from 'react';
import { Link } from 'react-router-dom';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import RouteConfig from '../../../RouteConfig';
import Strings from '../../../strings';
import type { TCustomUserLicenseRestrictionsObject } from '../../routes/users/EffectivePermissionsWizard';
import LicenseRestrictionsLockedGridRowInner from './LicenseRestrictionsLockedGridRowInner';

type TLicenseRestrictionsLockedGridRowProps = Table.DataRowProps & {
    customUserLicenseRestrictionsObject?: TCustomUserLicenseRestrictionsObject;
};

const LicenseRestrictionsLockedGridRow: React.FC<TLicenseRestrictionsLockedGridRowProps> = ({ customUserLicenseRestrictionsObject, ...props }) => {
    const { licenseRestrictionsHelper } = useContext(ConnectionContext);
    const typedRow = props.row as { FolderName: TFolderName };
    const myStrings = Strings.components.licenseRestrictions;

    const { isFolderNameLocked, folderNameLicenseRestriction } = licenseRestrictionsHelper.isFolderNameLocked(typedRow.FolderName);
    const userLicenseRestriction = customUserLicenseRestrictionsObject && customUserLicenseRestrictionsObject.modules[typedRow.FolderName];

    if (isFolderNameLocked || userLicenseRestriction) {
        const children = props.children as React.ReactNode[];
        const folderNameColumn = children[0]; // Is first in children

        return (
            <tr>
                {folderNameColumn}
                {isFolderNameLocked && <LicenseRestrictionsLockedGridRowInner colSpan={children.length} licenseRestriction={folderNameLicenseRestriction} unlockText={myStrings.unlockThisModule} />}
                {!isFolderNameLocked && userLicenseRestriction && (
                    <td colSpan={children.length}>
                        {Strings.formatString(
                            myStrings.userNotAuthorizedToUseModule,
                            customUserLicenseRestrictionsObject.user.FileAs ?? myStrings.user,
                            (<Link to={`${RouteConfig.users.edit.path}/${customUserLicenseRestrictionsObject.user.ItemGUID}`}>{myStrings.assignLicense}</Link>) as unknown as string,
                            (<Link to={RouteConfig.billing.subscriptions.path}>{myStrings.buyNewOne}</Link>) as unknown as string
                        )}
                    </td>
                )}
            </tr>
        );
    }

    return <VirtualTable.Row {...props} />;
};

export default LicenseRestrictionsLockedGridRow;
