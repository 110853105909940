import * as React from 'react';
import type { IPackageModel } from '../../../data/shopping/ISubscriptionsShoppingModel';
import Strings from '../../../strings';
import HtmlComponent from "../../shared/HtmlComponent";
import { QuestionMarkPopover } from "../../shared/QuestionMarkPopover";

type TPackageQuestionMarkPopoverProps = {
    packageData: IPackageModel;
    currentDbSizeGigabytes: number;
};

const PackageQuestionMarkPopover: React.FC<TPackageQuestionMarkPopoverProps> = ({ packageData, currentDbSizeGigabytes }) => {
    const myStrings = Strings.components.routes.subscriptions;

    if (!packageData.Description)
        return null;

    return (
        <QuestionMarkPopover placement="right" color="black" title={myStrings.packageContains}>
            <div className="pl-3">
                <HtmlComponent htmlString={Strings.pickTranslation(packageData.Description)} />
            </div>
            {!(!packageData.CompareToDbSizeGigabytes) &&
                <div className="font-italic">
                    {Strings.formatString(myStrings.currentDbSizeFormat, Strings.formatNumber(currentDbSizeGigabytes, 0) ?? "")}
                </div>
            }
        </QuestionMarkPopover>
    );
};

export default PackageQuestionMarkPopover;