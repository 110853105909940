import React, { useContext, useState } from 'react';
import ExternalLinks from '../../../data/constants/ExternalLinks';
import administrationSettingsImg from '../../../img/administrationSettingsInv.svg';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import { Settings } from './Settings';
import UserProfileInfo from './UserProfileInfo';
import { AccountManagementCustomerPopover, ExternalLinks as GuiExternalLinks } from '@eway-crm/gui';
import APP_BUILD_MODE from '../../../AppBuildMode';

const SETTINGS_PANEL_KEY = 'settingsPanel';
const USER_PROFILE_INFO_KEY = 'userProfileInfo';

const ACCOUNT_MANAGEMENT_CUSTOMER_POPOVER_POSITIONING = { offset: { mainAxis: 8 } };

const UserProfile: React.FC = () => {
    const { connection, licenseRestrictionsHelper, version, apiData } = useContext(ConnectionContext);
    const [showPanel, setShowPanel] = useState<string | null>(null);

    const { isLegacyAdministrationLinkHidden } = licenseRestrictionsHelper.isLegacyAdministrationLinkHidden();
    const currentOutlookClientVersion = version.outlookClientVersion;

    return (
        <>
            <AccountManagementCustomerPopover
                customerName={apiData.license.CustomerName}
                version={import.meta.env.VITE_VERSION}
                wsUrl={connection.getApiConnection().wsUrl}
                positioning={ACCOUNT_MANAGEMENT_CUSTOMER_POPOVER_POSITIONING}
            >
                <span className="top-navbar__customer-name">
                    {apiData.license.CustomerName}
                </span>
            </AccountManagementCustomerPopover>
            <div className={"top-navbar__divider"} />
            <span className="mx-0 my-auto">
                {!isLegacyAdministrationLinkHidden && (
                    <a href={ExternalLinks.oldAdminApp} className="nav-link m-0 p-0 btn btn-link">
                        <img
                            src={administrationSettingsImg}
                            alt={Strings.components.mainComponent.oldAdmin}
                            title={Strings.components.mainComponent.oldAdmin}
                            style={{ width: '20px', height: '20px', verticalAlign: 'middle', color: 'white' }}
                        />
                    </a>
                )}
            </span>
            <span className="mx-2 my-auto">
                <Settings
                    hidePanel={() => setShowPanel(null)}
                    togglePanel={() => setShowPanel((prevName) => (prevName !== SETTINGS_PANEL_KEY ? SETTINGS_PANEL_KEY : null))}
                    isPanelShown={showPanel === SETTINGS_PANEL_KEY}
                />
            </span>
            <UserProfileInfo
                userItem={connection.state.userItem}
                outlookClientInstallerUrl={!currentOutlookClientVersion ? null : GuiExternalLinks.getOutlookDownloadLink(APP_BUILD_MODE, currentOutlookClientVersion)}
                hidePanel={() => setShowPanel(null)}
                onShowSettings={() => setShowPanel(SETTINGS_PANEL_KEY)}
                togglePanel={() => setShowPanel((prevName) => (prevName !== USER_PROFILE_INFO_KEY ? USER_PROFILE_INFO_KEY : null))}
                isPanelShown={showPanel === USER_PROFILE_INFO_KEY}
            />
        </>
    );
};

export default UserProfile;
