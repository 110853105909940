import * as React from 'react';
import type { ShoppingConnection } from "../../ShoppingConnection";
import type { IOrder, IOrdersHistoryModel } from '../../../data/shopping/IOrdersHistoryModel';
import { css } from './Styles';
import Strings from '../../../strings';
import { PurchaseHistoryGrid } from '../../shared/purchaseHistory/PurchaseHistoryGrid';
import { DateHelper } from '../../../helpers/DateHelper';
import { MoreLessButton } from './MoreLessButton';
import type { TPaymentGateProvider } from '../../../data/shopping/IPaymentMethodsModel';
import ReactHelper from '../../../helpers/ReactHelper';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.home;

type TBillingHistoryProps = {
    shoppingConnection: ShoppingConnection;
    ordersHistoryCallback: (orderHistory: IOrdersHistoryModel) => void;
};

type TBillingHistoryState = {
    isShoppingEnabledLoaded: boolean;
    isShoppingEnabled: boolean;
    data: IOrder[] | null;
    dataSample: IOrder[] | null;
    paymentGateProvider: TPaymentGateProvider | null;
    showMore: boolean;
};

export class BillingHistory extends React.Component<TBillingHistoryProps, TBillingHistoryState> {

    constructor(props: TBillingHistoryProps) {
        super(props);
        this.state = {
            isShoppingEnabledLoaded: false,
            isShoppingEnabled: false,
            data: null,
            dataSample: null,
            paymentGateProvider: null,
            showMore: false
        };
    }

    componentDidMount() {
        this.props.shoppingConnection.isShoppingEnabled(
            () => this.setState({ isShoppingEnabledLoaded: true, isShoppingEnabled: true }, () => { this.load().catch((err) => console.error('Unable to load billing history data.', err)); }),
            () => this.setState({ isShoppingEnabledLoaded: true, isShoppingEnabled: false })
        );
    }

    private readonly load = async () => {
        const ordersHistory = await this.props.shoppingConnection.loadOrdersHistory(false);
        const data = (ordersHistory.Orders || []).sort((a, b) => -1 * DateHelper.compareUniversals(a.OrderDateString, b.OrderDateString));
        await ReactHelper.setState(
            this,
            {
                data: data,
                dataSample: data.slice(0, 5),
                paymentGateProvider: ordersHistory.PaymentGateProvider
            }
        );
        this.props.ordersHistoryCallback(ordersHistory);
    };

    private readonly reload = async () => {
        await ReactHelper.setState(this, { data: null, dataSample: null, paymentGateProvider: null });
        await this.load();
    };

    render() {
        if (this.state.isShoppingEnabledLoaded && !this.state.isShoppingEnabled) {
            return (
                <div className={css.spinnerContainer + ' text-muted my-2'}>
                    {myStrings.noData}
                </div>
            );
        }

        if (!this.state.isShoppingEnabledLoaded || !this.state.data || !this.state.dataSample || !this.state.paymentGateProvider) {
            return <div className={css.spinnerContainer}><Spinner variant={SpinnerVariant.ease} backgroundOpacity={0}/></div>;
        }

        const shownData = this.state.showMore ? this.state.data : this.state.dataSample;
        return (
            <div>
                <div className={(this.state.showMore ? css.gridContainerExpanded : css.gridContainerCollapsed)} style={(this.state.showMore ? { height: (this.state.data.length * 57 + 76) + 'px' } : undefined)}>
                    <PurchaseHistoryGrid
                        shoppingConnection={this.props.shoppingConnection}
                        variant="card"
                        data={shownData}
                        paymentGateProvider={this.state.paymentGateProvider}
                        onReload={this.reload}
                    />
                </div>
                {(this.state.data.length > 5) &&
                    <div className={css.moreContainer}>
                        {(this.state.showMore) ?
                            <MoreLessButton variant="less" onClick={() => this.setState({ showMore: false })} />
                            :
                            <MoreLessButton variant="more" onClick={() => this.setState({ showMore: true })} />
                        }
                    </div>
                }
            </div>
        );
    }
}