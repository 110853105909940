import * as React from 'react';
import { Form, Button } from 'react-bootstrap';
import { SearchState, IntegratedFiltering } from '@devexpress/dx-react-grid';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableHeaderRow, VirtualTable, TableColumnResizing } from '@devexpress/dx-react-grid-bootstrap4';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import type AssociativeArray from '../../../data/AssociativeArray';
import type { IApiUser } from '@eway-crm/connector';
import WizardModal from '../../WizardModal';
import Strings from '../../../strings';
import GridTable from '../../GridTable';
import StringHelper from '../../../helpers/StringHelper';
import { UserBadgeFlags } from '../../shared/users/UserBadgeFlags';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import type { IApiLocalizedLicenseBundle } from '@eway-crm/connector';
import { DelayedRender } from '@fluentui/react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { LicensePicker } from './LicensePicker';
import ReactHelper from '../../../helpers/ReactHelper';
import { SpinnerVariant } from '@eway-crm/gui';

type TLicenseMatrixWizardProps = {
    users: IApiUser[];
    availableLicenses: IApiLocalizedLicenseBundle[];
    onDismiss: () => void;
    onDone: () => void;
};

type TLicenseMatrixWizardState = {
    availableLicenses: IApiLocalizedLicenseBundle[];
    data: (IApiUser & { isUserDataChanged?: boolean })[];
    spentLicenses: AssociativeArray<number>;
    usersSearchedString: string;
    isSavingInProgress: boolean;
    anyChangeMade: boolean;
};

const myStrings = Strings.components.routes.users;
const usersGridColumns = [
    { name: 'FileAs', title: myStrings.fullName },
    { name: 'Username', title: myStrings.username },
    { name: 'IsActive', title: Strings.active },
    { name: 'Server_LicensingBundlesList', title: myStrings.ewayLicense }
];
const defaultColumnWidths = [
    { columnName: 'FileAs', width: 300 },
    { columnName: 'Username', width: 300 },
    { columnName: 'IsActive', width: 80 },
    { columnName: 'Server_LicensingBundlesList', width: 300 },
];
const getRowId = (row: IApiUser) => row.ItemGUID;

export class LicenseMatrixWizard extends React.Component<TLicenseMatrixWizardProps, TLicenseMatrixWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TLicenseMatrixWizardProps) {
        super(props);

        this.state = {
            data: props.users.sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs)),
            spentLicenses: LicenseMatrixWizard.getSpentCounts(props.users),
            usersSearchedString: '',
            isSavingInProgress: false,
            anyChangeMade: false,
            availableLicenses: props.availableLicenses,
        };
    }

    private static getSpentCounts(users: IApiUser[]): AssociativeArray<number> {
        const countsObj: AssociativeArray<number> = {};
        users.forEach((user) => {
            if (!user.IsActive) {
                return;
            }
            if (!user.Server_LicensingBundlesList || user.Server_LicensingBundlesList.length === 0) {
                return;
            }

            user.Server_LicensingBundlesList.forEach((lic) => {
                const code = lic.Code;
                if (typeof countsObj[code] === "undefined") {
                    countsObj[code] = 1;
                } else {
                    countsObj[code] = countsObj[code] + 1;
                }
            });
        });
        return countsObj;
    }

    private readonly getSpentLicensesCount = (code: string): number => {
        let count = 0;
        if (typeof this.state.spentLicenses[code] !== "undefined") {
            count = this.state.spentLicenses[code];
        }
        return count;
    };

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly setUserState = (itemGuid: string, state: Partial<IApiUser>) => {
        const index = this.state.data.findIndex((user) => user.ItemGUID === itemGuid);
        if (index === -1) {
            console.error('Unable find user with guid ' + itemGuid);
            return;
        }

        if (state.IsActive === false) {
            // Inactive users cannot be API users
            state.IsApiUser = false;
        }

        const orig = this.state.data[index];
        const result = {
            ...orig,
            ...state,
            isUserDataChanged: true
        };

        const newData = [...this.state.data];
        newData[index] = result;

        this.setState({
            data: newData,
            spentLicenses: LicenseMatrixWizard.getSpentCounts(newData),
            anyChangeMade: true
        });
    };

    private readonly submit = async () => {
        let exceededLicenses = false;
        this.props.availableLicenses.forEach((license) => {
            let count = 0;
            if (typeof this.state.spentLicenses[license.Code] !== 'undefined') {
                count = this.state.spentLicenses[license.Code];
            }
            if (count > license.Quantity) {
                exceededLicenses = true;
            }
        });
        if (exceededLicenses) return;

        const usersToSave = this.state.data.filter((d) => d.isUserDataChanged);
        if (usersToSave.length !== 0) {
            await ReactHelper.setState(this, { isSavingInProgress: true });

            const saveUsersTransmitObjects = usersToSave.map((user) => ({
                ItemGUID: user.ItemGUID,
                IsActive: user.IsActive,
                Server_LicensingBundlesList: user.Server_LicensingBundlesList?.map((bundle) => ({ Code: bundle.Code })) ?? null,
                IsApiUser: user.IsActive ? user.IsApiUser : false,
            }));
            await this.context.connection.askApi('SaveUsers', { transmitObjects: saveUsersTransmitObjects });
        }

        this.props.onDone();
    };

    private readonly CellComponent: React.ComponentType<Table.DataCellProps> = ({ column, ...restProps }) => {
        const typedRow = restProps.row as IApiUser;
        let inner = null;
        let className;
        if (column.name === 'IsActive') {
            const value = restProps.value as boolean;
            inner = (
                <Form.Check checked={value} onChange={(e: IFormCheckChangeEvent) => this.setUserState(typedRow.ItemGUID, { IsActive: e.target.checked })} />
            );
        } else if (column.name === 'Server_LicensingBundlesList') {
            const value = restProps.value as IApiLocalizedLicenseBundle[] | null;
            inner = (
                <LicensePicker
                    assignedLicenses={value ?? []}
                    availableLicenses={this.state.availableLicenses}
                    isApiUser={typedRow.IsApiUser}
                    onLicenseChange={({ assignedLicenses, availableLicenses, isApiUser }) => {
                        this.setState({ availableLicenses }, () => this.setUserState(typedRow.ItemGUID, { IsApiUser: isApiUser, Server_LicensingBundlesList: assignedLicenses }));
                    }}
                    disabled={!typedRow.IsActive}
                />
            );
            className = 'py-0 align-middle';
        } else if (column.name === 'Username') {
            if (!this.context.connection || !this.context.connection.state.session?.userGuid) {
                throw new Error("Unable to render UserBadgeFlags. The connection reference is missing or the user is not logged in.");
            }
            const value = restProps.value as string;
            inner = (
                <>
                    {value}
                    <UserBadgeFlags item={typedRow} currentUserGuid={this.context.connection.state.session.userGuid} />
                </>
            );
        } else {
            const value = restProps.value as React.ReactChild;
            inner = <>{value}</>;
        }
        return (
            <VirtualTable.Cell
                column={column}
                className={className}
                {...restProps}
            >
                {inner}
            </VirtualTable.Cell>
        );
    };

    render() {
        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        }

        let exceededLicenses = false;
        const licensesCounts = (
            <div className="list-group-horizontal">
                {this.props.availableLicenses.map((license, i) => {
                    const spentCount = this.getSpentLicensesCount(license.Code);
                    const remainingCount = license.Quantity - spentCount;
                    let className = 'list-group-item d-inline-block';
                    if (spentCount > license.Quantity) {
                        exceededLicenses = true;
                        className += ' list-group-item-danger';
                    }
                    return <div className={className} style={{ margin: "1px", borderWidth: "1px" }} key={'spent-lic-' + i}>{license.Name}:&nbsp;{remainingCount}/{license.Quantity} </div>;
                })}
            </div>
        );

        const noUsersString = this.state.usersSearchedString ? myStrings.noUsersMatch : myStrings.noUsers;
        return (
            <WizardModal size="xl" show={true} onHide={this.dismiss} hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)} showCancelButtonInFooter={true}>
                <WizardModal.Body>
                    <div className="container-fluid d-flex flex-column m-0 p-0 min-h-100">
                        <div className="row mb-3">
                            <div className="col">
                                <h2>{myStrings.reassignLicenses}</h2>
                            </div>
                            <div className="col-auto">
                                <Form.Control type="text" placeholder={Strings.search} value={this.state.usersSearchedString} onChange={(e) => this.setState({ usersSearchedString: e.target.value })} />
                            </div>
                        </div>
                        <div className="row mb-4">
                            <div className="col">{licensesCounts}</div>
                        </div>
                        <div className="row flex-fill d-flex justify-content-start">
                            <div className="col">
                                <DelayedRender>
                                    <Grid
                                        key="license-matrix-grid"
                                        rows={this.state.data}
                                        columns={usersGridColumns}
                                        rootComponent={GridTable.RootNoHover}
                                        getRowId={getRowId}
                                    >
                                        <SearchState
                                            key="license-matrix-grid-searchstate"
                                            value={this.state.usersSearchedString}
                                            onValueChange={(v) => this.setState({ usersSearchedString: v })}
                                        />
                                        <IntegratedFiltering />
                                        <VirtualTable
                                            containerComponent={GridTable.TableContainerComponent}
                                            cellComponent={this.CellComponent}
                                            messages={{ noData: noUsersString }}
                                            noDataCellComponent={GridTable.NoDataCell}
                                        />
                                        <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
                                        <TableHeaderRow />
                                    </Grid>
                                </DelayedRender>
                            </div>
                        </div>
                    </div>
                </WizardModal.Body>
                <WizardModal.Footer>
                    <Button variant="primary" onClick={() => void this.submit()} disabled={exceededLicenses}>{Strings.ok}</Button>
                </WizardModal.Footer>
            </WizardModal>
        );
    }
}