import * as React from 'react';
import StoreHelper from '../../StoreHelper';
import StoreV2Context from '../StoreV2Context';

const usePackage = (artno: string) => {
    const { storeData, packagesEditingStates, setPackageEditingState, isPackageInCart } = React.useContext(StoreV2Context);

    const packageData = React.useMemo(() => StoreHelper.getPackageByArtno(storeData, artno), [storeData, artno]);

    const packageEditingState = React.useMemo(() => {
        const ps = packagesEditingStates.find((p) => p.artno === artno);
        if (ps)
            return ps.state;

        return {
            currentEditQuantity: null
        };
    }, [artno, packagesEditingStates]);

    const steps = React.useMemo(() => {
        if (!packageData?.AmountRules)
            return null;

        if (packageData.AmountRules.Steps)
            return packageData.AmountRules.Steps;

        if (packageData.AmountRules.ConditionalRules) {
            const model = packageData.AmountRules.ConditionalRules.find((cm) => !!cm.Steps && StoreHelper.isConditionMet(cm.Condition, isPackageInCart));
            if (model)
                return model.Steps!;

            // There are steps but none of them matches.
            return [];
        }

        return null;
    }, [packageData, isPackageInCart]);

    const { isDisabled } = React.useMemo(() => {
        // If there are steps but none is available, the package is disabled.
        if (steps && steps.length === 0)
            return { isDisabled: true, enablingArtnos: null };

        // Just for typescript
        if (!packageData)
            return { isDisabled: false, enablingArtnos: null };

        return StoreHelper.isPackageDisabled(packageData, isPackageInCart);
    }, [steps, packageData, isPackageInCart]);

    // Reset status of disabled package.
    React.useEffect(() => {
        if (!isDisabled || !packageEditingState.currentEditQuantity)
            return;

        setPackageEditingState(artno, { currentEditQuantity: 0 });
    }, [artno, isDisabled, packageEditingState, setPackageEditingState]);

    return { packageData, packageEditingState, isDisabled, steps };
};

export default usePackage;