import React, { createRef } from 'react';
import type { match as IRouterMatch, RouteComponentProps } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import RouteConfig from '../../../RouteConfig';
import { SecondaryPanel } from '../../layout/SecondaryPanel';
import type { IApiDataResponse, IApiEnumType, IApiSaveResponse, IApiWorkflowModel, TFolderName, IApiSetFieldValueAction } from '@eway-crm/connector';
import { EnumTypeEditMode } from '@eway-crm/connector';
import { DateHelper } from '@eway-crm/gui';
import FolderSimpleWorkflowEditor from './FolderSimpleWorkflowEditor';
import { validate as uuidValidate } from 'uuid';
import WorkflowMenu, { WorkflowMenuItemAction } from './WorkflowMenu';
import FolderNames from '../../../data/constants/FolderNames';
import DeleteWorkflowWizard from './DeleteWorkflowWizard';
import ReactHelper from '../../../helpers/ReactHelper';
import memoizeOne from 'memoize-one';
import { RemoteItemStore } from '../../../RemoteItemStore';
import type { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { TEnumValue } from '../../shared/dropDowns/EnumValuesEditGrid';
import { v1 as createUuid } from 'uuid';
import WorkflowMenuPanelHead from './WorkflowMenuPanelHead';
import EditWorkflowNameWizard from './EditWorkflowNamesWizard';
import SimpleOrAdvancedEditorPicker from './SimpleOrAdvancedEditorPicker';
import Strings from '../../../strings';
import DuplicateWorkflowWizard from './DuplicateWorkflowWizard';
import type { TAutosuggestRefHandle } from '../../shared/Autosuggest/Autosuggest';
import AdvancedWorkflowEditor from './AdvancedWorkflowEditor';
import LoadingFolderContainer from '../../layout/LoadingFolderContainer';
import WorkflowUnsavedChangesDialog from './WorkflowUnsavedChangesDialog';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { LocalizedStringsMethods } from 'react-localization';
import workflowStageAttributesMap from './workflowStageAttributes';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import WorkflowLicenseRestrictionsErrors from './WorkflowLicenseRestrictionsErrors';
import { LicenseRestrictionsModalVariant } from '@eway-crm/gui';
import { SpinnerVariant } from '@eway-crm/gui';
import { DelayedRender } from '@fluentui/react';
import RouteLeavingGuardV5 from '../../shared/RouteLeavingGuardV5';

export type TEnumValueWithWfModel = TEnumValue & {
    wfModel?: IApiWorkflowModel | null;
};

export type TStageAttribute = {
    name: string;
    getTranslation: (strings: Omit<typeof Strings, keyof LocalizedStringsMethods>) => string;
    category?: string;
    value?: string;
    getSetFieldValueActions?: () => IApiSetFieldValueAction[];
};

type TWorkflowModelContainerProps = Pick<RouteComponentProps, 'history' | 'location'> & {
    selectedFolderName: TFolderName;
    wfModels: IApiWorkflowModel[];
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumn: (language: string) => Promise<void>;
    updateWfModels: (cb?: (wfModels: IApiWorkflowModel[]) => Promise<void>) => Promise<void>;
    independentWorkflowEnumTypeGuids: { [folderName: string]: string };
};

type TWorkflowModelContainerState = {
    enumType: IApiEnumType | null;
    itemTypes: TEnumValueWithWfModel[] | null;
    createWorkflowGuid: string | null;
    isCreatingWorkflowModel: boolean;
    editNameItemTypeGuid: string | null;
    deleteItemTypeGuid: string | null;
    duplicateItemTypeGuid: string | null;
    newWfMenuString: string;
    stageAttributes: TStageAttribute[] | null;
    anyChangeMade: boolean;
    unsavedChangesDialogContinueAction: { action: WorkflowMenuItemAction; id: string } | null;
    isLocalSavingInProgress: boolean;
};

const myStrings = Strings.components.routes.workflow;

export default class WorkflowModelContainer extends React.PureComponent<TWorkflowModelContainerProps, TWorkflowModelContainerState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly menuTableRef;
    private readonly folderSimpleWorkflowRef: React.RefObject<FolderSimpleWorkflowEditor>;
    private readonly newWorkflowInputRef: React.RefObject<TAutosuggestRefHandle>;

    constructor(props: TWorkflowModelContainerProps) {
        super(props);
        this.menuTableRef = createRef<typeof VirtualTable>();
        this.folderSimpleWorkflowRef = React.createRef();
        this.newWorkflowInputRef = React.createRef();

        this.state = {
            enumType: null,
            itemTypes: null,
            createWorkflowGuid: null,
            isCreatingWorkflowModel: false,
            editNameItemTypeGuid: null,
            duplicateItemTypeGuid: null,
            deleteItemTypeGuid: null,
            newWfMenuString: '',
            stageAttributes: null,
            anyChangeMade: false,
            unsavedChangesDialogContinueAction: null,
            isLocalSavingInProgress: false,
        };
    }

    private readonly updateEmptyStageAttributesGS = async (gsItemsToSave: { name: string; value: string }[]) => {
        const { askApi } = this.context.connection;
        await askApi('SaveGlobalSettings', {
            transmitObjects: gsItemsToSave.map((item) => ({
                Name: item.name,
                Value: item.value,
            })),
        });
    };

    private readonly onUpdateStageAttributes = async (gsName: string, value: string) => {
        await this.updateEmptyStageAttributesGS([{ name: gsName, value }]);
        const stageAttributes = await this.askForFolderStageAttributes();
        this.setState({ stageAttributes });
    };

    private readonly askForFolderStageAttributes = async () => {
        const remoteItemStore = new RemoteItemStore(this.context.connection);

        const folderStageAttributes = workflowStageAttributesMap[this.props.selectedFolderName];
        if (folderStageAttributes) {
            const stageAttributesRes = await remoteItemStore.askForGlobalSettings(folderStageAttributes.map((attr) => ({ name: attr.name, defaultValue: null })));

            const stageAttributes: TStageAttribute[] = folderStageAttributes.map((attr, idx) => {
                const value = stageAttributesRes[idx];
                return { value, ...attr };
            }) as TStageAttribute[];

            return stageAttributes;
        }

        return null;
    };

    private readonly reload = async () => {
        const { askApi } = this.context.connection;
        await ReactHelper.setState(this, {
            enumType: null,
            itemTypes: null,
            duplicateItemTypeGuid: null,
            createWorkflowGuid: null,
            deleteItemTypeGuid: null,
            editNameItemTypeGuid: null,
            anyChangeMade: false
        });

        const enumTypeName = FolderNames.getEnumTypeName(this.props.selectedFolderName);
        if (enumTypeName) {
            const enumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('SearchEnumTypes', { transmitObject: { EnumName: enumTypeName, includeEnumValuesAllActionEvents: true } });
            const enumType = enumTypeRes.Data[0];
            const enumValuesWithDeleteStatus = await new RemoteItemStore(this.context.connection).askForEnumValuesDeleteStatus(enumType.EnumValuesInEnumType);

            const getWfModel = (evItemGuid: string) => {
                if (this.props.independentWorkflowEnumTypeGuids[this.props.selectedFolderName]) {
                    return this.props.wfModels.find((wfm) => wfm.EnumTypeGuid === this.props.independentWorkflowEnumTypeGuids[this.props.selectedFolderName]);
                }

                return this.props.wfModels.find((wfm) => wfm.ParentEn === evItemGuid);
            };

            const itemTypes: TEnumValueWithWfModel[] =
                enumValuesWithDeleteStatus?.map((ev) => ({
                    ...ev,
                    wfModel: getWfModel(ev.ItemGUID),
                })) ?? [];

            const stageAttributes = await this.askForFolderStageAttributes();
            await this.context.reloadCustomizationStats();

            this.setState({ enumType, itemTypes, stageAttributes });
        }
    };

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const shouldFocusNewWorkflowInput = urlParams.get(RouteConfig.customizations.workflow.newParamName) === 'true';

        if (shouldFocusNewWorkflowInput && this.newWorkflowInputRef.current) {
            this.newWorkflowInputRef.current.focus();
        }

        this.reload()
            .catch((err) => console.error('Unable to reload wf model container.', err));
    }

    private readonly dismissAction = () => {
        this.setState({ editNameItemTypeGuid: null, deleteItemTypeGuid: null, duplicateItemTypeGuid: null });
    };

    private readonly onAnyChangeMadeChange = (anyChangeMade: boolean) => {
        this.setState({ anyChangeMade });
    };

    private readonly onCreateNewItemType = async (newItemTypeName: string) => {
        const { isLimitReached, licenseRestriction } = this.context.licenseRestrictionsHelper.isItemTypesCountExceeded(this.props.selectedFolderName);
        if (isLimitReached) {
            this.context.showLicenseRestrictionModal(licenseRestriction, { variant: LicenseRestrictionsModalVariant.workflow });
            return;
        }

        const newGuid = createUuid();

        let highestRank = 0;
        if (this.state.itemTypes && this.state.itemTypes.length > 0) {
            highestRank = this.state.itemTypes?.reduce((acc, currentVal) => (acc > currentVal.Rank ? acc : currentVal.Rank), 0);
        }

        await ReactHelper.setState(this, { createWorkflowGuid: newGuid });

        const transmitEnumValueObject: TEnumValueWithWfModel = {
            FileAs: newItemTypeName,
            Cs: newItemTypeName,
            De: newItemTypeName,
            En: newItemTypeName,
            EnumType: this.state.enumType!.ItemGUID,
            EnumTypeName: newItemTypeName,
            IncludeInLastActivityCalculation: false,
            IsDefault: false,
            IsSystem: false,
            IsVisible: true,
            CanBeDeleted: true,
            ItemGUID: newGuid,
            ItemVersion: 1,
            No: newItemTypeName,
            Rank: highestRank + 1,
            Ru: newItemTypeName,
            Sk: newItemTypeName,
            AllActionEvents: null,
            Server_ItemCreated: null,
            Server_ItemChanged: null,
            CreatedByGUID: this.context.connection.state.session!.userGuid,
            ModifiedByGUID: this.context.connection.state.session!.userGuid,
            OwnerGUID: this.context.connection.state.session!.userGuid,
            ItemChanged: DateHelper.toRfc3339String(new Date()),
            ItemCreated: DateHelper.toRfc3339String(new Date()),
            IsPrivate: false
        };

        await this.context.connection.askApi<IApiSaveResponse>('SaveEnumValue', { transmitObject: transmitEnumValueObject });

        await ReactHelper.setState(
            this,
            (prevState) => ({
                itemTypes: [...(prevState.itemTypes ?? []), transmitEnumValueObject],
                newWfMenuString: '',
                createWorkflowGuid: null,
            })
        );

        this.menuTableRef.current?.scrollToRow(newGuid);
        this.props.history.push(RouteConfig.customizations.workflow.getPathWithFolderAndGuid(this.props.selectedFolderName, newGuid));
        await this.updateItemTypes(this.props.wfModels);
    };

    private readonly updateItemTypes = async (wfModels: IApiWorkflowModel[]) => {
        const { askApi } = this.context.connection;
        const enumTypeName = FolderNames.getEnumTypeName(this.props.selectedFolderName);
        if (enumTypeName) {
            const enumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('SearchEnumTypes', { transmitObject: { EnumName: enumTypeName, includeEnumValuesAllActionEvents: true } });
            const enumType = enumTypeRes.Data[0];

            const enumValuesWithDeleteStatus = await new RemoteItemStore(this.context.connection).askForEnumValuesDeleteStatus(enumType.EnumValuesInEnumType);
            const itemTypes: TEnumValueWithWfModel[] =
                enumValuesWithDeleteStatus?.map((ev) => ({
                    ...ev,
                    wfModel: this.getItemTypeWfModel(wfModels, ev.ItemGUID, this.props.selectedFolderName)
                })) ?? [];

            const stageAttributes = await this.askForFolderStageAttributes();

            this.setState({
                enumType,
                itemTypes,
                stageAttributes,
                isCreatingWorkflowModel: false,
                createWorkflowGuid: null,
                deleteItemTypeGuid: null,
                duplicateItemTypeGuid: null,
                editNameItemTypeGuid: null,
            });
        }
    };

    private readonly createSimpleWorkflow = async () => {
        const currentItemGuid = this.getCurrentlySelectedItemTypeGuid();

        await ReactHelper.setState(this, { isCreatingWorkflowModel: true });
        await this.context.connection.askApi<IApiSaveResponse>('SaveWorkflowModel', {
            transmitObject: {
                ParentEn: currentItemGuid,
                IsVisible: true,
                IsUsingFlows: false,
            },
        });

        void this.props.updateWfModels(this.updateItemTypes);
    };

    private readonly isNewValueValid = () => {
        if (!this.state.itemTypes) {
            return false;
        }

        return EnumValuesValidator.getAreRowsAndColumnsValid([
            ...this.state.itemTypes,
            {
                ItemGUID: 'new',
                FileAs: this.state.newWfMenuString,
                En: this.state.newWfMenuString,
                Cs: this.state.newWfMenuString,
                De: this.state.newWfMenuString,
                Ru: this.state.newWfMenuString,
                No: this.state.newWfMenuString,
                Sk: this.state.newWfMenuString,
            },
        ]);
    };

    private readonly toggleHide = async (itemGuid: string) => {
        const { askApi } = this.context.connection;
        const enumValue = this.state.itemTypes?.find((it) => it.ItemGUID === itemGuid);

        if (enumValue) {
            const { isLimitReached: isItemTypesLimitReached, licenseRestriction: itemTypesLicenseRestriction } = this.context.licenseRestrictionsHelper.isItemTypesCountExceeded(this.props.selectedFolderName);
            const { isLimitReached: isBasicWorkflowLimitReached, licenseRestriction: basicWorkflowLicenseRestriction } = this.context.licenseRestrictionsHelper.isBasicWorkflowCountExceeded(this.props.selectedFolderName);
            const { isLimitReached: isAdvancedWorkflowLimitReached, licenseRestriction: advancedWorkflowLicenseRestriction } = this.context.licenseRestrictionsHelper.isAdvancedWorkflowCountExceeded(this.props.selectedFolderName);
            if (!enumValue.IsVisible) {
                if (!enumValue.IsSystem && isItemTypesLimitReached) {
                    this.context.showLicenseRestrictionModal(itemTypesLicenseRestriction, { variant: LicenseRestrictionsModalVariant.workflow });
                    return;
                } else if (isBasicWorkflowLimitReached && enumValue.wfModel && !enumValue.wfModel.IsUsingFlows && !enumValue.wfModel.IsSystem) {
                    this.context.showLicenseRestrictionModal(basicWorkflowLicenseRestriction, { variant: LicenseRestrictionsModalVariant.workflow });
                    return;
                } else if (isAdvancedWorkflowLimitReached && enumValue.wfModel && enumValue.wfModel.IsUsingFlows && !enumValue.wfModel.IsSystem) {
                    this.context.showLicenseRestrictionModal(advancedWorkflowLicenseRestriction, { variant: LicenseRestrictionsModalVariant.workflow });
                    return;
                }
            }

            await ReactHelper.setState(this, { isLocalSavingInProgress: true });
            await askApi<IApiSaveResponse>('SaveEnumValue', {
                transmitObject: {
                    ItemGUID: enumValue.ItemGUID,
                    IsVisible: !enumValue.IsVisible,
                },
            });
            await ReactHelper.setState(this, { isLocalSavingInProgress: false });
            this.reload()
                .catch((err) => console.error('Unable to reload wf model container.', err));
        }
    };

    private readonly onMenuAction = (action: WorkflowMenuItemAction, id: string) => {
        if (this.state.anyChangeMade) {
            this.setState({ unsavedChangesDialogContinueAction: { action, id } });
            return;
        }

        if (action === WorkflowMenuItemAction.Edit) {
            this.setState({ editNameItemTypeGuid: id });
        } else if (action === WorkflowMenuItemAction.ToggleHide) {
            this.toggleHide(id)
                .catch((err) => console.error('Unable to toggle hide inside wf model container.', err));
        } else if (action === WorkflowMenuItemAction.Duplicate) {
            this.setState({ duplicateItemTypeGuid: id });
        } else if (action === WorkflowMenuItemAction.Delete) {
            this.setState({ deleteItemTypeGuid: id });
        }
    };

    private readonly menuPanelHead = () => {
        const isNewValueValid = this.isNewValueValid();
        return (
            <div className="w-100">
                <WorkflowLicenseRestrictionsErrors folderName={this.props.selectedFolderName} />
                <WorkflowMenuPanelHead
                    value={this.state.newWfMenuString}
                    onValueChange={(newVal) => this.setState({ newWfMenuString: newVal })}
                    onValueSelection={(newVal) => {
                        isNewValueValid && void this.onCreateNewItemType(newVal);
                    }}
                    isCreatingNew={!!this.state.createWorkflowGuid}
                    invalid={!isNewValueValid}
                    ref={this.newWorkflowInputRef}
                    disabled={this.state.enumType?.EditMode !== EnumTypeEditMode.Editable}
                />
            </div>
        );
    };

    private readonly onMenuItemClick = (newItemId: string) => {
        this.props.history.push(RouteConfig.customizations.workflow.getPathWithFolderAndGuid(this.props.selectedFolderName, newItemId));
    };


    // After deleting Model and ItemType
    private readonly onDeleteDone = () => {
        this.props.history.push(RouteConfig.customizations.workflow.getPathWithFolder(this.props.selectedFolderName));
        void this.props.updateWfModels(this.reload);
    };

    // After deleting Model only
    private readonly onDeleteWfModelDone = () => {
        void this.props.updateWfModels(this.reload);
    };

    private readonly onDuplicateDone = () => {
        void this.props.updateWfModels(this.updateItemTypes);
    };

    private readonly menuPanelBody = () => {
        const itemTypeGuid = this.getCurrentlySelectedItemTypeGuid();
        return (
            <WorkflowMenu
                wfModels={this.props.wfModels}
                enumType={this.state.enumType!}
                items={this.state.itemTypes}
                onMenuItemClick={this.onMenuItemClick}
                onMenuAction={this.onMenuAction}
                selectedItemId={itemTypeGuid}
                tableRef={this.menuTableRef}
            />
        );
    };

    private readonly getCurrentlySelectedItemTypeGuid = () => {
        const itemTypeGuidMatch: IRouterMatch<{ itemTypeGuid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.customizations.workflow.pathWithItemTypeGuid });
        const itemTypeGuid = itemTypeGuidMatch?.params?.itemTypeGuid;
        return itemTypeGuid && uuidValidate(itemTypeGuid) ? itemTypeGuid : null;
    };

    private readonly getItemTypeWfModel = memoizeOne((wfModels: IApiWorkflowModel[] | null, itemTypeGuid: string | null, folderName: string) => {
        if (!wfModels || !itemTypeGuid) {
            return null;
        }

        if (this.props.independentWorkflowEnumTypeGuids[folderName]) {
            return wfModels.find((wfm) => wfm.EnumTypeGuid === this.props.independentWorkflowEnumTypeGuids[folderName]);
        }

        return wfModels.find((wfm) => wfm.ParentEn === itemTypeGuid);
    });

    private readonly handleRouteLeavingGuardSave = async (successCb: () => void) => {
        await this.folderSimpleWorkflowRef.current?.saveChanges();
        successCb();
    };

    private readonly handleUnsavedChangesDialogContinue = async () => {
        await this.folderSimpleWorkflowRef.current?.saveChanges();
        const { action, id } = this.state.unsavedChangesDialogContinueAction!;
        this.setState({ unsavedChangesDialogContinueAction: null }, () => {
            this.onMenuAction(action, id);
        });
    };

    render() {
        const itemTypeGuid = this.getCurrentlySelectedItemTypeGuid();
        const selectedWfModel = this.getItemTypeWfModel(this.props.wfModels, itemTypeGuid, this.props.selectedFolderName);
        const itemTypeTranslatedName = (Strings.pickTranslation(this.state.itemTypes?.find((ev) => ev.ItemGUID === itemTypeGuid)) as string | undefined) ?? null;
        const showAdvancedWfEditor = itemTypeGuid && selectedWfModel && selectedWfModel.IsUsingFlows && !this.state.isCreatingWorkflowModel;
        const showWfEditorPicker = itemTypeGuid && !selectedWfModel && this.state.itemTypes && !this.state.isCreatingWorkflowModel;

        return (
            <>
                <RouteLeavingGuardV5
                    key={itemTypeGuid}
                    onConfirmNavigation={(pathName) => {
                        this.setState({ anyChangeMade: false }, () => {
                            this.props.history.push(pathName);
                        });
                    }}
                    onSave={(cb) => {
                        void this.handleRouteLeavingGuardSave(cb);
                    }}
                    isActive={this.state.anyChangeMade}
                    modalTitle={Strings.confirmRouteChangeTitle}
                    modalMessage={Strings.confirmRouteChange}
                    saveButtonText={Strings.saveChangesContinue}
                    discardButtonText={Strings.discardChanges}
                />
                {this.state.isLocalSavingInProgress && (
                    <DelayedRender delay={250}>
                        <SpinnerModal variant={SpinnerVariant.linear} />
                    </DelayedRender>
                )}
                {this.state.unsavedChangesDialogContinueAction && (
                    <WorkflowUnsavedChangesDialog
                        onCancel={() => {
                            this.setState({ unsavedChangesDialogContinueAction: null });
                        }}
                        onContinue={() => void this.handleUnsavedChangesDialogContinue()}
                    />
                )}
                {this.state.deleteItemTypeGuid && this.state.itemTypes && (
                    <DeleteWorkflowWizard
                        onDismiss={this.dismissAction}
                        selectedItemType={this.state.itemTypes.find((ev) => ev.ItemGUID === this.state.deleteItemTypeGuid)!}
                        onDone={this.onDeleteDone}
                    />
                )}
                {this.state.editNameItemTypeGuid && this.state.enumType && this.state.itemTypes && (
                    <EditWorkflowNameWizard
                        onDismiss={this.dismissAction}
                        onDone={this.reload}
                        enumType={this.state.enumType}
                        enumValue={this.state.itemTypes.find((ev) => ev.ItemGUID === this.state.editNameItemTypeGuid)!}
                        displayLanguageColumns={this.props.displayLanguageColumns}
                        toggleDisplayLanguageColumn={this.props.toggleDisplayLanguageColumn}
                    />
                )}
                {this.state.duplicateItemTypeGuid && this.state.itemTypes && (
                    <DuplicateWorkflowWizard
                        itemTypes={this.state.itemTypes}
                        folderName={this.props.selectedFolderName}
                        enumType={this.state.enumType!}
                        sourceEnumValue={this.state.itemTypes.find((ev) => ev.ItemGUID === this.state.duplicateItemTypeGuid)!}
                        onDismiss={this.dismissAction}
                        onDone={this.onDuplicateDone}
                    />
                )}
                <SecondaryPanel variant="small" panelHead={this.menuPanelHead} panelBody={this.menuPanelBody}>
                    {this.state.isCreatingWorkflowModel && (
                        <LoadingFolderContainer
                            title={`${myStrings.title} | ${itemTypeTranslatedName} | ${FolderNames.getPluralName(this.props.selectedFolderName)}`}
                            subtitle={myStrings.subtitleSimpleEditor}
                        />
                    )}
                    {!!selectedWfModel && !selectedWfModel.IsUsingFlows && !!this.state.enumType && !this.state.isCreatingWorkflowModel && (
                        <FolderSimpleWorkflowEditor
                            ref={this.folderSimpleWorkflowRef}
                            key={selectedWfModel.EnumTypeGuid + this.state.enumType.ItemGUID}
                            folderName={this.props.selectedFolderName}
                            selectedWfModel={selectedWfModel}
                            onDeleteWfModelDone={this.onDeleteWfModelDone}
                            stageAttributes={this.state.stageAttributes}
                            onUpdateStageAttributes={this.onUpdateStageAttributes}
                            disabled={selectedWfModel.IsSystem && !selectedWfModel.AllowEditItems}
                            displayLanguageColumns={this.props.displayLanguageColumns}
                            toggleDisplayLanguageColumn={this.props.toggleDisplayLanguageColumn}
                            anyChangeMade={this.state.anyChangeMade}
                            onAnyChangeMadeChange={this.onAnyChangeMadeChange}
                            workflowName={itemTypeTranslatedName}
                        />
                    )}
                    {showAdvancedWfEditor && <AdvancedWorkflowEditor />}
                    {showWfEditorPicker && <SimpleOrAdvancedEditorPicker onSimpleClick={this.createSimpleWorkflow} folderName={this.props.selectedFolderName} />}
                </SecondaryPanel>
            </>
        );
    }
}
