import type { VirtualTable } from "@devexpress/dx-react-grid-bootstrap4";
import type EnumValuesValidator from "../../shared/dropDowns/EnumValuesValidator";

export default class FieldWizardHelper {
    static scrollToInvalidEnumValueRow = (
        invalidRowsAndCols: ReturnType<typeof EnumValuesValidator["getInvalidRowsAndColumns"]>,
        displayLanguageColumns: string[],
        toggleDisplayLanguageColumn: (lang: string, doNotSaveToUserSettings?: boolean) => Promise<void>,
        enumValueTableRef: React.RefObject<typeof VirtualTable>
    ) => {
        const invalidEntry = invalidRowsAndCols.entries().next().value as [string, string[]];
        const firstInvalidLangCol = invalidEntry[1][0];
        if (!displayLanguageColumns.includes(firstInvalidLangCol)) {
            void toggleDisplayLanguageColumn(firstInvalidLangCol, true);
        }

        enumValueTableRef.current?.scrollToRow(invalidEntry[0]);
    };
}