import React from 'react';

type TFolderSplashProps = {
    img: string;
    message: string;
};

const FolderSplash: React.FC<TFolderSplashProps> = ({ img, message }) => {
    return (
        <div className="h-100 position-relative">
            <div className="position-absolute" style={{ top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                <img className="d-inline-block" src={img} style={{ height: '160px', width: '100%' }} alt="" />
                <div className="text-black-50 text-center pt-4" style={{fontSize: '1.5rem'}}>{message}</div>
            </div>
        </div>
    );
};

export default FolderSplash;
