import React, { useState, useRef, useEffect, useContext } from 'react';
import type { ITextField} from '@fluentui/react';
import { TextField, PrimaryButton, mergeStyles } from '@fluentui/react';
import Strings from '../../../../strings';
import SearchResults from './SearchResults';
import SearchModal from './SearchModal';
import useGetSearchResults from './useGetSearchResults';
import type { TPage } from './SearchResultsObtainer';
import { RemoteItemStore } from '../../../../RemoteItemStore';
import StringHelper from '../../../../helpers/StringHelper';
import { useDetectDismiss } from '@eway-crm/gui';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';

const myStrings = Strings.components;

const SearchBar: React.FC = () => {
    const [value, setValue] = useState('');
    const [lastSearchedValue, setLastSearchedValue] = useState('');
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { ref: searchRef, isOpen: isResultOpen, setIsOpen: setIsResultOpen } = useDetectDismiss(false);
    const { isLoading, jsSearch } = useGetSearchResults(isResultOpen);
    const inputRef = useRef<ITextField>(null);
    const { connection } = useContext(ConnectionContext);

    const handleModalToggle = () => {
        setIsModalOpen((prevState) => !prevState);
        setIsResultOpen(false);
    };

    const handleModalHide = (query: string) => {
        setValue(query);
        setLastSearchedValue(query);
        handleModalToggle();
    };

    useEffect(() => {
        const handleClick = (e: MouseEvent) => {
            // Check if user clicked on side menu
            if (e.target && (e.target as Element).closest('.menu-item') && lastSearchedValue.length > 0) {
                new RemoteItemStore(connection).saveSearchFeedback(null, lastSearchedValue);
            }
        };
        document.addEventListener('click', handleClick, true);
        return () => document.removeEventListener('click', handleClick, true);
    }, [lastSearchedValue, connection]);

    const handleNewSearchValue = (newValue: string) => {
        if (newValue?.length < value.length) {
            // Check if user deleted at least 3 characters
            if (lastSearchedValue.length !== 0 && newValue.length <= lastSearchedValue.length - 3) {
                new RemoteItemStore(connection).saveSearchFeedback(null, lastSearchedValue);
                setLastSearchedValue('');
            }
        } else {
            setLastSearchedValue(newValue);
        }
    };

    const handleChange = (newValue = '') => {
        handleNewSearchValue(newValue);
        setValue(newValue);
        setIsResultOpen(true);
    };

    const handleResultClick = () => {
        setValue('');
        setIsResultOpen(false);
    };

    return (
        <>
            <div className="searchbar__container" ref={searchRef}>
                <div className={mergeStyles('searchbar', isResultOpen && 'result-open')}>
                    <TextField
                        placeholder={myStrings.search.searchPlaceholder}
                        value={value}
                        onChange={(e, newValue) => {
                            handleChange(newValue);
                        }}
                        onFocus={() => setIsResultOpen(true)}
                        autoComplete="off"
                        onRenderPrefix={() => (
                            <PrimaryButton
                                tabIndex={-1}
                                iconProps={{ iconName: 'Search' }}
                                className="searchbar__searchBtn"
                                onClick={() => {
                                    inputRef.current?.focus();
                                }}
                            />
                        )}
                        onRenderSuffix={() =>
                            value.length > 0 ? (
                                <PrimaryButton
                                    iconProps={{ iconName: 'Cancel' }}
                                    className="searchbar__clearBtn"
                                    onClick={() => {
                                        handleChange('');
                                        inputRef.current?.focus();
                                    }}
                                />
                            ) : null
                        }
                        componentRef={inputRef}
                        onKeyDown={(e) => e.key === 'Enter' && handleModalToggle()}
                        spellCheck="false"
                    />
                </div>
                {isResultOpen && isLoading && (
                    <div className="searchbar__results">
                        <div key="no-match" className="searchbar__results__no-match">
                            {`${myStrings.search.loading}...`}
                        </div>
                    </div>
                )}
                {isResultOpen && !isLoading && jsSearch.current && value && (
                    <div className="searchbar__results">
                        <SearchResults
                            search={jsSearch.current.search(StringHelper.removeAccents(value) || '') as TPage[]}
                            query={value}
                            maxResultsPerGroup={4}
                            focusInput={() => setTimeout(() => inputRef.current?.focus(), 10)}
                            onResultClick={handleResultClick}
                        />
                    </div>
                )}
            </div>
            {isModalOpen && <SearchModal jsSearch={jsSearch} query={value} onHide={handleModalHide} newValueCb={(newValue) => handleNewSearchValue(newValue)} />}
        </>
    );
};

export default SearchBar;
