import React from 'react';
import { Button } from 'react-bootstrap';
import ExternalLinks from '../../../data/constants/ExternalLinks';
import Strings from '../../../strings';

const myStrings = Strings.components.routes.workflow;

const AdvancedWorkflowEditor: React.FC = () => {
    return (
        <div className="h-100 position-relative flex-fill d-flex flex-column align-items-center justify-content-center">
            <h2 className="text-secondary text-center pt-2 mb-2">{myStrings.advancedEditorTitle}</h2>
            <p className="pb-4 text-center">{myStrings.advancedEditorSubtitle}</p>
            <div>
                <Button variant="secondary" href={ExternalLinks.oldAdminAppWorkflow}>
                    {myStrings.advancedEditorButton}
                </Button>
            </div>
        </div>
    );
};

export default AdvancedWorkflowEditor;
