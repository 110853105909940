import { mergeStyles } from '@fluentui/react';
import React from 'react';
import SaveButtonsToolbar from '../shared/SaveButtonsToolbar';

type TFolderContainerProps = React.PropsWithChildren & {
    title: string;
    subtitle: string;
    onSave?: () => Promise<void>;
    onDiscard?: () => void;
    anyChangeMade?: boolean;
    isSaveButtonDisabled?: boolean;
    hideSaveButtonsToolbar?: boolean;
    onRenderSaveButtonsToolbar?: () => React.ReactNode;
    className?: string;
};

const FolderContainer: React.FC<TFolderContainerProps> = ({
    title,
    subtitle,
    anyChangeMade,
    children,
    hideSaveButtonsToolbar,
    isSaveButtonDisabled,
    className,
    onDiscard,
    onSave,
    onRenderSaveButtonsToolbar,
}) => {
    return (
        <div className={mergeStyles("container-fluid d-flex h-100 px-3 py-3 px-lg-4 py-lg-4 px-xl-5 py-xl-4 flex-column", className)}>
            <div className="row mx-0">
                <div className="col p-0">
                    <div className="d-flex flex-column">
                        <h1 className="text-truncate mb-0">{title}</h1>
                        <div className="d-flex align-items-end justify-content-between">
                            <div className="text-medium mt-3 text-break">{subtitle}</div>
                            <div className="d-flex justify-content-end flex-column">
                                {onRenderSaveButtonsToolbar && onRenderSaveButtonsToolbar()}
                                {!hideSaveButtonsToolbar && !onRenderSaveButtonsToolbar && (
                                    <SaveButtonsToolbar disabled={isSaveButtonDisabled} anyChangeMade={!!anyChangeMade} onSave={onSave} onDiscard={onDiscard} />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {children}
        </div>
    );
};

export default FolderContainer;
