import { Dialog, DialogFooter } from '@fluentui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import StringHelper from '../../../helpers/StringHelper';
import Strings from '../../../strings';
import EnumValuesColumns from '../../shared/dropDowns/EnumValuesColumns';
import type { TEnumValue } from '../../shared/dropDowns/EnumValuesEditGrid';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';

const myStrings = Strings.components.routes.fields;

type TInvalidEnumValuesDialogProps = {
    onDismiss: () => void;
    enumValues: Partial<TEnumValue>[];
    customMessages?: {
        getErrorDuplicateText?: (langColumnTranslation: string, colValueInRow: string) => string;
    };
    hasWorkflowColumnHeaders?: boolean;
};

const InvalidEnumValuesDialog: React.FC<TInvalidEnumValuesDialogProps> = ({ onDismiss, enumValues, hasWorkflowColumnHeaders, customMessages }) => {
    const getColumnTranslation = (errorLangColumn: string) => {
        const stageName = StringHelper.capitalize(Strings.getLanguage())!;

        switch (errorLangColumn) {
            case stageName: {
                return hasWorkflowColumnHeaders ? myStrings.dropDowns.stageName : myStrings.dropDowns.name;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.en: {
                return myStrings.dropDowns.en;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.cs: {
                return myStrings.dropDowns.cs;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.de: {
                return myStrings.dropDowns.de;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.ru: {
                return myStrings.dropDowns.ru;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.sk: {
                return myStrings.dropDowns.sk;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.no: {
                return myStrings.dropDowns.no;
            }
            case EnumValuesColumns.EDIT_GRID_COLS.technicalName: {
                return myStrings.dropDowns.techName;
            }
            default: {
                console.warn(`Invalid column ${errorLangColumn}`);
                return '';
            }
        }
    };

    const getColumnToShowInError = (errorLangColumns: string[]) => {
        const stageName = StringHelper.capitalize(Strings.getLanguage())!;

        if (errorLangColumns.includes(stageName)) {
            return stageName;
        } else {
            return errorLangColumns[0];
        }
    };

    const getEnumValuesErrorMessage = () => {
        const invalidRowsAndColumns = EnumValuesValidator.getInvalidRowsAndColumns(enumValues);
        if (invalidRowsAndColumns.size !== 0) {
            const [errorRowGuid, errorLangColumns] = invalidRowsAndColumns.entries().next().value as [string, string[]];
            const errorRow = enumValues.find((ev) => ev.ItemGUID === errorRowGuid)!;

            const langColumn = getColumnToShowInError(errorLangColumns);
            const langColumnTranslation = getColumnTranslation(langColumn);
            const colValueInRow = errorRow[langColumn as keyof Partial<TEnumValue>] as string;

            if (!colValueInRow) {
                return Strings.formatString(myStrings.enumValuesErrorEmptyText, langColumnTranslation) as string;
            }

            return customMessages?.getErrorDuplicateText
            ? customMessages?.getErrorDuplicateText(langColumnTranslation, colValueInRow)
            : (Strings.formatString(myStrings.enumValuesErrorDuplicateText, langColumnTranslation, colValueInRow) as string);
        }
        return '';
    };

    return (
        <Dialog
            modalProps={{ isBlocking: true, styles: { scrollableContent: { whiteSpace: 'pre-wrap' } } }}
            onDismiss={onDismiss}
            dialogContentProps={{ title: myStrings.enumValuesErrorTitle, subText: getEnumValuesErrorMessage() }}
            hidden={false}
        >
            <DialogFooter>
                <Button variant="primary" onClick={onDismiss}>
                    {Strings.ok}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default InvalidEnumValuesDialog;
