export default class FieldPermissionNames {
    static readonly colName = 'colName';
    static readonly PermissionRule = 'PermissionRule';
    static readonly MandatoryRule = 'MandatoryRule';
    static readonly IsMandatory = 'IsMandatory';
    static readonly IsImportant = 'IsImportant';
    static readonly IsUnique = 'IsUnique';
    static readonly IsReadonly = 'IsReadonly';
    static readonly PermissionRuleKeys = {
        All: 'All',
        Own: 'Own',
        Readonly: 'Readonly',
        Invisible: 'Invisible',
        None: 'None',
    } as const;
    static readonly MandatoryRuleKeys = {
        Mandatory: 'Mandatory',
        Optional: 'Optional',
        Unique: 'Unique',
        None: 'None',
    } as const;
}
