import * as React from 'react';
import { FontIcon, Modal, mergeStyleSets, mergeStyles } from '@fluentui/react';
import type { IFeaturedVideo } from '../../../data/commonDataApi/IAdminFeaturedVideosResponse';
import type { Settings, CustomArrowProps } from "react-slick";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import VideoCarouselItem from './VideoCarouselItem';

const css = mergeStyleSets({
    modalContent: {
        backgroundColor: 'transparent',
        borderWidth: '0 !important',
        boxShadow: 'none'
    },
    arrow: {
        fontSize: '2em !important',
        cursor: 'pointer',
        color: "black !important",
        height: "auto !important",
        '&::before': {
            display: "none" // Hide default arrows
        },
        '&.slick-disabled': {
            display: "none !important"
        }
    },
    arrowPrev: {
        left: "-34px !important"
    },
    arrowNext: {
        right: "-32px !important"
    }
});

const SLIDER_RESPONSIVE_CONFIG: Settings['responsive'] = [
    {
        breakpoint: 1400,
        settings: {
            slidesToShow: 2.85
        }
    },
    {
        breakpoint: 1300,
        settings: {
            slidesToShow: 2.57
        }
    },
    {
        breakpoint: 1200,
        settings: {
            slidesToShow: 2.30
        }
    },
    {
        breakpoint: 1100,
        settings: {
            slidesToShow: 2.03
        }
    },
    {
        breakpoint: 1000,
        settings: {
            slidesToShow: 1.76
        }
    },
    {
        breakpoint: 900,
        settings: {
            slidesToShow: 1.50
        }
    },
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 1.23,
        }
    }
];

const NextArrow: React.FC<CustomArrowProps> = ({ className, onClick, style }) => {
    return (
        <FontIcon iconName="ChevronRight" style={style} className={mergeStyles(css.arrow, css.arrowNext, className)} onClick={onClick} />
    );
};

const PrevArrow: React.FC<CustomArrowProps> = ({ className, onClick, style }) => {
    return (
        <FontIcon iconName="ChevronLeft" style={style} className={mergeStyles(css.arrow, css.arrowPrev, className)} onClick={onClick} />
    );

};

type TVideoCarouselProps = {
    videos: IFeaturedVideo[];
};

type TVideoCarouselState = {
    openedVideoIndex: number | null;
    isSwiping: boolean;
};

export class VideoCarousel extends React.Component<TVideoCarouselProps, TVideoCarouselState> {

    constructor(props: TVideoCarouselProps) {
        super(props);
        this.state = {
            openedVideoIndex: null,
            isSwiping: false,
        };
    }

    private readonly openVideo = (index: number) => {
        if (this.state.isSwiping) {
            // Do not open the video if user is swiping
            // https://github.com/akiran/react-slick/issues/1952#issuecomment-763187798
            return;
        }
        this.setState({ openedVideoIndex: index });
    };

    private closeVideo = () => {
        this.setState({ openedVideoIndex: null });
    };

    render() {
        return (
            <>
                <div>
                    <Slider
                        slidesToShow={3.25}
                        arrows={true}
                        prevArrow={<PrevArrow />}
                        nextArrow={<NextArrow />}
                        responsive={SLIDER_RESPONSIVE_CONFIG}
                        swipeToSlide
                        onSwipe={() => this.setState({ isSwiping: true })}
                        afterChange={() => this.setState({ isSwiping: false })}
                        infinite={false}
                    >
                        {this.props.videos.map((d, i) =>
                            <VideoCarouselItem key={'yt-carusel-img-' + i} onOpen={() => this.openVideo(i)} video={d} />
                        )}
                    </Slider>
                </div>
                {(!!this.state.openedVideoIndex || this.state.openedVideoIndex === 0) &&
                    <Modal isOpen={true} onDismiss={this.closeVideo} containerClassName={css.modalContent}>
                        <iframe
                            id="ytplayer"
                            width="640"
                            height="360"
                            allowFullScreen
                            title={this.props.videos[this.state.openedVideoIndex].Title}
                            src={this.props.videos[this.state.openedVideoIndex].IframeUrl}
                            style={{ borderWidth: '0' }}
                        />
                    </Modal>
                }
            </>
        );
    }
}