import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { UserWizardBase } from './UserWizardBase';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiLocalizedLicenseBundle } from '@eway-crm/connector';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { LicensePicker } from './LicensePicker';
import { SpinnerVariant } from '@eway-crm/gui';

type TActivateWizardProps = {
    onDismiss: () => void;
    selectedUsers: IApiUser[];
    availableLicenses: IApiLocalizedLicenseBundle[];
    onDone: () => void;
};

type TActivateWizardState = {
    isSavingInProgress: boolean;
    availableLicenses: IApiLocalizedLicenseBundle[];
    assignedLicenses: IApiLocalizedLicenseBundle[];
    isApiUser: boolean;
};

export class ActivateWizard extends React.Component<TActivateWizardProps, TActivateWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TActivateWizardProps) {
        super(props);
        this.state = {
            isSavingInProgress: false,
            availableLicenses: props.availableLicenses,
            assignedLicenses: [],
            isApiUser: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                this.context.connection.callApi(
                    'SaveUsers',
                    {
                        transmitObjects: this.props.selectedUsers.map((u) => ({
                            ItemGUID: u.ItemGUID,
                            IsActive: true,
                            IsApiUser: this.state.isApiUser,
                            Server_LicensingBundlesList: UserWizardBase.prepareAssignedLicensesForSubmit(this.state.assignedLicenses)
                        }))
                    },
                    (_) => {
                        this.props.onDone();
                    }
                );
            }
        );
    };

    render() {
        const myStrings = Strings.components.routes.users;

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.activate}</h1>
                        <div className="mb-4">
                            {this.props.selectedUsers.map((user, i) =>
                                <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                    {user.FileAs}
                                </span>
                            )}
                        </div>
                        <div>
                            <Form className="form-application">
                                <Form.Row>
                                    <Form.Group as={Col} controlId="newUserLicense">
                                        <Form.Label>{myStrings.ewayLicense}</Form.Label>
                                        <LicensePicker 
                                            assignedLicenses={this.state.assignedLicenses}
                                            availableLicenses={this.state.availableLicenses}
                                            isApiUser={this.state.isApiUser}
                                            onLicenseChange={(newState) => { this.setState(newState);}}
                                            useComboBoxAsMenuWidth
                                        />
                                    </Form.Group>
                                </Form.Row>
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={this.submit}>{Strings.ok}</Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}