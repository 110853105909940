import React from 'react';
import { Form } from 'react-bootstrap';
import Strings from '../../strings';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { IApiFeature } from '@eway-crm/connector';
import FolderFeatures from './features/FolderFeatures';
import { SecondaryPanel } from '../layout/SecondaryPanel';
import Cookies from '../../Cookies';
import FeaturesMenu from './features/FeaturesMenu';
import RouteConfig from '../../RouteConfig';
import type { RouteComponentProps, match as IRouterMatch } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import type { TFeatureType} from '../../data/constants/FeatureTypes';
import FeatureTypes, { featureTypes } from '../../data/constants/FeatureTypes';
import type { IApiGlobalSetting } from '@eway-crm/connector';
import { RemoteItemStore } from '../../RemoteItemStore';
import { EnumTypes } from '../../data/constants/EnumTypes';
import type { IApiEnumValue } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import GlobalSettingTypes from '../../data/constants/GlobalSettingTypes';
import FolderNames from '../../data/constants/FolderNames';
import splashImg from '../../img/splash/features-splash.svg';
import memoizeOne from 'memoize-one';
import FolderSplash from '../layout/FolderSplash';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

export type TFeature = {
    ItemGuid: string | null;
    type: TFeatureType;
    name: string;
    customTitle?: string;
    description: string;
    icon: string;
    showPermissions: boolean;
    associatedFolderNames: string[];
    canBeToggled?: boolean;
    isLocked: boolean;
};

export type TEnumTypeEnumValues = { [key: string]: IApiEnumValue[] };

type TFeaturesProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TFeaturesState = {
    features: TFeature[] | null;
    globalSettings: IApiGlobalSetting[] | null;
    globalSettingCategoryNames: IApiEnumValue[] | null;
    allGroups: IApiGroup[] | null;
    enumTypeEnumValues: TEnumTypeEnumValues | null;
    menuSearchedString: string;
};

const myStrings = Strings.components.routes.features;

export default class Features extends React.Component<TFeaturesProps, TFeaturesState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TFeaturesProps) {
        super(props);
        this.state = {
            features: null,
            globalSettings: null,
            globalSettingCategoryNames: null,
            allGroups: null,
            enumTypeEnumValues: null,
            menuSearchedString: '',
        };
    }

    componentDidMount() {
        this.loadFeatures()
            .catch((err) => console.error('Unable to load features.', err));
    }

    private readonly loadEnumTypeValues = async (globalSettings: IApiGlobalSetting[]) => {
        const enumTypeGlobalSettings = globalSettings.filter((gs) => gs.Type === GlobalSettingTypes.nextStepMultiChoiceComboBox && !!gs.AvailableValues);
        const availableValues = enumTypeGlobalSettings.flatMap((gs) => gs.AvailableValues);

        const enumTypeEnumValues: TEnumTypeEnumValues = {};
        const remoteItemStore = new RemoteItemStore(this.context.connection);
        await Promise.all(
            availableValues.map(async (av) => {
                const enumTypeName = FolderNames.getEnumTypeName(av?.Value);
                let result = null;
                if (av && enumTypeName) {
                    result = await remoteItemStore.askForEnumValues(enumTypeName);
                    enumTypeEnumValues[av.Value] = result.Data;
                }
                return result;
            })
        );

        return enumTypeEnumValues;
    };

    private readonly loadFeatures = async (successCb?: () => void) => {
        const { askApi } = this.context.connection;
        const remoteItemStore = new RemoteItemStore(this.context.connection);

        const featuresResult = await askApi<IApiDataResponse<IApiFeature>>('GetFeatures', {});
        const globalSettingsResult = await askApi<IApiDataResponse<IApiGlobalSetting>>('GetGlobalSettings', {});
        const groupsResult = await askApi<IApiDataResponse<IApiGroup>>('GetGroups', {});
        const allGroups = RemoteItemStore.getDisplayableGroups(groupsResult.Data);
        const enumTypeEnumValues = await this.loadEnumTypeValues(globalSettingsResult.Data);
        const enumValuesResult = await remoteItemStore.askForEnumValues(EnumTypes.globalSettingsCategory);

        const localizedData = featureTypes.map((f) => {
            const item = featuresResult.Data.find((item) => item.FileAs === f.type);
            const { isFeatureLocked, dontShowToggle } = this.context.licenseRestrictionsHelper.isFeatureLocked(f.type);
            return {
                ...f,
                ItemGuid: item?.ItemGUID,
                associatedFolderNames: item?.AssociatedFolderNames ?? [],
                isLocked: f.canBeToggled && (!dontShowToggle && isFeatureLocked),
            } as TFeature;
        });

        this.setState({
            globalSettings: globalSettingsResult.Data,
            globalSettingCategoryNames: enumValuesResult.Data,
            features: localizedData,
            allGroups,
            enumTypeEnumValues,
        }, () => {
            successCb && successCb();
        });
    };

    private readonly onFeatureChange = (newFeature: string) => {
        this.props.history.push(`${RouteConfig.customizations.features.path}/${newFeature.toLowerCase()}`);
    };

    private readonly onFeatureToggle = (featureType: TFeatureType) => {
        const { isFeatureLocked, dontShowToggle, featureLockedLicenseRestriction } = this.context.licenseRestrictionsHelper.isFeatureLocked(featureType);
        if (!dontShowToggle && isFeatureLocked) {
            this.context.showLicenseRestrictionModal(featureLockedLicenseRestriction);
        }
    };

    private readonly menuPanelHead: React.FC = () => (
        <Form.Control
            type="text"
            placeholder={Strings.search}
            value={this.state.menuSearchedString}
            onChange={(e) => this.setState({ menuSearchedString: e.target.value })}
        />
    );

    private readonly menuPanelBody: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
        const featureName = this.getCurrentFeatureName();
        return (
            <FeaturesMenu
                items={this.state.features!}
                selectedItemId={featureName}
                searchedString={this.state.menuSearchedString}
                isCollapsed={isCollapsed}
                onMenuItemClick={this.onFeatureChange}
                onFeatureToggle={this.onFeatureToggle}
            />
        );
    };

    private readonly getCurrentFeatureName = () => {
        const match: IRouterMatch<{ feature: TFeatureType }> | null = matchPath(this.props.location.pathname, { path: RouteConfig.customizations.features.pathWithFeature });
        const featureName = match?.params?.feature;
        if (featureName) {
            return featureName;
        } else return null;
    };

    private readonly getSelectedFeatureGlobalSettings = memoizeOne(
        (selectedFeature: TFeature | null, globalSettings: IApiGlobalSetting[] | null, globalSettingCategoryNames: IApiEnumValue[] | null) => {
            if (!selectedFeature || !globalSettings || !globalSettingCategoryNames) {
                return [];
            }

            if (FeatureTypes.categoryInSeparateFeatureMap[selectedFeature.type as keyof typeof FeatureTypes.categoryInSeparateFeatureMap]) {
                const categories = FeatureTypes.categoryInSeparateFeatureMap[selectedFeature.type];
                const categoryItemGuids = globalSettingCategoryNames.filter((gsc) => categories.includes(gsc.En ?? '')).map((gsc) => gsc.ItemGUID);
                return globalSettings.filter((gs) => categoryItemGuids.includes(gs.Category));
            }

            // Some categories have separate Feature section
            const separateCategoriesGuids = Object.values(FeatureTypes.categoryInSeparateFeatureMap)
                .flatMap((categoryName) => categoryName)
                .map((categoryName) => globalSettingCategoryNames?.find((gsc) => gsc.En === categoryName)?.ItemGUID);

            // Display all unassigned settings in General
            if (selectedFeature.type === FeatureTypes.General) {
                return globalSettings.filter((gs) => gs.FeatureGuid === null && !separateCategoriesGuids.includes(gs.Category));
            }
            return globalSettings.filter((gs) => gs.FeatureGuid === selectedFeature.ItemGuid && !separateCategoriesGuids.includes(gs.Category));
        }
    );

    render() {
        const featureName = this.getCurrentFeatureName();
        const selectedFeature = this.state.features && featureName && this.state.features.find((f) => f.type.toLowerCase() === featureName.toLowerCase());

        if (!this.state.features || !this.state.globalSettings) {
            return <Spinner variant={SpinnerVariant.ease} />;
        }

        return (
            <SecondaryPanel
                collapseConfig={{ cookieName: Cookies.names.features }}
                onMenuCollapsedChange={(isCollapsed) => {
                    if (isCollapsed) {
                        this.setState({ menuSearchedString: '' });
                    }
                }}
                panelHead={this.menuPanelHead}
                panelBody={this.menuPanelBody}
            >
                {selectedFeature && this.state.globalSettings ? (
                    <FolderFeatures
                        key={featureName}
                        selectedFeature={selectedFeature}
                        globalSettings={this.getSelectedFeatureGlobalSettings(selectedFeature, this.state.globalSettings, this.state.globalSettingCategoryNames)}
                        globalSettingCategoryNames={this.state.globalSettingCategoryNames!}
                        onFeatureToggle={this.onFeatureToggle}
                        allGroups={this.state.allGroups!}
                        enumTypeEnumValues={this.state.enumTypeEnumValues!}
                        history={this.props.history}
                        location={this.props.location}
                        onReload={this.loadFeatures}
                    />
                ) : (
                    <FolderSplash img={splashImg} message={myStrings.splashMessage} />
                )}
            </SecondaryPanel>
        );
    }
}
