import Strings from './strings';
import type { IApiGlobalSetting, TFolderName } from '@eway-crm/connector';
import type { IApiEnumValue } from '@eway-crm/connector';
import type { IApiItemUsageStatus } from '@eway-crm/connector';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { Connection } from './components/layout/connection/Connection';
import type { IApiEnumType } from '@eway-crm/connector';
import { EnumTypes } from './data/constants/EnumTypes';
import type { IApiObjectType } from '@eway-crm/connector';
import type { IApiSaveResponse } from '@eway-crm/connector';
import { UserSettingsKeys } from './data/constants/UserSettingsKeys';
import type { IApiUserSetting } from '@eway-crm/connector';
import StringHelper from './helpers/StringHelper';
import type { IApiDatumResponse } from '@eway-crm/connector';
import type { IApiLicense } from '@eway-crm/connector';
import type { TApiItemWithReleations } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import FolderNames from './data/constants/FolderNames';
import RelationTypes from './data/constants/RelationTypes';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiAvailableVersionResponse } from '@eway-crm/connector';
import LocalStorageHelper from './helpers/LocalStorageHelper';
import type { IApiModulePermission } from '@eway-crm/connector';
import { GroupNames } from './data/constants/GroupNames';
import EnumValuesColumns from './components/shared/dropDowns/EnumValuesColumns';
import type LicenseRestrictionsHelper from './helpers/LicenseRestrictionsHelper';

type TGsValue = string | number | null;

export class RemoteItemStore {
    private connection: Connection;

    constructor(connectionReference: Connection) {
        this.connection = connectionReference;
    }

    readonly loadGlobalSetting = (gsName: string, defaultValue: TGsValue, valueCallback: (gs: TGsValue) => void) => {
        this.askForGlobalSetting(gsName, defaultValue)
            .then(valueCallback)
            .catch((err) => console.error('Unable to load global setting.', err));
    };

    readonly askForGlobalSetting = async (gsName: string, defaultValue: TGsValue) => {
        const globalSettingsRes = await this.connection.askApi<IApiDataResponse<IApiGlobalSetting>>('SearchGlobalSettings', {
            transmitObject: {
                Name: gsName,
            },
        });
        if (globalSettingsRes.Data.length === 0) {
            return defaultValue;
        } else {
            return RemoteItemStore.getGsValue(globalSettingsRes.Data[0], defaultValue);
        }
    };

    readonly loadGlobalSettings = (namesAndDefaultValues: { name: string; defaultValue: TGsValue }[], valuesCallback: (values: TGsValue[]) => void) => {
        this.askForGlobalSettings(namesAndDefaultValues)
            .then(valuesCallback)
            .catch((err) => console.error('Unable to load global settings.', err));
    };

    readonly askForGlobalSettings = async (namesAndDefaultValues: { name: string; defaultValue: TGsValue }[]) => {
        const globalSettingsRes = await this.connection.askApi<IApiDataResponse<IApiGlobalSetting>>('GetGlobalSettings', {});
        const values = namesAndDefaultValues.map(({ defaultValue }) => defaultValue);
        for (let i = 0; i < namesAndDefaultValues.length; i++) {
            const index = globalSettingsRes.Data.findIndex((item) => item.Name === namesAndDefaultValues[i].name);
            if (index > -1) {
                values[i] = RemoteItemStore.getGsValue(globalSettingsRes.Data[index], namesAndDefaultValues[i].defaultValue);
            }
        }
        return values;
    };

    static getGsValue(item: IApiGlobalSetting, defaultValue: TGsValue) {
        if (!item.Value && item.Value !== 0) {
            return defaultValue;
        } else {
            return item.Value;
        }
    }

    readonly loadEnumValues = (enumTypeName: string, dataCallback: (values: IApiEnumValue[]) => void) => {
        this.askForEnumValues(enumTypeName)
            .then((res) => dataCallback(res.Data))
            .catch((err) => console.error('Unable to load enum values.', err));
    };

    readonly askForEnumValues = async (enumTypeName: string) => {
        return await this.connection.askApi<IApiDataResponse<IApiEnumValue>>('SearchEnumValues', {
            transmitObject: {
                EnumTypeName: enumTypeName,
            },
        });
    };

    readonly askForEnumTypeByGuid = async (enumTypeGuid: string) => {
        return await this.connection.askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypesByItemGuids', { itemGuids: [enumTypeGuid] });
    };

    /**
     * Asyncly adds to every enum value record information whether it can be safely deleted.
     * @param enumValues Enum values.
     * @param dataCallback Result callback.
     */
    readonly loadEnumValuesDeleteStatus = (enumValues: IApiEnumValue[] | null, dataCallback: (values: ({ CanBeDeleted: boolean } & IApiEnumValue)[]) => void) => {
        this.askForEnumValuesDeleteStatus(enumValues)
            .then((res) => dataCallback(res))
            .catch((err) => console.error('Unable to load enum values delete statuses.', err));
    };

    readonly askForEnumValuesDeleteStatus = async (enumValues: IApiEnumValue[] | null) => {
        if (!enumValues) {
            return [];
        }

        const evuResult = await this.connection.askApi<IApiDataResponse<IApiItemUsageStatus>>('GetEnumValuesUsageStatus', {
            itemGuids: enumValues.filter((ev) => !ev.IsSystem).map((ev) => ev.ItemGUID),
        });
        const result = enumValues.map((ev) => {
            let canBeDeleted = !ev.IsSystem;
            if (canBeDeleted) {
                const index = evuResult.Data.findIndex((us) => us.ItemGuid === ev.ItemGUID);
                if (index !== -1 && evuResult.Data[index].IsUsed) {
                    canBeDeleted = false;
                }
            }

            return {
                CanBeDeleted: canBeDeleted,
                ...ev,
            };
        });

        return result;
    };

    readonly loadAffectedUsersData = (groupGuid: string, successCb: (affectedUserItems: IApiUser[]) => void) => {
        this.connection.callApi(
            'GetGroupsByItemGuids',
            {
                itemGuids: [groupGuid],
                includeForeignKeys: true,
                includeRelations: true,
                relationsFilter: { RelationType: RelationTypes.group, ForeignFolderName: FolderNames.users },
            },
            (groupsResult: IApiDataResponse<TApiItemWithReleations<IApiGroup>>) => {
                const editedGroupUserGuids = groupsResult.Data[0].Relations.map((r) => r.ForeignItemGUID);
                this.connection.callApi(
                    'GetUsersByItemGuids',
                    {
                        itemGuids: editedGroupUserGuids,
                        includeRelations: true,
                        relationsFilter: {
                            RelationType: RelationTypes.group,
                            ForeignFolderName: FolderNames.groups,
                        },
                    },
                    (usersResult: IApiDataResponse<TApiItemWithReleations<IApiUser>>) => {
                        successCb(usersResult.Data.filter((u) => u.IsActive));
                    }
                );
            }
        );
    };

    readonly saveSearchFeedback = (clickedResult: string | null, query: string, callback?: (result: IApiSaveResponse | null) => void) => {
        const path = clickedResult ? UserSettingsKeys.searchSuccessPath : UserSettingsKeys.searchUnsuccessPath;
        const name = query.slice(0, 64); // DB can only store up to 64 chars
        this.connection.callApi(
            'SearchUserSettings',
            {
                transmitObject: {
                    Path: path,
                    Name: name,
                },
            },
            (userSettingResult: IApiDataResponse<IApiUserSetting>) => {
                const userSettingsObject = userSettingResult.Data[0];
                let transmitObject;
                if (userSettingsObject && userSettingsObject.ItemGUID) {
                    transmitObject = {
                        ItemGUID: userSettingsObject.ItemGUID,
                        ItemVersion: userSettingsObject.ItemVersion + 1,
                        Path: path,
                        Name: name,
                        Value: Number(userSettingsObject.Value) + 1,
                        FileAs: clickedResult,
                    };
                } else {
                    transmitObject = {
                        Path: path,
                        Name: name,
                        Value: 1,
                        FileAs: clickedResult,
                    };
                }
                this.connection.callApi('SaveUserSetting', { transmitObject }, (saveUserSettingResult: IApiSaveResponse) => {
                    callback && callback(saveUserSettingResult);
                });
            }
        );
    };

    /**
     * Saves User Setting. If the setting already exists, it is overwritten with new value.
     * @param transmitObject UserSetting object to save
     */
    readonly saveUserSetting = async (transmitObject: Partial<IApiUserSetting>) => {
        const { askApi } = this.connection;
        return await askApi<IApiSaveResponse>('SaveUserSetting', { transmitObject: transmitObject });
    };

    readonly askForUserSetting = async (transmitObject: Partial<IApiUserSetting>) => {
        const { askApi } = this.connection;
        const userSettingRes = await askApi<IApiDataResponse<IApiUserSetting>>('SearchUserSettings', {
            transmitObject,
        });
        return userSettingRes.Data[0];
    };

    /**
     * Loads license key and licensing bundles capacity info.
     * @param doReload If true, forces the API/WS to download the most recent license key from license server and then returns it. If false, returns only the license which is currently in memory.
     * @param tryWithoutReloadIfFails If doReaload is true and the downloading of the new license key fails ()
     */
    readonly loadLicense = (doReload: boolean, tryWithoutReloadIfFails: boolean, callback: (license: IApiLicense) => void) => {
        if (!doReload && tryWithoutReloadIfFails) {
            throw new Error('Wrong argument. The arument tryWithoutReloadIfFails can be true only of doRealod is true.');
        }

        this.connection.callApi(
            'GetLicense',
            { doReload: doReload },
            (licResult: IApiDatumResponse<IApiLicense>) => callback(licResult.Datum),
            tryWithoutReloadIfFails ? () => this.loadLicense(false, false, callback) : undefined
        );
    };


    /**
     * Loads license key and licensing bundles capacity info.
     * @param doReload If true, forces the API/WS to download the most recent license key from license server and then returns it. If false, returns only the license which is currently in memory.
     * @param tryWithoutReloadIfFails If doReaload is true and the downloading of the new license key fails ()
     */
    readonly askLoadLicense = async (doReload: boolean, tryWithoutReloadIfFails: boolean) => {
        return await new Promise<IApiLicense>((resolve) => {
            this.loadLicense(doReload, tryWithoutReloadIfFails, resolve);
        });
    };

    static pickEnumValueTranslation(enumValue: IApiEnumValue | null): string | null {
        if (!enumValue) {
            return null;
        }
        const exact = Strings.switch(enumValue.En, enumValue.Cs);
        if (exact) {
            return exact;
        }
        return enumValue.En;
    }

    static getDisplayableGroups = <T extends IApiGroup>(groups: T[]) => groups.filter(grp => !grp.IsCategory && !grp.IsOutlookCategory && grp.GroupName !== GroupNames.systemHealthNotification);

    static getDisplayableFieldFolders = (otFolders: IApiObjectType[], licenseRestrictionsHelper: LicenseRestrictionsHelper): string[] =>
        otFolders.filter((ot) => ot.IsModule && ot.IsActive && (ot.ContainsAnyPermittableColumn || ot.IsCustomizable) && !licenseRestrictionsHelper.isFolderNameHidden(ot.FolderName as TFolderName)).map((ot) => ot.FolderName);

    static getDisplayableWorkflowFolders = (otFolders: IApiObjectType[], licenseRestrictionsHelper: LicenseRestrictionsHelper): string[] =>
        otFolders.filter((ot) => ot.IsModule && ot.IsActive && ot.IsCustomizable && !!FolderNames.getEnumTypeName(ot.FolderName) && !licenseRestrictionsHelper.isFolderNameHidden(ot.FolderName as TFolderName)).map((ot) => ot.FolderName as TFolderName);

    static getDisplayableModulePermissionFolders = (otFolders: IApiObjectType[], licenseRestrictionsHelper: LicenseRestrictionsHelper): string[] =>
        otFolders.filter((ot) => ot.IsModule && ot.IsActive && !licenseRestrictionsHelper.isFolderNameHidden(ot.FolderName as TFolderName)).map((ot) => ot.FolderName);

    static getDisplayableFieldPermissionFolders = (otFolders: IApiObjectType[], licenseRestrictionsHelper: LicenseRestrictionsHelper): string[] =>
        otFolders.filter((ot) => ot.IsModule && ot.IsActive && ot.ContainsAnyPermittableColumn && !licenseRestrictionsHelper.isFolderNameHidden(ot.FolderName as TFolderName)).map((ot) => ot.FolderName);

    static getDisplayableLayoutsFolders = (otFolders: IApiObjectType[], licenseRestrictionsHelper: LicenseRestrictionsHelper): string[] =>
        otFolders.filter((ot) => ot.IsModule && ot.IsActive && !licenseRestrictionsHelper.isFolderNameHidden(ot.FolderName as TFolderName)).map((ot) => ot.FolderName);

    static getDisplayableEnumTypes = (enumTypes: IApiEnumType[]) => {
        // Skip the enums which have wf model, are without folder or are readonly
        return enumTypes.filter(
            (enumType) => !enumType.AssociatedWorkflowModelGuid && enumType.AssociatedFolderNames && enumType.AssociatedFolderNames.length !== 0 && EnumTypes.isEditable(enumType.EnumName)
        );
    };

    static convertEnumValuesForCombo(values: IApiEnumValue[]): { text: string; key: string }[] {
        return values
            .sort((a, b) => StringHelper.localeCompare(RemoteItemStore.pickEnumValueTranslation(a), RemoteItemStore.pickEnumValueTranslation(b)))
            .map((c) => ({ text: RemoteItemStore.pickEnumValueTranslation(c) || '', key: c.FileAs || '' }));
    }

    /**
     * Loads data about new available version
     * @param reloadData ```true```: reload data from API, ```false```: use data from cache
     */
    readonly loadAvailableVersionData = (currentWsVersion: string, reloadData: boolean, callback: (availableVersionResult: IApiAvailableVersionResponse) => void) => {
        const availableVersionInfoCacheKey = `${LocalStorageHelper.names.availableVersionInfo}-${currentWsVersion}`;
        const availableVersionInfoCache = LocalStorageHelper.getItem<IApiAvailableVersionResponse>(availableVersionInfoCacheKey);
        if (!reloadData && availableVersionInfoCache) {
            setTimeout(() => callback(availableVersionInfoCache), 0);
        } else {
            this.connection.callApi('GetAvailableVersionInfo', { doReload: reloadData }, (availableVersionResult: IApiAvailableVersionResponse) => {
                let timeToExpire = 1000 * 60 * 15; // 15 mins
                if (availableVersionResult.IsNewVersionAvailable) {
                    timeToExpire = 1000 * 60 * 60; // 1 hr
                }
                LocalStorageHelper.setItem(availableVersionInfoCacheKey, availableVersionResult, timeToExpire);
                callback(availableVersionResult);
            });
        }
    };

    /**
     * Loads data about new available version
     * @param reloadData ```true```: reload data from API, ```false```: use data from cache
     */
    readonly askLoadAvailableVersionData = async (currentWsVersion: string, reloadData: boolean) => {
        return await new Promise<IApiAvailableVersionResponse>((resolve) => {
            this.loadAvailableVersionData(currentWsVersion, reloadData, resolve);
        });
    };

    readonly saveModulePermissions = async (itemsToSave: IApiModulePermission[]) => {
        return await this.connection
            .askApi('SaveModulePermissions', {
                transmitObjects: itemsToSave.map((item) => ({
                    GroupGuid: item.GroupGuid,
                    FolderName: item.FolderName,
                    View: item.View,
                    Edit: item.Edit,
                    Delete: item.Delete,
                    CanCreate: item.CanCreate,
                    CanExport: item.CanExport,
                    CanSeeHistory: item.CanSeeHistory,
                    RowsRestriction: item.RowsRestriction,
                })),
            });
    };

    readonly askForFieldsLanguageColumns = async () => {
        const displayLanguageColumnsSetting = await this.askForUserSetting({
            Path: UserSettingsKeys.fieldsPath,
            Name: UserSettingsKeys.fieldsDisplayLanguageColumnsName,
        });

        const guiLanguage = StringHelper.capitalize(Strings.getLanguage()) as string;
        let displayLanguageColumns = [guiLanguage];

        const languagesInUserSetting = displayLanguageColumnsSetting?.Value?.split(';').filter((lang) => EnumValuesColumns.LANGUAGE_COLUMNS.includes(lang));

        if (!languagesInUserSetting || languagesInUserSetting.length === 0) {
            const companyLanguage = (await this.askForGlobalSetting('DefaultLanguage', null)) as string | null;
            displayLanguageColumns = [StringHelper.capitalize(Strings.getLanguage()) as string];
            if (companyLanguage && companyLanguage !== Strings.getLanguage()) {
                // Also show default company language
                displayLanguageColumns.push(StringHelper.capitalize(companyLanguage) as string);
            }
        } else {
            displayLanguageColumns.push(...languagesInUserSetting.filter((lang) => lang !== guiLanguage));
        }

        return displayLanguageColumns;
    };

    readonly toggleFieldsDisplayLanguageColumns = (changedLanguage: string, currentDisplayLanguages: string[] | null, doNotSaveToUserSettings?: boolean) => {
        const guiLanguage = StringHelper.capitalize(Strings.getLanguage()) as string;
        const newLanguages = [
            guiLanguage, // GUI language is always first
            ...EnumValuesColumns.LANGUAGE_COLUMNS.filter((currentLanguage) => {
                if (currentLanguage === changedLanguage) {
                    return !currentDisplayLanguages?.includes(currentLanguage);
                }
                return currentLanguage !== guiLanguage && currentDisplayLanguages?.includes(currentLanguage);
            }),
        ];

        if (!doNotSaveToUserSettings) {
            // Don't await response to make toggle faster
            this.saveUserSetting({
                Path: UserSettingsKeys.fieldsPath,
                Name: UserSettingsKeys.fieldsDisplayLanguageColumnsName,
                Value: newLanguages.join(';'),
            })
                .catch((err) => console.error('Unable save user setting.', err));
        }

        return newLanguages;
    };
}
