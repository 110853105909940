import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import React from 'react';
import type { TFeatureType } from '../../../data/constants/FeatureTypes';
import Strings from '../../../strings';
import SideMenuGrid from '../../shared/SideMenuGrid';
import Toggle from '../../shared/Toggle';
import type { TFeature } from '../Features';

type TFeaturesMenuProps = {
    items: TFeature[];
    selectedItemId: string | null;
    searchedString: string;
    isCollapsed: boolean;
    onMenuItemClick: (newFeature: string) => void;
    onFeatureToggle: (featureType: TFeatureType, isActive: boolean) => void;
};

const gridColumns = [
    { name: 'icon', title: '' },
    { name: 'name', title: '' },
    { name: 'isActive', title: '' },
];

const columnExtensions: VirtualTable.ColumnExtension[] = [
    { columnName: 'icon', width: '54px' },
    { columnName: 'name', width: '158px' },
    { columnName: 'isActive', width: '58px' },
];

const gridColumnsCollapsed = gridColumns.filter((col) => col.name === 'icon');
const columnExtensionsCollapsed = columnExtensions.filter((col) => col.columnName === 'icon');

const myStrings = Strings.components.routes.features;

export default class FeaturesMenu extends React.Component<TFeaturesMenuProps> {
    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = (props) => {
        const row = props.row as TFeature;

        if (props.column.name === 'icon') {
            return (
                <VirtualTable.Cell className={'secondary-menu__iconCell'} {...props}>
                    <img src={row.icon} alt={row.name} className="secondary-menu__icon" />
                </VirtualTable.Cell>
            );
        } else if (props.column.name === 'isActive') {
            if (row.canBeToggled && row.isLocked) {
                return (
                    <VirtualTable.Cell className={'align-middle'} {...props}>
                        <Toggle
                            checked={!row.isLocked}
                            onClick={(e) => {
                                e.stopPropagation(); // Do not change menu selection when clicked on toggle
                            }}
                            onChange={() => {
                                this.props.onFeatureToggle(row.type, !row.isLocked);
                            }}
                        />
                    </VirtualTable.Cell>
                );
            }
            return null;
        }
        return <VirtualTable.Cell className={'align-middle pl-0 secondary-menu__contentCell'} {...props} />;
    };

    render() {
        return (
            <SideMenuGrid
                items={this.props.items}
                columns={this.props.isCollapsed ? gridColumnsCollapsed : gridColumns}
                getRowId={(row) => row.type.toLowerCase()}
                selectedItemId={this.props.selectedItemId}
                onMenuItemChange={this.props.onMenuItemClick}
                searchedString={this.props.searchedString}
                cellComponent={this.CellComponent}
                columnExtensions={this.props.isCollapsed ? columnExtensionsCollapsed: columnExtensions}
                noDataText={myStrings.noFeatureMatch}
            />
        );
    }
}
