import { useEffect, useState } from 'react';
import useDetectKeydown from './useDetectKeydown';

const useListArrowFocus = (listSize: number, reachedTopCb?: () => void, reachedBottomCb?: () => void): [number ,React.Dispatch<React.SetStateAction<number>>] => {
    const [currentFocus, setCurrentFocus] = useState(0);

    useEffect(() => {
        setCurrentFocus(0);
    }, [listSize]);

    useDetectKeydown((key, e) => {
        if (key === 'ArrowUp') {
            e.preventDefault();
            if (currentFocus <= 1) {
                setCurrentFocus(0);
                reachedTopCb && reachedTopCb();
            } else {
                setCurrentFocus(currentFocus - 1);
            }
        } else if (key === 'ArrowDown') {
            e.preventDefault();
            if (currentFocus === listSize) {
                reachedBottomCb && reachedBottomCb();
            } else {
                setCurrentFocus(currentFocus + 1);
            }
        }
    });

    return [currentFocus, setCurrentFocus];
};

export default useListArrowFocus;
