import { mergeStyles, TooltipHost } from '@fluentui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import ExternalLinks from '../../../data/constants/ExternalLinks';
import Strings from '../../../strings';
import workflowSplashImg from '../../../img/splash/workflow-splash.svg';
import FakeDisabledButton from '../../shared/FakeDisabledButton';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import type { TFolderName } from '@eway-crm/connector';
import LicenseRestrictionsTooltipContent from '../../shared/locks/LicenseRestrictionsTooltipContent';

type TSimpleOrAdvancedEditorPickerProps = React.HTMLProps<HTMLDivElement> & {
    onSimpleClick: () => Promise<void>;
    folderName: TFolderName;
};

const myStrings = Strings.components.routes.workflow;

const SimpleOrAdvancedEditorPicker: React.FC<TSimpleOrAdvancedEditorPickerProps> = ({ onSimpleClick, className, folderName, ...restProps }) => {
    const { licenseRestrictionsHelper } = useContext(ConnectionContext);
    const { isLimitReached: isBasicWorkflowLimitReached, licenseRestriction: basicWorkflowLicenseRestriction } = licenseRestrictionsHelper.isBasicWorkflowCountExceeded(folderName);
    const { isLimitReached: isAdvancedWorkflowLimitReached, licenseRestriction: advancedWorkflowLicenseRestriction } = licenseRestrictionsHelper.isAdvancedWorkflowCountExceeded(folderName);
    return (
        <div className={mergeStyles('h-100 position-relative flex-fill d-flex flex-column align-items-center justify-content-center', className)} {...restProps}>
            <img className="d-inline-block" src={workflowSplashImg} style={{ height: '160px', width: '100%' }} alt="" />
            <div className="text-black-50 text-center pt-4" style={{ fontSize: '1.5rem' }}>
                {myStrings.simpleOrAdvancedPickTitle}
            </div>
            <p className="pb-4 text-center text-black-50 pt-1" style={{ padding: '0 5rem', fontSize: '0.9rem' }}>
                {myStrings.simpleOrAdvancedPickSubtitle}
            </p>
            <div className="d-flex justify-content-center">
                <TooltipHost content={isBasicWorkflowLimitReached ? <LicenseRestrictionsTooltipContent licenseRestriction={basicWorkflowLicenseRestriction} /> : undefined}>
                    <Button variant="primary" className="mr-3" onClick={() => void onSimpleClick()} disabled={isBasicWorkflowLimitReached}>
                        {myStrings.simpleEditor}
                    </Button>
                </TooltipHost>
                <TooltipHost content={isAdvancedWorkflowLimitReached ? <LicenseRestrictionsTooltipContent licenseRestriction={advancedWorkflowLicenseRestriction} /> : undefined}>
                    <FakeDisabledButton variant={'orange'} href={ExternalLinks.oldAdminAppWorkflow} as={'a'} disabled={isAdvancedWorkflowLimitReached}>
                        {myStrings.advancedEditor}
                    </FakeDisabledButton>
                </TooltipHost>
            </div>
        </div>
    );
};

export default SimpleOrAdvancedEditorPicker;
