import type { IApiUser } from '@eway-crm/connector';
import { Dialog, DialogFooter } from '@fluentui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import Strings from '../../../../strings';

const myStrings = Strings.components.routes.users;

type EffectivePermissionsToggleGroupDialogProps = {
    onConfirm: () => Promise<void>;
    onCancel: () => void;
    currentUser: IApiUser;
    isAddingGroup: boolean;
    groupName: string;
};

const EffectivePermissionsToggleGroupDialog: React.FC<EffectivePermissionsToggleGroupDialogProps> = ({ onCancel, onConfirm, currentUser, isAddingGroup, groupName }) => {
    return (
        <Dialog
            modalProps={{ isBlocking: true }}
            dialogContentProps={{
                showCloseButton: false,
                title: isAddingGroup ? myStrings.addUserToGroupTitle : myStrings.removeUserFromGroupTitle,
                subText: isAddingGroup
                    ? (Strings.formatString(myStrings.addUserToGroupText, currentUser.FileAs ?? '', groupName) as string)
                    : (Strings.formatString(myStrings.removeUserFromGroupText, currentUser.FileAs ?? '', groupName) as string),
            }}
            hidden={false}
        >
            <DialogFooter>
                <Button variant="warning" onClick={() => void onConfirm()}>
                    {Strings.yes}
                </Button>
                <Button
                    variant="outline-secondary"
                    onClick={() => {
                        onCancel();
                    }}
                >
                    {Strings.no}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default EffectivePermissionsToggleGroupDialog;
