import React from 'react';
import { Button } from 'react-bootstrap';
import type { TFormDropdownOption } from './forms/FormDropdown';
import { FormDropdown } from './forms/FormDropdown';

export type TMultiselectDropdownOption = TFormDropdownOption;

type TMultiselectDropdownProps = {
    options: TMultiselectDropdownOption[];
    pickedOptions: TMultiselectDropdownOption[];
    onValueChange: (newPickedOptions: TMultiselectDropdownOption[]) => void;
    noOptionPickedTitle: string;
    isInvalid: boolean;
};

const noOptionPickedValue = '__noOptionPicked';

const MultiselectDropdown: React.FC<TMultiselectDropdownProps> = ({ options, pickedOptions, noOptionPickedTitle, isInvalid, onValueChange }) => {
    const removeItem = (removedValue: string) => {
        onValueChange(pickedOptions.filter((item) => item.value !== removedValue));
    };

    const addItem = (addedValue: string) => {
        onValueChange([...pickedOptions, options.find((item) => item.value === addedValue)!]);
    };

    return (
        <div className="d-flex flex-row flex-wrap">
            {pickedOptions.map((pickedOption, i) => (
                <div className="d-flex flex-nowrap align-items-center form-control-like mr-2" key={'picked-group-' + i}>
                    <div className="d-inline-block text-truncate" style={{ lineHeight: '1.5rem', maxWidth: '10rem' }}>
                        {pickedOption.title}
                    </div>
                    <Button
                        variant="outline-secondary"
                        size="sm"
                        className="p-0"
                        onClick={() => {
                            removeItem(pickedOption.value);
                        }}
                    >
                        <i className="mdl2 mdl2-cancel" aria-hidden="true" />
                    </Button>
                </div>
            ))}
            {options.length > 1 && (
                <div>
                    <FormDropdown
                        options={[{ title: noOptionPickedTitle, value: noOptionPickedValue }].concat(options)}
                        value={noOptionPickedValue}
                        onValueChange={(newValue) => {
                            newValue !== noOptionPickedValue && addItem(newValue);
                        }}
                        isInvalid={isInvalid}
                    />
                </div>
            )}
        </div>
    );
};

export default MultiselectDropdown;
