import * as React from 'react';
import Strings from '../../strings';
import type { IApiAvailableVersionResponse, IApiDataResponse, IApiGlobalSetting } from '@eway-crm/connector';
import { ButtonToolbar, Button } from 'react-bootstrap';
import { InstallerWizard } from './updates/InstallerWizard';
import { RemoteItemStore } from '../../RemoteItemStore';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import { AppBuildModeHelper, DateHelper, ExternalLinks, OptionalTooltip, Spinner, SpinnerVariant } from '@eway-crm/gui';
import RouteConfig from '../../RouteConfig';
import { Link } from 'react-router-dom';
import { Subtitle2 } from '@fluentui/react-components';
import APP_BUILD_MODE from '../../AppBuildMode';
import ReactHelper from '../../helpers/ReactHelper';
import LocalizationHelper from '../../helpers/LocalizationHelper';

type TUpdatesProps = {
    isNewVersionAvailableCallback: (isNewVersionAvailable: boolean) => void;
};

type TUpdatesState = {
    availableVersionInfo: IApiAvailableVersionResponse | null;
    isInstalling: boolean;
    maintenanceWindowGs: IApiGlobalSetting | null;
};

const myStrings = Strings.components.routes.updates;
const iframeName = 'versionChangelogIframe';

export class Updates extends React.Component<TUpdatesProps, TUpdatesState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TUpdatesProps) {
        super(props);
        this.state = {
            availableVersionInfo: null,
            isInstalling: false,
            maintenanceWindowGs: null
        };
    }

    componentDidMount() {
        void this.load();
    }

    private readonly reload = () => {
        this.setState(
            {
                availableVersionInfo: null,
                isInstalling: false
            },
            () => void this.load()
        );
    };

    private readonly handleUpdateInstaller = () => {
        console.log('Reloading page after update finished...');
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        window.location.reload(true);
    };

    private readonly load = async () => {
        const availableVersionResult = await new RemoteItemStore(this.context.connection).askLoadAvailableVersionData(this.context.version.wsVersion, true);
        await ReactHelper.setState(this, { availableVersionInfo: availableVersionResult, isInstalling: availableVersionResult.IsNewVersionAvailable && (availableVersionResult.IsApproved || false) });
        this.props.isNewVersionAvailableCallback(availableVersionResult.IsNewVersionAvailable);
        const isDisabledByMaintenanceWindow = this.isDisabledByMaintenanceWindow(availableVersionResult);
        if (isDisabledByMaintenanceWindow) {
            const globalSettingsResult = await this.context.connection.askApi<IApiDataResponse<IApiGlobalSetting>>('SearchGlobalSettings', { transmitObject: { Name: 'MaintenanceWindow' } });
            this.setState({ maintenanceWindowGs: globalSettingsResult.Data[0] });
        }
    };

    private readonly install = () => {
        if (this.state.isInstalling) {
            return;
        }
        this.setState({ isInstalling: true });
    };

    private readonly isDisabledByMaintenanceWindow = (availableVersionInfo: IApiAvailableVersionResponse | null) => {
        if (!availableVersionInfo || !availableVersionInfo.UpdateBlockingTodayMaintenance)
            return false;

        const dateNow = new Date();
        const startDateTime = new Date(availableVersionInfo.UpdateBlockingTodayMaintenance.Start);
        const endDateTime = new Date(availableVersionInfo.UpdateBlockingTodayMaintenance.End);
        if (startDateTime > endDateTime) {
            return dateNow >= startDateTime || dateNow <= endDateTime;
        }

        return dateNow >= startDateTime && dateNow <= endDateTime;
    };

    private readonly getDisabledByMaintentanceWindowTooltipContent = () => {
        if (!this.state.availableVersionInfo || !this.state.availableVersionInfo.UpdateBlockingTodayMaintenance) {
            throw new Error(`Maintenance window data is not available`);
        }

        const isPremise = AppBuildModeHelper.isPremise(APP_BUILD_MODE);
        const showLinkToGs = isPremise && this.state.maintenanceWindowGs && this.state.maintenanceWindowGs.Visible;

        const getFormattedTime = (date: Date) => {
            if (LocalizationHelper.isLangCs()) {
                return DateHelper.getFormattedTime(date);
            }

            return new Intl.DateTimeFormat('en-US', { hour: 'numeric', minute: 'numeric', hour12: true }).format(date);
        };

        const startDateTime = new Date(this.state.availableVersionInfo.UpdateBlockingTodayMaintenance.Start);
        const endDateTime = new Date(this.state.availableVersionInfo.UpdateBlockingTodayMaintenance.End);

        const isTomorrow = startDateTime > endDateTime;
        const time = getFormattedTime(endDateTime);
        let text = myStrings.installDisabledMaintenanceWindowText;
        if (isTomorrow) {
            text += ` ${Strings.formatString(myStrings.installDisabledMaintenanceWindowTryAgainTomorrow, time) as string}`;
        } else {
            text += ` ${Strings.formatString(myStrings.installDisabledMaintenanceWindowTryAgain, time) as string}`;
        }

        const disabledByMaintenanceTooltipContent = (
            <div className={"p-1 d-flex flex-column"}>
                <Subtitle2 block className={"mb-1"}>
                    {myStrings.installDisabledMaintenanceWindowTitle}
                </Subtitle2>
                <div>
                    {text} {showLinkToGs && Strings.formatString(
                        myStrings.installDisabledMaintentaceWindowChangeText,
                        <Link to={`${RouteConfig.customizations.features.path}/general?feature=MaintenanceWindow`}>{myStrings.installDisabledMaintenanceWindowLinkText}</Link>
                    )}
                </div>
            </div>
        );

        return {
            style: { minWidth: 450 },
            children: disabledByMaintenanceTooltipContent
        };
    };

    render() {
        let isLoading;
        let header;
        let body;
        if (!this.state.availableVersionInfo) {
            isLoading = true;
            header = <h1>{myStrings.title}</h1>;
            body = (
                <Spinner variant={SpinnerVariant.ease} />
            );
        } else {
            isLoading = false;
            const currentOutlookClientVersion = this.context.version.outlookClientVersion;

            if (!this.state.availableVersionInfo.IsNewVersionAvailable) {
                header = <h1>{myStrings.title}</h1>;
                body = (
                    <div className="col p-0 align-self-center text-center">
                        <div className="text-muted">
                            <h4>{myStrings.noUpdateAvailable}</h4>
                            <div>{Strings.formatString(myStrings.currentVersionFormat, currentOutlookClientVersion)}</div>
                            <div>
                                <a href={ExternalLinks.getOutlookDownloadLink(APP_BUILD_MODE, currentOutlookClientVersion)} rel="noopener noreferrer" target="_blank">
                                    {myStrings.download}
                                </a>
                            </div>
                        </div>
                    </div>
                );
            } else {
                header = (
                    <>
                        <h1>{Strings.formatString(myStrings.updateAvailableFormat, this.state.availableVersionInfo.ClientVersion!)}</h1>
                        <p>{Strings.formatString(myStrings.newerVersionAvailableFormat, this.state.availableVersionInfo.ClientVersion!, Strings.formatDate(this.state.availableVersionInfo.ReleaseDate)!, currentOutlookClientVersion)}</p>
                    </>
                );
                if (this.state.isInstalling) {
                    body = (
                        <InstallerWizard
                            previousOutlookClientVersion={currentOutlookClientVersion}
                            outlookClientVersion={this.state.availableVersionInfo.ClientVersion!}
                            webServiceVersion={this.state.availableVersionInfo.WebServiceVersion!}
                            isAlreadyApproved={this.state.availableVersionInfo.IsApproved || false}
                            kbLink={this.state.availableVersionInfo.KnowledgeBaseLink}
                            onDone={this.handleUpdateInstaller}
                        />
                    );
                } else {
                    if (this.state.availableVersionInfo.KnowledgeBaseLink) {
                        body = (
                            <div className="col p-0">
                                <div className="position-absolute" style={{ top: 0, left: 0, right: 0, bottom: 0 }}>
                                    <iframe src={'iframe.html?src=' + encodeURIComponent(this.state.availableVersionInfo.KnowledgeBaseLink)} title={this.state.availableVersionInfo.ClientVersion!} className="w-100 h-100 border-0" id={iframeName} name={iframeName} />
                                </div>
                            </div>
                        );
                    } else {
                        body = <></>;
                    }
                }
            }
        }

        const isDisabledByMaintenanceWindow = this.isDisabledByMaintenanceWindow(this.state.availableVersionInfo);

        return (
            <div className="container-fluid d-flex flex-column min-h-100">
                <div className="row">
                    <div className="col p-0">
                        <div>{header}</div>
                        <hr className="mb-1 mt-4" />
                        {!isLoading && (
                            <ButtonToolbar>
                                {!!this.state.availableVersionInfo?.IsNewVersionAvailable && (
                                    <OptionalTooltip relationship={"inaccessible"} content={this.getDisabledByMaintentanceWindowTooltipContent()} showTooltip={isDisabledByMaintenanceWindow}>
                                        <Button variant="outline-secondary" onClick={this.install} disabled={this.state.isInstalling || isDisabledByMaintenanceWindow}>
                                            <i className="mdl2 mdl2-play" aria-hidden="true" /> {myStrings.install}
                                        </Button>
                                    </OptionalTooltip>
                                )}
                                <Button variant="outline-secondary" onClick={this.reload} disabled={this.state.isInstalling}>
                                    <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                </Button>
                            </ButtonToolbar>
                        )}
                    </div>
                </div>
                <div className="row flex-fill d-flex justify-content-start mt-2">
                    {body}
                </div>
            </div>
        );
    }
}
