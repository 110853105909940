import React from 'react';

type TOptionalComponentWrapperProps<T> = {
    wrapperComponent?: React.ComponentType<React.PropsWithChildren & { data?: T }>;
    data?: T;
};

const OptionalComponentWrapper = <T,>({ children, wrapperComponent, data }: React.PropsWithChildren<TOptionalComponentWrapperProps<T>>) => {
    if (wrapperComponent) {
        const Wrapper = wrapperComponent;
        return <Wrapper data={data}>{children}</Wrapper>;
    }

    return <>{children}</>;
};

export default OptionalComponentWrapper;
