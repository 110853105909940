import React from 'react';
import { mergeStyles } from '@fluentui/react';
import type { ButtonProps} from 'react-bootstrap';
import { Button, Dropdown } from 'react-bootstrap';
import Strings from '../../strings';
import type { IFormCheckChangeEvent } from '../interfaces/IFormCheckChangeEvent';

type TFilterDropdownSwitchProps = {
    label: string;
    id: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

const toggleStyle = {
    cursor: 'pointer',
    selectors: {
        '::before': {
            cursor: 'pointer',
        },
        '::after': {
            cursor: 'pointer',
        },
    },
};

const toggleContainerStyles = {
    selectors: {
        'div:nth-of-type(n+2)': {
            marginTop: '0.5rem',
        },
    },
};

export default class FilterDropdown extends React.Component<React.PropsWithChildren> {
    static readonly Switch: React.FC<TFilterDropdownSwitchProps> = ({ label, id, checked, onChange, ...restProps }) => {
        return (
            <div className="custom-control custom-switch" {...restProps}>
                <input type="checkbox" className="custom-control-input" id={id} checked={checked} onChange={(e: IFormCheckChangeEvent) => onChange(e.target.checked)} />
                <label className={mergeStyles('custom-control-label', toggleStyle)} htmlFor={id}>
                    {label}
                </label>
            </div>
        );
    };

    private static readonly Toggle = React.forwardRef<HTMLButtonElement, Required<Pick<ButtonProps, 'onClick'>>>((props, ref) => (
        <Button variant="outline-secondary" ref={ref} onClick={(e) => props.onClick(e)}>
            <i className="mdl2 mdl2-filter" aria-hidden="true" /> {Strings.filter}
        </Button>
    ));

    render() {
        return (
            <Dropdown drop="left">
                <Dropdown.Toggle as={FilterDropdown.Toggle} id="users-filter-toggle-button" />
                <Dropdown.Menu className="p-2">
                    <div className={mergeStyles('text-nowrap', toggleContainerStyles)}>{this.props.children}</div>
                </Dropdown.Menu>
            </Dropdown>
        );
    }
}
