import * as React from 'react';
import Strings from "../../../strings";
import type { IApiUser } from '@eway-crm/connector';

type TUserBadgeFlagsProps = {
    item: IApiUser;
    currentUserGuid: string;
    style?: React.CSSProperties;
    currentGroupGuidBadgedAsDefault?: string;
};

export class UserBadgeFlags extends React.Component<TUserBadgeFlagsProps> {

    render() {
        const myStrings = Strings.components.routes.users;

        return (
            <>
                {this.props.item.IsSystem &&
                    <span style={this.props.style}> <span className="badge badge-warning">{myStrings.system}</span></span>
                }
                {this.props.item.ItemGUID === this.props.currentUserGuid &&
                    <span style={this.props.style}> <span className="badge badge-success">{myStrings.you}</span></span>
                }
                {!this.props.item.IsActive &&
                    <span style={this.props.style}> <span className="badge badge-gray">{Strings.inactive}</span></span>
                }
                {(!!this.props.item.Server_IsAccountLocked) &&
                    <span style={this.props.style}> <span className="badge badge-danger">{myStrings.locked}</span></span>
                }
                {(!!this.props.currentGroupGuidBadgedAsDefault && this.props.item.Groups_Default_GroupGuid === this.props.currentGroupGuidBadgedAsDefault) &&
                    <span style={this.props.style}> <span className="badge badge-success">{myStrings.defaultGroup}</span></span>
                }
            </>
        );
    }
}