import type { SpinnerVariant } from '@eway-crm/gui';
import { Spinner } from '@eway-crm/gui';
import * as React from 'react';
import { Modal } from 'react-bootstrap';
import './SpinnerModal.scss';

type TSpinnerModalProps = {
    variant?: SpinnerVariant;
    className?: string;
};

export class SpinnerModal extends React.PureComponent<TSpinnerModalProps> {
    static readonly CenteredContent: React.FC<TSpinnerModalProps> = ({ className, ...restProps }) => (
        <div className={'spinner-modal__centered-content'}>
            <Spinner {...restProps} />
        </div>
    );

    render() {
        return (
            <Modal show={true} centered dialogClassName={'spinner-modal__dialog'}>
                <Modal.Body>
                    <div className={"spinner-modal__spinner position-relative"}>
                        <Spinner {...this.props} />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}
