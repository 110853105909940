import * as React from 'react';
import { CompoundButton, mergeStyles, mergeStyleSets, SpinButton } from '@fluentui/react';
import type { IRow } from "../../../../../data/shopping/ISubscriptionsShoppingModel";
import StoreV2Context from '../StoreV2Context';
import usePackage from '../hooks/usePackage';
import StoreHelper from '../../StoreHelper';
import { Subscriptions } from '../../../Subscriptions';
import Strings from '../../../../../strings';
import UnitPriceSpan from '../../UnitPriceSpan';
import PackageQuestionMarkPopover from '../../PackageQuestionMarkPopover';
import UnitHelper from '../../../../../helpers/UnitHelper';
import PackageQuantitySlider from '../PackageQuantitySlider/PackageQuantitySlider';
import stars1 from '../../../../../img/purchase/stars-1.svg';
import stars2 from '../../../../../img/purchase/stars-2.svg';
import stars3 from '../../../../../img/purchase/stars-3.svg';

const css = mergeStyleSets({
    spinButton: {
        width: '86px'
    },
    compoundButton: {
        width: '200px',
        paddingTop: '28px',
        paddingBottom: '28px'
    },
    questionMark: {
        position: 'absolute',
        top: '0',
        right: '0'
    },
    chooseQuantityTest: {
        fontSize: '14px',
        marginRight: '14px'
    }
});

type TEditionPickerRowProps = {
    row: IRow;
    isWithSlider?: boolean;
};

const EditionPickerRow: React.FC<TEditionPickerRowProps> = ({ row, isWithSlider }) => {
    const { packagesEditingStates, setMultiplePackagesEditingStates, activeUsersCount } = React.useContext(StoreV2Context);

    const { pickedArtno, rowQuantity } = React.useMemo(() => {
        let pickedArtno: string | null = null;
        let rowQuantity = 0;
        if (row.Artnos && row.Artnos.length !== 0) {
            row.Artnos.forEach((artno) => {
                const es = packagesEditingStates.find(ps => ps.artno === artno);
                if (es && es.state.currentEditQuantity) {
                    rowQuantity = es.state.currentEditQuantity;
                    pickedArtno = artno;
                }
            });
            if (!pickedArtno) {
                pickedArtno = row.Artnos[0];
            }
        }
        return { pickedArtno, rowQuantity };
    }, [row.Artnos, packagesEditingStates]);

    const handleToggle = React.useCallback((toggledArtno: string) => {
        if (!row.Artnos)
            return;

        const newStates = row.Artnos.map((artno) => {
            const es = packagesEditingStates.find(ps => ps.artno === artno);
            let newQuantity: number;
            if (es && es.state.currentEditQuantity) {
                newQuantity = 0;
            } else {
                if (toggledArtno === artno) {
                    newQuantity = rowQuantity || activeUsersCount;
                } else {
                    newQuantity = 0;
                }
            }
            return { artno, newState: { currentEditQuantity: newQuantity } };
        });
        setMultiplePackagesEditingStates(newStates, !!rowQuantity);
    }, [row.Artnos, packagesEditingStates, rowQuantity, activeUsersCount, setMultiplePackagesEditingStates]);

    if (!row.Artnos || !pickedArtno)
        return null;

    return (
        <div>
            <div className="d-flex">
                <div>
                    <div>
                        <div className="d-inline-flex justify-content-end m-0 buttons-row">
                            {row.Artnos.map((artno, idx) => <EditionPackage key={artno} starCount={idx + 1} artno={artno} rowQuantity={rowQuantity} onToggle={handleToggle} />)}
                        </div>
                    </div>
                    <div>
                        {(isWithSlider) ?
                            <div className="mt-3">
                                <PackageQuantitySlider artno={pickedArtno} isExternallyDisabled={!rowQuantity} />
                            </div>
                            :
                            <div className="d-flex justify-content-end align-items-center mt-3">
                                <RowSpinButton pickedArtno={pickedArtno} isExternallyDisabled={!rowQuantity} />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

type TRowSpinButtonProps = {
    pickedArtno: string;
    isExternallyDisabled: boolean;
};

const RowSpinButton: React.FC<TRowSpinButtonProps> = ({ pickedArtno, isExternallyDisabled }) => {
    const { setPackageEditingState } = React.useContext(StoreV2Context);
    const { packageEditingState, isDisabled, packageData } = usePackage(pickedArtno);

    const handleSpinChange = React.useCallback((e?: React.SyntheticEvent, newValue?: string) => {
        if (isDisabled)
            return;

        setPackageEditingState(pickedArtno, { currentEditQuantity: parseInt(newValue as string) });
    }, [isDisabled, pickedArtno, setPackageEditingState]);

    const myStrings = Strings.components.routes.subscriptions;

    if (!packageData)
        return null;

    return (
        <>
            <div className={css.chooseQuantityTest}>{myStrings.chooseTheNumberOf} {UnitHelper.pickTranslatedUnit(5, packageData.Unit)}</div>
            <SpinButton
                disabled={isDisabled || isExternallyDisabled}
                value={String(packageEditingState.currentEditQuantity || 0)}
                min={0}
                step={1}
                onChange={handleSpinChange}
                className={css.spinButton}
            />
        </>
    );
};

type TEditionPackageProps = {
    artno: string;
    rowQuantity: number;
    onToggle: (artno: string) => void;
    starCount: number;
};

const EditionPackage: React.FC<TEditionPackageProps> = ({ artno, rowQuantity, starCount, onToggle }) => {
    const context = React.useContext(StoreV2Context);
    const { packageData, packageEditingState, isDisabled, steps } = usePackage(artno);
    const handleToggle = React.useCallback(() => {
        if (isDisabled)
            return;

        onToggle(artno);
    }, [artno, onToggle, isDisabled]);

    if (!packageData)
        return null;

    let priceQuantity = rowQuantity;
    if (steps && steps.length !== 0 && !steps.includes(priceQuantity)) {
        let index = steps.findIndex(s => s >= priceQuantity);
        if (index === -1) {
            index = steps.length - 1;
        }
        priceQuantity = steps[index];
    }

    const { showUnitPriceAsUnit, shownPrice } = StoreHelper.getUnitPriceAttributes(
        packageData,
        context.customerInfo.currency,
        priceQuantity,
        context.selectedPeriodType,
        context.isPackageInCart
    );

    const getStarImg = () => {
        switch (starCount) {
            case 1:
                return stars1;
            case 2:
                return stars2;
            case 3:
                return stars3;
            default:
                console.error(`Star image for count ${starCount} is not defined.`);
                break;
        }
    };

    return (
        <div className="p-0 button-container-in-row">
            <CompoundButton disabled={isDisabled} toggle checked={!!packageEditingState.currentEditQuantity} onClick={handleToggle} className={mergeStyles(css.compoundButton, 'button-in-row')} onRenderChildren={() => (
                <div>
                    <div className="d-flex justify-content-center align-items-center mb-1">
                        <img src={getStarImg()} className="mr-1" style={{ height: '1rem' }} alt="test" />
                        <h4 className="mb-0">{Strings.pickTranslation(packageData.Title)}</h4>
                    </div>
                    <div>
                        {(typeof shownPrice === 'number') &&
                            <UnitPriceSpan
                                formattedPrice={(packageData.ShowPlusInPrice ? '+' : '') + Strings.formatCurrency(shownPrice, Subscriptions.priceDecPlaces, context.customerInfo.currency)!}
                                packageData={packageData}
                                showUnit={showUnitPriceAsUnit}
                            />
                        }
                    </div>
                    <div className={css.questionMark}>
                        <PackageQuestionMarkPopover packageData={packageData} currentDbSizeGigabytes={context.currentDbSizeGigabytes} />
                    </div>
                </div>
            )} />
        </div>
    );
};

export default EditionPickerRow;