import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col, Badge } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import FolderNames from '../../../data/constants/FolderNames';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiGroup, TFolderName } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import memoizeOne from 'memoize-one';
import StringHelper from '../../../helpers/StringHelper';
import ReactHelper from '../../../helpers/ReactHelper';
import Autosuggest from '../../shared/Autosuggest/Autosuggest';
import AutosuggestInput from '../../shared/Autosuggest/AutosuggestInput';
import { AutosuggestContextConsumer } from '../../shared/Autosuggest/AutosuggestContext';
import AutosuggestItems from '../../shared/Autosuggest/AutosuggestItems';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import { SpinnerVariant } from '@eway-crm/gui';

type DuplicateGroupWizardProps = {
    onDismiss: () => void;
    group: IApiGroup;
    forbiddenGroupNames: string[];
    onDone: () => void;
    allGroups: IApiGroup[];
};

type DuplicateGroupWizardState = {
    targetGroupName: string;
    assignUsers: boolean;
    copyModulePermissions: boolean;
    copyFieldPermissions: boolean;
    isSavingInProgress: boolean;
    isGroupNameInvalidMessage: string | null;
};

const myStrings = Strings.components.routes.groups;

class DuplicateGroupWizard extends React.Component<DuplicateGroupWizardProps, DuplicateGroupWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: DuplicateGroupWizardProps) {
        super(props);
        this.state = {
            targetGroupName: '',
            assignUsers: true,
            copyModulePermissions: false,
            copyFieldPermissions: false,
            isSavingInProgress: false,
            isGroupNameInvalidMessage: null,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = async () => {
        const { askApi } = this.context.connection;
        const targetExistingGroup = this.getTargetExistingGroup(this.state.targetGroupName);
        if (
            !this.state.targetGroupName ||
            this.state.isGroupNameInvalidMessage ||
            this.isDuplicationNotAllowedForGroupName(this.state.targetGroupName) ||
            this.isGroupNameForbidden(this.state.targetGroupName)
        ) {
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        let folderNames: TFolderName[] | null;
        if (!this.state.assignUsers && !this.state.copyModulePermissions) {
            folderNames = null;
        } else {
            folderNames = [];
            if (this.state.assignUsers) {
                folderNames.push(FolderNames.users);
            }
            if (this.state.copyModulePermissions) {
                folderNames.push(FolderNames.reports);
            }
        }

        const transmitObject = {
            itemGuid: this.props.group.ItemGUID,
            copyAssignmentOfAllItems: false,
            copyAssignmentOfFolderNames: folderNames,
            copyModulePermissions: this.state.copyModulePermissions,
            copyColumnPermissions: this.state.copyFieldPermissions,
            copyFlows: this.state.copyModulePermissions,
        };

        if (!targetExistingGroup) {
            await askApi('DuplicateGroup', {
                ...transmitObject,
                newGroupName: this.state.targetGroupName,
            });
        } else {
            await askApi('DuplicateGroup', {
                ...transmitObject,
                existingTargetGroupGuid: targetExistingGroup.ItemGUID,
            });
        }
        this.props.onDone();
    };

    private readonly getGroupOptions = memoizeOne((allGroups: IApiGroup[]) => {
        return allGroups
            .filter((g) => !this.isDuplicationNotAllowedForGroupName(g.GroupName))
            .sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName))
            .map((group) => ({ key: group.ItemGUID, value: group.GroupName }));
    });

    private readonly getTargetExistingGroup = (groupName: string) => this.props.allGroups.find((g) => g.GroupName.toLowerCase() === groupName.toLowerCase());

    private readonly isGroupNameForbidden = (groupName: string) => this.props.forbiddenGroupNames.map((g) => g.toLowerCase()).includes(groupName.toLowerCase());

    private readonly isDuplicationNotAllowedForGroupName = (groupName: string) => {
        const targetExistingGroup = this.getTargetExistingGroup(groupName);
        return (
            targetExistingGroup &&
            (targetExistingGroup.System ||
                targetExistingGroup.DisallowControlUserAssignment ||
                targetExistingGroup.DisallowControlModulePermissions ||
                targetExistingGroup.DisallowControlColumnPermissions ||
                targetExistingGroup.ContainsAnyUneditablePermission ||
                groupName === this.props.group.GroupName)
        );
    };

    private readonly onGroupNameChange = (newValue: string) => {
        let isGroupNameInvalidMessage = null;
        if (!newValue) {
            isGroupNameInvalidMessage = Strings.thisFieldIsMandatory;
        }

        const targetExistingGroup = this.getTargetExistingGroup(newValue);
        if (!targetExistingGroup && newValue.length < 3) {
            isGroupNameInvalidMessage = myStrings.groupNameTooShort;
        }

        if (targetExistingGroup && this.isDuplicationNotAllowedForGroupName(newValue)) {
            isGroupNameInvalidMessage = Strings.formatString(myStrings.duplicateGroupErrorIsSystem, targetExistingGroup.GroupName) as string;
        }
        if (this.isGroupNameForbidden(newValue) || this.props.group.GroupName === newValue) {
            isGroupNameInvalidMessage = Strings.formatString(myStrings.duplicateGroupErrorReserved, newValue) as string;
        }
        this.setState({ targetGroupName: newValue, isGroupNameInvalidMessage });
    };

    render() {
        const doesGroupNameAlreadyExist = !!this.props.allGroups.find((g) => g.GroupName === this.state.targetGroupName);
        const isSubmitDisabled = !!this.state.isGroupNameInvalidMessage || !this.state.targetGroupName;
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction }  = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.duplicateGroup}</h1>
                        <div className="mb-4">
                            <span className="badge badge-info badge-full-font mr-1">{this.props.group.FileAs}</span>
                        </div>
                        <div>
                            <Form className="form-application" onSubmit={(e) => e.preventDefault()}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="duplGrpName" className="mb-2">
                                            <Form.Label className="mb-1">{myStrings.targetGroupName}</Form.Label>
                                            <Autosuggest
                                                allowCreatingNewItems
                                                showChevron
                                                suggestions={this.getGroupOptions(this.props.allGroups)}
                                                onValueChange={this.onGroupNameChange}
                                                onValueSelection={this.onGroupNameChange}
                                                value={this.state.targetGroupName}
                                                invalid={!!this.state.isGroupNameInvalidMessage}
                                            >
                                                <>
                                                    <AutosuggestInput />
                                                    <AutosuggestContextConsumer>
                                                        {({ isInputFocused }) => (
                                                            <>
                                                                {!isInputFocused && !doesGroupNameAlreadyExist && this.state.targetGroupName && !this.state.isGroupNameInvalidMessage && (
                                                                    <Badge variant="success mx-1" style={{ fontSize: '0.75rem' }}>
                                                                        {myStrings.new}
                                                                    </Badge>
                                                                )}
                                                            </>
                                                        )}
                                                    </AutosuggestContextConsumer>
                                                    <AutosuggestItems />
                                                </>
                                            </Autosuggest>
                                            <div className="text-danger" style={{ height: '0.85rem' }}>
                                                {this.state.isGroupNameInvalidMessage ? this.state.isGroupNameInvalidMessage : ''}
                                            </div>
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="duplGrpAssUsers" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.formatString(myStrings.copyUsers, doesGroupNameAlreadyExist ? myStrings.replace : myStrings.include)}
                                                checked={this.state.assignUsers}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ assignUsers: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="duplGrpAssModulePerms" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    <>
                                                        {Strings.formatString(myStrings.copyModulePermissions, doesGroupNameAlreadyExist ? myStrings.replace : myStrings.include)}{' '}
                                                        {isModulePermissionsLocked && <LicenseRestrictionsLockIcon isSmall={false} licenseRestriction={modulePermissionsLicenseRestriction} className="text-dark" />}
                                                    </>
                                                }
                                                checked={this.state.copyModulePermissions}
                                                disabled={isModulePermissionsLocked}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ copyModulePermissions: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="duplGrpAssFieldPerms" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={
                                                    <>
                                                        {Strings.formatString(myStrings.copyFieldPermissions, doesGroupNameAlreadyExist ? myStrings.replace : myStrings.include)}{' '}
                                                        {isFieldPermissionsLocked && <LicenseRestrictionsLockIcon isSmall={false} licenseRestriction={fieldPermissionsLicenseRestriction} className="text-dark"/>}
                                                    </>
                                                }
                                                checked={this.state.copyFieldPermissions}
                                                disabled={isFieldPermissionsLocked}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ copyFieldPermissions: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={() => void this.submit()} disabled={isSubmitDisabled}>
                            {Strings.ok}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}

export default DuplicateGroupWizard;
