import { DefaultButton, PrimaryButton, Stack } from "@fluentui/react";
import React from "react";
import Strings from "../../../../../../strings";
import { BraintreeCheckoutFinisher } from "../../../checkout/BraintreeCheckoutFinisher";
import StoreV2Context from "../../StoreV2Context";
import type { TPaymentConfig } from "./usePaymentConfig";
import type { TSubmitHandler } from "./useSubmitHandler";

const useBraintreePaymentConfig = (isEnabled: boolean, submitHandler: TSubmitHandler): TPaymentConfig => {
    const { customerInfo } = React.useContext(StoreV2Context);
    const [isFilling, setIsFilling] = React.useState<boolean>(false);

    const handleCancelAdding = React.useCallback(() => setIsFilling(false), [setIsFilling]);

    const renderComponent = React.useMemo(() => {
        if (isFilling) {
            return (
                <div>
                    <div className="checkoutV2__bt-contrainer">
                        <BraintreeCheckoutFinisher
                            clientToken={customerInfo.btClientToken}
                            onFinisherInitialized={submitHandler.handleFinisherInitialized}
                            onFinisherUnloaded={submitHandler.handleFinisherUnloaded}
                            onOnPaymentMethodConfirmed={submitHandler.handlePaymentMethodConfirmed}
                        />
                    </div>
                    <Stack horizontal horizontalAlign='end' tokens={{ childrenGap: '8' }}>
                        <PrimaryButton onClick={submitHandler.handleSubmitClick}>
                            {Strings.components.routes.subscriptions.pay}
                        </PrimaryButton>
                        <DefaultButton onClick={handleCancelAdding}>
                            {Strings.cancel}
                        </DefaultButton>
                    </Stack>
                </div>
            );
        }

        return null;
    }, [isFilling, handleCancelAdding, customerInfo.btClientToken, submitHandler.handleFinisherInitialized, submitHandler.handleFinisherUnloaded, submitHandler.handlePaymentMethodConfirmed, submitHandler.handleSubmitClick]);

    return React.useMemo(() => {
        const handlePaySimple = () => {
            if (!isEnabled)
                return;

            setIsFilling(true);
        };

        return {
            availableStripeMethods: null,
            selectedStripeMethod: null,
            onAddNew: null,
            onAddNewAndPay: null,
            onSelectMethodId: null,
            onPayWithStripeMethodId: null,
            isLoading: false,
            renderComponent,
            onPaySimple: handlePaySimple
        };
    }, [isEnabled, setIsFilling, renderComponent]);
};

export default useBraintreePaymentConfig;