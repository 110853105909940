import * as React from 'react';
import Strings from '../../../strings';
import { Button, Alert } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import type { ShoppingConnection } from '../../ShoppingConnection';
import type { IShoppingApiPaymentResult } from '../../../lsapi';
import { SpinnerModal } from '../SpinnerModal';
import { FontIcon, Dialog, DialogFooter } from '@fluentui/react';
import type { IShoppingDatumResponse } from '../../../data/shopping/IShoppingDatumResponse';
import BraintreeDropin from '../paymentGate/BraintreeDropin';
import { SpinnerVariant } from '@eway-crm/gui';


type TBraintreeInvoicePayerProps = {
    shoppingConnection: ShoppingConnection;
    invoiceId: number;
    priceToPay: number;
    currencyCode: string;
    invoiceNumber: string;
    onDone: () => Promise<void>;
    onCancel: () => void;
};

type TBraintreeInvoicePayerState = {
    clientToken: string | null;
    nonce: string | null;
    isNonceSent: boolean;
    gotNonceError: boolean;
    paymentResult: IShoppingApiPaymentResult | null;
};

const myStrings = Strings.components.routes.purchaseHistory;

export class BraintreeInvoicePayer extends React.Component<TBraintreeInvoicePayerProps, TBraintreeInvoicePayerState> {
    private nonceGetterRef: { current: ((getter: (nonce: string | null) => void) => void) | null };

    constructor(props: TBraintreeInvoicePayerProps) {
        super(props);
        this.state = {
            clientToken: null,
            nonce: null,
            isNonceSent: false,
            gotNonceError: false,
            paymentResult: null
        };
        this.nonceGetterRef = {
            current: null
        };
    }

    componentDidMount() {
        (async () => {
            const response = await this.props.shoppingConnection.askShoppingApi<IShoppingDatumResponse<string>>('GetBraintreeCustomerClientToken', { offerStoredMethods: true });
            this.setState({
                clientToken: response.Datum,
                nonce: null,
                isNonceSent: false,
                gotNonceError: false,
                paymentResult: null
            });
        })()
            .catch((err) => console.error('Unable to get braintree customer client token.', err));
    }

    private readonly nonceProcessor = (nonce: string | null) => {
        if (nonce) {
            this.setState({
                clientToken: this.state.clientToken,
                nonce: nonce,
                isNonceSent: true,
                gotNonceError: false,
                paymentResult: null
            },
                () => {
                    this.props.shoppingConnection.callShoppingApi(
                        'InstantlyPayInvoice',
                        {
                            invoiceId: this.props.invoiceId,
                            nonce: this.state.nonce
                        },
                        (result: IShoppingApiPaymentResult) => {
                            // The call was successful, but the payment still might be unsuccessful.
                            this.setState({
                                clientToken: null,
                                nonce: null,
                                isNonceSent: false,
                                gotNonceError: false,
                                paymentResult: result
                            });
                        },
                        (result) => {
                            this.setState({
                                clientToken: this.state.clientToken,
                                nonce: null,
                                isNonceSent: true,
                                gotNonceError: true,
                                paymentResult: null
                            });
                        }
                    );
                }
            );
        } else {
            this.setState({
                clientToken: this.state.clientToken,
                nonce: null,
                isNonceSent: false,
                gotNonceError: true,
                paymentResult: null
            });
        }
    };

    private readonly submit = () => {
        if (this.nonceGetterRef.current) {
            this.nonceGetterRef.current(this.nonceProcessor);
        } else {
            this.nonceProcessor(null);
        }
    };

    private readonly done = () => {
        void this.props.onDone();
    };

    render() {
        if (!!this.state.paymentResult) {
            const iconName = this.state.paymentResult.IsSuccessfulPayment ? 'CheckMark' : 'ErrorBadge';
            const title = this.state.paymentResult.IsSuccessfulPayment ? myStrings.invoiceSuccessfullyPaidTitle : myStrings.paymentErrorTitle;
            return (
                <Dialog modalProps={{ isBlocking: true }} dialogContentProps={{ showCloseButton: false, title: title }} hidden={false} minWidth={'500px'}>
                    <div className="container max-w-none">
                        <div className="row flex-nowrap">
                            <div className="col-auto">
                                <FontIcon iconName={iconName} style={{ fontSize: '4rem', lineHeight: '4rem' }} />
                            </div>
                            <div className="col">
                                <div style={{ overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                                    {(this.state.paymentResult.IsSuccessfulPayment) ?
                                        <>{myStrings.invoiceSuccessfullyPaidMessage}</>
                                        :
                                        <>
                                            {myStrings.paymentError}
                                            {(!!this.state.paymentResult.GateMessage) &&
                                                <> {myStrings.paymentErrorGateMessage} {this.state.paymentResult.GateMessage}</>
                                            }
                                        </>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <DialogFooter>
                        <Button variant="primary" onClick={this.done}>{Strings.ok}</Button>
                    </DialogFooter>
                </Dialog>
            );
        }

        return (
            <>
                {this.state.clientToken && this.state.nonce ?
                    <SpinnerModal variant={SpinnerVariant.linear} />
                    :
                    <>
                        {this.state.clientToken ?
                            <WizardModal show={true} onHide={this.props.onCancel}>
                                <WizardModal.Body>
                                    <h1>{myStrings.payInvoiceNo} <span className="text-nowrap">{this.props.invoiceNumber}</span></h1>
                                    {this.state.gotNonceError &&
                                        <Alert variant="danger">
                                            {Strings.components.routes.billingDetails.wrongMethod}
                                        </Alert>
                                    }
                                    <BraintreeDropin
                                        mode="checkout"
                                        checkoutData={{ amount: this.props.priceToPay, currencyCode: this.props.currencyCode }}
                                        name="PayInvoiceApplet"
                                        clientToken={this.state.clientToken}
                                        onDropinInitialized={(getter) => { this.nonceGetterRef.current = getter; }}
                                    />
                                </WizardModal.Body>
                                <WizardModal.Footer>
                                    <Button variant="primary" onClick={this.submit} disabled={!(!this.state.clientToken) && !(!this.state.nonce)}>
                                        {myStrings.pay} {Strings.formatCurrency(this.props.priceToPay, 2, this.props.currencyCode)}
                                    </Button>
                                    <Button variant="outline-secondary" onClick={this.props.onCancel}>
                                        {Strings.cancel}
                                    </Button>
                                </WizardModal.Footer>
                            </WizardModal>
                            :
                            <SpinnerModal variant={SpinnerVariant.linear} />
                        }
                    </>
                }
            </>
        );
    }
}