import * as React from 'react';
import type { IApiUser } from '@eway-crm/connector';
import { FolderNames } from '@eway-crm/connector';
import { Spinner } from 'react-bootstrap';
import type { ISettingsPanelButtonProps } from './SettingsPanel';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { AccountManagement, AppBuildModeHelper, ExternalLinks } from '@eway-crm/gui';
import APP_BUILD_MODE from '../../../AppBuildMode';

type TUserProfileInfoProps = ISettingsPanelButtonProps & {
    userItem: IApiUser | null;
    outlookClientInstallerUrl: string | null;
    onShowSettings: () => void;
};

const UserProfileInfo: React.FC<TUserProfileInfoProps> = (props) => {
    const context = React.useContext(ConnectionContext);

    const logout = () => {
        context.connection.logout('oauthLogout');
    };

    const switchAccount = () => {
        context.connection.logout('switchAccount');
    };

    const openCurrentUserItem = () => {
        const url = ExternalLinks.wa.getUrl(APP_BUILD_MODE, context.connection.getApiConnection().wsUrl, ExternalLinks.wa.getPathToItem(FolderNames.users, props.userItem!.ItemGUID));
        window.open(url, '_blank');
    };


    if (!props.userItem || !props.outlookClientInstallerUrl) {
        return <Spinner animation="grow" className={"my-auto"} />;
    }

    return (
        <AccountManagement
            apiConnection={context.connection.getApiConnection()}
            customerName={context.apiData.license.CustomerName}
            currentUser={props.userItem}
            otlDownloadLink={props.outlookClientInstallerUrl}
            onOpenCurrentUserItem={openCurrentUserItem}
            wsUrl={context.connection.getApiConnection().wsUrl}
            version={import.meta.env.VITE_VERSION}
            onSwitchAccount={!AppBuildModeHelper.isPremise(APP_BUILD_MODE) ? switchAccount : undefined}
            onLogout={logout}
            isCurrentUserAdmin
            onShowSettingsDialog={props.onShowSettings}
            className="nav-link m-0 p-0 btn btn-link h-100"
            onClick={props.hidePanel}
            currentApp="admin"
            buildMode={APP_BUILD_MODE}
            avatarImageAuthMode="header"
        />
    );
};

export default UserProfileInfo;