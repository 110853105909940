import type { TAppBuildMode } from "@eway-crm/gui";

const APP_BUILD_MODE: TAppBuildMode = (() => {
    if (import.meta.env.VITE_CLOUD_HOSTED === 'true') {
        if (import.meta.env.MODE === 'production') {
            return 'prodCloud';
        } else {
            return 'devCloud';
        }
    } else {
        if (import.meta.env.MODE === 'production') {
            return 'prodPremise';
        } else {
            return 'devPremise';
        }
    }
})();

export default APP_BUILD_MODE;