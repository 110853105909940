import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiGroup } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import StringHelper from '../../../helpers/StringHelper';
import type { TMultiselectDropdownOption } from '../../shared/MultiselectDropdown';
import MultiselectDropdown from '../../shared/MultiselectDropdown';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type DuplicateModulePermissionsWizardProps = {
    onDismiss: () => void;
    allGroups: IApiGroup[];
    currentGroup: IApiGroup;
    onDone: () => void;
};

type DuplicateModulePermissionsWizardState = {
    isFormValidated: boolean;
    availableGroups: IApiGroup[];
    pickedGroups: TMultiselectDropdownOption[];
    includeFieldPermissions: boolean;
    isSavingInProgress: boolean;
};

const myStrings = Strings.components.routes.modulePermissions.duplicate;
class DuplicateModulePermissionsWizard extends React.Component<DuplicateModulePermissionsWizardProps, DuplicateModulePermissionsWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: DuplicateModulePermissionsWizardProps) {
        super(props);
        this.state = {
            isFormValidated: false,
            pickedGroups: [],
            availableGroups: props.allGroups.filter((ag) => ag.ItemGUID !== props.currentGroup.ItemGUID).sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName)),
            includeFieldPermissions: false,
            isSavingInProgress: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = () => {
        if (this.state.pickedGroups.length === 0) {
            this.setState({ isFormValidated: true });
        } else {
            this.setState({ isFormValidated: true, isSavingInProgress: true }, () => {
                const targetGroupGuids = this.state.pickedGroups.map((pg) => pg.value);
                this.context.connection.callApi(
                    'DuplicateModulePermissions',
                    {
                        sourceGroupGuid: this.props.currentGroup.ItemGUID,
                        targetGroupGuids,
                    },
                    () => {
                        if (this.state.includeFieldPermissions) {
                            this.context.connection.callApi('DuplicateColumnPermissions', { sourceGroupGuid: this.props.currentGroup.ItemGUID, targetGroupGuids }, () => {
                                this.props.onDone();
                            });
                        } else {
                            this.props.onDone();
                        }
                    }
                );
            });
        }
    };

    render() {
        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.title}</h1>
                        <div className="mb-4">
                            <div className="mt-2 text-break">{Strings.formatString(myStrings.description, this.props.currentGroup.GroupName)}</div>
                        </div>
                        <div>
                            <Form className="form-application">
                                <Form.Row>
                                    <Form.Group as={Col}>
                                        <Form.Label>{myStrings.targetGroup}</Form.Label>
                                        <MultiselectDropdown
                                            noOptionPickedTitle={myStrings.noGroup}
                                            onValueChange={(newPickedOptions) => {
                                                this.setState({ pickedGroups: newPickedOptions });
                                            }}
                                            options={this.props.allGroups
                                                .filter((ag) => ag.ItemGUID !== this.props.currentGroup.ItemGUID && !this.state.pickedGroups.map((pg) => pg.value).includes(ag.ItemGUID))
                                                .map((ag) => ({ title: ag.GroupName, value: ag.ItemGUID, disabled: ag.ContainsAnyUneditablePermission || ag.DisallowControlModulePermissions }))
                                                .sort((a, b) => StringHelper.localeCompare(a.title, b.title))}
                                            pickedOptions={this.state.pickedGroups}
                                            isInvalid={(this.state.pickedGroups.length === 0 && this.state.isFormValidated)}
                                        />
                                        {this.state.pickedGroups.length === 0 && this.state.isFormValidated && <div className="invalid-feedback d-block">{Strings.thisFieldIsMandatory}</div>}
                                    </Form.Group>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="includeFieldPermissions" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={myStrings.includeFieldPermissions}
                                                checked={this.state.includeFieldPermissions}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ includeFieldPermissions: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={this.submit}>
                            {myStrings.okButtonTitle}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}

export default DuplicateModulePermissionsWizard;
