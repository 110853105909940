import { Checkbox, mergeStyles, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import type { IRow } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import Strings from '../../../../../strings';
import HtmlComponent from '../../../../shared/HtmlComponent';
import { Subscriptions } from '../../../Subscriptions';
import StoreHelper from '../../StoreHelper';
import UnitPriceSpan from '../../UnitPriceSpan';
import usePackage from '../hooks/usePackage';
import StoreV2Context from '../StoreV2Context';

type TCheckboxRowProps = {
    row: IRow;
};

const CheckboxRow: React.FC<TCheckboxRowProps> = ({ row }) => {
    const artno = row.Artnos?.[0];
    if (!artno)
        return null;

    return (
        <div>
            <CheckboxPackage artno={artno} />
        </div>
    );
};

type TCheckboxPackageProps = {
    artno: string;
};

const css = mergeStyleSets({
    checkbox: {
        selector: {
            '& .ms-Checkbox-checkbox': {
                alignSelf: 'start'
            }
        }
    }
});

const CheckboxPackage: React.FC<TCheckboxPackageProps> = ({ artno }) => {
    const { packageData, packageEditingState, isDisabled } = usePackage(artno);
    const { customerInfo, setPackageEditingState, selectedPeriodType, isPackageInCart } = React.useContext(StoreV2Context);

    const packageQuantity = packageEditingState.currentEditQuantity || 0;

    const handleChange = React.useCallback((e?: React.FormEvent, checked?: boolean) => {
        if (isDisabled)
            return;

        if (checked) {
            setPackageEditingState(artno, { currentEditQuantity: 1 });
        } else {
            setPackageEditingState(artno, { currentEditQuantity: 0 });
        }
    }, [artno, setPackageEditingState, isDisabled]);

    if (!packageData)
        return null;

    const { showUnitPriceAsUnit, shownPrice } = StoreHelper.getUnitPriceAttributes(
        packageData,
        customerInfo.currency,
        packageQuantity,
        selectedPeriodType,
        isPackageInCart
    );

    return (
        <Checkbox checked={!!packageQuantity} onChange={handleChange} className={mergeStyles('package-row p-3', css.checkbox, isDisabled && "package-row__disabled" )} disabled={isDisabled} onRenderLabel={() => (
            <div className="ml-2">
                <h5 className="mb-2">{Strings.pickTranslation(packageData.Title)}</h5>
                {(typeof shownPrice === 'number') &&
                    <div className="mb-2">
                        <UnitPriceSpan
                            formattedPrice={(packageData.ShowPlusInPrice ? '+' : '') + Strings.formatCurrency(shownPrice, Subscriptions.priceDecPlaces, customerInfo.currency)!}
                            packageData={packageData}
                            showUnit={showUnitPriceAsUnit}
                        />
                    </div>
                }
                {(!!packageData.Description) &&
                    <div>
                        <HtmlComponent htmlString={Strings.pickTranslation(packageData.Description)} />
                    </div>
                }
            </div>
        )} />
    );
};

export default CheckboxRow;