import type { ICustomerInfoModel } from "../../../data/shopping/ICustomerInfoModel";

export type TCustomerInfo = {
    emailAddress: string;
    vatNumber: string;
    taxNumber: string;
    identificationNumber: string;
    businessName: string;
    firstName: string;
    lastName: string;
    phone: string;
    street: string;
    city: string;
    state: string;
    postalCode: string;
    country: string;
    currency: string;
    willVatBeCharged: boolean;
    willVatBeShownInEula: boolean;
    btClientToken: string;
    chargeAdditionalServicesAutomatically: boolean;
};

export const convertToCustomerInfo = (customerInfo: ICustomerInfoModel): TCustomerInfo => ({
    emailAddress: customerInfo.EmailAddress || '',
    vatNumber: customerInfo.VatNumber || '',
    taxNumber: customerInfo.TaxNumber || '',
    identificationNumber: customerInfo.IdentificationNumber || '',
    businessName: customerInfo.BusinessName || '',
    firstName: customerInfo.FirstName || '',
    lastName: customerInfo.LastName || '',
    phone: customerInfo.Phone || '',
    street: customerInfo.AddressLine1 || '',
    city: customerInfo.City || '',
    state: customerInfo.State || '',
    postalCode: customerInfo.PostalCode || '',
    country: customerInfo.Country || '',
    currency: customerInfo.Currency || '',
    willVatBeCharged: customerInfo.WillVatBeCharged || false,
    willVatBeShownInEula: customerInfo.WillVatBeShownInEula || false,
    btClientToken: customerInfo.BtClientToken || '',
    chargeAdditionalServicesAutomatically: customerInfo.ChargeAdditionalServicesAutomatically || false
});