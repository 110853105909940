import type { Location } from 'history';
import StringHelper from './helpers/StringHelper';

class RouteConfig {
    static readonly root = {
        path: '/'
    };

    static readonly users = {
        basePath: '/users-permissions/',
        path: '/users-permissions/users',
        new: {
            path: '/users-permissions/users/new',
            pathWithCount: '/users-permissions/users/new/:count'
        },
        licences: {
            path: '/users-permissions/users/licences'
        },
        edit: {
            path: '/users-permissions/users/edit',
            pathWithGuid: '/users-permissions/users/edit/:guid'
        },
        effectivePermissions: {
            path: '/users-permissions/users/effective-permissions',
            pathWithGuid: '/users-permissions/users/effective-permissions/:guid'
        },
        actions: {
            afterBuy: {
                getPath: (addUsersFirst: boolean, freeSlotsCount?: number) => {
                    if (addUsersFirst)
                        return `${RouteConfig.users.new.path}/${freeSlotsCount}?action=afterBuy&addUsersFirst=true`;
                    else
                        return `${RouteConfig.users.licences.path}?action=afterBuy&addUsersFirst=false`;
                },
                isOn: (query: URLSearchParams) => {
                    return query.get('action') === 'afterBuy';
                },
                addUsersFirst: (query: URLSearchParams) => {
                    return query.get('addUsersFirst') === 'true';
                }
            }
        }
    };

    static readonly groups = {
        path: '/users-permissions/groups',
        new: {
            path: '/users-permissions/groups/new'
        },
        edit: {
            path: '/users-permissions/groups/edit',
            pathWithGuid: '/users-permissions/groups/edit/:guid',
            pathWithTabKey: '/users-permissions/groups/edit/:guid/:key',
            tabKeys: {
                general: 'general',
                users: 'users',
                modulePermissions: 'mp',
                fieldPermissions: 'fp',
                additionalSettings: 'additional'
            }
        }
    };

    static readonly modulePermissions = {
        path: '/users-permissions/module-permissions',
        pathWithGuid: '/users-permissions/module-permissions/:groupGuid',
        affectedUsers: {
            path: '/users-permissions/module-permissions/:groupGuid/affected',
            slug: 'affected'
        },
        duplicate: {
            path: '/users-permissions/module-permissions/:groupGuid/duplicate',
            slug: 'duplicate'
        }
    };

    static readonly customizations = {
        path: '/customizations',
        features: {
            path: '/customizations/features',
            pathWithFeature: '/customizations/features/:feature',
            featureParamName: 'feature',
            categoryParamName: 'cat'
        },
        fields: {
            path: '/customizations/fields',
            pathWithFolder: '/customizations/fields/:folder',
            getPathWithFolder: (folderName: string) => `${RouteConfig.customizations.fields.path}/${folderName.toLowerCase()}`,
            new: {
                path: '/customizations/fields/:folder/new',
                slug: 'new'
            },
            edit: {
                path: '/customizations/fields/:folder/edit',
                pathWithGuid: '/customizations/fields/:folder/edit/:guid',
                slug: 'edit'
            },
            duplicate: {
                path: '/customizations/fields/:folder/duplicate',
                pathWithGuid: '/customizations/fields/:folder/duplicate/:guid',
                slug: 'duplicate'
            }
        },
        workflow: {
            path: '/customizations/workflow',
            pathWithFolder: '/customizations/workflow/:folder',
            pathWithItemTypeGuid: '/customizations/workflow/:folder/:itemTypeGuid',
            getPathWithFolder: (folderName: string) => `${RouteConfig.customizations.workflow.path}/${folderName.toLowerCase()}`,
            getPathWithFolderAndGuid: (folderName: string, itemGuid: string) => `${RouteConfig.customizations.workflow.path}/${folderName.toLowerCase()}/${itemGuid.toLowerCase()}`,
            newParamName: 'create'
        }
    };

    static readonly billing = {
        path: '/billing',
        subscriptions: {
            path: '/billing/subscriptions',
            actions: {
                urlParamName: 'action',
                afterBuy: {
                    getPath: (isAnythingNew: boolean) => '/billing/subscriptions?action=' + (isAnythingNew ? 'afterBuy' : 'afterBuyDowngrade'),
                    isOn: (location: Location): false | { isAnythingNew: boolean } => {
                        const query = new URLSearchParams(location.search);
                        if (query.get('action') === 'afterBuy')
                            return { isAnythingNew: true };
                        else if (query.get('action') === 'afterBuyDowngrade')
                            return { isAnythingNew: false };
                        return false;
                    }
                }
            },
            getArtnoToBeAddedToCart: (location: Location): string | null => {
                const query = new URLSearchParams(location.search);
                return query.get('addToCartArtno');
            },
            getKey: (location: Location): string => {
                const query = new URLSearchParams(location.search);
                return `subscriptions-${(query.get('action') || 'noaction')}-${query.get('addToCartArtno') || 'noaddingtocart'}`;
            }
        },
        bills: {
            path: '/billing/bills'
        },
        paymentMethods: {
            path: '/billing/paymentmethods'
        },
        notifications: {
            path: '/billing/notifications'
        }
    };

    static readonly updates = {
        path: '/updates'
    };

    static isNonpaddingRoute(path: string): boolean {
        if (
            path === RouteConfig.root.path ||
            path.startsWith(RouteConfig.modulePermissions.path) ||
            path.startsWith(RouteConfig.customizations.path)
        ) {
            return true;
        } else return false;
    }

    static callsForCollapsedSideMenu(location: Location): boolean {
        const query = new URLSearchParams(location.search);
        return StringHelper.compareIgnoringCase(query.get('collapseSideMenu'), 'true') === 0;
    }
}

export default RouteConfig;