import generalIcon from '../../img/features/general.svg';
import securityIcon from '../../img/features/security.svg';
import contactsAndCompaniesIcon from '../../img/folders/Contacts.svg';
import salesIcon from '../../img/folders/Leads.svg';
import projectsIcon from '../../img/folders/Projects.svg';
import marketingIcon from '../../img/folders/Marketing.svg';
import Strings from '../../strings';

export type TFeatureType = keyof typeof FeatureTypes;

export default class FeatureTypes {
    static General = 'General';
    static ContactsAndCompanies = 'ContactsAndCompanies';
    static Security = 'Security';
    static Sales = 'Sales';
    static Projects = 'Projects';
    static MarketingCampaigns = 'MarketingCampaigns';

    static categoryInSeparateFeatureMap = {
        [FeatureTypes.Security]: ['Security', 'GDPR'],
    };

    static separateCategoryFeaturesMap = {
        Security: FeatureTypes.Security,
        GDPR: FeatureTypes.Security
    };
}

export const featureTypes = [    
    {
        type: FeatureTypes.ContactsAndCompanies,
        name: Strings.features.ContactsAndCompanies.name,
        description: Strings.features.ContactsAndCompanies.description,
        icon: contactsAndCompaniesIcon,
        showPermissions: true,
    },
    {
        type: FeatureTypes.Sales,
        name: Strings.features.Sales.name,
        description: Strings.features.Sales.description,
        icon: salesIcon,
        showPermissions: true,
        canBeToggled: true,
    },
    {
        type: FeatureTypes.Projects,
        name: Strings.features.Projects.name,
        description: Strings.features.Projects.description,
        icon: projectsIcon,
        showPermissions: true,
        canBeToggled: true,
    },
    {
        type: FeatureTypes.MarketingCampaigns,
        name: Strings.features.MarketingCampaigns.name,
        description: Strings.features.MarketingCampaigns.description,
        icon: marketingIcon,
        showPermissions: true,
        canBeToggled: true,

    },
    {
        type: FeatureTypes.Security,
        name: Strings.features.Security.name,
        customTitle: Strings.features.Security.customTitle,
        description: Strings.features.Security.description,
        icon: securityIcon,
        showPermissions: false,
    },
    {
        type: FeatureTypes.General,
        name: Strings.features.General.name,
        customTitle: Strings.features.General.customTitle,
        description: Strings.features.General.description,
        icon: generalIcon,
        showPermissions: false,
    },
];
