import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'core-js/features/array/flat';
import 'url-search-params-polyfill';
import 'element-closest-polyfill';
import './types/polyfill.d.ts';
import { createRoot } from 'react-dom/client';
import './index.scss';
import './strings';
import MainComponent from './components/MainComponent';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { ApplyFluentV9ThemeToBody, Apps, NavigatorHelper, UnsupportedBrowser } from '@eway-crm/gui';
import { HashRouter as Router } from 'react-router-dom';
import ConnectionProvider from './providers/ConnectionProvider';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';
import LocalStorageHelper from './helpers/LocalStorageHelper.js';
import { StrictMode } from 'react';

initializeIcons();
LocalStorageHelper.setLocalStorageAppPath(Apps.admin);

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    NavigatorHelper.isIE() ? (
        <UnsupportedBrowser />
    ) : (
        <StrictMode>
            <Router>
                <ConnectionProvider>
                    <FluentProvider theme={webLightTheme}>
                        <ApplyFluentV9ThemeToBody />
                        <MainComponent />
                    </FluentProvider>
                </ConnectionProvider>
            </Router>
        </StrictMode>
    )
);
