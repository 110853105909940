import StringHelper from "./StringHelper";

export class DateHelper {

    /**
     * Creates Date instance carying today date and zero time.
     */
    static getToday(): Date {
        const now = new Date();
        return DateHelper.getDatePart(now);
    }

    /**
     * Gets only day info from date.
     * 
     * @param date The input date.
     */
    static getDatePart(date: Date): Date {
        return new Date(date.getFullYear(), date.getMonth(), date.getDate());
    }

    /**
     * Parses universal datetime string into Date object.
     * If the input is null or empty, returns null.
     * 
     * @param input The input datetime stirng.
     */
    static parseUniversalDateOrNull(input: string | null): Date | null {
        if (!input)
            return null;
        return this.parseUniversalDate(input);
    }

    /**
     * Parses universal datetime string into Date object.
     * 
     * @param input The input datetime stirng.
     */
    static parseUniversalDate(input: string): Date {
        let date = new Date(input);
        if (isNaN(date.getDate())) {
            date = new Date(input.replace(' ', 'T'));
        }
        if (isNaN(date.getDate())) {
            date = new Date(input.replace(' ', 'T').replace('Z', ''));
        }
        if (isNaN(date.getDate())) {
            throw new Error(`Unable to parse date '${input}'`);
        }
        return date;
    }

    /**
     * Adds time to a date. Modelled after MySQL DATE_ADD function.
     * Example: dateAdd(new Date(), 'minute', 30)  //returns 30 minutes from now.
     * https://stackoverflow.com/a/1214753/18511
     * 
     * @param date  Date to start with
     * @param interval  One of: year, quarter, month, week, day, hour, minute, second
     * @param units  Number of units of the given interval to add.
     */
    static add(date: Date, interval: 'year' | 'quarter' | 'month' | 'week' | 'day' | 'hour' | 'minute' | 'second', units: number): Date {
        const ret = new Date(date); //don't change original date
        const checkRollover = function () { if (ret.getDate() !== date.getDate()) ret.setDate(0); };
        switch (String(interval).toLowerCase()) {
            case 'year': ret.setFullYear(ret.getFullYear() + units); checkRollover(); break;
            case 'quarter': ret.setMonth(ret.getMonth() + 3 * units); checkRollover(); break;
            case 'month': ret.setMonth(ret.getMonth() + units); checkRollover(); break;
            case 'week': ret.setDate(ret.getDate() + 7 * units); break;
            case 'day': ret.setDate(ret.getDate() + units); break;
            case 'hour': ret.setTime(ret.getTime() + units * 3600000); break;
            case 'minute': ret.setTime(ret.getTime() + units * 60000); break;
            case 'second': ret.setTime(ret.getTime() + units * 1000); break;
            default: throw new Error(`Wrong interval '${interval}' given.`);
        }
        return ret;
    }

    /**
     * Compares two universal date strings or nulls.
     * @param a First to compare.
     * @param b Second to compare.
     */
    static compareUniversals(a: string | null, b: string | null): number {
        return StringHelper.compare(a || '0001', b || '0001');
    }
}