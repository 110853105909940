import type { ContextType } from 'react';
import React from 'react';
import RouteConfig from '../../../../RouteConfig';
import type { IApiColumn, IApiEnumType, IApiEnumValue, IApiFeature, IApiGlobalSetting, IApiGroup, TFolderName } from '@eway-crm/connector';
import type { IApiUser } from '@eway-crm/connector';
import FolderNames from '../../../../data/constants/FolderNames';
import StringHelper from '../../../../helpers/StringHelper';
import LocalizationHelper from '../../../../helpers/LocalizationHelper';
import Strings from '../../../../strings';
import type { Connection } from '../../../layout/connection/Connection';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { IApiDatumResponse } from '@eway-crm/connector';
import { RemoteItemStore } from '../../../../RemoteItemStore';
import { SearchAction, getSearchPages } from './searchpages';
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper';
import UserAvatar from '../../../shared/users/UserAvatar';
import FeatureTypes, { featureTypes } from '../../../../data/constants/FeatureTypes';
import type { TFeature } from '../../Features';
import { EnumTypes } from '../../../../data/constants/EnumTypes';
import ObjectHelper from '../../../../helpers/ObjectHelper';
import FieldsGrid from '../../fields/FieldsGrid';
import type { LocalizedStringsMethods } from 'react-localization';
import Fields from '../../Fields';
import GlobalSettingTypes from '../../../../data/constants/GlobalSettingTypes';
import featuresIcon from '../../../../img/search/FeaturesIcon.svg';
import workflowIcon from '../../../../img/search/WorkflowIcon.svg';
import GroupAvatar from '../../../shared/groups/GroupAvatar';
import axios from 'axios';
import ExternalLinks from '../../../../data/constants/ExternalLinks';
import type { TLicenseRestriction } from '../../../../helpers/LicenseRestrictionsHelper';
import type LicenseRestrictionsHelper from '../../../../helpers/LicenseRestrictionsHelper';
import type { ConnectionContext } from '../../../../providers/ConnectionProvider';

export type TPageBase = {
    group: string;
    action?: SearchAction;
    nameGetter: (strings: Omit<typeof Strings, keyof LocalizedStringsMethods>) => string;
    description?: string;
    link: string;
    isExternalLink?: boolean;
    licenseRestriction?: TLicenseRestriction | null;
    iconName?: string;
    addNewIcon?: boolean;
    img?: string;
    getAvatar?: () => React.ReactNode;
    keyWords?: (string | null)[];
    synonyms?: string[];
};

export type TPage = TPageBase & {
    name: string;
    enName: string;
    searchHelper: string;
};

type TExternalPageBase = {
    group: string;
    action?: SearchAction;
    name: string;
    description?: string;
    link: string;
    iconName?: string;
    addNewIcon?: boolean;
    img?: string;
    synonyms?: string;
};

type TPartialWcfUser = Pick<IApiUser, 'Username' | 'FileAs' | 'FirstName' | 'LastName' | 'Email1Address' | 'Email2Address' | 'ProfilePictureWidth' | 'ItemGUID' | 'ItemVersion'>;
type TPartialWcfGroup = Pick<IApiGroup, 'GroupName' | 'Description' | 'ItemGUID'>;
type TPartialGsWithCategoryTranslations = Pick<IApiGlobalSetting, 'Name' | 'NameCs' | 'NameEn' | 'Type'> & { categoryTranslations: Pick<IApiEnumValue, 'Cs' | 'En' | 'FileAs'> };
type TFeatureTypesWithGs = { [featureType: string]: TPartialGsWithCategoryTranslations[] };
type TPartialApiColumn = Pick<IApiColumn, 'NameCs' | 'NameEn' | 'AltNameCs' | 'AltNameEn' | 'ColumnName' | 'FolderName' | 'EnumTypeItem'> & {
    AdditionalFieldItem?: { ItemGUID: string; Comment: string } | null;
};
type TPartialApiEnumType = Pick<IApiEnumType, 'EnumName'> & { EnumValuesInEnumType: Pick<IApiEnumValue, 'ItemGUID' | 'En' | 'Cs'>[] | null };
type TAllSearchResultsLoadedData = {
    usersData: TPartialWcfUser[];
    groupsData: TPartialWcfGroup[];
    featureTypesWithGs: TFeatureTypesWithGs;
    fieldsFolders: string[];
    columns: TPartialApiColumn[];
    workflowFolders: string[];
    workflowTypes: TPartialApiEnumType[];
    externalLinksEn: TExternalPageBase[];
    externalLinksCs: TExternalPageBase[];
};
type TSearchResultsCacheValue = { results: TAllSearchResultsLoadedData; ewayVersion: string; lastItemChangeId: number };
type TSearchResultsShouldReloadCacheValue = { reloadOnNextSearch: boolean };

const myStrings = Strings.components;

export default class SearchResultsObtainer {
    private cache: TSearchResultsCacheValue | null;
    private lastItemChangeId: number | null;
    private readonly connection: Connection;
    private readonly licenseRestrictionsHelper: LicenseRestrictionsHelper;
    private readonly context: ContextType<typeof ConnectionContext>;
    private usersData: TPartialWcfUser[];
    private groupsData: TPartialWcfGroup[];
    private featureTypesWithGs: TFeatureTypesWithGs;
    private fieldsFolders: string[];
    private columns: TPartialApiColumn[];
    private workflowFolders: string[];
    private workflowTypes: TPartialApiEnumType[];
    private externalLinksEn: TExternalPageBase[];
    private externalLinksCs: TExternalPageBase[];

    constructor(context: ContextType<typeof ConnectionContext>) {
        this.connection = context.connection;
        this.licenseRestrictionsHelper = context.licenseRestrictionsHelper;
        this.context = context;
        this.cache = null;
        this.lastItemChangeId = null;
        this.usersData = [];
        this.groupsData = [];
        this.featureTypesWithGs = {};
        this.fieldsFolders = [];
        this.columns = [];
        this.workflowFolders = [];
        this.workflowTypes = [];
        this.externalLinksEn = [];
        this.externalLinksCs = [];
    }

    public readonly loadAllSearchData = async () => {
        await this.getLastItemChangeId();

        const shouldReloadCache = SearchResultsObtainer.getShouldReloadOnNextSearch();
        if (shouldReloadCache) {
            await this.reloadAllData();
            return;
        }

        this.cache = LocalStorageHelper.getItem<TSearchResultsCacheValue>(LocalStorageHelper.names.searchResultsCacheKey);
        const isCacheCurrent = this.cache?.ewayVersion === this.context.version.wsVersion;

        if (this.cache && isCacheCurrent && this.lastItemChangeId) {
            const haveItemsChanged = await this.checkChangesInData(this.cache.lastItemChangeId, this.lastItemChangeId);
            if (haveItemsChanged) {
                await this.reloadAllData();
            } else {
                LocalStorageHelper.setItem<TSearchResultsCacheValue>(LocalStorageHelper.names.searchResultsCacheKey, { ...this.cache, lastItemChangeId: this.lastItemChangeId });
                this.usersData = this.cache.results.usersData;
                this.groupsData = this.cache.results.groupsData;
                this.featureTypesWithGs = this.cache.results.featureTypesWithGs;
                this.fieldsFolders = this.cache.results.fieldsFolders;
                this.columns = this.cache.results.columns;
                this.workflowFolders = this.cache.results.workflowFolders;
                this.workflowTypes = this.cache.results.workflowTypes;
                this.externalLinksCs = this.cache.results.externalLinksCs;
                this.externalLinksEn = this.cache.results.externalLinksEn;

                if ((Strings.getLanguage() === 'cs' && (this.cache.results.externalLinksCs?.length ?? 0) === 0) || (Strings.getLanguage() !== 'cs' && (this.cache.results.externalLinksEn?.length ?? 0) === 0)) {
                    await this.loadExternalLinks();
                    this.setResultsToCache();
                }
            }
        } else {
            await this.reloadAllData();
        }
    };

    private readonly getLastItemChangeId = async () => {
        const lastItemChangeIdRes = await this.connection.askApi<IApiDatumResponse<number>>('GetLastItemChangeId', {});
        this.lastItemChangeId = lastItemChangeIdRes.Datum;
        return this.lastItemChangeId;
    };

    private readonly checkChangesInData = async (baseChangeId: number, targetChangeId: number) => {
        if (targetChangeId < baseChangeId) {
            return true;
        }

        return await this.connection
            .askApi<IApiDatumResponse<boolean>>('HaveItemsChanged', {
                baseChangeId,
                targetChangeId,
                folderNames: [FolderNames.users, FolderNames.groups, FolderNames.additionalFields, FolderNames.enumTypes],
            })
            .then((haveChanged) => {
                return haveChanged.Datum;
            });
    };

    static readonly setShouldReloadOnNextSearch = (reloadOnNextSearch: boolean) => {
        LocalStorageHelper.setItem<TSearchResultsShouldReloadCacheValue>(LocalStorageHelper.names.searchResultsShouldReloadCacheKey, { reloadOnNextSearch });
    };

    static readonly getShouldReloadOnNextSearch = () => {
        const shouldReloadCache = LocalStorageHelper.getItem<TSearchResultsShouldReloadCacheValue>(LocalStorageHelper.names.searchResultsShouldReloadCacheKey);
        return !!shouldReloadCache?.reloadOnNextSearch;
    };

    private readonly setResultsToCache = () => {
        LocalStorageHelper.setItem<TSearchResultsCacheValue>(LocalStorageHelper.names.searchResultsCacheKey, {
            ewayVersion: this.context.version.wsVersion,
            lastItemChangeId: this.lastItemChangeId!,
            results: this.getAllLoadedData(),
        });
    };

    public readonly reloadAllData = async () => {
        await Promise.all([
            this.loadFields(),
            this.loadWorkflows(),
            this.loadUsers(),
            this.loadGroups(),
            this.loadFeatures(),
            this.loadExternalLinks(),
        ]);
        this.setResultsToCache();
        SearchResultsObtainer.setShouldReloadOnNextSearch(false);
        return this.getAllLoadedData();
    };

    public readonly getAllLoadedData = (): TAllSearchResultsLoadedData => {
        return {
            usersData: this.usersData,
            groupsData: this.groupsData,
            featureTypesWithGs: this.featureTypesWithGs,
            fieldsFolders: this.fieldsFolders,
            columns: this.columns,
            workflowFolders: this.workflowFolders,
            workflowTypes: this.workflowTypes,
            externalLinksCs: this.externalLinksCs,
            externalLinksEn: this.externalLinksEn,
        };
    };

    private readonly loadUsers = async () => {
        const usersRes = await this.connection.askApi<IApiDataResponse<IApiUser>>('SearchUsers', {
            transmitObject: {
                IsActive: true,
                IsSystem: false,
            },
        });

        this.usersData = usersRes.Data.map((user) => ({
            ItemGUID: user.ItemGUID,
            Username: user.Username,
            FileAs: user.FileAs,
            FirstName: user.FirstName,
            LastName: user.LastName,
            Email1Address: user.Email1Address,
            Email2Address: user.Email2Address,
            ProfilePictureWidth: user.ProfilePictureWidth,
            ItemVersion: user.ItemVersion,
        }));
        return this.usersData;
    };

    private readonly loadGroups = async () => {
        const groupsRes = await this.connection.askApi<IApiDataResponse<IApiGroup>>('SearchGroups', { transmitObject: { IsCategory: false, IsOutlookCategory: false } });
        this.groupsData = groupsRes.Data.map((group) => ({ ItemGUID: group.ItemGUID, GroupName: group.GroupName, Description: group.Description }));
        return this.groupsData;
    };

    private readonly loadFeatures = async () => {
        const { askApi } = this.connection;
        const remoteItemStore = new RemoteItemStore(this.connection);

        const featuresResult = await askApi<IApiDataResponse<IApiFeature>>('GetFeatures', {});
        const globalSettingsResult = await askApi<IApiDataResponse<IApiGlobalSetting>>('GetGlobalSettings', {});
        const enumValuesResult = await remoteItemStore.askForEnumValues(EnumTypes.globalSettingsCategory);

        const features = featureTypes.map((f) => {
            const item = featuresResult.Data.find((item) => item.FileAs === f.type);
            return {
                ...f,
                ItemGuid: item?.ItemGUID,
                associatedFolderNames: item?.AssociatedFolderNames ?? [],
            } as TFeature;
        });

        this.featureTypesWithGs = this.getFeatureTypesWithGsMap(features, globalSettingsResult.Data, enumValuesResult.Data);
        return this.featureTypesWithGs;
    };

    private readonly loadExternalLinks = async () => {
        try {
            if (Strings.getLanguage() === 'cs') {
                const { data } = await axios.get<{ data: TExternalPageBase[] }>(ExternalLinks.searchExternalLinksCs);
                this.externalLinksCs = data.data;

                return this.externalLinksCs;
            } else {
                const { data } = await axios.get<{ data: TExternalPageBase[] }>(ExternalLinks.searchExternalLinksEn);
                this.externalLinksEn = data.data;

                return this.externalLinksEn;
            }
        } catch (e) {
            console.error(`There was an error while loading external links for search`, e);
        }
    };

    private readonly getFeatureTypesWithGsMap = (features: TFeature[], globalSettings: IApiGlobalSetting[], globalSettingCategoryNames: IApiEnumValue[]) => {
        const featureTypeGuidMap: { [guid: string]: string } = {};
        features.forEach((feature) => {
            if (feature.ItemGuid) {
                featureTypeGuidMap[feature.ItemGuid] = feature.type;
            }
        });

        const categoryGuidMap: { [guid: string]: IApiEnumValue } = {};
        globalSettingCategoryNames.forEach((cat) => {
            categoryGuidMap[cat.ItemGUID] = cat;
        });

        const featureTypesWithGsMap: TFeatureTypesWithGs = {};
        const separateCategories = Object.keys(FeatureTypes.separateCategoryFeaturesMap);

        globalSettings.forEach((gs) => {
            if (gs.IsLegacy || !gs.Visible) {
                return;
            }

            const categoryItem = categoryGuidMap[gs.Category];
            const gsWithCategoryItem: TPartialGsWithCategoryTranslations = {
                Name: gs.Name,
                NameCs: gs.NameCs,
                NameEn: gs.NameEn,
                Type: gs.Type,
                categoryTranslations: {
                    Cs: categoryItem.Cs,
                    En: categoryItem.En,
                    FileAs: categoryItem.FileAs,
                },
            };

            if (separateCategories.includes(categoryItem.FileAs as string)) {
                ObjectHelper.addToArrayInObjectKey(
                    featureTypesWithGsMap,
                    FeatureTypes.separateCategoryFeaturesMap[categoryItem.FileAs as keyof typeof FeatureTypes.separateCategoryFeaturesMap],
                    gsWithCategoryItem
                );
            } else if (gs.FeatureGuid) {
                const featureType = featureTypeGuidMap[gs.FeatureGuid];
                if (featureType) {
                    ObjectHelper.addToArrayInObjectKey(featureTypesWithGsMap, featureType, gsWithCategoryItem);
                }
            } else {
                // Features without category goes to General section
                ObjectHelper.addToArrayInObjectKey(featureTypesWithGsMap, FeatureTypes.General, gsWithCategoryItem);
            }
        });

        return featureTypesWithGsMap;
    };

    private readonly loadFields = async () => {
        const { askApi } = this.connection;
        const { objectTypes } = this.context.apiData;
        this.fieldsFolders = RemoteItemStore.getDisplayableFieldFolders(objectTypes, this.licenseRestrictionsHelper);

        const colsResult = await askApi<IApiDataResponse<IApiColumn>>('GetColumns', { includeAdditionalFields: true });
        this.columns = colsResult.Data.filter((col) => FieldsGrid.getIsColumnEditable(col) && this.fieldsFolders.includes(col.FolderName)).map((col) => ({
            ColumnName: col.ColumnName,
            FolderName: col.FolderName,
            NameCs: col.NameCs,
            NameEn: col.NameEn,
            AltNameCs: col.AltNameCs,
            AltNameEn: col.AltNameEn,
            AdditionalFieldItem: col.AdditionalFieldItem,
            EnumTypeItem: col.EnumTypeItem,
        }));

        return this.columns;
    };

    private readonly loadWorkflows = async () => {
        const { askApi } = this.connection;
        const { objectTypes } = this.context.apiData;
        this.workflowFolders = RemoteItemStore.getDisplayableWorkflowFolders(objectTypes, this.licenseRestrictionsHelper);

        const workflowEnumTypes = this.workflowFolders.map((f) => FolderNames.getEnumTypeName(f)).filter((f) => !!f) as string[];
        const allEnumTypesRes = await askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypes', { omitEnumValues: true });
        const typesItemGuids = allEnumTypesRes.Data.filter((et) => workflowEnumTypes.includes(et.EnumName)).map((et) => et.ItemGUID);
        const enumTypesRes = await askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypesByItemGuids', { itemGuids: typesItemGuids });

        this.workflowTypes = enumTypesRes.Data.map((et) => ({
            EnumName: et.EnumName,
            EnumValuesInEnumType:
                et.EnumValuesInEnumType?.map((ev) => ({
                    En: ev.En,
                    Cs: ev.Cs,
                    ItemGUID: ev.ItemGUID,
                })) ?? null,
        }));

        return this.workflowTypes;
    };

    private readonly getDisplayedFolderNames = (folderNames: string[]): { folder: string; folderName: string; singular: string; synonyms: string }[] => {
        return folderNames
            .filter((folderName) => {
                return !this.licenseRestrictionsHelper.isFolderNameLocked(folderName as TFolderName).isFolderNameLocked;
            })
            .map((folderName) => {
                const { singular, plural, synonyms } = FolderNames.getAllStringForms(folderName);
                return { folder: plural, folderName: folderName, singular: singular, synonyms: synonyms };
            })
            .sort((a, b) => StringHelper.localeCompare(a.folder, b.folder));
    };

    private readonly prepareUsers = (usersData: TPartialWcfUser[]): TPageBase[] => {
        return usersData.flatMap((user) => [
            {
                group: myStrings.sideMenu.users,
                nameGetter: (strings) => {
                    if (user.FileAs) {
                        return `${strings.components.search.editUser} ${user.FileAs}`;
                    } else return `${strings.components.search.editUser} ${user.Username}`;
                },
                description: [user.Email1Address, user.Email2Address].filter((email) => email && email.length > 1).join(', '),
                keyWords: [user.FileAs || null, user.Username, user.Email1Address, user.Email2Address, user.ItemGUID],
                synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.user],
                link: `${RouteConfig.users.edit.path}/${user.ItemGUID}`,
                avatarItem: { ...user, __typeName: 'User' },
                getAvatar: () => <UserAvatar size={32} item={user} />,
            },
            {
                group: myStrings.sideMenu.users,
                nameGetter: (strings) => {
                    if (user.FileAs) {
                        return `${strings.components.search.effectivePermissions} ${user.FileAs}`;
                    } else return `${strings.components.search.effectivePermissions} ${user.Username}`;
                },
                description: [user.Email1Address, user.Email2Address].filter((email) => email && email.length > 1).join(', '),
                keyWords: [user.FileAs || null, user.Username, user.Email1Address, user.Email2Address, user.ItemGUID],
                synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.permissions, myStrings.search.synonyms.user],
                link: `${RouteConfig.users.effectivePermissions.path}/${user.ItemGUID}`,
                iconName: 'Permissions',
            },
        ]);
    };

    private readonly prepareGroups = (groupsData: TPartialWcfGroup[]): TPageBase[] => {
        return groupsData.map((group) => ({
            group: myStrings.sideMenu.groups,
            nameGetter: (strings) => `${strings.components.search.editGroup} ${group.GroupName}`,
            description: group.Description ?? '',
            keyWords: [group.ItemGUID, group.Description],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.group],
            link: `${RouteConfig.groups.edit.path}/${group.ItemGUID}`,
            avatarItem: { ...group, __typeName: 'Group' },
            licenseRestriction: this.licenseRestrictionsHelper.licenseRestrictions.functionalities.UserRoles ?? null,
            getAvatar: () => <GroupAvatar size={32} item={group} />,
        }));
    };

    private readonly prepareFeatures = (featureTypesWithGs: TFeatureTypesWithGs): TPageBase[] => {
        const alreadyUsedCategories = new Set<string>();
        return Object.entries(featureTypesWithGs).flatMap(([featureType, globalSettings]) => {
            const currentFeatureType = featureTypes.find((f) => f.type === featureType);
            const featureTypeName = currentFeatureType?.name ?? featureType;

            const featurePage: TPageBase[] = [];
            if (currentFeatureType) {
                featurePage.push({
                    group: myStrings.sideMenu.features,
                    nameGetter: () => currentFeatureType.name,
                    description: currentFeatureType.description,
                    img: currentFeatureType.icon,
                    keyWords: [currentFeatureType.type],
                    synonyms: [myStrings.search.synonyms.features],
                    link: `${RouteConfig.customizations.features.path}/${featureType.toLowerCase()}`,
                    action: SearchAction.Other,
                });
            }

            const categoryPages: TPageBase[] = [];
            const gsPages: TPageBase[] = [];
            globalSettings.forEach((gs) => {
                if (gs.categoryTranslations.FileAs && !alreadyUsedCategories.has(gs.categoryTranslations.FileAs)) {
                    alreadyUsedCategories.add(gs.categoryTranslations.FileAs);
                    categoryPages.push({
                        group: myStrings.sideMenu.features,
                        nameGetter: (strings) => strings.pickTranslation(gs.categoryTranslations) as string,
                        description: `${featureTypeName}`,
                        img: featuresIcon,
                        keyWords: [],
                        synonyms: [myStrings.search.synonyms.features],
                        link: `${RouteConfig.customizations.features.path}/${featureType.toLowerCase()}?${RouteConfig.customizations.features.categoryParamName}=${gs.categoryTranslations.FileAs}`,
                        action: SearchAction.Other2,
                    });
                }

                const { globalSettingLicenseRestriction } = this.licenseRestrictionsHelper.isGlobalSettingLocked(gs.Name, gs.categoryTranslations.FileAs);
                gsPages.push({
                    group: myStrings.sideMenu.features,
                    nameGetter: (strings) => strings.pickNameTranslation(gs) as string,
                    description: `${featureTypeName} > ${Strings.pickTranslation(gs.categoryTranslations) as string}`,
                    img: featuresIcon,
                    keyWords: [gs.Name],
                    synonyms: [myStrings.search.synonyms.features, gs.Type === GlobalSettingTypes.toggle ? myStrings.search.synonyms.toggle : ''],
                    licenseRestriction: globalSettingLicenseRestriction,
                    link: `${RouteConfig.customizations.features.path}/${featureType.toLowerCase()}?${RouteConfig.customizations.features.featureParamName}=${gs.Name}`,
                });
            });

            return featurePage.concat(categoryPages, gsPages);
        });
    };

    private readonly prepareModulePermissions = (groupsData: TPartialWcfGroup[]): TPageBase[] => {
        return groupsData.map((group) => ({
            group: myStrings.sideMenu.modulePermissions,
            nameGetter: (strings) => `${strings.components.search.editModulePermissions} ${group.GroupName}`,
            keyWords: [group.ItemGUID],
            synonyms: [myStrings.search.synonyms.module, myStrings.search.synonyms.permissions, myStrings.search.synonyms.assign, myStrings.search.synonyms.edit, myStrings.search.synonyms.group],
            link: `${RouteConfig.modulePermissions.path}/${group.ItemGUID}`,
            licenseRestriction: this.licenseRestrictionsHelper.licenseRestrictions.functionalities.ModulePermissions ?? null,
            avatarItem: { ...group, __typeName: 'Group' },
            getAvatar: () => <GroupAvatar size={32} item={group} />,
        }));
    };

    private readonly prepareFieldPermissions = (groupsData: TPartialWcfGroup[]): TPageBase[] => {
        return groupsData.map((group) => ({
            group: myStrings.routes.groups.fieldPermissions,
            nameGetter: (strings) => `${strings.components.search.fieldPermissionsPrefix} ${group.GroupName}`,
            keyWords: [group.ItemGUID],
            synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.fields, myStrings.search.synonyms.permissions, myStrings.search.synonyms.group],
            link: `${RouteConfig.groups.edit.path}/${group.ItemGUID}/${RouteConfig.groups.edit.tabKeys.fieldPermissions}`,
            licenseRestriction: this.licenseRestrictionsHelper.licenseRestrictions.functionalities.ColumnPermissions ?? null,
            avatarItem: { ...group, __typeName: 'Group' },
            getAvatar: () => <GroupAvatar size={32} item={group} />,
        }));
    };

    private readonly prepareFieldsFolders = (afFolders: string[]): TPageBase[] => {
        const displayedFoldersNames = this.getDisplayedFolderNames(afFolders);
        return displayedFoldersNames.flatMap((f) => [
            {
                group: `${myStrings.sideMenu.fields}`,
                action: SearchAction.Manage,
                nameGetter: (strings) => `${strings.components.search.fieldsNamePrefix} ${f.folder} ${strings.components.search.fieldsNameSuffix}`,
                link: `${RouteConfig.customizations.fields.path}/${f.folderName.toLowerCase()}`,
                img: FolderNames.getIcon(f.folderName),
                keyWords: [f.folderName],
                synonyms: [myStrings.search.synonyms.edit, myStrings.search.synonyms.fields, myStrings.search.synonyms.additionalField, f.folder, f.singular, f.synonyms],
            },
            {
                group: `${myStrings.sideMenu.fields}`,
                action: SearchAction.Add,
                nameGetter: (strings) => `${strings.components.search.newAdditionalFieldNamePrefix} ${f.folder}`,
                link: `${RouteConfig.customizations.fields.path}/${f.folderName.toLowerCase()}/${RouteConfig.customizations.fields.new.slug}`,
                img: FolderNames.getIcon(f.folderName),
                addNewIcon: true,
                keyWords: [f.folderName],
                synonyms: [myStrings.search.synonyms.create, myStrings.search.synonyms.fields, myStrings.search.synonyms.additionalField, f.folder, f.singular, f.synonyms],
            },
        ]);
    };

    private readonly prepareFieldsItems = (columns: TPartialApiColumn[], fieldsFolders: string[]): TPageBase[] => {
        const allDisplayedFoldersNames = this.getDisplayedFolderNames(fieldsFolders);
        const fieldItems: TPageBase[] = [];
        const currencyFolders = new Set<string>();

        columns.forEach((col) => {
            const displayedFolderNames = allDisplayedFoldersNames.find((f) => f.folderName === col.FolderName);

            if (col.EnumTypeItem?.EnumName === 'Currency') {
                // Currency enum should have only one search result with all foldernames in description
                currencyFolders.add(col.FolderName);
            } else if (displayedFolderNames) {
                fieldItems.push({
                    group: myStrings.sideMenu.fields,
                    nameGetter: (strings) => Fields.getColumnName(col, strings.switch),
                    description: displayedFolderNames?.folder,
                    link: `${RouteConfig.customizations.fields.path}/${col.FolderName.toLowerCase()}/${RouteConfig.customizations.fields.edit.slug}/${col.ColumnName}`,
                    img: FolderNames.getIcon(FolderNames.additionalFields),
                    keyWords: [col.AdditionalFieldItem?.ItemGUID ?? '', col.AdditionalFieldItem?.Comment ?? '', col.ColumnName],
                    synonyms: [
                        displayedFolderNames?.singular || '',
                        displayedFolderNames?.folder || '',
                        displayedFolderNames?.synonyms || '',
                        col.AdditionalFieldItem ? myStrings.search.synonyms.additionalField : myStrings.search.synonyms.systemField,
                        myStrings.search.synonyms.edit,
                        myStrings.search.synonyms.fields,
                    ],
                });
            }
        });

        const displayedCurrencyFolderNames = allDisplayedFoldersNames.filter((folder) => currencyFolders.has(folder.folderName));
        const displayedCurrencyFolders = displayedCurrencyFolderNames.map((folder) => folder.folderName);
        if (displayedCurrencyFolderNames.length > 0) {
            // Pick folderName for link based on feature order
            let currencyFolderName: string | null = null;
            if (displayedCurrencyFolders.includes(FolderNames.leads)) {
                currencyFolderName = FolderNames.leads;
            } else if (displayedCurrencyFolders.includes(FolderNames.projects)) {
                currencyFolderName = FolderNames.projects;
            } else if (displayedCurrencyFolders.includes(FolderNames.carts)) {
                currencyFolderName = FolderNames.carts;
            }

            const currencyCol = columns.find((col) => col.FolderName === currencyFolderName && col.ColumnName === 'CurrencyEn');
            if (currencyFolderName && currencyCol) {
                fieldItems.push({
                    group: myStrings.sideMenu.fields,
                    nameGetter: (strings) => Fields.getColumnName(currencyCol, strings.switch),
                    description: displayedCurrencyFolderNames.map((folder) => folder.folder).join(', '),
                    link: `${RouteConfig.customizations.fields.path}/${currencyFolderName.toLowerCase()}/${RouteConfig.customizations.fields.edit.slug}/${currencyCol.ColumnName}`,
                    img: FolderNames.getIcon(FolderNames.additionalFields),
                    keyWords: [currencyCol.ColumnName],
                    synonyms: [
                        displayedCurrencyFolderNames.map((folder) => `${folder.singular} ${folder.folder} ${folder.synonyms}`).join(' '),
                        myStrings.search.synonyms.systemField,
                        myStrings.search.synonyms.edit,
                    ],
                });
            }
        }
        return fieldItems;
    };

    private readonly prepareWorkflowTypes = (workflowTypes: TPartialApiEnumType[], workflowFolders: string[]): TPageBase[] => {
        // TODO: Disabled workflow types, models without types
        const allDisplayedFoldersNames = this.getDisplayedFolderNames(workflowFolders);

        const addNewWorkflowPages: TPageBase[] = allDisplayedFoldersNames.map((displayedFolderNames) => {
            return {
                group: myStrings.sideMenu.workflow,
                nameGetter: (strings) => `${strings.components.search.newWorkflowPrefix} ${displayedFolderNames.folder}`,
                link: `${RouteConfig.customizations.workflow.path}/${displayedFolderNames.folderName.toLowerCase()}?${RouteConfig.customizations.workflow.newParamName}=${true}`,
                synonyms: [
                    displayedFolderNames?.singular || '',
                    displayedFolderNames?.folder || '',
                    displayedFolderNames?.synonyms || '',
                    myStrings.search.synonyms.workflow,
                    myStrings.search.synonyms.create,
                ],
                addNewIcon: true,
                img: FolderNames.getIcon(displayedFolderNames.folderName),
            };
        });

        const workflowPages: TPageBase[] = workflowTypes.flatMap((et) => {
            const folderName = FolderNames.getFolderNameByEnumTypeName(et.EnumName) as string;
            const displayedFolderNames = allDisplayedFoldersNames.find((f) => f.folderName === folderName);

            return et.EnumValuesInEnumType!.map((ev) => ({
                group: myStrings.sideMenu.workflow,
                nameGetter: (strings) => strings.pickTranslation(ev) as string,
                description: displayedFolderNames?.folder,
                link: `${RouteConfig.customizations.workflow.path}/${folderName.toLowerCase()}/${ev.ItemGUID}`,
                keyWords: [ev.ItemGUID, et.EnumName],
                img: workflowIcon,
                synonyms: [
                    displayedFolderNames?.singular || '',
                    displayedFolderNames?.folder || '',
                    displayedFolderNames?.synonyms || '',
                    myStrings.search.synonyms.workflow,
                    myStrings.search.synonyms.edit,
                ],
            }));
        });
        return workflowPages.concat(addNewWorkflowPages);
    };

    private readonly prepareExternalPages = (): TPageBase[] => {
        const loadedExternalPages = Strings.getLanguage() === 'cs' ? this.externalLinksCs : this.externalLinksEn;

        const externalPages: TPageBase[] = loadedExternalPages.map((externalPage) => ({
            group: externalPage.group,
            link: externalPage.link,
            nameGetter: () => externalPage.name,
            action: externalPage.action,
            addNewIcon: externalPage.addNewIcon,
            description: externalPage.description,
            iconName: externalPage.iconName,
            img: externalPage.img,
            isExternalLink: externalPage.link.startsWith('http'),
            synonyms: externalPage.synonyms ? [externalPage.synonyms] : [],
        }));

        return externalPages;
    };

    private readonly finalizeResults = (allResults: TPageBase[]): TPage[] => {
        return allResults.map((result) => ({
            ...result,
            name: result.nameGetter(Strings),
            enName: result.nameGetter(LocalizationHelper.getLocalizedContent(Strings, 'en')),
            searchHelper: StringHelper.removeAccents(`${result.description ?? ''} ${result.nameGetter(Strings)} ${result.synonyms?.join(' ')} ${result.keyWords?.join(' ') ?? ''}`) ?? '',
        }));
    };

    public readonly getFinalizedResults = () => {
        const { isLegacyAdministrationLinkHidden } = this.licenseRestrictionsHelper.isLegacyAdministrationLinkHidden();
        const searchPages = getSearchPages(isLegacyAdministrationLinkHidden);
        const allResults = searchPages.concat(
            this.prepareFieldsFolders(this.fieldsFolders),
            this.prepareFieldsItems(this.columns, this.fieldsFolders),
            this.prepareUsers(this.usersData),
            this.prepareGroups(this.groupsData),
            this.prepareFeatures(this.featureTypesWithGs),
            this.prepareModulePermissions(this.groupsData),
            this.prepareFieldPermissions(this.groupsData),
            this.prepareWorkflowTypes(this.workflowTypes, this.workflowFolders),
            this.prepareExternalPages()
        );
        return this.finalizeResults(allResults);
    };
}

