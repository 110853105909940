import * as React from 'react';
import './SettingsPanel.scss';
import { FontIcon } from '@fluentui/react';
import { Button } from 'react-bootstrap';

export interface ISettingsPanelButtonProps {
    togglePanel: () => void;
    hidePanel: () => void;
    isPanelShown: boolean;
}

type TSettingsPanelProps = React.PropsWithChildren & {
    title: string;
    onHide: () => void;
};

export class SettingsPanel extends React.Component<TSettingsPanelProps> {
    render() {
        return (
            <div className={'settings-panel bg-light border-left text-dark'}>
                <div className="p-0">
                    <div className="row m-0 w-100">
                        <div className="col p-4 align-self-center">
                            <h3 className="m-0 font-semibold">{this.props.title}</h3>
                        </div>
                        <div className="col-auto p-0 mr-auto align-self-center">
                            <Button variant="outline-secondary" onClick={this.props.onHide}><FontIcon iconName="ChromeClose" /></Button>
                        </div>
                    </div>
                    <div className="row m-0 w-100">
                        <div className="col px-1">
                            {this.props.children}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}