import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiEnumValue, IApiResult } from '@eway-crm/connector';
import ReactHelper from '../../../helpers/ReactHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TDeleteWorkflowWizardProps = {
    onDismiss: () => void;
    selectedItemType: IApiEnumValue;
    onDone: () => void;
};

type TDeleteWorkflowWizardState = {
    isSavingInProgress: boolean;
};

const myStrings = Strings.components.routes.workflow;

export default class DeleteWorkflowWizard extends React.Component<TDeleteWorkflowWizardProps, TDeleteWorkflowWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TDeleteWorkflowWizardProps) {
        super(props);
        this.state = {
            isSavingInProgress: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = async () => {
        await ReactHelper.setState(this, { isSavingInProgress: true });
        await this.context.connection.askApi<IApiResult>('DeleteEnumValue', { itemGuid: this.props.selectedItemType.ItemGUID });
        this.props.onDone();
    };

    render() {
        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.deleteWorkflowTitle}</h1>
                        <div className="mb-4">
                            <span key={'del-wf-' + this.props.selectedItemType.ItemGUID} className="badge badge-info badge-full-font">
                                {Strings.pickTranslation(this.props.selectedItemType)}
                            </span>
                        </div>
                        <div>{myStrings.deleteWorkflowMessage}</div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="danger" onClick={() => void this.submit()}>
                            {Strings.delete}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}
