import React from 'react';
import { Form, Nav, Tab } from 'react-bootstrap';
import type { IApiGlobalSetting } from '@eway-crm/connector';
import { ConfirmBeforeUnload, SpinnerVariant } from '@eway-crm/gui';
import Strings from '../../../strings';
import type { TEnumTypeEnumValues, TFeature } from '../Features';
import GlobalSettingField from './GlobalSettingField';
import FeatureModulePermissionsTab from './FeatureModulePermissionsTab';
import type { IApiEnumValue } from '@eway-crm/connector';
import ObjectHelper from '../../../helpers/ObjectHelper';
import FeatureFieldPermissionsTab from './FeatureFieldPermissionsTab';
import type { IApiGroup } from '@eway-crm/connector';
import type { TEnumTypeEnumValueChangeItems } from './NestedMultiselectDropdown';
import GlobalSettingTypes from '../../../data/constants/GlobalSettingTypes';
import { ItemStorage } from '../../../data/ItemStorageWithDelayedSave';
import ConfirmBeforeRouteChange from '../../../helpers/components/ConfirmBeforeRouteChange';
import type { RouteComponentProps } from 'react-router-dom';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import ModulePermissions from '../ModulePermissions';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { IApiModulePermission } from '@eway-crm/connector';
import FolderNames from '../../../data/constants/FolderNames';
import { RemoteItemStore } from '../../../RemoteItemStore';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { TFieldPermissionWithCol } from '../fields/FieldPermissionsGrid';
import type { IApiColumnPermission } from '@eway-crm/connector';
import type { IApiColumn } from '@eway-crm/connector';
import SaveButtonsToolbar from '../../shared/SaveButtonsToolbar';
import Fields from '../Fields';
import RouteConfig from '../../../RouteConfig';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import type { TFeatureType } from '../../../data/constants/FeatureTypes';
import Toggle from '../../shared/Toggle';
import LicenseRestrictionsLockedSplash from '../../shared/locks/FeatureLockedSplash';
import ReactHelper from '../../../helpers/ReactHelper';

const myStrings = Strings.components.routes.features;

type TFolderFeaturesProps = Pick<RouteComponentProps, 'history' | 'location'> & {
    selectedFeature: TFeature;
    onFeatureToggle: (featureType: TFeatureType) => void;
    globalSettings: IApiGlobalSetting[];
    globalSettingCategoryNames: IApiEnumValue[];
    allGroups: IApiGroup[];
    enumTypeEnumValues: TEnumTypeEnumValues;
    onReload: (successCb: () => void) => Promise<void>;
};

type TFolderFeaturesState = {
    searchedString: string;
    selectedTab: TSelectedTabOption;
    globalSettings: IApiGlobalSetting[];
    initialGlobalSettings: IApiGlobalSetting[];
    enumTypeEnumValues: TEnumTypeEnumValues;
    initialEnumTypeEnumValues: TEnumTypeEnumValues;
    modulePermissions: TModulePermissionWithFolder[] | null;
    initialModulePermissions: TModulePermissionWithFolder[] | null;
    fieldPermissions: TFieldPermissionWithCol[] | null;
    initialFieldPermissions: TFieldPermissionWithCol[] | null;
    anyChangeMade: boolean;
    isSavingChanges: boolean;
    folders: string[] | null;
    columns: IApiColumn[] | null;
    fieldPermissionsSelectedGroup: IApiGroup | null;
    fieldPermissionsSelectedFolderName: string | null;
};

type TSelectedTabOption = 'settings' | 'modulePermissions' | 'fieldPermissions';
type TGlobalSettingWithShouldHide = IApiGlobalSetting & { isFieldDisabled: boolean; shouldHide: boolean };

class FolderFeatures extends React.Component<TFolderFeaturesProps, TFolderFeaturesState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private globalSettingsStorage: ItemStorage<IApiGlobalSetting>;
    private enumValuesStorage: ItemStorage<IApiEnumValue>;
    private modulePermissionsStorage: ItemStorage<TModulePermissionWithFolder>;
    private fieldPermissionsStorage: ItemStorage<TFieldPermissionWithCol>;
    private gsRefs: { [gsName: string]: React.RefObject<HTMLDivElement> };
    private categoryRefs: { [categoryName: string]: React.RefObject<HTMLDivElement> };
    constructor(props: TFolderFeaturesProps) {
        super(props);
        this.state = {
            searchedString: '',
            selectedTab: 'settings',
            globalSettings: props.globalSettings,
            initialGlobalSettings: props.globalSettings,
            enumTypeEnumValues: props.enumTypeEnumValues,
            initialEnumTypeEnumValues: props.enumTypeEnumValues,
            modulePermissions: null,
            initialModulePermissions: null,
            fieldPermissions: null,
            initialFieldPermissions: null,
            isSavingChanges: false,
            anyChangeMade: false,
            folders: null,
            columns: null,
            fieldPermissionsSelectedFolderName: null,
            fieldPermissionsSelectedGroup: null,
        };
        this.globalSettingsStorage = new ItemStorage((item) => item.ItemGUID);
        this.enumValuesStorage = new ItemStorage((item) => item.ItemGUID);
        this.modulePermissionsStorage = new ItemStorage((item) => item.FolderName);
        this.fieldPermissionsStorage = new ItemStorage((item) => `${item.ColumnName}.${item.GroupGuid}`); // Field Permission doesn't always have ItemGUID

        this.gsRefs = {};
        props.globalSettings.forEach(gs => {
            this.gsRefs[gs.Name] = React.createRef();
        });

        this.categoryRefs = {};
        props.globalSettingCategoryNames.forEach(cat => {
            if (cat.FileAs) {
                this.categoryRefs[cat.FileAs] = React.createRef();
            }
        });

    }

    private readonly onGlobalSettingChange = (newValue: string | null, itemGuid: string) => {
        const newGlobalSettings = this.state.globalSettings.map((gs) => {
            if (gs.ItemGUID === itemGuid) {
                const updatedGlobalSetting = { ...gs, Value: newValue };
                this.globalSettingsStorage.addItem(updatedGlobalSetting);
                return updatedGlobalSetting;
            }
            return gs;
        });
        this.setState({ globalSettings: newGlobalSettings, anyChangeMade: true });
    };

    private readonly onEnumValuesChange = (items: TEnumTypeEnumValueChangeItems[]) => {
        let newEnumValues: IApiEnumValue[] | null = null;
        const currentFolder = items[0].folder;
        const folderEnumValues = this.state.enumTypeEnumValues[currentFolder];
        if (folderEnumValues) {
            newEnumValues = folderEnumValues.map((ev) => {
                const changedItem = items.find((item) => item.itemGuid === ev.ItemGUID);
                if (changedItem) {
                    const newItem = { ...ev, IncludeInLastActivityCalculation: !!changedItem.checked };
                    this.enumValuesStorage.addItem(newItem);
                    return newItem;
                }
                return ev;
            });
        }

        if (newEnumValues !== null) {
            this.setState((prevState) => ({ enumTypeEnumValues: { ...prevState.enumTypeEnumValues, [currentFolder]: newEnumValues }, anyChangeMade: true }));
        }
    };

    private readonly onModulePermissionChange = (rowId: string, column: string, newValue: string | number | boolean | null, groupGuid: string) => {
        const newModulePermissions = this.state.modulePermissions!.map((mp) => {
            if (mp.FolderName === rowId && mp.GroupGuid === groupGuid) {
                const updatedModulePermission = ModulePermissions.changeModulePermission(mp, column, newValue);
                this.modulePermissionsStorage.addItem(updatedModulePermission);
                return updatedModulePermission;
            }
            return mp;
        });
        this.setState({ modulePermissions: newModulePermissions, anyChangeMade: true });
    };

    private readonly askForModulePermissions = async () => {
        const { askApi } = this.context.connection;
        const { objectTypes } = this.context.apiData;
        const otFolders = objectTypes.filter((ot) => ot.IsModule && ot.IsActive).map((ot) => ot.FolderName);
        const modulePermissionsResult = await askApi<IApiDataResponse<IApiModulePermission>>('GetModulePermissions', { includeDefaultPermissions: true });
        const modulePermissions = modulePermissionsResult.Data.filter((mp) => otFolders.includes(mp.FolderName)).map((mp) => ({
            ...mp,
            Folder: FolderNames.getPluralName(mp.FolderName),
        }));
        return modulePermissions;
    };

    private readonly loadModulePermissions = async () => {
        const modulePermissions = await this.askForModulePermissions();
        this.setState({ modulePermissions, initialModulePermissions: modulePermissions });
    };

    private readonly onFieldPermissionsChange = (newFieldPermissions: TFieldPermissionWithCol[], newItemToSave: TFieldPermissionWithCol) => {
        this.fieldPermissionsStorage.addItem(newItemToSave);
        this.setState({ fieldPermissions: newFieldPermissions, anyChangeMade: true });
    };

    private readonly loadColumns = async () => {
        const { askApi } = this.context.connection;
        const { objectTypes } = this.context.apiData;
        const otFolders = RemoteItemStore.getDisplayableFieldPermissionFolders(objectTypes, this.context.licenseRestrictionsHelper).filter((folder) => this.props.selectedFeature.associatedFolderNames.includes(folder));
        const colsResult = await askApi<IApiDataResponse<IApiColumn>>('GetColumns', { includeAdditionalFields: true });
        this.setState({
            folders: otFolders,
            columns: colsResult.Data,
        });
    };

    private readonly askForFieldPermissions = async (selectedGroup: IApiGroup | null, selectedFolderName: string | null) => {
        const { askApi } = this.context.connection;
        if (selectedGroup && selectedFolderName) {
            const colPermissions = await askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
                includeDefaultPermissions: true,
                transmitObject: {
                    GroupGuid: selectedGroup.ItemGUID,
                    FolderName: selectedFolderName,
                },
            });

            const columns = this.state.columns!.filter((col) => col.FolderName === this.state.fieldPermissionsSelectedFolderName && !!col.IsPermissionEnabled);
            if (this.state.fieldPermissionsSelectedGroup === selectedGroup && this.state.fieldPermissionsSelectedFolderName === selectedFolderName) {
                const fieldPermissions = colPermissions.Data.map((colPermission) => {
                    const column = columns.find((t) => t.ColumnName === colPermission.ColumnName && t.FolderName === colPermission.FolderName)!;
                    return {
                        ...colPermission,
                        col: column,
                        colName: Fields.getColumnName(column),
                    };
                });
                return fieldPermissions;
            }
        }
        return null;
    };

    private readonly loadFieldPermissions = (selectedGroup: IApiGroup | null, selectedFolderName: string | null) => {
        this.setState(
            {
                fieldPermissionsSelectedGroup: selectedGroup,
                fieldPermissionsSelectedFolderName: selectedFolderName,
                fieldPermissions: null, initialFieldPermissions: null
            },
            () => {
                (async () => {
                    if (selectedGroup && selectedFolderName) {
                        const initialFieldPermissions = await this.askForFieldPermissions(selectedGroup, selectedFolderName);
                        if (initialFieldPermissions) {
                            let fieldPermissions = [...initialFieldPermissions];
                            const itemsInStorage = this.fieldPermissionsStorage.getItems();
                            const itemsInStorageGuids = itemsInStorage.map((i) => i.ColumnName);
                            if (itemsInStorageGuids.length > 0) {
                                fieldPermissions = fieldPermissions.map((fp) => {
                                    if (itemsInStorageGuids.includes(fp.ColumnName)) {
                                        const unsavedFieldPermission = itemsInStorage.find((item) => item.ColumnName === fp.ColumnName && item.GroupGuid === fp.GroupGuid);
                                        if (unsavedFieldPermission) {
                                            return unsavedFieldPermission;
                                        }
                                    }
                                    return fp;
                                });
                            }

                            this.setState({
                                fieldPermissions,
                                initialFieldPermissions,
                            });
                        }
                    }
                })()
                    .catch((err) => console.error('Unable to load field permissions.', err));
            });
    };

    componentDidUpdate(prevProps: TFolderFeaturesProps) {
        if (prevProps.globalSettings !== this.props.globalSettings) {
            this.setState({ globalSettings: this.props.globalSettings, initialGlobalSettings: this.props.globalSettings });
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search);
        const featureName = urlParams.get(RouteConfig.customizations.features.featureParamName);
        const categoryName = urlParams.get(RouteConfig.customizations.features.categoryParamName);
        if (featureName && this.gsRefs[featureName]) {
            this.gsRefs[featureName].current?.scrollIntoView();
        }
        if (categoryName && this.categoryRefs[categoryName]) {
            this.categoryRefs[categoryName].current?.scrollIntoView();
        }
    }

    private readonly saveChanges = async () => {
        const { askApi } = this.context.connection;
        const gsItemsToSave = this.globalSettingsStorage.takeItems();
        const enumValuesToSave = this.enumValuesStorage.takeItems();
        const modulePermissionsToSave = this.modulePermissionsStorage.takeItems();
        const fieldPermissionsToSave = this.fieldPermissionsStorage.takeItems();

        await ReactHelper.setState(this, { isSavingChanges: true });

        try {
            let stateToSave = {};
            if (gsItemsToSave.length > 0) {
                await askApi('SaveGlobalSettings', {
                    transmitObjects: gsItemsToSave.map((item) => ({
                        ItemGUID: item.ItemGUID,
                        Value: item.Value,
                    })),
                });
            }
            if (enumValuesToSave.length > 0) {
                await askApi('SaveEnumValues', {
                    transmitObjects: enumValuesToSave.map((ev) => ({ ItemGUID: ev.ItemGUID, IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation })),
                });
            }
            if (modulePermissionsToSave.length > 0) {
                await new RemoteItemStore(this.context.connection).saveModulePermissions(modulePermissionsToSave);
                const modulePermissions = await this.askForModulePermissions();
                if (modulePermissions) {
                    stateToSave = { ...stateToSave, modulePermissions: modulePermissions, initialModulePermissions: modulePermissions };
                }
            }
            if (fieldPermissionsToSave.length > 0) {
                await askApi('SaveColumnPermissions', {
                    transmitObjects: fieldPermissionsToSave.map((item) => ({
                        GroupGuid: item.GroupGuid,
                        FolderName: item.FolderName,
                        ColumnName: item.ColumnName,
                        PermissionRule: item.PermissionRule,
                        MandatoryRule: item.MandatoryRule,
                    })),
                });
                const fieldPermissions = await this.askForFieldPermissions(this.state.fieldPermissionsSelectedGroup, this.state.fieldPermissionsSelectedFolderName);
                if (fieldPermissions) {
                    stateToSave = { ...stateToSave, fieldPermissions, initialFieldPermissions: fieldPermissions };
                }
            }

            if (gsItemsToSave.length > 0 || enumValuesToSave.length > 0) {
                void this.props.onReload(() => {
                    this.setState({ isSavingChanges: false, anyChangeMade: false, ...stateToSave });
                });
            } else {
                this.setState({ isSavingChanges: false, anyChangeMade: false, ...stateToSave });
            }
        } catch (e) {
            console.error('Unable to save folder features changes.', e);
        }
    };

    private readonly discardChanges = () => {
        this.globalSettingsStorage.clearItems();
        this.enumValuesStorage.clearItems();
        this.modulePermissionsStorage.clearItems();
        this.fieldPermissionsStorage.clearItems();
        this.setState((prevState) => ({
            globalSettings: prevState.initialGlobalSettings,
            enumTypeEnumValues: prevState.initialEnumTypeEnumValues,
            modulePermissions: prevState.initialModulePermissions,
            fieldPermissions: prevState.initialFieldPermissions,
            anyChangeMade: false,
        }));
    };

    private readonly getIsFieldDisabled = (globalSetting: IApiGlobalSetting) => {
        const { globalSettings: allGlobalSettings } = this.state;
        if (globalSetting.DependsOn) {
            const dependsOnGs = allGlobalSettings.find((ags) => ags.Name === globalSetting.DependsOn);
            if (dependsOnGs) {
                if (
                    (!globalSetting.DependsOnValueInverted && dependsOnGs.Value !== globalSetting.DependsOnValue) ||
                    (globalSetting.DependsOnValueInverted && dependsOnGs.Value === globalSetting.DependsOnValue)
                ) {
                    return true;
                }
            }
        }
        return false;
    };

    private readonly getFilteredGlobalSettings = (): TGlobalSettingWithShouldHide[] => {
        const filteredGlobalSettings = this.state.globalSettings.filter((gs) => {
            if (!gs.Visible || gs.IsLegacy) {
                return false;
            }
            return Strings.pickNameTranslation(gs)?.toLowerCase()?.includes(this.state.searchedString.toLowerCase());
        });

        return filteredGlobalSettings.map(gs => {
            const isFieldDisabled = this.getIsFieldDisabled(gs);

            let shouldHide = false;
            // Hide all disabled except for comboBox or inverted dependent values
            if (isFieldDisabled && gs.Type !== GlobalSettingTypes.comboBox && !gs.DependsOnValueInverted) {
                shouldHide = true;
            }

            return { ...gs, isFieldDisabled, shouldHide };
        });
    };

    private readonly getGroupedGlobalSettings = () => {
        const filteredGlobalSettings = this.getFilteredGlobalSettings();
        const globalSettingsCategories: { [ItemGuid: string]: string } = {};

        // Sort categories by rank
        const categoryNames = this.props.globalSettingCategoryNames
            .sort((a, b) => a.Rank - b.Rank)
            .map((cat) => {
                globalSettingsCategories[cat.ItemGUID] = cat.FileAs!;
                return cat.FileAs!;
            });

        // Assign GS to categories
        const results = filteredGlobalSettings.reduce((result: { [key: string]: TGlobalSettingWithShouldHide[] }, currentVal) => {
            if (!currentVal.shouldHide) {
                (result[globalSettingsCategories[currentVal.Category]] = result[globalSettingsCategories[currentVal.Category]] || []).push(currentVal);
            }
            return result;
        }, {});

        // Sort GS in categories by rank
        Object.keys(results).forEach((key) => {
            results[key] = results[key].sort((a, b) => {
                if (a.Rank === null) {
                    return 1;
                } else if (b.Rank === null) {
                    return -1;
                } else {
                    return a.Rank - b.Rank;
                }
            });
        });

        return ObjectHelper.sortObjectByKeyNameList(results, categoryNames);
    };

    private readonly onTabSelect = (tabKey: TSelectedTabOption) => {
        const { isFeatureLocked, featureLockedLicenseRestriction } = this.context.licenseRestrictionsHelper.isFeatureLocked(this.props.selectedFeature.type);
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();

        let permissionsLicenseRestriction;
        if (tabKey === "modulePermissions" && isModulePermissionsLocked) {
            permissionsLicenseRestriction = modulePermissionsLicenseRestriction;
        } else if (tabKey === "fieldPermissions" && isFieldPermissionsLocked) {
            permissionsLicenseRestriction = fieldPermissionsLicenseRestriction;
        }

        if (isFeatureLocked && ["modulePermissions", "fieldPermissions"].includes(tabKey)) {
            this.context.showLicenseRestrictionModal(featureLockedLicenseRestriction, { additionalLicenseRestrictions: permissionsLicenseRestriction ? [permissionsLicenseRestriction] : undefined });
            return;
        } else if (permissionsLicenseRestriction) {
            this.context.showLicenseRestrictionModal(permissionsLicenseRestriction);
            return;
        }

        this.setState({ searchedString: '', selectedTab: tabKey });
    };

    render() {
        const groupedGlobalSettings = this.getGroupedGlobalSettings();
        const { isFeatureLocked, featureLockedLicenseRestriction } = this.context.licenseRestrictionsHelper.isFeatureLocked(this.props.selectedFeature.type);
        const { isModulePermissionsLocked, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsLocked();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();

        return (
            <>
                {!!this.state.anyChangeMade && (
                    <>
                        <ConfirmBeforeUnload />
                        <ConfirmBeforeRouteChange history={this.props.history} />
                    </>
                )}
                {this.state.isSavingChanges && <SpinnerModal variant={SpinnerVariant.linear} />}
                <div className="h-100 px-3 pt-4 px-lg-4 pt-lg-4 px-xl-5 pt-xl-4 flex-column features">
                    <div className="h-100 w-100 d-flex flex-column">
                        <div className="d-flex align-items-stretch justify-content-between">
                            <div className="pb-2">
                                <h1>
                                    {this.props.selectedFeature.customTitle ?? Strings.formatString(myStrings.title, this.props.selectedFeature.name)}
                                </h1>
                                <div className="text-medium">{this.props.selectedFeature.description}</div>
                            </div>
                            <div className="d-flex justify-content-end flex-column">
                                {this.props.selectedFeature.canBeToggled && this.props.selectedFeature.isLocked && (
                                    <div className="d-flex justify-content-end align-items-center mb-2">
                                        <h3 className="m-0 mr-3 p-0 pb-1 text-danger">
                                            {Strings.inactive}
                                        </h3>
                                        <Toggle
                                            checked={false}
                                            onChange={() => {
                                                this.props.onFeatureToggle(this.props.selectedFeature.type);
                                            }}
                                        />
                                    </div>
                                )}
                                <SaveButtonsToolbar anyChangeMade={this.state.anyChangeMade} onSave={this.saveChanges} onDiscard={this.discardChanges} />
                            </div>
                        </div>
                        <hr className="mb-1 mt-3 w-100" />
                        <div className="d-flex flex-column flex-fill position-relative">
                            <Tab.Container
                                mountOnEnter
                                activeKey={this.state.selectedTab}
                                onSelect={(key) => {
                                    this.onTabSelect(key as TSelectedTabOption);
                                }}
                            >
                                <div className="row pb-3">
                                    <div className="col">
                                        <Nav variant="tabs" className="pt-1">
                                            <Nav.Item>
                                                <Nav.Link eventKey="settings">{myStrings.settingsTab}</Nav.Link>
                                            </Nav.Item>
                                            {this.props.selectedFeature.showPermissions && (
                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="modulePermissions">
                                                            {myStrings.modulePermissionsTab}{' '}
                                                            {isFeatureLocked && (
                                                                <LicenseRestrictionsLockIcon
                                                                    licenseRestriction={featureLockedLicenseRestriction}
                                                                    featureLockModalData={{
                                                                        additionalLicenseRestrictions: isModulePermissionsLocked ? [modulePermissionsLicenseRestriction] : undefined,
                                                                    }}
                                                                />
                                                            )}
                                                            {!isFeatureLocked && isModulePermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={modulePermissionsLicenseRestriction} />}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="fieldPermissions">
                                                            {myStrings.fieldPermissionsTab}{' '}
                                                            {isFeatureLocked && (
                                                                <LicenseRestrictionsLockIcon
                                                                    licenseRestriction={featureLockedLicenseRestriction}
                                                                    featureLockModalData={{
                                                                        additionalLicenseRestrictions: isFieldPermissionsLocked ? [fieldPermissionsLicenseRestriction] : undefined,
                                                                    }}
                                                                />
                                                            )}
                                                            {!isFeatureLocked && isFieldPermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={fieldPermissionsLicenseRestriction} />}
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </>
                                            )}
                                        </Nav>
                                    </div>
                                    {this.state.selectedTab === 'settings' && (
                                        <div className="col-auto">
                                            <Form.Control
                                                type="text"
                                                placeholder={Strings.search}
                                                value={this.state.searchedString}
                                                onChange={(e) => {
                                                    this.setState({ searchedString: e.target.value });
                                                }}
                                            />
                                        </div>
                                    )}
                                </div>
                                <Tab.Content className="mt-2 d-flex flex-fill">
                                    <Tab.Pane eventKey="settings" className="features__tab">
                                        {Object.entries(groupedGlobalSettings).map(([categoryName, globalSettings]) => (
                                            <div className="features__sectionWrap" key={categoryName} ref={this.categoryRefs[categoryName]}>
                                                <div className="features__sectionTitle">{Strings.pickTranslation(this.props.globalSettingCategoryNames.find(cat => cat.FileAs === categoryName))}</div>
                                                {globalSettings.map((gs) => {
                                                    if (gs.shouldHide) {
                                                        return null;
                                                    }

                                                    return (
                                                        <GlobalSettingField
                                                            ref={this.gsRefs[gs.Name]}
                                                            key={gs.ItemGUID}
                                                            globalSetting={gs}
                                                            onValueChange={this.onGlobalSettingChange}
                                                            allGroups={this.props.allGroups}
                                                            enumTypeEnumValues={this.state.enumTypeEnumValues}
                                                            onEnumValuesChange={this.onEnumValuesChange}
                                                            disabled={gs.isFieldDisabled}
                                                            gsCategoryName={this.props.globalSettingCategoryNames.find(cat => cat.FileAs === categoryName)}
                                                            selectedFeature={this.props.selectedFeature}
                                                        />
                                                    );
                                                })}
                                            </div>
                                        ))}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="modulePermissions" className="features__tab">
                                        {!isModulePermissionsLocked && !isFeatureLocked ? (
                                            <FeatureModulePermissionsTab
                                                searchedString={this.state.searchedString}
                                                selectedFeature={this.props.selectedFeature}
                                                modulePermissions={this.state.modulePermissions}
                                                groups={this.props.allGroups}
                                                onModulePermissionChange={this.onModulePermissionChange}
                                                onMount={this.loadModulePermissions}
                                            />
                                        ) : (
                                            <LicenseRestrictionsLockedSplash />
                                        )}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="fieldPermissions" className="features__tab">
                                        {!isFieldPermissionsLocked && !isFeatureLocked ? (
                                            <FeatureFieldPermissionsTab
                                                searchedString={this.state.searchedString}
                                                selectedFeature={this.props.selectedFeature}
                                                groups={this.props.allGroups}
                                                folders={this.state.folders}
                                                onMount={this.loadColumns}
                                                columns={this.state.columns}
                                                onMenuSelectionChange={this.loadFieldPermissions}
                                                onFieldPermissionsChange={this.onFieldPermissionsChange}
                                                selectedFolderName={this.state.fieldPermissionsSelectedFolderName}
                                                selectedGroup={this.state.fieldPermissionsSelectedGroup}
                                                fieldPermissions={this.state.fieldPermissions}
                                            />
                                        ) : (
                                            <LicenseRestrictionsLockedSplash />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default FolderFeatures;
