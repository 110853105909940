import * as React from 'react';
import type { ICheckSalesTaxModel } from '../../../../../../data/shopping/ICheckSalesTaxModel';
import type { IImmediateChargeModel } from '../../../../../../data/shopping/ICheckTaxesAndChargeForecastModel';
import type ICheckTaxesAndChargeForecastModel from '../../../../../../data/shopping/ICheckTaxesAndChargeForecastModel';
import type { IShoppingDatumResponse } from '../../../../../../data/shopping/IShoppingDatumResponse';
import type { TPeriodType } from '../../../../../../data/shopping/ISubscriptionsShoppingModel';
import { ConnectionContext } from '../../../../../../providers/ConnectionProvider';
import type { Connection } from '../../../../../layout/connection/Connection';
import type { ShoppingConnection } from '../../../../../ShoppingConnection';
import CheckoutSubmitter from '../../../CheckoutSubmitter';
import type { TCustomerInfo } from '../../../TCustomerInfo';
import StoreV2Context from '../../StoreV2Context';
import type TPackagesEditingStates from '../../TPackagesEditingStates';

const useCheckedTax = (onSalesTaxChange: (salesTax: ICheckSalesTaxModel | null) => void, onImmediateChargeChange: (immediateCharge: IImmediateChargeModel | null) => void) => {
    const { connection } = React.useContext(ConnectionContext);
    const { shoppingConnection, customerInfo, setCustomerInfo, packagesEditingStates, selectedPeriodType } = React.useContext(StoreV2Context);

    const [isCheckingTax, setIsCheckingTax] = React.useState(false);

    const checkVatNumber = React.useCallback(() => setIsCheckingTax(true), [setIsCheckingTax]);

    React.useEffect(() => {
        if (!isCheckingTax)
            return;

        checkTaxesInApi(shoppingConnection, customerInfo, packagesEditingStates, selectedPeriodType, connection)
            .then(({ resultCustomerData, salesTaxModel, immediateCharge }) => {
                setIsCheckingTax(false);
                setCustomerInfo(resultCustomerData);
                onSalesTaxChange(salesTaxModel);
                onImmediateChargeChange(immediateCharge);
            })
            .catch((err) => console.error('Unable to check vat number.', err));
    }, [isCheckingTax, shoppingConnection, customerInfo, packagesEditingStates, selectedPeriodType, onSalesTaxChange, setCustomerInfo, setIsCheckingTax, connection, onImmediateChargeChange]);

    const invokeTaxCheck = React.useCallback(() => {
        setIsCheckingTax(true);
    }, [setIsCheckingTax,]);

    // Check it on init as well.
    React.useEffect(() => checkVatNumber(), [checkVatNumber]);

    return {
        isCheckingTax,
        invokeTaxCheck
    };
};

const checkTaxesInApi = async (shoppingConnection: ShoppingConnection, customerInfo: TCustomerInfo, packagesEditingStates: TPackagesEditingStates, selectedPeriodType: TPeriodType, connection: Connection) => {
    const checkoutOrderModel = CheckoutSubmitter.createCheckoutOrderModel(
        customerInfo,
        packagesEditingStates.filter(ps => !!ps.state.currentEditQuantity && ps.state.currentEditQuantity > 0).map((item) => ({ Artno: item.artno, Amount: item.state.currentEditQuantity! })),
        null,
        null,
        null,
        selectedPeriodType,
        connection
    );
    const result = await shoppingConnection.askShoppingApi<IShoppingDatumResponse<ICheckTaxesAndChargeForecastModel>>(
        'CheckTaxesAndChargeForecast',
        {
            checkoutOrderModel: checkoutOrderModel
        }
    );

    // VAT
    const resultCustomerData: Partial<TCustomerInfo> = {};
    if (result.Datum.Vat.CurrencyCode) {
        resultCustomerData.currency = result.Datum.Vat.CurrencyCode;
    }
    resultCustomerData.willVatBeCharged = result.Datum.Vat.WillVatBeCharged;
    resultCustomerData.willVatBeShownInEula = result.Datum.Vat.WillVatBeShownInEula;

    return {
        resultCustomerData,
        salesTaxModel: result.Datum.SalesTax,
        immediateCharge: result.Datum.ImmediateCharge
    };
};

export default useCheckedTax;