import * as React from 'react';
import type { TableHeaderRow} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, VirtualTable, Table } from '@devexpress/dx-react-grid-bootstrap4';
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { FontIcon, mergeStyles } from '@fluentui/react';
import useSingleAndDoubleClick from '../helpers/hooks/useSingleAndDoubleClick';
import type { TableSelection } from '@devexpress/dx-react-grid';
import { SortableContext, sortableKeyboardCoordinates, useSortable } from '@dnd-kit/sortable';
import type { DragEndEvent} from '@dnd-kit/core';
import { closestCenter, DndContext, KeyboardSensor, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import { restrictToParentElement } from "@dnd-kit/modifiers";

class GridTable {

    static readonly createRootWithProps = (additionalProps: { id?: string }): React.FunctionComponent<Grid.RootProps> => {
        return (props) => {
            return <Grid.Root {...props} className="h-100 grid-table" id={additionalProps.id} />;
        };
    };

    static readonly Root: React.FunctionComponent<Grid.RootProps> = (props) => {
        return <Grid.Root {...props} className="h-100 grid-table" />;
    };

    static readonly RootGray: React.FunctionComponent<Grid.RootProps> = (props) => {
        return <Grid.Root {...props} className="h-100 grid-table grid-table-gray" />;
    };

    static readonly RootNoHover: React.FunctionComponent<Grid.RootProps> = (props) => {
        return <Grid.Root {...props} className="h-100 grid-table grid-table-no-hover" />;
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    static RowComponent: React.FC<TableSelection.RowProps & { onEdit?: (row: any) => void; hasError?: boolean }> = (props) => {
        const onClick = (e: React.MouseEvent) => {
            props.onToggle();
        };

        const onDoubleClick = (e: React.MouseEvent) => {
            props.onEdit && props.onEdit(props.tableRow.row);
        };

        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { handleClick, handleDoubleClick } = useSingleAndDoubleClick({ onClick, onDoubleClick, fireSingleClickImmediatelly: !props.highlighted });

        return (
            <tr onClick={handleClick} onDoubleClick={handleDoubleClick} className={mergeStyles("user-select-none", props.hasError && "row-error", props.highlighted && 'table-active')}>
                {props.children}
            </tr>
        );
    };

    static readonly createTableContainerComponent = (additionalProps: { id?: string }): React.FunctionComponent => {
        return (props) => {
            return <VirtualTable.Container {...props} className="flex-fill" id={additionalProps.id} />;
        };
    };

    static readonly TableContainerComponent: React.FunctionComponent = (props) => {
        return <VirtualTable.Container {...props} className="flex-fill" />;
    };

    static readonly SideMenuTableContainerComponent: React.ComponentType<object & { style?: React.CSSProperties }> = ({ style, ...props }) => {
        return <VirtualTable.Container {...props} style={{ ...style, overflowX: 'hidden' }} className="flex-fill" />;
    };

    static readonly SortLabel: React.FunctionComponent<TableHeaderRow.SortLabelProps> = ({ onSort, children, direction, disabled }) => {
        const onClick = disabled ? undefined : () => onSort({});
        let className;
        if (!disabled) {
            className = mergeStyles(className, 'sorting');
        }
        if (!!direction) {
            className = mergeStyles(className, 'sorting-active');
        }
        return (
            <span onClick={onClick} className={className} style={{ overflowX: "hidden" }}>
                {children}
                {direction &&
                    <>
                        &nbsp;
                        <i className={'mdl2 mdl2-' + (direction === 'asc' ? 'upload' : 'download')} />
                    </>
                }
            </span>
        );
    };

    private static readonly REORDERABLE_TABLE_BODY_MODIFIERS = [restrictToParentElement];

    static readonly ReorderableTableBodyComponent: React.FC<{ itemIds: string[]; onDragEnd: (event: DragEndEvent) => void } & Record<string, unknown>> = ({ itemIds, onDragEnd, ...restProps }) => {
        const sensors = useSensors(
            useSensor(PointerSensor),
            useSensor(KeyboardSensor, {
                coordinateGetter: sortableKeyboardCoordinates
            })
        );
    
        return (
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={onDragEnd} modifiers={this.REORDERABLE_TABLE_BODY_MODIFIERS} >
                <SortableContext items={itemIds}>
                    <Table.TableBody {...restProps} className="sortable-element-helper hide-dropdown-container" />
                </SortableContext>
            </DndContext>
        );
    };

    static readonly DragSymbol: React.FunctionComponent<React.HTMLProps<HTMLDivElement> & { rowId: string }> = ({ style, rowId, ...restProps }) => {
        const { listeners, attributes } = useSortable({ id: rowId, disabled: restProps.disabled });

        return (
            <div style={{ ...style, fontSize: '1rem' }} {...listeners} {...attributes} {...restProps}>
                <FontIcon iconName="WaffleOffice365" />
            </div>
        );
    };

    static readonly NoDataCell: React.FunctionComponent<Table.NoDataCellProps> = (props) => <td className="text-center text-muted py-5 no-data-cell" colSpan={props.colSpan}><big>{props.getMessage('noData')}</big></td>;
}

export default GridTable;