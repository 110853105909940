import * as React from 'react';
import Strings from '../../strings';
import { Modal, Button, ButtonToolbar } from 'react-bootstrap';
import type { ShoppingConnection } from '../ShoppingConnection';
import type { IOrdersHistoryModel } from '../../data/shopping/IOrdersHistoryModel';
import { SpinnerModal } from '../shared/SpinnerModal';
import { PurchaseHistoryGrid } from '../shared/purchaseHistory/PurchaseHistoryGrid';
import type { ITokenizedApiResult } from '@eway-crm/connector';
import { StripeRequiredActionHandler } from '../shared/subscriptions/StripeRequiredActionHandler';
import { StripeRequiredPaymentMethodHandler } from '../shared/subscriptions/StripeRequiredPaymentMethodHandler';
import ReactHelper from '../../helpers/ReactHelper';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TPurchaseHistoryProps = {
    shoppingConnection: ShoppingConnection;
    ordersHistoryCallback: (orderHistory: IOrdersHistoryModel) => void;
};

type TPurchaseHistoryState = {
    data: IOrdersHistoryModel | null;
    isPayingPastDue: boolean;
    pastDuePaymentResult: 'ok' | 'error' | null;
};

export class PurchaseHistory extends React.Component<TPurchaseHistoryProps, TPurchaseHistoryState> {

    constructor(props: TPurchaseHistoryProps) {
        super(props);
        this.state = {
            data: null,
            isPayingPastDue: false,
            pastDuePaymentResult: null
        };
    }

    componentDidMount() {
        this.reloadData()
            .catch((err) => console.error('Unable to reload purchase history data.', err));
    }

    private readonly reloadData = async () => {
        const ordersHistory = await this.props.shoppingConnection.loadOrdersHistory(false);
        await ReactHelper.setState(this, { data: ordersHistory });
        this.props.ordersHistoryCallback(ordersHistory);
    };

    private readonly reload = async () => {
        await ReactHelper.setState(this, { data: null, isPayingPastDue: false, pastDuePaymentResult: null });

        try {
            await this.reloadData();
        } catch(e) {
            console.error('Unable to reload purchase history data.', e);
        }
    };

    private readonly payPastDue = () => {
        if (!this.state.data || !this.state.data.PastDueSubscription) {
            return;
        }
        this.setState(
            { isPayingPastDue: true },
            () => {
                this.props.shoppingConnection.callShoppingApi(
                    'RetryChargePastdueSubscriptions',
                    {},
                    (result: ITokenizedApiResult) => {
                        this.setState({ isPayingPastDue: false, pastDuePaymentResult: "ok" });
                    },
                    (result: ITokenizedApiResult | null) => {
                        this.setState({ isPayingPastDue: false, pastDuePaymentResult: "error" });
                    }
                );
            }
        );
    };

    render() {
        const myStrings = Strings.components.routes.purchaseHistory;
        const headerComponent = (
            <>
                <h1>{myStrings.title}</h1>
                <hr className="mb-1 mt-4" />
            </>
        );

        if (!this.state.data) {
            return (
                <div className="container-fluid d-flex flex-column min-h-100">
                    <div className="row">
                        <div className="col p-0">
                            {headerComponent}
                        </div>
                    </div>
                    <Spinner backgroundOpacity={0} variant={SpinnerVariant.ease}/>
                </div>
            );
        }

        return (
            <div className="h-100">
                <div className="container-fluid d-flex flex-column min-h-100">
                    <div className="row">
                        <div className="col p-0">
                            {headerComponent}
                            <ButtonToolbar>
                                <Button variant="outline-secondary" onClick={() => void this.reload()}>
                                    <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                </Button>
                            </ButtonToolbar>
                            {(this.state.data.PastDueSubscription) &&
                                <div className="grid-table my-4 alert alert-danger">
                                    <h2>{myStrings.pastDueTitle}</h2>
                                    <p>{myStrings.pastDueDescription}</p>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>{myStrings.date}</th>
                                                <th>{myStrings.pastDueAmount}</th>
                                                <th>{myStrings.pastDueProblem}</th>
                                                <th>{myStrings.pastDueDescriptionEn}</th>
                                            </tr>
                                        </thead>
                                        {this.state.data.PastDueSubscription.FailedTransactions &&
                                            <tbody>
                                                {this.state.data.PastDueSubscription.FailedTransactions.map((failedTransaction, i) => (
                                                    <tr key={'failed-transaction-' + i}>
                                                        <td>{Strings.formatDate(failedTransaction.DateTimeString)}</td>
                                                        <td>{Strings.formatCurrency(failedTransaction.Price, 2, failedTransaction.CurrencyCode)}</td>
                                                        <td>{failedTransaction.FailStatusTitle}</td>
                                                        <td>{failedTransaction.DescriptionEn}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        }
                                    </table>
                                    <div className="d-flex justify-content-end">
                                        <Button variant="outline-danger" onClick={this.payPastDue}>{myStrings.pastDuePayNow}</Button>
                                    </div>
                                </div>
                            }

                            {(!!this.state.data.StripeSubscriptionIssue?.SubscriptionRequiresActionPaymentIntent) &&
                                <StripeRequiredActionHandler
                                    requiredActionModel={this.state.data.StripeSubscriptionIssue.SubscriptionRequiresActionPaymentIntent}
                                    shoppingConnection={this.props.shoppingConnection}
                                    onDone={this.reloadData}
                                />
                            }
                            {(!!this.state.data.StripeSubscriptionIssue?.SubscriptionRequiresPaymentMethodPaymentIntent) &&
                                <StripeRequiredPaymentMethodHandler
                                    requiredPaymentMethodModel={this.state.data.StripeSubscriptionIssue.SubscriptionRequiresPaymentMethodPaymentIntent}
                                    shoppingConnection={this.props.shoppingConnection}
                                    onDone={this.reloadData}
                                />
                            }
                        </div>
                    </div>
                    <div className="row flex-fill d-flex justify-content-start mt-2">
                        <div className="col p-0">
                            <div className="grid-table grid-table-no-hover" style={{ overflowY: 'auto', position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}>
                                <PurchaseHistoryGrid
                                    shoppingConnection={this.props.shoppingConnection}
                                    variant="full"
                                    data={this.state.data.Orders || []}
                                    paymentGateProvider={this.state.data.PaymentGateProvider}
                                    onReload={this.reload}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.isPayingPastDue &&
                    <SpinnerModal variant={SpinnerVariant.linear} />
                }

                {(!this.state.isPayingPastDue && this.state.pastDuePaymentResult) &&
                    <Modal show={true} onHide={() => void this.reload()} centered>
                        <Modal.Header closeButton>
                            <Modal.Title>{myStrings.pastDuePayNow}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {(this.state.pastDuePaymentResult === 'ok') &&
                                <p>
                                    {myStrings.pastDueSuccess}
                                </p>
                            }
                            {(this.state.pastDuePaymentResult === 'error') &&
                                <div className="alert alert-danger">
                                    {myStrings.pastDueSuccess}
                                </div>
                            }
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="primary" onClick={() => void this.reload()}>{Strings.ok}</Button>
                        </Modal.Footer>
                    </Modal>
                }
            </div>
        );
    }
}