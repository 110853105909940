import * as React from 'react';
import { Button } from 'react-bootstrap';
import Strings from '../../../strings';
import { Dialog, DialogFooter } from '@fluentui/react';

type TFolderFieldPermissionsWarningDialogProps = {
    uniqueNotFullyPermittedForGroupName: string | null;
    nonUniqueValue: string | null;
    onHide: () => void;
};

const myStrings = Strings.components.routes.fieldPermissions;

const FieldPermissionsWarningDialog: React.FC<TFolderFieldPermissionsWarningDialogProps> = ({ onHide, uniqueNotFullyPermittedForGroupName, nonUniqueValue }) => {
    const title = nonUniqueValue !== null ? myStrings.nonUniqueValueDialogTitle : myStrings.uniqueNotFullyPermittedDialogTitle;

    const getContent = () => {
        if (nonUniqueValue === null) {
            return Strings.formatString(myStrings.uniqueNotFullyPermittedDialogMessage, uniqueNotFullyPermittedForGroupName!) as string;
        } else if (nonUniqueValue === "") {
            return Strings.formatString(myStrings.nonUniqueValueDialogMessageBlank, nonUniqueValue) as string;
        } else {
            return Strings.formatString(myStrings.nonUniqueValueDialogMessage, nonUniqueValue) as string;
        }
    };

    return (
        <Dialog
            maxWidth={430}
            modalProps={{ isBlocking: true }}
            dialogContentProps={{
                showCloseButton: false,
                isMultiline: true,
                title: title,
            }}
            hidden={false}
        >
            <div style={{ fontSize: '0.75rem', whiteSpace: 'pre-wrap' }}>{getContent()}</div>
            <DialogFooter>
                <Button variant="primary" onClick={onHide}>
                    {Strings.ok}
                </Button>
            </DialogFooter>
        </Dialog>
    );
};

export default FieldPermissionsWarningDialog;