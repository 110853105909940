import type { CreatePaymentMethodCardData, PaymentIntentResult, Stripe } from '@stripe/stripe-js';
import * as React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import type { IStripeDataModel } from '../../../data/shopping/IStripeDataModel';
import Strings from '../../../strings';
import type { TStripeDropinSurroundingComponentProps } from '../../shared/paymentGate/StripeDropin';
import { StripeDropin } from '../../shared/paymentGate/StripeDropin';
import type { TStripeInitData } from '../../shared/paymentGate/StripeElementsContainer';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { ShoppingConnection } from '../../ShoppingConnection';
import WizardModal from '../../WizardModal';
import { SpinnerVariant } from '@eway-crm/gui';

type TStripeRequiredPaymentMethodHandlerProps = {
    shoppingConnection: ShoppingConnection;
    requiredPaymentMethodModel: IStripeDataModel;
    onDone: () => Promise<void>;
};

export const StripeRequiredPaymentMethodHandler: React.FC<TStripeRequiredPaymentMethodHandlerProps> = (props) => {
    const { requiredPaymentMethodModel, shoppingConnection, onDone } = props;
    const initData = React.useMemo((): TStripeInitData => ({ publicApiKey: requiredPaymentMethodModel.PublicApiKey, clientSecret: requiredPaymentMethodModel.ClientSecret }), [requiredPaymentMethodModel]);
    const existingCardInitDataGetter = React.useCallback((chosenId: string): TStripeInitData => initData, [initData]);

    const submit = React.useCallback((stripe: Stripe, clientSecret: string, paymentMethod: string | Omit<CreatePaymentMethodCardData, 'type'>): Promise<PaymentIntentResult> => {
        return (async () => {
            const isNewCard = typeof paymentMethod !== 'string';

            const result = await stripe.confirmCardPayment(clientSecret, {
                payment_method: paymentMethod,
                setup_future_usage: isNewCard ? 'off_session' : undefined
            });

            if (result.error) {
                console.error('Stripe paymentconfirming failed.', result.error);
                return result;
            }

            if (result.paymentIntent?.payment_method) {
                await shoppingConnection.askShoppingApi('SetStripeDefaultPaymentMethod', { paymentMethodId: result.paymentIntent?.payment_method });
            }

            await shoppingConnection.askShoppingApi('RetryChargePastdueSubscriptions', {});
            window.setTimeout(() => void onDone(), 10);

            return result;
        })();
    }, [shoppingConnection, onDone]);

    return (
        <StripeDropin
            shoppingConnection={props.shoppingConnection}
            loadingComponent={<SpinnerModal variant={SpinnerVariant.ease} />}
            existingCardInitDataGetter={existingCardInitDataGetter}
            onExistingCardSubmit={submit}
            newCardInitData={initData}
            onNewCardSubmit={submit}
            as={PayerModal}
        />
    );
};


const PayerModal: React.FC<TStripeDropinSurroundingComponentProps> = (props) => {
    const { isProcessing } = props;

    const myStrings = Strings.components.routes.subscriptions;
    return (
        <WizardModal show={true} size="lg">
            <WizardModal.Body>
                <h3 className="mb-3">{myStrings.stripeSubscriptionPaymentMethodRequestedTitle}</h3>
                <div className="mb-4">{myStrings.stripeSubscriptionPaymentMethodRequestedMessage}</div>
                {props.children}
            </WizardModal.Body>
            <WizardModal.Footer>
                <Button variant="primary" onClick={props.onSubmit} disabled={isProcessing || props.isSubmitDisabled}>
                    {(!!isProcessing) &&
                        <Spinner animation="border" size="sm" className="mr-2" />
                    }
                    {myStrings.tryAgain}
                </Button>
            </WizardModal.Footer>
        </WizardModal>
    );
};