import type { IPalette} from '@fluentui/react';
import { BaseSlots, createTheme, DefaultButton, Dialog, DialogFooter, DialogType, NeutralColors, PrimaryButton, SharedColors, ThemeGenerator, themeRulesStandardCreator } from '@fluentui/react';
import * as React from 'react';
import Strings from '../../../../../strings';
import { SpinnerVariant } from '@eway-crm/gui';
import { SpinnerModal } from '../../../../shared/SpinnerModal';
import StoreV2Context from '../StoreV2Context';

const redTheme = (() => {
    const themeRules = themeRulesStandardCreator();

    const colors = {
        primaryColor: SharedColors.red10,
        backgroundColor: NeutralColors.white,
        textColor: SharedColors.red20,
    };
    ThemeGenerator.insureSlots(themeRules, false);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.primaryColor]], colors.primaryColor, false, false, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.backgroundColor]], colors.backgroundColor, false, false, true);
    ThemeGenerator.setSlot(themeRules[BaseSlots[BaseSlots.foregroundColor]], colors.textColor, undefined, false, false);

    const themeAsJson = ThemeGenerator.getThemeAsJson(themeRules) as IPalette;

    return createTheme({
        ...{ palette: themeAsJson },
    });
})();

const AccountClosingButton: React.FC = () => {
    const { shoppingConnection, onStoreDataChanged } = React.useContext(StoreV2Context);
    const [isConfirming, setIsConfirming] = React.useState(false);
    const [isWorking, setIsWorking] = React.useState(false);

    React.useEffect(() => {
        if (!isWorking)
            return;

        (
            async () => {
                await shoppingConnection.askShoppingApi('CloseAccount', {});
                void onStoreDataChanged();
            }
        )().catch(error => console.error('Unable to close account.', error));
    }, [isWorking, shoppingConnection, onStoreDataChanged]);

    const myStrings = Strings.components.routes.subscriptions;

    return (
        <>
            <DefaultButton text={myStrings.closeAccount} styles={{ label: { color: SharedColors.red20 } }} onClick={() => setIsConfirming(true)} />
            <Dialog
                hidden={!isConfirming || isWorking}
                onDismiss={() => setIsConfirming(false)}
                dialogContentProps={{
                    type: DialogType.normal,
                    title: myStrings.closeAccountDialogTitle,
                    closeButtonAriaLabel: 'Close',
                    subText: myStrings.closeAccountDialogMessage,
                }}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => { setIsConfirming(false); setIsWorking(true); }} text={myStrings.closeAccount} theme={redTheme} />
                    <DefaultButton onClick={() => setIsConfirming(false)} text={Strings.cancel} autoFocus />
                </DialogFooter>
            </Dialog>
            {(isWorking) &&
                <SpinnerModal variant={SpinnerVariant.linear} />
            }
        </>
    );
};

export default AccountClosingButton;