import { usePropagateMouseEventToIframe } from '@eway-crm/gui';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import * as React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import type { ICustomerInfoModel } from '../../../../data/shopping/ICustomerInfoModel';
import type { TPaymentGateProvider } from '../../../../data/shopping/IPaymentMethodsModel';
import type { ICurrentPlanModel, IStoreModel } from "../../../../data/shopping/ISubscriptionsShoppingModel";
import RouteConfig from '../../../../RouteConfig';
import type { ShoppingConnection } from '../../../ShoppingConnection';
import type { TCountriesAndRegions } from '../TCountriesAndRegions';
import type { TCustomerInfo } from '../TCustomerInfo';
import { convertToCustomerInfo } from '../TCustomerInfo';
import AccountClosingButton from './AccountClosingButton/AccountClosingButton';
import CheckoutV2Modal from './CheckoutV2/CheckoutV2Modal';
import useAddToCartFromUrlEffect from './hooks/useAddToCartFromUrlEffect';
import useSubscriptionEditing from './hooks/useSubscriptionEditing';
import StoreV2Context from './StoreV2Context';
import StoreV2Row from './StoreV2Row';
import Summary from './Summary/Summary';

const css = mergeStyleSets({
    absoluteParent: {
        top: '0',
        bottom: '0',
        left: '0',
        right: '0'
    },
    rows: {
        selector: {
            '& .buttons-row .button-container-in-row:not(:last-child) .button-in-row': {
                borderTopRightRadius: '0',
                borderBottomRightRadius: '0'
            },
            '& .buttons-row .button-container-in-row:not(:first-child):not(:only-child) .button-in-row': {
                borderLeftWidth: '0',
                borderTopLeftRadius: '0',
                borderBottomLeftRadius: '0'
            }
        }
    }
});

type TStoreV2Props = {
    shoppingConnection: ShoppingConnection;
    storeData: IStoreModel;
    customerInfo: ICustomerInfoModel;
    paymentGateProvider: TPaymentGateProvider;
    currentDbSizeGigabytes: number;
    activeUsersCount: number;
    countries: TCountriesAndRegions;
    currentPlan: ICurrentPlanModel | null;
    onStoreDataChanged: () => Promise<void>;
    isAfterBuy: false | { isAnythingNew: boolean };
    isCloseAccountAllowed: boolean;
    collectPersonName: boolean;
};

const StoreV2: React.FC<TStoreV2Props> = ({
    shoppingConnection,
    storeData,
    customerInfo: initialCustomerInfo,
    paymentGateProvider,
    currentDbSizeGigabytes,
    activeUsersCount,
    countries,
    currentPlan,
    onStoreDataChanged,
    isAfterBuy,
    isCloseAccountAllowed,
    collectPersonName
}) => {
    usePropagateMouseEventToIframe();

    React.useEffect(() => {
        if (window.eway) {
            if (isAfterBuy) {
                // After buy means shopping was completed.
                window.eway.onShoppingDone();
            }
        }
    }, [isAfterBuy]);

    const {
        defaultPackagesStates,
        isAnythingAlreadySubscribed,
        artnoToFeatureMap,
        ekArtnos,
        featureToPakagesMap,
        packagesEditingStates,
        revertToDefaultPackagesStates,
        setMultiplePackagesEditingStates,
        setPackageEditingState,
        isPackageInCart,
        periodType,
        setPeriodType
    } = useSubscriptionEditing(storeData, currentPlan, activeUsersCount);

    const [customerInfo, setCustomerInfoState] = React.useState(convertToCustomerInfo(initialCustomerInfo));
    const setCustomerInfo = React.useCallback((partialState: Partial<TCustomerInfo>) => {
        const resultState: TCustomerInfo = { ...customerInfo, ...partialState };
        setCustomerInfoState(resultState);
    }, [customerInfo, setCustomerInfoState]);

    const [isCheckingOut, setIsCheckingOut] = React.useState(!!isAfterBuy); // Open checkout modal when isAfterBuy

    // Add packages to cart if requested by URL params
    const location = useLocation();
    const history = useHistory();
    useAddToCartFromUrlEffect(
        location,
        storeData,
        activeUsersCount,
        featureToPakagesMap,
        defaultPackagesStates,
        setMultiplePackagesEditingStates,
        setIsCheckingOut
    );

    const storeContext = React.useMemo(() => ({
        shoppingConnection,
        storeData,
        countries,
        customerInfo,
        setCustomerInfo,
        paymentGateProvider,
        packagesEditingStates,
        setPackageEditingState,
        setMultiplePackagesEditingStates,
        currentDbSizeGigabytes,
        activeUsersCount,
        isAnythingAlreadySubscribed,
        currentPlan,
        onStoreDataChanged,
        isPackageInCart,
        revertToDefaultPackagesStates,
        selectedPeriodType: periodType,
        setSelectedPeriodType: setPeriodType,
        collectPersonName
    }), [
        shoppingConnection,
        storeData,
        countries,
        customerInfo,
        setCustomerInfo,
        paymentGateProvider,
        packagesEditingStates,
        setPackageEditingState,
        setMultiplePackagesEditingStates,
        currentDbSizeGigabytes,
        activeUsersCount,
        isAnythingAlreadySubscribed,
        currentPlan,
        onStoreDataChanged,
        isPackageInCart,
        revertToDefaultPackagesStates,
        periodType,
        setPeriodType,
        collectPersonName
    ]);

    const handleCheckoutDismiss = React.useCallback(() => {
        setIsCheckingOut(false);

        if (isAfterBuy) {
            // Remove url param
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(RouteConfig.billing.subscriptions.actions.urlParamName);
            history.replace({ search: queryParams.toString() });
        }
    }, [setIsCheckingOut, isAfterBuy, history, location.search]);

    return (
        <StoreV2Context.Provider value={storeContext}>
            <div className="position-relative h-100">
                <div className={mergeStyles('overflow-auto position-absolute', css.absoluteParent)}>
                    <div className="container mx-0">
                        <div className="row">
                            <div className={mergeStyles('col-auto pl-0 pr-4 mr-4', css.rows)}>
                                {(!!storeData.Rows) &&
                                    storeData.Rows.map((row, i) => (
                                        <StoreV2Row key={`${row.Title?.En}-${i}`} row={row} artnoToFeatureMap={artnoToFeatureMap} ekArtnos={ekArtnos} />
                                    ))
                                }
                            </div>
                            <div className="col pl-0 pr-0">
                                <Summary onCheckout={React.useCallback(() => setIsCheckingOut(true), [setIsCheckingOut])} />
                            </div>
                        </div>
                    </div>
                    {(isCloseAccountAllowed) &&
                        <div>
                            <AccountClosingButton />
                        </div>
                    }
                </div>
            </div>
            {isCheckingOut && <CheckoutV2Modal onDismiss={handleCheckoutDismiss} isAfterBuy={isAfterBuy} />}
        </StoreV2Context.Provider>
    );
};


export default StoreV2;