import 'unorm';

class StringHelper {

    static compare(a: string | null | undefined, b: string | null | undefined): -1 | 0 | 1 {
        return ((a === b) ? 0 : (((a ?? '') > (b ?? '')) ? 1 : -1));
    }

    static localeCompare(a: string | null | undefined, b: string | null | undefined): number {
        return (a ?? '').localeCompare((b ?? ''));
    }

    static compareIgnoringCase(a: string | null | undefined, b: string | null | undefined): number {
        return (a ?? '').localeCompare((b ?? ''), undefined, { sensitivity: 'accent' });
    }

    static removeAccents(s: string | null | undefined): string | null {
        if (!s)
            return null;

        return s.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    }

    static escapeRegExp(string: string): string {
        return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
    }

    static emptyIfNull(string: string | null | undefined): string {
        if (!string)
            return '';

        return string;
    }

    static split = (string: string | null | undefined, delimiter: string) => {
        if (!string) {
            return [];
        }
        return string.split(delimiter).filter(item => !!item).map(item => item.trim());
    };

    static capitalize = (string: string | null | undefined) => {
        if (!string) {
            return null;
        }
        const [first, ...rest] = string;
        return first.toLocaleUpperCase() + rest.join("");
    };
}

export default StringHelper;