import { saveAs } from 'file-saver';
import type ExcelJS from 'exceljs';
import { format } from 'date-fns';

export default class ExcelHelper {
    static exportToExcel = (workbook: ExcelJS.Workbook, fileName: string, callback?: () => void) => {
        workbook.xlsx.writeBuffer()
            .then((buffer: ExcelJS.Buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `eWay-CRM_${fileName}_${format(new Date(), "yyyy-MM-dd")}.xlsx`);
                callback && callback();
            })
            .catch((err) => console.error('Unable to write xlsx buffer.', err));
    };

    /**
     * Remove invalid Excel sheet name characters  ```\, /, ?, *, :, [, ]```
     * https://stackoverflow.com/a/62303903/14334319
     * @param string Sheet name
     */
    static removeInvalidSheetNameChars(string: string): string {
        return string.replace(/[\\/?*[\]:]/g, '');
    }

    /**
     * Removes invalid Excel sheet name characters and trims name to 31 characters
     * @param string Sheet name
     */
    static prepareSheetName = (string: string) => {
        return ExcelHelper.removeInvalidSheetNameChars(string).slice(0, 31);
    };

    /**
     * Styled header row
     * @param worksheet current worksheet
     * @param name text displayed in header row
     */
    static insertHeaderRow = (worksheet: ExcelJS.Worksheet, name: string) => {
        const headerRow = worksheet.addRow([name]);
        headerRow.height = 20;
        headerRow.font = { bold: true, size: 16 };
    };

    /**
     * Styled column title row
     * @param worksheet current worksheet
     * @param columnTitles names of columns
     */
    static insetColumnTitleRow = (worksheet: ExcelJS.Worksheet, columnTitles: string[]) => {
        const titleRow = worksheet.addRow(columnTitles);
        titleRow.eachCell((cell) => {
            cell.border = {
                bottom: { style: 'thin' },
            };
        });
    };
}
