import { mergeStyleSets } from '@fluentui/react';

export const css = mergeStyleSets({
    spinnerContainer: {
        textAlign: 'center',
        position: 'relative',
        marginTop: '2rem'
    },
    spinner: {
        width: '5rem',
        height: '5rem',
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    gridContainerCollapsed: {
        height: '360px'
    },
    gridContainerExpanded: {},
    moreContainer: {
        textAlign: 'center'
    }
});