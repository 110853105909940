import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col, Badge } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiDataResponse, IApiEnumType, IApiEnumValue, IApiResult, IApiSaveResponse, IApiWorkflowModel, TFolderName } from '@eway-crm/connector';
import memoizeOne from 'memoize-one';
import StringHelper from '../../../helpers/StringHelper';
import ReactHelper from '../../../helpers/ReactHelper';
import Autosuggest from '../../shared/Autosuggest/Autosuggest';
import AutosuggestInput from '../../shared/Autosuggest/AutosuggestInput';
import AutosuggestItems from '../../shared/Autosuggest/AutosuggestItems';
import { AutosuggestContextConsumer } from '../../shared/Autosuggest/AutosuggestContext';
import type { TEnumValueWithWfModel } from './WorkflowModelContainer';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { LicenseRestrictionsModalVariant } from '@eway-crm/gui';
import { SpinnerVariant } from '@eway-crm/gui';

type DuplicateWorkflowWizardProps = {
    onDismiss: () => void;
    itemTypes: TEnumValueWithWfModel[];
    sourceEnumValue: TEnumValueWithWfModel;
    onDone: () => void;
    folderName: TFolderName;
    enumType: IApiEnumType;
};

type DuplicateWorkflowWizardState = {
    targetWorkflowName: string;
    assignUsers: boolean;
    copyModulePermissions: boolean;
    copyFieldPermissions: boolean;
    isSavingInProgress: boolean;
    invalidNameMessage: string | null;
    nameAlreadyExistsWarning: string | null;
    isFormValidated: boolean;
};

const myStrings = Strings.components.routes.workflow;

class DuplicateWorkflowWizard extends React.Component<DuplicateWorkflowWizardProps, DuplicateWorkflowWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: DuplicateWorkflowWizardProps) {
        super(props);
        this.state = {
            targetWorkflowName: '',
            assignUsers: true,
            copyModulePermissions: false,
            copyFieldPermissions: false,
            isSavingInProgress: false,
            invalidNameMessage: null,
            nameAlreadyExistsWarning: null,
            isFormValidated: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly loadSourceEnumTypeEnumValuesToCopy = async (itemGuid: string) => {
        const { askApi } = this.context.connection;

        let sourceEnumTypeEnumValues: Partial<IApiEnumValue>[] = [];
        const sourceEnumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('GetEnumTypesByItemGuids', { itemGuids: [itemGuid], includeEnumValuesAllActionEvents: true });
        const sourceEnumType = sourceEnumTypeRes.Data[0];
        if (sourceEnumType.EnumValuesInEnumType) {
            sourceEnumTypeEnumValues = sourceEnumType.EnumValuesInEnumType.map((ev) => ({
                En: ev.En,
                Cs: ev.Cs,
                De: ev.De,
                Ru: ev.Ru,
                No: ev.No,
                Sk: ev.Sk,
                Rank: ev.Rank,
                FileAs: ev.FileAs,
                IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
                IsDefault: ev.IsDefault,
                IsVisible: ev.IsVisible,
                IsSystem: ev.IsSystem,
                AllActionEvents: ev.AllActionEvents,
            }));
        }

        return sourceEnumTypeEnumValues;
    };

    private readonly createNewEnumValue = async (enumValueName: string) => {
        const transmitEnumValueObject = {
            FileAs: enumValueName,
            Cs: enumValueName,
            De: enumValueName,
            En: enumValueName,
            EnumType: this.props.enumType.ItemGUID,
            EnumTypeName: enumValueName,
            IncludeInLastActivityCalculation: false,
            IsDefault: false,
            IsSystem: false,
            IsVisible: true,
            CanBeDeleted: true,
            No: enumValueName,
            Rank: (this.props.enumType.EnumValuesInEnumType?.length ?? 0) + 1,
            Ru: enumValueName,
            Sk: enumValueName,
        };

        const saveEnumValueRes = await this.context.connection.askApi<IApiSaveResponse>('SaveEnumValue', { transmitObject: transmitEnumValueObject });
        return saveEnumValueRes.Guid;
    };

    private readonly submit = async () => {
        const { askApi } = this.context.connection;
        if (!this.state.targetWorkflowName || this.state.invalidNameMessage || !this.props.sourceEnumValue.wfModel) {
            await ReactHelper.setState(this, { isFormValidated: true });
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        let existingTargetItemTypeGuid = this.getExistingEnumValueGuid(this.state.targetWorkflowName);
        if (!existingTargetItemTypeGuid) {
            // Target is new and doesn't exist yet

            const isVisibleCount = this.props.enumType.EnumValuesInEnumType?.filter(ev => ev.IsVisible).length;
            const { isLimitReached, licenseRestriction } = this.context.licenseRestrictionsHelper.isItemTypesCountExceeded(this.props.folderName, isVisibleCount);

            if (isLimitReached) {
                this.context.showLicenseRestrictionModal(licenseRestriction, { variant: LicenseRestrictionsModalVariant.workflow });
                await ReactHelper.setState(this, { isSavingInProgress: false });
                return;
            }

            existingTargetItemTypeGuid = await this.createNewEnumValue(this.state.targetWorkflowName);
        } else {
            // Delete old WF Model if it exists
            const existingTargetItemTypeValue = this.props.itemTypes.find((ev) => ev.ItemGUID === existingTargetItemTypeGuid);
            if (existingTargetItemTypeValue?.wfModel) {
                await askApi<IApiResult>('DeleteWorkflowModel', { itemGuid: existingTargetItemTypeValue.wfModel.ItemGUID });
            }
        }

        // Create new WF Model
        const { Guid: modelGuid } = await askApi<IApiSaveResponse>('SaveWorkflowModel', {
            transmitObject: {
                ParentEn: existingTargetItemTypeGuid,
                IsVisible: true,
                IsUsingFlows: false,
            },
        });

        const workflowModelRes = await askApi<IApiDataResponse<IApiWorkflowModel>>('GetWorkflowModelsByItemGuids', { itemGuids: [modelGuid] });
        const sourceEnumTypeEnumValuesToCopy = await this.loadSourceEnumTypeEnumValuesToCopy(this.props.sourceEnumValue.wfModel.EnumTypeGuid);
        await askApi<IApiSaveResponse>('SaveEnumType', {
            transmitObject: {
                ItemGUID: workflowModelRes.Data[0].EnumTypeGuid,
                EnumValuesInEnumType: sourceEnumTypeEnumValuesToCopy,
            },
        });

        this.props.onDone();
    };

    private readonly isTargetEnumValueDuplicable = (ev: TEnumValueWithWfModel, sourceEnumValue: TEnumValueWithWfModel) =>
        !ev.IsSystem && ev.CanBeDeleted && !ev.wfModel?.IsUsingFlows && ev.ItemGUID !== sourceEnumValue.ItemGUID;

    private readonly getSuggestions = memoizeOne((enumValues: TEnumValueWithWfModel[], sourceEnumValue: TEnumValueWithWfModel) => {
        return enumValues.filter((ev) => this.isTargetEnumValueDuplicable(ev, sourceEnumValue)).map((ev) => ({ key: ev.ItemGUID, value: Strings.pickTranslation(ev) as string }));
    });

    private readonly getExistingEnumValueGuid = (value: string) => {
        if (!value) {
            return null;
        }

        const map = EnumValuesValidator.getInvalidRowsAndColumns([
            {
                ItemGUID: 'new',
                FileAs: value,
                En: value,
                Cs: value,
                De: value,
                Ru: value,
                No: value,
                Sk: value,
            },
            ...this.props.itemTypes,
        ]);

        if (!map.size) {
            return null;
        }

        return map.keys().next().value as string;
    };

    private readonly onValueChange = (value: string) => {
        const existingItemGuid = this.getExistingEnumValueGuid(value);
        let errorMessage: string | null = null;
        let alreadyExistingName: string | null = null;

        if (existingItemGuid) {
            const enumVal = this.props.itemTypes.find((ev) => ev.ItemGUID === existingItemGuid);

            if (enumVal) {
                if (!this.isTargetEnumValueDuplicable(enumVal, this.props.sourceEnumValue)) {
                    errorMessage = myStrings.wfCannotBeTargetOfDuplication;
                }

                const stageName = StringHelper.capitalize(Strings.getLanguage())!;
                const stageNameTranslation = enumVal[stageName as keyof IApiEnumValue] as string;
                if (stageNameTranslation.toLowerCase() !== value.toLowerCase()) {
                    alreadyExistingName = stageNameTranslation;
                }
            }
        }

        this.setState({
            targetWorkflowName: value,
            invalidNameMessage: errorMessage,
            nameAlreadyExistsWarning: alreadyExistingName ? (Strings.formatString(myStrings.wfNameAlreadyExistsInTranslation, alreadyExistingName) as string) : null,
        });
    };

    private readonly getIsValueInSuggestions = (value: string) => {
        return this.getSuggestions(this.props.itemTypes, this.props.sourceEnumValue).some((ev) => ev.value.toLowerCase() === value.toLowerCase());
    };

    render() {
        const suggestions = this.getSuggestions(this.props.itemTypes, this.props.sourceEnumValue);
        const isValueInSuggestions = this.getIsValueInSuggestions(this.state.targetWorkflowName);
        const isSubmitDisabled = !!this.state.invalidNameMessage || !this.state.targetWorkflowName;

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.duplicateWorkflow}</h1>
                        <div className="mb-4">
                            <span className="badge badge-info badge-full-font mr-1">{Strings.pickTranslation(this.props.sourceEnumValue)}</span>
                        </div>
                        <div>
                            <Form className="form-application" onSubmit={(e) => e.preventDefault()}>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="duplGrpName" className="mb-2">
                                            <Form.Label className="mb-1">{myStrings.targetWorkflow}</Form.Label>
                                            <Autosuggest
                                                allowCreatingNewItems
                                                showChevron
                                                suggestions={suggestions}
                                                onValueChange={this.onValueChange}
                                                onValueSelection={this.onValueChange}
                                                value={this.state.targetWorkflowName}
                                                invalid={!!this.state.invalidNameMessage}
                                            >
                                                <>
                                                    <AutosuggestInput />
                                                    <AutosuggestContextConsumer>
                                                        {({ isInputFocused }) => (
                                                            <>
                                                                {!isInputFocused &&
                                                                    !!this.state.targetWorkflowName &&
                                                                    !isValueInSuggestions &&
                                                                    !this.state.nameAlreadyExistsWarning &&
                                                                    !this.state.invalidNameMessage && (
                                                                        <Badge variant="success mx-1" style={{ fontSize: '0.75rem' }}>
                                                                            {myStrings.newWorkflowBadge}
                                                                        </Badge>
                                                                    )}
                                                            </>
                                                        )}
                                                    </AutosuggestContextConsumer>
                                                    <AutosuggestItems />
                                                </>
                                            </Autosuggest>
                                            {this.state.invalidNameMessage && (
                                                <div className="text-danger" style={{ height: '0.85rem' }}>
                                                    {this.state.invalidNameMessage}
                                                </div>
                                            )}
                                            {this.state.nameAlreadyExistsWarning && !this.state.invalidNameMessage && (
                                                <div className="text-warning" style={{ height: '0.85rem' }}>
                                                    {this.state.nameAlreadyExistsWarning}
                                                </div>
                                            )}
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={() => void this.submit()} disabled={isSubmitDisabled}>
                            {Strings.ok}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}

export default DuplicateWorkflowWizard;
