import * as React from 'react';
import Strings from './../../../strings';
import { Form, Col } from 'react-bootstrap';
import type { TFormDropdownOption } from '../../shared/forms/FormDropdown';
import { FormDropdown } from '../../shared/forms/FormDropdown';
import FolderNames from '../../../data/constants/FolderNames';
import StringHelper from '../../../helpers/StringHelper';
import type { IApiColumn, TNumericValidatorType } from '@eway-crm/connector';
import { EnumTypeEditMode } from '@eway-crm/connector';
import { AdditionalFieldTypes } from '../../../data/constants/AdditionalFieldTypes';
import NumericSpinner from '../../shared/forms/NumericSpinner';
import { SummaryTypes } from '../../../data/constants/SummaryTypes';
import { ValidatorTypes } from '../../../data/constants/ValidatorTypes';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import type { TEnumValue } from './../../shared/dropDowns/EnumValuesEditGrid';
import EnumValuesEditGrid from './../../shared/dropDowns/EnumValuesEditGrid';
import { LinkTypes } from '../../../data/constants/LinkTypes';
import type { TUsedFieldsMap } from './ForbiddenFieldNameDetector';
import { ForbiddenFieldNameDetector } from './ForbiddenFieldNameDetector';
import { NewAdditionalFieldWizard } from './NewAdditionalFieldWizard';
import type { TFieldPermissionWithCol } from './FieldPermissionsGrid';
import { DelayedRender, mergeStyles } from '@fluentui/react';
import MultiselectDropdown from '../../shared/MultiselectDropdown';
import GeneralFieldPermissionToggles from './GeneralFieldPermissionToggles';
import type { OptionalExceptFor } from '../../../data/typeHelpers/OptionalExceptFor';
import type { TFieldWithCol } from './FolderFields';
import type { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';

type TFieldGeneralFormProps = {
    isGeneralFormValidated: boolean;
    availableFolderNames: string[];
    linkableFolderNames: string[];
    customizableFolderNames?: string[];
    isNew: boolean;
    itemGuid: string | null;
    name: string;
    setName: (name: string) => void;
    type: number;
    setType?: (type: number) => void;
    folderNames: string[];
    setFolderNames?: (folderNames: string[]) => void;
    comment: string;
    setComment: (comment: string) => void;
    editMask: string;
    setEditMask: (editMask: string) => void;
    linkType: string;
    setLinkType: (linkType: string) => void;
    numberOfRows: number;
    setNumberOfRows: (numberOfRows: number) => void;
    numberFormat: string;
    setNumberFormat: (numberFormat: TNumericValidatorType) => void;
    summaryType: string;
    setSummaryType: (summaryType: string) => void;
    showTime: boolean;
    setShowTime: (showTime: boolean) => void;
    relatedFolderName: string;
    setRelatedFolderName?: (relatedFolderName: string) => void;
    isRelatedFolderDisabled: boolean;
    enumTypeName: string;
    enumValues: Partial<TEnumValue>[];
    setEnumValues: (enumValues: Partial<TEnumValue>[], callback?: () => void) => void;
    areSystemPermissionsDisabled: boolean;
    systemGroupFieldPermissions: OptionalExceptFor<TFieldPermissionWithCol, 'MandatoryRule' | 'PermissionRule' | 'IsMandatoryRuleEditable' | 'IsPermissionRuleEditable'>;
    onSystemGroupChange: (permissionName: string) => void;
    allColumns: IApiColumn[];
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumns: (language: string) => Promise<void>;
    allFields: TFieldWithCol[];
    folderName: string;
    enumValuesEditGridRef: React.RefObject<typeof VirtualTable>;
};

type TFieldGeneralFormState = {
    usedFieldNames: TUsedFieldsMap | null;
};

const myStrings = Strings.components.routes.fields;

export class FieldGeneralForm extends React.Component<TFieldGeneralFormProps, TFieldGeneralFormState> {
    private readonly generalFormReference: React.RefObject<HTMLFormElement>;

    private readonly typeOptions: TFormDropdownOption[];
    private readonly folderNameOptions: TFormDropdownOption[];
    private readonly summaryTypeOptions: TFormDropdownOption[];
    private readonly numberFormatOptions: TFormDropdownOption[];
    private readonly relatedFolderNameOptions: TFormDropdownOption[];
    private readonly linkTypeOptions: TFormDropdownOption[];

    constructor(props: TFieldGeneralFormProps) {
        super(props);
        if (props.availableFolderNames.length === 0) {
            throw new Error('No available folder names supplied.');
        }

        if (props.linkableFolderNames.length === 0) {
            throw new Error('No linkable folder names supplied.');
        }

        this.generalFormReference = React.createRef();

        this.typeOptions = AdditionalFieldTypes.order.map((afType) => {
            const af = AdditionalFieldTypes.all[afType as keyof typeof AdditionalFieldTypes.all];
            return {
                title: af.localizedName,
                value: afType.toString(),
            };
        });
        this.folderNameOptions = props.availableFolderNames
            .map((folderName: string): TFormDropdownOption => ({ title: FolderNames.getPluralName(folderName), value: folderName, disabled: !props.customizableFolderNames?.includes(folderName) }))
            .sort((a, b) => StringHelper.localeCompare(a.title, b.title));
        this.summaryTypeOptions = SummaryTypes.all.map((st) => ({ title: SummaryTypes.getLocalizedName(st), value: st }));
        this.numberFormatOptions = ValidatorTypes.all.map((vt) => ({ title: ValidatorTypes.getLocalizedName(vt), value: vt }));
        this.relatedFolderNameOptions = props.linkableFolderNames
            .map((folderName: string): TFormDropdownOption => ({ title: FolderNames.getPluralName(folderName), value: folderName }))
            .sort((a, b) => StringHelper.localeCompare(a.title, b.title));
        this.linkTypeOptions = LinkTypes.all.map((lt) => ({ title: LinkTypes.getLocalizedName(lt), value: lt }));

        this.state = {
            usedFieldNames: ForbiddenFieldNameDetector.getUsedFieldNames(props.allColumns),
        };
    }

    readonly isFormValid = () => {
        return (!this.generalFormReference.current || this.generalFormReference.current.checkValidity() === true) && this.state.usedFieldNames !== null && !this.getCollisionFolderName();
    };

    private readonly getCollisionFolderName = () => {
        if (this.props.name && this.state.usedFieldNames !== null) {
            return ForbiddenFieldNameDetector.getNameCollisionOrigin(this.state.usedFieldNames, this.props.name, this.props.folderNames, this.props.isNew, this.props.itemGuid);
        }
        return null;
    };

    render() {
        const offeredFolderNameOptions = this.folderNameOptions.filter((opt) => this.props.folderNames.findIndex((fn) => fn === opt.value) === -1);
        const nameCollisionFolderName = this.getCollisionFolderName();
        const isTypeAndModuleValid = NewAdditionalFieldWizard.isTypeAndModuleValid(this.props.folderNames, this.props.type);

        return (
            <Form ref={this.generalFormReference} validated={this.props.isGeneralFormValidated} className="form-application">
                <Form.Row>
                    <Col sm={7} className="pr-4">
                        <Form.Group>
                            <Form.Label>{myStrings.name}</Form.Label>
                            <Form.Control type="text" required value={this.props.name} onChange={(e) => this.props.setName(e.target.value)} />
                        </Form.Group>
                        {!!nameCollisionFolderName && (
                            <div className="alert alert-danger">
                                {Strings.formatString(
                                    nameCollisionFolderName.isAdditionalField ? myStrings.fieldNameAlreadyUsedFormat : myStrings.fieldNameReservedFormat,
                                    FolderNames.getPluralName(nameCollisionFolderName.folderName)
                                )}
                            </div>
                        )}
                        {this.props.isNew && (
                            <Form.Row>
                                <Form.Group as={Col}>
                                    <Form.Label>{myStrings.modules}</Form.Label>
                                    <div className="d-flex flex-row flex-wrap">
                                        <MultiselectDropdown
                                            options={offeredFolderNameOptions}
                                            noOptionPickedTitle={myStrings.noModule}
                                            pickedOptions={this.props.folderNames.map((fn) => this.folderNameOptions.find((fno) => fno.value === fn)!)}
                                            onValueChange={(newPickedOptions) => {
                                                this.props.setFolderNames && this.props.setFolderNames(newPickedOptions.map((o) => o.value));
                                            }}
                                            isInvalid={this.props.folderNames.length === 0}
                                        />
                                    </div>
                                </Form.Group>
                            </Form.Row>
                        )}
                        {!isTypeAndModuleValid && <div className="alert alert-danger">{myStrings.typeAndModuleAreInvalid}</div>}
                        <Form.Group>
                            <Form.Label>{myStrings.description}</Form.Label>
                            <Form.Control type="text" value={this.props.comment} onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.setComment(e.target.value)} />
                        </Form.Group>
                    </Col>
                    <Col sm={5} className="pl-4">
                        {this.props.isNew && (
                            <Col>
                                <Form.Group>
                                    <Form.Label>{myStrings.type}</Form.Label>
                                    <FormDropdown options={this.typeOptions} value={this.props.type.toString()} onValueChange={(val) => this.props.setType && this.props.setType(Number(val))} />
                                </Form.Group>
                            </Col>
                        )}
                        <div style={{ paddingLeft: '15px' }}>
                            <GeneralFieldPermissionToggles
                                systemGroupFieldPermissions={this.props.systemGroupFieldPermissions}
                                onSystemGroupChange={this.props.onSystemGroupChange}
                                isDisabled={this.props.areSystemPermissionsDisabled}
                                isNewField={this.props.isNew}
                                allFields={this.props.allFields}
                                folderName={this.props.folderName}
                            />
                        </div>
                    </Col>
                </Form.Row>
                {this.props.type !== AdditionalFieldTypes.checkBox && <div className="mt-5" />}
                {(this.props.type === AdditionalFieldTypes.textBox || this.props.type === AdditionalFieldTypes.linkTextBox) && (
                    <Form.Row>
                        <Form.Group
                            as={Col}
                            sm={this.props.type === AdditionalFieldTypes.linkTextBox ? 6 : undefined}
                            className={mergeStyles(this.props.type === AdditionalFieldTypes.linkTextBox && 'pr-4')}
                        >
                            <Form.Label>{myStrings.editMask}</Form.Label>
                            <Form.Control type="text" value={this.props.editMask} onChange={(e) => this.props.setEditMask(e.target.value)} />
                        </Form.Group>
                        {this.props.type === AdditionalFieldTypes.linkTextBox && (
                            <Form.Group as={Col} sm={6} className="pl-4">
                                <Form.Label>{myStrings.linkType}</Form.Label>
                                <FormDropdown options={this.linkTypeOptions} value={this.props.linkType} onValueChange={(val) => this.props.setLinkType(val)} />
                            </Form.Group>
                        )}
                    </Form.Row>
                )}
                {this.props.type === AdditionalFieldTypes.memoBox && (
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{myStrings.height}</Form.Label>
                            <NumericSpinner value={this.props.numberOfRows} onChange={(val) => this.props.setNumberOfRows(val)} min={1} max={25} />
                        </Form.Group>
                    </Form.Row>
                )}
                {this.props.type === AdditionalFieldTypes.numericBox && (
                    <Form.Row>
                        <Form.Group as={Col} sm={6} className="pr-4">
                            <Form.Label>{myStrings.format}</Form.Label>
                            <FormDropdown options={this.numberFormatOptions} value={this.props.numberFormat} onValueChange={(val) => this.props.setNumberFormat(val as TNumericValidatorType)} />
                        </Form.Group>
                        <Form.Group as={Col} sm={6} className="pl-4">
                            <Form.Label>{myStrings.summarizationType}</Form.Label>
                            <FormDropdown options={this.summaryTypeOptions} value={this.props.summaryType} onValueChange={(val) => this.props.setSummaryType(val)} />
                        </Form.Group>
                    </Form.Row>
                )}
                {this.props.type === AdditionalFieldTypes.dateEdit && (
                    <Form.Row>
                        <Form.Group as={Col} controlId="dateShowTime">
                            <Form.Check type="checkbox" label={myStrings.showTime} checked={this.props.showTime} disabled={!this.props.isNew} onChange={(e: IFormCheckChangeEvent) => this.props.setShowTime(e.target.checked)} />
                        </Form.Group>
                    </Form.Row>
                )}
                {this.props.type === AdditionalFieldTypes.relation && (
                    <Form.Row>
                        <Form.Group as={Col}>
                            <Form.Label>{myStrings.relatedTable}</Form.Label>
                            <FormDropdown
                                options={this.relatedFolderNameOptions}
                                value={this.props.relatedFolderName}
                                onValueChange={(val) => this.props.setRelatedFolderName && this.props.setRelatedFolderName(val)}
                                disabled={this.props.isRelatedFolderDisabled}
                            />
                        </Form.Group>
                    </Form.Row>
                )}
                {(this.props.type === AdditionalFieldTypes.comboBox || this.props.type === AdditionalFieldTypes.multiSelectComboBox) && (
                    <>
                        <h4>{myStrings.values}</h4>
                        <div style={{ height: '20rem', overflowY: 'hidden' }}>
                            <DelayedRender>
                                <EnumValuesEditGrid
                                    data={this.props.enumValues}
                                    onDataChange={(newData, clb) => this.props.setEnumValues(newData, clb)}
                                    enumType={{
                                        EnumName: this.props.enumTypeName,
                                        AllowEditLastActivity: false,
                                        AllowEditVisibility: true,
                                        RequireDefaultValue: false,
                                        IsAdditionalField: true,
                                        IsSystem: false,
                                        EditMode: EnumTypeEditMode.Editable
                                    }}
                                    displayLanguageColumns={this.props.displayLanguageColumns}
                                    toggleDisplayLanguageColumns={this.props.toggleDisplayLanguageColumns}
                                    tableRef={this.props.enumValuesEditGridRef}
                                />
                            </DelayedRender>
                        </div>
                    </>
                )}
            </Form>
        );
    }
}
