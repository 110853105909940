import * as React from 'react';
import { Button, ButtonToolbar, Dropdown, Form } from 'react-bootstrap';
import type { RouteComponentProps, match as IRouterMatch } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import Strings from '../../../strings';
import { type IApiGroup, type TFolderName } from '@eway-crm/connector';
import { ConfirmBeforeUnload } from '@eway-crm/gui';
import RouteConfig from '../../../RouteConfig';
import DuplicateModulePermissionsWizard from './DuplicateModulePermissionsWizard';
import ModulePermissionsGrid, { modulePermissionsOptionsSettings } from './ModulePermissionsGrid';
import PermissionsLegend from '../../shared/permissions/PermissionsLegend';
import type { TModulePermissionWithFolder } from '../ModulePermissions';
import { FontIcon, TooltipHost } from '@fluentui/react';
import AffectedUsers from '../../shared/permissions/AffectedUsers';
import { LoaderProvider } from '../../shared/WcfDataLoaderProvider';
import type { IApiUser } from '@eway-crm/connector';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { RemoteItemStore } from '../../../RemoteItemStore';
import { IntegratedFiltering } from '@devexpress/dx-react-grid';
import ModulePermissionNames from '../../../data/constants/ModulePermissionNames';
import LocalStorageHelper from '../../../helpers/LocalStorageHelper';
import StringHelper from '../../../helpers/StringHelper';
import ExcelJS from 'exceljs';
import ExcelHelper from '../../../helpers/ExcelHelper';
import { ItemStorage } from '../../../data/ItemStorageWithDelayedSave';
import ModulePermissions from '../ModulePermissions';
import ConfirmBeforeRouteChange from '../../../helpers/components/ConfirmBeforeRouteChange';
import memoizeOne from 'memoize-one';
import FolderContainer from '../../layout/FolderContainer';
import ReactHelper from '../../../helpers/ReactHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsTooltipContent from '../../shared/locks/LicenseRestrictionsTooltipContent';
import { SpinnerVariant } from '@eway-crm/gui';
import CustomWarningStrip, { type TCustomWarningBar } from './CustomWarningStrip';

type TFolderModulePermissionsProps = Pick<RouteComponentProps, 'history' | 'location'> & {
    selectedGroup: IApiGroup;
    allGroups: IApiGroup[];
    onReload: () => void;
    modulePermissions: TModulePermissionWithFolder[];
};

type TFolderModulePermissionsState = {
    searchedString: string;
    isLoadingData: boolean;
    modulePermissions: TModulePermissionWithFolder[];
    initialModulePermissions: TModulePermissionWithFolder[];
    lastSeenRowInitialCache: TModulePermissionsLastSeenRowCacheValue | null;
    anyChangeMade: boolean;
    isSavingData: boolean;
};

export type TModulePermissionsLastSeenRowCacheValue = {
    rowId: string;
    sort: 'asc' | 'desc';
    search: string;
};

const EXPORT_OPTIONS = {
    onlyCurrentTable: 'onlyCurrentTable',
    allTables: 'allTables',
} as const;

const EXPORT_FILENAME = 'Module_Permissions';

const myStrings = Strings.components.routes.modulePermissions;

export class FolderModulePermissions extends React.Component<TFolderModulePermissionsProps, TFolderModulePermissionsState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private modulePermissionsStorage: ItemStorage<TModulePermissionWithFolder>;

    constructor(props: TFolderModulePermissionsProps) {
        super(props);
        const lastSeenRow = this.getLastSeenRowFromCache();
        this.state = {
            modulePermissions: props.modulePermissions,
            initialModulePermissions: props.modulePermissions,
            searchedString: lastSeenRow?.search ?? '',
            lastSeenRowInitialCache: lastSeenRow,
            isLoadingData: false,
            anyChangeMade: false,
            isSavingData: false,
        };

        this.modulePermissionsStorage = new ItemStorage((item) => item.FolderName);
    }

    private readonly duplicate = () => {
        this.props.history.push(`${RouteConfig.modulePermissions.path}/${this.props.selectedGroup.ItemGUID}/${RouteConfig.modulePermissions.duplicate.slug}`);
    };

    private readonly showAffectedUsers = () => {
        this.props.history.push(`${RouteConfig.modulePermissions.path}/${this.props.selectedGroup.ItemGUID}/${RouteConfig.modulePermissions.affectedUsers.slug}`);
    };

    private readonly getAffectedUsersData = (successCb: (affectedUserItems: IApiUser[]) => void) => {
        this.setState({ isLoadingData: true }, () => {
            new RemoteItemStore(this.context.connection).loadAffectedUsersData(this.props.selectedGroup.ItemGUID, (affectedUserItems) => {
                this.setState({ isLoadingData: false }, () => {
                    successCb(affectedUserItems);
                });
            });
        });
    };

    private readonly onDismiss = () => {
        this.props.history.push(`${RouteConfig.modulePermissions.path}/${this.props.selectedGroup.ItemGUID}`);
    };

    private readonly onReload = () => {
        this.props.onReload();
    };

    private readonly onDone = () => {
        this.onDismiss();
        this.onReload();
    };

    private readonly getModulesWithComplexPermissionsCombination = (permissions: TModulePermissionWithFolder[], groupGuid: string) => {
        const foldersWithComplexCombination = permissions.filter((mp) => (mp.Edit === ModulePermissionNames.Options.Inherited || mp.Edit === ModulePermissionNames.Options.Group || mp.Edit === ModulePermissionNames.Options.Related) && mp.Edit !== mp.View && mp.GroupGuid === groupGuid).map(item => item.Folder);
        return foldersWithComplexCombination;
    };

    private readonly getIfAnyModuleHasComplexPermissionsSettings = (permissions: TModulePermissionWithFolder[], groupGuid: string) => {
        return permissions.some((mp => (mp.View === ModulePermissionNames.Options.Inherited || mp.View === ModulePermissionNames.Options.Group) && mp.GroupGuid === groupGuid));
    };

    private readonly onModulePermissionChange = (folderName: string, column: string, newValue: string | number | boolean | null) => {
        const groupGuid = this.props.selectedGroup.ItemGUID;
        const newModulePermissions = this.state.modulePermissions.map((mp) => {
            if (mp.FolderName === folderName && mp.GroupGuid === groupGuid) {
                const updatedModulePermission = ModulePermissions.changeModulePermission(mp, column, newValue);
                this.modulePermissionsStorage.addItem(updatedModulePermission);
                return updatedModulePermission;
            }
            return mp;
        });
        this.setState({
            modulePermissions: newModulePermissions,
            anyChangeMade: true,
        });
    };

    private readonly saveChanges = async () => {
        await ReactHelper.setState(this, { isSavingData: true });
        await new RemoteItemStore(this.context.connection).saveModulePermissions(this.modulePermissionsStorage.takeItems());
        this.onReload();
    };

    private readonly discardChanges = () => {
        this.modulePermissionsStorage.clearItems();
        this.setState((prevState) => {
            return {
                modulePermissions: prevState.initialModulePermissions,
                anyChangeMade: false,
            };
        });
    };

    private readonly exportExcelWorkbook = (exportOption: keyof typeof EXPORT_OPTIONS) => {
        let groupsToExport = this.props.allGroups;

        if (exportOption === EXPORT_OPTIONS.onlyCurrentTable) {
            groupsToExport = [this.props.selectedGroup];
        }

        this.setState(
            {
                isLoadingData: true,
            },
            () => {
                const groupsToExportGuids = groupsToExport.map((g) => g.ItemGUID);
                const groupGuidsWithModulePermissions: { [groupGuid: string]: TModulePermissionWithFolder[] } = {};

                this.state.modulePermissions.forEach((mp) => {
                    if (!groupsToExportGuids.includes(mp.GroupGuid)) {
                        return;
                    }

                    if (groupGuidsWithModulePermissions[mp.GroupGuid]) {
                        groupGuidsWithModulePermissions[mp.GroupGuid].push(mp);
                    } else {
                        groupGuidsWithModulePermissions[mp.GroupGuid] = [mp];
                    }
                });

                const workbook = new ExcelJS.Workbook();

                groupsToExport
                    .sort((a, b) => StringHelper.localeCompare(a.GroupName, b.GroupName))
                    .forEach((group) => {
                        const worksheet = workbook.addWorksheet(ExcelHelper.removeInvalidSheetNameChars(`${group.GroupName}`).slice(0, 31)); // Limit is 31 chars
                        worksheet.columns = [{ width: 30 }, { width: 20 }, { width: 15 }, { width: 20 }, { width: 20 }, { width: 15 }, { width: 15 }, { width: 20 }, { width: 40 }] as ExcelJS.Column[]; // cast because of bug in newest version of ExcelJS https://github.com/exceljs/exceljs/issues/1543#issuecomment-742622403

                        const headerRow = worksheet.addRow([`${Strings.components.sideMenu.modulePermissions} | ${group.GroupName}`]);
                        worksheet.mergeCells(1, 0, 1, worksheet.columns.length - 1);
                        headerRow.height = 20;
                        headerRow.font = { bold: true, size: 16 };

                        const columnTitleRow = worksheet.addRow([
                            myStrings.columns.moduleTitle,
                            myStrings.columns.viewTitle,
                            myStrings.columns.canCreateTitle,
                            myStrings.columns.editTitle,
                            myStrings.columns.deleteTitle,
                            myStrings.columns.canExportTitle,
                            myStrings.columns.canSeeHistoryTitle,
                            myStrings.columns.rowsRestrictionTitle,
                        ]);

                        columnTitleRow.eachCell((cell) => {
                            cell.border = {
                                bottom: { style: 'thin' },
                            };
                        });

                        groupGuidsWithModulePermissions[group.ItemGUID]
                            .sort((a, b) => StringHelper.localeCompare(a.Folder, b.Folder))
                            .forEach((mp, idx) => {
                                const currentRowIdx = idx + 3;
                                const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(mp.FolderName as TFolderName);
                                if (isFolderNameLocked) {
                                    const lockedRow = worksheet.addRow([mp.Folder, Strings.components.licenseRestrictions.excelExportModuleLocked]);
                                    worksheet.mergeCells(currentRowIdx, 2, currentRowIdx, worksheet.columns.length - 1);
                                    const lockedCellStyle = lockedRow.getCell(2).style;
                                    lockedCellStyle.alignment = { horizontal: 'center' };
                                    lockedCellStyle.font = { bold: true };
                                    return;
                                }
                                worksheet.addRow([
                                    mp.Folder,
                                    modulePermissionsOptionsSettings[mp.View].description,
                                    mp.CanCreate ? Strings.yes : Strings.no,
                                    modulePermissionsOptionsSettings[mp.Edit].description,
                                    modulePermissionsOptionsSettings[mp.Delete].description,
                                    mp.CanExport ? Strings.yes : Strings.no,
                                    mp.CanSeeHistory ? Strings.yes : Strings.no,
                                    !mp.RowsRestriction ? myStrings.options.noRestrictionsText : mp.RowsRestriction.toString(),
                                ]);
                            });
                    });

                ExcelHelper.exportToExcel(workbook, exportOption !== EXPORT_OPTIONS.allTables ? `${this.props.selectedGroup.GroupName}_${EXPORT_FILENAME}` : EXPORT_FILENAME, () => {
                    this.setState({
                        isLoadingData: false,
                    });
                });
            }
        );
    };

    private readonly getLastSeenRowFromCache = () => LocalStorageHelper.getItem<TModulePermissionsLastSeenRowCacheValue>(LocalStorageHelper.names.modulePermissionsLastSeenRowCacheKey);

    private readonly saveLastSeenRowToCache = (folderName: string, sort: 'asc' | 'desc', search: string) => {
        LocalStorageHelper.setItem<TModulePermissionsLastSeenRowCacheValue>(
            LocalStorageHelper.names.modulePermissionsLastSeenRowCacheKey,
            { rowId: folderName, sort, search },
            1000 * 60 * 15 // 15 mins
        );
    };

    private readonly onLastSeenChange = (folderName: React.ReactText | null, sort: 'asc' | 'desc') => {
        const sortedModulePermissions = this.state.modulePermissions
            .filter(
                (mp, index, row) =>
                    mp.GroupGuid === this.props.selectedGroup.ItemGUID &&
                    IntegratedFiltering.defaultPredicate(mp.Folder, { columnName: ModulePermissionNames.Columns.Folder, value: this.state.searchedString }, row)
            )
            .sort((a, b) => StringHelper.localeCompare(a.Folder, b.Folder));
        const firstRowId = sortedModulePermissions[0]?.FolderName;
        const lastShownRow = this.getLastSeenRowFromCache();
        if (!folderName && lastShownRow) {
            folderName = lastShownRow.rowId;
        }
        if (folderName !== firstRowId) {
            this.saveLastSeenRowToCache(folderName as string, sort, this.state.searchedString || (lastShownRow?.search ?? ''));
        }
    };

    private readonly onSearchStringChange = (newSearchedString: string) => {
        this.setState({ searchedString: newSearchedString }, () => {
            const lastShownRow = this.getLastSeenRowFromCache();
            if (lastShownRow) {
                this.saveLastSeenRowToCache(lastShownRow.rowId, lastShownRow.sort, newSearchedString);
            }
        });
    };

    private readonly getGroupsModulePermissions = memoizeOne((modulePermissions: TModulePermissionWithFolder[], selectedGroupGuid: string) =>
        modulePermissions.filter((mp) => mp.GroupGuid === selectedGroupGuid)
    );

    private readonly getCellWrapper = () => {
        const { isModulePermissionsReadOnly, modulePermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isModulePermissionsReadOnly();

        if (!isModulePermissionsReadOnly) {
            // Do not render any wrappers
            return undefined;
        }

        return ({ children }: { children?: React.ReactNode }) => (
            <TooltipHost content={<LicenseRestrictionsTooltipContent licenseRestriction={modulePermissionsLicenseRestriction} />}>{children}</TooltipHost>
        );
    };

    private readonly getRouteMatchParams = () => {
        const duplicatingMatch: IRouterMatch<{ groupGuid: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.modulePermissions.duplicate.path });
        const showingAffectedUsersMatch: IRouterMatch<{ groupGuid: string } | null> | null = matchPath(this.props.location.pathname, {
            path: RouteConfig.modulePermissions.affectedUsers.path,
        });
        return { isDuplicating: !!duplicatingMatch, isShowingAffectedUsers: !!showingAffectedUsersMatch };
    };

    render() {
        const { isDuplicating, isShowingAffectedUsers } = this.getRouteMatchParams();
        const groupId = this.props.selectedGroup.ItemGUID;
        const { isModulePermissionsReadOnly } = this.context.licenseRestrictionsHelper.isModulePermissionsReadOnly();
        const showWarningStripConfig: TCustomWarningBar = { modulesWithComplexCombination: this.getModulesWithComplexPermissionsCombination(this.state.modulePermissions, groupId), complexSettingWarning: this.getIfAnyModuleHasComplexPermissionsSettings(this.state.modulePermissions, groupId) };

        return (
            <>
                <FolderContainer
                    title={Strings.formatString(Strings.components.routes.modulePermissions.title, this.props.selectedGroup.FileAs as string) as string}
                    subtitle={Strings.formatString(Strings.components.routes.modulePermissions.subtitle, this.props.selectedGroup.FileAs as string) as string}
                    anyChangeMade={this.state.anyChangeMade}
                    onSave={this.saveChanges}
                    onDiscard={this.discardChanges}
                >
                    <div className="row mx-0">
                        <div className="col p-0">
                            <hr className="mb-0 mt-4" />
                            {isDuplicating && <DuplicateModulePermissionsWizard allGroups={this.props.allGroups} currentGroup={this.props.selectedGroup} onDismiss={this.onDismiss} onDone={this.onDone} />}
                            {(this.state.isLoadingData || this.state.isSavingData) && <SpinnerModal variant={SpinnerVariant.linear} />}
                            {isShowingAffectedUsers && (
                                <LoaderProvider
                                    key={this.props.selectedGroup.ItemGUID}
                                    loadData={this.getAffectedUsersData}
                                    render={(affectedUserItems) => {
                                        return (
                                            <AffectedUsers
                                                affectedUsers={affectedUserItems}
                                                currentGroup={this.props.selectedGroup}
                                                allGroups={this.props.allGroups}
                                                onDismiss={this.onDismiss}
                                                title={myStrings.affectedUsers}
                                                description={myStrings.affectedUsersDescription}
                                            />
                                        );
                                    }}
                                />
                            )}
                            {!!this.state.anyChangeMade && (
                                <>
                                    <ConfirmBeforeUnload />
                                    <ConfirmBeforeRouteChange history={this.props.history} />
                                </>
                            )}
                        </div>
                    </div>
                    <div className="d-block">
                        <div className="container max-w-none mx-0 mb-3 p-0">
                            <div className="row justify-content-end">
                                <div className="col">
                                    <CustomWarningStrip warningBarConfig={showWarningStripConfig} />
                                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        <ButtonToolbar>
                                            <Button variant="outline-secondary" onClick={this.onReload}>
                                                <i className="mdl2 mdl2-refresh" aria-hidden="true" /> {Strings.refresh}
                                            </Button>
                                            <Button variant="outline-secondary" onClick={this.duplicate} disabled={isModulePermissionsReadOnly}>
                                                <i className="mdl2 mdl2-copy" aria-hidden="true" /> {Strings.duplicate}
                                            </Button>
                                            <Button variant="outline-secondary" onClick={this.showAffectedUsers}>
                                                <FontIcon iconName="UserOptional" /> {myStrings.affectedUsers}
                                            </Button>
                                            <Dropdown>
                                                <Dropdown.Toggle className="exportBtn__toggle" title={Strings.exportToExcel} variant="outline-secondary">
                                                    <FontIcon iconName="ExcelDocument" /> {Strings.export}
                                                    <FontIcon className="pl-1 exportBtn__chevronDown" iconName="ChevronDown" />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="exportBtn__menu">
                                                    <Dropdown.Item className="exportBtn__item" eventKey="1" onClick={() => this.exportExcelWorkbook(EXPORT_OPTIONS.onlyCurrentTable)}>
                                                        {myStrings.exportThisTable}
                                                    </Dropdown.Item>
                                                    <Dropdown.Item className="exportBtn__item" eventKey="2" onClick={() => this.exportExcelWorkbook(EXPORT_OPTIONS.allTables)}>
                                                        {myStrings.exportAllTables}
                                                    </Dropdown.Item>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </ButtonToolbar>
                                        <Form.Control
                                            style={{ width: "auto" }}
                                            type="text"
                                            placeholder={Strings.search}
                                            value={this.state.searchedString}
                                            onChange={(e) => {
                                                this.onSearchStringChange(e.target.value);
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row flex-fill d-flex justify-content-start mt-2 mx-0">
                        <div className="col p-0">
                            <div className="h-100 position-absolute" style={{ maxHeight: '100%', minHeight: '0', top: '0', bottom: '0', left: '0', right: '0' }}>
                                <ModulePermissionsGrid
                                    modulePermissions={this.getGroupsModulePermissions(this.state.modulePermissions, this.props.selectedGroup.ItemGUID)}
                                    onModulePermissionChange={this.onModulePermissionChange}
                                    disabled={this.props.selectedGroup.DisallowControlModulePermissions || isModulePermissionsReadOnly}
                                    searchedString={this.state.searchedString}
                                    onLastSeenRowChange={this.onLastSeenChange}
                                    lastSeenRow={this.state.lastSeenRowInitialCache}
                                    cellWrapper={this.getCellWrapper()}
                                />
                            </div>
                        </div>
                    </div>
                    <PermissionsLegend optionsSettings={modulePermissionsOptionsSettings} />
                </FolderContainer>
            </>
        );
    }
}
