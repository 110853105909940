import React from 'react';
import { Form } from 'react-bootstrap';
import type { match as IRouterMatch, RouteComponentProps } from 'react-router-dom';
import { matchPath } from 'react-router-dom';
import Cookies from '../../Cookies';
import StringHelper from '../../helpers/StringHelper';
import RouteConfig from '../../RouteConfig';
import Strings from '../../strings';
import { SecondaryPanel } from '../layout/SecondaryPanel';
import FoldersMenu from '../shared/FoldersMenu';
import splashImg from '../../img/splash/workflow-splash.svg';
import type { IApiDataResponse, IApiEnumType, IApiWorkflowModel, TFolderName } from '@eway-crm/connector';
import { RemoteItemStore } from '../../RemoteItemStore';
import WorkflowModelContainer from './workflow/WorkflowModelContainer';
import ReactHelper from '../../helpers/ReactHelper';
import FolderSplash from '../layout/FolderSplash';
import FolderNames from '../../data/constants/FolderNames';
import { ConnectionContext } from '../../providers/ConnectionProvider';
import FeatureLockedSplash from '../shared/locks/FeatureLockedSplash';
import WorkflowRestrictionTooltipContent from './workflow/WorkflowRestrictionTooltipContent';
import NotificationDot from '../layout/NotificationDot';
import { getWorkflowLicenseRestrictionsErrors } from './workflow/WorkflowLicenseRestrictionsErrors';
import InteractiveTooltip from '../shared/InteractiveTooltip';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

const myStrings = Strings.components.routes.workflow;

type TWorkflowProps = Pick<RouteComponentProps, 'history' | 'location'>;

type TWorkflowState = {
    folders: string[] | null;
    wfModels: IApiWorkflowModel[] | null;
    menuSearchedString: string;
    wfMenuString: string;
    displayLanguageColumns: string[] | null;
    independentWorkflowEnumTypeGuids: { [folderName: string]: string } | null;
};

export default class Workflow extends React.Component<TWorkflowProps, TWorkflowState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TWorkflowProps) {
        super(props);
        this.state = {
            folders: null,
            wfModels: null,
            menuSearchedString: '',
            wfMenuString: '',
            displayLanguageColumns: null,
            independentWorkflowEnumTypeGuids: null,
        };
    }

    private readonly reload = () => {
        this.setState({ folders: null, wfModels: null }, () => {
            (async () => {
                const { askApi } = this.context.connection;
                const { objectTypes } = this.context.apiData;
                const otFolders = RemoteItemStore.getDisplayableWorkflowFolders(objectTypes, this.context.licenseRestrictionsHelper);
                const wfModels = await askApi<IApiDataResponse<IApiWorkflowModel>>('GetWorkflowModels', {});
                const displayLanguageColumns = await new RemoteItemStore(this.context.connection).askForFieldsLanguageColumns();

                const vacationEnumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('SearchEnumTypes', { transmitObject: { EnumName: 'Absence' } });
                const bonusesEnumTypeRes = await askApi<IApiDataResponse<IApiEnumType>>('SearchEnumTypes', { transmitObject: { EnumName: 'Bonus' } });
                const independentWorkflowEnumTypeGuids = {
                    [FolderNames.vacation]: vacationEnumTypeRes.Data[0].ItemGUID,
                    [FolderNames.bonuses]: bonusesEnumTypeRes.Data[0].ItemGUID,
                };

                this.setState({
                    folders: otFolders,
                    wfModels: wfModels.Data,
                    displayLanguageColumns,
                    independentWorkflowEnumTypeGuids,
                });
            })()
                .catch((err) => console.error('Unable to reload workflow data.', err));
        });
    };

    private readonly updateWfModels = async (cb?: (wfModels: IApiWorkflowModel[]) => void) => {
        const { askApi } = this.context.connection;
        const wfModelsRes = await askApi<IApiDataResponse<IApiWorkflowModel>>('GetWorkflowModels', {});
        await this.context.reloadCustomizationStats();
        this.setState({ wfModels: wfModelsRes.Data }, () => cb && cb(wfModelsRes.Data));
    };

    private readonly toggleDisplayLanguageColumns = async (changedLanguage: string) => {
        const newLanguages = new RemoteItemStore(this.context.connection).toggleFieldsDisplayLanguageColumns(changedLanguage, this.state.displayLanguageColumns);
        await ReactHelper.setState(this, { displayLanguageColumns: newLanguages });
    };

    componentDidMount() {
        this.reload();
    }

    private readonly getCurrentlySelectedFolderName = () => {
        const folderMatch: IRouterMatch<{ folder: string } | null> | null = matchPath(this.props.location.pathname, { path: RouteConfig.customizations.workflow.pathWithFolder });
        const folderName = folderMatch?.params?.folder;
        if (folderName && this.state.folders) {
            const capitalizedFolderName = this.state.folders.find((f) => !StringHelper.compareIgnoringCase(f, folderName)) ?? null;
            return capitalizedFolderName as TFolderName | null;
        }

        return null;
    };

    private readonly SecondaryPanelHead: React.FC = () => (
        <Form.Control type="text" placeholder={Strings.search} value={this.state.menuSearchedString} onChange={(e) => this.setState({ menuSearchedString: e.target.value })} />
    );

    private readonly SecondaryPanelBody: React.FC<{ isCollapsed: boolean }> = ({ isCollapsed }) => {
        const workflowLicenseRestrictionErrors = getWorkflowLicenseRestrictionsErrors(this.context);

        return (
            <FoldersMenu
                folderNames={this.state.folders!}
                selectedFolderName={this.getCurrentlySelectedFolderName()}
                searchedString={this.state.menuSearchedString}
                isCollapsed={isCollapsed}
                onFolderChange={(newFolderName) => {
                    this.props.history.push(RouteConfig.customizations.workflow.getPathWithFolder(newFolderName));
                }}
                onRenderCellSuffix={(props) => {
                    const typedRow = props.row as { folderName: TFolderName };
                    const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(typedRow.folderName);

                    if (isFolderNameLocked) {
                        return null;
                    }

                    const licenseRestrictionErrors = workflowLicenseRestrictionErrors[typedRow.folderName];
                    if (licenseRestrictionErrors) {
                        return (
                            <InteractiveTooltip content={<WorkflowRestrictionTooltipContent folderName={typedRow.folderName} />} hostClassName="ml-auto" className="workflow__menu-tooltip">
                                <NotificationDot value={licenseRestrictionErrors.length} />
                            </InteractiveTooltip>
                        );
                    }

                    return null;
                }}
            />
        );
    };
    render() {
        const selectedFolderName = this.getCurrentlySelectedFolderName();
        const { isFolderNameLocked } = this.context.licenseRestrictionsHelper.isFolderNameLocked(selectedFolderName);
        const isSelectedFolderNameLocked = selectedFolderName && isFolderNameLocked;
        if (!this.state.folders || !this.state.wfModels || !this.state.displayLanguageColumns || !this.state.independentWorkflowEnumTypeGuids) {
            return <Spinner variant={SpinnerVariant.ease} />;
        }
        return (
            <SecondaryPanel
                collapseConfig={{ cookieName: Cookies.names.workflow }}
                onMenuCollapsedChange={(isCollapsed) => {
                    if (isCollapsed) {
                        this.setState({ menuSearchedString: '' });
                    }
                }}
                panelHead={this.SecondaryPanelHead}
                panelBody={this.SecondaryPanelBody}
            >
                {!selectedFolderName ? (
                    <FolderSplash img={splashImg} message={myStrings.splashMessage} />
                ) : isSelectedFolderNameLocked ? (
                    <FeatureLockedSplash />
                ) : (
                    <WorkflowModelContainer
                        key={selectedFolderName}
                        selectedFolderName={selectedFolderName}
                        wfModels={this.state.wfModels}
                        history={this.props.history}
                        location={this.props.location}
                        displayLanguageColumns={this.state.displayLanguageColumns}
                        toggleDisplayLanguageColumn={this.toggleDisplayLanguageColumns}
                        updateWfModels={this.updateWfModels}
                        independentWorkflowEnumTypeGuids={this.state.independentWorkflowEnumTypeGuids}
                    />
                )}
            </SecondaryPanel>
        );
    }
}
