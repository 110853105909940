import { mergeStyles } from '@fluentui/react';
import React from 'react';
import type { ButtonToolbarProps } from 'react-bootstrap';
import { Button, ButtonToolbar } from 'react-bootstrap';
import Strings from '../../strings';

type TSaveButtonsToolbar = ButtonToolbarProps & {
    anyChangeMade: boolean;
    onSave?: () => Promise<void>;
    onDiscard?: () => void;
    disabled?: boolean;
};

const SaveButtonsToolbar: React.FC<TSaveButtonsToolbar> = ({ anyChangeMade, onDiscard, onSave, className, disabled, ...restProps }) => {
    return (
        <ButtonToolbar className={mergeStyles("align-items-end flex-nowrap", className)} {...restProps}>
            <Button variant="primary" className={mergeStyles('mx-2', !anyChangeMade && 'invisible')} onClick={() => void onSave?.()} title={Strings.saveChanges} disabled={disabled}>
                {Strings.save}
            </Button>
            <Button variant="outline-secondary" className={mergeStyles(!anyChangeMade && "invisible")} onClick={onDiscard} title={Strings.discardChanges}>
                {Strings.discard}
            </Button>
        </ButtonToolbar>
    );
};

export default SaveButtonsToolbar;
