import { IconButton, mergeStyles, mergeStyleSets, useTheme } from '@fluentui/react';
import * as React from 'react';
import type { TPeriodType } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import UnitHelper from '../../../../../helpers/UnitHelper';
import Strings from "../../../../../strings";
import { NumberFormatter } from '../../../../shared/NumberFormatter';
import { Subscriptions } from '../../../Subscriptions';
import getPrice from '../../getPrice';
import UnitPriceSpan from '../../UnitPriceSpan';
import useItemsInCart from '../hooks/useItemsInCart';
import usePackage from '../hooks/usePackage';
import StoreV2Context from '../StoreV2Context';

const css = mergeStyleSets({
    row: {
        marginBottom: '12px'
    },
    rowTitle: {
        fontSize: '16px',
        fontWeight: '500'
    },
    rowUnit: {
        fontSize: '14px'
    },
    rowError: {
        color: 'red',
        fontSize: '12px',
        maxWidth: '280px'
    }
});

const shownPricePeriod: TPeriodType = 'Monthly';

type TSummaryItemsProps = {
    allowRemoving: boolean;
};

const SummaryItems: React.FC<TSummaryItemsProps> = ({ allowRemoving }) => {
    const items = useItemsInCart();
    return (
        <>
            {items.map((p, i) => <PackageSummaryRow key={`${p.artno}-${i}`} artno={p.artno} allowRemoving={allowRemoving} />)}
        </>
    );
};

const PackageSummaryRow: React.FC<{ artno: string; allowRemoving: boolean }> = ({ artno, allowRemoving }) => {
    const { customerInfo, isPackageInCart, setPackageEditingState, currentDbSizeGigabytes, selectedPeriodType } = React.useContext(StoreV2Context);
    const { packageData, packageEditingState, steps } = usePackage(artno);
    const theme = useTheme();

    if (!packageData)
        return null;

    const packageQuantity = packageEditingState.currentEditQuantity || 0;
    const price = packageQuantity * getPrice(
        packageData,
        packageQuantity,
        customerInfo.currency,
        selectedPeriodType,
        isPackageInCart,
        shownPricePeriod // Summary item row price is forcely always shown as monthly price.
    );

    const isRemoveable = !steps || steps.length === 0 || steps.includes(0) || price > 0;

    const handleCrossClick = () => {
        if (!isRemoveable)
            return;

        setPackageEditingState(artno, { currentEditQuantity: 0 });
    };

    const myStrings = Strings.components.routes.subscriptions;

    return (
        <div className={mergeStyles(css.row, 'd-flex align-items-center')}>
            <div>
                <div className={mergeStyles(css.rowTitle, 'ignore-ew-window-move')}>{Strings.pickTranslation(packageData.InCartTitle)}</div>
                <div className={mergeStyles(css.rowUnit, 'ignore-ew-window-move')}>
                    {NumberFormatter.format(packageQuantity, 'metric')} {UnitHelper.pickTranslatedUnit(packageQuantity, packageData.Unit)}
                    <> {myStrings.for} </>
                    <UnitPriceSpan formattedPrice={Strings.formatCurrency(price, Subscriptions.priceDecPlaces, customerInfo.currency)!} packageData={packageData} periodType={shownPricePeriod} showUnit={false} />
                </div>
                {(packageData.CompareToDbSizeGigabytes && !!packageEditingState.currentEditQuantity && packageEditingState.currentEditQuantity < currentDbSizeGigabytes) &&
                    <div className={mergeStyles(css.rowError, 'ignore-ew-window-move')}>
                        {Strings.formatString(myStrings.lessThanDbSizeFormat, Strings.formatNumber(currentDbSizeGigabytes, 0) ?? "")}
                    </div>
                }
            </div>
            {(allowRemoving) &&
                <div className="ml-auto">
                    <IconButton iconProps={{ iconName: 'Cancel' }} styles={{ icon: { color: theme.palette.neutralSecondary } }} onClick={handleCrossClick} className={mergeStyles(!isRemoveable && 'd-none')} />
                </div>
            }
        </div>
    );
};

export default SummaryItems;