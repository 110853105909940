import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col } from 'react-bootstrap';
import StringHelper from '../../../helpers/StringHelper';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiUser } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import { VirtualizedComboBox } from '@fluentui/react';
import { formatRFC3339 } from 'date-fns';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TDeactivateWizardProps = {
    onDismiss: () => void;
    delegateOnly: boolean;
    selectedUsers: IApiUser[];
    availableSubstituteUsers: IApiUser[];
    onDone: () => void;
};

type TDeactivateWizardState = {
    reassignCompanies: boolean;
    reassignContacts: boolean;
    reassignActiveDeals: boolean;
    reassignCompletedDeals: boolean;
    reassignDeadDeals: boolean;
    reassignActiveProjects: boolean;
    reassignCompletedProjects: boolean;
    reassignDeadProjects: boolean;
    reassignMarketings: boolean;
    reassignActiveTasks: boolean;
    newOwnerGuid: string | null;
    isSavingInProgress: boolean;
    isFormValidated: boolean;
};

export class DeactivateWizard extends React.Component<TDeactivateWizardProps, TDeactivateWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TDeactivateWizardProps) {
        super(props);
        this.state = {
            reassignCompanies: true,
            reassignContacts: true,
            reassignActiveDeals: true,
            reassignCompletedDeals: false,
            reassignDeadDeals: false,
            reassignActiveProjects: true,
            reassignCompletedProjects: false,
            reassignDeadProjects: false,
            reassignMarketings: true,
            reassignActiveTasks: true,
            newOwnerGuid: null,
            isSavingInProgress: false,
            isFormValidated: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly isAtLeastOneOptionChecked = () => {
        return this.state.reassignCompanies ||
        this.state.reassignContacts ||
        this.state.reassignActiveProjects ||
        this.state.reassignCompletedProjects ||
        this.state.reassignDeadProjects ||
        this.state.reassignActiveDeals ||
        this.state.reassignCompletedDeals ||
        this.state.reassignDeadDeals ||
        this.state.reassignActiveTasks;
    };

    private readonly deactivateUsers = () => {
        if (this.props.delegateOnly) {
            this.props.onDone();
        } else {
            this.context.connection.callApi(
                'SaveUsers',
                {
                    transmitObjects: this.props.selectedUsers.map((u) => ({ ItemGUID: u.ItemGUID, IsActive: false, IsApiUser: false })),
                },
                (_) => {
                    this.props.onDone();
                }
            );
        }
    };

    private readonly submit = () => {
        if (!this.state.newOwnerGuid && this.isAtLeastOneOptionChecked()) {
            this.setState({ isFormValidated: true });
            return;
        }

        this.setState({ isSavingInProgress: true, isFormValidated: true }, () => {
            if (this.isAtLeastOneOptionChecked()) {
                this.context.connection.callApi(
                    'ReassignItems',
                    {
                        oldUserGuids: this.props.selectedUsers.map((u) => u.ItemGUID),
                        newUserGuid: this.state.newOwnerGuid,
                        itemChanged: formatRFC3339(new Date()),
                        reassignCompanies: this.state.reassignCompanies,
                        reassignContacts: this.state.reassignContacts,
                        reassignActiveProjects: this.state.reassignActiveProjects,
                        reassignCompletedProjects: this.state.reassignCompletedProjects,
                        reassignDeadProjects: this.state.reassignDeadProjects,
                        reassignActiveLeads: this.state.reassignActiveDeals,
                        reassignCompletedLeads: this.state.reassignCompletedDeals,
                        reassignDeadLeads: this.state.reassignDeadDeals,
                        reassignActiveTasks: this.state.reassignActiveTasks,
                        reassignCompletedTasks: false,
                    },
                    (_) => {
                        if (this.props.delegateOnly) {
                            this.props.onDone();
                        } else {
                            this.deactivateUsers();
                        }
                    }
                );
            } else {
                this.deactivateUsers();
            }
        });
    };

    render() {
        const myStrings = Strings.components.routes.users;

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            const title = this.props.delegateOnly ? myStrings.delegate : myStrings.deactivate;
            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{title}</h1>
                        <div className="mb-4">
                            {this.props.selectedUsers.map((user, i) => (
                                <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                    {user.FileAs}
                                </span>
                            ))}
                        </div>
                        <div>
                            <Form className="form-application">
                                <Form.Row>
                                    <Col>
                                        <Form.Label className="mb-2">{myStrings.reassignFollowing}</Form.Label>
                                        <Form.Group controlId="deactivateReassignCompanies" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.companies}
                                                checked={this.state.reassignCompanies}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignCompanies: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignContacts" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.contacts}
                                                checked={this.state.reassignContacts}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignContacts: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignActiveDeals" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.activeDeals}
                                                checked={this.state.reassignActiveDeals}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignActiveDeals: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignCompletedDeals" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.completedDeals}
                                                checked={this.state.reassignCompletedDeals}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignCompletedDeals: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignDeadDeals" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.deadDeals}
                                                checked={this.state.reassignDeadDeals}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignDeadDeals: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignActiveProjects" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.activeProjects}
                                                checked={this.state.reassignActiveProjects}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignActiveProjects: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignCompletedProjects" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.completedProjects}
                                                checked={this.state.reassignCompletedProjects}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignCompletedProjects: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignDeadProjects" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.deadProjects}
                                                checked={this.state.reassignDeadProjects}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignDeadProjects: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignMarketingCampaigns" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.marketingCampaigns}
                                                checked={this.state.reassignMarketings}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignMarketings: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateReassignActiveTasks" className="mb-2">
                                            <Form.Check
                                                type="checkbox"
                                                label={Strings.activeTasks}
                                                checked={this.state.reassignActiveTasks}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ reassignActiveTasks: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="deactivateNewOwner" className="mt-3">
                                            <Form.Label>{myStrings.newOwner}</Form.Label>
                                            <VirtualizedComboBox
                                                selectedKey={this.state.newOwnerGuid}
                                                options={this.props.availableSubstituteUsers
                                                    .sort((a, b) => StringHelper.localeCompare(a.FileAs, b.FileAs))
                                                    .map((usr) => ({ text: usr.FileAs || '', key: usr.ItemGUID }))}
                                                onChange={(e, o, i, v) => this.setState({ newOwnerGuid: o!.key as string })}
                                                useComboBoxAsMenuWidth={true}
                                                errorMessage={this.state.isFormValidated && !this.state.newOwnerGuid && this.isAtLeastOneOptionChecked() ? Strings.thisFieldIsMandatory : undefined}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={this.submit}>
                            {Strings.ok}
                        </Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}
