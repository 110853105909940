import React, { useState, useRef } from 'react';
import { mergeStyles, Tooltip } from '@fluentui/react';

type TInteractiveTooltipProps = React.PropsWithChildren & {
    content?: JSX.Element;
    hostClassName?: string;
    className?: string;
};

/**
 * Tooltip that can handle interactive content without sometimes dismissing (happens with TooltipHost from FluentUI)
 */
const InteractiveTooltip: React.FC<TInteractiveTooltipProps> = ({ children, content, hostClassName, className }) => {
    const [isHoveringHost, setIsHoveringHost] = useState(false);
    const [isHoveringTooltip, setIsHoveringTooltip] = useState(false);
    const hostRef = useRef<HTMLDivElement>(null);

    return (
        <div
            className={mergeStyles('interactive-tooltip', hostClassName)}
        >
            <div
                onMouseEnter={() => {
                    setIsHoveringHost(true);
                }}
                onMouseLeave={() => {
                    setIsHoveringHost(false);
                }}
                ref={hostRef}
            >
                {children}
            </div>
            {(isHoveringHost || isHoveringTooltip) && (
                <Tooltip
                    className={mergeStyles('interactive-tooltip__tooltip', className)}
                    targetElement={hostRef.current as HTMLElement}
                    styles={{ root: {} }}
                    content={content}
                    onMouseLeave={() => setIsHoveringTooltip(false)}
                    onMouseEnter={() => setIsHoveringTooltip(true)}
                >
                    <div hidden={true}>{content}</div>
                </Tooltip>
            )}
        </div>
    );
};

export default InteractiveTooltip;
