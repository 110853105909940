import * as React from 'react';
import * as Dropin from 'braintree-web-drop-in';

type TMode = 'vault' | 'checkout';

export type TBraintreeNonceCallback = (nonce: string | null) => void;

export type TBraintreeOnInitializedEvent = (getter: ((clb: TBraintreeNonceCallback) => void)) => void;

type TBraintreeDropinProps = {
    name: string;
    clientToken: string;
    onDropinInitialized: TBraintreeOnInitializedEvent;
    mode: TMode;
    checkoutData?: {
        amount: number;
        currencyCode: string;
    };
};

class BraintreeDropin extends React.Component<TBraintreeDropinProps> {

    private onWillUnmountEvents: (() => void)[];

    constructor(props: TBraintreeDropinProps) {
        super(props);
        this.onWillUnmountEvents = [];
        if (props.mode === 'checkout' && !props.checkoutData)
            throw new Error('No checkout data for dropin.');
    }

    componentDidMount() {
        const paypalCreateOptions: Dropin.paypalCreateOptions = {
            flow: this.props.mode
        };
        let cardCreateOptions: Dropin.cardCreateOptions | undefined;
        if (this.props.mode === 'checkout') {
            paypalCreateOptions.amount = this.props.checkoutData!.amount;
            paypalCreateOptions.currency = this.props.checkoutData!.currencyCode;
            cardCreateOptions = {
                overrides: {},
                vault: {
                    allowVaultCardOverride: false,
                    vaultCard: false
                }
            };
        }

        Dropin.create({
            authorization: this.props.clientToken,
            container: '#' + this.props.name + 'BraintreeDropinContainer',
            paypal: paypalCreateOptions,
            card: cardCreateOptions
        }, (createErr, instance) => {
            if (createErr) {
                console.error(createErr);
                return;
            }

            // Register deactivation.
            this.onWillUnmountEvents.push(() => {
                if (instance) {
                    instance.teardown()
                        .catch((err) => console.error('Unable to tear down braintree dropin.', err));
                }
            });

            // Register nonce getter.
            setTimeout(() => {
                if (this.props.onDropinInitialized) {
                    this.props.onDropinInitialized(
                        (callback: TBraintreeNonceCallback) => {
                            if (!instance) {
                                throw new Error("Unable to init BT dropint. The instance is null or undefined.");
                            }
                            instance.requestPaymentMethod((requestPaymentMethodErr, payload) => {
                                if (requestPaymentMethodErr) {
                                    console.error(requestPaymentMethodErr);
                                    callback(null);
                                    return;
                                }
                                if (!payload) {
                                    throw new Error("Payload is undefined.");
                                }
                                callback(payload.nonce);
                            });
                        }
                    );
                }
            }, 10);
        });
    }

    componentWillUnmount() {
        if (this.onWillUnmountEvents) {
            this.onWillUnmountEvents.map((event, _) => event());
        }
    }

    render() {
        return (
            <div id={this.props.name + 'BraintreeDropinContainer'} />
        );
    }
}

export default BraintreeDropin;