import React from 'react';
import type { Column} from '@devexpress/dx-react-grid';
import { IntegratedFiltering, SearchState, SelectionState } from '@devexpress/dx-react-grid';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableSelection, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import GridTable from '../GridTable';

type TSideMenuGridProps<TItem extends Record<string, unknown>> = {
    items: TItem[];
    columns: Column[];
    getRowId: (row: TItem) => React.ReactText;
    selectedItemId: string | null;
    onMenuItemChange: (newItemId: string) => void;
    searchedString: string;
    cellComponent: React.ComponentClass<Table.DataCellProps> | React.FunctionComponent<Table.DataCellProps> | undefined;
    columnExtensions: VirtualTable.ColumnExtension[];
    height?: number ;
    noDataText: string;
    tableRef?: React.RefObject<typeof VirtualTable>;
    estimatedRowHeight?: number;
};

const SideMenuGrid = <TItem extends Record<string, unknown>>(props: TSideMenuGridProps<TItem>) => {
    const handleSelectionChange = (selection: React.ReactText[]) => {
        if (selection.length !== 0) {
            const newSelection = selection[selection.length - 1] as string;
            props.onMenuItemChange(newSelection);
        }
    };

    return (
        <Grid rows={props.items} columns={props.columns} rootComponent={GridTable.Root} getRowId={props.getRowId}>
            <SelectionState selection={!!props.selectedItemId ? [props.selectedItemId] : []} onSelectionChange={handleSelectionChange} />
            <SearchState value={props.searchedString} />
            <IntegratedFiltering />
            <VirtualTable
                ref={props.tableRef}
                height={props.height ?? 450}
                estimatedRowHeight={props.estimatedRowHeight}
                containerComponent={GridTable.SideMenuTableContainerComponent}
                cellComponent={props.cellComponent}
                columnExtensions={props.columnExtensions}
                messages={{ noData: props.noDataText }}
                noDataCellComponent={GridTable.NoDataCell}
            />
            <TableSelection selectByRowClick highlightRow showSelectionColumn={false} />
        </Grid>
    );
};

export default SideMenuGrid;
