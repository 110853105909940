import { IconButton } from '@fluentui/react';
import { useState } from 'react';

const BACK_BUTTON = { iconName: 'Back' };
const FORWARD_BUTTON = { iconName: 'Forward' };

type TTooltipContentStepsProps<TStep> = {
    steps: TStep[];
    onRenderContent: (step: TStep) => JSX.Element;
};

const TooltipContentSteps = <TStep,>({ steps, onRenderContent }: TTooltipContentStepsProps<TStep>) => {
    const [step, setStep] = useState(0);

    return (
        <div className="d-flex py-2 px-1 flex-column">
            <div>{onRenderContent(steps[step])}</div>
            <div>
                {steps.length > 1 && (
                    <div className={'d-flex align-items-center justify-content-end'}>
                        <div className="mr-3">
                            {step + 1} / {steps.length}
                        </div>
                        <div>
                            <IconButton
                                iconProps={BACK_BUTTON}
                                disabled={step === 0}
                                onClick={() => {
                                    setStep((prev) => prev - 1);
                                }}
                            />
                            <IconButton
                                className="ml-1"
                                iconProps={FORWARD_BUTTON}
                                disabled={step === steps.length - 1}
                                onClick={() => {
                                    setStep((prev) => prev + 1);
                                }}
                            />
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default TooltipContentSteps;
