import * as React from 'react';
import Strings from '../../../strings';
import { Form, Button, Col } from 'react-bootstrap';
import { PasswordHelper } from '../../../helpers/PasswordHelper';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiUser } from '@eway-crm/connector';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import PasswordInput from '../../shared/users/PasswordInput';
import type { IApiPasswordStrength } from '@eway-crm/connector';
import ReactHelper from '../../../helpers/ReactHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TResetPasswordWizardProps = {
    onDismiss: () => void;
    selectedUsers: IApiUser[];
    minimumPasswordLength: number;
    onDone: () => void;
};

type TResetPasswordWizardState = {
    autoGeneratePassword: boolean;
    password: string;
    passwordToCheck: string | null;
    passwordStrengths: IApiPasswordStrength[] | null;
    forceChangePassword: boolean;
    sendNewPassword: boolean;
    isFormValidated: boolean;
    isSavingInProgress: boolean;
};

const myStrings = Strings.components.routes.users;

export class ResetPasswordWizard extends React.Component<TResetPasswordWizardProps, TResetPasswordWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly formReference: React.RefObject<HTMLFormElement>;

    constructor(props: TResetPasswordWizardProps) {
        super(props);
        this.formReference = React.createRef();
        this.state = {
            autoGeneratePassword: true,
            password: '',
            passwordToCheck: '',
            passwordStrengths: null,
            forceChangePassword: true,
            sendNewPassword: true,
            isFormValidated: false,
            isSavingInProgress: false,
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = async () => {
        if (
            (this.formReference.current && this.formReference.current.checkValidity() === false) ||
            (!this.state.autoGeneratePassword && !PasswordHelper.areStrongEnough(this.state.passwordStrengths))
        ) {
            this.setState({ isFormValidated: true });
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        const dataToSubmit: Partial<IApiUser>[] = this.props.selectedUsers.map((user) => ({
            ItemGUID: user.ItemGUID,
            ItemVersion: user.ItemVersion + 1,
            Server_Password: this.state.autoGeneratePassword ? PasswordHelper.generate(Math.max(this.props.minimumPasswordLength, 12)) : this.state.password,
            Server_ForcePasswordChange: this.state.forceChangePassword ? true : undefined,
        }));

        const passwordStrengths = await this.getPasswordStrengths(this.state.password);
        if (!this.state.autoGeneratePassword && !PasswordHelper.areStrongEnough(passwordStrengths)) {
            this.setState({ passwordStrengths: passwordStrengths ?? null, isSavingInProgress: false, isFormValidated: true });
        } else {
            await this.context.connection.askApi('SaveUsers', { transmitObjects: dataToSubmit, });

            if (this.state.sendNewPassword) {
                await this.context.connection.askApi(
                    'SendWelcomeEmails',
                    {
                        transmitObjects: dataToSubmit.map((user) => ({
                            UserTransmitObject: user,
                            MessageType: 'PasswordChanged',
                        })),
                        webServiceAddress: this.context.connection.getApiConnection().wsUrl,
                    }
                );
            }

            this.props.onDone();
        }
    };

    private readonly checkPasswordStrengths = async (password: string) => {
        if (!this.state.passwordToCheck) {
            await ReactHelper.setState(this, { passwordToCheck: password });
            const passwordCurrentlyChecking = this.state.passwordToCheck;
            const passwordStrengths = await this.getPasswordStrengths(password);
            this.setState({ passwordStrengths: passwordStrengths ?? null, passwordToCheck: null }, () => {
                const newPasswordToCheck = this.state.password && passwordCurrentlyChecking !== this.state.password ? this.state.password : null;
                if (newPasswordToCheck) { // If password changed during API call
                    this.checkPasswordStrengths(newPasswordToCheck)
                        .catch((err) => console.error('Unable to check another password strength.', err));
                }
            });
        }
    };

    private readonly getPasswordStrengths = async (password: string) => {
        const passwordStrengths = await PasswordHelper.getPasswordStrengths(
            this.context.connection,
            password,
            this.props.selectedUsers.map((user) => user.Username)
        );
        return passwordStrengths;
    };

    private readonly onPasswordChange = (newValue: string) => {
        this.setState(
            (prevState) => ({ password: newValue, passwordStrengths: newValue ? prevState.passwordStrengths : null }),
            () => {
                this.checkPasswordStrengths(newValue)
                    .catch((err) => console.error('Unable to check the changed password strength.', err));
            }
        );
    };

    render() {
        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            let unableSendMessage = null;
            if (this.state.sendNewPassword) {
                const withoutEmailIndex = this.props.selectedUsers.findIndex((usr) => !usr.Email1Address);
                if (withoutEmailIndex !== -1) {
                    unableSendMessage = Strings.formatString(myStrings.unableSendNewPasswordFormat, this.props.selectedUsers[withoutEmailIndex].FileAs || this.props.selectedUsers[withoutEmailIndex].Username);
                }
            }

            return (
                <WizardModal show={true} onHide={this.dismiss}>
                    <WizardModal.Body>
                        <h1>{myStrings.resetPassword}</h1>
                        <div className="mb-4">
                            {this.props.selectedUsers.map((user, i) => (
                                <span key={'selected-user-' + i} className="badge badge-info badge-full-font mr-1">
                                    {user.FileAs}
                                </span>
                            ))}
                        </div>
                        <div>
                            <Form ref={this.formReference} validated={this.state.isFormValidated} className="form-application">
                                <Form.Row>
                                    <Col>
                                        <Form.Label className="mb-2">{myStrings.passwordSettings}</Form.Label>
                                        <Form.Group controlId="newUserAutogeneratePassword" className="mb-2">
                                            <Form.Check
                                                type="radio"
                                                label={myStrings.autogeneratePassword}
                                                checked={this.state.autoGeneratePassword}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ autoGeneratePassword: e.target.checked, sendNewPassword: true })}
                                            />
                                        </Form.Group>
                                        <Form.Group controlId="newUserTypePassword">
                                            <Form.Check
                                                type="radio"
                                                label={myStrings.letMeCreatePassword}
                                                checked={!this.state.autoGeneratePassword}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ autoGeneratePassword: !e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    {!this.state.autoGeneratePassword && this.props.selectedUsers.length > 1 && (
                                        <div className="alert alert-warning ml-4" role="alert">
                                            {myStrings.usersWillHaveSamePassword}
                                        </div>
                                    )}
                                    <PasswordInput
                                        disabled={this.state.autoGeneratePassword}
                                        usernames={this.props.selectedUsers.map(user => user.Username)}
                                        invalid={!this.state.autoGeneratePassword && this.state.isFormValidated && !PasswordHelper.areStrongEnough(this.state.passwordStrengths)}
                                        minPasswordLength={this.props.minimumPasswordLength}
                                        onChange={this.onPasswordChange}
                                        value={this.state.password}
                                        passwordStrengths={this.state.passwordStrengths}
                                    />
                                </Form.Row>
                                <Form.Row className="mt-3">
                                    <Col>
                                        <Form.Group controlId="newUserForcePassword" className="mb-0">
                                            <Form.Check
                                                type="checkbox"
                                                label={myStrings.forceChangePasswordOnLogin}
                                                checked={this.state.forceChangePassword}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ forceChangePassword: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                <Form.Row>
                                    <Col>
                                        <Form.Group controlId="newUserSendPassword" className="mb-0">
                                            <Form.Check
                                                type="checkbox"
                                                label={myStrings.sendNewPassword}
                                                checked={this.state.sendNewPassword}
                                                onChange={(e: IFormCheckChangeEvent) => this.setState({ sendNewPassword: e.target.checked })}
                                            />
                                        </Form.Group>
                                    </Col>
                                </Form.Row>
                                {!!unableSendMessage &&
                                    <div className="alert alert-danger mt-2">
                                        {unableSendMessage}
                                    </div>
                                }
                            </Form>
                        </div>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={() => void this.submit()} disabled={!!unableSendMessage}>{myStrings.reset}</Button>
                        <Button variant="outline-secondary" onClick={this.dismiss}>{Strings.cancel}</Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}
