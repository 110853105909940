import { Spinner, SpinnerVariant } from '@eway-crm/gui';
import React from 'react';
import FolderContainer from './FolderContainer';

type TLoadingFolderContainerProps = {
    title: string;
    subtitle: string;
};

const LoadingFolderContainer: React.FC<TLoadingFolderContainerProps> = ({ title, subtitle }) => {
    return (
        <FolderContainer
            title={title}
            subtitle={subtitle}
            anyChangeMade={false} // Always hide save buttons
        >
            <div className="row flex-fill d-flex justify-content-start mt-3 mx-0">
                <div className="col p-0 align-self-center text-center">
                    <Spinner backgroundOpacity={1} variant={SpinnerVariant.ease}/>
                </div>
            </div>
        </FolderContainer>
    );
};

export default LoadingFolderContainer;
