import type { IApiEnumType, IApiEnumValue, IApiEvent, IApiSetFieldValueAction } from '@eway-crm/connector';
import { FieldNames } from '@eway-crm/connector';
import React from 'react';
import Strings from '../../../strings';
import type { EnumValueAdditionalColumnConfig, TEnumValueEditGridCustomCellProps } from '../../shared/dropDowns/EnumValuesEditGrid';
import EnumValuesEditGrid from '../../shared/dropDowns/EnumValuesEditGrid';
import type { IDropdownOption} from '@fluentui/react';
import { Dropdown, mergeStyles, TooltipHost } from '@fluentui/react';
import FolderNames from '../../../data/constants/FolderNames';
import StringHelper from '../../../helpers/StringHelper';
import type { TStageAttribute } from './WorkflowModelContainer';
import memoizeOne from 'memoize-one';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import EnumValuesColumns from '../../shared/dropDowns/EnumValuesColumns';
import LocalizationHelper from '../../../helpers/LocalizationHelper';

const myStrings = Strings.components.routes.workflow;
const myDropdownStrings = Strings.components.routes.fields.dropDowns;

const STAGE_ATTRIBUTES_COL_NAME = 'stageAttributes';

const LEADS_STAGE_ATTRIBUTES = [...Array<never>(9)].map((_, idx) => {
    const percentDecimal = (idx + 1) / 10;
    const value = `${percentDecimal * 100}%`;
    return { key: percentDecimal.toString(), text: value };
});

const NO_SELECTED_STAGE_ATTRIBUTE_KEY = 'NO_SELECTED_ATTRIBUTE';
const NO_SELECTED_STAGE_ATTRIBUTE = { key: NO_SELECTED_STAGE_ATTRIBUTE_KEY, text: `<${myStrings.noSelectedAttribute}>` };
const NO_SELECTED_STAGE_ATTRIBUTE_IN_LEADS = { key: NO_SELECTED_STAGE_ATTRIBUTE_KEY, text: `<${myStrings.noSelectedAttributeInLeads}>` };

type TSimpleWorkflowEditorProps = {
    wfSteps: IApiEnumValue[];
    onDataChange: (newEnumValues: Partial<IApiEnumValue>[], callback?: () => void) => void;
    enumType: Pick<IApiEnumType, 'EnumName' | 'AllowEditVisibility' | 'AllowEditLastActivity' | 'RequireDefaultValue' | 'IsSystem' | 'IsAdditionalField' | 'EditMode'>;
    folderName: string;
    stageAttributes: TStageAttribute[] | null;
    onUpdateStageAttributes: (gsName: string, value: string) => Promise<void>;
    disabled: boolean;
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumn: (language: string) => Promise<void>;
};

export default class SimpleWorkflowEditor extends React.PureComponent<TSimpleWorkflowEditorProps> {
    private readonly onStageAttributeChange = (rowId: string, newValue: string) => {
        const stageName = StringHelper.capitalize(Strings.getLanguage()) as keyof IApiEnumValue;
        const allTechnicalNames = this.props.wfSteps.map((step) => step.FileAs);

        const rows = this.props.wfSteps;
        const changedRows: Partial<IApiEnumValue>[] = rows.map((row) => {
            if (row.ItemGUID === rowId && this.props.stageAttributes) {
                let newStageAttribute = this.props.stageAttributes.find((attr) => attr.value === newValue);
                if (!newStageAttribute && newValue !== NO_SELECTED_STAGE_ATTRIBUTE_KEY) {
                    // GS with name of stage is currently null, so we have to update it in DB
                    newStageAttribute = this.props.stageAttributes.find(attr => attr.getTranslation(LocalizationHelper.getLocalizedContent(Strings, 'en')) === newValue)!;
                    if (newStageAttribute) {
                        newStageAttribute.value = newStageAttribute?.getTranslation(LocalizationHelper.getLocalizedContent(Strings, 'en'));
                        void this.props.onUpdateStageAttributes(newStageAttribute.name, newStageAttribute.value);
                    }
                }

                const currentStageNameValue = row[stageName] as string;
                let newFileAs = row.FileAs;

                const allStageAttributeValues = this.props.stageAttributes?.map((attr) => attr.value ?? attr.getTranslation(LocalizationHelper.getLocalizedContent(Strings, 'en')));
                if (allStageAttributeValues.includes(newValue)) {
                    // Change to stage attribute
                    newFileAs = newStageAttribute?.value ?? newFileAs;
                } else if (allStageAttributeValues.includes(row.FileAs as string) && allStageAttributeValues.includes(newValue)) {
                    // Change from stage attribute to other stage attribute
                    newFileAs = currentStageNameValue;
                    let newNumber = 2;
                    while (allTechnicalNames.includes(newFileAs)) {
                        newFileAs = `${currentStageNameValue} (${newNumber})`;
                        newNumber++;
                    }
                } else if (allStageAttributeValues.includes(row.FileAs as string) && !allStageAttributeValues.includes(newValue)) {
                    // Change from stage attribute to normal
                    newFileAs = currentStageNameValue;
                    let newNumber = 2;
                    while (allStageAttributeValues.includes(newFileAs)) {
                        newFileAs = `${currentStageNameValue} (${newNumber})`;
                        newNumber++;
                    }
                }

                if (this.props.folderName === FolderNames.leads) {
                    if (newValue !== NO_SELECTED_STAGE_ATTRIBUTE_KEY) {
                        let actions: IApiSetFieldValueAction[] = [
                            {
                                __type: 'SetFieldValueAction:#EA',
                                FieldName: FieldNames.Leads.Probability,
                                NewValue: newValue,
                                Name: 'SetProbabilityValueAction',
                                ExecuteOnlyOnce: false,
                                Relevance: [{ GroupName: null, IsForAllGroups: true, CompletionLevelName: 'CannotIgnore' }],
                            },
                        ];

                        if (newStageAttribute && newStageAttribute.getSetFieldValueActions) {
                            actions = newStageAttribute.getSetFieldValueActions();
                        }

                        return {
                            ...row,
                            FileAs: newFileAs,
                            AllActionEvents: [
                                {
                                    TypeName: 'OnSaving',
                                    Actions: actions,
                                },
                            ],
                        };
                    }
                }
                return { ...row, FileAs: newFileAs, AllActionEvents: [] };
            }
            return row;
        });

        this.props.onDataChange(changedRows);
    };

    private readonly customChangeHandler = (row: Partial<IApiEnumValue>, changedRow: Partial<IApiEnumValue>): Partial<IApiEnumValue> => {
        const stageName = StringHelper.capitalize(Strings.getLanguage()) as keyof IApiEnumValue;
        const result = { ...row, ...changedRow };
        const sameOrEmpty = (getter: (r: Partial<IApiEnumValue>) => string | null | undefined): boolean => {
            const resultStr = getter(result);
            const rowStr = getter(row);
            return (!resultStr && !rowStr) || (resultStr === row[stageName] && rowStr === row[stageName]);
        };

        if (
            sameOrEmpty((r) => Strings.switch(r.Cs as string | null, r.En as string | null)) &&
            sameOrEmpty((r) => r.De) &&
            sameOrEmpty((r) => r.Sk) &&
            sameOrEmpty((r) => r.Ru) &&
            sameOrEmpty((r) => r.No)
        ) {
            const stageNameValue = result[stageName] as string | null;

            result.En = stageNameValue;
            result.Cs = stageNameValue;
            result.De = stageNameValue;
            result.Sk = stageNameValue;
            result.Ru = stageNameValue;
            result.No = stageNameValue;

            if (this.props.stageAttributes && !this.props.stageAttributes.map((attr) => attr.value).includes(row.FileAs as string)) {
                const allTechnicalNames = this.props.wfSteps.map((step) => step.FileAs);
                let fileAsValue = stageNameValue;
                if (allTechnicalNames.includes(stageNameValue)) {
                    let newNumber = 2;
                    while (allTechnicalNames.includes(fileAsValue)) {
                        fileAsValue = `${fileAsValue} (${newNumber})`;
                        newNumber++;
                    }
                }
                result.FileAs = fileAsValue;
            }
        }
        return result;
    };

    private readonly getActionValue = (allActionEvents?: IApiEvent[] | null, options?: IDropdownOption[]) => {
        if (!allActionEvents || allActionEvents.length === 0) {
            return null;
        }
        const savingActionEvents = allActionEvents.filter((ac) => ac.TypeName === 'OnSaving').flatMap((ac) => ac.Actions);
        const probabilitySetFieldValueAction = savingActionEvents.find((ac) => ac.__type === 'SetFieldValueAction:#EA' && ac.FieldName === FieldNames.Leads.Probability) as IApiSetFieldValueAction;

        if (probabilitySetFieldValueAction?.NewValue) {
            const isValueInOptions = options?.some(o => o.key === probabilitySetFieldValueAction?.NewValue);
            if (isValueInOptions) {
                return probabilitySetFieldValueAction.NewValue;
            }
        }

        return null;
    };

    private readonly StageAttributesTableCell: React.FC<TEnumValueEditGridCustomCellProps> = (props) => {
        const invalidRowsAndColumns = EnumValuesValidator.getInvalidRowsAndColumns(this.props.wfSteps);

        const typedRow = props.row as IApiEnumValue;
        let className = mergeStyles('align-middle', this.props.disabled && 'text-black-50');

        if (invalidRowsAndColumns.has(typedRow.ItemGUID)) {
            if (invalidRowsAndColumns.get(typedRow.ItemGUID)!.findIndex((c) => c === props.column.name) !== -1) {
                className += ' bg-danger text-white';
            }
        }

        const options: IDropdownOption[] = this.props.folderName === FolderNames.leads ? [NO_SELECTED_STAGE_ATTRIBUTE_IN_LEADS, ...LEADS_STAGE_ATTRIBUTES] : [NO_SELECTED_STAGE_ATTRIBUTE];
        const allTechnicalNames = this.props.wfSteps.map((step) => step.FileAs);
        options.push(...this.props.stageAttributes!.map((attr) => ({ key: attr.value ?? attr.getTranslation(LocalizationHelper.getLocalizedContent(Strings, 'en')), text: attr.getTranslation(Strings) ?? '', disabled: allTechnicalNames.includes(attr.value) && attr.value !== typedRow.FileAs })));

        if (this.props.folderName === FolderNames.leads) {
            options.sort((a, b) => a.text.localeCompare(b.text, undefined, { numeric: true, sensitivity: 'base' })); // Sort win probability percentage
        }

        const selectedKey = this.props.stageAttributes!.find((attr) => attr.value === typedRow.FileAs)?.value ?? this.getActionValue(typedRow.AllActionEvents, options) ?? NO_SELECTED_STAGE_ATTRIBUTE_KEY;
        const stageName = StringHelper.capitalize(Strings.getLanguage()) as keyof IApiEnumValue;
        return (
            <td className={`${className} p-0`}>
                <Dropdown
                    disabled={this.props.disabled}
                    options={options}
                    calloutProps={{ className: 'enable-tooltip-in-dropdown' }}
                    selectedKey={selectedKey}
                    onChange={(e, option) => {
                        option && this.onStageAttributeChange(typedRow.ItemGUID, option.key as string);
                    }}
                    onRenderOption={(option) => {
                        if (option && option?.disabled) {
                            const alreadyUsedInStageName = this.props.wfSteps.find((step) => step.FileAs === option.key)?.[stageName] as string;
                            return <TooltipHost content={Strings.formatString(myStrings.optionAlreadyUsedInOtherStage, alreadyUsedInStageName) as string}>{option?.text}</TooltipHost>;
                        }
                        return <>{option?.text}</>;
                    }}
                    dropdownWidth={this.props.folderName === FolderNames.leads ? 200 : 150}
                />
            </td>
        );
    };

    private readonly getAdditionalColumns = memoizeOne((folderName: string, stageAttributes: TStageAttribute[] | null): EnumValueAdditionalColumnConfig[] | null => {
        if (!stageAttributes) {
            return null;
        }
        return [
            {
                columnName: STAGE_ATTRIBUTES_COL_NAME,
                title: folderName === FolderNames.leads ? myStrings.winProbability : myDropdownStrings.stageAttributes,
                cellComponent: this.StageAttributesTableCell,
                align: 'center',
                width: 120,
            },
        ];
    });

    private readonly getDisabledColumns = memoizeOne((stageAttributes: TStageAttribute[] | null) => (stageAttributes ? [EnumValuesColumns.EDIT_GRID_COLS.technicalName] : undefined));

    render() {
        return (
            <div className="flex-fill h-100 workflow-table">
                <EnumValuesEditGrid
                    data={this.props.wfSteps}
                    displayLanguageColumns={this.props.displayLanguageColumns}
                    enumType={this.props.enumType}
                    onDataChange={this.props.onDataChange}
                    hiddenColumns={[EnumValuesColumns.EDIT_GRID_COLS.isDefault]}
                    toggleDisplayLanguageColumns={this.props.toggleDisplayLanguageColumn}
                    additionalColumns={this.getAdditionalColumns(this.props.folderName, this.props.stageAttributes)}
                    disabledColumns={this.getDisabledColumns(this.props.stageAttributes)}
                    isReadonly={this.props.disabled}
                    customChangeHandler={this.props.stageAttributes ? this.customChangeHandler : undefined}
                    addNewValuesToBottom
                    languageColumnWidth={150}
                    gridHeight={250} // Min height for grid
                    hasWorkflowColumnHeaders
                />
            </div>
        );
    }
}
