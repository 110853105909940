const activityEvents = [
    'mousedown', 'keydown',
    'scroll', 'touchstart'
];
const inactivityIntervalSeconds = 5;

export class ActivityWatcher {
    private secondsSinceLastActivity = 0;

    readonly start = () => {
        activityEvents.forEach((eventName: string) => {
            document.addEventListener(eventName, this.handleActivity, true);
        });
        setInterval(this.handleTimerTick, inactivityIntervalSeconds * 1000);
    };

    readonly getInactivitySeconds = (): number => {
        return this.secondsSinceLastActivity;
    };

    readonly fakeActivity = () => {
        this.handleActivity();
    };

    private readonly handleActivity = () => {
        this.secondsSinceLastActivity = 0;
    };

    private readonly handleTimerTick = () => {
        this.secondsSinceLastActivity = this.secondsSinceLastActivity + inactivityIntervalSeconds;
    };
}