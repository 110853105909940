import React, { useContext } from 'react';
import { autosuggestContext } from './AutosuggestContext';
import './Autosuggest.scss';
import { mergeStyles } from '@fluentui/react';
import type { TAutosuggestItem } from './Autosuggest';
import { NEW_SUGGESTION_KEY } from './Autosuggest';
import useListArrowFocus from '../../../helpers/hooks/useListArrowFocus';
import useDetectKeydown from '../../../helpers/hooks/useDetectKeydown';
import './Autosuggest.scss';
import Strings from '../../../strings';

type TAutosuggestItemsProps = React.HTMLProps<HTMLDivElement>;

const myStrings = Strings.components.routes.workflow;

const AutosuggestItems: React.FC<TAutosuggestItemsProps> = () => {
    const { value, setIsInputFocused, isSuggestOpen, suggestions, onValueSelection, setIsSuggestOpen } = useContext(autosuggestContext);
    const [currentFocus] = useListArrowFocus(suggestions.length);
    const areItemsHidden = !isSuggestOpen || !suggestions || suggestions.length === 0;

    const onSuggestionSelect = (item: TAutosuggestItem) => {
        onValueSelection(item.value);
    };

    useDetectKeydown((key) => {
        if (key === 'Enter' && !areItemsHidden && !currentFocus) {
            onSuggestionSelect({ key: value, value });
        }
        if (key === 'Escape' && !areItemsHidden) {
            setIsSuggestOpen(false);
        }
        if (key === 'Tab') {
            setIsSuggestOpen(false);
            setIsInputFocused(false);
        }
    });

    if (areItemsHidden) {
        return null;
    }

    return (
        <div className={mergeStyles('autosuggest__items')}>
            {suggestions.map((item, idx) => {
                return <AutosuggestItem key={item.key} isFocused={idx === currentFocus - 1} item={item} onSuggestionSelect={onSuggestionSelect} />;
            })}
        </div>
    );
};

const AutosuggestItem: React.FC<{ item: TAutosuggestItem; isFocused: boolean; onSuggestionSelect: (item: TAutosuggestItem) => void }> = ({ item, isFocused, onSuggestionSelect }) => {
    useDetectKeydown((key) => {
        if (key === 'Enter' && isFocused) {
            onSuggestionSelect(item);
        }
    });

    const onClick = () => {
        onSuggestionSelect(item);
    };

    const isCreateNewItem = item.key === NEW_SUGGESTION_KEY;

    return (
        <div
            key={item.key}
            title={item.value}
            className={mergeStyles('autosuggest__item', isFocused && 'focused', isCreateNewItem && 'autosuggest__newItem alert-success')}
            onClick={onClick}
        >
            {isCreateNewItem ? `${myStrings.create} "${item.value}"` : item.value}
        </div>
    );
};

export default AutosuggestItems;
