import * as React from 'react';
import { Button } from 'react-bootstrap';
import Strings from '../../../strings';
import type { TPaymentGateProvider } from '../../../data/shopping/IPaymentMethodsModel';
import type { ShoppingConnection } from "../../ShoppingConnection";
import { BraintreeInvoicePayer } from './BraintreeInvoicePayer';
import { StripeInvoicePayer } from './StripeInvoicePayer';

type TInvoicePayerProps = {
    shoppingConnection: ShoppingConnection;
    paymentGateProvider: TPaymentGateProvider;
    invoiceId: number;
    priceToPay: number;
    currencyCode: string;
    invoiceNumber: string;
    onDone: () => Promise<void>;
};

export const InvoicePayer: React.FC<TInvoicePayerProps> = (props) => {
    const [isShown, setIsShown] = React.useState<boolean>(false);

    const show = React.useCallback(() => setIsShown(true), [setIsShown]);
    const cancel = React.useCallback(() => setIsShown(false), [setIsShown]);

    const myStrings = Strings.components.routes.purchaseHistory;
    return (
        <>
            <Button variant="link" className="p-0 border-0 align-baseline" onClick={show}>{myStrings.payNow}</Button>
            {(!!isShown) &&
                <>
                    {(props.paymentGateProvider === 'Braintree') &&
                        <BraintreeInvoicePayer
                            shoppingConnection={props.shoppingConnection}
                            invoiceId={props.invoiceId}
                            priceToPay={props.priceToPay}
                            currencyCode={props.currencyCode}
                            invoiceNumber={props.invoiceNumber}
                            onDone={props.onDone}
                            onCancel={cancel}
                        />
                    }
                    {(props.paymentGateProvider === 'Stripe') &&
                        <StripeInvoicePayer
                            shoppingConnection={props.shoppingConnection}
                            invoiceId={props.invoiceId}
                            priceToPay={props.priceToPay}
                            currencyCode={props.currencyCode}
                            invoiceNumber={props.invoiceNumber}
                            onDone={props.onDone}
                            onCancel={cancel}
                        />
                    }
                </>
            }
        </>);
};