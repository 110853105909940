import * as React from 'react';
import Strings from './../../../strings';
import WizardModal from './../../WizardModal';
import { Button, Nav, Tab } from 'react-bootstrap';
import type { IApiColumn, IApiColumnPermission, IApiDataResponse, IApiEnumType, IApiAdditionalField, IApiDatumResponse, TNumericValidatorType, IApiGroup } from '@eway-crm/connector';
import { EnumTypeEditMode } from '@eway-crm/connector';
import { AdditionalFieldTypes } from '../../../data/constants/AdditionalFieldTypes';
import { SummaryTypes } from '../../../data/constants/SummaryTypes';
import { ValidatorTypes } from '../../../data/constants/ValidatorTypes';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { LinkTypes } from '../../../data/constants/LinkTypes';
import { FieldGeneralForm } from './FieldGeneralForm';
import type { TEnumValue } from '../../shared/dropDowns/EnumValuesEditGrid';
import EnumValuesEditGrid from '../../shared/dropDowns/EnumValuesEditGrid';
import FieldPermissionsWarningDialog from './FieldPermissionsWarningDialog';
import { GroupNames } from '../../../data/constants/GroupNames';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import FieldPermissionsForm from './FieldPermissionsForm';
import { ItemStorage } from '../../../data/ItemStorageWithDelayedSave';
import GeneralFieldPermissionToggles from './GeneralFieldPermissionToggles';
import { DelayedRender, mergeStyles, TooltipHost } from '@fluentui/react';
import Fields from '../Fields';
import FieldTypes from '../../../data/constants/FieldTypes';
import FolderNames from '../../../data/constants/FolderNames';
import type { TFieldPermissionWithCol } from './FieldPermissionsGrid';
import { RemoteItemStore } from '../../../RemoteItemStore';
import ReactHelper from '../../../helpers/ReactHelper';
import SearchResultsObtainer from '../home/SearchBar/SearchResultsObtainer';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import InvalidEnumValuesDialog from './InvalidEnumValuesDialog';
import type { TFieldWithCol } from './FolderFields';
import FieldLicenseRestriction from './FieldLicenseRestriction';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import LicenseRestrictionsLockedSplash from '../../shared/locks/FeatureLockedSplash';
import { SpinnerVariant } from '@eway-crm/gui';
import type { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import FieldWizardHelper from './FieldWizardHelper';

const GENERAL_TAB_KEY = 'general';
const FIELD_PERMISSIONS_TAB_KEY = 'fieldPermissions';

type TEditFieldWizardProps = {
    onDismiss: () => void;
    onDone: () => void;
    availableFolderNames: string[];
    additionalFieldItem: IApiAdditionalField | null;
    enumValues: TEnumValue[] | null;
    enumType: IApiEnumType | null;
    isLastActivityGsEnabled: boolean;
    fieldPermissions: TFieldPermissionWithCol[];
    folderName: string;
    allGroups: IApiGroup[];
    currentColumn: IApiColumn;
    allColumns: IApiColumn[];
    displayLanguageColumns: string[];
    allFields: TFieldWithCol[];
};

type TEditFieldWizardState = {
    currentTabKey: string;
    isGeneralFormValidated: boolean;
    name: string;
    comment: string;
    editMask: string | null;
    linkType: string | null;
    numberOfRows: number | null;
    summaryType: string | null;
    numberFormat: TNumericValidatorType | null;
    showTime: boolean;
    relatedFolderName: string | null;
    enumValues: Partial<TEnumValue>[];
    anyEnumValuesChange: boolean;
    isSaving: boolean;
    anyChangeMade: boolean;
    fieldPermissions: TFieldPermissionWithCol[];
    searchedString: string;
    uniqueNotFullyPermittedForGroupName: string | null;
    nonUniqueValue: string | null;
    isCheckingForDuplicateValues: boolean;
    displayLanguageColumns: string[];
    showInvalidEnumValuesError: boolean;
};

const myStrings = Strings.components.routes.fields;

export default class EditFieldWizard extends React.Component<TEditFieldWizardProps, TEditFieldWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly generalFormReference: React.RefObject<FieldGeneralForm>;
    private readonly fieldPermissionsStorage: ItemStorage<TFieldPermissionWithCol>;
    private readonly enumValuesEditGridRef: React.RefObject<typeof VirtualTable>;

    constructor(props: TEditFieldWizardProps) {
        super(props);
        this.generalFormReference = React.createRef();
        this.fieldPermissionsStorage = new ItemStorage((item) => item.GroupGuid);
        this.enumValuesEditGridRef = React.createRef();

        this.state = {
            currentTabKey: GENERAL_TAB_KEY,
            isGeneralFormValidated: false,
            name: Fields.getColumnName(props.currentColumn),
            comment: props.currentColumn.Type,
            editMask: null,
            linkType: null,
            numberOfRows: null,
            summaryType: null,
            numberFormat: null,
            showTime: false,
            relatedFolderName: null,
            enumValues: props.enumValues || [],
            anyEnumValuesChange: false,
            isSaving: false,
            anyChangeMade: false,
            fieldPermissions: props.fieldPermissions,
            searchedString: '',
            uniqueNotFullyPermittedForGroupName: null,
            nonUniqueValue: null,
            isCheckingForDuplicateValues: false,
            displayLanguageColumns: props.displayLanguageColumns,
            showInvalidEnumValuesError: false,
        };
        if (props.additionalFieldItem) {
            this.state = {
                ...this.state,
                name: props.additionalFieldItem.Name,
                comment: props.additionalFieldItem.Comment || '',
                editMask: props.additionalFieldItem.Data_EditMask || '',
                linkType: props.additionalFieldItem.Data_LinkType || LinkTypes.custom,
                numberOfRows: props.additionalFieldItem.Data_MemoBoxLines || 3,
                summaryType: props.additionalFieldItem.Data_SummaryType || SummaryTypes.average,
                numberFormat: props.additionalFieldItem.Data_FormatType || ValidatorTypes.int,
                showTime: props.additionalFieldItem.Data_IsDateTime || false,
                relatedFolderName: props.additionalFieldItem.Data_RelatedFolderName || props.availableFolderNames[0],
            };
        }
    }

    private readonly dismissEnumValuesError = () => {
        this.setState({ showInvalidEnumValuesError: false });
    };

    private readonly submit = () => {
        if (this.generalFormReference.current && !this.generalFormReference.current.isFormValid()) {
            this.setState({ isGeneralFormValidated: true });
            return;
        }
        if (this.state.enumValues) {
            const invalidRowsAndColumns = EnumValuesValidator.getInvalidRowsAndColumns(this.state.enumValues);
            if (invalidRowsAndColumns.size !== 0) {
                FieldWizardHelper.scrollToInvalidEnumValueRow(
                    invalidRowsAndColumns,
                    this.state.displayLanguageColumns,
                    this.toggleDisplayLanguageColumns,
                    this.enumValuesEditGridRef
                );

                this.setState({ showInvalidEnumValuesError: true });
                return;
            }
        }

        this.setState({ isSaving: true }, () => {
            (async () => {
                const { askApi } = this.context.connection;

                if (this.props.additionalFieldItem) {
                    const transmitObject: Partial<IApiAdditionalField> = {
                        ItemGUID: this.props.additionalFieldItem.ItemGUID,
                        ItemVersion: this.props.additionalFieldItem.ItemVersion + 1,
                        Name: this.state.name.trim(),
                        FileAs: this.state.name.trim(),
                        Comment: this.state.comment,
                    };
                    if (this.props.additionalFieldItem.Type === AdditionalFieldTypes.textBox || this.props.additionalFieldItem.Type === AdditionalFieldTypes.linkTextBox) {
                        transmitObject.Data_EditMask = this.state.editMask || null;
                        if (this.props.additionalFieldItem.Type === AdditionalFieldTypes.linkTextBox) {
                            transmitObject.Data_LinkType = this.state.linkType;
                        }
                    }
                    if (this.props.additionalFieldItem.Type === AdditionalFieldTypes.memoBox) {
                        transmitObject.Data_MemoBoxLines = this.state.numberOfRows;
                    }
                    if (this.props.additionalFieldItem.Type === AdditionalFieldTypes.numericBox) {
                        transmitObject.Data_FormatType = this.state.numberFormat;
                        transmitObject.Data_SummaryType = this.state.summaryType;
                    }
                    if (this.props.additionalFieldItem.Type === AdditionalFieldTypes.dateEdit) {
                        transmitObject.Data_IsDateTime = this.state.showTime;
                    }

                    await askApi('SaveAdditionalField', { transmitObject: transmitObject });
                }

                if (this.props.enumType && this.state.anyEnumValuesChange) {
                    const etTransmitObject = {
                        ItemGUID: this.props.enumType.ItemGUID,
                        EnumValuesInEnumType: this.state.enumValues.map((ev, i) => ({
                            ItemGUID: ev.ItemGUID,
                            ItemVersion: ev.ItemVersion! + 1,
                            Rank: i + 1,
                            FileAs: ev.FileAs,
                            En: ev.En,
                            Cs: ev.Cs,
                            De: ev.De,
                            Ru: ev.Ru,
                            No: ev.No,
                            Sk: ev.Sk,
                            IsDefault: ev.IsDefault,
                            IsVisible: ev.IsVisible,
                            IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
                        })),
                    };
                    await askApi('SaveEnumType', { transmitObject: etTransmitObject });
                }

                const fieldPermissionsToSave = this.fieldPermissionsStorage.takeItems();
                let transmitObjects = fieldPermissionsToSave.map((item) => {
                    return {
                        GroupGuid: item.GroupGuid,
                        FolderName: item.FolderName,
                        ColumnName: item.ColumnName,
                        PermissionRule: item.PermissionRule,
                        MandatoryRule: item.MandatoryRule,
                    };
                });

                const copyChangesToCols = this.getCopyChangesToCols();
                if (copyChangesToCols) {
                    // Copy Permission level changes of OriginalCurrencyColumn to dependant fields

                    const getParticularColsPermissionsToSave = async (columnName: string) => {
                        const columnPermissionsRes = await askApi<IApiDataResponse<IApiColumnPermission>>('SearchColumnPermissions', {
                            includeDefaultPermissions: true,
                            transmitObject: {
                                ColumnName: columnName,
                                FolderName: this.props.folderName,
                            },
                        });

                        return fieldPermissionsToSave.map((item) => {
                            const currentCol = columnPermissionsRes.Data.find((currentCol) => currentCol.GroupGuid === item.GroupGuid && columnName === currentCol.ColumnName)!;
                            return {
                                GroupGuid: currentCol.GroupGuid,
                                FolderName: currentCol.FolderName,
                                ColumnName: currentCol.ColumnName,
                                PermissionRule: item.PermissionRule,
                                MandatoryRule: currentCol.MandatoryRule,
                            };
                        });
                    };

                    const copyChangesToColsPermissions = await Promise.all(copyChangesToCols.map((col) => getParticularColsPermissionsToSave(col.ColumnName)));
                    transmitObjects = transmitObjects.concat(copyChangesToColsPermissions.flatMap((cp) => cp));
                }

                if (transmitObjects.length !== 0) {
                    await askApi('SaveColumnPermissions', {
                        transmitObjects: transmitObjects,
                    });
                }

                SearchResultsObtainer.setShouldReloadOnNextSearch(true);
                this.props.onDone();
            })()
                .catch((err) => console.error('Unable to submit field wizard.', err));
        });
    };

    private readonly getSystemGroupPermissions = () => {
        return this.state.fieldPermissions.find((fp) => fp.colName === GroupNames.system)!;
    };

    private readonly getSystemGroup = () => {
        return this.props.allGroups.find((group) => group.GroupName === GroupNames.system)!;
    };

    private readonly changeSystemGroupValues = (permissionRule: string | null, mandatoryRule: string | null) => {
        this.setState({
            fieldPermissions: this.state.fieldPermissions.map((fp) => {
                if (fp.colName === GroupNames.system) {
                    const newFp = { ...fp, PermissionRule: permissionRule ?? fp.PermissionRule, MandatoryRule: mandatoryRule ?? fp.MandatoryRule };
                    this.fieldPermissionsStorage.addItem(newFp);
                    return newFp;
                }
                return fp;
            }),
            anyChangeMade: true,
        });
    };

    private readonly onSystemGroupChange = (permissionName: string) => {
        const permissions = GeneralFieldPermissionToggles.onSystemGroupChange(permissionName, this.getSystemGroupPermissions(), this.setFieldToUnique);
        if (permissions) {
            this.changeSystemGroupValues(permissions.permissionRule, permissions.mandatoryRule);
        }
    };

    private readonly setFieldToUnique = () => {
        const notFullyPermittedFieldPermissions = this.state.fieldPermissions.filter((fp) => fp.PermissionRule !== FieldPermissionNames.PermissionRuleKeys.All);
        if (notFullyPermittedFieldPermissions.length > 0 && !(notFullyPermittedFieldPermissions.length === 1 && notFullyPermittedFieldPermissions[0] === this.getSystemGroupPermissions())) {
            this.setState({ uniqueNotFullyPermittedForGroupName: this.props.allGroups.find((ag) => ag.ItemGUID === notFullyPermittedFieldPermissions[0].GroupGuid)!.GroupName });
        } else {
            this.setState({ isCheckingForDuplicateValues: true }, () => {
                this.context.connection.callApi(
                    'CheckForDuplicatedValues',
                    { columnName: this.props.currentColumn.ColumnName, folderName: this.props.folderName },
                    (res: IApiDatumResponse<{ IsThereAnyDuplicatedValue: boolean; DuplicatedValue: string }>) => {
                        if (res.Datum.IsThereAnyDuplicatedValue) {
                            this.setState({ nonUniqueValue: res.Datum.DuplicatedValue, isCheckingForDuplicateValues: false });
                        } else {
                            this.setState({ isCheckingForDuplicateValues: false }, () => {
                                this.changeSystemGroupValues(FieldPermissionNames.PermissionRuleKeys.All, FieldPermissionNames.MandatoryRuleKeys.Unique);
                            });
                        }
                    }
                );
            });
        }
    };

    private readonly getCopyChangesToCols = () => this.props.allColumns.filter((col) => col.FolderName === this.props.folderName && col.OriginalCurrencyColumn === this.props.currentColumn.ColumnName);

    private readonly getCopyChangesToFolderNameNote = () => {
        let copyChangesToFolderNameNote: string | null = null;
        if (this.props.folderName === FolderNames.goods) {
            if (!!this.props.additionalFieldItem) {
                copyChangesToFolderNameNote = Strings.formatString(myStrings.changesWillBeAlsoDuplicatedToFolderNameSingular, FolderNames.getPluralName(FolderNames.goodsInCart)) as string;
            } else {
                copyChangesToFolderNameNote = Strings.formatString(
                    myStrings.changesWillBeAlsoDuplicatedToFolderNamePlural,
                    `${FolderNames.getPluralName(FolderNames.goodsInCart)} ${Strings.and} ${FolderNames.getPluralName(FolderNames.goodsInSet)}`
                ) as string;
            }
        } else if (this.props.folderName === FolderNames.goodsInCart) {
            if (!!this.props.additionalFieldItem) {
                copyChangesToFolderNameNote = Strings.formatString(myStrings.changesWillBeAlsoDuplicatedToFolderNameSingular, FolderNames.getPluralName(FolderNames.goods)) as string;
            } else {
                copyChangesToFolderNameNote = Strings.formatString(
                    myStrings.changesWillBeAlsoDuplicatedToFolderNamePlural,
                    `${FolderNames.getPluralName(FolderNames.goods)} ${Strings.and} ${FolderNames.getPluralName(FolderNames.goodsInSet)}`
                ) as string;
            }
        } else if (this.props.folderName === FolderNames.goodsInSet) {
            copyChangesToFolderNameNote = Strings.formatString(
                myStrings.changesWillBeAlsoDuplicatedToFolderNamePlural,
                `${FolderNames.getPluralName(FolderNames.goods)} ${Strings.and} ${FolderNames.getPluralName(FolderNames.goodsInCart)}`
            ) as string;
        }
        return copyChangesToFolderNameNote;
    };

    private readonly getCopyChangesToFieldsNote = () => {
        let note: string | null = null;
        const fieldNames = this.getCopyChangesToCols()
            .map((col) => Fields.getColumnName(col))
            .join(', ');

        if (fieldNames) {
            note = Strings.formatString(myStrings.changesWillBeAlsoDuplicatedToFields, fieldNames) as string;
        }
        return note;
    };

    private readonly getFooterNote = (): string | null => this.getCopyChangesToFolderNameNote() ?? this.getCopyChangesToFieldsNote();

    private readonly isFieldPermissionsTabDisabled = () => {
        const systemFp = this.props.fieldPermissions.find(fp => fp.colName === GroupNames.system);

        if (systemFp && systemFp.col) {
            if (
                systemFp.col.AllowedColumnMandatoryTypes?.length === 1 &&
                systemFp.col.AllowedColumnMandatoryTypes[0] === FieldPermissionNames.MandatoryRuleKeys.None &&
                systemFp.col.AllowedColumnPermissionTypes?.length === 1 &&
                systemFp.col.AllowedColumnPermissionTypes[0] === FieldPermissionNames.PermissionRuleKeys.All
            ) {
                return true;
            }
        }
        return false;
    };

    private readonly toggleDisplayLanguageColumns = async (changedLanguage: string, doNotSaveToUserSettings?: boolean) => {
        const newLanguages = new RemoteItemStore(this.context.connection).toggleFieldsDisplayLanguageColumns(changedLanguage, this.state.displayLanguageColumns, doNotSaveToUserSettings);
        await ReactHelper.setState(this, { displayLanguageColumns: newLanguages });
    };

    private readonly onTabSelect = (key: string | null) => {
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        if (key === FIELD_PERMISSIONS_TAB_KEY && isFieldPermissionsLocked) {
            this.context.showLicenseRestrictionModal(fieldPermissionsLicenseRestriction);
            return;
        }

        this.setState({ currentTabKey: key || GENERAL_TAB_KEY });
    };

    private readonly onEnumValuesChange = (val: Partial<TEnumValue>[], clb?: () => void) => {
        this.setState({ enumValues: val, anyEnumValuesChange: true, anyChangeMade: true }, clb);
    };

    render() {
        if (this.state.isSaving) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        }

        const fieldName = this.props.additionalFieldItem ? this.props.additionalFieldItem.Name : Fields.getColumnName(this.props.currentColumn);
        const footerNote = this.getFooterNote();
        const isFieldPermissionsTabDisabled = this.isFieldPermissionsTabDisabled();
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        return (
            <>
                <WizardModal
                    show={true}
                    size={'lg'}
                    dialogClassName={mergeStyles(this.props.enumType && 'modal-90w modal-max-width-1200')}
                    onHide={this.props.onDismiss}
                    hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}
                    showCancelButtonInFooter={true}
                    dialogBodyExpectedHeight="39rem"
                >
                    <WizardModal.Body>
                        <div className="d-flex justify-content-between">
                            <div>
                                <h1>{fieldName}</h1>
                                <p className="modal-text">{FieldTypes.getLocalizedName(this.props.currentColumn.Type)}</p>
                            </div>
                            {this.state.currentTabKey === GENERAL_TAB_KEY && this.state.enumValues && (
                                <FieldLicenseRestriction
                                    enumValues={this.state.enumValues}
                                    columnName={this.props.currentColumn.ColumnName}
                                    enumType={this.props.enumType}
                                />
                            )}
                        </div>
                        <Tab.Container activeKey={this.state.currentTabKey} onSelect={this.onTabSelect} mountOnEnter>
                            <div className="mt-4">
                                <Nav variant="tabs">
                                    <Nav.Item>
                                        <Nav.Link eventKey={GENERAL_TAB_KEY}>{myStrings.general}</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <TooltipHost content={isFieldPermissionsTabDisabled ? myStrings.fieldPermissionsTabDisabled : undefined}>
                                            <Nav.Link eventKey={FIELD_PERMISSIONS_TAB_KEY} disabled={isFieldPermissionsTabDisabled}>
                                                {myStrings.fieldPermissions} {isFieldPermissionsLocked && <LicenseRestrictionsLockIcon licenseRestriction={fieldPermissionsLicenseRestriction} />}
                                            </Nav.Link>
                                        </TooltipHost>
                                    </Nav.Item>
                                </Nav>
                            </div>
                            <div className="mt-4">
                                <Tab.Content>
                                    <Tab.Pane eventKey={GENERAL_TAB_KEY}>
                                        <>
                                            {this.props.additionalFieldItem ? (
                                                <FieldGeneralForm
                                                    ref={this.generalFormReference}
                                                    isGeneralFormValidated={this.state.isGeneralFormValidated}
                                                    availableFolderNames={this.props.availableFolderNames}
                                                    linkableFolderNames={[this.state.relatedFolderName!]}
                                                    isNew={false}
                                                    itemGuid={this.props.additionalFieldItem.ItemGUID}
                                                    name={this.state.name}
                                                    setName={(val) => this.setState({ name: val, anyChangeMade: true })}
                                                    type={this.props.additionalFieldItem.Type}
                                                    folderNames={[this.props.additionalFieldItem.ObjectTypeFolderName]}
                                                    comment={this.state.comment}
                                                    setComment={(val) => this.setState({ comment: val, anyChangeMade: true })}
                                                    editMask={this.state.editMask!}
                                                    setEditMask={(val) => this.setState({ editMask: val, anyChangeMade: true })}
                                                    linkType={this.state.linkType!}
                                                    setLinkType={(val) => this.setState({ linkType: val, anyChangeMade: true })}
                                                    numberOfRows={this.state.numberOfRows!}
                                                    setNumberOfRows={(val) => this.setState({ numberOfRows: val, anyChangeMade: true })}
                                                    numberFormat={this.state.numberFormat!}
                                                    setNumberFormat={(val) => this.setState({ numberFormat: val, anyChangeMade: true })}
                                                    summaryType={this.state.summaryType!}
                                                    setSummaryType={(val) => this.setState({ summaryType: val, anyChangeMade: true })}
                                                    showTime={this.state.showTime}
                                                    setShowTime={(val) => this.setState({ showTime: val, anyChangeMade: true })}
                                                    relatedFolderName={this.state.relatedFolderName!}
                                                    isRelatedFolderDisabled={true}
                                                    enumTypeName={'AF_' + this.props.additionalFieldItem.FieldId}
                                                    enumValues={this.state.enumValues}
                                                    systemGroupFieldPermissions={this.getSystemGroupPermissions()}
                                                    areSystemPermissionsDisabled={this.getSystemGroup().DisallowControlColumnPermissions}
                                                    setEnumValues={this.onEnumValuesChange}
                                                    onSystemGroupChange={this.onSystemGroupChange}
                                                    allColumns={this.props.allColumns}
                                                    displayLanguageColumns={this.state.displayLanguageColumns}
                                                    toggleDisplayLanguageColumns={this.toggleDisplayLanguageColumns}
                                                    allFields={this.props.allFields}
                                                    folderName={this.props.folderName}
                                                    enumValuesEditGridRef={this.enumValuesEditGridRef}
                                                />
                                            ) : (
                                                <>
                                                    <div className="d-flex flex-row w-100 justify-content-between">
                                                        <GeneralFieldPermissionToggles
                                                            onSystemGroupChange={this.onSystemGroupChange}
                                                            systemGroupFieldPermissions={this.getSystemGroupPermissions()}
                                                            isDisabled={this.getSystemGroup().DisallowControlColumnPermissions}
                                                            isNewField={false}
                                                            allFields={this.props.allFields}
                                                            folderName={this.props.folderName}
                                                        />
                                                    </div>
                                                    {this.props.enumType && this.props.enumValues && (
                                                        <DelayedRender>
                                                            <EnumValuesEditGrid
                                                                data={this.state.enumValues}
                                                                enumType={this.props.enumType}
                                                                onDataChange={this.onEnumValuesChange}
                                                                isLastActivityGsEnabled={this.props.isLastActivityGsEnabled}
                                                                displayLanguageColumns={this.state.displayLanguageColumns}
                                                                toggleDisplayLanguageColumns={this.toggleDisplayLanguageColumns}
                                                                isOnlyPartiallyEditable={this.props.enumType.EditMode === EnumTypeEditMode.VisibleRankDefaultOnly}
                                                                isReadonly={this.props.enumType.EditMode === EnumTypeEditMode.Readonly}
                                                                columnName={this.props.currentColumn.ColumnName}
                                                                tableRef={this.enumValuesEditGridRef}
                                                            />
                                                        </DelayedRender>
                                                    )}
                                                </>
                                            )}
                                            {(this.state.nonUniqueValue !== null || this.state.uniqueNotFullyPermittedForGroupName !== null) && (
                                                <FieldPermissionsWarningDialog
                                                    nonUniqueValue={this.state.nonUniqueValue}
                                                    uniqueNotFullyPermittedForGroupName={this.state.uniqueNotFullyPermittedForGroupName}
                                                    onHide={() => {
                                                        this.setState({ nonUniqueValue: null, uniqueNotFullyPermittedForGroupName: null });
                                                    }}
                                                />
                                            )}
                                        </>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey={FIELD_PERMISSIONS_TAB_KEY}>
                                        {!isFieldPermissionsLocked ? (
                                            <FieldPermissionsForm
                                                allGroups={this.props.allGroups}
                                                fieldPermissions={this.state.fieldPermissions}
                                                searchedString={this.state.searchedString}
                                                onFieldPermissionsChange={(newFieldPermissions, newItemsToSave) => {
                                                    this.fieldPermissionsStorage.addItem(newItemsToSave);
                                                    this.setState({ fieldPermissions: newFieldPermissions, anyChangeMade: true });
                                                }}
                                                onSearchedStringChange={(newSearchedString) => this.setState({ searchedString: newSearchedString })}
                                                gridHeight={this.props.enumType ? '34.25rem' : '25rem'}
                                            />
                                        ) : (
                                            <LicenseRestrictionsLockedSplash className="pt-3" />
                                        )}
                                    </Tab.Pane>
                                </Tab.Content>
                            </div>
                        </Tab.Container>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        {footerNote ? (
                            <div className="d-flex flex-grow-1 justify-content-between align-items-center">
                                <div className="mr-5">{footerNote}</div>
                                <Button variant="primary" onClick={this.submit}>
                                    {Strings.save}
                                </Button>
                            </div>
                        ) : (
                            <Button variant="primary" onClick={this.submit}>
                                {Strings.save}
                            </Button>
                        )}
                    </WizardModal.Footer>
                </WizardModal>
                {this.state.showInvalidEnumValuesError && <InvalidEnumValuesDialog onDismiss={this.dismissEnumValuesError} enumValues={this.state.enumValues} />}
            </>
        );
    }
}
