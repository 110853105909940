import { LocalStorageHelper as GuiLocalStorageHelper } from "@eway-crm/gui";

type TSession = {
    sessionId: string;
    userGuid: string;
    wsUrl: string;
};

export default class LocalStorageHelper extends GuiLocalStorageHelper {
    static names = {
        fieldPermissionsLastSeenRowCacheKey: 'FieldPermissionsLastSeenRow',
        modulePermissionsLastSeenRowCacheKey: 'ModulePermissionsLastSeenRow',
        featuredVideosCacheKey: 'VideosCache',
        searchResultsCacheKey: 'SearchResultsCache',
        searchResultsShouldReloadCacheKey: 'SearchResultsShouldReloadCache',
        availableVersionInfo: 'AvailableVersionInfo',
        ordersHistory: 'ordersHistory',
        session: 'session',
        doNotOpenLoginPopupNextTime: 'doNotOpenLoginPopupNextTime'
    };

    static getSession = () => {
        return LocalStorageHelper.getItem<TSession>(LocalStorageHelper.names.session, true);
    };

    static saveSession = (session: TSession | null) => {
        LocalStorageHelper.setItem(
            LocalStorageHelper.names.session,
            session,
            30 * 24 * 60 * 60 * 1000, //30d
            true
        );

        if (session) {
            LocalStorageHelper.setLocalStorageWsPath(session.wsUrl);
        }
    };
}
