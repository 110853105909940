import { DefaultButton, mergeStyles, mergeStyleSets, PrimaryButton, DefaultEffects, NeutralColors } from '@fluentui/react';
import * as React from 'react';
import type { TPeriodType } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import Strings from "../../../../../strings";
import { Subscriptions } from '../../../Subscriptions';
import StoreHelper from '../../StoreHelper';
import useItemsInCart from '../hooks/useItemsInCart';
import StoreV2Context from '../StoreV2Context';
import SummaryItems from '../SummaryItems/SummaryItems';
import usePackagePriceSum from './hooks/usePackagePriceSum';
import PeriodToggle from './PeriodToggle/PeriodToggle';

const css = mergeStyleSets({
    summary: {
        maxWidth: '500px',
        boxShadow: DefaultEffects.elevation8,
        position: 'sticky',
        top: '10px'
    },
    buttons: {
        marginTop: '2.5rem'
    },
    wouldaPrice: {
        color: NeutralColors.gray70
    },
    bottomSpacing: {
        marginBottom: '2rem'
    }
});

type TSummaryProps = {
    onCheckout: () => void;
};

export const mainDisplayedPeriod: TPeriodType = 'Monthly';

const Summary: React.FC<TSummaryProps> = ({ onCheckout }) => {
    const { customerInfo, storeData, isAnythingAlreadySubscribed, packagesEditingStates, revertToDefaultPackagesStates, selectedPeriodType, currentPlan } = React.useContext(StoreV2Context);
    const items = useItemsInCart();
    const sum = usePackagePriceSum();

    const anyDifference = React.useMemo(() => {
        if (!!currentPlan?.PeriodType && currentPlan.PeriodType !== selectedPeriodType)
            return true;

        let anyDifference = false;
        StoreHelper.iteratePackages(
            storeData,
            (packageData) => {
                if (anyDifference)
                    return;

                if (packageData.OriginalStatus?.IsSubscribed && packageData.OriginalStatus.Quantity) {
                    const ps = packagesEditingStates.find(ps => ps.artno === packageData.Artno);
                    if (!ps || ps.state.currentEditQuantity !== packageData.OriginalStatus?.Quantity) {
                        anyDifference = true;
                    }
                } else {
                    if (packagesEditingStates.some(ps => ps.artno === packageData.Artno && !!ps.state.currentEditQuantity)) {
                        anyDifference = true;
                    }
                }
            }
        );
        return anyDifference;
    }, [storeData, packagesEditingStates, currentPlan?.PeriodType, selectedPeriodType]);

    if (items.length === 0)
        return null;

    const myStrings = Strings.components.routes.subscriptions;

    return (
        <div className={mergeStyles(css.summary, 'p-4 mb-4')}>
            <div className="mb-4">
                <PeriodToggle className={mergeStyles('d-flex justify-content-end', css.bottomSpacing)} />
                <h2 className="text-center mb-1">
                    {anyDifference ? myStrings.yourNewSubscription : myStrings.yourCurrentSubscription}
                </h2>
                <h4 className="text-center mb-1">
                    {Strings.formatCurrency(
                        sum.mainPeriodPrice,
                        Subscriptions.priceDecPlaces,
                        customerInfo.currency
                    )}
                    &nbsp;/&nbsp;{StoreHelper.getPeriodStrings(mainDisplayedPeriod).unit}
                </h4>
                <div className={mergeStyles('text-center', css.bottomSpacing, sum.wouldaMainPeriodPrice <= sum.price && 'invisible')}>
                    {myStrings.billedAt}
                    &nbsp;
                    <s className={css.wouldaPrice}>
                        {Strings.formatCurrency(
                            sum.wouldaMainPeriodPrice,
                            Subscriptions.priceDecPlaces,
                            customerInfo.currency
                        )}
                    </s>
                    &nbsp;
                    {Strings.formatCurrency(
                        sum.price,
                        Subscriptions.priceDecPlaces,
                        customerInfo.currency
                    )}
                    &nbsp;/&nbsp;{StoreHelper.getPeriodStrings(selectedPeriodType).unit}
                </div>
            </div>
            <SummaryItems allowRemoving />
            <div className={mergeStyles('d-flex', css.buttons)}>
                <div>
                    <DefaultButton text={myStrings.revert} onClick={revertToDefaultPackagesStates} disabled={!anyDifference} />
                </div>
                <div className="ml-auto">
                    <PrimaryButton text={isAnythingAlreadySubscribed ? myStrings.updateSubscription : myStrings.buyNow} onClick={onCheckout} disabled={!anyDifference} />
                </div>
            </div>
        </div>
    );
};

export default Summary;