import * as React from 'react';
import { NavLink } from 'react-router-dom';
import type { ICurrentPlanModel } from '../../../../data/shopping/ISubscriptionsShoppingModel';
import IframePostMessageHelper from '../../../../helpers/IframePostMessageHelper';
import RouteConfig from '../../../../RouteConfig';
import Strings from '../../../../strings';
import { StripeRequiredActionHandler } from '../../../shared/subscriptions/StripeRequiredActionHandler';
import { StripeRequiredPaymentMethodHandler } from '../../../shared/subscriptions/StripeRequiredPaymentMethodHandler';
import type { ShoppingConnection } from '../../../ShoppingConnection';
import WizardModal from '../../../WizardModal';

type TSubscriptionIssuesHandlerProps = {
    shoppingConnection: ShoppingConnection;
    currentPlan: ICurrentPlanModel;
    onDone: () => Promise<void>;
};

export const getAreThereAnySubscriptionIssues = (currentPlan: ICurrentPlanModel | null) => {
    if (!currentPlan) {
        return false;
    }

    return currentPlan.IsPastDue || !!currentPlan.StripeSubscriptionIssue?.SubscriptionRequiresActionPaymentIntent || !!currentPlan.StripeSubscriptionIssue?.SubscriptionRequiresPaymentMethodPaymentIntent;
};

const SubscriptionIssuesHandler: React.FC<TSubscriptionIssuesHandlerProps> = ({ shoppingConnection, currentPlan, onDone }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const isShowingAnyContent = getAreThereAnySubscriptionIssues(currentPlan);

    React.useEffect(() => {
        if (isShowingAnyContent) {
            IframePostMessageHelper.checkoutLoaded();
        }
    }, [isShowingAnyContent]);

    return (
        <>
            {(currentPlan.IsPastDue) &&
                <WizardModal show={true} size="lg">
                    <WizardModal.Body>
                        <h3>{myStrings.pastDueTitle}</h3>
                        <p>{myStrings.pastDueMessage}</p>
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <NavLink to={RouteConfig.billing.bills.path} className="btn btn-primary">
                            {myStrings.checkBills}
                        </NavLink>
                        <NavLink to={RouteConfig.billing.paymentMethods.path} className="btn btn-primary">
                            {myStrings.managePaymentMehtods}
                        </NavLink>
                    </WizardModal.Footer>
                </WizardModal>
            }
            {(!!currentPlan.StripeSubscriptionIssue?.SubscriptionRequiresActionPaymentIntent) &&
                <StripeRequiredActionHandler
                    requiredActionModel={currentPlan.StripeSubscriptionIssue.SubscriptionRequiresActionPaymentIntent}
                    shoppingConnection={shoppingConnection}
                    onDone={onDone}
                />
            }
            {(!!currentPlan.StripeSubscriptionIssue?.SubscriptionRequiresPaymentMethodPaymentIntent) &&
                <StripeRequiredPaymentMethodHandler
                    requiredPaymentMethodModel={currentPlan.StripeSubscriptionIssue.SubscriptionRequiresPaymentMethodPaymentIntent}
                    shoppingConnection={shoppingConnection}
                    onDone={onDone}
                />
            }
        </>
    );
};

export default SubscriptionIssuesHandler;