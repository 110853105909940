import type { IPackageModel, IPriceModel, TPeriodType } from "../../../data/shopping/ISubscriptionsShoppingModel";
import type { IPriceWithCurrency } from "../../../data/shopping/IPriceWithCurrency";
import StoreHelper from "./StoreHelper";

const getPrice = (packageData: IPackageModel, amount: number, currency: string, periodType: TPeriodType, isPackageInCart: (artno: string) => boolean, maskAsPeriodType?: TPeriodType): number => {
    if (!packageData.PriceList)
        return 0;

    const highestPriceList: IPriceModel | null = packageData.PriceList.reduce(
        (highest: IPriceModel | null, current: IPriceModel) => {
            if ((current.MinAmount || 0) <= amount && (current.PeriodType === periodType) && (!current.Condition || StoreHelper.isConditionMet(current.Condition, isPackageInCart))) {
                if (!highest || (highest.MinAmount || 0) < (current.MinAmount || 0)) {
                    return current;
                }
            }
            return highest;
        },
        null
    );

    if (!highestPriceList)
        return 0;

    if (!highestPriceList.PriceWithoutVat) {
        console.error("Function getPrice found a price model but it contains no price without vat.");
        return 0;
    }

    const properCurrencyPrice = highestPriceList.PriceWithoutVat.find(p => p.Currency === currency);

    if (!properCurrencyPrice) {
        console.error('Function getPrice found a price model but it contains no matching currency for "' + currency + '".');
        return 0;
    }

    const unitPrice = properCurrencyPrice.Price || 0;
    if (!maskAsPeriodType || maskAsPeriodType === periodType)
        return unitPrice;

    // Unit price is forecly shown as montly price.
    switch (maskAsPeriodType) {
        case 'Yearly':
            switch (periodType) {
                case 'Yearly':
                    return unitPrice;
                case 'Monthly':
                    return unitPrice * 12.0;
                case 'Triennially':
                    return unitPrice / 3.0;
            }
            break;
        case 'Monthly':
            switch (periodType) {
                case 'Yearly':
                    return unitPrice / 12.0;
                case 'Monthly':
                    return unitPrice;
                case 'Triennially':
                    return unitPrice / 36.0;
            }
            break;
        case 'Triennially':
            switch (periodType) {
                case 'Yearly':
                    return unitPrice * 3.0;
                case 'Monthly':
                    return unitPrice * 36.0;
                case 'Triennially':
                    return unitPrice;

            }
            break;

    }
    throw new Error(`Unsupported period type '${maskAsPeriodType}' or '${periodType}'.`);
};

export const getFirstNonZeroPriceAmount = (packageData: IPackageModel, currency: string, periodType: TPeriodType): number | null => {
    if (!packageData.PriceList) {
        return null;
    }

    const firstPaidPriceList: IPriceModel | null = packageData.PriceList.reduce(
        (chosen: IPriceModel | null, current: IPriceModel) => {
            if (!current.PriceWithoutVat)
                return chosen;

            if (current.PeriodType !== periodType)
                return chosen;

            const currencyPrice: IPriceWithCurrency | undefined = current.PriceWithoutVat.find(price => price.Currency === currency);
            if (!currencyPrice)
                return chosen;

            if (currencyPrice.Price <= 0.00)
                return chosen;

            if (!chosen)
                return current;

            if ((current.MinAmount || 0) < (chosen.MinAmount || 0)) {
                return current;
            }

            return chosen;
        },
        null
    );

    if (!firstPaidPriceList)
        return null;

    return firstPaidPriceList.MinAmount || 0;
};

export default getPrice;