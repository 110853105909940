import * as React from 'react';
import Strings from '../../../strings';
import { Form, Col } from 'react-bootstrap';
import StringHelper from '../../../helpers/StringHelper';
import { RemoteItemStore } from '../../../RemoteItemStore';
import type { TCountriesAndRegions } from '../../routes/subscriptions/TCountriesAndRegions';
import type { IApiEnumValue } from '@eway-crm/connector';
import type { TCustomerInfo } from '../../routes/subscriptions/TCustomerInfo';
import { Link, mergeStyles, VirtualizedComboBox } from '@fluentui/react';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import { QuestionMarkPopover } from '../QuestionMarkPopover';

type TCompanyDetailsFormProps = {
    countries: TCountriesAndRegions;
    isFormValidated: boolean;
    formReference: React.RefObject<HTMLFormElement>;
    customerInfo: TCustomerInfo;
    onCustomerInfoChanged: (info: Partial<TCustomerInfo>) => void;
    onTaxImportantFieldBlur?: () => void;
    hideHeadings?: boolean;
    hidePersonName?: boolean;
    emailLabel?: string;
    isReadonly?: boolean;
    onIsReadonlyChange?: (isReadonly: boolean) => void;
};


const CompanyDetailRow: React.FC<{prefix?: string} & React.HTMLProps<HTMLDivElement>> = ({children, prefix, className, ...restProps }) => {
    if (!children) {
        return null;
    }

    return <div className={mergeStyles('ignore-ew-window-move', className)} {...restProps} >{prefix && `${prefix}: `}{children}</div>;
};

export const CompanyDetailsForm: React.FC<TCompanyDetailsFormProps> = (props) => {
    const myStrings = Strings.components.routes.subscriptions;
    const isFullAddrRequired = isFullAddressRequired(props.customerInfo);
    const isSlovak = StringHelper.compareIgnoringCase(props.customerInfo.country, 'SK') === 0;
    const showNoCompanyInfo = StringHelper.compareIgnoringCase(props.customerInfo.country, 'US') === 0 || StringHelper.compareIgnoringCase(props.customerInfo.country, 'CA') === 0;

    const getCurrentCountryName = () => {
        const currentCounty = props.countries.find(countries => countries.countryEv.FileAs === props.customerInfo.country);
        if (!currentCounty) {
            return null;
        }
        return Strings.pickTranslation(currentCounty.countryEv) as string;
    };

    const getAddressRow = () => {
        let addressRow = `${props.customerInfo.city}`;


        if (props.customerInfo.state) {
            if (addressRow) {
                addressRow += `, ${props.customerInfo.state}`;
            } else {
                addressRow = `${props.customerInfo.state}`;
            }
        }

        if (props.customerInfo.postalCode) {
            if (addressRow) {
                addressRow += ` ${props.customerInfo.postalCode}`;
            } else {
                addressRow = `${props.customerInfo.postalCode}`;
            }
        }

        return addressRow;
    };

    return (
        <>
            {(!props.hideHeadings) &&
                <h3>{myStrings.companyDetails}</h3>
            }
            {props.isReadonly && (
                <div className="mb-3">
                    <div className={'d-flex mb-3 align-items-baseline'}>
                        <div className={'m-0 mr-2 ignore-ew-window-move'} style={{ fontSize: '1rem', fontWeight: 500 }}>
                            {props.customerInfo.businessName}
                        </div>
                        <Link onClick={() => props.onIsReadonlyChange && props.onIsReadonlyChange(false)}>Edit</Link>
                    </div>
                    <CompanyDetailRow>
                        {props.customerInfo.firstName} {props.customerInfo.lastName}
                    </CompanyDetailRow>
                    <CompanyDetailRow>{props.customerInfo.street}</CompanyDetailRow>
                    <CompanyDetailRow>
                         {getAddressRow()}
                    </CompanyDetailRow>
                    <CompanyDetailRow>{getCurrentCountryName()}</CompanyDetailRow>
                    {!showNoCompanyInfo && (
                        <div className={'my-3'}>
                            <CompanyDetailRow prefix={isSlovak ? myStrings.vatNumberSk : myStrings.vatNumber}>{props.customerInfo.vatNumber}</CompanyDetailRow>
                            <CompanyDetailRow prefix={isSlovak ? myStrings.idNumberSk : myStrings.idNumber}>{props.customerInfo.identificationNumber}</CompanyDetailRow>
                            {isSlovak && <CompanyDetailRow prefix={isSlovak ? myStrings.taxNumberSk : myStrings.taxNumber}>{props.customerInfo.taxNumber}</CompanyDetailRow>}
                        </div>
                    )}
                    {props.customerInfo.emailAddress && (
                        <Link className={'mt-3 d-block'} href={`mailto:${props.customerInfo.emailAddress}`}>
                            {props.customerInfo.emailAddress}
                        </Link>
                    )}
                    <CompanyDetailRow>{props.customerInfo.phone}</CompanyDetailRow>
                </div>
            )}
                <Form ref={props.formReference} validated={props.isFormValidated} className="form-application">
                    {!props.isReadonly && (
                        <>
                        <Form.Row>
                            <Form.Group as={Col} sm={6} controlId="formDetailBusinessName">
                                <Form.Label>
                                    {myStrings.businessName}
                                    <RedStar />
                                </Form.Label>
                                <Form.Control required type="text" value={props.customerInfo.businessName} onChange={(e) => props.onCustomerInfoChanged({ businessName: e.target.value })} />
                            </Form.Group>
                        </Form.Row>
                        {!props.hidePersonName && (
                            <Form.Row>
                                <Form.Group as={Col} controlId="formDetailFirstName">
                                    <Form.Label>{myStrings.firstName}</Form.Label>
                                    <Form.Control type="text" autoFocus value={props.customerInfo.firstName} onChange={(e) => props.onCustomerInfoChanged({ firstName: e.target.value })} />
                                </Form.Group>
                                <Form.Group as={Col} controlId="formDetailLastName">
                                    <Form.Label>{myStrings.lastName}</Form.Label>
                                    <Form.Control type="text" value={props.customerInfo.lastName} onChange={(e) => props.onCustomerInfoChanged({ lastName: e.target.value })} />
                                </Form.Group>
                            </Form.Row>
                        )}
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDetailStreet">
                                <Form.Label>
                                    {myStrings.addressLine}
                                    <RedStar if={isFullAddrRequired} />
                                </Form.Label>
                                <Form.Control type="text" value={props.customerInfo.street} onChange={(e) => props.onCustomerInfoChanged({ street: e.target.value })} required={isFullAddrRequired} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formDetailCity">
                                <Form.Label>
                                    {myStrings.city}
                                    <RedStar if={isFullAddrRequired} />
                                </Form.Label>
                                <Form.Control type="text" value={props.customerInfo.city} onChange={(e) => props.onCustomerInfoChanged({ city: e.target.value })} required={isFullAddrRequired} />
                            </Form.Group>
                        </Form.Row>
                        <CustomerPostalStateCountryFormRow
                            countries={props.countries}
                            customerInfo={props.customerInfo}
                            onTaxImportantFieldBlur={props.onTaxImportantFieldBlur}
                            onCustomerInfoChanged={props.onCustomerInfoChanged}
                            isFullAddressRequired={isFullAddrRequired}
                        />
                        <Form.Row>
                            <Form.Group as={Col} controlId="formDetailEmail">
                                <Form.Label>
                                    {props.emailLabel || myStrings.emailAddress}
                                    <RedStar />
                                </Form.Label>
                                <Form.Control required type="email" value={props.customerInfo.emailAddress} onChange={(e) => props.onCustomerInfoChanged({ emailAddress: e.target.value || '' })} />
                            </Form.Group>
                            <Form.Group as={Col} controlId="formDetailPhone">
                                <Form.Label>{myStrings.phone}</Form.Label>
                                <Form.Control type="tel" value={props.customerInfo.phone} onChange={(e) => props.onCustomerInfoChanged({ phone: e.target.value })} />
                            </Form.Group>
                        </Form.Row>
                        {!showNoCompanyInfo && (
                            <>
                                <Form.Row>
                                    <Form.Group as={Col} controlId="formDetailVatNumber">
                                        <Form.Label>{isSlovak ? myStrings.vatNumberSk : myStrings.vatNumber}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            value={props.customerInfo.vatNumber}
                                            onChange={(e) => props.onCustomerInfoChanged({ vatNumber: e.target.value })}
                                            onBlur={props.onTaxImportantFieldBlur}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} controlId="formDetailIdNumber">
                                        <Form.Label>{isSlovak ? myStrings.idNumberSk : myStrings.idNumber}</Form.Label>
                                        <Form.Control type="text" value={props.customerInfo.identificationNumber} onChange={(e) => props.onCustomerInfoChanged({ identificationNumber: e.target.value })} />
                                    </Form.Group>
                                </Form.Row>
                                {isSlovak && (
                                    <Form.Row>
                                        <Form.Group as={Col} sm={6} controlId="formDetailTaxNumber">
                                            <Form.Label>{isSlovak ? myStrings.taxNumberSk : myStrings.taxNumber}</Form.Label>
                                            <Form.Control type="text" value={props.customerInfo.taxNumber} onChange={(e) => props.onCustomerInfoChanged({ taxNumber: e.target.value })} />
                                        </Form.Group>
                                    </Form.Row>
                                )}
                            </>
                        )}
                        </>
                    )}
                    <Form.Row>
                        <Form.Group as={Col} controlId="formDetailChargeAdditionalAccept">
                            <Form.Check
                                type="checkbox"
                                label={
                                    <span>
                                        {myStrings.chargeAdditionalServicesAutomatically}{' '}
                                        <QuestionMarkPopover placement="right" color="black">
                                            {myStrings.chargeAdditionalServicesAutomaticallyMore}
                                        </QuestionMarkPopover>
                                    </span>
                                }
                                checked={props.customerInfo.chargeAdditionalServicesAutomatically}
                                onChange={(e: IFormCheckChangeEvent) => props.onCustomerInfoChanged({ chargeAdditionalServicesAutomatically: e.target.checked })}
                            />
                        </Form.Group>
                    </Form.Row>
                </Form>
        </>
    );
};

const RedStar: React.FC<{ if?: boolean }> = (props) => (typeof props.if === 'undefined' || !!props.if) ? (<span className="text-danger"> *</span>) : null;

type TCustomerPostalStateCountryFormRowProps = {
    customerInfo: TCustomerInfo;
    countries: TCountriesAndRegions;
    onCustomerInfoChanged: (customerInfo: Partial<TCustomerInfo>) => void;
    onTaxImportantFieldBlur?: () => void;
    isFullAddressRequired: boolean;
};

const getRegions = (countries: TCountriesAndRegions, countryCode: string): IApiEnumValue[] | null => {
    const regions = countries.findMap(country => {
        if (country.countryEv.FileAs === countryCode)
            return country.regions;
        return undefined;
    });
    return regions || null;
};

const CustomerPostalStateCountryFormRow: React.FC<TCustomerPostalStateCountryFormRowProps> = (props) => {
    const regions = getRegions(props.countries, props.customerInfo.country);
    const myStrings = Strings.components.routes.subscriptions;

    const onCountryChange = (countryCode: string) => {
        const regions = getRegions(props.countries, countryCode);
        if (!regions || regions.length === 0) {
            props.onCustomerInfoChanged({ country: countryCode });
        } else {
            const region = regions.find(r => StringHelper.compareIgnoringCase(r.FileAs, props.customerInfo.state) === 0 || StringHelper.compareIgnoringCase(RemoteItemStore.pickEnumValueTranslation(r), props.customerInfo.state) === 0);
            props.onCustomerInfoChanged({
                country: countryCode,
                state: (!region ? regions[0].FileAs : region.FileAs) || ''
            });
        }
    };

    return (
        <Form.Row>
            <Form.Group as={Col} xs={4} sm={2} controlId="formDetailPostalCode">
                <Form.Label>{myStrings.postalCode}<RedStar if={props.isFullAddressRequired} /></Form.Label>
                <Form.Control type="text" value={props.customerInfo.postalCode} onChange={(e) => props.onCustomerInfoChanged({ postalCode: e.target.value })} required={props.isFullAddressRequired} />
            </Form.Group>
            <Form.Group as={Col} xs={8} sm={4} controlId="formDetailState">
                <Form.Label>{myStrings.state}<RedStar if={props.isFullAddressRequired} /></Form.Label>
                {(!!regions) ?
                    <>
                        <VirtualizedComboBox
                            selectedKey={props.customerInfo.state}
                            options={RemoteItemStore.convertEnumValuesForCombo(regions)}
                            onChange={(e, o, i, v) => props.onCustomerInfoChanged({ state: o!.key as string })}
                            onBlur={props.onTaxImportantFieldBlur}
                            useComboBoxAsMenuWidth={true}
                            required
                            autoComplete="off"
                        />
                    </>
                    :
                    <>
                        <Form.Control type="text" value={props.customerInfo.state} onChange={(e) => props.onCustomerInfoChanged({ state: e.target.value })} required={props.isFullAddressRequired} />
                    </>
                }
            </Form.Group>
            <Form.Group as={Col} xs={12} sm={6} controlId="formDetailCountry">
                <Form.Label>{myStrings.country}<RedStar /></Form.Label>
                <VirtualizedComboBox
                    selectedKey={props.customerInfo.country}
                    options={RemoteItemStore.convertEnumValuesForCombo(props.countries.map(c => c.countryEv))}
                    onChange={(e, o, i, v) => onCountryChange(o!.key as string)}
                    onBlur={props.onTaxImportantFieldBlur}
                    useComboBoxAsMenuWidth={true}
                    required
                />
            </Form.Group>
        </Form.Row>
    );
};

export const isFullAddressRequired = (customerInfo: TCustomerInfo): boolean => {
    return StringHelper.compareIgnoringCase(customerInfo.country, 'US') === 0;
};