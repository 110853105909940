import React from "react";
import type { IPaymentMethodsModel, IStripePaymentMethod } from "../../../../../../data/shopping/IPaymentMethodsModel";
import type { IShoppingDatumResponse } from "../../../../../../data/shopping/IShoppingDatumResponse";
import { ConnectionContext } from "../../../../../../providers/ConnectionProvider";
import Strings from "../../../../../../strings";
import { Spinner, SpinnerVariant } from '@eway-crm/gui';
import CheckoutSubmitter from "../../../CheckoutSubmitter";
import StoreV2Context from "../../StoreV2Context";
import EulaAcceptanceForm from "../EulaAcceptanceForm/EulaAcceptanceForm";
import StripeCardEnteringForm from "../StripeCardEnteringForm/StripeCardEnteringForm";
import type { TPaymentConfig } from "./usePaymentConfig";
import type { TSubmitHandler } from "./useSubmitHandler";

type TCardFillingState = 'none' | 'stripeAdding' | 'stripeAddingAndPay';

const useStripePaymentConfig = (isEnabled: boolean, checkEula: () => boolean, showEula: () => void, submitHandler: TSubmitHandler): TPaymentConfig => {
    const { connection } = React.useContext(ConnectionContext);
    const { shoppingConnection } = React.useContext(StoreV2Context);
    const { handleFinisherInitialized, handleFinisherUnloaded, handlePaymentMethodConfirmed } = submitHandler;
    const [isLoading, setIsLoading] = React.useState<boolean>(isEnabled);
    const [methods, setMethods] = React.useState<IStripePaymentMethod[] | null>(null);
    const [selectedCardId, setSelectedCardId] = React.useState<string | null>(null);
    const [fillingCardState, setFillingCardState] = React.useState<TCardFillingState>('none');

    React.useEffect(() => isEnabled ? handleFinisherUnloaded : undefined, [isEnabled, handleFinisherUnloaded]);

    React.useEffect(() => {
        if (!isLoading)
            return;

        if (methods || !isEnabled) {
            setIsLoading(false);
            return;
        }

        (async () => {
            const methodsResult = await shoppingConnection.askShoppingApi<IShoppingDatumResponse<IPaymentMethodsModel>>('GetPaymentMethods', {});
            const availableMethods = methodsResult.Datum.StripeMethods?.filter(pm => !pm.IsExpired) || [];
            setMethods(availableMethods);
            const activeId = availableMethods.find(m => m.IsActive)?.Id;
            if (activeId) {
                setSelectedCardId(activeId);
            }
        })()
            .catch((err) => console.error('Unable to get payment methods.', err));
    }, [isLoading, methods, isEnabled, setMethods, shoppingConnection]);

    const availableStripeMethods: IStripePaymentMethod[] | null = !!(methods?.length) ? methods : null;

    const finish = React.useCallback((paymentMethodId: string) => {
        window.setTimeout(() => {
            handlePaymentMethodConfirmed((customerInfo, products, eulaHtml, selectedPeriodType) => CheckoutSubmitter.createCheckoutOrderModel(customerInfo, products, eulaHtml, null, paymentMethodId, selectedPeriodType, connection));
        }, 10);
    }, [handlePaymentMethodConfirmed, connection]);

    React.useEffect(() => {
        if (!isEnabled)
            return;

        handleFinisherInitialized(() => { throw new Error('Submiting via submithandler is not supported by stripe payment config. It submits directly via handlePaymentMethodConfirmed.'); });
    }, [isEnabled, handleFinisherInitialized]);

    const handleCancelAdding = React.useCallback(() => setFillingCardState('none'), [setFillingCardState]);
    const handleAdded = React.useCallback(() => {
        // Reload cards.
        setMethods(null);
        setIsLoading(true);
        setFillingCardState('none');
    }, [setMethods, setIsLoading, setFillingCardState]);

    const renderComponent = React.useMemo(() => {
        if (isLoading) {
            return (
                <div className="checkoutV2__loader">
                    <Spinner variant={SpinnerVariant.linear}/>
                </div>
            );
        }

        if (fillingCardState === 'stripeAdding') {
            return (
                <StripeCardEnteringForm
                    onAdded={handleAdded}
                    onCanceled={handleCancelAdding}
                />
            );
        }

        if (fillingCardState === 'stripeAddingAndPay') {
            return (
                <StripeCardEnteringForm
                    onAdded={finish}
                    onCanceled={handleCancelAdding}
                    submitButtonText={Strings.components.routes.subscriptions.pay}
                    onBeforeSubmitting={checkEula}
                >
                    <div className="d-flex justify-content-end">
                        <EulaAcceptanceForm onShowFullEula={showEula} payBtnText={Strings.components.routes.subscriptions.pay} />
                    </div>
                </StripeCardEnteringForm>
            );
        }

        return null;
    }, [isLoading, fillingCardState, showEula, handleAdded, finish, handleCancelAdding, checkEula]);

    return React.useMemo(() => {
        if (!availableStripeMethods) {
            const handlePaySimple = () => {
                if (!isEnabled)
                    return;

                setFillingCardState('stripeAddingAndPay');
            };
            return {
                availableStripeMethods: null,
                selectedStripeMethod: null,
                onAddNew: null,
                onAddNewAndPay: null,
                onSelectMethodId: null,
                onPayWithStripeMethodId: null,
                isLoading,
                renderComponent,
                onPaySimple: handlePaySimple
            };
        }

        const selectedStripeMethod = selectedCardId ? availableStripeMethods.find(m => m.Id === selectedCardId)! : availableStripeMethods.find(m => m.IsActive)!;

        return {
            availableStripeMethods,
            selectedStripeMethod,
            onAddNew: () => setFillingCardState('stripeAdding'),
            onAddNewAndPay: () => setFillingCardState('stripeAddingAndPay'),
            onSelectMethodId: setSelectedCardId,
            onPayWithStripeMethodId: finish,
            onPaySimple: null,
            isLoading,
            renderComponent
        };
    }, [availableStripeMethods, isEnabled, selectedCardId, finish, setFillingCardState, isLoading, renderComponent]);
};

export default useStripePaymentConfig;