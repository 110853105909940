import { useEffect } from 'react';

const useDetectKeydown = (cb: (key: string, event: KeyboardEvent) => void) => {
    useEffect(() => {
        const handleDetectKeypress = (event: KeyboardEvent) => {
            cb(event.key, event);
        };
        document.addEventListener('keydown', handleDetectKeypress);
        return () => document.removeEventListener('keydown', handleDetectKeypress);
    }, [cb]);
};

export default useDetectKeydown;
