import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { IApiEnumType, IApiWorkflowModel } from '@eway-crm/connector';
import memoizeOne from 'memoize-one';
import type { IContextualMenuProps, IIconProps} from '@fluentui/react';
import { CommandButton, DelayedRender, mergeStyles, Shimmer, ShimmerElementType } from '@fluentui/react';
import React from 'react';
import { Badge } from 'react-bootstrap';
import Strings from '../../../strings';
import SideMenuGrid from '../../shared/SideMenuGrid';
import type { TEnumValueWithWfModel } from './WorkflowModelContainer';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import pauseImg from '../../../img/pause-button.svg';

const ESTIMATED_ROW_HEIGHT = 43; // Actual size of the row in px

export enum WorkflowMenuItemAction {
    Edit,
    ToggleHide,
    Duplicate,
    Delete,
}

type TWorkflowMenuProps = {
    items: TEnumValueWithWfModel[] | null;
    enumType: IApiEnumType;
    selectedItemId: string | null;
    onMenuItemClick: (newItem: string) => void;
    onMenuAction: (action: WorkflowMenuItemAction, id: string) => void;
    wfModels: IApiWorkflowModel[];
    tableRef?: React.RefObject<typeof VirtualTable>;
};

type TGridRow = {
    name: string;
    id: string;
    editorType: EditorType;
    disabled?: boolean;
    isVisible: boolean;
    isSystem: boolean;
    canBeEdited: boolean;
    canBeHidden: boolean;
    canBeCopied: boolean;
    canBeDeleted: boolean;
};

enum EditorType {
    Simple,
    Advanced,
    None,
}

const gridColumns = [
    { name: 'name', title: '' },
    { name: 'actions', title: '' },
];

const columnExtensions: VirtualTable.ColumnExtension[] = [
    { columnName: 'name', width: '225px' },
    { columnName: 'actions', width: '24px' },
];

const SHIMMER_LINES_COUNT = 5;
const SHIMMER_WRAPPER_CLASS = mergeStyles({
    padding: '2px 4px',
    selectors: {
        '& > .ms-Shimmer-container': {
            marginBottom: '6px',
        },
    },
});

const myStrings = Strings.components.routes.workflow;

export default class WorkflowMenu extends React.Component<TWorkflowMenuProps> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private readonly getDisplayedData = memoizeOne((items: TEnumValueWithWfModel[], enumType: IApiEnumType): TGridRow[] => {
        return items
            .sort((a, b) => a.Rank - b.Rank)
            .sort((a, b) => Number(b.IsVisible) - Number(a.IsVisible))
            .map((item) => {
                return {
                    name: Strings.pickTranslation(item)!,
                    id: item.ItemGUID,
                    editorType: item.wfModel ? (item.wfModel.IsUsingFlows ? EditorType.Advanced : EditorType.Simple) : EditorType.None,
                    disabled: !!item.wfModel && item.wfModel.IsSystem && !item.wfModel.AllowEditItems, // Gray without any actions but still selectable
                    canBeEdited: !item.IsSystem,
                    canBeHidden: enumType.AllowEditVisibility,
                    isVisible: item.IsVisible,
                    isSystem: item.IsSystem,
                    canBeCopied: !!item.wfModel && !item.wfModel.IsUsingFlows,
                    canBeDeleted: item.CanBeDeleted && !item.IsSystem && (!item.wfModel || !item.wfModel.IsSystem),
                };
            });
    });

    private readonly CellComponent: React.FunctionComponent<Table.DataCellProps> = (props) => {
        const typedRow = props.row as TGridRow;
        const typedValue = props.value as React.ReactChild;

        if (props.column.name === 'actions') {
            const moreIcon: IIconProps = { iconName: 'MoreVertical' };
            const menuIconStyle = { color: 'inherit' };
            const menuProps: IContextualMenuProps = {
                items: [
                    {
                        key: 'editEvent',
                        text: myStrings.editName,
                        onClick: () => {
                            this.props.onMenuAction(WorkflowMenuItemAction.Edit, typedRow.id);
                        },
                        disabled: !typedRow.canBeEdited,
                        iconProps: { iconName: 'Edit', style: menuIconStyle },
                    },
                    {
                        key: 'toggleHideEvent',
                        text: typedRow.isVisible ? myStrings.disable : myStrings.enable,
                        onClick: () => {
                            this.props.onMenuAction(WorkflowMenuItemAction.ToggleHide, typedRow.id);
                        },
                        disabled: !typedRow.canBeHidden,
                        iconProps: { iconName: typedRow.isVisible ? 'CirclePause' : 'MSNVideos', style: menuIconStyle },
                    },
                    {
                        key: 'duplicateEvent',
                        text: Strings.duplicate,
                        onClick: () => {
                            this.props.onMenuAction(WorkflowMenuItemAction.Duplicate, typedRow.id);
                        },
                        disabled: !typedRow.canBeCopied,
                        iconProps: { iconName: 'Copy', style: menuIconStyle },
                    },
                    {
                        key: 'deleteEvent',
                        text: Strings.delete,
                        onClick: () => {
                            this.props.onMenuAction(WorkflowMenuItemAction.Delete, typedRow.id);
                        },
                        disabled: !typedRow.canBeDeleted,
                        iconProps: { iconName: 'Trash', style: menuIconStyle },
                    },
                ],
            };

            return (
                <VirtualTable.Cell className="p-0 align-middle " {...props}>
                    <CommandButton disabled={typedRow.disabled} iconProps={moreIcon} menuProps={menuProps} className="p-0 border-0 invisible d-parent-tr-hover-visiblie moreButtonDropdown" />
                </VirtualTable.Cell>
            );
        }

        return (
            <VirtualTable.Cell className={mergeStyles('align-middle d-flex align-items-center', typedRow.disabled && 'text-black-50')} title={typedValue} {...props}>
                {typedRow.isVisible ? (
                    <div title={myStrings.enabled} className="workflow__enabled-dot" />
                ) : (
                    <img src={pauseImg} className="workflow__paused-img" title={myStrings.disabled} alt={myStrings.disabled} />
                )}
                <div>
                    {typedValue}
                    <EditorBadge type={typedRow.editorType} />
                    {typedRow.isSystem && (
                        <Badge variant={'warning'} className="ml-1">
                            {myStrings.system}
                        </Badge>
                    )}
                </div>
            </VirtualTable.Cell>
        );
    };

    render() {
        if (!this.props.items) {
            return (
                <DelayedRender delay={150}>
                    <div className={SHIMMER_WRAPPER_CLASS}>
                        {[...Array<never>(SHIMMER_LINES_COUNT)].map((_, i) => (
                            <Shimmer key={i} shimmerElements={[{ type: ShimmerElementType.line, width: '100%', height: 37 }]} />
                        ))}
                    </div>
                </DelayedRender>
            );
        }
        return (
            <SideMenuGrid
                items={this.getDisplayedData(this.props.items, this.props.enumType)}
                columns={gridColumns}
                getRowId={(row) => row.id}
                selectedItemId={this.props.selectedItemId}
                searchedString=""
                onMenuItemChange={this.props.onMenuItemClick}
                cellComponent={this.CellComponent}
                columnExtensions={columnExtensions}
                noDataText={myStrings.noModelTypeMatch}
                tableRef={this.props.tableRef}
                estimatedRowHeight={ESTIMATED_ROW_HEIGHT}
            />
        );
    }
}

const EditorBadge: React.FC<{ type: EditorType }> = ({ type }) => {
    if (type === EditorType.None) {
        return null;
    }

    let text = myStrings.simple;
    let tooltip = myStrings.simpleEditor;
    let variant = 'success';

    if (type === EditorType.Advanced) {
        text = myStrings.advanced;
        tooltip = myStrings.advancedEditor;
        variant = 'orange';
    }

    return (
        <Badge variant={variant} className="ml-1" title={tooltip}>
            {text}
        </Badge>
    );
};
