import * as React from 'react';
import { mergeStyles, mergeStyleSets } from '@fluentui/react';
import type { IRow } from '../../../../../data/shopping/ISubscriptionsShoppingModel';
import usePackage from '../hooks/usePackage';
import HtmlComponent from '../../../../shared/HtmlComponent';
import Strings from '../../../../../strings';
import PackageQuantitySlider from '../PackageQuantitySlider/PackageQuantitySlider';
import StoreV2Context from '../StoreV2Context';

const css = mergeStyleSets({
    capacityError: {
        color: 'red',
        fontSize: '12px',
        maxWidth: '550px'
    }
});

type TSliderRowProps = {
    row: IRow;
};

const SliderRow: React.FC<TSliderRowProps> = ({ row }) => {
    const artno = row.Artnos?.[0];
    if (!artno)
        return null;

    return (
        <div>
            <PackageSlider artno={artno} />
        </div>
    );
};

type TPackageSliderProps = {
    artno: string;
};

const PackageSlider: React.FC<TPackageSliderProps> = ({ artno }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const { currentDbSizeGigabytes } = React.useContext(StoreV2Context);
    const { packageData, isDisabled, packageEditingState } = usePackage(artno);

    if (!packageData)
        return null;

    return (
        <div className={mergeStyles('p-3 package-row', isDisabled && "package-row__disabled")} title={packageData.CompareToDbSizeGigabytes ? (Strings.formatString(myStrings.currentDbSizeFormat, Strings.formatNumber(currentDbSizeGigabytes ?? 0, 0) ?? '') as string) : undefined}>
            <div className="mb-2" >
                <PackageQuantitySlider artno={artno} />
                {(packageData.CompareToDbSizeGigabytes && !!packageEditingState.currentEditQuantity && packageEditingState.currentEditQuantity < currentDbSizeGigabytes) &&
                    <div className={css.capacityError}>
                        {Strings.formatString(myStrings.lessThanDbSizeFormat, Strings.formatNumber(currentDbSizeGigabytes, 0) ?? "")}
                    </div>
                }
            </div>
            {(!!packageData.Description) &&
                <div>
                    <HtmlComponent htmlString={Strings.pickTranslation(packageData.Description)} />
                </div>
            }
        </div>
    );
};

export default SliderRow;