
export default class GlobalSettingTypes {
    static readonly textBox = 0;
    static readonly toggle = 1;
    static readonly numberBox = 2;
    static readonly floatNumberBox = 3;
    static readonly multiLineTextBox = 4;
    static readonly comboBox = 5;
    static readonly multiChoiceComboBox = 6;
    static readonly groupMultiChoiceComboBox = 7;
    static readonly nextStepMultiChoiceComboBox = 8;
    static readonly timeInterval = 9;
    static readonly domains = 10;
}