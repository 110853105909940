import * as React from 'react';
import Strings from './../../../strings';
import WizardModal from './../../WizardModal';
import WizardSteps from './../../WizardSteps';
import { Button } from 'react-bootstrap';
import type { IApiColumn, IApiEnumValue, TNumericValidatorType, IApiGroup, IApiColumnPermission, IApiSaveResponse, IApiDataResponse, IApiAdditionalField } from '@eway-crm/connector';
import { LicenseRestrictionKeys } from '@eway-crm/connector';
import { AdditionalFieldTypes } from '../../../data/constants/AdditionalFieldTypes';
import { SummaryTypes } from '../../../data/constants/SummaryTypes';
import { ValidatorTypes } from '../../../data/constants/ValidatorTypes';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { LinkTypes } from '../../../data/constants/LinkTypes';
import { FieldGeneralForm } from './FieldGeneralForm';
import FolderNames from '../../../data/constants/FolderNames';
import { v1 as createUuid } from 'uuid';
import { GroupNames } from '../../../data/constants/GroupNames';
import FieldPermissionsWarningDialog from '../fields/FieldPermissionsWarningDialog';
import FieldPermissionNames from '../../../data/constants/FieldPermissionNames';
import FieldPermissionsForm from './FieldPermissionsForm';
import { ItemStorage } from '../../../data/ItemStorageWithDelayedSave';
import GeneralFieldPermissionToggles from './GeneralFieldPermissionToggles';
import Fields from '../Fields';
import FieldTypes from '../../../data/constants/FieldTypes';
import type { OptionalExceptFor } from '../../../data/typeHelpers/OptionalExceptFor';
import { mergeStyles } from '@fluentui/react';
import type { TFieldPermissionWithCol } from './FieldPermissionsGrid';
import { RemoteItemStore } from '../../../RemoteItemStore';
import ReactHelper from '../../../helpers/ReactHelper';
import InvalidEnumValuesDialog from './InvalidEnumValuesDialog';
import type { TFieldWithCol } from './FolderFields';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import FieldsLicenseRestrictionHelper from './FieldsLicenseRestrictionHelper';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import LicenseRestrictionsLockedSplash from '../../shared/locks/FeatureLockedSplash';
import { SpinnerVariant } from '@eway-crm/gui';
import type LicenseRestrictionsHelper from '../../../helpers/LicenseRestrictionsHelper';
import type { VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import FieldWizardHelper from './FieldWizardHelper';

const myStrings = Strings.components.routes.fields;

export type TFieldPermissionWithName = OptionalExceptFor<TFieldPermissionWithCol, keyof IApiColumnPermission | 'colName'>;

type TNewAdditionalFieldWizardProps = {
    availableFolderNames: string[];
    linkableFolderNames: string[];
    customizableFolderNames: string[];
    folderName: string;
    onDismiss: () => void;
    onDone: () => void;
    sourceAdditionalFieldItem?: IApiAdditionalField | null;
    sourceEnumValues?: IApiEnumValue[] | null;
    allColumns: IApiColumn[];
    sourceColumn?: IApiColumn;
    fieldPermissions: TFieldPermissionWithName[];
    allGroups: IApiGroup[];
    displayLanguageColumns: string[];
    allFields: TFieldWithCol[];
    licenseRestrictionsHelper: LicenseRestrictionsHelper; // Pass as props so that we can use it in constructor without using deprecated legacy context API
};

type TNewAdditionalFieldWizardState = {
    currentStep: number;
    isGeneralFormValidated: boolean;
    name: string;
    comment: string;
    type: number;
    folderNames: string[];
    editMask: string;
    linkType: string;
    numberOfRows: number;
    summaryType: string;
    numberFormat: TNumericValidatorType;
    showTime: boolean;
    relatedFolderName: string;
    enumValues: Partial<IApiEnumValue>[];
    isSubmitting: boolean;
    anyChangeMade: boolean;
    fieldPermissions: TFieldPermissionWithName[];
    anyChangeToFieldPermissionsMade: boolean;
    searchedString: string;
    uniqueNotFullyPermittedForGroupName: string | null;
    isCheckingForDuplicateValues: boolean;
    displayLanguageColumns: string[];
    showInvalidEnumValuesError: boolean;
};

export class NewAdditionalFieldWizard extends React.Component<TNewAdditionalFieldWizardProps, TNewAdditionalFieldWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private static readonly stepGeneral = 1;
    private static readonly stepPermissions = 2;
    private static readonly firstStep = NewAdditionalFieldWizard.stepGeneral;
    private static readonly lastStep = NewAdditionalFieldWizard.stepPermissions;

    private readonly generalFormReference: React.RefObject<FieldGeneralForm>;
    private readonly fieldPermissionsStorage: ItemStorage<TFieldPermissionWithName>;
    private readonly enumValuesEditGridRef: React.RefObject<typeof VirtualTable>;

    constructor(props: TNewAdditionalFieldWizardProps) {
        super(props);

        const initialItemsToSave = props.fieldPermissions.filter(
            (fp) => fp.PermissionRule !== FieldPermissionNames.PermissionRuleKeys.All || fp.MandatoryRule !== FieldPermissionNames.MandatoryRuleKeys.None
        );
        this.fieldPermissionsStorage = new ItemStorage((item) => item.GroupGuid, initialItemsToSave);

        if (props.availableFolderNames.length === 0) {
            throw new Error('No available folder names supplied.');
        }
        if (props.linkableFolderNames.length === 0) {
            throw new Error('No linkable folder names supplied.');
        }

        this.generalFormReference = React.createRef();
        this.enumValuesEditGridRef = React.createRef();

        this.state = {
            currentStep: NewAdditionalFieldWizard.firstStep,
            isGeneralFormValidated: false,
            type: AdditionalFieldTypes.textBox,
            name: '',
            comment: '',
            folderNames: [props.folderName],
            editMask: '',
            linkType: LinkTypes.custom,
            numberOfRows: 3,
            summaryType: SummaryTypes.sum,
            numberFormat: ValidatorTypes.int,
            showTime: false,
            relatedFolderName: props.linkableFolderNames[0],
            enumValues: [],
            isSubmitting: false,
            anyChangeMade: false,
            fieldPermissions: this.props.fieldPermissions.map((fp) => ({ ...fp, IsMandatoryRuleEditable: true, IsPermissionRuleEditable: true, col: undefined })), // Remove any restrictions of duplicated field
            anyChangeToFieldPermissionsMade: false,
            searchedString: '',
            uniqueNotFullyPermittedForGroupName: null,
            isCheckingForDuplicateValues: false,
            displayLanguageColumns: props.displayLanguageColumns,
            showInvalidEnumValuesError: false,
        };

        if (props.sourceAdditionalFieldItem) {
            // Check if we can copy mandatory or important restrictions
            const mandatoryFieldsPerModuleLimit = props.licenseRestrictionsHelper.licenseRestrictions.functionalities?.[LicenseRestrictionKeys.mandatoryFields]?.currentLimit ?? null;
            const importantFieldsPerModuleLimit = props.licenseRestrictionsHelper.licenseRestrictions.functionalities?.[LicenseRestrictionKeys.importantFields]?.currentLimit ?? null;
            if (mandatoryFieldsPerModuleLimit || importantFieldsPerModuleLimit) {
                const systemGroupPermissions = this.getSystemGroupPermissions(this.state.fieldPermissions);
                const fieldsLicenseLimitsHelper = new FieldsLicenseRestrictionHelper(props.licenseRestrictionsHelper.licenseRestrictions, props.allFields, null);

                if (mandatoryFieldsPerModuleLimit && systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Mandatory) {
                    const mandatoryCount = fieldsLicenseLimitsHelper.getRuleCount('MandatoryRule', FieldPermissionNames.MandatoryRuleKeys.Mandatory, false);
                    if (mandatoryFieldsPerModuleLimit >= mandatoryCount) {
                        const newPermissions = GeneralFieldPermissionToggles.onSystemGroupChange(FieldPermissionNames.MandatoryRuleKeys.Mandatory, this.getSystemGroupPermissions(this.state.fieldPermissions));
                        if (newPermissions) {
                            const newFp = this.state.fieldPermissions.map(fp => {
                                if (fp.GroupGuid === systemGroupPermissions.GroupGuid && newPermissions) {
                                    return { ...fp, MandatoryRule: newPermissions?.mandatoryRule, PermissionRule: newPermissions?.permissionRule };
                                }
                                return fp;
                            });
                            this.state = {
                                ...this.state,
                                fieldPermissions: newFp
                            };
                        }
                    }
                } else if (importantFieldsPerModuleLimit && systemGroupPermissions.MandatoryRule === FieldPermissionNames.MandatoryRuleKeys.Optional) {
                    const optionalCount = fieldsLicenseLimitsHelper.getRuleCount('MandatoryRule', FieldPermissionNames.MandatoryRuleKeys.Optional, false);

                    if (importantFieldsPerModuleLimit >= optionalCount) {
                        const newPermissions = GeneralFieldPermissionToggles.onSystemGroupChange(FieldPermissionNames.MandatoryRuleKeys.Optional, this.getSystemGroupPermissions(this.state.fieldPermissions));

                        const newFp = this.state.fieldPermissions.map(fp => {
                            if (fp.GroupGuid === systemGroupPermissions.GroupGuid && newPermissions) {
                                return { ...fp, MandatoryRule: newPermissions.mandatoryRule, PermissionRule: newPermissions.permissionRule };
                            }
                            return fp;
                        });

                        this.state = {
                            ...this.state,
                            fieldPermissions: newFp
                        };
                    }
                }
            }

            this.state = {
                ...this.state,
                type: props.sourceAdditionalFieldItem.Type,
                name: (props.sourceAdditionalFieldItem.Name || '') + ' (2)',
                comment: props.sourceAdditionalFieldItem.Comment || '',
                folderNames: [props.folderName],
                editMask: props.sourceAdditionalFieldItem.Data_EditMask || '',
                linkType: props.sourceAdditionalFieldItem.Data_LinkType || LinkTypes.custom,
                numberOfRows: props.sourceAdditionalFieldItem.Data_MemoBoxLines || 3,
                summaryType: props.sourceAdditionalFieldItem.Data_SummaryType || SummaryTypes.sum,
                numberFormat: props.sourceAdditionalFieldItem.Data_FormatType || ValidatorTypes.int,
                showTime: props.sourceAdditionalFieldItem.Data_IsDateTime || false,
                relatedFolderName: props.sourceAdditionalFieldItem.Data_RelatedFolderName || props.linkableFolderNames[0],
            };
        } else if (props.sourceColumn) {
            this.state = {
                ...this.state,
                type: FieldTypes.getAfType(props.sourceColumn.Type) ?? AdditionalFieldTypes.textBox,
                name: Fields.getColumnName(props.sourceColumn) + ' (2)',
            };
        }

        if (props.sourceEnumValues) {
            const enumValues = props.sourceEnumValues.map((ev) => ({
                ItemGUID: createUuid(),
                ItemVersion: 0,
                CanBeDeleted: true,
                IsSystem: false,
                Rank: ev.Rank,
                FileAs: ev.FileAs,
                En: ev.En,
                Cs: ev.Cs,
                De: ev.De,
                Ru: ev.Ru,
                No: ev.No,
                Sk: ev.Sk,
                IsDefault: ev.IsDefault,
                IsVisible: ev.IsVisible,
                IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
            }));

            this.state = {
                ...this.state,
                enumValues,
            };
        }
    }

    private readonly back = () => {
        this.goToStep(this.state.currentStep - 1);
    };

    private readonly next = () => {
        this.goToStep(this.state.currentStep + 1);
    };

    private readonly onStepPicked = (wantedStep: number) => {
        const { isFieldPermissionsLocked, fieldPermissionsLicenseRestriction } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        if (wantedStep === NewAdditionalFieldWizard.stepPermissions && isFieldPermissionsLocked) {
            this.context.showLicenseRestrictionModal(fieldPermissionsLicenseRestriction);
            return;
        }
        this.goToStep(wantedStep);
    };

    private readonly goToStep = (wantedStep: number) => {
        const number = Math.max(NewAdditionalFieldWizard.firstStep, Math.min(NewAdditionalFieldWizard.lastStep, wantedStep));
        if (this.state.currentStep === number) {
            return;
        }

        for (let i = NewAdditionalFieldWizard.firstStep; i < number; i++) {
            if (!this.isStepValid(i, true)) {
                return;
            }
        }
        this.setState({
            currentStep: number,
            isGeneralFormValidated: false,
        });
    };

    private readonly isStepValid = (number: number, goToIfInvalid: boolean): boolean => {
        const falseCallback = () => {
            if (goToIfInvalid) {
                this.goToStep(number);
            }
        };

        if (number === NewAdditionalFieldWizard.stepGeneral) {
            if (this.generalFormReference.current && !this.generalFormReference.current.isFormValid()) {
                this.setState({ isGeneralFormValidated: true }, falseCallback);
                return false;
            }

            if (this.state.folderNames.length === 0) {
                this.setState({ isGeneralFormValidated: true }, falseCallback);
                return false;
            }

            if (!NewAdditionalFieldWizard.isTypeAndModuleValid(this.state.folderNames, this.state.type)) {
                this.setState({ isGeneralFormValidated: true }, falseCallback);
                return false;
            }

            if (this.state.type === AdditionalFieldTypes.comboBox || this.state.type === AdditionalFieldTypes.multiSelectComboBox) {
                const invalidRowsAndColumns = EnumValuesValidator.getInvalidRowsAndColumns(this.state.enumValues);
                if (invalidRowsAndColumns.size !== 0) {
                    FieldWizardHelper.scrollToInvalidEnumValueRow(
                        invalidRowsAndColumns,
                        this.state.displayLanguageColumns,
                        this.toggleDisplayLanguageColumns,
                        this.enumValuesEditGridRef
                    );
                    this.setState({ showInvalidEnumValuesError: true, isGeneralFormValidated: true }, falseCallback);
                    return false;
                }
            }
        }

        return true;
    };

    static isTypeAndModuleValid(folderNames: string[], type: number): boolean {
        if (folderNames.indexOf(FolderNames.marketingList) !== -1) {
            return type !== AdditionalFieldTypes.relation && type !== AdditionalFieldTypes.multiSelectComboBox;
        }
        return true;
    }

    private readonly dismissEnumValuesError = () => {
        this.setState({ showInvalidEnumValuesError: false });
    };

    private readonly getSystemGroupPermissions = (fieldPermissions: TFieldPermissionWithName[]) => {
        return fieldPermissions.find((fp) => fp.colName === GroupNames.system)!;
    };

    private readonly submit = () => {
        if (!this.isStepValid(this.state.currentStep, false)) {
            return;
        }

        this.setState({ isSubmitting: true }, () => {
            this.submitFromIndex(0, () => {
                this.props.onDone();
            });
        });
    };

    private readonly submitFromIndex = (index: number, endCallback: () => void) => {
        if (this.state.folderNames.length <= index) {
            endCallback();
            return;
        }

        const folderName = this.state.folderNames[index];
        this.submitForFolder(folderName, () => this.submitFromIndex(index + 1, endCallback));
    };

    private readonly submitForFolder = (folderName: string, callback: () => void) => {
        const transmitObject: Partial<IApiAdditionalField> = {
            ObjectTypeFolderName: folderName,
            FileAs: this.state.name.trim(),
            Name: this.state.name.trim(),
            Comment: this.state.comment,
            Type: this.state.type,
            IsInGeneralSection: false,
        };
        if (this.state.type === AdditionalFieldTypes.textBox || this.state.type === AdditionalFieldTypes.linkTextBox) {
            transmitObject.Data_EditMask = this.state.editMask || null;
            if (this.state.type === AdditionalFieldTypes.linkTextBox) {
                transmitObject.Data_LinkType = this.state.linkType;
            }
        }
        if (this.state.type === AdditionalFieldTypes.memoBox) {
            transmitObject.Data_MemoBoxLines = this.state.numberOfRows;
        }
        if (this.state.type === AdditionalFieldTypes.numericBox) {
            transmitObject.Data_FormatType = this.state.numberFormat;
            transmitObject.Data_SummaryType = this.state.summaryType;
        }
        if (this.state.type === AdditionalFieldTypes.dateEdit) {
            transmitObject.Data_IsDateTime = this.state.showTime;
        }
        if (this.state.type === AdditionalFieldTypes.relation) {
            transmitObject.Data_RelatedFolderName = this.state.relatedFolderName;
        }
        this.context.connection.callApi(
            'SaveAdditionalField',
            {
                transmitObject: transmitObject,
            },
            (saveResult: IApiSaveResponse) => {
                const submitFieldPermissions = (callback: () => void) => {
                    this.context.connection.callApi(
                        'SearchAdditionalFields',
                        {
                            transmitObject: {
                                ItemGUID: saveResult.Guid,
                            },
                        },
                        (afResult: IApiDataResponse<IApiAdditionalField>) => {
                            const fieldPermissionsToSave = this.fieldPermissionsStorage.getItems();
                            this.context.connection.callApi(
                                'SaveColumnPermissions',
                                {
                                    transmitObjects: fieldPermissionsToSave.concat(this.getSystemGroupPermissions(this.state.fieldPermissions)).map((item) => {
                                        return {
                                            GroupGuid: item.GroupGuid,
                                            FolderName: folderName,
                                            ColumnName: afResult.Data[0].ColumnName,
                                            PermissionRule: item.PermissionRule,
                                            MandatoryRule: item.MandatoryRule,
                                        };
                                    }),
                                },
                                callback
                            );
                        }
                    );
                };

                if (this.state.type === AdditionalFieldTypes.comboBox || this.state.type === AdditionalFieldTypes.multiSelectComboBox) {
                    // We have to create the enum as well.
                    this.context.connection.callApi('GetAdditionalFieldsByItemGuids', { itemGuids: [saveResult.Guid] }, (getAfsResult: IApiDataResponse<IApiAdditionalField>) => {
                        if (getAfsResult.Data.length === 0) {
                            throw new Error('Unable to find additional field after it was created.');
                        }

                        if (!getAfsResult.Data[0].AssociatedEnumTypeGuid) {
                            throw new Error(`Additional field '${getAfsResult.Data[0].ItemGUID}' is type '${this.state.type}', but it has no AssociatedEnumTypeGuid.`);
                        }

                        const etTransmitObject = {
                            ItemGUID: getAfsResult.Data[0].AssociatedEnumTypeGuid,
                            EnumValuesInEnumType: this.state.enumValues.map((ev, i) => ({
                                Rank: i + 1,
                                FileAs: ev.FileAs,
                                En: ev.En,
                                Cs: ev.Cs,
                                De: ev.De,
                                Ru: ev.Ru,
                                No: ev.No,
                                Sk: ev.Sk,
                                IsDefault: ev.IsDefault,
                                IsVisible: ev.IsVisible,
                                IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
                            })),
                        };
                        this.context.connection.callApi('SaveEnumType', { transmitObject: etTransmitObject }, () => {
                            submitFieldPermissions(callback);
                        });
                    });
                } else {
                    submitFieldPermissions(callback);
                }
            }
        );
    };

    private readonly changeSystemGroupValues = (permissionRule: string, mandatoryRule: string) => {
        this.setState({
            fieldPermissions: this.state.fieldPermissions.map((fp) => {
                if (fp.colName === GroupNames.system) {
                    return { ...fp, PermissionRule: permissionRule, MandatoryRule: mandatoryRule };
                }
                return fp;
            }),
        });
    };

    private readonly onSystemGroupChange = (permissionName: string) => {
        const permissions = GeneralFieldPermissionToggles.onSystemGroupChange(permissionName, this.getSystemGroupPermissions(this.state.fieldPermissions), this.setFieldToUnique);
        if (permissions) {
            this.changeSystemGroupValues(permissions.permissionRule, permissions.mandatoryRule);
        }
    };

    private readonly setFieldToUnique = () => {
        const notFullyPermittedFieldPermissions = this.state.fieldPermissions.filter((fp) => fp.PermissionRule !== FieldPermissionNames.PermissionRuleKeys.All);
        if (notFullyPermittedFieldPermissions.length > 0 && !(notFullyPermittedFieldPermissions.length === 1 && notFullyPermittedFieldPermissions[0] === this.getSystemGroupPermissions(this.state.fieldPermissions))) {
            this.setState({ uniqueNotFullyPermittedForGroupName: this.props.allGroups.find((ag) => ag.ItemGUID === notFullyPermittedFieldPermissions[0].GroupGuid)!.GroupName });
        } else {
            this.changeSystemGroupValues(FieldPermissionNames.PermissionRuleKeys.All, FieldPermissionNames.MandatoryRuleKeys.Unique);
        }
    };

    private readonly toggleDisplayLanguageColumns = async (changedLanguage: string, doNotSaveToUserSettings?: boolean) => {
        const newLanguages = new RemoteItemStore(this.context.connection).toggleFieldsDisplayLanguageColumns(changedLanguage, this.state.displayLanguageColumns, doNotSaveToUserSettings);
        await ReactHelper.setState(this, { displayLanguageColumns: newLanguages });
    };

    render() {
        if (this.state.isSubmitting) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        }

        const isShowingCombobox = this.state.type === AdditionalFieldTypes.comboBox || this.state.type === AdditionalFieldTypes.multiSelectComboBox;
        const { isFieldPermissionsLocked } = this.context.licenseRestrictionsHelper.isFieldPermissionsLocked();
        return (
            <>
                <WizardModal
                    show={true}
                    size="xl"
                    dialogClassName={mergeStyles(isShowingCombobox && 'modal-90w')}
                    onHide={this.props.onDismiss}
                    hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}
                    showCancelButtonInFooter={true}
                    dialogBodyExpectedHeight="38rem"
                >
                    <WizardModal.Title>{myStrings.addField}</WizardModal.Title>
                    <WizardModal.Steps>
                        <WizardSteps
                            steps={[myStrings.general, myStrings.fieldPermissions]}
                            currentStep={this.state.currentStep}
                            onStepPicked={this.onStepPicked}
                            lockedSteps={isFieldPermissionsLocked ? [myStrings.fieldPermissions] : undefined}
                        />
                    </WizardModal.Steps>
                    <WizardModal.Body>
                        {this.state.currentStep === NewAdditionalFieldWizard.stepGeneral && (
                            <>
                                <h2 className="mb-5">{myStrings.general}</h2>
                                <FieldGeneralForm
                                    ref={this.generalFormReference}
                                    isGeneralFormValidated={this.state.isGeneralFormValidated}
                                    availableFolderNames={this.props.availableFolderNames}
                                    linkableFolderNames={this.props.linkableFolderNames}
                                    customizableFolderNames={this.props.customizableFolderNames}
                                    isNew={true}
                                    itemGuid={null}
                                    name={this.state.name}
                                    setName={(val) => this.setState({ name: val, anyChangeMade: true })}
                                    type={this.state.type}
                                    setType={(val) => this.setState({ type: val, anyChangeMade: true })}
                                    folderNames={this.state.folderNames}
                                    setFolderNames={(val) => this.setState({ folderNames: val, anyChangeMade: true })}
                                    comment={this.state.comment}
                                    setComment={(val) => this.setState({ comment: val, anyChangeMade: true })}
                                    editMask={this.state.editMask}
                                    setEditMask={(val) => this.setState({ editMask: val, anyChangeMade: true })}
                                    linkType={this.state.linkType}
                                    setLinkType={(val) => this.setState({ linkType: val, anyChangeMade: true })}
                                    numberOfRows={this.state.numberOfRows}
                                    setNumberOfRows={(val) => this.setState({ numberOfRows: val, anyChangeMade: true })}
                                    numberFormat={this.state.numberFormat}
                                    setNumberFormat={(val) => this.setState({ numberFormat: val, anyChangeMade: true })}
                                    summaryType={this.state.summaryType}
                                    setSummaryType={(val) => this.setState({ summaryType: val, anyChangeMade: true })}
                                    showTime={this.state.showTime}
                                    setShowTime={(val) => this.setState({ showTime: val, anyChangeMade: true })}
                                    relatedFolderName={this.state.relatedFolderName}
                                    setRelatedFolderName={(val) => this.setState({ relatedFolderName: val, anyChangeMade: true })}
                                    isRelatedFolderDisabled={false}
                                    enumTypeName={'AF_XXX'}
                                    enumValues={this.state.enumValues}
                                    setEnumValues={(val, clb) => this.setState({ enumValues: val, anyChangeMade: true }, clb)}
                                    areSystemPermissionsDisabled={false}
                                    systemGroupFieldPermissions={this.getSystemGroupPermissions(this.state.fieldPermissions)}
                                    onSystemGroupChange={this.onSystemGroupChange}
                                    allColumns={this.props.allColumns}
                                    displayLanguageColumns={this.state.displayLanguageColumns}
                                    toggleDisplayLanguageColumns={this.toggleDisplayLanguageColumns}
                                    allFields={this.props.allFields}
                                    folderName={this.props.folderName}
                                    enumValuesEditGridRef={this.enumValuesEditGridRef}
                                />
                                {this.state.uniqueNotFullyPermittedForGroupName !== null && (
                                    <FieldPermissionsWarningDialog
                                        nonUniqueValue={null}
                                        uniqueNotFullyPermittedForGroupName={this.state.uniqueNotFullyPermittedForGroupName}
                                        onHide={() => {
                                            this.setState({ uniqueNotFullyPermittedForGroupName: null });
                                        }}
                                    />
                                )}
                            </>
                        )}
                        {this.state.currentStep === NewAdditionalFieldWizard.stepPermissions &&
                            (!isFieldPermissionsLocked ? (
                                <>
                                    <h2>{myStrings.fieldPermissions}</h2>
                                    <FieldPermissionsForm
                                        allGroups={this.props.allGroups}
                                        fieldPermissions={this.state.fieldPermissions}
                                        searchedString={this.state.searchedString}
                                        onFieldPermissionsChange={(newFieldPermissions, newItemToSave) => {
                                            this.fieldPermissionsStorage.addItem(newItemToSave);
                                            this.setState({ fieldPermissions: newFieldPermissions, anyChangeMade: true });
                                        }}
                                        onSearchedStringChange={(newSearchedString) => this.setState({ searchedString: newSearchedString })}
                                        gridHeight={isShowingCombobox ? '40.05rem' : '26rem'}
                                    />
                                </>
                            ) : (
                                <LicenseRestrictionsLockedSplash />
                            ))}
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        {this.state.currentStep > NewAdditionalFieldWizard.firstStep && (
                            <Button variant="outline-secondary" onClick={this.back}>
                                {Strings.back}
                            </Button>
                        )}
                        {this.state.currentStep < NewAdditionalFieldWizard.lastStep && (
                            <Button variant="outline-secondary" onClick={this.next}>
                                {Strings.next}
                            </Button>
                        )}
                        <Button variant="primary" onClick={this.submit}>
                            {myStrings.createField}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
                {this.state.showInvalidEnumValuesError && <InvalidEnumValuesDialog onDismiss={this.dismissEnumValuesError} enumValues={this.state.enumValues} />}
            </>
        );
    }
}
