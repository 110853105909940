export enum UpdateStatusMessageType {
    touchedBeforeUpdateStart = 'TouchedBeforeUpdateStart',
    updateStarted = 'UpdateStarted',
    updateBreakedByFatalErrorWithSmallErrors = 'UpdateBreakedByFatalErrorWithSmallErrors',
    updateBreakedByFatalErrorWithoutSmallErrors = 'UpdateBreakedByFatalErrorWithoutSmallErrors',
    updateRunningWithSmallErrors = 'UpdateRunningWithSmallErrors',
    updateCompletedWithSmallErrors = 'UpdateCompletedWithSmallErrors',
    updateCompletedWithoutSmallErrors = 'UpdateCompletedWithoutSmallErrors',
    versionHasChangedInMeantime = 'VersionHasChangedInMeantime',
    updateFailedWhileShuttingDown = 'UpdateFailedWhileShuttingDown',
    updateFailedWhileBackingUpDatabase = 'UpdateFailedWhileBackingUpDatabase'
}