import * as React from 'react';
import Strings from '../../../strings';
import { Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiEnumType, IApiEnumValue } from '@eway-crm/connector';
import { EnumTypeEditMode } from '@eway-crm/connector';
import ReactHelper from '../../../helpers/ReactHelper';
import { DelayedRender } from '@fluentui/react';
import InvalidEnumValuesDialog from '../fields/InvalidEnumValuesDialog';
import EnumValuesValidator from '../../shared/dropDowns/EnumValuesValidator';
import EnumValuesEditGrid from '../../shared/dropDowns/EnumValuesEditGrid';
import EnumValuesColumns from '../../shared/dropDowns/EnumValuesColumns';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';

type TEditWorkflowNamesWizardProps = {
    onDismiss: () => void;
    enumType: IApiEnumType;
    enumValue: IApiEnumValue;
    onDone: () => Promise<void>;
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumn: (language: string) => Promise<void>;
};

type TEditWorkflowNameWizardState = {
    isSavingInProgress: boolean;
    enumValue: Partial<IApiEnumValue>[];
    anyChangeMade: boolean;
    showInvalidEnumValuesError: boolean;
};

const myStrings = Strings.components.routes.workflow;

export default class EditWorkflowNameWizard extends React.Component<TEditWorkflowNamesWizardProps, TEditWorkflowNameWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TEditWorkflowNamesWizardProps) {
        super(props);
        this.state = {
            enumValue: [props.enumValue],
            isSavingInProgress: false,
            anyChangeMade: false,
            showInvalidEnumValuesError: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly submit = async () => {
        const { askApi } = this.context.connection;
        if (!this.state.enumValue) {
            return;
        }
        
        const allEnumValues = this.getAllEnumValues();
        if (!EnumValuesValidator.getAreRowsAndColumnsValid(allEnumValues)) {
            this.setState({ showInvalidEnumValuesError: true });
            return;
        }

        await ReactHelper.setState(this, { isSavingInProgress: true });

        const transmitObjects = this.state.enumValue.map((ev, i) => ({
            ItemGUID: ev.ItemGUID,
            ItemVersion: ev.ItemVersion ?? 0 + 1,
            Rank: ev.Rank,
            FileAs: ev.FileAs,
            En: ev.En,
            Cs: ev.Cs,
            De: ev.De,
            Ru: ev.Ru,
            No: ev.No,
            Sk: ev.Sk,
            IsDefault: ev.IsDefault,
            IsVisible: ev.IsVisible,
            IncludeInLastActivityCalculation: ev.IncludeInLastActivityCalculation,
        }));

        await askApi('SaveEnumValues', { transmitObjects });
        void this.props.onDone();
    };

    private readonly dismissEnumValuesError = () => {
        this.setState({ showInvalidEnumValuesError: false });
        return;
    };

    private readonly getAllEnumValues = () => {
        const enumValuesInEnumType = this.props.enumType.EnumValuesInEnumType ?? this.state.enumValue;
        return enumValuesInEnumType.map(ev => ev.ItemGUID === this.state.enumValue[0].ItemGUID ? this.state.enumValue[0] : ev);
    };

    render() {
        const allEnumValues = this.getAllEnumValues();

        if (this.state.isSavingInProgress) {
            return <SpinnerModal variant={SpinnerVariant.linear} />;
        } else {
            return (
                <>
                    <WizardModal show={true} onHide={this.dismiss} showCancelButtonInFooter dialogClassName="modal-90w" hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}>
                        <WizardModal.Body>
                            <h1>{myStrings.editNameTitle}</h1>
                            <div className="mb-4">
                                <span key={'edit-wf-' + this.props.enumValue.ItemGUID} className="badge badge-info badge-full-font">
                                    {Strings.pickTranslation(this.props.enumValue)}
                                </span>
                            </div>
                            <div>{myStrings.editNameMessage}</div>
                            <DelayedRender>
                                <EnumValuesEditGrid
                                    data={this.state.enumValue}
                                    enumType={this.props.enumType}
                                    onDataChange={(val, clb) => this.setState({ enumValue: val, anyChangeMade: true }, clb)}
                                    gridHeight={200}
                                    displayLanguageColumns={this.props.displayLanguageColumns}
                                    hiddenColumns={[EnumValuesColumns.EDIT_GRID_COLS.dragColumn, EnumValuesColumns.EDIT_GRID_COLS.editColumn, EnumValuesColumns.EDIT_GRID_COLS.isDefault]}
                                    toggleDisplayLanguageColumns={this.props.toggleDisplayLanguageColumn}
                                    isReadonly={this.props.enumType.EditMode === EnumTypeEditMode.Readonly}
                                    isOnlyPartiallyEditable={this.props.enumType.EditMode === EnumTypeEditMode.VisibleRankDefaultOnly}
                                />
                            </DelayedRender>
                        </WizardModal.Body>
                        <WizardModal.Footer>
                            <Button variant="primary" onClick={() => void this.submit()}>
                                {Strings.save}
                            </Button>
                        </WizardModal.Footer>
                    </WizardModal>
                    {this.state.showInvalidEnumValuesError && (
                        <InvalidEnumValuesDialog
                            onDismiss={this.dismissEnumValuesError}
                            enumValues={allEnumValues}
                            customMessages={{ getErrorDuplicateText: (langColumn) => Strings.formatString(myStrings.errorDuplicateNameText, langColumn) as string }}
                        />
                    )}
                </>
            );
        }
    }
}
