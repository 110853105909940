import type { LocalizedStrings } from 'react-localization';
import Strings from '../strings';

export default class LocalizationHelper {
    static getLocalizedContent = <T>(ls: LocalizedStrings<T>, lang = 'en'): T => {
        type TMyLocalizedStrings = LocalizedStrings<T> & {
            getContent(): { [key: string]: T };
        };
        return (ls as TMyLocalizedStrings).getContent()[lang];
    };

    static isLangCs = () => {
        return Strings.getLanguage() === 'cs';
    };
}
