import { FontIcon } from '@fluentui/react';
import * as React from 'react';
import { Button } from 'react-bootstrap';
import type { ICustomerInfoModel } from '../../../data/shopping/ICustomerInfoModel';
import Strings from '../../../strings';
import { CompanyDetailsForm } from '../../shared/subscriptions/CompanyDetailsForm';
import type { TCountriesAndRegions } from '../subscriptions/TCountriesAndRegions';
import type { TCustomerInfo} from '../subscriptions/TCustomerInfo';
import { convertToCustomerInfo } from '../subscriptions/TCustomerInfo';

type TCompanyDetailsEditorProps = {
    originalCustomerInfo: ICustomerInfoModel;
    countries: TCountriesAndRegions;
    onSaving: (customerInfo: TCustomerInfo) => void;
};

export const CompanyDetailsEditor: React.FC<TCompanyDetailsEditorProps> = (props) => {
    const myStrings = Strings.components.routes.billingDetails;

    const [customerInfo, setCustomerInfoState] = React.useState<TCustomerInfo>(convertToCustomerInfo(props.originalCustomerInfo));
    const [isModified, setIsModified] = React.useState<boolean>(false);
    const setCustomerInfo = (infoPartial: Partial<TCustomerInfo>) => {
        setIsModified(true);
        setCustomerInfoState({ ...customerInfo, ...infoPartial });
    };
    const [isValidated, setIsValidated] = React.useState<boolean>(false);
    const detailsFormReference = React.useRef<HTMLFormElement>(null);

    const reset = () => {
        setCustomerInfoState(convertToCustomerInfo(props.originalCustomerInfo));
        setIsModified(false);
    };

    const save = () => {
        if (detailsFormReference.current && detailsFormReference.current.checkValidity()) {
            props.onSaving(customerInfo);
        } else {
            setIsValidated(true);
        }
    };

    return (
        <div>
            <div>
                <CompanyDetailsForm
                    countries={props.countries}
                    isFormValidated={isValidated}
                    formReference={detailsFormReference}
                    customerInfo={customerInfo}
                    onCustomerInfoChanged={setCustomerInfo}
                    hideHeadings={true}
                    hidePersonName={true}
                    emailLabel={myStrings.primaryContactEmail}
                />
            </div>
            {(isModified) &&
                <div className="container p-0">
                    <div className="row">
                        <div className="col align-self-center text-muted">
                            <FontIcon iconName="Info" style={{ lineHeight: '150%', fontSize: '150%', verticalAlign: 'middle' }} />
                            {' '}{Strings.allChangesWillBeSavedOnSave}
                        </div>
                        <div className="col-auto p-0 float-right">
                            <Button variant="primary" className="mr-2" onClick={save}>
                                {Strings.save}
                            </Button>
                            <Button variant="outline-secondary" onClick={reset}>
                                {Strings.discard}
                            </Button>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};