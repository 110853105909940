import * as React from 'react';
import Strings from '../../../strings';
import { Button, Alert } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { TAddingButtonChildProps } from './AddingButton';
import type { IShoppingDatumResponse } from '../../../data/shopping/IShoppingDatumResponse';
import BraintreeDropin from '../../shared/paymentGate/BraintreeDropin';
import { SpinnerVariant } from '@eway-crm/gui';

type TBraintreeAddingButtonState = {
    clientToken: string | null;
    nonce: string | null;
    isNonceSent: boolean;
    gotNonceError: boolean;
};

export class BraintreeAddingModal extends React.Component<TAddingButtonChildProps, TBraintreeAddingButtonState> {

    private nonceGetterRef: { current: ((getter: (nonce: string | null) => void) => void) | null };

    constructor(props: TAddingButtonChildProps) {
        super(props);
        this.state = {
            clientToken: null,
            nonce: null,
            isNonceSent: false,
            gotNonceError: false
        };
        this.nonceGetterRef = {
            current: null
        };
    }

    componentDidMount() {
        this.props.shoppingConnection.callShoppingApi(
            'GetBraintreeCustomerClientToken',
            {},
            (result: IShoppingDatumResponse<string | null>) => {
                this.setState({
                    clientToken: result.Datum,
                    nonce: null,
                    isNonceSent: false,
                    gotNonceError: false
                });
            },
            null
        );
    }

    private readonly cancel = () => {
        if (this.state.clientToken && this.state.nonce)
            return;

        this.props.onCanceled();
    };

    private readonly nonceProcessor = (nonce: string | null) => {
        if (nonce) {
            this.setState({
                clientToken: this.state.clientToken,
                nonce: nonce,
                isNonceSent: true,
                gotNonceError: false
            },
                () => {
                    this.props.shoppingConnection.callShoppingApi(
                        'AddPaymentMethod',
                        {
                            nonce: this.state.nonce
                        },
                        () => {
                            // Reload.
                            this.props.onAdded();
                        },
                        () => {
                            this.setState({
                                clientToken: this.state.clientToken,
                                nonce: null,
                                isNonceSent: true,
                                gotNonceError: true
                            });
                        }
                    );
                }
            );
        } else {
            this.setState({
                clientToken: this.state.clientToken,
                nonce: null,
                isNonceSent: false,
                gotNonceError: true
            });
        }
    };

    private readonly submit = () => {
        if (this.nonceGetterRef.current) {
            this.nonceGetterRef.current(this.nonceProcessor);
        } else {
            this.nonceProcessor(null);
        }
    };

    render() {
        const myStrings = Strings.components.routes.billingDetails;
        return (
            <>
                {this.state.clientToken && this.state.nonce ?
                    <SpinnerModal variant={SpinnerVariant.linear} />
                    :
                    <>
                        {this.state.clientToken ?
                            <WizardModal show={true} onHide={this.cancel}>
                                <WizardModal.Body>
                                    <h1>{myStrings.newPaymentMethod}</h1>
                                    {this.state.gotNonceError &&
                                        <Alert variant="danger">
                                            {myStrings.wrongMethod}
                                        </Alert>
                                    }
                                    <BraintreeDropin mode="vault" name="NewPaymentMethodApplet" clientToken={this.state.clientToken} onDropinInitialized={(getter) => { this.nonceGetterRef.current = getter; }} />
                                </WizardModal.Body>
                                <WizardModal.Footer>
                                    <Button variant="primary" onClick={this.submit} disabled={!(!this.state.clientToken) && !(!this.state.nonce)}>
                                        {myStrings.add}
                                    </Button>
                                    <Button variant="outline-secondary" onClick={this.cancel}>
                                        {Strings.cancel}
                                    </Button>
                                </WizardModal.Footer>
                            </WizardModal>
                            :
                            <SpinnerModal variant={SpinnerVariant.linear} />
                        }
                    </>
                }
            </>
        );
    }
}