import { FieldNames, GlobalSettingsNames } from "@eway-crm/connector";
import FolderNames from "../../../data/constants/FolderNames";
import type { TStageAttribute } from "./WorkflowModelContainer";

const workflowStageAttributesMap: Record<string, TStageAttribute[]> = {
    [FolderNames.bonuses]: [{ name: GlobalSettingsNames.bonusesCompletedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.bonusesCompletedState }],
    [FolderNames.projects]: [
        { name: GlobalSettingsNames.completedStateName, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.projectsCompletedState },
        { name: GlobalSettingsNames.deadStateName, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.projectsDeadState },
    ],
    [FolderNames.leads]: [
        {
            name: GlobalSettingsNames.leadsCompletedState,
            getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.leadsCompletedState,
            getSetFieldValueActions: () => [
                {
                    __type: 'SetFieldValueAction:#EA',
                    FieldName: FieldNames.Leads.Probability,
                    NewValue: '1',
                    Name: 'SetProbabilityValueAction',
                    ExecuteOnlyOnce: false,
                    Relevance: [{ GroupName: null, IsForAllGroups: true, CompletionLevelName: 'CannotIgnore' }],
                },
                {
                    __type: 'SetFieldValueAction:#EA',
                    FieldName: FieldNames.Leads.EstimatedEnd,
                    NewValue: 'SQL# SELECT GETDATE();',
                    Name: 'SetEstimatedEndValueAction',
                    ExecuteOnlyOnce: false,
                    Relevance: [{ GroupName: null, IsForAllGroups: true, CompletionLevelName: 'CannotIgnore' }],
                },
            ],
        },
        {
            name: GlobalSettingsNames.leadsDeadState,
            getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.leadsDeadState,
            getSetFieldValueActions: () => [
                {
                    __type: 'SetFieldValueAction:#EA',
                    FieldName: FieldNames.Leads.Probability,
                    NewValue: '0',
                    Name: 'SetProbabilityValueAction',
                    ExecuteOnlyOnce: false,
                    Relevance: [{ GroupName: null, IsForAllGroups: true, CompletionLevelName: 'CannotIgnore' }],
                },
                {
                    __type: 'SetFieldValueAction:#EA',
                    FieldName: FieldNames.Leads.EstimatedEnd,
                    NewValue: 'SQL# SELECT GETDATE();',
                    Name: 'SetEstimatedEndValueAction',
                    ExecuteOnlyOnce: false,
                    Relevance: [{ GroupName: null, IsForAllGroups: true, CompletionLevelName: 'CannotIgnore' }],
                },
            ],
        },
    ],
    [FolderNames.marketing]: [
        { name: GlobalSettingsNames.marketingCompletedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.marketingCompletedState },
        { name: GlobalSettingsNames.marketingDeadState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.marketingDeadState },
    ],
    [FolderNames.tasks]: [
        { name: GlobalSettingsNames.tasksCompletedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.tasksCompletedState },
        { name: GlobalSettingsNames.tasksDeferredState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.tasksDeferredState },
        { name: GlobalSettingsNames.tasksInProgressState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.tasksInProgressState },
        { name: GlobalSettingsNames.tasksNotStartedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.tasksNotStartedState },
        { name: GlobalSettingsNames.tasksWaitOnSomeoneElseState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.tasksWaitOnSomeoneElseState },
    ],
    [FolderNames.vacation]: [{ name: GlobalSettingsNames.vacationCompletedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.vacationCompletedState }],
    [FolderNames.workReports]: [{ name: GlobalSettingsNames.workReportApprovedState, getTranslation: (strings) => strings.components.routes.workflow.stageAttributes.workReportApprovedState }],
};

export default workflowStageAttributesMap;