import { CookiesHelper } from "@eway-crm/gui";

class Cookies {

    static names = {
        language: 'lang',
        sideMenu: 'sideMenu',
        users: 'users',
        features: 'features',
        modulePermissions: 'modulePermissions',
        fieldPermissions: 'fieldPermissions',
        fields: 'fields',
        workflow: 'workflow',
        browserId: 'browserid'
    };

    static setCookie<T extends Record<string, unknown>>(name: string, value: T | null | undefined, exhours: number) {
        let resultValue = '';
        if (value) {
            let resultObj;
            const currentObj = this.getCookie(name);
            if (currentObj) {
                resultObj = { ...currentObj, ...value };
            } else {
                resultObj = value;
            }
            resultValue = JSON.stringify(resultObj);
        }

        CookiesHelper.setCookieText(name, resultValue, exhours);
    }

    static getCookie<T>(name: string): Partial<T> {
        const textValue = CookiesHelper.getCookieText(name);
        if (!textValue) {
            return {};
        }
        try {
            return JSON.parse(textValue) as Partial<T>;
        } catch (_) {
            return {};
        }
    }
}

export default Cookies;