import * as React from 'react';
import type { Column, EditingCell, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { EditingState } from '@devexpress/dx-react-grid';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, VirtualTable, TableHeaderRow, TableInlineCellEditing, TableColumnResizing, TableColumnVisibility, TableColumnReordering } from '@devexpress/dx-react-grid-bootstrap4';
import { v1 as createUuid } from 'uuid';
import { Button, Form } from 'react-bootstrap';
import GridTable from '../../GridTable';
import type { IApiEnumType } from '@eway-crm/connector';
import type { IApiEnumValue } from '@eway-crm/connector';
import { EnumValueBadgeFlags } from './EnumValueBadgeFlags';
import type { IFormCheckChangeEvent } from '../../interfaces/IFormCheckChangeEvent';
import Strings from '../../../strings';
import { EnumTypes } from '../../../data/constants/EnumTypes';
import memoizeOne from 'memoize-one';
import AddTranslationColumnsDropdown from './AddTranslationColumnsDropdown';
import HideColumnDropdown from './HideColumnDropdown';
import StringHelper from '../../../helpers/StringHelper';
import { TooltipHost } from '@fluentui/react';
import FakeDisabledButton from '../FakeDisabledButton';
import EnumValuesValidator from './EnumValuesValidator';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import EnumValuesColumns from './EnumValuesColumns';
import type { TLicenseRestriction } from '../../../helpers/LicenseRestrictionsHelper';
import LicenseRestrictionsLockIcon from '../locks/LicenseRestrictionsLockedIcon';
import LicenseRestrictionsTooltipContent from '../locks/LicenseRestrictionsTooltipContent';
import { arrayMove, useSortable } from '@dnd-kit/sortable';
import type { DragEndEvent } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import { mergeClasses } from '@fluentui/react-components';

export type TEnumValueEditGridCustomCellProps = Table.DataCellProps & { stateSetter: React.Component<TEnumValuesEditGridProps, TEnumValuesEditGridState>['setState'] };

export type EnumValueAdditionalColumnConfig = {
    columnName: string;
    editingEnabled?: boolean;
    align?: 'left' | 'right' | 'center';
    title: string;
    width: number;
    tableHeadCell?: React.FC<TableHeaderRow.CellProps>;
    cellComponent?: React.FC<TEnumValueEditGridCustomCellProps>;
};

export type TEnumValue = IApiEnumValue & {
    CanBeDeleted: boolean;
};

const gridRootId = 'EnumTypeEditWizardGridRoot';
const gridContainerId = 'EnumTypeEditWizardGridContainer';
const gridRowHeight = 51;
const DEFAULT_LANGUAGE_COLUMN_WIDTH = 300;

const GridRootComponent = GridTable.createRootWithProps({ id: gridRootId });
const TableContainerComponent = GridTable.createTableContainerComponent({ id: gridContainerId });

const myStrings = Strings.components.routes.fields.dropDowns;

type TEnumValuesEditGridProps = {
    enumType: Pick<IApiEnumType, 'EnumName' | 'AllowEditVisibility' | 'AllowEditLastActivity' | 'RequireDefaultValue' | 'IsSystem' | 'IsAdditionalField' | 'EditMode'>;
    data: Partial<TEnumValue>[];
    isLastActivityGsEnabled?: boolean;
    onDataChange: (newData: Partial<TEnumValue>[], callback?: () => void) => void;
    gridHeight?: number;
    hiddenColumns?: string[];
    displayLanguageColumns: string[];
    toggleDisplayLanguageColumns: (language: string) => Promise<void>;
    isOnlyPartiallyEditable?: boolean;
    isReadonly?: boolean;
    additionalColumns?: EnumValueAdditionalColumnConfig[] | null;
    customChangeHandler?: (row: Partial<IApiEnumValue>, changedRow: Partial<IApiEnumValue>) => Partial<IApiEnumValue>;
    disabledColumns?: string[];
    addNewValuesToBottom?: boolean;
    languageColumnWidth?: number;
    hasWorkflowColumnHeaders?: boolean;
    columnName?: string;
    tableRef?: React.RefObject<typeof VirtualTable>;
};

type TEnumValuesEditGridState = {
    editingCells: EditingCell[];
    columnWidths: TableColumnWidthInfo[];
};

export default class EnumValuesEditGrid extends React.Component<TEnumValuesEditGridProps, TEnumValuesEditGridState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TEnumValuesEditGridProps) {
        super(props);
        this.state = {
            editingCells: [],
            columnWidths: [],
        };
    }

    private readonly commitChanges = (added?: readonly Partial<IApiEnumValue>[], changed?: { [key: string]: Partial<IApiEnumValue> }, deleted?: readonly React.ReactText[]) => {
        if (!added && (!changed || (changed && Object.values(changed).length === 1 && !Object.values(changed)[0])) && !deleted) {
            return;
        }

        const stageName = StringHelper.capitalize(Strings.getLanguage()) as keyof IApiEnumValue;
        const rows = this.props.data;
        let changedRows: Partial<TEnumValue>[] = [];
        let editingCells: EditingCell[] | undefined;
        if (added) {
            const itemGuid = createUuid();
            const fieldLicenseRestriction = this.context.licenseRestrictionsHelper.getFieldLicenseRestriction(this.props.columnName, this.props.enumType.EnumName);
            const isVisibleCountExceeded = this.getIsVisibleCountExceeded(fieldLicenseRestriction);

            const addedRows = added.map((row) => ({
                ItemGUID: itemGuid,
                ItemVersion: 0,
                CanBeDeleted: true,
                IsSystem: false,
                FileAs: '',
                En: '',
                Cs: '',
                De: '',
                Ru: '',
                Sk: '',
                No: '',
                IsDefault: false,
                IsVisible: isVisibleCountExceeded ? false : true,
                IncludeInLastActivityCalculation: this.props.isLastActivityGsEnabled ?? false,
                ...row,
            }));

            if (this.props.addNewValuesToBottom) {
                changedRows = [...rows, ...addedRows];
            } else {
                changedRows = [...addedRows, ...rows];
            }

            editingCells = [{ rowId: itemGuid, columnName: stageName }];
        }
        if (changed) {
            let defaultGuid: string | null = null;
            const changeCombiner = (row: Partial<IApiEnumValue>, changedRow: Partial<IApiEnumValue>): Partial<IApiEnumValue> => {
                if (this.props.customChangeHandler) {
                    return this.props.customChangeHandler(row, changedRow);
                }

                if (changedRow.IsDefault) {
                    defaultGuid = row.ItemGUID!;
                }
                const result = { ...row, ...changedRow };
                const sameOrEmpty = (getter: (r: Partial<IApiEnumValue>) => string | null | undefined): boolean => {
                    const resultStr = getter(result);
                    const rowStr = getter(row);
                    return (!resultStr && !rowStr) || (resultStr === row[stageName] && rowStr === row[stageName]);
                };
                if (
                    sameOrEmpty((r) => Strings.switch(r.Cs as string | null, r.En as string | null)) &&
                    sameOrEmpty((r) => r.De) &&
                    sameOrEmpty((r) => r.Sk) &&
                    sameOrEmpty((r) => r.Ru) &&
                    sameOrEmpty((r) => r.No) &&
                    sameOrEmpty((r) => r.FileAs)
                ) {
                    const stageNameValue = result[stageName] as string | null;
                    result.En = stageNameValue;
                    result.Cs = stageNameValue;
                    result.De = stageNameValue;
                    result.Sk = stageNameValue;
                    result.Ru = stageNameValue;
                    result.No = stageNameValue;
                    result.FileAs = stageNameValue;
                }
                return result;
            };
            changedRows = rows.map((row) => (changed[row.ItemGUID!] ? changeCombiner(row, changed[row.ItemGUID!]) : row));
            if (defaultGuid) {
                for (let i = 0; i < changedRows.length; i++) {
                    if (changedRows[i].IsDefault && changedRows[i].ItemGUID !== defaultGuid) {
                        changedRows[i].IsDefault = false;
                    }
                }
            }
        }
        if (deleted) {
            const deletedSet = new Set(deleted);
            changedRows = rows.filter((row) => !deletedSet.has(row.ItemGUID!));
        }

        const saveCallback = () => {
            this.props.onDataChange(changedRows);
        };
        if (editingCells) {
            this.setState(
                {
                    editingCells: editingCells,
                },
                saveCallback
            );
        } else {
            saveCallback();
        }
    };

    private readonly handleDragEnd = (event: DragEndEvent) => {
        const { active, over } = event;
        if (!active || !over) {
            return;
        }

        if (active.id !== over.id) {
            const oldIndex = this.props.data.findIndex(x => x.ItemGUID === active.id);
            const newIndex = this.props.data.findIndex(x => x.ItemGUID === over.id);

            if (oldIndex === -1 || newIndex === -1) {
                return;
            }

            const newData = arrayMove(this.props.data, oldIndex, newIndex);
            this.props.onDataChange(newData);
        }
    };

    private readonly addEmptyRow = () => this.commitChanges([{}]);

    private readonly removeRow = (itemGuid: string) => this.commitChanges(undefined, undefined, [itemGuid]);

    private readonly getSystemItemGuidsMap = memoizeOne((data: Partial<TEnumValue>[]) => {
        return new Set(data.filter((ev) => ev.IsSystem).map((ev) => ev.ItemGUID!));
    });

    private readonly handleEditCellsChanged = (cells: EditingCell[]) => {
        if (this.props.isOnlyPartiallyEditable || this.props.isReadonly) {
            cells = [];
        }

        const systemItemGuidsMap = this.getSystemItemGuidsMap(this.props.data);
        if (cells && cells.length > 0) {
            cells = cells.filter(
                (c) =>
                    c.columnName !== EnumValuesColumns.EDIT_GRID_COLS.dragColumn &&
                    c.columnName !== EnumValuesColumns.EDIT_GRID_COLS.editColumn &&
                    c.columnName !== EnumValuesColumns.EDIT_GRID_COLS.isDefault &&
                    c.columnName !== EnumValuesColumns.EDIT_GRID_COLS.isVisible &&
                    c.columnName !== EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity &&
                    !this.props.disabledColumns?.includes(c.columnName) &&
                    !systemItemGuidsMap.has(c.rowId as string)
            );
        }

        // For some reason, if don't stop here, component reloads and the scroll is reset.
        if (cells.length === 0 && this.state.editingCells.length === 0) {
            return;
        }

        this.setState({ editingCells: cells });
    };

    private readonly handgleGridStateChange = <K extends keyof TEnumValuesEditGridState>(newState: Pick<TEnumValuesEditGridState, K>) => {
        this.setState(newState);
    };

    private readonly getIsVisibleCountExceeded = (fieldLicenseRestriction: TLicenseRestriction | null) => {
        const fieldLicenseRestrictionLimit = fieldLicenseRestriction?.currentLimit;
        const currentIsVisibleCount = this.props.data.filter(d => d.IsVisible && !d.IsSystem).length;
        const isVisibleCountExceeded = typeof fieldLicenseRestrictionLimit === "number" && currentIsVisibleCount >= fieldLicenseRestrictionLimit;
        return isVisibleCountExceeded;
    };

    private readonly TableBody: React.FunctionComponent = (props) => {
        return <GridTable.ReorderableTableBodyComponent itemIds={this.props.data.map(x => x.ItemGUID!)} onDragEnd={this.handleDragEnd} {...props} />;
    };

    private readonly TableHeadCell: React.FunctionComponent<TableHeaderRow.CellProps> = (props) => {
        const isStageName = props.column.name.toLowerCase() === Strings.getLanguage();
        const stageNameTranslation = this.props.hasWorkflowColumnHeaders ? myStrings.stageName : myStrings.name;
        let licenseRestriction: TLicenseRestriction | null = null;

        if (props.column.name === EnumValuesColumns.EDIT_GRID_COLS.editColumn) {
            const additionalProps: { disabled?: boolean; style?: React.CSSProperties } = {};
            if (this.state.editingCells.length !== 0) {
                additionalProps.disabled = true;
                additionalProps.style = { cursor: 'not-allowed' };
            }
            return (
                <TableHeaderRow.Cell {...props} style={{ height: '46px' }}>
                    {!this.props.isOnlyPartiallyEditable && !this.props.isReadonly && (
                        <Button variant="link" onClick={() => this.addEmptyRow()} className="p-0 border-0" {...additionalProps}>
                            <i className="mdl2 mdl2-add" aria-hidden="true" />
                        </Button>
                    )}
                </TableHeaderRow.Cell>
            );
        } else if (props.column.name === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity) {
            const { isNextStepsAndLastActivityLocked, nextStepsAndLastActivityLicenseRestriction } = this.context.licenseRestrictionsHelper.isNextStepsAndLastActivityLocked();
            if (isNextStepsAndLastActivityLocked) {
                licenseRestriction = nextStepsAndLastActivityLicenseRestriction;
            }
        } else if (props.column.name === EnumValuesColumns.EDIT_GRID_COLS.addTranslations) {
            const { gridColumns } = this.getColumns(
                this.props.enumType.EnumName === EnumTypes.taskType,
                this.props.enumType.AllowEditVisibility,
                this.props.enumType.AllowEditLastActivity,
                this.props.additionalColumns,
                this.props.languageColumnWidth
            );
            return (
                <TableHeaderRow.Cell {...props} className="py-0 px-1">
                    <AddTranslationColumnsDropdown
                        displayLanguageColumns={this.props.displayLanguageColumns}
                        gridColumns={gridColumns}
                        onToggleDislayLanguageColumn={this.props.toggleDisplayLanguageColumns}
                    />
                </TableHeaderRow.Cell>
            );
        } else if (EnumValuesColumns.LANGUAGE_COLUMNS.includes(props.column.name) && !isStageName) {
            return (
                <TableHeaderRow.Cell {...props} title={props.column.title} style={{ height: gridRowHeight }}>
                    {props.children} <HideColumnDropdown colName={props.column.name} onToggleDisplayLanguageColumn={this.props.toggleDisplayLanguageColumns} />
                </TableHeaderRow.Cell>
            );
        } else if (this.props.additionalColumns) {
            const currentColumn = this.props.additionalColumns.find((col) => col.columnName === props.column.name);
            if (currentColumn && currentColumn.tableHeadCell) {
                return <currentColumn.tableHeadCell {...props} />;
            }
        }
        return (
            <TableHeaderRow.Cell {...props} title={props.column.title} style={{ height: gridRowHeight }}>
                {isStageName ? stageNameTranslation : props.children}
                {!!licenseRestriction && <LicenseRestrictionsLockIcon licenseRestriction={licenseRestriction} isSmall={false} />}
            </TableHeaderRow.Cell>
        );
    };

    private readonly TableRow: React.FunctionComponent<Table.DataRowProps> = (props) => {
        const row = props.row as TEnumValue;

        const sortable = useSortable({ id: row.ItemGUID });
        const style = {
            transform: CSS.Transform.toString(sortable.transform),
            transition: sortable.transition,
        };

        return <VirtualTable.Row {...props} forwardedRef={sortable.setNodeRef} data-guid={row.ItemGUID} className={mergeClasses(sortable.isDragging && "sortable-is-dragging")} style={style} />;
    };

    private readonly ViewTableCell: React.FunctionComponent<Table.DataCellProps> = (props) => {
        const row = props.row as TEnumValue;

        const isOnlyPartiallyEditable = this.props.isOnlyPartiallyEditable || row.IsSystem;
        const isReadonly = this.props.isReadonly;
        const invalidRowsAndColumns = EnumValuesValidator.getInvalidRowsAndColumns(this.props.data);
        const isStageName = props.column.name === Strings.switch(EnumValuesColumns.EDIT_GRID_COLS.en, EnumValuesColumns.EDIT_GRID_COLS.cs);

        let className = 'align-middle';

        if (isReadonly) {
            className += ' disabled';
        } else if (
            isOnlyPartiallyEditable &&
            props.column.name !== EnumValuesColumns.EDIT_GRID_COLS.dragColumn &&
            props.column.name !== EnumValuesColumns.EDIT_GRID_COLS.isDefault &&
            props.column.name !== EnumValuesColumns.EDIT_GRID_COLS.isVisible &&
            props.column.name !== EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity
        ) {
            className += ' disabled';
        }
        if (this.props.disabledColumns?.includes(props.column.name)) {
            className += ' disabled';
        }

        if (isStageName) {
            className += ' highlighted';
        }
        if (invalidRowsAndColumns.has(row.ItemGUID)) {
            if (invalidRowsAndColumns.get(row.ItemGUID)!.findIndex((c) => c === props.column.name) !== -1) {
                className += ' bg-danger text-white';
            }
        }

        if (props.column.name === EnumValuesColumns.EDIT_GRID_COLS.dragColumn) {
            const isDragEnabled = this.state.editingCells.length === 0 && !isReadonly;
            return (
                <VirtualTable.Cell className={className} {...props}>
                    <GridTable.DragSymbol style={{ cursor: isDragEnabled ? 'move' : 'not-allowed' }} rowId={row.ItemGUID} disabled={!isDragEnabled} />
                </VirtualTable.Cell>
            );
        } else if (props.column.name === EnumValuesColumns.EDIT_GRID_COLS.editColumn) {
            const additionalProps: { disabled?: boolean; style?: React.CSSProperties } = {};
            if (this.state.editingCells.length !== 0) {
                additionalProps.disabled = true;
                additionalProps.style = { cursor: 'not-allowed' };
            }

            let tooltipContent;
            if (isOnlyPartiallyEditable || isReadonly) {
                tooltipContent = Strings.components.routes.fields.enumValueCannotBeDeletedIsSystem;
            } else if (!row.CanBeDeleted) {
                tooltipContent = Strings.components.routes.fields.enumValueCannotBeDeletedIsUsed;
            }

            return (
                <VirtualTable.Cell className={className} {...props}>
                    <TooltipHost content={tooltipContent}>
                        <FakeDisabledButton
                            variant="link"
                            onClick={() => this.removeRow(row.ItemGUID)}
                            className="p-0 border-0"
                            {...additionalProps}
                            disabled={!row.CanBeDeleted || isOnlyPartiallyEditable || isReadonly || this.state.editingCells.length !== 0}
                        >
                            <i className="mdl2 mdl2-delete" aria-hidden="true" />
                        </FakeDisabledButton>
                    </TooltipHost>
                </VirtualTable.Cell>
            );
        } else if (
            props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isDefault ||
            props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isVisible ||
            props.column.name === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity
        ) {
            className += ' text-center';
            const protectDefaultFromUnchecking = props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isDefault && this.props.enumType.RequireDefaultValue;
            const protectVisibleFromUnchecking = props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isVisible && row.IsDefault && this.props.enumType.RequireDefaultValue;
            const { isNextStepsAndLastActivityLocked, nextStepsAndLastActivityLicenseRestriction } = this.context.licenseRestrictionsHelper.isNextStepsAndLastActivityLocked();
            const isNextStepsOrLastActivityDisabled = props.column.name === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity && isNextStepsAndLastActivityLocked;
            const changeHandler = (e: IFormCheckChangeEvent) => {
                if (!e.target.checked && (protectDefaultFromUnchecking || protectVisibleFromUnchecking)) {
                    // Default flag can be removed only by activating it anywhere else.
                    return;
                }

                const fieldLicenseRestriction = this.context.licenseRestrictionsHelper.getFieldLicenseRestriction(this.props.columnName, this.props.enumType.EnumName);
                const isVisibleCountExceeded = this.getIsVisibleCountExceeded(fieldLicenseRestriction);
                if (isVisibleCountExceeded && !row.IsSystem) {
                    const protectVisibleFromChecking = props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isVisible && isVisibleCountExceeded;
                    const protectDefaultFromChecking = props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isDefault && isVisibleCountExceeded; // Default flag also turns on isVisible
                    if (e.target.checked && (protectVisibleFromChecking || protectDefaultFromChecking)) {
                        this.context.showLicenseRestrictionModal(fieldLicenseRestriction!);
                        return;
                    }
                }

                const obj: Record<string, string | boolean> = { ItemGUID: row.ItemGUID };

                // Value with default flag always has to be visible
                if (e.target.checked && props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isDefault) {
                    obj[EnumValuesColumns.EDIT_GRID_COLS.isVisible] = true;
                } else if (!e.target.checked && props.column.name === EnumValuesColumns.EDIT_GRID_COLS.isVisible && row.IsDefault) {
                    obj[EnumValuesColumns.EDIT_GRID_COLS.isDefault] = false;
                }

                obj[props.column.name] = e.target.checked;
                const array: Record<string, typeof obj> = {};
                array[row.ItemGUID] = obj;
                this.commitChanges(undefined, array, undefined);
            };
            return (
                <VirtualTable.Cell className={className} {...props}>
                    <TooltipHost content={isNextStepsOrLastActivityDisabled ? <LicenseRestrictionsTooltipContent licenseRestriction={nextStepsAndLastActivityLicenseRestriction} /> : undefined}>
                        <Form.Check
                            type="checkbox"
                            // Make all license locked next steps or last activity checked
                            checked={isNextStepsOrLastActivityDisabled || (props.value as boolean)}
                            onChange={changeHandler}
                            disabled={this.state.editingCells.length > 0 || isReadonly || isNextStepsOrLastActivityDisabled}
                        />
                    </TooltipHost>
                </VirtualTable.Cell>
            );
        } else if (this.props.additionalColumns) {
            const currentColumn = this.props.additionalColumns.find((col) => col.columnName === props.column.name);
            if (currentColumn && currentColumn.cellComponent) {
                return <currentColumn.cellComponent {...props} stateSetter={this.handgleGridStateChange} />;
            }
        }

        return (
            <VirtualTable.Cell className={className} {...props}>
                {props.value}
                {isStageName && <EnumValueBadgeFlags item={row} />}
            </VirtualTable.Cell>
        );
    };

    private readonly getColumns = memoizeOne((isFuturistic: boolean, allowEditVisibility: boolean, allowEditLastActivity: boolean, additionalColumns?: EnumValueAdditionalColumnConfig[] | null, customLanguageColumnWidth?: number) => {
        const isLangCs = Strings.getLanguage() === 'cs';
        const languageColumnWidth = customLanguageColumnWidth ?? DEFAULT_LANGUAGE_COLUMN_WIDTH;

        const gridColumns: Column[] = [
            { name: EnumValuesColumns.EDIT_GRID_COLS.dragColumn, title: ' ' },
            { name: EnumValuesColumns.EDIT_GRID_COLS.editColumn, title: ' ' },
            { name: EnumValuesColumns.EDIT_GRID_COLS.isVisible, title: myStrings.visible },
            { name: EnumValuesColumns.EDIT_GRID_COLS.isDefault, title: myStrings.default },
            { name: EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity, title: isFuturistic ? myStrings.useForNextStep : myStrings.useForLastActivity },
            { name: EnumValuesColumns.EDIT_GRID_COLS.en, title: myStrings.en },
            { name: EnumValuesColumns.EDIT_GRID_COLS.cs, title: myStrings.cs },
            { name: EnumValuesColumns.EDIT_GRID_COLS.de, title: myStrings.de },
            { name: EnumValuesColumns.EDIT_GRID_COLS.ru, title: myStrings.ru },
            { name: EnumValuesColumns.EDIT_GRID_COLS.sk, title: myStrings.sk },
            { name: EnumValuesColumns.EDIT_GRID_COLS.no, title: myStrings.no },
            { name: EnumValuesColumns.EDIT_GRID_COLS.technicalName, title: myStrings.techName },
            { name: EnumValuesColumns.EDIT_GRID_COLS.addTranslations, title: myStrings.addColumn },
        ];

        const gridColumnWidths: TableColumnWidthInfo[] = [
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.dragColumn, width: 38 },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.editColumn, width: 40 },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isVisible, width: isLangCs ? 74 : 68 },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isDefault, width: isLangCs ? 80 : 63 },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity, width: isLangCs ? 226 : 190 },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.en, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.cs, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.de, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.ru, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.sk, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.no, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.technicalName, width: languageColumnWidth },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.addTranslations, width: 150 },
        ];

        const gridColumnAlignment: Table.ColumnExtension[] = [
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.dragColumn, align: 'center' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.editColumn, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isVisible, align: 'center' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isDefault, align: 'center' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity, align: 'center' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.en, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.cs, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.de, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.ru, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.sk, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.no, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.technicalName, align: 'left' },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.addTranslations, align: 'left' },
        ];

        const gridColumnExtensions: EditingState.ColumnExtension[] = [
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.dragColumn, editingEnabled: false },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.editColumn, editingEnabled: false },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isVisible, editingEnabled: false },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.isDefault, editingEnabled: false },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity, editingEnabled: false },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.en, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.cs, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.de, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.ru, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.sk, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.no, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.technicalName, editingEnabled: true },
            { columnName: EnumValuesColumns.EDIT_GRID_COLS.addTranslations, editingEnabled: false },
        ];

        if (!allowEditVisibility) {
            gridColumns.removeWhere((col) => col.name === EnumValuesColumns.EDIT_GRID_COLS.isVisible);
            gridColumnWidths.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.isVisible);
            gridColumnAlignment.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.isVisible);
            gridColumnExtensions.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.isVisible);
        }

        if (!allowEditLastActivity) {
            gridColumns.removeWhere((col) => col.name === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity);
            gridColumnWidths.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity);
            gridColumnAlignment.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity);
            gridColumnExtensions.removeWhere((col) => col.columnName === EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity);
        }

        if (additionalColumns) {
            additionalColumns.forEach((config) => {
                gridColumns.push({ name: config.columnName, title: config.title });
                gridColumnWidths.push({ columnName: config.columnName, width: config.width ?? languageColumnWidth });
                gridColumnExtensions.push({ columnName: config.columnName, editingEnabled: !!config.editingEnabled });

                if (config.align) {
                    gridColumnAlignment.push({ columnName: config.columnName, align: config.align });
                }
            });
        }

        return { gridColumns, gridColumnWidths, gridColumnAlignment, gridColumnExtensions };
    });

    private readonly getHiddenColumnNames = memoizeOne((displayLanguageColumns: string[], hiddenColumns?: string[]) =>
        EnumValuesColumns.LANGUAGE_COLUMNS.filter((lang) => !displayLanguageColumns.includes(lang)).concat(hiddenColumns ?? [])
    );

    private readonly getColumnsOrder = memoizeOne((displayLanguageColumns: string[]) => {
        const order: string[] = [
            EnumValuesColumns.EDIT_GRID_COLS.dragColumn,
            EnumValuesColumns.EDIT_GRID_COLS.editColumn,
            EnumValuesColumns.EDIT_GRID_COLS.isVisible,
            EnumValuesColumns.EDIT_GRID_COLS.isDefault,
            EnumValuesColumns.EDIT_GRID_COLS.useForLastActivity,
        ];
        displayLanguageColumns.forEach((lang) => order.push(lang));

        order.push(EnumValuesColumns.EDIT_GRID_COLS.technicalName);
        if (this.props.additionalColumns) {
            this.props.additionalColumns.forEach((col) => {
                order.push(col.columnName);
            });
        }
        order.push(EnumValuesColumns.EDIT_GRID_COLS.addTranslations);

        return order.filter((item) => !this.props.hiddenColumns?.includes(item));
    });

    render() {
        const { gridColumns, gridColumnWidths, gridColumnAlignment, gridColumnExtensions } = this.getColumns(
            this.props.enumType.EnumName === EnumTypes.taskType,
            this.props.enumType.AllowEditVisibility,
            this.props.enumType.AllowEditLastActivity,
            this.props.additionalColumns,
            this.props.languageColumnWidth
        );

        return (
            <div className="flex-fill h-100">
                <Grid rows={this.props.data} columns={gridColumns} rootComponent={GridRootComponent} getRowId={(row: IApiEnumValue) => row.ItemGUID}>
                    <EditingState
                        onCommitChanges={(p) => this.commitChanges(p.added, p.changed, p.deleted)}
                        editingCells={this.state.editingCells}
                        onEditingCellsChange={this.handleEditCellsChanged}
                        addedRows={[]}
                        onAddedRowsChange={this.addEmptyRow}
                        columnExtensions={gridColumnExtensions}
                    />
                    <VirtualTable
                        height={this.props.gridHeight}
                        containerComponent={TableContainerComponent}
                        cellComponent={this.ViewTableCell}
                        bodyComponent={this.TableBody}
                        rowComponent={this.TableRow}
                        messages={{ noData: myStrings.noValuesYet }}
                        noDataCellComponent={GridTable.NoDataCell}
                        estimatedRowHeight={gridRowHeight}
                        columnExtensions={gridColumnAlignment}
                        ref={this.props.tableRef}
                    />
                    <TableColumnResizing defaultColumnWidths={gridColumnWidths} />
                    <TableColumnReordering order={this.getColumnsOrder(this.props.displayLanguageColumns)} />
                    <TableHeaderRow cellComponent={this.TableHeadCell} />
                    <TableColumnVisibility hiddenColumnNames={this.getHiddenColumnNames(this.props.displayLanguageColumns, this.props.hiddenColumns)} />
                    <TableInlineCellEditing />
                </Grid>
            </div>
        );
    }
}
