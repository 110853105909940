import type React from 'react';
import { useEffect } from 'react';
import type { RouteComponentProps } from 'react-router-dom';
import Strings from '../../strings';

type TAlertBeforeRouteChange = Pick<RouteComponentProps, 'history'> & {
    message?: string;
};

/**
 * Browser will show confirmation dialog before changing route.
 */

const ConfirmBeforeRouteChange: React.FC<TAlertBeforeRouteChange> = ({ history, message }) => {
    useEffect(() => {
        const unblock = history.block(() => message ?? Strings.confirmRouteLeave);
        return unblock;
    }, [history, message]);

    return null;
};

export default ConfirmBeforeRouteChange;
