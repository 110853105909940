import * as React from 'react';
import { Overlay, Popover } from 'react-bootstrap';

export type TPopoverPlacement = 'auto-start' | 'auto' | 'auto-end' | 'top-start' | 'top' | 'top-end' | 'right-start' | 'right' | 'right-end' | 'bottom-end' | 'bottom' | 'bottom-start' | 'left-end' | 'left' | 'left-start';

type StableOverlayPopoverProps = React.PropsWithChildren & {
    placement: TPopoverPlacement;
    popoverTitle: React.ReactNode;
    popoverContent: React.ReactNode;
    className?: string;
};

type StableOverlayPopoverState = {
    hovering?: boolean;
    show?: boolean;
};

class StableOverlayPopover extends React.Component<StableOverlayPopoverProps, StableOverlayPopoverState> {

    private target: React.RefObject<HTMLDivElement>;

    constructor(props: StableOverlayPopoverProps) {
        super(props);
        this.target = React.createRef();
        this.state = {
            hovering: false,
            show: false
        };
    }

    private handleMouseEnter = () => {
        this.setState({ hovering: true, show: true });
    };

    private handleMouseLeave = () => {
        this.setState(
            { hovering: false },
            () => {
                setTimeout(
                    () => {
                        if (!this.state.hovering) {
                            this.setState({ show: false });
                        }
                    },
                    300
                );
            }
        );
    };
    render() {

        return (
            <>
                <div ref={this.target} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave} className={this.props.className}>
                    {this.props.children}
                </div>
                <Overlay target={this.target.current} show={this.state.show} placement={this.props.placement}>
                    <Popover id="popover-package-info">
                        <div onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                            <Popover.Title as="h3">{this.props.popoverTitle}</Popover.Title>
                            <Popover.Content>{this.props.popoverContent}</Popover.Content>
                        </div>
                    </Popover>
                </Overlay>
            </>
        );
    }
}

export default StableOverlayPopover;