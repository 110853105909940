import type { Connection } from '../components/layout/connection/Connection';
import type { IApiDataResponse } from '@eway-crm/connector';
import type { IApiPasswordStrength } from '@eway-crm/connector';

export class PasswordHelper {
    static MIN_ACCEPTABLE_PASSWORD_SCORE = 2;

    static readonly getLowestPasswordScore = (passwordStrengths: IApiPasswordStrength[] | null) => {
        if (!passwordStrengths || passwordStrengths.length === 0) {
            return 0;
        } else {
            const scoreArray = passwordStrengths.map((ps) => ps.Score);
            return Math.min(...scoreArray); // Take the lowest score
        }
    };

    static readonly areStrongEnough = (passwordStrengths?: IApiPasswordStrength[] | null) => {
        if (!passwordStrengths || passwordStrengths.length === 0) {
            return false;
        }
        return passwordStrengths.every((ps) => ps.IsAdmissible || ps.Score >= PasswordHelper.MIN_ACCEPTABLE_PASSWORD_SCORE);
    };

    static generate(length: number): string {
        const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*';
        let retVal = '';
        for (let i = 0, n = charset.length; i < length; ++i) {
            retVal += charset.charAt(Math.floor(Math.random() * n));
        }
        return retVal;
    }

    static getPasswordStrengths = async (connection: Connection, password: string, usernames: string[]) => {
        const nonEmptyUsernames: string[] | null = usernames.filter((username) => !!username); // API does not accept empty usernames
        if (password && nonEmptyUsernames.length !== 0) {
            const { Data: passwordStrengths } = await connection.askApi<IApiDataResponse<IApiPasswordStrength>>('CheckPasswordStrength', {
                password,
                usernames: nonEmptyUsernames,
            });
            return passwordStrengths;
        } else return;
    };
}
