import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import type { StripeError } from '@stripe/stripe-js';
import * as React from 'react';
import Strings from '../../../strings';
import type { ShoppingConnection } from '../../ShoppingConnection';
import { StripeClientSecretContext, StripeElementsContainer } from './StripeElementsContainer';
import { isConnectionSecureForStripe, StripeElementsForm } from './StripeElementsForm';

export type TStripeCardAdderContainerProps = React.PropsWithChildren & {
    isSubmittable: boolean;
    isProcessing: boolean;
    onSubmit: () => void;
    errorMessage?: string;
};

type TStripeCardAdderProps = {
    loadingComponent?: JSX.Element;
    elementsContainerComponent: React.ComponentType<TStripeCardAdderContainerProps>;
    shoppingConnection: ShoppingConnection;
    onAdded: (id: string) => void;
};

const INIT_DATA: { apiMethodName: 'GetStripeCustomerSetupModel' } = { apiMethodName: 'GetStripeCustomerSetupModel' };

const StripeCardAdder: React.FC<TStripeCardAdderProps> = (props) => {
    const { shoppingConnection, onAdded, elementsContainerComponent } = props;

    return (
        <StripeElementsContainer
            initData={INIT_DATA}
            shoppingConnection={shoppingConnection}
            loadingComponent={props.loadingComponent}
        >
            <StripeCardForm
                onAdded={onAdded}
                shoppingConnection={shoppingConnection}
                elementsContainerComponent={elementsContainerComponent}
            />
        </StripeElementsContainer>
    );
};

type TCardFormProps = {
    shoppingConnection: ShoppingConnection;
    elementsContainerComponent: React.ComponentType<TStripeCardAdderContainerProps>;
    onAdded: (id: string) => void;
};

const StripeCardForm: React.FC<TCardFormProps> = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const clientSecret = React.useContext(StripeClientSecretContext);

    const [stripeError, setStripeError] = React.useState<StripeError | null>(null);
    const [isProcessing, setIsProcessing] = React.useState<boolean>(false);

    const handleSubmit = React.useCallback(() => {
        if (isProcessing)
            return;

        setIsProcessing(true);
    }, [isProcessing, setIsProcessing]);

    const { shoppingConnection, onAdded } = props;
    React.useEffect(() => {
        if (!isProcessing)
            return;

        (async () => {
            if (!stripe || !elements) {
                console.warn('Stripe.js has not loaded yet.');
                return;
            }

            const cardElement = elements.getElement(CardNumberElement);

            if (!cardElement) {
                console.error('Unable to find Stripe card element.');
                return;
            }

            const result = await stripe.confirmCardSetup(clientSecret, {
                payment_method: {
                    card: cardElement
                }
            });

            if (result.error) {
                console.error('Stripe payment method setup confirming failed.', result.error);
                setStripeError(result.error);
                setIsProcessing(false);
                return;
            }

            if (!result.setupIntent.payment_method) {
                console.error('Stripe payment method setup confirming failed. Missing PM ID.', result);
                setIsProcessing(false);
                return;
            }

            await shoppingConnection.askShoppingApi('SetStripeDefaultPaymentMethod', { paymentMethodId: result.setupIntent.payment_method });

            onAdded(result.setupIntent.payment_method as string);
        })()
            .catch((err) => console.error('Unable to set stripe default payment method.', err));
    }, [stripe, elements, setStripeError, isProcessing, clientSecret, shoppingConnection, onAdded]);

    const Container = props.elementsContainerComponent;
    return (
        <Container isProcessing={isProcessing}
            isSubmittable={!(!stripe || !elements || isProcessing || !isConnectionSecureForStripe())}
            onSubmit={handleSubmit}
            errorMessage={(!!stripeError ? (stripeError.message || Strings.components.routes.billingDetails.wrongMethod) : undefined)}
        >
            <StripeElementsForm />
        </Container>
    );
};

export default StripeCardAdder;