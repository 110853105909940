import { mergeStyles } from '@fluentui/react';
import React from 'react';

type TNotificationDotProps = React.HTMLProps<HTMLDivElement> & {
    value: string | number;
};

const NotificationDot: React.FC<TNotificationDotProps> = ({ className, value, ...restProps }) => {

    const getSizeClassName = () => {
        const valueLength = value.toString().length;

        if (valueLength >= 4) {
            return "notification-dot--size8";
        } else if (valueLength >= 3) {
            return "notification-dot--size10";
        }

        return null;
    };

    return (
        <div className={mergeStyles('notification-dot', getSizeClassName(), className)} {...restProps}>
            {value}
        </div>
    );
};

export default NotificationDot;
