import type { IDropdownOption} from '@fluentui/react';
import { Dropdown, Icon, mergeStyles, TextField, Toggle } from '@fluentui/react';
import GlobalSettingTypes from '../../../data/constants/GlobalSettingTypes';
import type { IApiEnumValue, IApiGlobalSetting } from '@eway-crm/connector';
import { GlobalSettingsNames } from '@eway-crm/connector';
import type { IApiGroup } from '@eway-crm/connector';
import StringHelper from '../../../helpers/StringHelper';
import Strings from '../../../strings';
import type { TEnumTypeEnumValues, TFeature } from '../Features';
import GroupDropdownPicker from './GroupDropdownPicker';
import type { TEnumTypeEnumValueChangeItems } from './NestedMultiselectDropdown';
import NestedMultiselectDropdown from './NestedMultiselectDropdown';
import NumberInputField from './NumberInputField';
import TimeRangePicker from './TimeRangePicker';
import NumberInputFieldWithSetButton from './NumberInputFieldWithSetButton';
import React, { useContext } from 'react';
import ExternalLinks from '../../../data/constants/ExternalLinks';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import LicenseRestrictionsLockIcon from '../../shared/locks/LicenseRestrictionsLockedIcon';
import { DomainsList } from '@eway-crm/gui';

const myStrings = Strings.components.routes.features;

type TKbLinkProps = {
    urlShortCode: string | null;
};

const KbLink: React.FC<TKbLinkProps> = ({ urlShortCode }) => {
    if (!urlShortCode) {
        return null;
    }
    return (
        <a className="features__kbLink" href={ExternalLinks.getKbLink(urlShortCode)} title={myStrings.externalLinkTooltip} rel="noopener noreferrer" target="_blank">
            <Icon iconName="NavigateExternalInline" />
        </a>
    );
};

const GS_DROPDOWN_STYLES = { dropdownItems: { borderRight: 'none !important' } };
const GS_DROPDOWN_CALLOUT_PROPS = { calloutMaxHeight: 380 };

const onRenderDropdownTitle = (dropdownOptions?: IDropdownOption[], defaultRender?: (props?: IDropdownOption[] | undefined) => JSX.Element | null) => {
    const sortedOptions = dropdownOptions?.sort((a, b) => StringHelper.localeCompare(a.text, b.text));
    return defaultRender ? defaultRender(sortedOptions) : null;
};

type TGlobalSettingFieldProps = {
    globalSetting: IApiGlobalSetting;
    allGroups: IApiGroup[];
    enumTypeEnumValues: TEnumTypeEnumValues;
    onValueChange: (newValue: string | null, itemGuid: string) => void;
    onEnumValuesChange: (items: TEnumTypeEnumValueChangeItems[]) => void;
    disabled?: boolean;
    gsCategoryName?: IApiEnumValue;
    selectedFeature: TFeature;
};

const GlobalSettingField = React.forwardRef<HTMLDivElement, TGlobalSettingFieldProps>(({ globalSetting, onValueChange, allGroups, enumTypeEnumValues, onEnumValuesChange, disabled, gsCategoryName, selectedFeature }, ref) => {
    const { licenseRestrictionsHelper, showLicenseRestrictionModal } = useContext(ConnectionContext);

    const { isGlobalSettingLocked, globalSettingLicenseRestriction } = licenseRestrictionsHelper.isGlobalSettingLocked(globalSetting.Name, gsCategoryName?.FileAs);

    const checkLicenseBeforeChange = () => {
        if (isGlobalSettingLocked) {
            showLicenseRestrictionModal(globalSettingLicenseRestriction);
            return false;
        }

        return true;
    };

    const handleValueChange = (newValue: string | number | null) => {
        const canContinue = checkLicenseBeforeChange();
        if (!canContinue) {
            return;
        }

        if (newValue !== globalSetting.Value) {
            onValueChange(typeof newValue === 'number' ? newValue.toString() : newValue, globalSetting.ItemGUID);
        }
    };

    const handleMultiDropdownChange = (option: IDropdownOption) => {
        const canContinue = checkLicenseBeforeChange();
        if (!canContinue) {
            return;
        }

        const defaultValues = StringHelper.split(globalSetting.Value?.toString(), ';');
        if (!option || !defaultValues) {
            return;
        }
        let newValues = [...defaultValues];
        if (option.selected) {
            newValues.push(option.key.toString());
        } else {
            newValues = newValues.filter((dv) => dv !== option.key);
        }
        handleValueChange(newValues.length !== 0 ? newValues.join(';') : null);
    };

    const handleEnumValuesChange = (items: TEnumTypeEnumValueChangeItems[]) => {
        const canContinue = checkLicenseBeforeChange();
        if (!canContinue) {
            return;
        }

        onEnumValuesChange(items);
    };

    let inner = <></>;

    if (globalSetting.Type === GlobalSettingTypes.toggle) {
        const isChecked = isGlobalSettingLocked ? false : globalSetting.Value === '1'; // Look as off when feature is locked
        inner = (
            <Toggle
                checked={isChecked}
                onText={Strings.on}
                offText={Strings.off}
                onChange={(e, checked) => {
                    handleValueChange(checked ? '1' : '0');
                }}
                disabled={disabled}
            />
        );
    } else if ([GlobalSettingTypes.numberBox, GlobalSettingTypes.floatNumberBox].includes(globalSetting.Type)) {
        if (globalSetting.UnlimitedValue || globalSetting.DisabledValue) {
            inner = (
                <NumberInputFieldWithSetButton
                    min={globalSetting.MinValue ?? undefined}
                    max={globalSetting.MaxValue ?? undefined}
                    value={globalSetting.Value?.toString() ?? undefined}
                    onValueChange={handleValueChange}
                    isFloat={globalSetting.Type === GlobalSettingTypes.floatNumberBox}
                    disabled={disabled}
                    buttonText={globalSetting.UnlimitedValue ? myStrings.setUnlimited : myStrings.setDisabled}
                    replaceValue={globalSetting.UnlimitedValue ? Number(globalSetting.UnlimitedValue) : Number(globalSetting.DisabledValue)}
                    replaceText={globalSetting.UnlimitedValue ? myStrings.unlimited : myStrings.disabled}
                />
            );
        } else {
            inner = (
                <NumberInputField
                    className="features__input features__numberInput"
                    min={globalSetting.MinValue ?? undefined}
                    max={globalSetting.MaxValue ?? undefined}
                    value={globalSetting.Value?.toString() ?? undefined}
                    onValueChange={handleValueChange}
                    isFloat={globalSetting.Type === GlobalSettingTypes.floatNumberBox}
                    disabled={disabled}
                />
            );
        }
    } else if (globalSetting.Type === GlobalSettingTypes.multiLineTextBox) {
        inner = (
            <TextField
                multiline
                rows={4}
                className="features__input"
                defaultValue={globalSetting.Value?.toString() ?? undefined}
                onBlur={(e) => {
                    handleValueChange(e.target.value);
                }}
                disabled={disabled}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.comboBox) {
        inner = (
            <Dropdown
                styles={GS_DROPDOWN_STYLES}
                title={Strings.pickTranslation(globalSetting.AvailableValues?.find((av) => av.Key === globalSetting.Value)?.Translations) ?? undefined}
                calloutProps={GS_DROPDOWN_CALLOUT_PROPS}
                className="features__input"
                options={globalSetting.AvailableValues?.map((av) => ({ key: av.Key, text: Strings.pickTranslation(av.Translations) ?? av.Value })) ?? []}
                selectedKey={globalSetting.Value}
                onChange={(e, option) => {
                    option && handleValueChange(option.key);
                }}
                disabled={disabled}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.multiChoiceComboBox) {
        const selectedKeys = StringHelper.split(globalSetting.Value?.toString(), ';');

        const onMultiDropdownChange = (option: IDropdownOption) => {
            if (!option || !selectedKeys) {
                return;
            }
            let newValues = [...selectedKeys];
            if (option.selected) {
                newValues.push(option.key.toString());
            } else {
                newValues = newValues.filter((dv) => dv !== option.key);
            }
            handleValueChange(newValues.length !== 0 ? newValues.join(';') : null);
        };

        const dropdownOptions = globalSetting.AvailableValues?.map((av) => ({ key: av.Key, text: Strings.pickTranslation(av.Translations) ?? av.Value })) ?? [];
        const dropdownTitle = dropdownOptions.filter(o => selectedKeys.includes(o.key))?.map(o => o.text).sort(StringHelper.localeCompare).join(", ");

        inner = (
            <Dropdown
                styles={GS_DROPDOWN_STYLES}
                calloutProps={GS_DROPDOWN_CALLOUT_PROPS}
                className="features__input"
                multiSelect
                selectedKeys={selectedKeys}
                options={dropdownOptions}
                onChange={(e, option) => {
                    option && onMultiDropdownChange(option);
                }}
                disabled={disabled}
                onRenderTitle={onRenderDropdownTitle}
                title={dropdownTitle}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.groupMultiChoiceComboBox) {
        inner = (
            <GroupDropdownPicker
                allGroups={allGroups}
                className="features__input"
                styles={GS_DROPDOWN_STYLES}
                calloutProps={GS_DROPDOWN_CALLOUT_PROPS}
                onChange={(newValues) => {
                    handleValueChange(newValues.join(';'));
                }}
                selectedKeys={StringHelper.split(globalSetting.Value as string, ';')}
                disabled={disabled}
                onRenderTitle={onRenderDropdownTitle}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.nextStepMultiChoiceComboBox) {
        const isNextStepsOrLastActivity = [GlobalSettingsNames.nextStepAttributes, GlobalSettingsNames.lastActivityAttributes].includes(globalSetting.Name);
        const lockedSelectedKeys = globalSetting.AvailableValues?.map(av => av.Key) ?? [];
        // Show all next steps or last activity as checked if they are locked by license
        const selectedKeys = isNextStepsOrLastActivity && isGlobalSettingLocked ? lockedSelectedKeys : StringHelper.split(globalSetting.Value as string, ';');
        inner = (
            <NestedMultiselectDropdown
                styles={GS_DROPDOWN_STYLES}
                calloutProps={GS_DROPDOWN_CALLOUT_PROPS}
                className="features__input"
                enumTypeEnumValues={enumTypeEnumValues}
                availableValues={globalSetting.AvailableValues}
                selectedKeys={selectedKeys}
                onChange={handleMultiDropdownChange}
                onEnumValuesChange={handleEnumValuesChange}
                disabled={disabled}
                onRenderTitle={onRenderDropdownTitle}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.timeInterval) {
        inner = (
            <TimeRangePicker
                defaultValue={globalSetting.Value?.toString()}
                betweenText={myStrings.timeRangePickerBetween}
                onChange={(newValue) => {
                    handleValueChange(newValue);
                }}
                disabled={disabled}
            />
        );
    } else if (globalSetting.Type === GlobalSettingTypes.domains) {
        const domains = globalSetting.Value ? JSON.parse(globalSetting.Value as string) as string[] : [];
        inner = (
            <div className="features__domainsListWrapper">
                <DomainsList
                    initialDomains={domains} 
                    changedDomains={domains} 
                    onDomainsChanged={(data) => handleValueChange(JSON.stringify(data))}
                    domainInputPlaceholder={globalSetting.Name === 'IgnoreEmailsFromDomainsOnEmailsTracking' ? 'hubspot.com' : undefined}/>
            </div>
        );
    } else {
        inner = (
            <TextField
                className="features__input"
                defaultValue={globalSetting.Value?.toString()}
                onBlur={(e) => {
                    handleValueChange(e.target.value);
                }}
                disabled={disabled}
            />
        );
    }

    return (
        <div className="features__featureWrap" ref={ref}>
                <div className={mergeStyles('features__featureTitle', (disabled || isGlobalSettingLocked) && 'text-black-50')}>
                    {isGlobalSettingLocked && <LicenseRestrictionsLockIcon licenseRestriction={globalSettingLicenseRestriction} className={"mr-2 text-dark"} isSmall={false}/>}
                    {Strings.pickNameTranslation(globalSetting)} <KbLink urlShortCode={globalSetting.KbUrlShortCode} />{' '}
                </div>
                {inner}
            </div>
    );
});

export default GlobalSettingField;
