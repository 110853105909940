import type { TFolderName } from '@eway-crm/connector';
import { CustomizationStatsItemKeys, Edition, Feature, FieldNames, GlobalSettingsNames, LicenseRestrictionKeys } from '@eway-crm/connector';
import { LicenseRestrictionsHelperBase } from '@eway-crm/gui';
import FeatureTypes from '../data/constants/FeatureTypes';
import FolderNames from '../data/constants/FolderNames';

export type TLicenseRestriction = {
    availableInFeature: Feature;
    availableInEdition: Edition;
    currentLimit?: number | null;
};

export type TLicenseRestrictionsObject = {
    functionalities: { [key: string]: TLicenseRestriction };
    modules: { [Key in TFolderName]?: TLicenseRestriction };
    globalSettings: { [key: string]: TLicenseRestriction };
};

type TIsLockedReturnType<TIsLockedName extends string, TLicenseRestrictionName extends string> =
    | ({ [key in TIsLockedName]: true } & { [key in TLicenseRestrictionName]: TLicenseRestriction })
    | ({ [key in TIsLockedName]: false } & { [key in TLicenseRestrictionName]: null });

export default class LicenseRestrictionsHelper extends LicenseRestrictionsHelperBase {
    public readonly getCustomizationStatsItem = (itemKey: string, folderName?: TFolderName) => {
        return this.customizationStats.find((cs) => cs.Key === itemKey && (folderName ? cs.FolderName === folderName : true));
    };

    public readonly isUserRolesLocked = (): TIsLockedReturnType<'isUserRolesLocked', 'userRolesLicenseRestriction'> => {
        const userRolesLicenseRestriction = this.licenseRestrictions.functionalities.UserRoles;
        if (userRolesLicenseRestriction && userRolesLicenseRestriction.currentLimit === null) {
            return { userRolesLicenseRestriction, isUserRolesLocked: true };
        }
        return { userRolesLicenseRestriction: null, isUserRolesLocked: false };
    };

    public readonly isUserRolesReadOnly = (): TIsLockedReturnType<'isUserRolesReadOnly', 'userRolesLicenseRestriction'> => {
        const userRolesLicenseRestriction = this.licenseRestrictions.functionalities.UserRoles;
        // Current Limit 0 means it is read only in this case
        if (userRolesLicenseRestriction && userRolesLicenseRestriction.currentLimit === 0) {
            return { userRolesLicenseRestriction, isUserRolesReadOnly: true };
        }
        return { userRolesLicenseRestriction: null, isUserRolesReadOnly: false };
    };

    public readonly isModulePermissionsLocked = (): TIsLockedReturnType<'isModulePermissionsLocked', 'modulePermissionsLicenseRestriction'> => {
        const modulePermissionsLicenseRestriction = this.licenseRestrictions.functionalities.ModulePermissions;
        if (modulePermissionsLicenseRestriction && modulePermissionsLicenseRestriction.currentLimit === null) {
            return { modulePermissionsLicenseRestriction, isModulePermissionsLocked: true };
        }
        return { modulePermissionsLicenseRestriction: null, isModulePermissionsLocked: false };
    };

    public readonly isModulePermissionsReadOnly = (): TIsLockedReturnType<'isModulePermissionsReadOnly', 'modulePermissionsLicenseRestriction'> => {
        const modulePermissionsLicenseRestriction = this.licenseRestrictions.functionalities.ModulePermissions;
        // Current Limit 0 means it is read only in this case
        if (modulePermissionsLicenseRestriction && modulePermissionsLicenseRestriction.currentLimit === 0) {
            return { modulePermissionsLicenseRestriction, isModulePermissionsReadOnly: true };
        }
        return { modulePermissionsLicenseRestriction: null, isModulePermissionsReadOnly: false };
    };

    public readonly isFieldPermissionsLocked = (): TIsLockedReturnType<'isFieldPermissionsLocked', 'fieldPermissionsLicenseRestriction'> => {
        const fieldPermissionsLicenseRestriction = this.licenseRestrictions.functionalities.ColumnPermissions;
        if (fieldPermissionsLicenseRestriction && fieldPermissionsLicenseRestriction.currentLimit === null) {
            return { fieldPermissionsLicenseRestriction, isFieldPermissionsLocked: true };
        }
        return { fieldPermissionsLicenseRestriction: null, isFieldPermissionsLocked: false };
    };

    public readonly isFormLayoutsCustomizationLimited = (): TIsLockedReturnType<'isFormLayoutsCustomizationLimited', 'formLayoutsCustomizationLicenseRestriction'> => {
        const restriction = this.licenseRestrictions.functionalities.FormLayoutCustomization;
        if (restriction)
            return { formLayoutsCustomizationLicenseRestriction: restriction, isFormLayoutsCustomizationLimited: true };

        return { formLayoutsCustomizationLicenseRestriction: null, isFormLayoutsCustomizationLimited: false };
    };

    public readonly isMandatoryFieldsLocked = (): TIsLockedReturnType<'isMandatoryFieldsLocked', 'mandatoryFieldsLicenseRestriction'> => {
        const mandatoryFieldsLicenseRestriction = this.licenseRestrictions.functionalities.MandatoryFields;
        if (mandatoryFieldsLicenseRestriction && mandatoryFieldsLicenseRestriction.currentLimit === null) {
            return { mandatoryFieldsLicenseRestriction, isMandatoryFieldsLocked: true };
        }
        return { mandatoryFieldsLicenseRestriction: null, isMandatoryFieldsLocked: false };
    };

    public readonly isImportantFieldsLocked = (): TIsLockedReturnType<'isImportantFieldsLocked', 'importantFieldsLicenseRestriction'> => {
        const importantFieldsLicenseRestriction = this.licenseRestrictions.functionalities.ImportantFields;
        if (importantFieldsLicenseRestriction && importantFieldsLicenseRestriction.currentLimit === null) {
            return { importantFieldsLicenseRestriction, isImportantFieldsLocked: true };
        }
        return { importantFieldsLicenseRestriction: null, isImportantFieldsLocked: false };
    };

    public readonly isUniqueFieldsLocked = (): TIsLockedReturnType<'isUniqueFieldsLocked', 'uniqueFieldsLicenseRestriction'> => {
        const uniqueFieldsLicenseRestriction = this.licenseRestrictions.functionalities.UniqueFields;
        if (uniqueFieldsLicenseRestriction && uniqueFieldsLicenseRestriction.currentLimit === null) {
            return { uniqueFieldsLicenseRestriction, isUniqueFieldsLocked: true };
        }
        return { uniqueFieldsLicenseRestriction: null, isUniqueFieldsLocked: false };
    };

    public readonly isReadOnlyFieldsLocked = (): TIsLockedReturnType<'isReadOnlyFieldsLocked', 'readonlyFieldsLicenseRestriction'> => {
        const readonlyFieldsLicenseRestriction = this.licenseRestrictions.functionalities.ReadOnlyFields;
        if (readonlyFieldsLicenseRestriction && readonlyFieldsLicenseRestriction.currentLimit === null) {
            return { readonlyFieldsLicenseRestriction, isReadOnlyFieldsLocked: true };
        }
        return { readonlyFieldsLicenseRestriction: null, isReadOnlyFieldsLocked: false };
    };

    public readonly isCustomFieldsCountExceeded = () => {
        const visibleFoldersCustomFieldsCounts = this.customizationStats.filter(stat => stat.Key === CustomizationStatsItemKeys.customAdditionalFieldsCount && !this.isFolderNameLocked(stat.FolderName).isFolderNameLocked);
        let allCustomFieldsCount = 0;
        visibleFoldersCustomFieldsCounts.forEach(stat => { allCustomFieldsCount += stat.Value; });

        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.customFields, CustomizationStatsItemKeys.customAdditionalFieldsCount, undefined, allCustomFieldsCount);
    };

    public readonly isMandatoryFieldsCountExceeded = (folderName: TFolderName) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.mandatoryFields, CustomizationStatsItemKeys.customMandatoryFieldsCount, folderName);
    };

    public readonly isImportantFieldsCountExceeded = (folderName: TFolderName) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.importantFields, CustomizationStatsItemKeys.customOptionalFieldsCount, folderName);
    };

    public readonly isItemTypesCountExceeded = (folderName: TFolderName, currentCount?: number) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.itemTypes, CustomizationStatsItemKeys.customVisibleTypesCount, folderName, currentCount);
    };

    public readonly isAdvancedWorkflowCountExceeded = (folderName: TFolderName) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.workflowAdvancedDefinitions, CustomizationStatsItemKeys.customEnabledAdvancedWorkflowsCount, folderName);
    };

    public readonly isBasicWorkflowCountExceeded = (folderName: TFolderName) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.workflowBasicDefinitions, CustomizationStatsItemKeys.customEnabledBasicWorkflowsCount, folderName);
    };

    public readonly isCurrencyCountExceeded = (folderName: TFolderName) => {
        return this.isFunctionalityCountExceeded(LicenseRestrictionKeys.multipleCurrencies, CustomizationStatsItemKeys.visibleCurrenciesCount, folderName);
    };

    /**
     * Check if the folder should be hidden instead of locked.
     */
    public readonly isFolderNameHidden = (folderName: TFolderName) => {
        const { folderNameLicenseRestriction, isFolderNameLocked } = this.isFolderNameLocked(folderName);
        if (isFolderNameLocked && !this.isFeatureInLicense(folderNameLicenseRestriction.availableInFeature)) {
            // Folders from Features that are not purchased at all should be hidden
            return true;
        }
        return false;
    };

    public readonly isFolderNameLocked = (folderName: TFolderName | null): TIsLockedReturnType<'isFolderNameLocked', 'folderNameLicenseRestriction'> => {
        if (folderName) {
            const folderNameLicenseRestriction = this.licenseRestrictions.modules[folderName];
            if (folderNameLicenseRestriction) {
                return { folderNameLicenseRestriction, isFolderNameLocked: true };
            }
        }
        return { folderNameLicenseRestriction: null, isFolderNameLocked: false };
    };

    /**
     * @param featureName Feature name used in Admin
     * @returns
     * featureLockedLicenseRestriction = Fake restriction with same format as other restrictions,
     * isFeatureLocked = the feature is not bought or is Free edition
     * dontShowToggle = whether to show toggle in Feature Menu
     */
    public readonly isFeatureLocked = (featureName: string): TIsLockedReturnType<'isFeatureLocked', 'featureLockedLicenseRestriction'> & { dontShowToggle: boolean } => {
        const getFeatureInLicense = (feature: Feature) => this.license.Features?.find((f) => f.Feature === feature);

        switch (featureName) {
            case FeatureTypes.Sales:
                const salesFeature = getFeatureInLicense(Feature.Sales);
                const isSalesFeatureFree = salesFeature?.Edition === Edition.Free;

                if (!salesFeature || isSalesFeatureFree) {
                    return {
                        isFeatureLocked: true,
                        dontShowToggle: isSalesFeatureFree,
                        featureLockedLicenseRestriction: {
                            availableInFeature: Feature.Sales,
                            availableInEdition: Edition.Basic,
                        },
                    };
                }
                break;
            case FeatureTypes.MarketingCampaigns:
                const marketingFeature = getFeatureInLicense(Feature.Marketing);
                const isMarketingFeatureFree = marketingFeature?.Edition === Edition.Free;

                if (!marketingFeature || isMarketingFeatureFree) {
                    return {
                        isFeatureLocked: true,
                        dontShowToggle: isMarketingFeatureFree,
                        featureLockedLicenseRestriction: {
                            availableInFeature: Feature.Marketing,
                            availableInEdition: Edition.Basic,
                        },
                    };
                }
                break;
            case FeatureTypes.Projects:
                const projectsFeature = getFeatureInLicense(Feature.Projects);
                const isProjectsFeatureFree = projectsFeature?.Edition === Edition.Free;
                if (!projectsFeature || isProjectsFeatureFree) {
                    return {
                        isFeatureLocked: true,
                        dontShowToggle: isProjectsFeatureFree,
                        featureLockedLicenseRestriction: {
                            availableInFeature: Feature.Projects,
                            availableInEdition: Edition.Basic,
                        },
                    };
                }
                break;
            default:
                break;
        }

        return {
            isFeatureLocked: false,
            dontShowToggle: false,
            featureLockedLicenseRestriction: null,
        };
    };

    public readonly isGlobalSettingLocked = (globalSettingName: string, gsCategoryName?: string | null): TIsLockedReturnType<'isGlobalSettingLocked', 'globalSettingLicenseRestriction'> => {
        const getRestriction = (restriction?: TLicenseRestriction | null): TIsLockedReturnType<'isGlobalSettingLocked', 'globalSettingLicenseRestriction'> => {
            if (restriction) {
                return { globalSettingLicenseRestriction: restriction, isGlobalSettingLocked: true };
            }
            return { globalSettingLicenseRestriction: null, isGlobalSettingLocked: false };
        };

        if (this.licenseRestrictions.globalSettings[globalSettingName]) {
            return getRestriction(this.licenseRestrictions.globalSettings[globalSettingName]);
        } else if ([GlobalSettingsNames.nextStepAttributes, GlobalSettingsNames.lastActivityAttributes].includes(globalSettingName)) {
            return getRestriction(this.licenseRestrictions.functionalities.NextStepAndLastActivityCustomization);
        } else if ([GlobalSettingsNames.contactDuplicityCheckEnabled, GlobalSettingsNames.companyDuplicityCheckEnabled].includes(globalSettingName)) {
            return getRestriction(this.licenseRestrictions.functionalities.DuplicityChecker);
        } else if (
            [
                GlobalSettingsNames.enableCompaniesSyncIntoMobileDeviceContacts,
                GlobalSettingsNames.enableContactsSyncIntoMobileDevice,
                GlobalSettingsNames.enableLeadsSyncIntoMobileDeviceContacts,
                GlobalSettingsNames.enableUsersSyncIntoMobileDeviceContacts,
            ].includes(globalSettingName)
        ) {
            return getRestriction(this.licenseRestrictions.functionalities.ContactsSynchronizationWithAndroid);
        } else if (GlobalSettingsNames.automaticallyCreateJournalAfterCallDuration === globalSettingName) {
            return getRestriction(this.licenseRestrictions.functionalities.TapiIntegration);
        } else if (gsCategoryName === 'WorkReports') {
            return getRestriction(this.licenseRestrictions.modules.WorkReports);
        } else if (gsCategoryName === 'Carts') {
            return getRestriction(this.licenseRestrictions.modules.Carts);
        } else if (gsCategoryName === 'GoodsInCart') {
            return getRestriction(this.licenseRestrictions.modules.GoodsInCart);
        }

        return getRestriction(null);
    };

    public readonly isNextStepsAndLastActivityLocked = (): TIsLockedReturnType<'isNextStepsAndLastActivityLocked', 'nextStepsAndLastActivityLicenseRestriction'> => {
        const nextStepsAndLastActivityLicenseRestriction = this.licenseRestrictions.functionalities.NextStepAndLastActivityCustomization;
        if (nextStepsAndLastActivityLicenseRestriction && nextStepsAndLastActivityLicenseRestriction.currentLimit === null) {
            return { nextStepsAndLastActivityLicenseRestriction, isNextStepsAndLastActivityLocked: true };
        }
        return { nextStepsAndLastActivityLicenseRestriction: null, isNextStepsAndLastActivityLocked: false };
    };

    /**
     * Gets license restriction based on columnName
     */
    public readonly getFieldLicenseRestriction = (columnName?: string | undefined, enumName?: string) => {
        if (!columnName) {
            return null;
        }

        if (columnName === FieldNames.Common.CurrencyEn || enumName === "Currency") {
            return this.licenseRestrictions.functionalities.MultipleCurrencies ?? null;
        } else if (FieldNames.allTypeEnNames.includes(columnName)) {
            return this.licenseRestrictions.functionalities.ItemTypes ?? null;
        }

        return null;
    };

    public readonly isFieldLocked = (folderName: TFolderName, colName: string): TIsLockedReturnType<'isFieldLocked', 'fieldLockedLicenseRestriction'> => {
        if (folderName === FolderNames.projects) {
            if (colName === FieldNames.Projects.SuperiorProject) {
                const fieldLockedLicenseRestriction = this.licenseRestrictions.functionalities.SubProjects;
                if (fieldLockedLicenseRestriction) {
                    return { fieldLockedLicenseRestriction, isFieldLocked: true };
                }
            }
        } else if (folderName === FolderNames.tasks) {
            if (colName === FieldNames.Tasks.Reminder) {
                const fieldLockedLicenseRestriction = this.licenseRestrictions.functionalities.TasksReminders;
                if (fieldLockedLicenseRestriction) {
                    return { fieldLockedLicenseRestriction, isFieldLocked: true };
                }
            }
        }

        return { fieldLockedLicenseRestriction: null, isFieldLocked: false };
    };

    public readonly isLegacyAdministrationLinkHidden = (): TIsLockedReturnType<'isLegacyAdministrationLinkHidden', 'legacyAdministrationLicenseRestriction'> => {
        const legacyAdministrationLicenseRestriction = this.licenseRestrictions.functionalities.LegacyAdministration;

        if (legacyAdministrationLicenseRestriction && legacyAdministrationLicenseRestriction.currentLimit === null) {
            return { legacyAdministrationLicenseRestriction, isLegacyAdministrationLinkHidden: true };
        }
        return { legacyAdministrationLicenseRestriction: null, isLegacyAdministrationLinkHidden: false };
    };
}
