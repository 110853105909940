import * as React from 'react';
import type { Table} from '@devexpress/dx-react-grid-bootstrap4';
import { Grid, TableColumnResizing, TableHeaderRow, VirtualTable } from '@devexpress/dx-react-grid-bootstrap4';
import type { Sorting, TableColumnWidthInfo } from '@devexpress/dx-react-grid';
import { IntegratedFiltering, IntegratedSorting, SearchState, SortingState } from '@devexpress/dx-react-grid';
import Strings from '../../../../strings';
import GridTable from '../../../GridTable';
import { Dropdown } from '@fluentui/react';
import LicenseRestrictionsLockIcon from '../../../shared/locks/LicenseRestrictionsLockedIcon';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';
import type { IApiLayout, TFolderName } from '@eway-crm/connector';
import LicenseRestrictionsLockedGridRow from '../../../shared/locks/LicenseRestrictionsLockedGridRow';
import StringHelper from '../../../../helpers/StringHelper';
import { FolderNamesHelper } from '@eway-crm/gui';
import FolderNames from '../../../../data/constants/FolderNames';

const GRID_ROOT_ID = 'GroupDefaultFormLayoutsGridRoot';
const GRID_CONTAINER_ID = 'GroupDefaultFormLayoutsGridContainer';

const DefaultFormLayoutsGridNames = {
    columns: {
        folder: 'folder',
        layout: 'layoutGuid'
    }
};

const columns = [
    { name: DefaultFormLayoutsGridNames.columns.folder, title: Strings.components.routes.groups.defaultFormLayouts.folderName },
    { name: DefaultFormLayoutsGridNames.columns.layout, title: Strings.components.routes.groups.defaultFormLayouts.layout }
];
const sortingStateColumnExtensions: SortingState.ColumnExtension[] = [
    { columnName: DefaultFormLayoutsGridNames.columns.layout, sortingEnabled: false }
];
const integratedSortingColumnExtensions: IntegratedSorting.ColumnExtension[] = [{ columnName: DefaultFormLayoutsGridNames.columns.folder, compare: StringHelper.localeCompare }];
const rowHeight = 46;

const GridRootComponent = GridTable.createRootWithProps({ id: GRID_ROOT_ID });
const TableContainerComponent = GridTable.createTableContainerComponent({ id: GRID_CONTAINER_ID });

const getGridRowId = (row: TMappingWithFolder) => row.folderName;

export type TFormLayoutGroupMapping = {
    folderName: TFolderName;
    layoutGuid: string | null;
};

type TMappingWithFolder = TFormLayoutGroupMapping & {
    folder: string;
};

type TDefaultFormLayoutsGridProps = {
    searchedString: string;
    gridHeight: string | number;
    availableLayouts: IApiLayout[];
    mappings: TFormLayoutGroupMapping[];
    onMappingChange: (folderName: TFolderName, layoutGuid: string | null) => void;
};

const CellContext = React.createContext<Pick<TDefaultFormLayoutsGridProps, 'availableLayouts' | 'onMappingChange'>>(null!);

const noLayoutOptionKey = '__none';

const DefaultFormLayoutsGrid: React.FC<TDefaultFormLayoutsGridProps> = ({ searchedString, gridHeight, mappings, availableLayouts, onMappingChange }) => {
    const [sort, setSort] = React.useState<Sorting[]>([{ columnName: DefaultFormLayoutsGridNames.columns.folder, direction: 'asc' }]);
    const [columnWidths, setColumnWidths] = React.useState<TableColumnWidthInfo[]>([]);

    const mappingsWithFolders = React.useMemo(() => mappings.map(m => ({ ...m, folder: FolderNamesHelper.getPluralName(m.folderName) })), [mappings]);

    return (
        <div className="h-100">
            <CellContext.Provider value={{ availableLayouts, onMappingChange }}>
                <Grid rows={mappingsWithFolders} columns={columns} rootComponent={GridRootComponent} getRowId={getGridRowId}>
                    <SearchState value={searchedString} />
                    <SortingState
                        sorting={sort}
                        onSortingChange={(newSort) => setSort(newSort)}
                        columnExtensions={sortingStateColumnExtensions}
                    />
                    <IntegratedFiltering />
                    <IntegratedSorting columnExtensions={integratedSortingColumnExtensions} />
                    <VirtualTable
                        containerComponent={TableContainerComponent}
                        messages={{ noData: searchedString ? Strings.components.routes.groups.defaultFormLayouts.noMatch : Strings.components.routes.groups.defaultFormLayouts.noData }}
                        noDataCellComponent={GridTable.NoDataCell}
                        estimatedRowHeight={rowHeight}
                        height={gridHeight}
                        cellComponent={ViewTableCell}
                        rowComponent={LicenseRestrictionsLockedGridRow}
                    />
                    <TableColumnResizing columnWidths={columnWidths} onColumnWidthsChange={(newColumnWidths) => setColumnWidths(newColumnWidths)} />
                    <TableHeaderRow showSortingControls sortLabelComponent={GridTable.SortLabel} />
                </Grid>
            </CellContext.Provider>
        </div>
    );
};

const ViewTableCell: React.FC<Table.DataCellProps> = (props) => {
    const { licenseRestrictionsHelper } = React.useContext(ConnectionContext);
    const { availableLayouts, onMappingChange } = React.useContext(CellContext);

    const typedValue = props.value as React.ReactText;
    const typedRow = props.row as TMappingWithFolder;

    const { isFolderNameLocked, folderNameLicenseRestriction } = licenseRestrictionsHelper.isFolderNameLocked(typedRow.folderName);

    if (props.column.name === DefaultFormLayoutsGridNames.columns.layout) {
        const options = [
            { key: noLayoutOptionKey, text: Strings.components.routes.groups.defaultFormLayouts.noLayout },
            ...availableLayouts
                .filter(l => l.FolderName === typedRow.folderName)
                .map(l => ({ key: l.ItemGUID, text: l.FileAs ?? '' }))
                .sort((a, b) => StringHelper.localeCompare(a.text, b.text))
        ];
        return (
            <VirtualTable.Cell className="align-middle" {...props}>
                <Dropdown
                    options={options}
                    selectedKey={typedRow.layoutGuid ?? noLayoutOptionKey}
                    onChange={(_, o) => onMappingChange(typedRow.folderName, !o || o.key === noLayoutOptionKey ? null : o.key as string)}
                />
            </VirtualTable.Cell>
        );
    }

    return (
        <VirtualTable.Cell className="align-middle" {...props} title={typedValue}>
            <img src={FolderNames.getIcon(typedRow.folderName)} alt={typedRow.folder} className="secondary-menu__icon mr-2" />
            {typedValue}
            {isFolderNameLocked && <LicenseRestrictionsLockIcon licenseRestriction={folderNameLicenseRestriction} isSmall={false} className="ml-1 text-dark" />}
        </VirtualTable.Cell>
    );
};

export default DefaultFormLayoutsGrid;