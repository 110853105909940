import * as React from 'react';
import type { IApiUser } from '@eway-crm/connector';
import type { IApiDataResponse } from '@eway-crm/connector';
import StringHelper from '../../../helpers/StringHelper';
import { css } from './Styles';
import { UsersGrid } from '../../shared/users/UsersGrid';
import { MoreLessButton } from './MoreLessButton';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { Spinner, SpinnerVariant } from '@eway-crm/gui';

type TLoginActivityProps = Record<string, unknown>;

type TLoginActivityState = {
    data: IApiUser[] | null;
    showMore: boolean;
};

export class LoginActivity extends React.Component<TLoginActivityProps, TLoginActivityState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    constructor(props: TLoginActivityProps) {
        super(props);
        this.state = {
            data: null,
            showMore: false
        };
    }

    componentDidMount() {
        this.load();
    }

    private readonly load = () => {
        this.context.connection.callApi(
            'GetUsers',
            {},
            (result: IApiDataResponse<IApiUser>) => {
                this.setState({
                    data: result.Data.sort((a, b) => (-1) * StringHelper.compare(a.Server_LastActivity || '0001', b.Server_LastActivity || '0001'))
                });
            }
        );
    };

    render() {
        if (!this.state.data) {
            return (
                <div className={css.spinnerContainer}>
                    <Spinner variant={SpinnerVariant.ease} backgroundOpacity={0} />
                </div>
            );
        }

        const shownData = this.state.showMore ? this.state.data : this.state.data.slice(0, 5);
        return (
            <div>
                <div className={(this.state.showMore ? css.gridContainerExpanded : css.gridContainerCollapsed)} style={(this.state.showMore ? { height: (this.state.data.length * 57 + 43) + 'px' } : undefined)}>
                    <UsersGrid
                        variant="card"
                        data={shownData}
                        searchedString={''}
                        selection={[]}
                        loadingEdittedUserItemGuid={null}
                        visibleGroups={[]}
                    />
                </div>
                {(this.state.data.length > 5) &&
                    <div className={css.moreContainer}>
                        {(this.state.showMore) ?
                            <MoreLessButton variant="less" onClick={() => this.setState({ showMore: false })} />
                            :
                            <MoreLessButton variant="more" onClick={() => this.setState({ showMore: true })} />
                        }
                    </div>
                }
            </div>
        );
    }
}