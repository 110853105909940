import * as React from 'react';
import ReactHelper from '../helpers/ReactHelper';
import { Modal, Button } from 'react-bootstrap';
import Strings from '../strings';
import { Dialog, DialogFooter, mergeStyles } from '@fluentui/react';

class WizardModalTitle extends React.Component<React.PropsWithChildren> {
    render() {
        return <>{this.props.children}</>;
    }
}

class WizardModalSteps extends React.Component<React.PropsWithChildren> {
    render() {
        return <>{this.props.children}</>;
    }
}

class WizardModalBody extends React.Component<React.PropsWithChildren> {
    render() {
        return <>{this.props.children}</>;
    }
}

class WizardModalFooter extends React.Component<React.PropsWithChildren> {
    render() {
        return <>{this.props.children}</>;
    }
}

type THideConfirmDialog = { title: string; message: string };

type TWizardModalProps = React.PropsWithChildren & {
    show?: boolean | undefined;
    size?: 'sm' | 'lg' | 'xl' | undefined;
    onHide?: () => void | undefined;
    hideConfirmDialog?: THideConfirmDialog;
    showCancelButtonInFooter?: boolean;
    className?: string;
    dialogBodyExpectedHeight?: string | undefined;
    dialogBodyClassName?: string;
    dialogClassName?: string;
    centered?: boolean;
};

type TWizardModalState = {
    isDismissing: boolean;
};

const unsavedChangesDialogStrings: THideConfirmDialog = { title: Strings.unsavedChanges, message: Strings.youHaveUnsavedChanges };

class WizardModal extends React.Component<TWizardModalProps, TWizardModalState> {

    static readonly Title = WizardModalTitle;
    static readonly Steps = WizardModalSteps;
    static readonly Body = WizardModalBody;
    static readonly Footer = WizardModalFooter;

    constructor(props: TWizardModalProps) {
        super(props);
        this.state = {
            isDismissing: false
        };
    }

    static getUnsavedChangedDialog(anyChangeMade: boolean): THideConfirmDialog | undefined {
        return anyChangeMade ? unsavedChangesDialogStrings : undefined;
    }

    private readonly isDismissAllowed = (): boolean => {
        return !(!this.props.onHide);
    };

    private readonly dismiss = () => {
        if (!this.isDismissAllowed())
            return;

        if (this.props.hideConfirmDialog) {
            this.setState({ isDismissing: true });
        } else {
            this.reallyDismiss();
        }
    };

    private readonly reallyDismiss = () => {
        if (!this.isDismissAllowed() || !this.props.onHide)
            return;

        this.setState(
            { isDismissing: false },
            () => this.props.onHide && this.props.onHide()
        );
    };

    render() {
        const title = ReactHelper.Children.takeByType(this.props.children, WizardModalTitle);
        const steps = ReactHelper.Children.takeByType(this.props.children, WizardModalSteps);
        const body = ReactHelper.Children.takeByType(this.props.children, WizardModalBody);
        const footer = ReactHelper.Children.takeByType(this.props.children, WizardModalFooter);

        const isDismissAllowed = this.isDismissAllowed();

        return (
            <>
                <Modal show={this.props.show} size={this.props.size} centered={this.props.centered ?? true} onHide={this.dismiss} enforceFocus={false} className={this.props.className} dialogClassName={this.props.dialogClassName} backdrop="static">
                    {React.Children.count(title) !== 0 ?
                        <Modal.Header closeButton={isDismissAllowed}>
                            <Modal.Title>{title}</Modal.Title>
                        </Modal.Header>
                        :
                        <Modal.Header closeButton={isDismissAllowed} className="border-bottom-0" />
                    }
                    <Modal.Body className="p-0">
                        {React.Children.count(steps) !== 0 ?
                            <div className="container-fluid d-flex flex-column" style={{ minHeight: this.props.dialogBodyExpectedHeight }}>
                                <div className="row flex-fill d-flex justify-content-start flex-sm-nowrap">
                                    <div className="col-auto px-xl-6 py-xl-5 p-lg-4 p-md-3 p-2 border-right">
                                        {steps}
                                    </div>
                                    <div className={mergeStyles("col min-w-0", this.props.dialogBodyClassName ?? "p-xl-5 p-lg-4 p-md-3 p-2")}>
                                        {body}
                                    </div>
                                </div>
                            </div>
                            :
                            <div
                                className={mergeStyles("min-w-0", this.props.dialogBodyClassName ?? "px-xl-5 px-lg-4 pb-lg-4 pt-lg-2 p-md-3 pt-md-2 p-2")}
                                style={this.props.dialogBodyExpectedHeight ? { minHeight: this.props.dialogBodyExpectedHeight } : undefined}
                            >
                                {body}
                            </div>
                        }
                    </Modal.Body>
                    {(React.Children.count(footer) !== 0 || this.props.showCancelButtonInFooter) ?
                        <Modal.Footer className="px-xl-5 px-lg-4 py-lg-4 p-md-3 p-2 flex-nowrap">
                            {footer}
                            {this.props.showCancelButtonInFooter &&
                                <Button variant="outline-secondary" onClick={this.dismiss} className="mr-0">{Strings.cancel}</Button>
                            }
                        </Modal.Footer>
                        :
                        <Modal.Footer className="py-lg-3 p-md-2 p-1 border-0" />
                    }
                </Modal>
                {(this.state.isDismissing && !!this.props.hideConfirmDialog) &&
                    <Dialog modalProps={{ isBlocking: true }} dialogContentProps={{ showCloseButton: false, title: this.props.hideConfirmDialog.title, subText: this.props.hideConfirmDialog.message }} hidden={false}>
                        <DialogFooter>
                            <Button variant="warning" onClick={this.reallyDismiss}>{Strings.yes}</Button>
                            <Button variant="outline-secondary" onClick={() => this.setState({ isDismissing: false })}>{Strings.no}</Button>
                        </DialogFooter>
                    </Dialog>
                }
            </>
        );
    }
}

export default WizardModal;