import type { ISpinButtonProps} from '@fluentui/react';
import { SpinButton } from '@fluentui/react';

const DEFAULT_SPIN_BUTTON_VALUE = 0;
const DEFAULT_SPIN_BUTTON_MIN_VALUE = -999999999;
const DEFAULT_SPIN_BUTTON_MAX_VALUE = 999999999;

type TNumberInputField = ISpinButtonProps & {
    min?: number;
    max?: number;
    value?: string;
    onValueChange: (newValue: number) => void;
    isFloat?: boolean;
    replaceValueMap?: { [key: number]: string };
};

const getDisplayValue = (value: number, replaceValueMap?: { [key: number]: string }) => {
    let finalValue = value.toString();

    if (replaceValueMap) {
        const replaceValue = replaceValueMap[value];
        if (replaceValue) {
            finalValue = replaceValue;
        }
    }
    
    return finalValue;
};

const NumberInputField: React.FC<TNumberInputField> = ({ min, max, value, onValueChange, isFloat, replaceValueMap, ...restProps }) => {
    const spinButtonValue = value ? Number(value) : DEFAULT_SPIN_BUTTON_VALUE;
    const maxSpinButtonValue = max ?? DEFAULT_SPIN_BUTTON_MAX_VALUE;
    const minSpinButtonValue = min ?? DEFAULT_SPIN_BUTTON_MIN_VALUE;

    const setNewValue = (newValue: number) => {
        newValue = Number(newValue.toFixed(isFloat ? 4 : 0));
        onValueChange(newValue);
    };

    const onSpinButtonIncrement = () => {
        let newSpinButtonValue;
        if (spinButtonValue + 1 <= maxSpinButtonValue) {
            newSpinButtonValue = spinButtonValue + 1;
        } else {
            newSpinButtonValue = maxSpinButtonValue;
        }
        setNewValue(newSpinButtonValue);
    };

    const onSpinButtonDecrement = () => {
        let newSpinButtonValue;

        if (spinButtonValue - 1 > minSpinButtonValue) {
            newSpinButtonValue = spinButtonValue - 1;
        } else {
            newSpinButtonValue = minSpinButtonValue;
        }
        setNewValue(newSpinButtonValue);
    };

    const onSpinButtonValidate = (value: string) => {
        const unlocalizedValue = value.replace(",", ".");
        const numberMatch = (isFloat ? /^-?(\d+)\.?(\d+)?/ : /^-?(\d+)/).exec(unlocalizedValue);

        const matchedValue = Number(numberMatch?.[0]);

        let newSpinButtonValue;
        if (typeof(matchedValue) === 'number' && !isNaN(matchedValue)) {
            if (matchedValue > maxSpinButtonValue) {
                newSpinButtonValue = maxSpinButtonValue;
            } else if (matchedValue < minSpinButtonValue) {
                newSpinButtonValue = minSpinButtonValue;
            } else if (value.trim().length === 0) {
                newSpinButtonValue = DEFAULT_SPIN_BUTTON_VALUE;
            } else {
                newSpinButtonValue = matchedValue;
            }
        } else {
            newSpinButtonValue = spinButtonValue;
        }
        setNewValue(newSpinButtonValue);
    };

    const displayValue = getDisplayValue(spinButtonValue, replaceValueMap);

    return (
        <SpinButton
            {...restProps}
            min={minSpinButtonValue}
            max={maxSpinButtonValue}
            value={displayValue}
            onValidate={(v, e) => {
                onSpinButtonValidate(v);
            }}
            onIncrement={onSpinButtonIncrement}
            onDecrement={onSpinButtonDecrement}
        />
    );
};

export default NumberInputField;
