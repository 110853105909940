import { WindowHelper } from '@eway-crm/gui';
import { IconButton, mergeStyles, Modal } from '@fluentui/react';
import React from 'react';
import IframePostMessageHelper from '../../../../../helpers/IframePostMessageHelper';
import CheckoutResult from '../../checkout/CheckoutResult';
import StoreHelper from '../../StoreHelper';
import { getAreThereAnySubscriptionIssues } from '../../SubscriptionIssuesHandler/SubscriptionIssuesHandler';
import StoreV2Context from '../StoreV2Context';
import CheckoutV2 from './CheckoutV2';
import useSubmitHandler from './hooks/useSubmitHandler';
import SubmitModal from './SubmitModal/SubmitModal';
import cardIssueImg from '../../../../../img/purchase/card-issue.svg';
import Strings from '../../../../../strings';
import GoToAdminCenter from './GoToAdminCenter';

// Allow event bubbling so that events propagate outside of modal.
// https://github.com/microsoft/fluentui/issues/20073
const CHECKOUT_MODAL_LAYER_PROPS = { eventBubblingEnabled: true };

type TCheckoutV2ModalProps = {
    onDismiss: () => void;
    isAfterBuy: false | { isAnythingNew: boolean };
};

const CheckoutV2Modal: React.FC<TCheckoutV2ModalProps> = ({ onDismiss, isAfterBuy }) => {
    const myStrings = Strings.components.routes.subscriptions;
    const isInIframe = WindowHelper.isInIframe();
    const { storeData, packagesEditingStates, currentPlan } = React.useContext(StoreV2Context);

    const areThereAnySubscriptionIssues = getAreThereAnySubscriptionIssues(currentPlan);
    const eulaRef = React.useRef<HTMLDivElement>(null!);

    const items = React.useMemo(() => {
        return packagesEditingStates
            .map((ps) => ({ quantity: ps.state.currentEditQuantity || 0, packageData: StoreHelper.getPackageByArtno(storeData, ps.artno) }))
            .filter((i) => i.quantity > 0 && !!i.packageData)
            .map((i) => ({ quantity: i.quantity, packageData: i.packageData! }));
    }, [packagesEditingStates, storeData]);

    const submitHandler = useSubmitHandler(items, eulaRef);

    const handleDismiss = (e: React.MouseEvent<unknown>) => {
        if (isInIframe) {
            e.preventDefault(); // Prevent redirect by link
            IframePostMessageHelper.windowClose();
            return;
        }
        onDismiss();
    };

    React.useEffect(() => {
        IframePostMessageHelper.checkoutLoaded();
    }, []);

    const handleModalDismiss = (ev?: React.MouseEvent<HTMLElement | HTMLButtonElement, MouseEvent>) => {
        if (ev?.type === 'keydown') {
            const event = ev as unknown as React.KeyboardEvent<KeyboardEvent>;
            if (event.key === 'Escape') {
                // Only dismiss on Escape, not on click outside
                handleDismiss(ev);
            }
        }
    };

    let content = <CheckoutV2 onDismiss={handleDismiss} submitHandler={submitHandler} items={items} eulaRef={eulaRef} />;

    if (areThereAnySubscriptionIssues) {
        content = <GoToAdminCenter imgSrc={cardIssueImg} imgClassName="checkoutV2__card-issue-img" message={myStrings.subscriptionPaymentIssueGoToAdminCenterMessage} />;
    } else if (isAfterBuy) {
        content = <CheckoutResult onDismiss={handleDismiss} isAnythingNew={isAfterBuy.isAnythingNew} />;
    } else if (submitHandler.isSubmitted) {
        content = <SubmitModal submitResult={submitHandler.checkoutSubmittedModel} />;
    }

    return (
        <Modal
            isOpen={true}
            scrollableContentClassName={'checkoutV2'}
            forceFocusInsideTrap={false}
            onDismiss={handleModalDismiss}
            isBlocking={false}
            layerProps={CHECKOUT_MODAL_LAYER_PROPS}
            containerClassName={mergeStyles('checkoutV2__container', isInIframe && 'in-iframe')}
        >
            {isInIframe && <IconButton iconProps={{ iconName: 'Cancel' }} className={"checkoutV2__close-icon"} onClick={handleDismiss} />}
            {content}
        </Modal>
    );
};

export default CheckoutV2Modal;
