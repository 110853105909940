import React from 'react';
import Strings from '../../../../strings';

const myStrings = Strings.components.routes.users;

type TEffectivePermissionsTooltipContainer = React.PropsWithChildren & {
    description: React.ReactNode | null;
};

const EffectivePermissionsTooltipContainer: React.FC<TEffectivePermissionsTooltipContainer> = ({ description, children }) => {
    return <div className="p-3">
    <h3 className="mb-1">{myStrings.effectivePermission}</h3>
    <p className="mb-2 pr-2">
        {description}
    </p>
    <div>{children}</div>
</div>;
};

export default EffectivePermissionsTooltipContainer;
