import type { IModalStyles} from '@fluentui/react';
import { DefaultButton, IconButton, mergeStyles, Modal, PrimaryButton } from '@fluentui/react';
import * as React from 'react';
import type { ICheckSalesTaxModel } from '../../../../../data/shopping/ICheckSalesTaxModel';
import Strings from '../../../../../strings';
import { ProductsStepSummaryTable } from '../../ProductsStepSummaryTable';
import StoreHelper from '../../StoreHelper';
import StoreV2Context from '../StoreV2Context';
import SummaryItems from '../SummaryItems/SummaryItems';
import CompanyDetailsFormContainer from './CompanyDetailsFormContainer/CompanyDetailsFormContainer';
import useCheckedTax from './hooks/useCheckedTax';
import type useSubmitHandler from './hooks/useSubmitHandler';
import './../../../../../theme/checkoutV2.scss';
import CreditCard, { getCardShortTitle } from '../../../../shared/subscriptions/CreditCard';
import CollapsibleCheckoutSection from '../../../../shared/subscriptions/CollapsibleCheckoutSection';
import EulaContainer from '../EulaContainer/EulaContainer';
import type TCheckoutItem from '../../TCheckoutItem';
import { WindowHelper } from '@eway-crm/gui';
import { Subscriptions } from '../../../Subscriptions';
import useTotalPrices from './hooks/useTotalPrices';
import usePaymentConfig from './hooks/usePaymentConfig';
import type { IStripePaymentMethod } from '../../../../../data/shopping/IPaymentMethodsModel';
import EulaAcceptanceForm from './EulaAcceptanceForm/EulaAcceptanceForm';
import StringHelper from '../../../../../helpers/StringHelper';
import type { IImmediateChargeModel } from '../../../../../data/shopping/ICheckTaxesAndChargeForecastModel';
import PeriodToggle from '../Summary/PeriodToggle/PeriodToggle';

const RENDER_COMPONENT_MODAL_STYLES: Partial<IModalStyles> = { main: { minWidth: 0 } };

type TCheckoutV2Props = {
    onDismiss: (e: React.MouseEvent<unknown>) => void;
    submitHandler: ReturnType<typeof useSubmitHandler>;
    eulaRef: React.MutableRefObject<HTMLDivElement>;
    items: TCheckoutItem[];
};

export const getPayWithTitle = (card: IStripePaymentMethod) => `${Strings.components.routes.subscriptions.payWith} ${StringHelper.capitalize(card.Brand)} ${getCardShortTitle(card)}`;

const CheckoutV2: React.FC<TCheckoutV2Props> = ({ onDismiss, submitHandler, eulaRef, items }) => {
    const { storeData, customerInfo, isPackageInCart, selectedPeriodType } = React.useContext(StoreV2Context);
    const isCustomerInfoValid = StoreHelper.isCompanyInfoValid(customerInfo);
    const [isEditingCompanyInfo, setIsEditingCompanyInfo] = React.useState(!isCustomerInfoValid);
    const handleCustomerInfoEditingBegin = React.useCallback(() => setIsEditingCompanyInfo(true), [setIsEditingCompanyInfo]);

    const [salesTax, setSalesTax] = React.useState<ICheckSalesTaxModel | null>(null);
    const [immediateCharge, setImmediateCharge] = React.useState<IImmediateChargeModel | null>(null);
    const { isCheckingTax, invokeTaxCheck } = useCheckedTax(setSalesTax, setImmediateCharge);

    const [isEulaShown, setIsEulaShown] = React.useState(false);

    const handleFormBlur = React.useCallback(() => {
        setIsEditingCompanyInfo(false);
        invokeTaxCheck();
    }, [setIsEditingCompanyInfo, invokeTaxCheck]);

    const checkEula = React.useCallback(() => {
        if (isCheckingTax || !isCustomerInfoValid)
            return false;

        return true;
    }, [isCheckingTax, isCustomerInfoValid]);

    const showFullEula = React.useCallback(() => setIsEulaShown(true), [setIsEulaShown]);

    const paymentConfig = usePaymentConfig(checkEula, showFullEula, submitHandler);

    const { totalPriceWithoutTaxes } = useTotalPrices(items);

    const myStrings = Strings.components.routes.subscriptions;
    const isInIframe = WindowHelper.isInIframe();

    const handleDefaultFinish = React.useCallback(() => {
        if (!checkEula())
            return;

        if (!!paymentConfig.selectedStripeMethod && !!paymentConfig.onPayWithStripeMethodId) {
            paymentConfig.onPayWithStripeMethodId(paymentConfig.selectedStripeMethod.Id);
        } else {
            paymentConfig.onPaySimple();
        }
    }, [checkEula, paymentConfig]);
    const handleDropDownFinish = React.useCallback((id: string) => {
        if (!checkEula())
            return;

        paymentConfig.onPayWithStripeMethodId && paymentConfig.onPayWithStripeMethodId(id);
    }, [checkEula, paymentConfig]);

    const willPayAnything = !storeData.SkipPaymentWhenSubscribing && (!immediateCharge || (immediateCharge.DiffAmount ?? 0) > 0 || immediateCharge.ChargesFullSubscriptionAmount);
    const defaultFinishMobileText = !willPayAnything ? myStrings.finishOrderWitoutPaying : myStrings.pay;
    const defaultFinishText = !willPayAnything ? myStrings.finishOrderWitoutPaying : (!!paymentConfig.selectedStripeMethod ? getPayWithTitle(paymentConfig.selectedStripeMethod) : myStrings.pay);
    const dropdownCardOptions = React.useMemo(() => {
        if (!willPayAnything || !paymentConfig.availableStripeMethods || !paymentConfig.onAddNewAndPay || !paymentConfig.onPayWithStripeMethodId)
            return null;

        return [
            ...paymentConfig.availableStripeMethods.map(card => ({
                key: card.Id,
                text: getPayWithTitle(card),
                iconProps: { iconName: 'PaymentCard' },
                onClick: () => handleDropDownFinish(card.Id)
            })),
            {
                key: '__new',
                text: Strings.components.routes.subscriptions.payWithAnother,
                iconProps: { iconName: 'Add' },
                onClick: paymentConfig.onAddNewAndPay
            }
        ];
    }, [paymentConfig, handleDropDownFinish, willPayAnything]);

    return (
        <>
            {!isInIframe && <IconButton iconProps={{ iconName: 'Cancel' }} className={'checkoutV2__close-icon'} onClick={onDismiss} />}
            <Modal
                className={isEulaShown ? "d-none" : undefined}
                isOpen={!!paymentConfig.renderComponent}
                forceFocusInsideTrap={false}
                styles={RENDER_COMPONENT_MODAL_STYLES}
            >
                <div className="checkoutV2__payment-component-panel">
                    {paymentConfig.renderComponent}
                </div>
            </Modal>
            {(!!paymentConfig.availableStripeMethods) &&
                <CreditCard
                    availableCards={paymentConfig.availableStripeMethods}
                    selectedCard={paymentConfig.selectedStripeMethod}
                    items={items}
                    onAddNewCard={paymentConfig.onAddNew}
                    onCardSelected={paymentConfig.onSelectMethodId}
                    className="checkoutV2__credit-card"
                />
            }
            <div className="checkoutV2__panel-wrap">
                <div className="checkoutV2__left-panel">
                    <CompanyDetailsFormContainer isEditingCompanyInfo={isEditingCompanyInfo} onCompanyInfoEditingBegin={handleCustomerInfoEditingBegin} onCompanyInfoEditingEnd={handleFormBlur} />
                </div>
                <div className={mergeStyles('d-flex flex-column justify-content-between checkoutV2__right-panel')}>
                    <div className="d-flex flex-column flex-grow-1 justify-content-between">
                        <CollapsibleCheckoutSection title={myStrings.subscriptionSummary} mobileSubtitle={`${Strings.formatCurrency(totalPriceWithoutTaxes, Subscriptions.priceDecPlaces, customerInfo.currency)} / ${StoreHelper.getPeriodStrings(selectedPeriodType).unit}`}>
                            <SummaryItems allowRemoving={false} />
                            {isInIframe && (
                                <div className="mb-2">
                                    <PeriodToggle className="d-flex" onToggleChange={() => invokeTaxCheck()} disabled={isCheckingTax || paymentConfig.isLoading} />
                                </div>
                            )}
                        </CollapsibleCheckoutSection>
                        <div className="d-flex justify-content-end mt-auto mb-3">
                            <div className="checkoutV2__price-summary">
                                <ProductsStepSummaryTable
                                    customerInfo={customerInfo}
                                    salesTax={salesTax}
                                    items={items}
                                    isCheckingSalesTax={isCheckingTax}
                                    isPackageInCart={isPackageInCart}
                                    immediateCharge={immediateCharge}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="checkoutV2__footer">
                        <div className="checkoutV2__btn-wrap">
                            <PrimaryButton
                                className={'checkoutV2__buy-btn--small-screen'}
                                disabled={isCheckingTax || !isCustomerInfoValid || isEditingCompanyInfo || paymentConfig.isLoading || !!paymentConfig.renderComponent}
                                onClick={handleDefaultFinish}
                            >
                                {defaultFinishMobileText}
                            </PrimaryButton>
                            <div className={'checkoutV2__buy-btn--large-screen'}>
                                <PrimaryButton
                                    split={!!dropdownCardOptions}
                                    disabled={isCheckingTax || !isCustomerInfoValid || isEditingCompanyInfo || paymentConfig.isLoading || !!paymentConfig.renderComponent}
                                    onClick={handleDefaultFinish}
                                    menuProps={!!dropdownCardOptions ? { items: dropdownCardOptions } : undefined}
                                >
                                    {defaultFinishText}
                                </PrimaryButton>
                            </div>
                            <DefaultButton onClick={onDismiss}>{Strings.cancel}</DefaultButton>
                        </div>
                        <EulaAcceptanceForm onShowFullEula={showFullEula} payBtnText={defaultFinishMobileText} />
                    </div>
                </div>
            </div>
            <div className={(!!paymentConfig.renderComponent && isEulaShown) ? 'checkoutV2__eula-wrapper' : undefined}>
                <EulaContainer
                    eulaRef={eulaRef}
                    isVisible={isEulaShown}
                    items={items}
                    onAccept={() => {
                        setIsEulaShown(false);
                    }}
                    onDismiss={() => {
                        setIsEulaShown(false);
                    }}
                />
            </div>
        </>
    );
};

export default CheckoutV2;
