import * as React from 'react';
import { Button, Alert, Spinner } from 'react-bootstrap';
import Strings from '../../../strings';
import WizardModal from '../../WizardModal';
import type { TAddingButtonChildProps } from './AddingButton';
import type { TStripeCardAdderContainerProps } from '../../shared/paymentGate/StripeCardAdder';
import StripeCardAdder from '../../shared/paymentGate/StripeCardAdder';
import { SpinnerModal } from '../../shared/SpinnerModal';
import { SpinnerVariant } from '@eway-crm/gui';

const StripeAddingModal: React.FC<TAddingButtonChildProps> = (props) => {
    const { onCanceled } = props;
    const StripeCardFormModal = React.useMemo(() => {
        const StripeCardFormModal: React.FC<TStripeCardAdderContainerProps> = (props) => {
            const myStrings = Strings.components.routes.billingDetails;
            return (
                <WizardModal show={true} onHide={onCanceled}>
                    <WizardModal.Body>
                        <h1 className="mb-5">{myStrings.newPaymentMethod}</h1>
                        {(!!props.errorMessage) &&
                            <Alert variant="danger">
                                {props.errorMessage}
                            </Alert>
                        }
                        {props.children}
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        <Button variant="primary" onClick={props.onSubmit} disabled={!props.isSubmittable}>
                            {(!!props.isProcessing) &&
                                <Spinner animation="border" size="sm" className="mr-2" />
                            }
                            {myStrings.add}
                        </Button>
                        <Button variant="outline-secondary" onClick={onCanceled} disabled={props.isProcessing}>
                            {Strings.cancel}
                        </Button>
                    </WizardModal.Footer>
                </WizardModal>
            );
        };
        return StripeCardFormModal;
    }, [onCanceled]);

    return (
        <StripeCardAdder
            loadingComponent={<SpinnerModal variant={SpinnerVariant.ease} />}
            elementsContainerComponent={StripeCardFormModal}
            onAdded={props.onAdded}
            shoppingConnection={props.shoppingConnection}
        />
    );
};

export default StripeAddingModal;