import React, { useContext } from 'react';
import Strings from '../../../../strings';
import ObjectHelper from '../../../../helpers/ObjectHelper';
import useListArrowFocus from '../../../../helpers/hooks/useListArrowFocus';
import SearchResultItem from './SearchResultItem';
import type { TPage } from './SearchResultsObtainer';
import { RemoteItemStore } from '../../../../RemoteItemStore';
import { SearchAction } from './searchpages';
import { ConnectionContext } from '../../../../providers/ConnectionProvider';

type TSearchResultsProps = {
    search: TPage[];
    maxResultsPerGroup?: number;
    focusInput: () => void;
    query: string;
    onResultClick?: () => void;
};

type TGroupedResults = {
    [group: string]: TPage[];
};

const myStrings = Strings.components;

const resultsOrder = [
    myStrings.sideMenu.users,
    myStrings.sideMenu.groups,
    myStrings.sideMenu.modulePermissions,
    myStrings.sideMenu.features,
    myStrings.sideMenu.fields,
    myStrings.routes.groups.fieldPermissions,
    myStrings.sideMenu.workflow,
    myStrings.sideMenu.billing,
    myStrings.sideMenu.updates,
    myStrings.search.apps.name,
];

const groupResults = (arr: TPage[], maxResultsPerGroup: number | undefined) => {
    const results = arr.reduce((result: TGroupedResults, currentVal: TPage) => {
        // Check if maxResultsPerGroup is exceeded
        if (maxResultsPerGroup && result[currentVal.group] && result[currentVal.group].length >= maxResultsPerGroup) {
            return result;
        }
        (result[currentVal.group] = result[currentVal.group] || []).push(currentVal);
        return result;
    }, {});
    const resultsWithSortedGroups = ObjectHelper.sortObjectByKeyNameList(results, resultsOrder);
    return sortResults(resultsWithSortedGroups);
};

const sortResults = (searchResults: TGroupedResults) => {
    Object.keys(searchResults).forEach((key) => {
        const results = searchResults[key];
        if (results) {
            const manage = results.filter((item) => item.action === SearchAction.Manage);
            const addNew = results.filter((item) => item.action === SearchAction.Add);
            const other = results.filter((item) => item.action === SearchAction.Other);
            const other2 = results.filter((item) => item.action === SearchAction.Other2);

            // Move results with actions to the bottom of results in this order
            manage && manage.forEach((item) => results.push(results.splice(results.indexOf(item), 1)[0]));
            addNew && addNew.forEach((item) => results.push(results.splice(results.indexOf(item), 1)[0]));
            other && other.forEach((item) => results.push(results.splice(results.indexOf(item), 1)[0]));
            other2 && other2.forEach((item) => results.push(results.splice(results.indexOf(item), 1)[0]));
        }
    });
    return searchResults;
};

const SearchResults: React.FC<TSearchResultsProps> = ({ search, maxResultsPerGroup, focusInput, query, onResultClick }) => {
    const { connection } = useContext(ConnectionContext);
    const groupedResults = search && groupResults(search, maxResultsPerGroup);
    const resultsLength = Object.values(groupedResults).flat().length;
    const [currentFocus] = useListArrowFocus(resultsLength, focusInput);
    let resultIndex = 0;

    const handleResultClick = (clickedResult: string) => {
        onResultClick && onResultClick();
        new RemoteItemStore(connection).saveSearchFeedback(clickedResult, query);
    };

    return (
        <>
            {Object.keys(groupedResults).length === 0 ? (
                <div key="no-match" className="searchbar__results__no-match">
                    {`${myStrings.search.noResultsFound} `}
                    <a href={myStrings.search.noResultsFoundHowToLink} target="_blank" rel="noreferrer noopener">{myStrings.search.noResultsFoundHowTo}</a>
                    {` ${myStrings.search.noResultsFoundOr} `}
                    <a href={myStrings.search.noResultsFoundCommunityLink} target="_blank" rel="noreferrer noopener">{myStrings.search.noResultsFoundCommunity}</a>
                    {`.`}
                </div>
            ) : (
                Object.entries(groupedResults).map(([group, pages]) => (
                    <div key={group}>
                        <div className="searchbar__results__group">{group}</div>
                        {pages.map((page: TPage) => {
                            resultIndex++;
                            return <SearchResultItem page={page} key={`${page.link}`} isFocused={resultIndex === currentFocus} isExternalLink={!!page.isExternalLink} resultClick={handleResultClick} />;
                        })}
                    </div>
                ))
            )}
        </>
    );
};

export default SearchResults;
