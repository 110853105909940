import React from 'react';
import { InputGroup } from 'react-bootstrap';
import type { IApiPasswordStrength } from '@eway-crm/connector';
import { PasswordHelper } from '../../../helpers/PasswordHelper';
import Strings from '../../../strings';

type TPasswordRankProps = {
    passwordStrengths: IApiPasswordStrength[] | null;
};

const myStrings = Strings.components.routes.users;
const PasswordRank = ({ passwordStrengths }: TPasswordRankProps) => {
    const score = PasswordHelper.getLowestPasswordScore(passwordStrengths);
    let passwordRank = null;
    if (passwordStrengths !== null) {
        if (score > 3) {
            passwordRank = <InputGroup.Text className="alert-success">{myStrings.passwordStrong}</InputGroup.Text>;
        } else if (score > 2) {
            passwordRank = <InputGroup.Text className="alert-info">{myStrings.passwordGood}</InputGroup.Text>;
        } else if (score > 1) {
            passwordRank = <InputGroup.Text className="alert-warning">{myStrings.passwordWeak}</InputGroup.Text>;
        } else {
            passwordRank = <InputGroup.Text className="alert-danger">{myStrings.passwordSuperweak}</InputGroup.Text>;
        }
    }

    return passwordRank;
};

export default PasswordRank;
