import React from 'react';
import type { Table } from '@devexpress/dx-react-grid-bootstrap4';
import type { ISelectableOption } from '@fluentui/react';
import { Dropdown } from '@fluentui/react';
import OptionalComponentWrapper from '../../../helpers/components/OptionalComponentWrapper';
import type { Column } from '@devexpress/dx-react-grid';

export const ORB_ICON_SIZE = '16px';

export type TPermissionsCellEditProps<TWrapperDataRow> = {
    onValueChange?: (rowId: string, column: string, newValue: string | number | boolean | null) => void;
    isEditable?: boolean;
    cellWrapper?: React.ComponentType<{ data?: { row: TWrapperDataRow; column: Column } }>;
};

type TDropdownCellEditProps<TWrapperDataRow> = TPermissionsCellEditProps<TWrapperDataRow> & {
    optionsSettings: {
        [key: string]: {
            key: string;
            text: (action: string) => string;
            color: string;
            icon: ((props: { style: React.CSSProperties }) => JSX.Element) | React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined }>;
        };
    };
    columnSettings: {
        action: string;
        width: number;
    };
};

const OrbDropdownEditCell = <TWrapperDataRow,>({ onValueChange, optionsSettings, columnSettings, isEditable = true, ...props }: React.PropsWithChildren<Table.DataCellProps & TDropdownCellEditProps<TWrapperDataRow>>) => {
    const typedValue = props.value as keyof typeof optionsSettings;
    const optionSettings = optionsSettings[typedValue];

    if (!optionSettings) {
        console.error("There is a mismatch between available field permission values and actual value", props, columnSettings, optionsSettings);
        return <td />;
    }

    if (!isEditable) {
        const Icon = optionSettings.icon;
        const typedRow = props.row as TWrapperDataRow;
        return (
            <td className="p-0 align-middle text-center" title={optionSettings.text(columnSettings.action)}>
                <OptionalComponentWrapper wrapperComponent={props.cellWrapper} data={{ row: typedRow, column: props.column }}>
                    <Icon className="d-block my-0 mx-auto" style={{ width: ORB_ICON_SIZE, height: ORB_ICON_SIZE, fill: optionSettings.color, opacity: '0.5' }} />
                </OptionalComponentWrapper>
            </td>
        );
    }

    return (
        <td className="p-0 align-middle" title={optionSettings.text(columnSettings.action)}>
            <Dropdown
                title={optionSettings.text(columnSettings.action)}
                {...props}
                options={Object.entries(optionsSettings).map(([key, option]) => ({
                    key,
                    text: option.text(columnSettings.action),
                    data: { icon: option.icon, color: option.color },
                }))}
                selectedKey={typedValue}
                dropdownWidth={'auto'}
                onRenderTitle={() => {
                    const Icon = optionSettings.icon;
                    return <Icon style={{ width: ORB_ICON_SIZE, height: ORB_ICON_SIZE, fill: optionSettings.color }} />;
                }}
                onRenderOption={(props?: ISelectableOption<{ icon: React.ComponentType<{ style: React.CSSProperties }>; color: string }>) => {
                    const Icon = props!.data!.icon;
                    return (
                        <>
                            <Icon style={{ width: ORB_ICON_SIZE, height: ORB_ICON_SIZE, marginRight: '0.4rem', fill: props?.data?.color }} />
                            <span>{props?.text}</span>
                        </>
                    );
                }}
                onChange={(event, option) => {
                    onValueChange && onValueChange(props.tableRow.rowId as string, props.column.name, option?.key as string);
                }}
                style={{ margin: '0 auto' }}
            />
        </td>
    );
};

export default OrbDropdownEditCell;
