import * as React from 'react';
import Strings from '../../../strings';
import { Form, Col, Button } from 'react-bootstrap';
import WizardModal from '../../WizardModal';
import WizardSteps from '../../WizardSteps';
import type { TGroupWizardBaseProps } from './GroupWizardBase';
import { GroupWizardBase } from './GroupWizardBase';
import FolderNames from '../../../data/constants/FolderNames';
import RelationTypes from '../../../data/constants/RelationTypes';
import { SpinnerModal } from '../../shared/SpinnerModal';
import type { IApiResult } from "@eway-crm/connector";
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import { SpinnerVariant } from '@eway-crm/gui';
import WizardBase from '../../shared/WizardBase';
import DisabledByPermissionBadge from '../../shared/DisabledByPermissionsBadge';

const dialogBodyExpectedHeight = '44rem';

type TNewGroupWizardState = {
    currentStep: number;
    groupName: string;
    responsibilityDescription: string;
    note: string;
    usersSearchedString: string;
    usersSelection: string[];
    isPm: boolean;
    isRole: boolean;
    anyChangeMade: boolean;
    isGeneralFormValidated: boolean;
    isSavingInProgress: boolean;
};

export class NewGroupWizard extends React.Component<TGroupWizardBaseProps, TNewGroupWizardState> {
    static contextType = ConnectionContext;
    context!: React.ContextType<typeof ConnectionContext>;

    private static readonly stepGeneral = 1;
    private static readonly stepAssignUsers = 2;
    private static readonly stepSpecial = 3;

    private static readonly firstStep = NewGroupWizard.stepGeneral;
    private static readonly lastStep = NewGroupWizard.stepSpecial;

    private readonly generalFormReference: React.RefObject<HTMLFormElement>;

    constructor(props: TGroupWizardBaseProps) {
        super(props);
        this.generalFormReference = React.createRef();
        this.state = {
            currentStep: NewGroupWizard.firstStep,
            groupName: '',
            responsibilityDescription: '',
            note: '',
            usersSearchedString: '',
            usersSelection: [],
            isPm: false,
            isRole: false,
            anyChangeMade: false,
            isGeneralFormValidated: false,
            isSavingInProgress: false
        };
    }

    private readonly dismiss = () => {
        this.props.onDismiss();
    };

    private readonly back = () => {
        this.goToStep(this.state.currentStep - 1);
    };

    private readonly next = () => {
        this.goToStep(this.state.currentStep + 1);
    };

    private readonly goToStep = (wantedStep: number) => {
        const number = Math.max(NewGroupWizard.firstStep, Math.min(NewGroupWizard.lastStep, wantedStep));
        if (this.state.currentStep === number)
            return;

        for (let i = NewGroupWizard.firstStep; i < number; i++) {
            if (!this.isStepValid(i, true)) {
                return;
            }
        }
        this.setState({
            currentStep: number,
            isGeneralFormValidated: false
        });
    };

    private readonly isStepValid = (number: number, goToIfInvalid: boolean) => {
        const falseCallback = () => {
            if (goToIfInvalid) {
                this.goToStep(number);
            }
        };

        if (number === NewGroupWizard.stepGeneral) {
            if (this.generalFormReference.current && this.generalFormReference.current.checkValidity() === false) {
                this.setState({ isGeneralFormValidated: true }, falseCallback);
                return false;
            }

            if (!GroupWizardBase.isGroupNameValid(this.state.groupName, this.props.forbiddenGroupNames)) {
                falseCallback();
                return false;
            }
        }

        return true;
    };

    private readonly getFieldDisabledByPermissions = (fieldName: string) => {
        return WizardBase.getFieldDisabledByPermissions(fieldName, this.props.myColumnPermissionsMap);
    };

    private readonly submit = () => {
        this.setState(
            { isSavingInProgress: true },
            () => {
                this.context.connection.callApi(
                    'SaveGroup',
                    {
                        transmitObject: {
                            IsCategory: false,
                            IsOutlookCategory: false,
                            IsPM: this.state.isPm,
                            IsRole: this.state.isRole,
                            GroupName: this.state.groupName.trim(),
                            FileAs: this.state.groupName.trim(),
                            ResponsibilityDescription: this.state.responsibilityDescription,
                            Description: this.state.note
                        }
                    },
                    (result: IApiResult & { Guid: string }) => {
                        if (this.state.usersSelection.length === 0) {
                            this.props.onDone();
                        } else {
                            const groupRelations = this.state.usersSelection.map((userGuid) => {
                                return {
                                    ItemGUID1: userGuid,
                                    ItemGUID2: result.Guid,
                                    FolderName1: FolderNames.users,
                                    FolderName2: FolderNames.groups,
                                    RelationType: RelationTypes.group,
                                    DifferDirection: false
                                };
                            });
                            this.context.connection.callApi(
                                'SaveRelations',
                                {
                                    transmitObjects: groupRelations
                                },
                                (_) => {
                                    this.props.onDone();
                                }
                            );
                        }
                    }
                );
            }
        );
    };

    render() {
        const descriptionDisabledByPermissions = this.getFieldDisabledByPermissions("ResponsibilityDescription");
        const noteDisabledByPermissions = this.getFieldDisabledByPermissions("Description");

        const myStrings = Strings.components.routes.groups;

        if (this.state.isSavingInProgress) {
            return (
                <SpinnerModal variant={SpinnerVariant.linear} />
            );
        } else {
            return (
                <WizardModal
                    show={true}
                    size="xl"
                    onHide={this.dismiss}
                    showCancelButtonInFooter={true}
                    hideConfirmDialog={WizardModal.getUnsavedChangedDialog(this.state.anyChangeMade)}
                    dialogBodyExpectedHeight={dialogBodyExpectedHeight}
                >
                    <WizardModal.Title>{myStrings.addNewGroup}</WizardModal.Title>
                    <WizardModal.Steps>
                        <WizardSteps steps={[myStrings.general, myStrings.assignUsers, myStrings.special]} currentStep={this.state.currentStep} onStepPicked={this.goToStep} />
                    </WizardModal.Steps>
                    <WizardModal.Body>
                        {this.state.currentStep === NewGroupWizard.stepGeneral &&
                            <>
                                <h2 className="mb-5">{myStrings.general}</h2>
                                <Form ref={this.generalFormReference} validated={this.state.isGeneralFormValidated} className="form-application">
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="newGroupName">
                                            <Form.Label>{myStrings.groupName}</Form.Label>
                                            <Form.Control
                                                type="text"
                                                required
                                                pattern={GroupWizardBase.getGroupNamePattern(this.state.groupName, this.props.forbiddenGroupNames)}
                                                value={this.state.groupName} onChange={(e) => this.setState({ groupName: e.target.value, anyChangeMade: true })}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                {this.state.groupName ? (this.state.groupName.trim().length < GroupWizardBase.minGroupNameLength ? myStrings.groupNameTooShort : myStrings.selectUniqueName) : Strings.thisFieldIsMandatory}
                                            </Form.Control.Feedback>
                                        </Form.Group>
                                    </Form.Row>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="newGroupRespDescription">
                                            <Form.Label>{myStrings.description}<DisabledByPermissionBadge disabledByPermissions={descriptionDisabledByPermissions} /></Form.Label>
                                            <Form.Control type="text" disabled={descriptionDisabledByPermissions?.isDisabled} value={this.state.responsibilityDescription} onChange={(e) => this.setState({ responsibilityDescription: e.target.value, anyChangeMade: true })} />
                                        </Form.Group>
                                    </Form.Row>
                                    <h2 className="mt-5">{myStrings.note} <DisabledByPermissionBadge disabledByPermissions={noteDisabledByPermissions} /></h2>
                                    <Form.Row>
                                        <Form.Group as={Col} controlId="newGroupNote">
                                            <Form.Control as="textarea" rows={14} disabled={noteDisabledByPermissions?.isDisabled} placeholder={myStrings.writeNotes} value={this.state.note} onChange={(e) => this.setState({ note: e.target.value, anyChangeMade: true })} />
                                        </Form.Group>
                                    </Form.Row>
                                </Form>
                            </>
                        }
                        {this.state.currentStep === NewGroupWizard.stepAssignUsers &&
                            <GroupWizardBase.UsersPicker
                                showHeading
                                showSearch
                                usersSelection={this.state.usersSelection}
                                onUsersSelectionChange={(sel) => { this.setState({ usersSelection: sel, anyChangeMade: true }); }}
                                usersSearchedString={this.state.usersSearchedString}
                                onUsersSearchedStringChange={(str) => { this.setState({ usersSearchedString: str, anyChangeMade: true }); }}
                                availableUsers={this.props.availableUsers}
                                currentUserGuid={this.context.connection.state.session!.userGuid}
                            />
                        }
                        {this.state.currentStep === NewGroupWizard.stepSpecial &&
                            <GroupWizardBase.SpecialForm
                                showHeading={true}
                                itemGuid={null}
                                isSystem={false}
                                pmGroupItem={this.props.pmGroupItem}
                                isPm={this.state.isPm}
                                isRole={this.state.isRole}
                                onRoleOrPmChange={(isRole, isPm) => this.setState({ isPm: isPm, isRole: isRole, anyChangeMade: true })}
                            />
                        }
                    </WizardModal.Body>
                    <WizardModal.Footer>
                        {this.state.currentStep > NewGroupWizard.firstStep &&
                            <Button variant="outline-secondary" onClick={this.back}>{Strings.back}</Button>
                        }
                        {this.state.currentStep < NewGroupWizard.lastStep &&
                            <Button variant="secondary" onClick={this.next}>{Strings.next}</Button>
                        }
                        {this.state.currentStep === NewGroupWizard.lastStep &&
                            <Button variant="primary" onClick={this.submit}>{myStrings.createGroup}</Button>
                        }
                    </WizardModal.Footer>
                </WizardModal>
            );
        }
    }
}