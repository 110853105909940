import React, { useState, useEffect, useRef } from 'react';
import WizardModal from '../../../WizardModal';
import type * as JsSearch from 'js-search';
import type { ITextField} from '@fluentui/react';
import { TextField, PrimaryButton } from '@fluentui/react';
import SearchResults from './SearchResults';
import { Button } from 'react-bootstrap';
import Strings from '../../../../strings';
import type { TPage } from './SearchResultsObtainer';
import StringHelper from '../../../../helpers/StringHelper';

type TSearchModalProps = {
    jsSearch: React.MutableRefObject<JsSearch.Search> | null;
    query: string;
    onHide: (query: string) => void;
    newValueCb: (value: string) => void;
};

const myStrings = Strings.components.search;

const SearchModal: React.FC<TSearchModalProps> = ({ query, onHide, jsSearch, newValueCb }) => {
    const [value, setValue] = useState(query);
    const modalSearchRef = useRef<ITextField>(null);

    useEffect(() => {
        modalSearchRef.current?.focus();
    }, [modalSearchRef]);

    return (
        <div>
            <WizardModal show={true} size="lg" onHide={() => onHide(value)} centered={false}>
                <WizardModal.Body>
                    <div className="searchbar__modal">
                        <h4 className="searchbar__modal__title">{myStrings.title}</h4>
                        <TextField
                            placeholder={myStrings.searchPlaceholder}
                            onChange={(e, newValue = '') => {
                                newValueCb(newValue);
                                setValue(newValue);
                            }}
                            className="searchbar my-2"
                            value={value}
                            autoComplete="off"
                            componentRef={modalSearchRef}
                            spellCheck="false"
                            onRenderSuffix={() =>
                                value.length > 0 ? (
                                    <PrimaryButton
                                        iconProps={{ iconName: 'Cancel' }}
                                        className="searchbar__clearBtn"
                                        onClick={() => {
                                            setValue('');
                                            modalSearchRef.current?.focus();
                                        }}
                                    />
                                ) : null
                            }
                        />
                        {jsSearch && jsSearch.current && (
                            <div className="searchbar__modal__resultsWrap">
                                <SearchResults
                                    search={jsSearch.current.search(StringHelper.removeAccents(value) || '') as TPage[]}
                                    focusInput={() => setTimeout(() => modalSearchRef.current?.focus(), 10)}
                                    query={query}
                                    maxResultsPerGroup={20}
                                    onResultClick={() => onHide('')}
                                />
                            </div>
                        )}
                    </div>
                </WizardModal.Body>
                <WizardModal.Footer>
                    <Button variant="secondary" onClick={() => onHide(value)}>
                        {myStrings.dismiss}
                    </Button>
                </WizardModal.Footer>
            </WizardModal>
        </div>
    );
};

export default SearchModal;
