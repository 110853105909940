import type { IFontIconProps} from '@fluentui/react';
import { FontIcon, mergeStyles, TooltipHost } from '@fluentui/react';
import React, { useContext } from 'react';
import type { TLicenseRestriction } from '../../../helpers/LicenseRestrictionsHelper';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import type { TLicenseRestrictionsModalData } from '@eway-crm/gui';
import LicenseRestrictionsTooltipContent from './LicenseRestrictionsTooltipContent';

type TLicenseRestrictionsLockIconProps = IFontIconProps & {
    licenseRestriction: TLicenseRestriction;
    featureLockModalData?: TLicenseRestrictionsModalData; 
    isSmall?: boolean;
    showLockModalOnClick?: boolean;
};

const LicenseRestrictionsLockIcon: React.FC<TLicenseRestrictionsLockIconProps> = ({ className, licenseRestriction, featureLockModalData, isSmall = true, showLockModalOnClick = true, ...restProps }) => {
    const { showLicenseRestrictionModal } = useContext(ConnectionContext);

    const handleClick = () => {
        if (!showLockModalOnClick) {
            return;
        }

        showLicenseRestrictionModal(licenseRestriction, featureLockModalData);
    };

    return (
        <TooltipHost content={<LicenseRestrictionsTooltipContent licenseRestriction={licenseRestriction} featureLockModalData={featureLockModalData}/>} hostClassName={className}>
            <FontIcon className={mergeStyles('cursor-pointer', isSmall && 'small')} iconName="LockSolid" {...restProps} onClick={handleClick} />
        </TooltipHost>
    );
};

export default LicenseRestrictionsLockIcon;
