import { IconButton, mergeStyles } from '@fluentui/react';
import React, { useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import Strings from '../../../../../strings';
import { Eula } from '../../checkout/Eula';
import type TCheckoutItem from '../../TCheckoutItem';
import StoreV2Context from '../StoreV2Context';
import './EulaContainer.scss';

type TEulaContainerProps = {
    isVisible: boolean;
    eulaRef: React.MutableRefObject<HTMLDivElement>;
    items: TCheckoutItem[];
    onAccept: () => void;
    onDismiss: () => void;
};

const EulaContainer: React.FC<TEulaContainerProps> = ({ isVisible, eulaRef, items, onAccept, onDismiss }) => {
    const { storeData, countries, customerInfo, selectedPeriodType, isPackageInCart } = React.useContext(StoreV2Context);
    const myStrings = Strings.components.routes.subscriptions;
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isVisible) {
            // Scroll to top to not use scroll position from previous page
            containerRef.current?.scrollIntoView(true);
        }
    }, [isVisible, eulaRef]);

    return (
        <div ref={containerRef} className={mergeStyles("eula-container", !isVisible && "not-visible")}>
            <div className={"eula-container__icons"}>
                <IconButton iconProps={{ iconName: 'Cancel' }} className={"checkoutV2__close-icon"} onClick={onDismiss} />
                <IconButton iconProps={{ iconName: 'Back' }} className={"eula-container__back-icon "} onClick={onDismiss} />
            </div>
            <div ref={eulaRef} className="eula-container__content">
                <Eula
                    customerInfo={customerInfo}
                    customerCountryEv={countries.find((c) => c.countryEv.FileAs === customerInfo.country)?.countryEv || null}
                    eula={storeData.Eula}
                    eulaAlternatives={storeData.EulaAlternatives}
                    orderNumber={storeData.NewOrderNumber}
                    items={items}
                    isPackageInCart={isPackageInCart}
                    periodType={selectedPeriodType}
                />
            </div>
            <Button
                className="eula-container__accept-btn"
                variant="outline-secondary"
                onClick={onAccept}
            >
                {myStrings.accept}
            </Button>
        </div>
    );
};

export default EulaContainer;
