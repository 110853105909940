import * as React from 'react';
import Strings from "../../../strings";
import type { IApiGroup } from '@eway-crm/connector';

type TGroupBadgeFlagsProps = {
    item: IApiGroup;
    defaultGroupGuid?: string;
    style?: React.CSSProperties;
};

export class GroupBadgeFlagsHelper {
    static hasAnyBadge(item: IApiGroup, defaultGroupGuid?: string) {
        return item.System
            || item.IsAdmin
            || item.IsPM
            || item.ItemGUID === defaultGroupGuid;
    }
}

const GroupBadgeFlags: React.FC<TGroupBadgeFlagsProps> = React.memo((props) => {
    const myStrings = Strings.components.routes.groups;

    if (!GroupBadgeFlagsHelper.hasAnyBadge(props.item, props.defaultGroupGuid))
        return null;

    return (
        <>
            {props.item.System &&
                <span style={props.style}> <span className="badge badge-warning">{myStrings.system}</span></span>
            }
            {props.item.IsAdmin &&
                <span style={props.style}> <span className="badge badge-warning">{myStrings.administrator}</span></span>
            }
            {props.item.IsPM &&
                <span style={props.style}> <span className="badge badge-info">{myStrings.projectManager}</span></span>
            }
            {props.item.ItemGUID === props.defaultGroupGuid &&
                <span style={props.style}> <span className="badge badge-success">{Strings.components.routes.users.defaultGroup}</span></span>
            }
        </>
    );
});

export default GroupBadgeFlags;