import type { IDropdownOption, IDropdownProps } from '@fluentui/react';
import { Dropdown } from '@fluentui/react';
import React, { useMemo } from 'react';
import type { IApiGroup } from '@eway-crm/connector';
import StringHelper from '../../../helpers/StringHelper';
import Strings from '../../../strings';

type TGroupDropdownPickerProps = Pick<IDropdownProps, 'styles' | 'className' | 'calloutProps' | 'disabled' | 'onRenderTitle'> & {
    allGroups: IApiGroup[];
    selectedKeys?: string[];
    onChange?: (newValues: string[]) => void;
};

const myStrings = Strings.components.routes.features;

const GroupDropdownPicker: React.FC<TGroupDropdownPickerProps> = ({ selectedKeys = [], allGroups, onChange, ...restProps }) => {
    const dropdownOptions = useMemo(() => allGroups.map((grp) => ({ key: grp.GroupName, text: grp.GroupName })).sort((a, b) => StringHelper.localeCompare(a.text, b.text)), [allGroups]);

    const handleChange = (e: React.FormEvent<HTMLDivElement>, item?: IDropdownOption): void => {
        if (item) {
            const newSelectedKeys = item.selected ? [...selectedKeys, item.key as string] : selectedKeys.filter((key) => key !== item.key);
            onChange && onChange(newSelectedKeys);
        }
    };

    const title = useMemo(() => selectedKeys.sort(StringHelper.localeCompare).join(", "), [selectedKeys]);

    return (
        <Dropdown
            placeholder={myStrings.selectGroups}
            selectedKeys={selectedKeys}
            options={dropdownOptions}
            title={title}
            multiSelect
            onChange={handleChange}
            {...restProps}
        />
    );
};

export default GroupDropdownPicker;
