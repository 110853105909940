import { Link } from '@fluentui/react';
import React, { useContext } from 'react';
import { ConnectionContext } from '../../../providers/ConnectionProvider';
import Strings from '../../../strings';
import disableWorkflowImgEn from '../../../img/locks/disableWorkflowEn.gif';
import disableWorkflowImgCs from '../../../img/locks/disableWorkflowCs.gif';
import type { TFolderName } from '@eway-crm/connector';
import { getWorkflowLicenseRestrictionsErrors } from './WorkflowLicenseRestrictionsErrors';
import TooltipContentSteps from '../../layout/TooltipContentSteps';
import LocalizationHelper from '../../../helpers/LocalizationHelper';

type TWorkflowRestrictionTooltipContentProps = {
    folderName: TFolderName;
};

const WorkflowRestrictionTooltipContent: React.FC<TWorkflowRestrictionTooltipContentProps> = ({ folderName }) => {
    const myStrings = Strings.components.licenseRestrictions;
    const context = useContext(ConnectionContext);
    const { showLicenseRestrictionModal } = context;

    const allWorkflowLicenseRestrictionErrors = getWorkflowLicenseRestrictionsErrors(context);
    const wfLicenseRestrictionErrors = allWorkflowLicenseRestrictionErrors[folderName];

    if (!wfLicenseRestrictionErrors) {
        return null;
    }

    return (
        <TooltipContentSteps
            steps={wfLicenseRestrictionErrors}
            onRenderContent={(step) => {
                return (
                    <div className="d-flex flex-row">
                        <div>
                            <h4 className="mb-2">{step.title}</h4>
                            <div>{step.tooltipMessage}</div>
                            <br />
                            <div>
                                {Strings.formatString(
                                    myStrings.workflowUpgradePlanToExtendLimit,
                                    <Link
                                        className="d-inline-block"
                                        onClick={() => {
                                            showLicenseRestrictionModal(step.stats.licenseRestriction!);
                                        }}
                                    >
                                        {myStrings.upgradeYourPlanBtn}
                                    </Link>
                                )}
                            </div>
                        </div>
                        <img src={LocalizationHelper.isLangCs() ? disableWorkflowImgCs : disableWorkflowImgEn} className="workflow__menu-tooltip__img" alt="disable workflow" />
                    </div>
                );
            }}
        />
    );
};

export default WorkflowRestrictionTooltipContent;