export class NumberHelper {

    static compare(a: number, b: number): number {
        return (a > b ? 1 : (a < b ? -1 : 0));
    }

    static compareWithNulls(a: number | null | undefined, b: number | null | undefined, nullReplacement: number): number {
        if (!a && a !== 0) {
            a = nullReplacement;
        }
        if (!b && b !== 0) {
            b = nullReplacement;
        }
        return NumberHelper.compare(a, b);
    }

    /**
     * Gets random number frombetween the two values (both including). Both inputs must be integers.
     * 
     * @param min Included minimum.
     * @param max Included maximum.
     */
    static getRandomInt(min: number, max: number): number {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    /**
     * Prepend zeroes in front of the number.
     */
    static prependZeros = (number: number, minNumberOfDigits: number) => {
        return number.toLocaleString("en-US", {
            minimumIntegerDigits: minNumberOfDigits,
            useGrouping: false,
        });
    };
}