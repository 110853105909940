import type { IDropdownOption, IDropdownProps, ISelectableOption} from '@fluentui/react';
import { Checkbox, Dropdown, DropdownMenuItemType, mergeStyles } from '@fluentui/react';
import React, { useCallback, useMemo } from 'react';
import type { IApiGlobalSetting } from '@eway-crm/connector';
import StringHelper from '../../../helpers/StringHelper';
import Strings from '../../../strings';
import type { TEnumTypeEnumValues } from '../Features';

const OPTION_DIVIDER = { key: 'divider', index: Math.random(), text: '-', itemType: DropdownMenuItemType.Divider };

type TNestedMultiselectDropdownProps = Pick<IDropdownProps, 'styles' | 'className' | 'calloutProps' | 'disabled' | 'onRenderTitle'> & {
    enumTypeEnumValues: TEnumTypeEnumValues;
    availableValues: IApiGlobalSetting['AvailableValues'];
    selectedKeys?: string[];
    onChange: (option: IDropdownOption) => void;
    onEnumValuesChange: (items: TEnumTypeEnumValueChangeItems[]) => void;
};

export type TEnumTypeEnumValueChangeItems = {
    itemGuid: string;
    folder: string;
    checked: boolean | null;
};

type TDropdownItem = ISelectableOption & {
    id: string;
    onChange: (option: IDropdownOption) => void;
    checked: boolean;
    indeterminate?: boolean;
};

const DropdownOption: React.FC<TDropdownItem> = ({ text, id, onChange, checked, indeterminate }) => {
    return (
        <div className={mergeStyles('features__dropdownItem')}>
            <Checkbox
                label={text}
                checked={checked}
                indeterminate={indeterminate}
                styles={{ text: { margin: '1px' } }}
                onChange={(e, checked) => {
                    onChange({ key: id, text, selected: indeterminate ? false : !!checked });
                }}
            />
        </div>
    );
};

type TDropdownEnumValueItem = ISelectableOption & {
    id: string;
    onChange: (item: TEnumTypeEnumValueChangeItems) => void;
    checked: boolean;
    folder: string;
};

const DropdownEnumValueItem: React.FC<TDropdownEnumValueItem> = ({ text, id, onChange, checked, folder }) => {
    return (
        <div className={mergeStyles('features__dropdownItem features__dropdownEnumValueItem')}>
            <Checkbox
                label={text}
                checked={checked}
                styles={{ text: { margin: '1px' } }}
                onChange={(e, checked) => {
                    onChange({ checked: !!checked, itemGuid: id, folder });
                }}
            />
        </div>
    );
};

const myStrings = Strings.components.routes.features;

const NestedMultiselectDropdown: React.FC<TNestedMultiselectDropdownProps> = ({ selectedKeys = [], enumTypeEnumValues, availableValues, onChange, onEnumValuesChange, ...restProps }) => {
    const handleRenderItem = useCallback(
        (props?: ISelectableOption, defaultRender?: (props?: ISelectableOption) => JSX.Element | null) => {
            const currentFolder = availableValues?.find((av) => av.Key === props?.key)?.Value;
            if (props) {
                if (currentFolder) {
                    const enumValues = enumTypeEnumValues[currentFolder]?.sort((a, b) => a.Rank - b.Rank);
                    const isEveryEnumValueChecked = enumValues ? enumValues?.every((ev) => !!ev.IncludeInLastActivityCalculation) : true;
                    const isNoEnumValueChecked = enumValues ? enumValues?.every((ev) => !ev.IncludeInLastActivityCalculation) : false;

                    let renderItems = (
                        <DropdownOption
                            key={props.key}
                            text={props.text}
                            checked={!!props.selected}
                            indeterminate={props.selected && !isEveryEnumValueChecked}
                            id={props.key as string}
                            onChange={(option) => {
                                if (!!option.selected && isNoEnumValueChecked) {
                                    enumValues && onEnumValuesChange(enumValues.map((ev) => ({ itemGuid: ev.ItemGUID, checked: true, folder: currentFolder })));
                                    onChange(option);
                                } else if (!!option.selected && !isEveryEnumValueChecked) {
                                    onChange(option);
                                } else if (!!option.selected && isEveryEnumValueChecked) {
                                    onChange(option);
                                } else if (!option.selected && isEveryEnumValueChecked) {
                                    onChange(option);
                                    enumValues && onEnumValuesChange(enumValues.map((ev) => ({ itemGuid: ev.ItemGUID, checked: false, folder: currentFolder })));
                                } else if (!option.selected && !isEveryEnumValueChecked) {
                                    enumValues && onEnumValuesChange(enumValues.map((ev) => ({ itemGuid: ev.ItemGUID, checked: true, folder: currentFolder })));
                                } else {
                                    onChange(option);
                                }
                            }}
                        />
                    );

                    if (enumValues) {
                        const enumValueRenders = enumValues.map((ev) => (
                            <DropdownEnumValueItem
                                text={Strings.pickTranslation(ev)!}
                                key={ev.ItemGUID}
                                id={ev.ItemGUID}
                                checked={ev.IncludeInLastActivityCalculation}
                                onChange={(item) => {
                                    if (item.checked && !props.selected) {
                                        onChange({ ...props, selected: true });
                                    }
                                    onEnumValuesChange([item]);
                                }}
                                folder={currentFolder}
                            />
                        ));

                        renderItems = (
                            <div key={props.key}>
                                {renderItems}
                                <div>{enumValueRenders}</div>
                                {defaultRender ? defaultRender(OPTION_DIVIDER) : null}
                            </div>
                        );
                    }
                    return renderItems;
                }

                return null;
            }
            return null;
        },
        [availableValues, enumTypeEnumValues, onChange, onEnumValuesChange]
    );

    const dropdownOptions: IDropdownOption[] = useMemo(
        () => availableValues?.map((av) => ({ key: av.Key, text: Strings.pickTranslation(av.Translations) ?? av.Value, selected: selectedKeys.includes(av.Key) })) ?? [],
        [selectedKeys, availableValues]
    );

    const dropdownTitle = useMemo(
        () =>
            selectedKeys
                .map((key) => dropdownOptions.find((option) => option.key === key)?.text)
                .sort(StringHelper.localeCompare)
                .join(', '),
        [selectedKeys, dropdownOptions]
    );

    return <Dropdown onRenderItem={handleRenderItem} placeholder={myStrings.selectOptions} title={dropdownTitle} selectedKeys={selectedKeys} multiSelect options={dropdownOptions} {...restProps} />;
};

export default NestedMultiselectDropdown;
